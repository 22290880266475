import styled from 'styled-components'
import InputCloseIcon from '../../../assets/images/icon/InputCloseIcon.png'
import InputCloseIcon2 from '../../../assets/images/icon/inputInitIcon2.png'

export default function SearchParamInitIcon({ onClick }: { onClick: () => void }) {
  return (
    <>
      <ImgTag alt="검색어 초기화" onClick={onClick} src={InputCloseIcon} />
    </>
  )
}
export function SearchParamInitIcon2({ onClick }: { onClick: () => void }) {
  return (
    <>
      <ImgTag2 alt="검색어 초기화" onClick={onClick} src={InputCloseIcon2} />
    </>
  )
}

const ImgTag2 = styled.img`
  height: 16px;
  width: 16px;
  &:hover {
    cursor: pointer;
  }
`

const ImgTag = styled.img`
  height: 20px;
  width: 20px;
  &:hover {
    cursor: pointer;
  }
`
