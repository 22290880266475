import styled from 'styled-components'
import hashtagClose from '../../../assets/images/icon/hashtagClose.png'
import { useForm } from 'react-hook-form'
import { SwiperSlide } from 'swiper/react'
import SwiperListTwo from '../swiper/SwiperListTwo'
import { useEffect } from 'react'
import SwiperListAutoWidth from '../swiper/SwiperListAutoWidth'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import { castTypeSearchArr } from 'src/utils/common/scripts/checkType'
import { useNavigate } from 'react-router-dom'
import NoData from '../content/Nodata'

type HashTagListLayout = {
  hashTagArr: string[]
  getValues: ReturnType<typeof useForm>['getValues']
  name: string
  setValue: ReturnType<typeof useForm>['setValue']
  onDelete?: (newArr: string[]) => void
  isHistory?: boolean
}

type HashTagItemType = {
  item: string

  onClick: (item: string) => void
}

export default function HashTagListLayout({
  isHistory = false,
  onDelete,
  hashTagArr,
  getValues,
  name,
  setValue,
}: HashTagListLayout) {
  const onClickDeleteHashTag = (deleteItem: string) => {
    const search = localStorage.getItem('search_history')
    if (search) {
      localStorage.setItem('search_history', search.replace(`#${deleteItem}#`, ''))
    }
    const newArr: string[] = Array.isArray(getValues(name + 'Arr'))
      ? getValues(name + 'Arr').filter((item: string) => item !== deleteItem)
      : []
    setValue('hashtagArr', newArr)
    onDelete?.(newArr)
  }

  if (hashTagArr.length === 0) {
    return isHistory ? <NoData discription="최근 검색어가 없습니다." /> : <></>
  }

  return (
    <SwiperListAutoWidth>
      {hashTagArr.map((item: string) => {
        return (
          <SwiperSlide key={generateRandomKey()} style={{ width: 'auto' }}>
            <HashTagItem onClick={onClickDeleteHashTag} item={item} />
          </SwiperSlide>
        )
      })}
    </SwiperListAutoWidth>
  )
}

export function RecentKeyWordListLayout({
  isHistory = false,
  onDelete,
  hashTagArr,
  getValues,
  name,
  setValue,
}: HashTagListLayout) {
  const onClickDeleteHashTag = (deleteItem: string) => {
    const search = localStorage.getItem('search_history')
    if (search) {
      localStorage.setItem('search_history', search.replace(`#${deleteItem}#`, ''))
    }
    const newArr: string[] = Array.isArray(getValues(name + 'Arr'))
      ? getValues(name + 'Arr').filter((item: string) => item !== deleteItem)
      : []
    setValue('hashtagArr', newArr)
    onDelete?.(newArr)
  }

  if (hashTagArr.length === 0) {
    return isHistory ? <NoData discription="최근 검색어가 없습니다." /> : <></>
  }

  return (
    <>
      {hashTagArr.map((item: string) => {
        return (
          <>
            <RecentKeywordItem onClick={onClickDeleteHashTag} item={item} />
          </>
        )
      })}
    </>
  )
}

export function RecentKeywordItem({ item, onClick }: HashTagItemType) {
  const navigate = useNavigate()

  return (
    <KeywordTagWrap>
      <KeywordTagDiv
        onClick={() => {
          navigate(`plant/${item}`)
        }}
      >
        {item}
      </KeywordTagDiv>
      <HashTagImgDiv
        onClick={() => {
          onClick(item)
        }}
      >
        <HashTagImg alt={`${item} 지우기`} src={hashtagClose} />
      </HashTagImgDiv>
    </KeywordTagWrap>
  )
}

export function HashTagItem({ item, onClick }: HashTagItemType) {
  const navigate = useNavigate()

  return (
    <HashTagWrap>
      <HashTagDiv
        onClick={() => {
          // navigate(`plant/${item}`)
        }}
      >
        # {item}
      </HashTagDiv>
      <HashTagImgDiv
        onClick={() => {
          onClick(item)
        }}
      >
        <HashTagImg alt={`${item} 지우기`} src={hashtagClose} />
      </HashTagImgDiv>
    </HashTagWrap>
  )
}

const KeywordTagWrap = styled.div`
  justify-content: space-between;
  display: flex;
  margin-right: 23px;
  :hover {
    cursor: pointer;
  }
`

const KeywordTagDiv = styled.div`
  justify-content: center;
  align-items: center;

  align-content: center;
  text-align: center;

  height: 40px;

  /* Body 01-R */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: -0.02em;

  /* Black */
  color: #242424;
`

const HashTagWrap = styled.div`
  display: flex;
  margin-right: 14px;
  :hover {
    cursor: pointer;
  }
`

const HashTagDiv = styled.div`
  justify-content: center;
  align-items: center;

  align-content: center;
  text-align: center;

  width: fit-content;
  padding-right: 11px;
  padding-left: 11px;
  height: 26px;

  background: #f2f2f2;
  border-radius: 7px;

  font-family: 'Inter-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: #1c1c1c;
`

const HashTagImgDiv = styled.div`
  align-content: center;
  margin-left: 5px;
`

const HashTagImg = styled.img`
  width: 9px;
  height: 9px;
`
