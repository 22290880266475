/*
    파일명 : GreenCaring.tsx
    설명 : 그린 마켓 > 돌보미
    작성일 : 2024-06-19
*/

import { useState } from 'react'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MotionUpLayout from 'src/components/common/layout/MotionUpLayout'
import MuiMain from 'src/components/common/mui/MuiMain'
import Title from 'src/components/common/title/Title'
import CaringForm from 'src/components/specific/green/caring/CaringForm'

export default function GreenCaring() {
  const [isVisible, setIsVisible] = useState(true)
  return (
    <>
      <MotionUpLayout backPath="/green" isVisible={isVisible}>
        <ContainerLayout styleId="div_container_02">
          <MuiMain>
            <Content
              p={0}
              pt={0}
              pb={0}
              component={
                <>
                  <CaringForm
                    onClick={() => {
                      setIsVisible(false)
                    }}
                  />
                </>
              }
            />
          </MuiMain>
        </ContainerLayout>
      </MotionUpLayout>
    </>
  )
}
