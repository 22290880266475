import { Skeleton } from '@mui/material'
import styled from 'styled-components'

type PlantItemLayoutType = {
  children: React.ReactNode
  name: string | null
  eng?: string | null
  onClick: () => void
  className: string
  firstmt?: string
  padding?: string
  index: number
}

export default function PlantItemWithNmb({
  firstmt = '17px',
  padding = '10px 0px 10px 14px',
  children,
  index,
  name,
  eng = '',
  onClick,
  className,
}: PlantItemLayoutType) {
  return (
    <PlantItemContainer
      $firstmt={firstmt}
      $pd={padding}
      onClick={() => {
        onClick()
      }}
      className={className}
    >
      <Number>{index}</Number>
      {children}

      <PlantText name={name} eng={eng} />
    </PlantItemContainer>
  )
}

export function PlantText({ name, eng }: { name: string | null; eng?: string | null }) {
  if (!name) {
    return (
      <TextContainer>
        <Skeleton width={60} variant="text" />
        <Skeleton width={30} variant="text" />
      </TextContainer>
    )
  } else {
    return (
      <TextContainer>
        <PlantTitle>{name}</PlantTitle>
        {eng && <PlantEngTitle>{eng}</PlantEngTitle>}
      </TextContainer>
    )
  }
}

const Number = styled.div`
  width: 20px;
  height: 23px;

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  /* identical to box height, or 164% */
  text-align: center;
  margin-right: 8px;
  letter-spacing: -0.05em;

  color: #1c1c1c;
`

const PlantItemContainer = styled.div<{ $pd: string; $firstmt: string }>`
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  display: flex;
  margin-top: 0px;

  padding: ${({ $pd }) => $pd || '10px 0px 10px 14px'};

  border: 2px solid white;
  border-radius: 5px;
  transition: all 0.2s;

  &.first {
    margin-top: ${({ $firstmt }) => `${$firstmt}`};
  }
`

const TextContainer = styled.div`
  disply: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 9px;
`

const PlantTitle = styled.h2`
  font-family: Pretendard-Regular;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.75px;

  color: #1c1c1c;
`

const PlantEngTitle = styled.h3`
  margin-top: 8px;
  font-family: Pretendard-Regular;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.65px;

  color: #979797;
`
