/*eslint-disable */
import { useMutation } from '@tanstack/react-query'
import api from 'src/utils/common/api/useAxios'
import setAccessToken from '../../common/scripts/setAccessToken'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const postLogin = async reqBody => {
  const res = await api.post(`auth-service/login`, reqBody)
  const accessToken = res.data.data.accessToken // 엑세스 토큰

  return setAccessToken(accessToken) // 로컬 스토리지에 엑세스 토큰 저장
}

export const useLogin = () => {
  const navigate = useNavigate()

  const { mutate, isPending, isLoading } = useMutation({
    mutationKey: ['login'],
    mutationFn: postLogin,
    onSuccess: () => {
      // toast.success('로그인이 성공적으로 처리되었습니다.')

      navigate('/main')
    },
    onError: () => {
      toast.error('로그인에 실패하였습니다.')
    },
  })

  return {
    mutate,
    isPending,
    isLoading,
  }
}
