/*eslint-disable */

import * as React from 'react'
import Box from '@mui/material/Box'

import { useCurrentLastQuery } from 'src/utils/common/hooks/customHook'
import { CONTENT_PADDING, CONTENT_PADDING_BOTTOM, CONTENT_PADDING_TOP } from 'src/utils/common/constants/constants'

interface TabPanelProps {
  children?: React.ReactNode
  pt?: number
  pb?: number
  p?: number
  h?: string
}

function CustomTabPanel(props: TabPanelProps) {
  const { h, children, pt, pb, p, ...other } = props

  return (
    <div role="tabpanel" aria-labelledby={`simple-tab`} {...other}>
      <Box sx={{ height: h, p: p, pt: pt, pb: pb }}>
        {/* <div> */}
        {children}
        {/* </div> */}
      </Box>
    </div>
  )
}

export type ContentType = {
  component: JSX.Element | null
  pt?: number
  pb?: number
  p?: number
  h?: string
}

export default function Content({
  component,
  h = 'auto',
  p = CONTENT_PADDING,
  pt = CONTENT_PADDING_TOP,
  pb = CONTENT_PADDING_BOTTOM,
}: ContentType) {
  return (
    <Box sx={{ width: '100%' }}>
      <CustomTabPanel h={h} pt={pt} pb={pb} p={p}>
        {component}
      </CustomTabPanel>
    </Box>
  )
}
