import DetailViewLabelLayout from 'src/components/common/layout/DetailViewLabelLayout'
import styled from 'styled-components'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import PageSubTitleTwo from 'src/components/common/content/PageSubTitleTwo'
import ForwardIcon from 'src/components/common/icon/ForwardIcon'
import { title } from 'process'
import CustomMap from 'src/components/common/form/sub/loc/CustomMap'
import LineOne from 'src/components/common/line/LineOne'

export default function GreenLocationInfo() {
  return (
    <>
      <LineOne mt="23px" />
      <DetailViewLabelLayout h2MarginBottom="10px" padding="0px 0px 0px 0px" marginBottom="23px" title="관련 글 목록">
        <>
          <PageSubTitleTwo
            iconComp={<ForwardIcon onClick={() => {}} title="용산구 이촌1동" />}
            marginBottom="25px"
            h1ClassNm="small"
            title="용산역 1번 출구"
          />
          <CustomMap
            borderRadius="7px"
            height="135px"
            width="100%"
            clickable={false}
            center={{ lat: 37.4967090172, lng: 126.846 }}
            onChangePosition={() => {}}
          />
        </>
      </DetailViewLabelLayout>
      <LineOne mt="23px" />
    </>
  )
}

const Wrap = styled.div``
