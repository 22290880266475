import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MuiMain from 'src/components/common/mui/MuiMain'
import CollectionItemHeader from 'src/components/specific/collection/list/CollectionItemHeader'
import img from '../../../../assets/images/collection/collectionHeaderImg.png'

/*
    파일명 : CollectionItem.tsx
    설명 : 싹싹도감 > 내 도감 > 도감 상세
    작성일 : 2024-06-17
*/
export default function CollectionItem() {
  return (
    <ContainerLayout styleId="div_container_03">
      <MuiMain>
        <Content p={0} pt={0} pb={0} component={<CollectionItemHeader img={img} />} />
      </MuiMain>
    </ContainerLayout>
  )
}
