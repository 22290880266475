import styled from 'styled-components'
import Skeleton from '@mui/material/Skeleton'

type LoadingContainer = {
  margin?: string
  width?: string
  height?: string
  styleId?: string
}
export default function LoadingContainer({
  margin = '0px 0px 0px 0px',
  width = '100%',
  height = '100%',
  styleId = '',
}: LoadingContainer) {
  return <Container className={styleId} $margin={margin} $height={height} $width={width} />
}

const Container = styled.div<{ $margin: string; $width: string; $height: string }>`
  margin: ${({ $margin }) => `${$margin}`};
  width: ${({ $width }) => `${$width}`};
  height: ${({ $height }) => `${$height}`};

  background: #f2f2f2;
  &.custom-chip {
    /* Frame 11 */

    box-sizing: border-box;
    background: #f2f2f2;

    width: 84px;
    height: 33px;

    border-radius: 77px;
    margin-right: 5px;
  }

  &.img_box {
    /* Rectangle 145 */

    width: 100%;
    aspect-ratio: 1 / 1;

    border-radius: 7px;
  }

  &.img_box_h {
    /* Rectangle 145 */

    height: 100%;
    aspect-ratio: 1 / 1;

    border-radius: 10px;
  }
  &.hash {
    width: 58px;
    height: 26px;

    background: #f2f2f2;
    border-radius: 7px;
  }

  &.green_img {
    box-sizing: border-box;

    width: 100%;
    aspect-ratio: 172 / 126;
    margin-bottom: 9px;
    /* Rectangle 141 */
    border-radius: 7px;
  }

  &.prof_img {
    border-radius: 50%;
  }
`
