import styled from 'styled-components'
import plant from '../../../assets/images/profile/plant.png'
import plantActive from '../../../assets/images/profile/plantActive.png'
import good from '../../../assets/images/profile/good.png'
import goodActive from '../../../assets/images/profile/goodActive.png'
import comment from '../../../assets/images/profile/comment.png'
import commentActive from '../../../assets/images/profile/commentActive.png'

export function ProfilePlantIcon({ active }: { active: boolean }) {
  return (
    <>
      <Plant alt="내식물" src={active ? plantActive : plant} />
    </>
  )
}

export function ProfileGoodIcon({ active }: { active: boolean }) {
  return (
    <>
      <Good alt="좋아요한게시글" src={active ? goodActive : good} />
    </>
  )
}

export function ProfileCommentIcon({ active }: { active: boolean }) {
  return (
    <>
      <Comment alt="댓글단게시글" src={active ? commentActive : comment} />
    </>
  )
}

const Plant = styled.img`
  width: 27.5px;
  height: 20px;
`

const Good = styled.img`
  width: 24px;
  height: 20px;
`

const Comment = styled.img`
  width: 21.6px;
  height: 18px;
`
