import BackDrop from '../content/BackDrop'
import { AnimatePresence, motion } from 'framer-motion'
import PopOutLayout from '../layout/PopOutLayout'
import Drawer from './Drawer'
import styled from 'styled-components'

export default function BackDropDrawer({
  backdropActive = true,
  drawerOpen,
  onClickBackDrop,
  title,
  subTitle,
  children,
  mb,
  position = 'absolute',
  height,
  pt,
  b,
}: {
  position?: string
  drawerOpen: boolean
  backdropActive: boolean
  onClickBackDrop?: () => void
  title?: string | null
  subTitle?: string | null
  children?: React.ReactNode | null
  mb?: string
  height?: number
  pt?: number
  b?: number
}) {
  return (
    <>
      <AnimatePresence>
        {drawerOpen && (
          <FixedLayout>
            {backdropActive && (
              <BackDrop
                onClickBackDrop={() => {
                  onClickBackDrop?.()
                }}
              />
            )}
            <PopOutLayout height={height}>
              <Drawer
                position={position}
                b={b}
                pt={pt}
                key="drawer"
                height={height}
                mb={mb}
                title={title}
                subTitle={subTitle}
              >
                {children}
              </Drawer>
            </PopOutLayout>
          </FixedLayout>
        )}
      </AnimatePresence>
    </>
  )
}

const FixedLayout = styled(motion.div)`
  position: fixed;
  bottom: 90px;
  width: 100%;
  height: 100%;
  max-width: 390px;
  z-index: 1000;
`
