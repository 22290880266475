/*
    파일명 : CollectionMainRec.tsx
    설명 : 싹싹도감 메인 > 식물 둘러보기
    작성일 : 2024-06-13
*/

import PageSubTitleTwo from 'src/components/common/content/PageSubTitleTwo'
import CollectionRecList from './CollectionRecList'
import { COLLECTION_REC_SAMPLE } from 'src/utils/common/constants/constants'
import { useGetPlantList } from '../hook/CollectionListCustomHook'
import DrawerLayout from 'src/components/common/layout/DrawerLayout'
import { useEffect, useState } from 'react'
import CollectionItemMoveListMain from './CollectionItemMoveListMain'
import { useRecoilState } from 'recoil'
import { refethCollections } from 'src/utils/common/state/state'

export default function CollectionMainRec() {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [closeInner, setCloseInner] = useState(false)
  const [refetchCol, setRefetchCol] = useRecoilState(refethCollections)
  const [selectId, setSelectId] = useState<{
    id: number | string
    clicked: boolean
  } | null>(null)
  const { collectionList, setCollectionList, isPending } = useGetPlantList()
  const [changedBookmark, setChangedBookmark] = useState<number | string | null>(null)

  useEffect(() => {
    if (changedBookmark) {
      if (collectionList && selectId?.id) {
        setCollectionList(selectId?.id)
      }
    }
  }, [changedBookmark])
  return (
    <>
      <PageSubTitleTwo
        marginTop="31px"
        marginBottom="23px"
        title={!collectionList ? null : '다른 유저들이 본 식물들'}
      />
      <CollectionRecList
        changedBookmark={changedBookmark}
        onClickBookMark={(id, clicked) => {
          setDrawerOpen(true)
          setSelectId({
            id,
            clicked,
          })
        }}
        isPending={isPending}
        clickedDefault={false}
        h="auto"
        scroll={false}
        onScrollEnd={() => {}}
        arr={!collectionList || collectionList.length === 0 ? null : collectionList}
      />
      <DrawerLayout
        title="내 도감 목록"
        height="60%"
        pb={0}
        pt={0}
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false)
          setCloseInner(false)
        }}
        closeInner={closeInner}
        children={
          <>
            <CollectionItemMoveListMain
              type="ADD"
              plantId={selectId?.id || null}
              onFinish={() => {
                setCloseInner(true)
                setRefetchCol(true)
                if (selectId) setChangedBookmark(selectId.id)
              }}
            />
          </>
        }
      />
    </>
  )
}
