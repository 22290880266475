import styled from 'styled-components'
import LoadingContainer from '../container/LoadingContainer'
import Skeleton from '@mui/material/Skeleton'

export default function ProfileContent({ content }: { content: string | null }) {
  if (content === null) {
    return (
      <>
        <Skeleton variant="text" sx={{ marginTop: '20px', fontSize: '1rem' }} />
        <Skeleton variant="text" sx={{ marginBottom: '18px', fontSize: '1rem' }} />
      </>
    )
  } else {
    return (
      <Wrap>
        <pre>{content}</pre>
      </Wrap>
    )
  }
}

const Wrap = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  p,
  pre,
  span {
    text-align: left;
    /* 행복한 식집사 생활을 위한 반려식물 관리 서비스 꼼삐 🪴 */

    /* Body 02 */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    /* identical to box height, or 153% */
    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }
`
