import { plantTypeType, RcntVwdItem } from 'src/utils/common/type/type'
import SearchField from '../../plant/sub/SearchField'
import { useState } from 'react'
import CollectionSearchPlantList from '../sub/CollectionSearchPlantList'
import NoItemList from '../../plant/sub/NoItemList'
import LoadingSpinner from 'src/components/common/container/LoadingSpinner'
import { useLocation, useNavigate } from 'react-router-dom'
import { onSaveHistory } from 'src/utils/common/scripts/common'
import RegNewPlant from '../../plant/create/RegNewPlant'

export default function SearchPlant() {
  const [plantList, setPlantLists] = useState<plantTypeType[] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<undefined | boolean>(undefined)
  const [searchParam, setSearchParam] = useState('')
  const navigate = useNavigate()
  const [registerPlant, setRegisterPlant] = useState(false)

  const location = useLocation()

  const onReceiveDataHandler = (lists: plantTypeType[] | undefined) => {
    setPlantLists(lists)
  }
  const onChangeLoadingState = (state: boolean | undefined) => {
    setIsLoading(state)
  }

  const onChangeSearchParam = (value: string) => {
    if (registerPlant === true) {
      setRegisterPlant(false)
    }
    setSearchParam(value)
  }

  const onSaveSearchHistory = (newSearchParam: string | number) => {
    let search = localStorage.getItem('search_history')
    if (!search) {
      localStorage.setItem('search_history', `#${searchParam}#`)
    } else {
      const searchArr = search.split('#')
      if (10 > searchArr.length) {
        search = search.replace(`#${searchParam}#`, '')
        localStorage.setItem('search_history', `#${searchParam}#${search}`)
      } else {
        if (search.includes(`#${newSearchParam}#`)) {
          search = search.replace(`#${newSearchParam}#`, '')
          localStorage.setItem('search_history', `#${newSearchParam}#${search}`)
        }
      }
    }

    onSaveHistory(newSearchParam, 'recently_clicked')

    navigate(`/collection/${newSearchParam}`, { state: { backpath: location.pathname } })
  }

  return (
    <>
      <SearchField
        type="COLLECTION"
        onClickBackBtn={() => {
          navigate('../', { state: { direction: 'back' } })
        }}
        onChangeSearchParam={onChangeSearchParam}
        onChangeLoadingState={onChangeLoadingState}
        onReceiveData={onReceiveDataHandler}
      />
      {isLoading ? (
        <LoadingSpinner />
      ) : plantList && plantList.length > 0 ? (
        <CollectionSearchPlantList onClickPlant={onSaveSearchHistory} itemArr={plantList} />
      ) : (
        <>
          {registerPlant ? (
            <>
              <RegNewPlant
                onFinish={() => {
                  setRegisterPlant(false)
                }}
              />
            </>
          ) : (
            <NoItemList
              onClick={() => {
                setRegisterPlant(true)
              }}
              showBtn={true}
            />
          )}
        </>
      )}
    </>
  )
}
