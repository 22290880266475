import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MuiMain from 'src/components/common/mui/MuiMain'
import Title from 'src/components/common/title/Title'
import CreateNewPlant from 'src/components/specific/plant/create/CreateNewPlant'
import useDeletePlant from 'src/components/specific/plant/hooks/getPlantInfoCustomHook'
import LoadingSpinner from 'src/components/common/container/LoadingSpinner'
import { useNavigate, useNavigation, useParams } from 'react-router-dom'
import { HiOutlineTrash } from 'react-icons/hi'
import FullLoadingSpinner from 'src/components/common/container/FullLoadingSpinner'
import { useEffect, useState } from 'react'
import Alert3Modal from 'src/components/common/modal/Alert3Modal'

export default function PlantUpdate() {
  const { deletePlant, frmtPlant, loadinPlant, deletingPlant, deletingSuccess, deletingFailed } = useDeletePlant()
  const navigate = useNavigate()
  const { plantId } = useParams()
  const [requestDeleting, setRequestDeleting] = useState(false)

  const [alert, setAlert] = useState<{
    title: string
    discription: string
    showAlert: boolean
    params?: number | string | null
    type?: string
  }>({
    title: '',
    discription: '',
    showAlert: false,
    type: 'after',
  })

  useEffect(() => {
    if (requestDeleting) {
      deletePlant()
      setRequestDeleting(false)
    }
  }, [requestDeleting])

  if (deletingPlant || loadinPlant) {
    return (
      <>
        <FullLoadingSpinner isShow={true} />
      </>
    )
  }

  return (
    <>
      <ContainerLayout styleId="div_container_01">
        <MuiMain>
          <Title
            onClick={() => {
              navigate(`/plant/detail/${plantId}`, { state: { direction: 'back' } })
            }}
            title="식물 수정"
            comp={
              <>
                <HiOutlineTrash
                  onClick={() => {
                    if (loadinPlant) return
                    setAlert({
                      title: '식물 삭제',
                      showAlert: true,
                      discription: '식물을 삭제하시겠어요?',
                    })
                  }}
                  style={{
                    justifySelf: 'self-end',
                    marginRight: '17px',
                  }}
                  color="#979797"
                />
              </>
            }
          />
          <Content component={<>{frmtPlant ? <CreateNewPlant updateItem={frmtPlant} /> : <LoadingSpinner />}</>} />

          <Alert3Modal
            title={alert.title}
            description={alert.discription}
            open={alert.showAlert}
            afterCloseAlert={() => {
              setAlert({
                title: '',
                discription: '',
                showAlert: false,
                params: null,
                type: 'after',
              })
              setRequestDeleting(true)
            }}
            handleClose={() => {
              setAlert({
                title: '',
                discription: '',
                showAlert: false,
                params: null,
                type: 'after',
              })
            }}
          />
        </MuiMain>
      </ContainerLayout>
    </>
  )
}
