/*
    파일명 : PlazaHome.tsx
    설명 : 식물 광장 > 메인
    작성일 : 2024-06-11
    @param 
        
*/

import PageHeader from 'src/components/common/content/PageHeader'
import FormMain from 'src/components/common/form/FormMain'
import MagnifyingIcon from 'src/components/common/icon/MagnifyingIcon'
import SelectChipsList from '../../common/form/sub/select/SelectChipsList'
import PlazaList from './sub/PlazaList'
import { CONTENT_PADDING, CONTENT_PADDING_TOP, PLANT_PLAZA_SELECT } from 'src/utils/common/constants/constants'
import PlazaSearch from './sub/PlazaSearch'
import WriteIcon from 'src/components/common/icon/WriteIcon'
import IconLayout from 'src/components/common/icon/IconLayout'
import { VscBell, VscBellDot } from 'react-icons/vsc'
import { AppBar, Box } from '@mui/material'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { PostType } from 'src/utils/common/type/type'

export default function PlazaHome({
  onClickUpdate,
  onClickPost,
  onClickBel,
  refetch,
  setInitStts,
}: {
  onClickPost: () => void
  onClickUpdate: (updatePost: PostType) => void
  onClickBel: () => void
  refetch: boolean
  setInitStts: () => void
}) {
  const location = useLocation()
  const { typeNm, paramNm } = useParams()
  const [type, setType] = useState<string>(typeNm || 'common')
  const [searchParam, setSearchParam] = useState<string>(paramNm || '')
  const [hashtag, setHashtag] = useState('')
  const onClickChip = (item: string) => {
    setType(item)
    setHashtag('')
  }
  const onChangeSearchParam = (param: string) => {
    setSearchParam(param)
  }

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          top: 0,
          WebkitTransition: 'none',
          zIndex: 10,
          height: '195px',
          width: '100%',
          right: 'unset',
          boxShadow: 'unset',
          p: CONTENT_PADDING,
          pt: CONTENT_PADDING_TOP,
          pb: 0,
          backgroundColor: 'white',
          maxWidth: '390px',
        }}
      >
        <PageHeader
          title="식물광장"
          iconComp={
            <IconLayout>
              <WriteIcon onClick={onClickPost} />
              <VscBell onClick={onClickBel} style={{ marginLeft: '16px' }} size={23} />
            </IconLayout>
          }
        />

        <PlazaSearch
          hashtag={hashtag}
          onChangeSearchParam={onChangeSearchParam}
          defaultVal={typeNm ? `select.${typeNm}` : 'select.common'}
          onClickChip={onClickChip}
        />
      </AppBar>

      <PlazaList
        onClickItem={(item: string) => {
          setHashtag(item)
        }}
        setInitStts={setInitStts}
        isUpdated={refetch}
        searchParam={searchParam}
        type={type}
        onClickUpdate={onClickUpdate}
      />
    </>
  )
}

interface MainWrapperProps {
  $position: string
}
const MainWrapper = styled.div<MainWrapperProps>`
  width: 390px;

  overflow: hidden;
  position: ${props => props.$position};
  box-sizing: border-box;
  // left: 15vw;
  @media screen and (max-width: 1024px) {
    left: 0;
  }
  background: white;
`

const HeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  max-width: 390px;
`

const LayoutContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
`
