import { Route, Routes, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'

import MainFindIdAndPwd from '../../../pages/user/find/MainFindIdAndPwd'
import MainUserCreate from 'src/pages/user/create/MainUserCreate'

import PlantCreate from 'src/pages/plant/create/PlantCreate'

import Login, { action } from 'src/pages/login/Login'
import MainHome, { loader } from 'src/pages/main/MainHome'
import App from 'src/App'
import CreateNewPlant from 'src/components/specific/plant/create/CreateNewPlant'
import DiaryCreate from 'src/pages/diary/DiaryCreate'
import PlantDetail from 'src/pages/plant/detail/PlantDetail'
import Plaza from 'src/pages/plaza/Plaza'
import PlazaCreate from 'src/pages/plaza/create/PlazaCreate'
import MotionUpLayout from 'src/components/common/layout/MotionUpLayout'
import Collection from 'src/pages/collection/Collection'
import CollectionDetail, { colAction } from 'src/pages/collection/detail/CollectionDetail'
import CollectionSearch from 'src/pages/collection/search/CollectionSearch'
import CollectionList from 'src/pages/collection/list/CollectionList'
import CollectionItem from 'src/pages/collection/list/item/CollectionItem'
import CollectionItemEdit from 'src/pages/collection/list/item/edit/CollectionItemEdit'
import CollectionsearchPlant from 'src/pages/collection/search/CollectionSearchPlant'
import Green from 'src/pages/green/Green'
import GreenCaring from 'src/pages/green/caring/GreenCaring'
import GreenMarket from 'src/pages/green/market/GreenMarket'
import GreenDetail from 'src/pages/green/detail/GreenDetail'
import GreenChat from 'src/pages/green/detail/chat/GreenChat'
import Plan from 'src/pages/plan/Plan'
import PlantUpdate from 'src/pages/plant/detail/update/PlantUpdate'
import Follow from 'src/pages/follow/Follow'

import Profile2 from 'src/pages/profile/Profile2'
import CharacterUpdate from 'src/pages/plant/detail/update/CharacterUpdate'
import MyPage from 'src/pages/profile/MyPage'
import CharacterUpdateSample from 'src/pages/plant/detail/update/CharacterUpdateSample'

function RoutesComp() {
  return (
    <>
      <Route path="/login" element={<Login />} />
      <Route path="/user/create" element={<MainUserCreate />} />
      <Route path="/user/find/*" element={<FindPage />} />
      <Route path="/test" element={<CreateNewPlant />} />
      <Route path="/main" element={<MainHome />} />
      <Route path="/plant/*" element={<PlantPage />} />
      <Route path="/diary/create" element={<DiaryCreate />} />
      <Route path="/plan/create" element={<Plan />} />
      <Route path="/collection/*" element={<CollectionPage />} />
      <Route path="/plaza/*" element={<PlazaPage />} />

      <Route path="/profile/:userId" element={<Profile2 />} />

      <Route path="/green/*" element={<GreenPage />} />
    </>
  )
}

export function FindPage() {
  return (
    <Routes>
      <Route path="id" element={<MainFindIdAndPwd />} />
      <Route path="pwd" element={<MainFindIdAndPwd />} />
    </Routes>
  )
}

export function PlantPage() {
  return (
    <Routes>
      <Route index element={<PlantCreate />} />
      <Route path="create" element={<PlantCreate />} />
      <Route path="create/:plantId" element={<PlantCreate />} />
      <Route path="create/:plantId/:charId" element={<PlantCreate />} />
      <Route path="detail/:plantId" element={<PlantDetail />} />
      <Route path="detail/:plantId/update" element={<PlantUpdate />} />

      <Route path="detail/:plantId/update/character" element={<CharacterUpdate />} />
      <Route path="detail/character/sample" element={<CharacterUpdateSample />} />
    </Routes>
  )
}

export function PlantCreatePage() {
  return (
    <Routes>
      <Route index element={<PlantCreate />} />
      <Route path=":plantId" element={<PlantCreate />} />
      <Route path=":plantId/:charId" element={<PlantCreate />} />
    </Routes>
  )
}

export function CollectionPage() {
  return (
    <Routes>
      <Route index element={<Collection />} />
      <Route path="list/*" element={<CollectionListPage />} />
      <Route path=":itemId" element={<CollectionDetail />} />
      <Route path="search/*" element={<CollectionSearchPage />} />
    </Routes>
  )
}

export function CollectionListPage() {
  return (
    <Routes>
      <Route index element={<CollectionList />} />
      <Route path=":collectionId/*" element={<CollectionItemPage />} />
    </Routes>
  )
}

export function CollectionItemPage() {
  return (
    <Routes>
      <Route index element={<CollectionItem />} />
      <Route path="edit" element={<CollectionItemEdit />} />
    </Routes>
  )
}

export function CollectionSearchPage() {
  return (
    <Routes>
      <Route index element={<CollectionSearch />} />
      <Route path="plant" element={<CollectionsearchPlant />} />
      <Route path="plant/:plantNm" element={<CollectionsearchPlant />} />
    </Routes>
  )
}

export function PlazaPage() {
  return (
    <Routes>
      <Route index element={<Plaza />} />
      <Route path=":typeNm/:paramNm" element={<Plaza />} />
      <Route path="create/*" element={<PlazaCreatePage />} />
    </Routes>
  )
}

export function ProfilePage() {
  return (
    <Routes>
      <Route path=":userId/*" element={<ProfileSubPage />} />
    </Routes>
  )
}

export function ProfileSubPage() {
  return (
    <Routes>
      <Route index element={<Profile2 />} />
      <Route path="setting" element={<MyPage />} />
    </Routes>
  )
}

export function PlazaCreatePage() {
  return (
    <Routes>
      <Route path="asking" element={<PlazaCreate />} />
      <Route path="boasting" element={<PlazaCreate />} />
    </Routes>
  )
}

export function GreenPage() {
  return (
    <Routes>
      <Route index element={<Green />} />
      <Route path="detail/:detailId/*" element={<GreenDetailPage />} />
      <Route path="create/*" element={<GreenCreatePage />} />
    </Routes>
  )
}

export function GreenDetailPage() {
  return (
    <Routes>
      <Route index element={<GreenDetail />} />
      <Route path="chat/:userId" element={<GreenChat />} />
    </Routes>
  )
}

export function GreenCreatePage() {
  return (
    <Routes>
      <Route path="market" element={<GreenMarket />} />
      <Route path="caring" element={<GreenCaring />} />
    </Routes>
  )
}

export default RoutesComp

// const routes = createBrowserRouter(
//   createRoutesFromElements(
//     <Route path="/" element={<App />}>
//       <Route path="" element={<Login />} action={action} />
//       <Route path="user">
//         <Route path="create" element={<MainUserCreate />} />
//         <Route path="find">
//           <Route path="id" element={<MainFindIdAndPwd />} />
//           <Route path="pwd" element={<MainFindIdAndPwd />} />
//         </Route>
//       </Route>
//       <Route path="test" element={<CreateNewPlant />} />
//       <Route id="main" path="main" element={<MainHome />} loader={loader} />
//       <Route path="plant" loader={loader}>
//         <Route path="create">
//           <Route index={true} element={<PlantCreate />} />
//           <Route path=":plantId" element={<PlantCreate />}>
//             <Route path=":charId" element={<PlantCreate />} />
//           </Route>
//         </Route>
//         <Route path="detail">
//           <Route path=":plantId" element={<PlantDetail />} />
//         </Route>
//       </Route>
//       <Route path="diary">
//         <Route path="create" element={<DiaryCreate />} />
//       </Route>
//       <Route path="plan">
//         <Route path="create" element={<Plan />} />
//       </Route>
//       <Route path="collection">
//         <Route index={true} element={<Collection />} />
//         <Route path="list">
//           <Route index={true} element={<CollectionList />} />
//           <Route path=":collectionId">
//             <Route index={true} element={<CollectionItem />} />
//             <Route path="edit" action={colAction} element={<CollectionItemEdit />} />
//           </Route>
//         </Route>
//         <Route path="search">
//           <Route index={true} element={<CollectionSearch />} />
//           <Route path="plant" element={<CollectionsearchPlant />} />
//           <Route path="plant/:plantNm" element={<CollectionsearchPlant />} />
//         </Route>
//         <Route path=":itemId" element={<CollectionDetail />} />
//       </Route>
//       <Route path="plaza">
//         <Route index={true} element={<Plaza />} />
//         <Route path="create">
//           <Route path="asking" element={<PlazaCreate />}>
//             <Route path=":askingId" element={<PlazaCreate />} />
//           </Route>
//           <Route path="boasting" element={<PlazaCreate />}>
//             <Route path=":boastingId" element={<PlazaCreate />} />
//           </Route>
//         </Route>
//       </Route>
//       <Route path="profile">
//         <Route
//           path=":userId"
//           element={
//             <>
//               <Profile />
//             </>
//           }
//         />
//       </Route>
//       <Route path="green">
//         <Route index={true} element={<Green />} />
//         <Route path="detail/:detailId">
//           <Route index={true} element={<GreenDetail />} />
//           <Route
//             path="chat/:userId"
//             element={
//               <>
//                 <GreenChat />
//               </>
//             }
//           />
//         </Route>

//         <Route path="create">
//           <Route path="market" element={<GreenMarket />}>
//             <Route path=":marketId" element={<></>} />
//           </Route>
//           <Route path="caring" element={<GreenCaring />}>
//             <Route path=":caringId" element={<></>} />
//           </Route>
//         </Route>
//       </Route>
//     </Route>,
//   ),
// )

// export default routes
