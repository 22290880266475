import styled from 'styled-components'
import wateringIcon from '../../../assets/images/icon/wateringIcon2.png'

export default function WateringIcon({
  intervalIndays,
  margin = '0px 0px 0px 0px',
}: {
  margin?: string
  intervalIndays?: number | null | string
}) {
  return (
    <Watering $margin={margin}>
      <img src={wateringIcon} alt="물방울" />
      {(intervalIndays && <span>+ {intervalIndays}일</span>) || <p>+ 0일</p>}
    </Watering>
  )
}

const Watering = styled.div<{ $margin: string }>`
  display: flex;
  align-items: center;
  margin: ${({ $margin }) => $margin};

  width: 95px;
  height: 38px;
  justify-content: center;

  background: #e0f7f1;
  border-radius: 50px;

  width: 88px;
  height: 29px;

  /* Gray 02 */
  background: #f4f6fa;
  border-radius: 50px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 1;

  span {
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #1c1c1c;
  }

  img {
    height: 18px;
    width: 14px;
    margin-right: 10px;
  }
`
