/*
  작성자 : 김효은
  작성일 : 2024-05-09
  설명 : 타이머 인풋 . 버튼이 한 줄로 표현 될 때 사용하는 컴포넌트
*/

import InputWithBtnLayout from 'src/components/common/layout/InputWithBtnLayout'

export interface InputFieldProps {
  inputComp: JSX.Element | JSX.Element[]
  buttonComp: JSX.Element | JSX.Element[]
}

export default function InputWithTimerButton({ inputComp, buttonComp }: InputFieldProps) {
  return (
    <>
      <InputWithBtnLayout>
        <>{inputComp}</>
        <>{buttonComp}</>
      </InputWithBtnLayout>
    </>
  )
}
