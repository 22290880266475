/*eslint-disable */
import { useMutation } from '@tanstack/react-query'
import api, { socialApi } from 'src/utils/common/api/useAxios'
import setAccessToken from '../../common/scripts/setAccessToken'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { getDomain } from 'src/utils/common/scripts/common'

const REDIRECT_URI = `${getDomain()}`

// ※주의※
// 여기 안에 분기처리(if문) 넣으면
// 무조건적으로 onSuccess 함수 실행됨
// 그래서 mutate함수 바깥에 if문 넣음
const postKakaoLogin = async url => {
  const code = url.get('code')
  const res = await socialApi.post(`/auth-service/oauth/kakao?code=${code}&redirect_url=${REDIRECT_URI}`)

  window.history.replaceState({}, '', document.location.pathname) // url 원래대로
  const accessToken = res.data.data.accessToken // 엑세스 토큰
  return setAccessToken(accessToken) // 로컬 스토리지에 엑세스 토큰 저장
}

export const useKaKaoLogin = () => {
  const navigate = useNavigate()
  return useMutation({
    mutationKey: ['kakao'],
    mutationFn: postKakaoLogin,
    onSuccess: () => {
      toast.success('카카오 로그인이 성공적으로 처리되었습니다.')
      navigate('/main')
    },
    onError: error => {
      toast.error('카카오 로그인이 처리되지 않았습니다.')
    },
  })
}
