/*eslint-disable */

import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { useLocation } from 'react-router-dom'
import { useCurrentLastQuery } from '../../../../utils/common/hooks/customHook'
import { CONTENT_PADDING } from 'src/utils/common/constants/constants'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import zIndex from '@mui/material/styles/zIndex'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  customP: number | undefined
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, customP, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: customP, pt: 4.25 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
interface TabData {
  title: string | React.ReactNode
  component: JSX.Element | null
  queryId?: string
}
export type MenuTabType = {
  tabObjArr: TabData[]
  customP?: number
  defaultVal?: string
  onClick?: (index: number) => void
}
export default function MenuTab({ defaultVal, onClick, tabObjArr, customP = CONTENT_PADDING }: MenuTabType) {
  const [value, setValue] = React.useState(defaultVal ? tabObjArr.findIndex(tab => tab.queryId === defaultVal) : 0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    onClick?.(newValue)
  }

  const { currentId } = useCurrentLastQuery()

  // currentId 변경 시 해당하는 탭의 인덱스를 찾아 설정
  React.useEffect(() => {
    if (!defaultVal) {
      const index = tabObjArr.findIndex(tab => tab.queryId === currentId)
      if (index !== -1) {
        setValue(index)
      }
    }
  }, [currentId, tabObjArr, defaultVal])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={active.line}>
        <Tabs sx={active.tabs} value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabObjArr.map((item: any, _idx: number) => (
            <Tab sx={unselected.tab} key={generateRandomKey()} label={item.title} {...a11yProps(_idx)} />
          ))}
        </Tabs>
      </Box>

      {tabObjArr.map((item: any, _idx: number) => (
        <CustomTabPanel customP={customP} key={Math.random()} value={value} index={_idx}>
          {item.component}
        </CustomTabPanel>
      ))}
    </Box>
  )
}
const unselected = {
  tab: {
    fontfamily: 'Pretendard-Regular',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '-0.02em',
    flex: '1',

    lineHeight: '24px',
    color: '#A2A3A7',

    paddingBottom: '0px',
  },
}

const active = {
  line: {
    borderBottom: '1.20576px solid #F2F2F2',
  },
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#00C398',
    },
    '& .MuiTab-root.Mui-selected': {
      color: '#00C398',
    },
  },
}
