import styles from './styles/MainHomeWeather.module.css'
import WeatherRain from '../../../assets/images/icon/WeatherRain.png'
import { UseMainHomeApi } from './hooks/MainHomeCustomHook'
import logIcon from '../../../assets/images/icon/LocationIcon.png'
import { Skeleton } from '@mui/material'
import styled from 'styled-components'
import WeatherIcon from 'src/components/common/icon/WeatherIcon'
import { MemberAddressType } from 'src/utils/common/type/type'

export default function MainHomeWeather({ loc }: { loc: null | MemberAddressType }) {
  if (!loc) {
    return (
      <>
        <Skeleton variant="rounded" height={99} width={356} sx={{ marginTop: '23px' }} />
      </>
    )
  } else {
    return <SubComp loc={loc} />
  }
}

export function SubComp({ loc }: { loc: MemberAddressType }) {
  const { weatherInfo, weather, isSuccess, isPending } = UseMainHomeApi({ loc })

  if (!weatherInfo || isPending) {
    return (
      <>
        <Skeleton variant="rounded" height={108} sx={{ marginTop: '20px', width: '100%' }} />
      </>
    )
  } else {
    return (
      <Wrap>
        <OuterWrap>
          <div>
            <Loc>
              <img style={{ width: '15px', height: '17px' }} src={logIcon} alt="날씨 위치" />
              {loc.address && <p>{loc.address}</p>}
            </Loc>
            <BottomDiv>
              <ImgDiv>{weatherInfo.icon && <WeatherIcon icon={weatherInfo.icon} />}</ImgDiv>
              <BottomInnerDiv>
                <Weather>
                  <h3>{weatherInfo.temp}˚</h3>
                </Weather>
                <MinNMax>
                  <p>최고 {weatherInfo.max}˚</p>
                  <p>최저 {weatherInfo.min}˚</p>
                </MinNMax>
              </BottomInnerDiv>
              <Discription>
                <pre>{weatherInfo.discription}</pre>
              </Discription>
            </BottomDiv>
          </div>
        </OuterWrap>

        {/* <RightDiv>
          <p>{weatherInfo.discription}</p>
        </RightDiv> */}
      </Wrap>
    )
  }
}
const OuterWrap = styled.div`
  width: 100%;
  // display: flex;
  // justify-content: center;
`
const LeftDiv = styled.div``
const RightDiv = styled.div`
  /* 공기 */
  padding: 6px 10px;
  align-items: center;
  display: flex;
  box-sizing: border-box;

  width: 100%;
  // margin-left: 4.87%;
  height: 75px;

  background: #e1f5fe;
  border: 0.5px solid #4fc3f7;
  border-radius: 5px;
  margin: auto;
  margin-right: 0px;
  p {
    /* 비가 하루종일 내리고 바람이 많이 부는 날씨에요! */

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */
    text-align: center;

    color: #1c1c1c;
  }
`

const Discription = styled.div`
  /* 공기 */
  whitespace: pre;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 0px;

  height: max-content;

  /* Gray 02 */
  background: #f4f6fa;
  border-radius: 13px;

  padding-left: 2px;
  padding-right: 2px;

  width: 100%;
  margin-left: 9px;

  /* 부분적으로 비가 내려요. */
  pre {
    /* Caption */
    font-family: 'Pretendard-Regaular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */
    text-align: center;
    letter-spacing: -0.02em;

    /* Gray 05 */
    color: #646669;

    @media (max-width: 372px) {
      font-size: 8px;
    }
  }
`
const MinNMax = styled.div`
  // margin-left: 10px;

  margin-left: 13px;
  width: 56px;
  p {
    /* 최고 22˚ 최저 16˚ */

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    color: #979797;
  }
`
const Loc = styled.div`
  display: flex;
  p {
    font-family: Pretendard-Regular;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1c1c1c;
    margin-left: 8px;
  }
`

const Wrap = styled.div`
  /* 날씨 위젯 */

  /* Auto layout */

  margin-top: 20px;
  width: 100%;
  height: 108px;

  /* White */
  background: #ffffff;
  /* Main Shadow */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 13px;

  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
`

const Weather = styled.div`
  height: 100%;
  display: flex;
  h3 {
    /* 18˚ */
    margin-left: 4px;

    font-family: 'Pretendard-Regular';
    /* 18˚ */

    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    /* identical to box height */
    text-align: center;

    /* Black */
    color: #242424;
  }
`

const BottomInnerDiv = styled.div`
  display: flex;
  align-items: center;
`

const BottomDiv = styled.div`
  display: flex;
  width: 100%;

  margin-top: 9px;

  align-items: center;
`
const ImgDiv = styled.div`
  height: 89%;

  margin-right: 0.8vw;

  aspect-ratio: 1 / 1;
  img {
    height: 100%;
    aspect-ratio: 1 / 1;
  }
`
