import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import styled from 'styled-components'
import DualButtonLayout from '../button/DualButtonLayout'
import FormMain from '../form/FormMain'
import { alertState2 } from 'src/utils/common/state/state'
import { useRecoilState } from 'recoil'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '300px',
  bgcolor: 'background.paper',
  borderRadius: '18px',
  boxShadow: 24,
  p: 4,
}

export default function Alert2Modal({
  title,
  description,
  open,
  handleClose,
  afterCloseAlert,
  beforeCloseAlert,
  type,
  params,
  afterCloseAlertWidthParams,
  beforeCloseAlertWidthParams,
}: {
  description: string
  title: string
  open: boolean
  type?: string
  params?: string | number
  handleClose: () => void
  afterCloseAlertWidthParams?: (newParams?: string | number) => void
  afterCloseAlert?: () => void
  beforeCloseAlert?: () => void
  beforeCloseAlertWidthParams?: (newParams?: string | number) => void
}) {
  const confirmAlertHandler = () => {
    afterCloseAlert?.()
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          borderRadius: '18px',
        }}
      >
        <Box sx={style}>
          <Title id="modal-modal-title" variant="h6">
            {title}
          </Title>
          <Content id="modal-modal-description" sx={{ mb: '23px', mt: 2 }}>
            {description}
          </Content>
          <DualButtonLayout styleID="div_dual_btn_left">
            <FormMain.Button onClick={handleClose} styleID="btn_diary_dual_01" type="button">
              취소
            </FormMain.Button>
            <FormMain.Button onClick={confirmAlertHandler} styleID="btn_diary_dual_02" type="button">
              확인
            </FormMain.Button>
          </DualButtonLayout>
        </Box>
      </Modal>
    </>
  )
}

const Title = styled(Typography)`
  /* 앗! 이미 일지를 썼던 날이에요! */

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.05em;

  color: #1c1c1c;
`

const Content = styled(Typography)`
  /* 일지를 수정하시겠어요? */

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  letter-spacing: -0.05em;

  color: #1c1c1c;
`
