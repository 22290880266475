/*
    파일명 : PlazaSelect.tsx
    설명 : 식물 광장 > 메인 > 셀렉트 박스
    작성일 : 2024-06-12
        
*/

import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import FormMain from 'src/components/common/form/FormMain'
import ChoiceChips from 'src/components/common/form/sub/select/ChoiceChips'
import { findTrueValues } from 'src/utils/common/scripts/common'

import { SelectChipsType } from 'src/utils/common/type/type'
import styled from 'styled-components'

type SelectChipsListType = {
  width?: string
  mt?: string
  mb?: string
  length: number
  defaultVal: string
  onClickChip: (item: string) => void
  list: SelectChipsType[] | null
  register: ReturnType<typeof useForm>['register']
  getValues: ReturnType<typeof useForm>['getValues']
  setValue: ReturnType<typeof useForm>['setValue']
  watch: ReturnType<typeof useForm>['watch']
  isChanged?: null | string
}

export default function SelectChipsList({
  setValue,
  getValues,
  watch,
  register,
  list,
  length,
  onClickChip,
  defaultVal,
  isChanged,
  mt = '16.48px',
  mb = '19px',
  width = 'unset',
}: SelectChipsListType) {
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    setValue(defaultVal, true)

    setToggle(prev => !prev)
  }, [])

  useEffect(() => {
    if (isChanged) {
      if (getValues('select')) {
        const trueVal = findTrueValues(getValues('select'))
        setValue(`select.${trueVal[0]}`, false)
      }

      setValue(`select.${isChanged}`, true)
      onClickChip(isChanged)

      setToggle(prev => !prev)
    }
  }, [isChanged])

  useEffect(() => {
    const subscirbe = watch((data, { name }) => {
      if (name === 'select') {
        const trueVal = findTrueValues(data.select)

        if (trueVal.length === 1) {
          onClickChip(trueVal[0])
        }
      }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])

  return (
    <Wrap $mt={mt} $mb={mb} $wd={width}>
      <ChoiceChips
        length={length}
        list={list}
        listName="select"
        register={register}
        getValues={getValues}
        setValue={setValue}
      />
    </Wrap>
  )
}

const Wrap = styled.div<{ $mt: string; $mb: string; $wd: string }>`
  width: ${({ $wd }) => $wd};
  margin-top: ${({ $mt }) => $mt};
  margin-bottom: ${({ $mb }) => $mb};
`
