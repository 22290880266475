/*
    파일명 : PlantDetailDiary.tsx
    설명 : 식물 상세 페이지 > 일지
    작성일 : 2024-06-06
*/

import { CustomButton } from 'src/components/common/button/CustomButton'
import DiaryButtonContainer from 'src/components/common/container/DiaryButtonContainer'
import FormMain from 'src/components/common/form/FormMain'
import LabelLayout from 'src/components/common/form/sub/label/LabelLayout'
import DetailViewLabelLayout from 'src/components/common/layout/DetailViewLabelLayout'
import TempSavedDiary from '../sub/TempSavedDiary'
import SavedDiary from '../sub/SavedDiary'
import { CONTENT_PADDING, PLANT_CARE_DETAIL_SAMPE } from 'src/utils/common/constants/constants'
import NoDiary from '../sub/NoDiary'
import moment from 'moment'
import { CalendarEventType } from 'src/utils/common/type/type'
import useDeletePlant, { useGetDirayDetail, useGetDirayList } from '../hooks/getPlantInfoCustomHook'
import { useEffect, useState } from 'react'
import LoadingSpinner from 'src/components/common/container/LoadingSpinner'
import styled from 'styled-components'
import DiaryPlanList from '../sub/DiaryPlanList'
import DiaryContent from '../sub/DiaryContent'
import DiaryImgList from '../sub/DiaryImgList'
import { Box } from '@mui/material'
import { MenuToggle } from 'src/components/common/title/TitleLight'
import Alert from 'src/components/common/modal/Alert'
import { useRecoilState } from 'recoil'
import { alertState, LodingSpinnerType, selectedDirayDate, transitionSttsState } from 'src/utils/common/state/state'
import { PiTreasureChest } from 'react-icons/pi'
import { useDeleteDiary } from '../../diary/hooks/DiaryCreateCustomHook'
import DiaryMng from './DiaryMng'
import FullLoadingSpinner from 'src/components/common/container/FullLoadingSpinner'

moment.locale('ko')

const sample: CalendarEventType[] = [
  { date: '2024-07-17', id: 1 },
  {
    date: '2024-07-18',
    id: 2,
  },
]

export default function PlantDetailDiary() {
  const [transitionStts, setTransitionStts] = useRecoilState<string>(transitionSttsState)
  const [hasEvent, setHasEvent] = useState<boolean>(false)
  const { period, setPeriod, response, isPending, refetch } = useGetDirayList()

  const [selectedDate, setSelectedDate] = useRecoilState(selectedDirayDate)
  const [detailId, setDetailId] = useState<number | null>(null)

  const onPostFinish = () => {
    refetch()
  }

  const onRequestInfo = (date: Date) => {
    if (!response) return

    const formatDate = moment(date).format('YYYY-MM-DD')

    const matchedList = response.filter(item => formatDate === item.date)
    if (matchedList.length > 0 && matchedList[0].id) {
      setHasEvent(true)
      setDetailId(matchedList[0].id)
    } else {
      setDetailId(null)
      setHasEvent(false)
    }
  }

  const onChangeDate = (start: Date, end: Date) => {
    const stformat = moment(start).format('YYYY-MM-DD')
    const endformat = moment(end).format('YYYY-MM-DD')
    if (period.start !== stformat && period.end !== endformat && transitionStts.includes('EXITED')) {
      setPeriod({ start: stformat, end: endformat })
    }
  }

  useEffect(() => {
    if (response) {
      onRequestInfo(selectedDate.date)
    }
  }, [response])

  return (
    <>
      <SavedDiary
        events={response ? response : null}
        onChangeDate={onChangeDate}
        onRequestInfo={onRequestInfo}
        careArr={PLANT_CARE_DETAIL_SAMPE}
      />
      {!isPending && (
        <DiaryMng isPending={isPending} hasEvent={hasEvent} onPostFinish={onPostFinish} detailId={detailId} />
      )}
      {/* <FullLoadingSpinner isShow={isPending ? true : false} /> */}
    </>
  )
}
