import React, { useMemo } from 'react'
import DetailViewLabelLayout from 'src/components/common/layout/DetailViewLabelLayout'
import DiaryPlanList from './DiaryPlanList'
import styled from 'styled-components'
import moment from 'moment'
import WeeklyCalendarMain from 'src/components/common/calendar/WeeklyCalendarMain'
import CalendarMain from 'src/components/common/calendar/CalendarMain'
import NoDiary from './NoDiary'
import { Box } from '@mui/material'
import { CONTENT_PADDING } from 'src/utils/common/constants/constants'
import { CalendarEventType } from 'src/utils/common/type/type'

moment.locale('ko')

export default function SavedDiary({
  careArr,
  onChangeDate,
  events,
  onRequestInfo,
}: {
  careArr: string[]
  onChangeDate: (start: Date, end: Date) => void
  events: CalendarEventType[] | null
  onRequestInfo: (date: Date) => void
}) {
  // Memoize the CalendarMain component
  const calendarMain = useMemo(
    () => (
      <CalendarMain
        viewAll={false}
        onRequestInfo={onRequestInfo}
        readOnly={true}
        events={events}
        onChangeDate={onChangeDate}
      />
    ),
    [onChangeDate, events, onRequestInfo],
  )

  // Memoize the DiaryPlanList component
  const diaryPlanList = useMemo(() => <DiaryPlanList careArr={careArr} />, [careArr])

  return (
    <>
      <Box sx={{ p: CONTENT_PADDING, pb: 0, pt: 0 }}>{calendarMain}</Box>

      {/* <DetailViewLabelLayout marginTop="23px" menu={true} h2MarginBottom="18px" title="오늘의 일지">
        <DiaryWrap>
          {diaryPlanList}
          <DiaryContent
            width="100%"
            title="<무럭무럭>"
            content="오늘 나탈리는 무럭무럭 자랐다!! 짱귀욥당 오늘 나탈리는 무럭무럭 자랐다!! 짱귀욥당 오늘 나탈리는 무럭무럭 자랐다!! 짱귀욥당오늘 나탈리는 무럭무럭 자랐다!! 짱귀욥당오늘 나탈리는 무럭무럭 자랐다!! 짱귀욥당오늘 나탈리는 무럭무럭 자랐다!! 짱귀욥당오늘 나탈리는 무럭무럭 자랐다!! 짱귀욥당오늘 나탈리는 무럭무럭 자랐다!! 짱귀욥당"
          />
          <DiaryImgList />
        </DiaryWrap>
      </DetailViewLabelLayout> */}
    </>
  )
}

const DiaryWrap = styled.div`
  margin: 0px 24px 10px 24px;
`
