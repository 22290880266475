import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import FormMain from 'src/components/common/form/FormMain'
import HashTagList, { RecentKeyWordList } from 'src/components/common/form/sub/hashtag/HashTagList'

/*
    파일명 : RcntSearchedList.tsx
    설명 : 최근 본 식물 > 검색 리스트
    작성일 : 2024-06-16
*/

export interface RcntSearchedListType {
  watch: ReturnType<typeof useForm>['watch']
  getValues: ReturnType<typeof useForm>['getValues']
  setValue: ReturnType<typeof useForm>['setValue']
}
export default function RcntSearchedList({ watch, setValue, getValues }: RcntSearchedListType) {
  return <RecentKeyWordList watch={watch} getValues={getValues} setValue={setValue} name="hashtag" />
}
