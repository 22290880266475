/*
    파일명 : PlantInfoGuide.tsx
    설명 : 식물 상세 > 정보 > 관리 가이드
    작성일 : 2024.06.07
*/

import { PlantCareGuideSampleType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import PlantInfoElement from './PlantInfoElement'
import LoadingContainer from 'src/components/common/container/LoadingContainer'
import { Skeleton } from '@mui/material'

export default function PlantInfoGuide({
  careGuideList,
  mt = '0px',
}: {
  mt?: string
  careGuideList: PlantCareGuideSampleType[] | null
}) {
  if (careGuideList) {
    return (
      <GuideComp mt={mt}>
        {careGuideList.map((item: PlantCareGuideSampleType) => {
          return <PlantInfoElement key={item.id} careGuideObj={item} />
        })}
      </GuideComp>
    )
  } else {
    return <Skeleton variant="rounded" height="227px" width="100%" />
  }
}

export function GuideComp({ children, mt }: { mt: string; children: React.ReactNode }) {
  return <GuideWrap $mt={mt}>{children}</GuideWrap>
}

const GuideWrap = styled.div<{ $mt: string }>`
  width: 100%;

  padding: 12px 0px;

  background: white;
  border-radius: 7px;
  margin-top: ${({ $mt }) => $mt};
  > div {
    margin-bottom: 19px;
    &:last-of-type {
      /* 마지막 하위 div에 대한 스타일 */
      margin-bottom: 0; /* 예시로 margin을 0으로 설정 */
    }
  }

  /* Frame 490 */

  /* Auto layout */
`
