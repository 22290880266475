/*eslint-disable */

import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { getDataAdminTanstack, getDataTanstack, postDataTanstackWithToken } from 'src/utils/common/api/http'
import api from 'src/utils/common/api/useAxios'
import { useCheckTokenValidity } from 'src/utils/common/hooks/customHook'
import { castPlantTypeObj } from 'src/utils/common/scripts/checkType'
import { plantTypeType } from 'src/utils/common/type/type'

export const fetchPlantList = () => {
  const [keyword, setKeyword] = useState('')
  const [resultList, setResultList] = useState<plantTypeType[] | undefined>(undefined)
  const { checkData } = useCheckTokenValidity()
  function isPlantTypeTypeArray(arr: any): arr is plantTypeType[] {
    return (
      Array.isArray(arr) && arr.every(item => typeof item === 'object' && 'name' in item && 'thumbnail_url' in item)
    )
  }

  const { data, refetch, isLoading, isPending } = useQuery({
    queryKey: [
      'plant',
      {
        searchParam: 10,
        url: `${process.env.REACT_APP_API_GET_PLANT_TYPE_LIST}?keyword=${keyword}&page=0&size=`,
      },
    ],
    queryFn: getDataAdminTanstack,
    enabled: false,
  })

  useEffect(() => {
    if (data === undefined) {
      setResultList(undefined)
      syncKeyword()
    }
    if (data && data.data && typeof data.data === 'object' && (data.data as any).results) {
      if (castPlantTypeObj(data)) {
        setResultList((data.data as any).results)
      }
    }
  }, [data])

  useEffect(() => {
    if (keyword.length) {
      getPlantList()
    }
  }, [keyword])

  const getPlantList = async () => {
    await refetch()
  }

  const { mutate, isPending: isPenSync } = useMutation({
    mutationFn: postDataTanstackWithToken,
    onSuccess: response => {
      // if (keyword.length) {
      //   getPlantList()
      // }
    },
    onError: (error: Error) => {
      checkData(error)
    },
  })

  const syncKeyword = () => {
    const url = `${process.env.REACT_APP_API_DOMAIN}encyclo-service/sync?keyword=${keyword}`
    mutate({ url })
  }

  return { setKeyword, data, resultList, isLoading, isPending: isPending && isPenSync }
}
