import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/free-mode'
import styled from 'styled-components'

export default function SwiperListTwo({
  children,
  slidesPerView = 3.2,
  spaceBetween = 0.2,
  pagination = false,
  mt = '0px',
  mb = '0px',
  ml = '0px',
  height = '300px',
}: {
  slidesPerView?: number
  spaceBetween?: number
  pagination?: boolean
  children: React.ReactNode
  mt?: string
  ml?: string
  mb?: string
  height?: string
}) {
  if (pagination) {
    return (
      <SwiperContainer
        $mt={mt}
        $mb={mb}
        $height={height}
        freeMode={false}
        grabCursor={true}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        pagination={true}
      >
        {children}
      </SwiperContainer>
    )
  } else {
    return (
      <SwiperContainerWithioutPage
        $mt={mt}
        $mb={mb}
        $ml={ml}
        freeMode={false}
        grabCursor={true}
        modules={[FreeMode]}
        className="mySwiper"
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
      >
        {children}
      </SwiperContainerWithioutPage>
    )
  }
}

const SwiperContainer = styled(Swiper)<{ $mt: string; $mb: string; $height: string }>`
  margin-top: ${({ $mt }) => $mt};
  margin-bottom: ${({ $mb }) => $mb};
  // height: ${({ $height }) => $height};
  aspect-ratio: 1 / 1; /* 16:9 비율 설정 */
  * > .swiper-pagination-bullet-active {
    background: #ffffff;
  }

  .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    position: relative;
    bottom: 46px;
  }
`

const SwiperContainerWithioutPage = styled(Swiper)<{ $mt: string; $mb: string; $ml: string }>`
  margin-top: ${({ $mt }) => $mt};
  margin-bottom: ${({ $mb }) => $mb};
  margin-left: ${({ $ml }) => $ml};
`
