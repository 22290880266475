import { useMutation } from '@tanstack/react-query'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { queryClient } from 'src/App'
import { postDataJsonWithToken, postDataMultiPartWithToken } from 'src/utils/common/api/http'
import { useCheckTokenValidity, useValidationCheck } from 'src/utils/common/hooks/customHook'

/*
    일지 유효성 검사
*/
export const useFormModalValidateCheck = () => {
  const {
    checkInputText,
    selectedKey,
    checkCheckBox,
    isValidObj,
    confirmed,
    formAlert,

    checkTextArea,

    setValidCnt,
  } = useValidationCheck()

  useEffect(() => {
    setValidCnt(1)
  }, [])

  const checkTitle = (title: string) => {
    return checkInputText({
      value: title,
      name: 'title',
      label: '제목',
      regExp: '^[a-zA-Z가-힣0-9 ]{1,10}$',
    })
  }

  const { checkData } = useCheckTokenValidity()

  const {
    mutate,
    data: createDiaryResult,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: postDataJsonWithToken,
    onSuccess: data => {
      //  queryClient.ini
      queryClient.invalidateQueries({ queryKey: ['my-collection'] })
      // toast.success('도감 생성이 완료되었습니다.')
    },
    onError: (error: Error) => {
      checkData(error)
    },
  })

  const createForm = (title: string) => {
    const formData = {
      title: title,
    }

    const url = `${process.env.REACT_APP_API_DOMAIN}encyclo-service/my-encyclopedia`
    mutate({ formData, url })
  }

  return {
    selectedKey,
    isValidObj,
    confirmed,
    formAlert,
    isSuccess,
    checkTitle,
    checkCheckBox,
    checkTextArea,
    isPending,
    createForm,
  }
}

export const test = () => {}
