import { ChangeEvent, useRef } from 'react'
import { validateFile } from 'src/utils/common/scripts/common'
import iconImg from '../../../assets/images/icon/CamerIcon.png'
import iconImg2 from '../../../assets/images/icon/CameraIconCollection.png'
import styled from 'styled-components'

export default function CameraIcon({
  onAddFile,
  disabled = false,
  type,
  onClick,
}: {
  type: string
  disabled?: boolean
  onAddFile?: (file: File) => void
  onClick?: () => void
}) {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    // FileList를 배열로 변환
    const fileList = Array.from(event.target.files)

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i]
      // 첨부파일 검증
      if (!validateFile(file)) {
        handleResetFiles()
      } else {
        onAddFile?.(file)
      }
    }
  }

  const handleResetFiles = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }
  return (
    <>
      <input
        type="file"
        disabled={disabled}
        hidden
        ref={inputRef}
        accept="image/jpeg, image/png, image/jpg"
        onChange={handleUpload}
      />
      <Img
        className={type === 'COLLECTION' ? 'collection' : 'plant'}
        alt="사진 검색"
        src={type === 'COLLECTION' ? iconImg2 : iconImg}
        onClick={() => {
          if (disabled) {
            onClick?.()
          } else if (inputRef.current) {
            inputRef.current.click()
          }
        }}
      />
    </>
  )
}

const Img = styled.img`
  &.collection {
    height: 20px;
    width: 20px;
    margin-left: 14.5px;
  }
  &.plant {
    height: 16px;
    width: 20px;
  }

  &:hover {
    cursor: pointer;
  }
`
const svgStyle = {}
