import styles from '../../styles/AllAgree.module.css'
import agreeChk from '../../../../../../assets/images/icon/agreeChk.png'
import activeChk from '../../../../../../assets/images/icon/activeAgreeChk.png'

export default function AllAgree({
  title,
  checked,
  onChange,
}: {
  title: string
  checked: boolean
  onChange: () => void
}) {
  const handleImageClick = () => {
    onChange()
  }

  return (
    <div className={styles.div_all_agree_wrap}>
      <div className={styles.div_all_agree}>{title}</div>
      <div className={styles.div_chkbox}>
        <input type="checkbox" checked={checked} onChange={handleImageClick} hidden />
        <img src={checked ? activeChk : agreeChk} alt="동의" onClick={handleImageClick} style={{ cursor: 'pointer' }} />
      </div>
    </div>
  )
}
