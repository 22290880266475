import { dataObjArr } from 'src/pages/login/Login'
import SwipeList from '../../common/swiper/SwipeList'

import styles from './styles/MyPlantList.module.css'
import ContainerWithTitle from 'src/components/common/container/ContainerWithTitle'
import { getDataTanstackWithToken } from 'src/utils/common/api/http'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { plantTypeType } from 'src/utils/common/type/type'
import { isArrayOfTypeMyPlantType } from 'src/utils/common/scripts/common'

type MyPlantListType = {
  userName: string | null
  isPending: boolean
}

export default function MyPlantList({ userName, isPending }: MyPlantListType) {
  const {
    data,
    refetch,
    isSuccess,
    isPending: loadingList,
  } = useQuery({
    queryKey: [
      'my-plant',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}my-plant/plants/list`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
    staleTime: 300000,
  })

  useEffect(() => {
    refetch()
  }, [])
  if (isPending || loadingList) {
    return (
      <>
        <ContainerWithTitle title={null}>
          <SwipeList list={null} />
        </ContainerWithTitle>
      </>
    )
  }

  return (
    <ContainerWithTitle title="나의 정원">
      {(data?.myPlantResponseList && isArrayOfTypeMyPlantType(data?.myPlantResponseList) && (
        <SwipeList list={data?.myPlantResponseList} />
      )) || <SwipeList list={undefined} />}
    </ContainerWithTitle>
  )
}
