import styled from 'styled-components'
import InputWithButton from './InputWithButton'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import HashTagListLayout from 'src/components/common/layout/HashTagListLayout'

export interface InputHashTagProps {
  inputComp: JSX.Element | JSX.Element[]
  buttonComp: JSX.Element | JSX.Element[]
  styleId?: string | undefined
  watch: ReturnType<typeof useForm>['watch']
  getValues: ReturnType<typeof useForm>['getValues']
  setValue: ReturnType<typeof useForm>['setValue']
  name: string
  onDelete?: (newArr: string[]) => void
}

export default function InputHashTag({
  name,
  watch,
  setValue,
  getValues,
  inputComp,
  buttonComp,
  onDelete,
  styleId = 'div_hash',
}: InputHashTagProps) {
  const [toggle, setToggle] = useState(false)
  const [newArr, setNewArr] = useState([])

  useEffect(() => {
    const subscirbe = watch((data, { name: tgNm }) => {
      if (tgNm === name + 'Arr') {
        setToggle(prev => !prev)
        setNewArr(getValues(name + 'Arr'))
      }
    })
    return () => subscirbe.unsubscribe()
  }, [watch])

  return (
    <>
      <InputWithButton inputComp={inputComp} buttonComp={buttonComp} styleId={styleId} />
      {newArr ? (
        <HashTagListLayout
          onDelete={onDelete}
          setValue={setValue}
          getValues={getValues}
          name={name}
          hashTagArr={newArr}
        />
      ) : (
        <></>
      )}
    </>
  )
}
