import { useState } from 'react'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MotionUpLayout from 'src/components/common/layout/MotionUpLayout'
import MuiMain from 'src/components/common/mui/MuiMain'
import MarketForm from 'src/components/specific/green/market/MarketForm'

export default function GreenMarket() {
  const [isVisible, setIsVisible] = useState(true)
  return (
    <>
      <MotionUpLayout backPath="/green" isVisible={isVisible}>
        <ContainerLayout styleId="div_container_02">
          <MuiMain>
            <Content
              p={0}
              pt={0}
              pb={0}
              component={
                <>
                  <MarketForm
                    onClick={() => {
                      setIsVisible(false)
                    }}
                  />
                </>
              }
            />
          </MuiMain>
        </ContainerLayout>
      </MotionUpLayout>
    </>
  )
}
