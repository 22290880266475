import { ProfileContainer, ProfileDivComp, ProfileImg, ProfileWrap } from 'src/components/common/content/ProfileHeader'

import samlePrfImg from '../../../../assets/images/post/sampleProfile.png'
import DetailViewLabelLayout from 'src/components/common/layout/DetailViewLabelLayout'
import PageSubTitleTwo from 'src/components/common/content/PageSubTitleTwo'
import ForwardIcon from 'src/components/common/icon/ForwardIcon'
import GreenPostsList from './GreenPostsList'
import { GREEN_MARKET_POST_SUMMARY } from 'src/utils/common/constants/constants'
import LineOne from 'src/components/common/line/LineOne'

export default function GreenSellerPosts() {
  return (
    <>
      <ProfileContainer>
        <ProfileWrap>
          <ProfileImg profileSrc={samlePrfImg} />
          <ProfileDivComp level="1" userNm="멋진 농부" regFormat="후기 2   ·   거래 내역 3" />
        </ProfileWrap>
      </ProfileContainer>
      <PageSubTitleTwo
        marginTop="20px"
        iconComp={<ForwardIcon onClick={() => {}} />}
        marginBottom="25px"
        title="멋진 농부 님의 푸른 마켓"
      />
      <GreenPostsList itemArr={GREEN_MARKET_POST_SUMMARY} />
      <LineOne mt="23px" />
      <PageSubTitleTwo marginBottom="25px" title="혹시 이건 어때요?" />

      <GreenPostsList itemArr={GREEN_MARKET_POST_SUMMARY} />
    </>
  )
}
