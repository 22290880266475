import styled from 'styled-components'

/*
    파일명 : PageHeader.tsx
    설명 : 페이지 메인의 header 
    작성일 : 2024-06-11
    @param 
        
*/
type PageHeaderType = {
  iconComp: React.ReactNode
  title: string
  onClick?: () => void
}
export default function PageHeader({ onClick, iconComp, title }: PageHeaderType) {
  return (
    <Header>
      <h1>{title}</h1>
      <div
        onClick={() => {
          onClick?.()
        }}
      >
        {iconComp}
      </div>
    </Header>
  )
}

const Header = styled.div`
  width: 100%;
  display: flex;
  place-content: space-between;
  align-items: center;

  h1 {
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    letter-spacing: -0.05em;

    color: #1c1c1c;
  }
`
