import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { postDataJsonWithToken } from 'src/utils/common/api/http'
import { useCheckTokenValidity } from 'src/utils/common/hooks/customHook'
import { validateNullCheck, validateRegExpCheck } from 'src/utils/common/scripts/validateCheck'
import { validateCheckType } from 'src/utils/common/type/type'

export function useCreateNewPlantType() {
  const [confirmed, setConfirmed] = useState(false)
  const [requestFinish, setRequestFinish] = useState(false)
  const [formAlert, setFormAlert] = useState<{
    scName: string
    nmName: string
  }>({
    scName: '',
    nmName: '',
  })

  const [isValidObj, setIsValidObj] = useState<{
    scName: undefined | boolean
    nmName: undefined | boolean
  }>({
    scName: undefined,
    nmName: undefined,
  })

  useEffect(() => {
    const values = Object.values(isValidObj)

    // 배열의 every 메서드를 사용하여 모든 값이 true인지 확인합니다.
    const allTrue = values.every(value => value === true)
    if (allTrue) {
      setConfirmed(true)
    } else {
      setConfirmed(false)
    }
  }, [isValidObj])

  const setTrue = (key: string) => {
    updateFormAlert(key, '')
    updateIsValid(key, true)
  }
  const setFalse = (key: string, text: string) => {
    updateFormAlert(key, text)
    updateIsValid(key, false)
  }

  const updateFormAlert = (key: string, val: string | boolean | undefined) => {
    setFormAlert(prev => {
      let newVal = { ...prev }
      newVal = {
        ...prev,
        [key]: val,
      }
      return newVal
    })
  }

  const updateIsValid = (key: string, val: string | boolean | undefined) => {
    setIsValidObj(prev => {
      let newVal = { ...prev }
      newVal = {
        ...prev,
        [key]: val,
      }
      return newVal
    })
  }

  // 필수 값 null 체크
  function nullCheck(checkObj: {
    targetObj: { [key: string]: string | number }
    nameArr: string[]

    discription: string
  }) {
    const validCheck: validateCheckType = validateNullCheck(checkObj.targetObj, checkObj.nameArr)
    const isValid = checkValidation(validCheck, checkObj.discription)
    return isValid
  }

  function checkValidation(validCheck: validateCheckType, discription: string) {
    if (!validCheck.isValid) {
      if (validCheck.unValidKey.length) {
        const name = validCheck.unValidName
        setFalse(name, discription)
      }
    }
    return validCheck.isValid
  }

  function regExpCheck(checkObj: {
    targetObj: { [key: string]: string }
    nameArr: string[]
    regExpArr: string[]
    discription: string
  }) {
    const validCheck: validateCheckType = validateRegExpCheck(checkObj.targetObj, checkObj.nameArr, checkObj.regExpArr)
    const isValid = checkValidation(validCheck, checkObj.discription)
    return isValid
  }

  function checkScName(name: string) {
    let isValid = nullCheck({
      targetObj: {
        name,
      },
      nameArr: ['scName'],
      discription: '학명을 입력해주세요',
    })
    if (!isValid) return isValid
    isValid = regExpCheck({
      targetObj: {
        name,
      },
      nameArr: ['scName'],
      regExpArr: ['^[a-zA-Z0-9 ]{1,30}$'],
      discription: '학명을 정확히 입력해주세요',
    })
    if (isValid) {
      setTrue('scName')
    }
    return isValid
  }

  function checkNmName(name: string) {
    let isValid = nullCheck({
      targetObj: {
        name,
      },
      nameArr: ['nmName'],
      discription: '일반명을 입력해주세요',
    })
    if (!isValid) return isValid
    isValid = regExpCheck({
      targetObj: {
        name,
      },
      nameArr: ['nmName'],
      regExpArr: ['^[a-zA-Z가-힣0-9 ]{1,10}$'],
      discription: '일반명을 정확히 입력해주세요',
    })
    if (isValid) {
      setTrue('nmName')
    }
    return isValid
  }

  const { checkData } = useCheckTokenValidity()
  const {
    mutate,
    data: createDiaryResult,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: postDataJsonWithToken,
    onSuccess: data => {
      //  queryClient.ini
      toast.success('식물 추가 요청이 완료되었습니다.')
      setRequestFinish(true)
    },
    onError: (error: Error) => {
      toast.success('식물 추가 요청이 실패하였습니다.')
      setRequestFinish(true)
      checkData(error)
    },
  })

  const createForm = (data: { commonName: string; scientificName: string }) => {
    const formData = {
      commonName: 'string',
      scientificName: 'string',
    }

    const url = `${process.env.REACT_APP_API_DOMAIN}encyclo-service/plant/inquiry`
    mutate({ formData, url })
  }

  return {
    createForm,
    requestFinish,
    isPending,
    checkNmName,
    checkScName,
    formAlert,
    isValidObj,
    confirmed,
    setConfirmed,
  }
}

export const test = () => {}
