import styled from 'styled-components'
import iconImg from '../../../assets/images/icon/plusBtnIcon.png'
/*
    파일명 : PlusIcon.tsx
    설명 : 버튼에 들어가는 icon 컴포넌트
    작성일 : 2024-08-23
*/

export default function PlusIcon({ size, margin }: { size: number; margin: string }) {
  return (
    <>
      <IconComp $margin={margin} $size={size} src={iconImg} alt="추가" />
    </>
  )
}

const IconComp = styled.img<{ $size: number; $margin: string }>`
  width: ${({ $size }) => `${$size}px`};
  height: ${({ $size }) => `${$size}px`};
  margin: ${({ $margin }) => $margin};
`
