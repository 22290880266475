import styled from 'styled-components'

import { SwiperSlide } from 'swiper/react'

import SwiperListAutoWidth from '../swiper/SwiperListAutoWidth'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import LoadingContainer from '../container/LoadingContainer'

type HashTagListLayout = {
  hashTagArr: string[] | null
}

type HashTagItemType = {
  item: string | null
}

export default function HashTagListProfile({ hashTagArr }: HashTagListLayout) {
  if (!hashTagArr) {
    return (
      <SwiperListAutoWidth>
        <SwiperSlide key={generateRandomKey()} style={{ width: 'auto' }}>
          <HashTag item={null} />
        </SwiperSlide>
        <SwiperSlide key={generateRandomKey()} style={{ width: 'auto' }}>
          <HashTag item={null} />
        </SwiperSlide>
      </SwiperListAutoWidth>
    )
  } else {
    return (
      <SwiperListAutoWidth>
        {hashTagArr.map((item: string) => {
          return (
            <SwiperSlide key={item} style={{ width: 'auto' }}>
              <HashTag item={item} />
            </SwiperSlide>
          )
        })}
      </SwiperListAutoWidth>
    )
  }
}

export function HashTag({ item }: HashTagItemType) {
  if (!item) {
    return (
      <HashTagWrap>
        <LoadingContainer styleId="hash" />
      </HashTagWrap>
    )
  } else {
    return (
      <HashTagWrap>
        <HashTagDiv>{item}</HashTagDiv>
      </HashTagWrap>
    )
  }
}

const HashTagWrap = styled.div`
  display: flex;
  margin-right: 14px;
  margin-top: 7px;
`

const HashTagDiv = styled.div`
  justify-content: center;
  align-items: center;

  align-content: center;
  text-align: center;

  width: fit-content;
  padding-right: 17px;
  padding-left: 17px;
  height: 33px;

  background: #f9f9f9;
  border-radius: 7px;

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: -0.05em;

  color: #1c1c1c;

  color: #1c1c1c;

  /* Frame 6 */

  border-radius: 77px;
`
