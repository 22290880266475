import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import styles from '../styles/RegActiveBtn.module.css'

export default function RegActiveCustomBtn({
  title,
  onClick,
  styleID,
}: {
  styleID: string
  title: string
  onClick?: () => void
}) {
  return (
    <div
      onClick={() => {
        onClick?.()
      }}
      className={`${divideStyleIDString(styles, styleID)}`}
    >
      {title}
    </div>
  )
}
