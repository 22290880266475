/*
    파일명 : MiddleContainerTwo.tsx
    설명 : 관련글 목록 박스 같은 곳에 사용
    작성일 : 2024-06-14    
*/

import styled from 'styled-components'

type MiddleContainerTwo = {
  children: React.ReactNode
  mt?: string
}

export default function MiddleContainerTwo({ children, mt = '0px' }: MiddleContainerTwo) {
  return <Container $mt={mt}>{children}</Container>
}

const Container = styled.div<{ $mt: string }>`
  box-sizing: border-box;
  margin-top: ${({ $mt }) => $mt};

  width: 100%;
  padding-bottom: 0px;

  // aspect-ratio: 1.1068 / 1;

  border: none;
  border-radius: 7px;
`
