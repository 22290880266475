import styles from './styles/ScrollButton.module.css'
import RegBtn from '../../../assets/images/icon/RegBtn.png'
import RegActiveBtn from '../../../assets/images/icon/RegActiveBtn.png'
import { useEffect, useState } from 'react'
import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import RegActiveCustomBtn from 'src/components/specific/main/sub/RegActiveCustomBtn'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

type ScrollButtonType = {
  btnOneTitle: string
  btnTwoTitle: string
  btnOnePath: string
  btnTwoPath: string
}

export default function ScrollButton({ btnOneTitle, btnTwoTitle, btnOnePath, btnTwoPath }: ScrollButtonType) {
  const [activeBtn, setActiveBtn] = useState(false)
  const navigate = useNavigate()

  let styleId = 'div_btn_wrap'
  if (activeBtn) {
    styleId += ' active'
  }

  const onClickBtnTwo = () => {
    navigate(btnTwoPath)
  }

  const onClickBtnOne = () => {
    navigate(btnOnePath)
  }

  const buttonVariants = {
    hidden: { x: 100, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  }

  return (
    <WrapDiv>
      <AnimatePresence>
        {activeBtn && (
          <>
            <motion.div
              className={styles.backdrop}
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
            />
            <MotionDiv
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={buttonVariants}
              transition={{ duration: 0.5 }}
              onClick={onClickBtnTwo}
            >
              <RegActiveCustomBtn styleID="btn_div_02" title={btnTwoTitle} />
            </MotionDiv>
            <MotionDiv
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={buttonVariants}
              transition={{ duration: 0.5, delay: 0.2 }}
              onClick={onClickBtnOne}
            >
              <RegActiveCustomBtn styleID="btn_div" title={btnOneTitle} />
            </MotionDiv>
          </>
        )}
      </AnimatePresence>

      <div
        className={`${divideStyleIDString(styles, styleId)}`}
        onClick={() => {
          setActiveBtn(prev => !prev)
        }}
      >
        <img src={activeBtn ? RegActiveBtn : RegBtn} alt="등록버튼" />
      </div>
    </WrapDiv>
  )
}

const WrapDiv = styled.div`
  position: fixed;
  bottom: 90px;
  width: 100%;
  max-width: 390px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 10;
  pointer-events: none;
`

const MotionDiv = styled(motion.div)`
  pointer-events: auto;
  z-index: 12;
`
