import BackIcon from 'src/components/common/icon/BackIcon'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MuiMain from 'src/components/common/mui/MuiMain'
import DiaryDetailView from 'src/components/specific/diary/sub/DiaryDetailView'

export default function DiaryDetail() {
  return (
    <ContainerLayout styleId="div_container_02">
      <MuiMain>
        <Content
          h="150vh"
          p={0}
          pt={0}
          pb={0}
          component={
            <>
              <DiaryDetailView />
            </>
          }
        />
      </MuiMain>
    </ContainerLayout>
  )
}
