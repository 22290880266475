/*
    파일명 : PlanDttmValidateCaption.tsx
    설명 : 일정 등록/수정 > 시간 설정 > 유효성 text
    작성일 : 2024-09-15
*/

import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { planDttmValidateObj } from 'src/utils/common/type/type'

type PlanDttmValidateCaptionProps = {
  getValues: ReturnType<typeof useForm>['getValues']
  watch: ReturnType<typeof useForm>['watch']
  checkDt: (dateObj: planDttmValidateObj) => boolean
  hidden: boolean
}

export default function PlanDttmValidateCaption({ hidden, checkDt, watch, getValues }: PlanDttmValidateCaptionProps) {
  const [isValidDttm, setIsValidDttm] = useState<boolean>(true)
  const onChangeDttm = () => {
    if (getValues('endDt') && getValues('startDt') && getValues('endTm') && getValues('startTm')) {
      const valid = checkDt({
        endDt: getValues('endDt'),
        endTm: getValues('endTm'),
        startDt: getValues('startDt'),
        name: 'startDt',
        startTm: getValues('startTm'),
      })

      setIsValidDttm(valid)
    }
  }

  useEffect(() => {
    const subscription = watch(data => {
      if (data.endDt) {
        onChangeDttm()
      }
      if (data.startDt) {
        onChangeDttm()
      }
      if (data.endTm) {
        onChangeDttm()
      }
      if (data.startTm) {
        onChangeDttm()
      }
    })

    return () => subscription.unsubscribe()
  }, [watch])

  if (hidden) {
    return <></>
  }

  return (
    <>
      {isValidDttm ? (
        <></>
      ) : (
        <>
          <P>종료일이 시작일보다 앞서야 합니다.</P>
        </>
      )}
    </>
  )
}

const P = styled.div`
  /* 인증번호를 다시 확인해주세요. */

  margin-top: 10px;
  margin-left: 3.6%;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #ff0000;
`
