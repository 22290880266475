import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { COLOR_SELECT } from 'src/utils/common/constants/constants'
import styled from 'styled-components'
import colorSelected from '../../../../../assets/images/form/colorSelected.png'
import { generateRandomKey } from 'src/utils/common/scripts/common'

type ColorChipsType = {
  setValue: ReturnType<typeof useForm>['setValue']
  register: ReturnType<typeof useForm>['register']
  getValues: ReturnType<typeof useForm>['getValues']
  defaultVal: string
  colorChipArr: string[]
  onClick?: (index: number) => void
  lineMaxQuantity?: number
}

export default function ColorChips({
  lineMaxQuantity,
  onClick,
  colorChipArr,
  getValues,
  setValue,
  register,
  defaultVal,
}: ColorChipsType) {
  const [toggle, setToggle] = useState(false)
  useEffect(() => {
    register('color')
    setValue('color', defaultVal)
    setToggle(prev => !prev)
  }, [])
  if (!lineMaxQuantity) {
    return (
      <Wrap>
        {colorChipArr.map((item, index) => {
          return (
            <div key={`${item}123`}>
              <Div
                onClick={() => {
                  setValue('color', item)
                  setToggle(prev => !prev)
                  onClick?.(index)
                }}
                $bc={item}
              >
                <>{getValues('color') === item && <Selected src={colorSelected} alt="선택됨" />}</>
              </Div>
            </div>
          )
        })}
      </Wrap>
    )
  } else {
    return (
      <>
        <Wrap>
          {colorChipArr.slice(0, lineMaxQuantity).map((item, index) => {
            return (
              <div key={`${item}123`}>
                <TowLineDiv
                  onClick={() => {
                    setValue('color', item)
                    setToggle(prev => !prev)
                    onClick?.(index)
                  }}
                  $bc={item}
                >
                  <>{getValues('color') === item && <Selected src={colorSelected} alt="선택됨" />}</>
                </TowLineDiv>
              </div>
            )
          })}
        </Wrap>
        <BottomWrap>
          {colorChipArr.slice(lineMaxQuantity).map((item, index) => {
            return (
              <div key={`${item}123`}>
                <TowLineDiv
                  onClick={() => {
                    setValue('color', item)
                    setToggle(prev => !prev)
                    onClick?.(index)
                  }}
                  $bc={item}
                >
                  <>{getValues('color') === item && <Selected src={colorSelected} alt="선택됨" />}</>
                </TowLineDiv>
              </div>
            )
          })}
        </BottomWrap>
      </>
    )
  }
}

const Selected = styled.img`
  width: 18px;
  height: 18px;
`
const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
`
const BottomWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`

const Div = styled.div<{ $bc: string }>`
  /* Rectangle 319 */

  width: 40px;
  height: 40px;

  background: ${({ $bc }) => $bc};
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  display: flex;

  &:hover {
    cursor: pointer;
  }
`

const TowLineDiv = styled.div<{ $bc: string }>`
  /* Rectangle 319 */

  width: 70px;
  height: 50px;

  background: ${({ $bc }) => $bc};
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  display: flex;

  &:hover {
    cursor: pointer;
  }
`
