/*
    파일명 : PlazaCreate.tsx
    설명 : 식물 광장 > 질문하기, 자랑하기
    작성일 : 2024-06-10
*/

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MotionUpLayout from 'src/components/common/layout/MotionUpLayout'
import MuiMain from 'src/components/common/mui/MuiMain'
import Title from 'src/components/common/title/Title'
import PlazaCreateForm from 'src/components/specific/plaza/create/PlazaCreateForm'

export default function PlazaCreate() {
  const path = useLocation()

  const [isVisible, setIsVisible] = useState(true)

  let initialTitle = ''
  if (path.pathname.includes('asking')) {
    initialTitle = '질문하기'
  } else {
    initialTitle = '식물 자랑하기'
  }
  const [title, setTitle] = useState(initialTitle)

  useEffect(() => {
    if (path.pathname.includes('asking')) {
      setTitle('질문하기')
    } else {
      setTitle('식물 자랑하기')
    }
  }, [path])
  return (
    <>
      <MotionUpLayout backPath="/plaza" isVisible={isVisible}>
        <ContainerLayout styleId="div_container_02">
          <MuiMain>
            <Title
              styleID="div_container"
              onClick={() => {
                setIsVisible(false)
              }}
              close={true}
              title={title}
              comp={
                <>
                  <p>임시저장</p>
                </>
              }
            />
            <Content
              component={
                <PlazaCreateForm
                  openProp={false}
                  onPostSuccess={() => {}}
                  updatePost={undefined}
                  formType={title === '질문하기' ? 'ASKING' : 'BOASTING'}
                />
              }
            />
          </MuiMain>
        </ContainerLayout>
      </MotionUpLayout>
    </>
  )
}
