import styled from 'styled-components'
import Alert from '../modal/Alert'
import styles from '../modal/Modal.module.css'
import { motion } from 'framer-motion'
import { createPortal } from 'react-dom'
import { Backdrop, CircularProgress } from '@mui/material'
import { useRecoilState } from 'recoil'
import { LodingSpinnerType } from 'src/utils/common/state/state'
import { useEffect, useState } from 'react'

export default function FullLoadingSpinner({ isShow, onClose }: { isShow: boolean; onClose?: () => void }) {
  // const [isShow, setIsShow] = useRecoilState(LodingSpinnerType)

  const [show, setShow] = useState(false)
  useEffect(() => {
    setShow(isShow)
  }, [isShow])

  return (
    <>
      <>
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={show}
          onClick={() => {
            // setShow(false)
          }}
        >
          <CircularProgress style={{ visibility: 'unset' }} />
        </Backdrop>
      </>
    </>
  )
}
const LayoutContainer = styled(motion.div)`
  justify-content: center;
  position: relative;
  width: 390px;
  height: 100%;
`
