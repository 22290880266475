import { PlazaPostSampleType, PostType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import HashTagList from './HashTagList'
import PostImages from './PostImages'
import { useState } from 'react'
import { Skeleton } from '@mui/material'

type PostContentType = {
  post: PostType | undefined
  onClickItem: (item: string) => void
}

export default function PostContent({ post, onClickItem }: PostContentType) {
  const [readAll, setReadAll] = useState(false)

  if (!post) {
    return (
      <>
        <Wrap>
          <Skeleton variant="text" />
          <Skeleton variant="text" sx={{ marginTop: '8px' }} />
        </Wrap>
      </>
    )
  }

  return (
    <Wrap>
      {post.imageUrls && post.imageUrls.length > 0 && <PostImages srcArr={post.imageUrls} />}
      <h2>{post.title}</h2>
      <Content>{post.content.length > 60 && !readAll ? post.content.slice(0, 60) : post.content}</Content>
      {post.content.length > 60 && !readAll && (
        <ReadMore
          onClick={() => {
            setReadAll(true)
          }}
        >
          ... 더보기
        </ReadMore>
      )}
      {post.hashtagList && post.hashtagList.length > 0 && (
        <HashTagWrap>
          <HashTagList onClickItem={onClickItem} hashTagArr={post.hashtagList} />
        </HashTagWrap>
      )}
    </Wrap>
  )
}

export function CommentContent({ content }: { content: string }) {
  return (
    <CommentWrap>
      <Content>{content}</Content>
    </CommentWrap>
  )
}

const CommentWrap = styled.div`
  width: 100%;
  margin-top: 2px;
`
const HashTagWrap = styled.div`
  margin-top: 8px;
`
const Wrap = styled.div`
  width: 100%;
  margin-top: 8px;

  h2 {
    /* 잎이 다 떨어졌는데 왜 그런 걸까요?? */

    /* Body 01-M */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }
`

const Content = styled.div`
  margin-top: 8px;
  /* 지난주까지만 해도 괜찮았는데 점점 시들어가더니 잎이 거의 다 떨어졌네요ㅜㅜ 영양제를 넣어줘야 할까요?? 아님 */

  /* Body 01-R */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.02em;

  /* Black */
  color: #242424;
  white-space: pre-wrap;
`

const ReadMore = styled.p`
  /* ... 더보기 */

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: -0.05em;

  color: #d9d9d9;
  margin-top: 8px;
  &:hover {
    cursor: pointer;
  }
`
