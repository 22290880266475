import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { queryClient } from 'src/App'

import {
  deleteDataTanstackWithToken,
  getDataTanstackWithToken,
  postDataJsonWithTokenExceptBody,
  postDataMultiPartWithToken,
} from 'src/utils/common/api/http'
import { useCheckTokenValidity, useValidationCheck } from 'src/utils/common/hooks/customHook'
import { castPostListType, isObject } from 'src/utils/common/scripts/checkType'
import { isArrayOfTypeMyPlantType } from 'src/utils/common/scripts/common'
import { myPlantType, PostType } from 'src/utils/common/type/type'

export const useUpdateProfile = () => {
  const {
    checkInputText,
    selectedKey,
    checkTextAreaWithMaxAndReg,
    checkCheckBox,
    isValidObj,
    confirmed,
    formAlert,
    nullCheck,
    regExpCheck,
    setTrue,
    checkTextArea,
    checkTextAreaWithMax,
    checkFileCnt,
    setValidCnt,
  } = useValidationCheck()

  const checkTitle = (title: string) => {
    return checkTextAreaWithMaxAndReg({
      value: title,
      name: 'title',
      label: '닉네임',
      regExp: '^[a-zA-Z가-힣0-9 ]{0,12}$',
    })
  }

  useEffect(() => {
    setValidCnt(2)
  }, [])

  const checkContent = (content: string) => {
    return checkTextAreaWithMax({
      max: 3000,
      value: content,
      name: 'content',
      label: '내용',
    })
  }

  const { checkData } = useCheckTokenValidity()
  const navigate = useNavigate()
  const {
    mutate,
    data: createDiaryResult,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: postDataMultiPartWithToken,
    onSuccess: data => {
      toast.success('프로필 수정이 완료되었습니다.')
      //     queryClient.invalidateQueries({ queryKey: ['my-info'] })
    },
    onError: (error: Error) => {
      checkData(error)
    },
    onSettled: async () => {
      await queryClient.cancelQueries({ queryKey: ['my-info'] })
      queryClient.invalidateQueries({ queryKey: ['my-info'] })
      navigate('/profile/myprofile', { state: { direction: 'na' } })
    },
  })

  const createData = (
    data: {
      nickName: string | null
      indroduction: string | null
      lat: number | null
      lon: number | null
      isPicUploaded: boolean
    },
    form: FormData,
  ) => {
    const formData = {
      nickName: data.nickName,
      introduction: data.indroduction,
      latitude: data.lat,
      longitude: data.lon,
      isPicUploaded: data.isPicUploaded,
    }

    form.append('nickName', `${data.nickName}`)
    form.append('introduction', `${data.indroduction}`)
    form.append('latitude', `${data.lat}`)
    form.append('longitude', `${data.lon}`)
    form.append('isPicUploaded', `${data.isPicUploaded}`)

    const url = `${process.env.REACT_APP_API_DOMAIN}auth-service/member`
    mutate({ formData: form, url })
  }

  return {
    createData,
    checkTitle,
    isValidObj,
    confirmed,
    checkContent,
    formAlert,
    isSuccess,
    isError,
  }
}

export const useGetPlantList = () => {
  const [list, setList] = useState<myPlantType[] | undefined>()

  const {
    data,
    refetch,
    isSuccess,
    isPending: loadingList,
  } = useQuery({
    queryKey: [
      'my-plant',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}my-plant/plants/list`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
    staleTime: 300000,
  })

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (data?.myPlantResponseList && isArrayOfTypeMyPlantType(data?.myPlantResponseList)) {
      setList(data?.myPlantResponseList)
    }
  }, [data])

  return {
    loadingList,
    list,
  }
}

export const useGetProfilePlantList = () => {
  const { userId } = useParams()

  const [firstFetch, setFirstFetch] = useState(false)
  const [list, setList] = useState<myPlantType[] | undefined>(undefined)

  const {
    data,
    refetch,
    isSuccess,
    isPending: loadingList,
  } = useQuery({
    queryKey: [
      'my-plant-profile',
      {
        searchParam: userId && userId !== 'myprofile' && typeof Number(userId) === 'number' ? `/${userId}` : '',
        url: `${process.env.REACT_APP_API_DOMAIN}my-plant/plants/list`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,

    // staleTime: 300000,
  })
  useEffect(() => {
    if (data?.myPlantResponseList && isArrayOfTypeMyPlantType(data?.myPlantResponseList)) {
      setList(data?.myPlantResponseList)
    }
  }, [data])

  const requestFirstFetch = () => {
    setFirstFetch(true)
  }

  useEffect(() => {
    refetch()
  }, [])

  return {
    requestFirstFetch,
    loadingList,
    list,
    isSuccess,
  }
}

export function useGetProfilePosts() {
  const [list, setList] = useState<PostType[] | undefined>(undefined)
  const { userId } = useParams()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(4)

  /* 데이터 api */
  const {
    data: myPosts,
    refetch: getMyPosts,
    isSuccess: isSuccessMyPosts,
    isPending: gettingMyPosts,
    isError: isErrorMyPosts,
  } = useQuery({
    queryKey: [
      'board-list-my-posts',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}board-service/common/list/members?page=${page}&size=${size}&sort=createdAt%2CDESC`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  const {
    data: userPosts,
    refetch: getUserPosts,
    isSuccess: isSuccessUserPosts,
    isLoading: gettingUserPosts,
    isError: isErrorUserPosts,
  } = useQuery({
    queryKey: [
      'board-list-user-posts',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}board-service/common/list/members/${userId}?page=${page}&size=${size}&sort=createdAt%2CDESC`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  useEffect(() => {
    requestApi()
  }, [page])

  const changePage = () => {
    const requestType = getRequestingType()

    if (requestType === 'UNSET') return
    console.log('requestType : ' + requestType)
    const num = page + 1
    console.log('nextPage : ' + num)
    if (checkIsPending(requestType)) return

    const count = num * size // 1 * 1
    console.log('count : ' + count)
    console.log('length : ' + (list ? list.length : 0))

    if (list && list.length === count) {
      console.log('setPage')
      setPage(num)
    }
  }

  const checkIsPending = (requestType: string) => {
    let result = false

    switch (requestType) {
      case 'COMMENT_USER':
        if (gettingUserPosts) {
          result = true
        }
        break
      case 'COMMENT_MINE':
        if (gettingMyPosts) {
          result = true
        }
        break
      default:
        result = true
        break
    }
    console.log('result : ' + result)
    return result
  }

  const getRequestingType = () => {
    let result = 'UNSET'

    if (userId && !userId.includes('myprofile')) {
      result = 'COMMENT_USER'
    } else {
      result = 'COMMENT_MINE'
    }

    return result
  }

  const requestApi = () => {
    const requestType = getRequestingType()
    if (requestType === 'COMMENT_MINE') {
      console.log('requesting mine')
      getMyPosts()
    } else if (requestType === 'COMMENT_USER') {
      console.log('requesting user')
      getUserPosts()
    }
  }

  /* 데이터 변환 */

  useEffect(() => {
    castData(userPosts, 'COMMENT_USER')
  }, [userPosts])
  useEffect(() => {
    castData(myPosts, 'COMMENT_MINE')
  }, [myPosts])

  const castData = (data: any, type: string) => {
    const requestType = getRequestingType()
    if (type !== requestType) return
    if (data && data?.commonArticleResponseList) {
      const newObj = castPostListType(data)

      if (list === null || list === undefined) {
        setList(newObj?.commonArticleResponseList)
      } else {
        setList(prev => {
          if (page === 0) {
            return newObj?.commonArticleResponseList
          }

          if (prev && newObj) {
            return prev?.concat(newObj?.commonArticleResponseList)
          } else {
            return newObj?.commonArticleResponseList
          }
        })
      }
    }
  }
  return {
    list,

    changePage,

    isPending: gettingMyPosts,
    isPendingUser: gettingUserPosts,
    isMyProfile: getRequestingType() === 'COMMENT_MINE' ? true : false,
  }
}

export function useGetMyProfilePosts() {
  const [list, setList] = useState<PostType[] | null | undefined>(null)

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const changePage = () => {
    const num = page + 1
    console.log('nextPage : ' + num)
    if (gettingMyPosts) return

    const count = num * size // 1 * 1
    console.log('count : ' + count)
    console.log('length : ' + (list ? list.length : 0))

    if (list && list.length === count) {
      console.log('setPage')
      setPage(prev => prev + 1)
    }
  }

  /* 데이터 api */
  const {
    data: myPosts,
    refetch: getMyPosts,
    isSuccess: isSuccessMyPosts,
    isLoading: gettingMyPosts,
    isError: isErrorMyPosts,
  } = useQuery({
    queryKey: [
      'board-list-my-posts',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}board-service/common/list/members?page=${page}&size=${size}&sort=createdAt%2CDESC`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  useEffect(() => {
    if (myPosts && myPosts?.commonArticleResponseList) {
      const newObj = castPostListType(myPosts)

      if (list === null || list === undefined) {
        setList(newObj?.commonArticleResponseList)
      } else {
        setList(prev => {
          if (page === 0) {
            return newObj?.commonArticleResponseList
          }

          if (prev && newObj) {
            return prev?.concat(newObj?.commonArticleResponseList)
          } else {
            return newObj?.commonArticleResponseList
          }
        })
      }
    }
  }, [myPosts])

  const castData = (data: any) => {
    if (data && data?.commonArticleResponseList) {
      const newObj = castPostListType(data)

      if (list === null || list === undefined) {
        setList(newObj?.commonArticleResponseList)
      } else {
        setList(prev => {
          if (page === 0) {
            return newObj?.commonArticleResponseList
          }

          if (prev && newObj) {
            return prev?.concat(newObj?.commonArticleResponseList)
          } else {
            return newObj?.commonArticleResponseList
          }
        })
      }
    }
  }

  useEffect(() => {
    console.log('requesting')

    getMyPosts()
  }, [page])

  return {
    list,

    changePage,

    isPending: gettingMyPosts,
  }
}

export function useGetProfilePostsLiked() {
  const [list, setList] = useState<PostType[] | undefined>(undefined)
  const { userId } = useParams()

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const {
    data: PostsILiked,
    refetch: getPostsILiked,
    isSuccess: isSuccessPostsILiked,
    isPending: gettingPostsILiked,
    isError: isErroPostsILiked,
  } = useQuery({
    queryKey: [
      'board-list-i-liked',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}board-service/common/list/members/likes?page=${page}&size=${size}&sort=createdAt%2CDESC`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  const {
    data: PostsuserLiked,
    refetch: getPostsuserLiked,
    isSuccess: isSuccessPostsuserLiked,
    isLoading: isPendingPostsuserLiked,
    isError: isErrorPostsuserLiked,
  } = useQuery({
    queryKey: [
      'board-list-user-liked',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}board-service/common/list/members/likes/${userId}?page=${page}&size=${size}&sort=createdAt%2CDESC`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  /* 데이터 변환 */
  useEffect(() => {
    castData(PostsILiked, 'GOOD_MINE')
  }, [PostsILiked])

  useEffect(() => {
    castData(PostsuserLiked, 'GOOD_USER')
  }, [PostsuserLiked])

  const castData = (data: any, type: string) => {
    const requestType = getRequestingType()
    if (type !== requestType) return
    if (data && data?.commonArticleResponseList) {
      const newObj = castPostListType(data)

      if (list === null || list === undefined) {
        setList(newObj?.commonArticleResponseList)
      } else {
        setList(prev => {
          if (page === 0) {
            return newObj?.commonArticleResponseList
          }

          if (prev && newObj) {
            return prev?.concat(newObj?.commonArticleResponseList)
          } else {
            return newObj?.commonArticleResponseList
          }
        })
      }
    }
  }

  useEffect(() => {
    requestApi()
  }, [page])

  const changePage = () => {
    const requestType = getRequestingType()

    if (requestType === 'UNSET') return

    const num = page + 1
    if (checkIsPending(requestType)) return
    const count = num * size // 1 * 1
    console.log(list ? list.length : 0)

    if (list && list.length === count) {
      setPage(num)
    }
  }

  const checkIsPending = (requestType: string) => {
    let result = false

    switch (requestType) {
      case 'GOOD_USER':
        if (isPendingPostsuserLiked) {
          result = true
        }

        break
      case 'GOOD_MINE':
        if (gettingPostsILiked) {
          result = true
        }
        break

      default:
        result = true
        break
    }
    return result
  }

  const getRequestingType = () => {
    let result = 'UNSET'

    if (userId && !userId.includes('myprofile')) {
      result = 'GOOD_USER'
    } else {
      result = 'GOOD_MINE'
    }

    return result
  }

  const requestApi = () => {
    // if (!postType || !size) return

    const requestType = getRequestingType()
    if (requestType === 'GOOD_USER') {
      getPostsuserLiked()
    } else if (requestType === 'GOOD_MINE') {
      getPostsILiked()
    }
  }

  return {
    list,

    changePage,

    isPending: gettingPostsILiked,
    isPendingUser: isPendingPostsuserLiked,
    isMyProfile: getRequestingType() === 'GOOD_MINE' ? true : false,
  }
}

export const useFollow = () => {
  const { userId } = useParams()
  const { checkData } = useCheckTokenValidity()
  const [successRequesting, setSuccessRequesting] = useState<boolean | undefined>(undefined)

  const {
    mutate,
    data: response,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: postDataJsonWithTokenExceptBody,
    onSuccess: data => {
      // queryClient.ini
      queryClient.invalidateQueries({ queryKey: ['follow-list'] })
      toast.success('팔로우 요청에 성공하였습니다.')
      setSuccessRequesting(true)
    },
    onError: (error: Error) => {
      toast.error('팔로우 좋아요 요청에 실패하였습니다.')
      setSuccessRequesting(false)
      checkData(error)
    },
  })

  const {
    mutate: deleteLike,
    data: deleteResponse,
    isPending: deleting,
    isSuccess: deletingSuccess,
    isError: deletingFailed,
  } = useMutation({
    mutationFn: deleteDataTanstackWithToken,
    onSuccess: data => {
      // queryClient.ini
      queryClient.invalidateQueries({ queryKey: ['follow-list'] })
      toast.success('팔로우가 취소되었습니다.')
      setSuccessRequesting(true)
    },
    onError: (error: Error) => {
      toast.error('팔로우가 해제에 실패하였습니다.')
      checkData(error)
      setSuccessRequesting(false)
    },
  })

  const createForm = (stts: boolean, id?: number) => {
    let url = `${process.env.REACT_APP_API_DOMAIN}auth-service/follow?followeeId=`
    if (id) {
      url += id
    } else {
      url += userId
    }

    if (stts) {
      mutate({ url })
    } else {
      deleteLike({ url })
    }
  }

  return {
    isPending,
    createForm,
    isSuccess,
    isError,
    deleting,
    deletingSuccess,
    deletingFailed,
    successRequesting,
  }
}
