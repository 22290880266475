import styled from 'styled-components'

type RelatedPostLayoutType = {
  children: React.ReactNode
  mb?: string
  onClick?: () => void
  height?: string
  outterHeight?: string
  border?: string
  br?: string
}

export default function RelatedPostLayout({
  outterHeight = '102px',
  height = '102px',
  border = 'unset',
  br = 'unset',
  onClick,
  children,
  mb = '0px',
}: RelatedPostLayoutType) {
  return (
    <OuterWrap $border={border} $br={br} $height={outterHeight} $mb={mb}>
      <Wrap
        $height={height}
        $mb={mb}
        onClick={() => {
          onClick?.()
        }}
      >
        {children}
      </Wrap>
    </OuterWrap>
  )
}

const OuterWrap = styled.div<{ $br: string; $border: string; $mb: string; $height: string }>`
  /* Auto layout */
  display: flex;

  max-width: 100%;
  height: ${({ $height }) => $height};

  margin-bottom: ${({ $mb }) => $mb};
  box-shadow: ${({ $border }) => $border};
  border-radius: ${({ $br }) => $br};
`

const Wrap = styled.div<{ $mb: string; $height: string }>`
  /* Frame 247 */

  /* Auto layout */
  display: flex;
  width: 100%;
  max-width: 100%;
  height: ${({ $height }) => $height};

  align-items: center; /* 수직 가운데 정렬 추가 */
`
