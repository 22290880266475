import styles from './MiddleContainer.module.css'
import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'

export default function MiddleContainer({ text, styleID = 'div_mid_container' }: { text: string; styleID?: string }) {
  return (
    <div className={`${divideStyleIDString(styles, styleID)}`}>
      <p>{text}</p>
    </div>
  )
}
