import { collectionRecSampleType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import LoadingContainer from '../container/LoadingContainer'
/*
    파일명 : BookmarkItem.tsx
    설명 : 싹싹도감 > 상세 > 관련 게시글 > 이미지는 height기준
    작성일 : 2024-06-13
*/
type MarkedItemType = {
  item: collectionRecSampleType | null
  onClick: () => void
  iconComp?: React.ReactNode
  border?: string
}
export default function MarkedItem({ border = 'unset', iconComp, item, onClick }: MarkedItemType) {
  if (!item) {
    return (
      <Wrap>
        <LoadingContainer styleId="img_box_h" />
      </Wrap>
    )
  }
  return (
    <Wrap
      onClick={() => {
        onClick()
      }}
    >
      <ItemImgDiv $border={border} $bgurl={item.img_url}>
        {iconComp && <BookMark>{iconComp}</BookMark>}
      </ItemImgDiv>
    </Wrap>
  )
}

interface ItemImgDivProps {
  $bgurl: string
  $border: string
}

const ItemImgDiv = styled.div<ItemImgDivProps>`
  position: relative;
  height: 100%;
  border: ${props => props.$border};
  aspect-ratio: 1 / 1;
  background: url(${props => props.$bgurl});
  background-size: cover;
  background-position: center; /* 이미지를 가운데로 정렬합니다. */
  border-radius: 10px;
`

const BookMark = styled.div`
  bottom: 10px;

  position: absolute;
  width: 100%;
  height: 21px;
  text-align: right;
`

const Wrap = styled.div`
  height: 100%;

  &:hover {
    cursor: pointer;
  }
`
