import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import FormMain from '../../FormMain'
import styles from '../../styles/LabelLayout.module.css'
import { ReactNode } from 'react'
import styled from 'styled-components'

type LabelLayout = {
  label?: string | React.ReactNode
  display?: string
  isFirst?: boolean // 첫번째에 위치하는지
  infoText?: string
  warning?: boolean
  warnText?: string
  infoStyleId?: string
  sublabel?: string
  children: JSX.Element | JSX.Element[] | ReactNode
}

export default function LabelLayout({
  label,
  isFirst = false,
  infoStyleId = '',
  infoText = '',
  display = 'block',
  children,

  warnText = '',
  warning = false,
  sublabel,
}: LabelLayout) {
  let style = ''
  if (!isFirst) {
    style = 'mt_2p7_vh'
  }
  if (label) {
    return (
      <LabelDiv $mb="0px" $display={display} className={`${divideStyleIDString(styles, style)}`}>
        <LabelTitle $display={display}>
          {typeof label === 'string' ? <span>{label}</span> : <>{label}</>}

          {sublabel && <span>{sublabel}</span>}
        </LabelTitle>
        {children}
        {infoText.length > 0 && <InfoComp infoStyleId={infoStyleId} infoText={infoText} />}

        <FormMain.AlertText visible={warning} text={warnText} />
      </LabelDiv>
    )
  } else {
    return (
      <LabelDiv $mb="0px" $display={display} className={`${divideStyleIDString(styles, style)}`}>
        {children}
        {infoText.length > 0 && <InfoComp infoStyleId={infoStyleId} infoText={infoText} />}

        <FormMain.AlertText visible={warning} text={warnText} />
      </LabelDiv>
    )
  }
}

export function InfoComp({ infoStyleId, infoText }: { infoStyleId: string; infoText: string }) {
  return (
    <>
      <div className={`${divideStyleIDString(styles, 'div_info ' + infoStyleId)}`}>
        <span>{infoText}</span>
      </div>
    </>
  )
}
export const LabelDiv = styled.div<{ $display: string; $mb: string }>`
  display: ${({ $display }) => $display};
  margin-bottom: ${({ $mb }) => $mb};
  justify-content: space-between;
`

export const LabelTitle = styled.div<{ $display: string }>`
  text-align: left;

  justify-content: space-between;

  align-content: center;
  margin-bottom: ${({ $display }) => ($display === 'flex' ? '0px' : '12px')};
`
