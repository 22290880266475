import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import FormMain from 'src/components/common/form/FormMain'
import { useFormModalValidateCheck } from 'src/components/common/modal/useFormModalCustomHook'
import FixedButtonLayout from 'src/components/common/layout/FixedButtonLayout'
import { useNavigate } from 'react-router-dom'
import InfoModal from 'src/components/common/modal/InfoModal'
import CollectionIcon from '../../../../assets/images/icon/CollectionIcon.png'
import styled from 'styled-components'

export default function CollectionAdd({ onFinish }: { onFinish: () => void }) {
  const { register, getValues, setValue, watch, handleSubmit } = useForm()
  const { createForm, isSuccess, checkTitle, isValidObj, confirmed, isPending } = useFormModalValidateCheck()

  // useEffect(() => {
  //   if (isSuccess) confirmAlertHandler()
  // }, [isSuccess])

  // const confirmAlertHandler = () => {
  //   onFinish()
  // }
  useEffect(() => {
    const subscirbe = watch((data, { name }) => {
      if (name === 'title') {
        checkTitle(getValues('title'))
      }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])

  const onClickSubmitBtn = (data: { [key: string]: string }) => {
    if (!confirmed) {
      checkTitle(data.title)
    } else {
      createForm(data.title)
    }
  }
  return (
    <>
      <FormMain styleID="fullH">
        <FormMain.InputFull
          styleId={
            isValidObj && isValidObj.title === false ? 'div_plant_02 wdt_full border_red' : 'div_plant_02 wdt_full'
          }
          inputComp={
            <FormMain.Input
              type="text"
              placeholder="도감명을 입력해주세요"
              registerFn={register('title', {
                required: false,
              })}
            />
          }
        />
      </FormMain>
      <FixedButtonLayout bottom="10px">
        <FormMain.Button
          styleID={confirmed === true ? 'btn_submit_01_active' : 'btn_submit_01'}
          onClick={isPending ? () => {} : handleSubmit(onClickSubmitBtn)}
          type="button"
        >
          {isPending ? '...' : '확인'}
        </FormMain.Button>
      </FixedButtonLayout>
      <SuccessAlert open={isSuccess} onFinish={onFinish} />
    </>
  )
}

type SuccessAlertProps = {
  open: boolean

  onFinish: () => void
}
function SuccessAlert({ open, onFinish }: SuccessAlertProps) {
  const [isOpened, setIsOpened] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (open) {
      setIsOpened(true)
    }
  }, [open])

  return (
    <>
      <InfoModal
        mt="0px"
        onTransitionExited={() => {
          onFinish()
        }}
        handleClose={() => {}}
        open={isOpened}
        title="도감 생성이 완료되었습니다"
        comp={
          <>
            <Img alt="도감 등록 완료 이미지" src={CollectionIcon} />
          </>
        }
      />
    </>
  )
}

const Img = styled.img``
