import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

type PopOutLayoutProps = {
  children?: React.ReactNode
  height?: number
  onClose?: () => void
}

export default function PopOutLayout({ onClose, children, height = 591 }: PopOutLayoutProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose?.()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClose])

  return (
    <motion.div
      initial={{ y: height }}
      animate={{ y: 0 }}
      exit={{ y: height }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      style={{ position: 'relative' }} // Ensure position relative to prevent layout shift
    >
      {children}
    </motion.div>
  )
}
