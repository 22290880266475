import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export default function CollectionGridLayout({
  children,
  onScrollEnd,
  scroll,
  h,
}: {
  onScrollEnd: () => void
  children: React.ReactNode
  scroll: boolean
  h: string
}) {
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const wrapRef = useRef<HTMLDivElement>(null)

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    if (target) {
      const currentPosition = target.scrollTop
      const maxScroll = target.scrollHeight - target.clientHeight
      if (currentPosition >= maxScroll) {
        onScrollEnd()
      }
      // Save the scroll position
      setScrollPosition(currentPosition)
    }
  }

  useEffect(() => {
    // Restore scroll position on re-render
    if (wrapRef.current && scrollPosition !== 0) {
      wrapRef.current.scrollTop = scrollPosition
    }
  }, [scrollPosition])

  return (
    <Outer $h={h}>
      <Wrap $scroll={scroll} onScroll={handleScroll} ref={wrapRef}>
        {children}
      </Wrap>
    </Outer>
  )
}

const Outer = styled.div<{ $h: string }>`
  width: 100%;
  height: 320px;
  height: ${({ $h }) => $h};
`

const Wrap = styled.div<{ $scroll: boolean }>`
  width: 100%;
  display: grid;
  height: inherit;

  overflow-y: ${({ $scroll }) => ($scroll ? 'scroll' : 'unset')};

  // grid-template-rows: 156px;
  grid-template-columns: repeat(3, 1fr); /* Three columns with equal width */
  gap: 12px 7px; /* 위아래는 12px, 양옆은 7px */
  grid-template-rows: min-content;
  /* 스크롤바 스타일링 */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`
