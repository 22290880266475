import React, { useState } from 'react'
import { CalendarEventType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import moment from 'moment'
import { generateRandomKey } from 'src/utils/common/scripts/common'

moment.locale('ko')

interface Event {
  date: Date
  event: string
}

type WeeklyCalendarType = {
  disabledDate: Date
  startOfWeek: Date
  viewAll?: boolean
  selectedDate: Date
  onClickDate: (date: Date) => void
  events: CalendarEventType[] | null
  readOnly?: boolean
}

function WeeklyCalendar({
  readOnly = true,
  events,
  startOfWeek,
  viewAll = false,
  disabledDate,
  selectedDate,
  onClickDate,
}: WeeklyCalendarType) {
  // 주간 일정 표시용 년도와 월

  // 요일 배열
  const daysOfWeek: string[] = ['일', '월', '화', '수', '목', '금', '토']

  // 주간 일정 (임의의 예시)
  const weeklyEvents: Event[] = [
    { date: new Date(), event: '이번 주 일정' },
    {
      date: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 2),
      event: '다음 주 일정',
    },
  ]

  const onClickDateDate = (date: Date) => {
    if (date > disabledDate && !viewAll) {
      return
    } else {
      onClickDate(date)
    }
  }

  function getEvents(day: Date) {
    if (events === null) {
      return <></>
    }
    const hasWeeklyEvent = events.filter(event => event.date === moment(day).format('YYYY-MM-DD'))

    let eventsComp = <></>

    if (hasWeeklyEvent.length >= 4) {
      eventsComp = (
        <>
          {hasWeeklyEvent.slice(0, 4).map((item, index) =>
            index <= 2 ? (
              <HavePlan key={generateRandomKey()} $bc={item.colorType ? item.colorType : '#00C398'}>
                .
              </HavePlan>
            ) : (
              <></>
            ),
          )}
        </>
      )
    } else {
      eventsComp = (
        <>
          {hasWeeklyEvent.map((item, index) => (
            <HavePlan key={generateRandomKey()} $bc={item.colorType ? item.colorType : '#00C398'}>
              .
            </HavePlan>
          ))}
        </>
      )
    }

    if (readOnly && hasWeeklyEvent.length > 0) {
      return (
        <div style={{ position: 'relative' }} key={generateRandomKey()}>
          <PlanWrap>{eventsComp}</PlanWrap>
          <RestPlanWrap>
            {hasWeeklyEvent.length > 3 && <RestEvents>+{hasWeeklyEvent.length - 3}</RestEvents>}
          </RestPlanWrap>
        </div>
      )
    } else {
      return <></>
    }
  }

  return (
    <div>
      <DaysOfWeek>
        {daysOfWeek.map((day, index) => (
          <div key={generateRandomKey()}>
            <strong>{day}</strong>
          </div>
        ))}
      </DaysOfWeek>
      <Days>
        {[...Array(7)].map((_, index) => {
          const currentDate: Date = new Date(startOfWeek)
          currentDate.setDate(startOfWeek.getDate() + index)
          // const weeklyEvent: Event | undefined = weeklyEvents.find(
          //   (event: Event) => event.date.toDateString() === currentDate.toDateString(),
          // )
          return (
            <div
              onClick={() => {
                onClickDateDate(currentDate)
              }}
              className={`div_days ${currentDate.toDateString() === selectedDate.toDateString() ? 'selected' : ''}`}
              key={generateRandomKey()}
            >
              <>
                <span>
                  {currentDate.getDate()}
                  {getEvents(currentDate)}
                </span>
              </>
            </div>
          )
        })}
      </Days>
    </div>
  )
}

const PlanWrap = styled.div`
  display: flex;
  position: absolute;
  bottom: -5px;
  width: 100%;
  place-content: center;
`

const RestPlanWrap = styled.div`
  display: flex;
  position: absolute;
  right: -4px;
  bottom: -22px;
  width: 100%;
  place-content: center;
`
const RestEvents = styled.div`
  box-sizing: border-box;
  font-size: 9px;
  z-index: 1;
  height: 100%;
  color: black;
  font-weight: normal;
`
const HavePlan = styled.div<{ $bc: string }>`
  box-sizing: border-box;
  font-size: xx-large;

  z-index: 1;

  height: 100%;
  color: ${({ $bc }) => $bc};

  font-weight: normal;
`

const Days = styled.div`
  -webkit-tap-highlight-color: transparent;
  display: flex;
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  place-content: space-between;

  .div_days {
    position: relative;
    box-sizing: border-box;
    align-content: center;
    text-align: center;
    width: 36px;
    height: 36px;

    &:hover {
      cursor: pointer;
    }

    span {
      font-family: 'Pretendard-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #1c1c1c;
    }
  }

  .div_days:first-child span {
    color: #2d9fe8;
  }

  .div_days:last-child span {
    color: #2d9fe8;
  }

  .haveplan {
    box-sizing: border-box;
    font-size: xx-large;
    bottom: -11px;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    color: #00c398;
    font-weight: normal;
  }

  .selected {
    /* Ellipse 63 */

    background-color: #e0f7f1;

    border-radius: 50%;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;

      color: #00c398;
    }
  }
`

const DaysOfWeek = styled.div`
  display: flex;
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  place-content: space-between;
  div {
    box-sizing: border-box;
    align-content: center;
    text-align: center;
    width: 36px;
    height: 36px;

    strong {
      font-family: 'Pretendard-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #979797;
    }

    &:first-child strong {
      /* 첫 번째 strong 요소에 대한 스타일 */

      color: #2d9fe8;
    }

    &:last-child strong {
      /* 마지막 strong 요소에 대한 스타일 */
      color: #2d9fe8;
    }
  }
`

export default WeeklyCalendar
