import { useNavigate } from 'react-router-dom'
import CollectionImgInfo from 'src/components/common/content/CollectionImgInfo'
import SwiperListAutoWidth from 'src/components/common/swiper/SwiperListAutoWidth'

import sample from '../../../../assets/images/collection/collectionHeaderImg.png'

import { generateRandomKey } from 'src/utils/common/scripts/common'
import { CollectionType, collectionListSampleType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import { SwiperSlide } from 'swiper/react'
import { useCollectionInfo } from '../hook/CollectionListCustomHook'
import CollectionMainListPending from './CollectionMainListPending'
import NoDataContainer, { NoDataIcon } from 'src/components/common/container/NoDataContainer'
import { useRecoilState } from 'recoil'
import { refethCollections } from 'src/utils/common/state/state'
import { useEffect } from 'react'

/*
    파일명 : CollectionMainList.tsx
    설명 : 싹싹도감 메인 > 00님의 식물도감 > 도감 리스트
    작성일 : 2024-06-13
*/
export default function CollectionMainList() {
  const navigate = useNavigate()
  const { isSuccess, collectionList, isPending, refetch } = useCollectionInfo()
  const [refetchCol, setRefetchCol] = useRecoilState(refethCollections)

  useEffect(() => {
    if (refetchCol) {
      refetch()
      setRefetchCol(false)
    }
  }, [refetchCol])

  if (isPending || collectionList === undefined) {
    return <CollectionMainListPending />
  }

  if (collectionList && collectionList?.data && collectionList.data.myEncyclopediaList) {
    if (collectionList.data.myEncyclopediaList.length === 0) {
      return (
        <NoColl
          onClick={() => {
            navigate('list')
          }}
        >
          <NoDataIcon mt="0px" />
        </NoColl>
      )
    } else {
      return (
        <SwiperListAutoWidth
          onClick={() => {
            navigate('list')
          }}
          spaceBetween={10}
        >
          {collectionList?.data.myEncyclopediaList.map((item: CollectionType) => {
            return (
              <SwiperContainer key={generateRandomKey()}>
                <Img src={item.coverImageUrl || sample} alt="이미지" />
                <CollectionImgInfo
                  item={{
                    name: item.title,
                    cnt: item.plantCount,
                  }}
                />
              </SwiperContainer>
            )
          })}
        </SwiperListAutoWidth>
      )
    }
  } else {
    return (
      <NoColl
        onClick={() => {
          navigate('list')
        }}
      >
        <NoDataIcon mt="0px" />
      </NoColl>
    )
  }
}

const NoColl = styled.div`
  /* Rectangle 113 */

  width: 100%;
  height: 186px;

  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 13px;
  display: flex;
  place-content: center;

  align-items: center;
`
const SwiperContainer = styled(SwiperSlide)`
  box-sizing: border-box;

  width: 186px;
  height: 224px;
`

const Img = styled.img`
  box-sizing: border-box;
  width: 100%;
  height: 171px;
  border: 3px solid #ffffff;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  margin-bottom: 9px;
  border-radius: 14px;
`
