import BackDrop from '../content/BackDrop'
import { AnimatePresence, motion } from 'framer-motion'
import PopOutLayout from '../layout/PopOutLayout'
import Drawer from './Drawer'
import Drawer2 from './Drawer2'

export default function BackDropDrawer2({
  backdropActive = true,
  drawerOpen,
  onClickBackDrop,
  title,
  subTitle,
  children,
  mb,
  height,
  pt,
}: {
  drawerOpen: boolean
  backdropActive: boolean
  onClickBackDrop?: () => void
  title?: string | null
  subTitle?: string | null
  children?: React.ReactNode | null
  mb?: string
  height?: number
  pt?: number
}) {
  return (
    <>
      {drawerOpen && backdropActive && (
        <BackDrop
          onClickBackDrop={() => {
            onClickBackDrop?.()
          }}
        />
      )}
      <AnimatePresence>
        {drawerOpen && (
          <PopOutLayout height={height}>
            <Drawer2 pt={pt} key="drawer" height={height} mb={mb} title={title} subTitle={subTitle}>
              {children}
            </Drawer2>
          </PopOutLayout>
        )}
      </AnimatePresence>
    </>
  )
}
