import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import divideStyleIDString from '../../../../../utils/common/scripts/divideStyleIDString'
import styles from '../../styles/InputTimerField.module.css'

import Timer from 'src/components/common/timer/Timer'

interface InputFieldProps {
  type: 'text' | 'password'
  id?: string
  name?: string
  placeholder?: string
  registerFn: React.InputHTMLAttributes<HTMLInputElement>
  readOnly?: boolean
  styleID?: string
  value?: string
  disabled?: boolean
  divStyleId?: string
  certConfirm?: boolean | null
  onChange?: (key: string, value: string) => void
  onBlur?: (key: string, value: string) => void
}

// 포커싱 ref 타입
export type InputFieldRef = {
  focus: () => void
  scrollToTop: () => void
}

const InputTimerField = forwardRef<InputFieldRef, InputFieldProps>(function InputField(
  {
    divStyleId = 'div_with_timer',
    type,
    id,
    disabled = false,
    certConfirm = null,
    name = '',
    placeholder,
    registerFn,
    readOnly,
    styleID = '',
  },
  ref,
) {
  const inputRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus()
    },
    scrollToTop: () => {
      inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
  }))
  return (
    <>
      <div className={`${divideStyleIDString(styles, divStyleId)}`}>
        <input
          ref={inputRef}
          id={id}
          type={type}
          name={name}
          placeholder={placeholder}
          {...registerFn} // Explicitly pass registerFn instead of spreading
          readOnly={readOnly}
          aria-label={`inputTEXT_${id}`}
          aria-labelledby={id}
          className={`${divideStyleIDString(styles, styleID)}`}
          disabled={disabled}
        />
        <Timer certConfirm={certConfirm} />
      </div>
    </>
  )
})

export default InputTimerField
