import { CustomButton } from 'src/components/common/button/CustomButton'
import { usePlantPageStatus } from '../../../../utils/store/plant/usePlantPageStatus'
import styled from 'styled-components'
import NoItemBox from './NoItemBox'
import NoDataContainer2 from 'src/components/common/container/NoDataContainer2'

export default function NoItemList({ showBtn = true, onClick }: { showBtn?: boolean; onClick?: () => void }) {
  const { setPageStatus } = usePlantPageStatus()
  const onClickNxtBtn = () => {
    if (onClick) {
      onClick()
    } else {
      setPageStatus('regNewPlant')
    }
  }
  return (
    <NoItemListContainer>
      <div style={{ height: '235px' }}>
        <NoDataContainer2 ac="center" mt="0px" title="검색 결과가 없습니다." discription="식물을 직접 등록해 주세요!" />
      </div>
      {/* <HeaderText>검색 결과가 없습니다.</HeaderText>
      <NoItemBox /> */}
      {showBtn && (
        <CustomButton
          marginTop="16px"
          onClick={onClickNxtBtn}
          type="button"
          styleID="btn_submit_01_active"
          width="100%"
        >
          식물 직접 등록하기
        </CustomButton>
      )}
    </NoItemListContainer>
  )
}

const NoItemListContainer = styled.div`
  width: 100%;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const HeaderText = styled.div`
  margin-bottom: 18px;
  margin-top: 67px;
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;

  letter-spacing: -0.05em;

  color: #1c1c1c;
`
