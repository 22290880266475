import styled from 'styled-components'
import styles from '../../styles/InputContainer.module.css'
import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'

type InputContainerProp = {
  children: JSX.Element | JSX.Element[] | string
  styleID?: string
  width?: string
  height?: string
  padding?: string
  margin?: string
  onClick?: () => void
}

export default function InputContainer({
  children,
  onClick,
  styleID = 'default',
  width = '',
  height = '',
  padding = '',
  margin = '',
}: InputContainerProp) {
  return (
    <InputContainerStyle
      onClick={() => {
        onClick?.()
      }}
      className={`${divideStyleIDString(styles, styleID)}`}
      width={width}
      height={height}
      padding={padding}
      margin={margin}
    >
      {children}
    </InputContainerStyle>
  )
}

interface InputContainerStyleProps {
  width: string
  height: string
  padding: string
  margin: string
}

const InputContainerStyle = styled.div<InputContainerStyleProps>`
  width: ${props => props.width};
  height: ${props => props.height};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
`
