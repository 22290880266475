import ImgTag from 'src/components/common/imgTag/ImgTag'
import styled from 'styled-components'
import mockUpImg from 'src/assets/images/plant/MockupImg.png'
import { CustomButton } from 'src/components/common/button/CustomButton'
import { usePlantPageStatus } from '../../../../utils/store/plant/usePlantPageStatus'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ItemObjType, plantTypeType } from 'src/utils/common/type/type'
import PlantListLayout from 'src/components/common/layout/PlantListLayout'
import PlantItemLayout from 'src/components/common/layout/PlantItemLayout'
import MyPlantSample from '../../../../assets/images/plant/MyPlantSample.png'
import { generateRandomKey } from 'src/utils/common/scripts/common'

// PlantItemList 컴포넌트의 프롭 타입을 지정합니다.
type PlantItemListProps = {
  itemArr: plantTypeType[]
  height?: string
}

export default function PlantItemList({ height = '448px', itemArr }: PlantItemListProps) {
  const { setPageStatus } = usePlantPageStatus()
  const [selectItemNm, setSelectItemNm] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<string | number>('')
  const navigate = useNavigate()

  const onClickNxtBtn = () => {
    if (selectedItem) {
      setPageStatus('selectChar')
      navigate(selectedItem.toString(), { state: { direction: 'na', plantTypeNm: selectItemNm } })
    }
  }

  return (
    <>
      <PlantListLayout height={height}>
        {itemArr.map((item: plantTypeType, index: number) => (
          <PlantItemLayout
            eng={item.eng}
            padding="12px 0px 12px 12px"
            height="84px"
            onClick={() => {
              setSelectItemNm(item.commonName)
              setSelectedItem(item.plantSpeciesId)
            }}
            name={item.commonName}
            key={generateRandomKey()}
            className={
              selectedItem === item.plantSpeciesId
                ? index === 0
                  ? 'selected first'
                  : 'selected'
                : index === 0
                  ? 'first'
                  : ''
            }
          >
            <img
              src={item.imageUrl ? item.imageUrl : MyPlantSample}
              alt="이미지태그"
              style={{
                width: '60px',
                height: '60px',
              }}
            />
          </PlantItemLayout>
        ))}
      </PlantListLayout>
      <CustomButton
        marginTop="16px"
        onClick={onClickNxtBtn}
        type="button"
        styleID={selectedItem ? 'btn_submit_01_active' : 'btn_submit_01'}
        width="100%"
      >
        다음
      </CustomButton>
    </>
  )
}
