import moment from 'moment'
import 'moment/locale/ko' // 한국어 로케일을 로드
import { useState } from 'react'
/*
    파일명 : TopSearched.tsx
    설명 : 싹싹도감 > 인기 검색어
    작성일 : 2024-06-16
*/

import PageSubTitleTwo from 'src/components/common/content/PageSubTitleTwo'
import TopSearchedList from '../sub/TopSearchedList'
import { useTopSearchedInfo } from '../hook/CollectionListCustomHook'

export default function TopSearched() {
  const [current, setCurrent] = useState(new Date())
  const format = moment(current).format('MM. DD HH:MM 기준')
  const { list, isPending, response, isSuccess } = useTopSearchedInfo()

  let titleComp = <PageSubTitleTwo marginTop="23px" marginBottom="12px" title={null} />
  if (response) {
    titleComp = (
      <PageSubTitleTwo
        marginTop="23px"
        h1ClassNm="small"
        marginBottom="12px"
        title="인기 검색어"
        iconComp={<span>{response?.data.referenceTime} 기준</span>}
      />
    )
  }

  return (
    <>
      {titleComp}
      <TopSearchedList list={isPending ? null : list} />
    </>
  )
}
