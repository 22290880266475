import { Box } from '@mui/material'
import { CustomButton } from 'src/components/common/button/CustomButton'
import { CONTENT_PADDING } from 'src/utils/common/constants/constants'
import { formatNumber } from 'src/utils/common/scripts/common'
import styled from 'styled-components'
import soldIcon from '../../../../assets/images/post/sttsDeal.png'

import GoodIcon from 'src/components/common/icon/GoodIcon'

type GreenChatBtn = {
  price: number
  onClick: () => void
  clicked: boolean
  isSold: boolean
}

export default function GreenChatBtn({ isSold, clicked, price, onClick }: GreenChatBtn) {
  let priceComp = <Price>{formatNumber(price)}원</Price>
  if (isSold) {
    priceComp = (
      <>
        <Sold src={soldIcon} alt="판매 완료" />
        <Price className="sold">{formatNumber(price)}원</Price>
      </>
    )
  }
  return (
    <Box sx={{ p: CONTENT_PADDING, pt: 0 }}>
      <Wrap>
        <LeftDiv>
          <GoodIcon isSold={isSold} mr="15px" clicked={clicked} onClick={() => {}} />

          {priceComp}
        </LeftDiv>
        <RightDiv>
          <CustomButton styleID={isSold ? 'btn_chat is_sold' : 'btn_chat'} type="button" onClick={onClick}>
            채팅하기
          </CustomButton>
        </RightDiv>
      </Wrap>
    </Box>
  )
}

const LeftDiv = styled.div`
  display: flex;
  align-items: center;
`
const RightDiv = styled.div``

const Sold = styled.img`
  width: 57px;
  height: 21px;
  margin-right: 6px;
`

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Price = styled.p`
  font-family: 'Inter-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  /* identical to box height */

  color: #1c1c1c;
  &.sold {
    color: #979797;
  }
`
