import { useEffect, useRef, useState } from 'react'
import { TextareaAutosize } from '@mui/material'
import styled from 'styled-components'
import { usePostComment } from '../hooks/PlazaCreateCustomHook'
import { useForm } from 'react-hook-form'
import FormMain from 'src/components/common/form/FormMain'
import { CustomButton } from 'src/components/common/button/CustomButton'

export default function PlazaCommentInput({
  nickName,
  targetId,
  isUpdate,
  commentType,
  onResizeHeight,
  onFinish,
  updateObj,
}: {
  nickName: string
  targetId: number | string
  updateObj: {
    id: string | number
    content: string
    name: string
  } | null
  commentType: string
  onResizeHeight: (h: number) => void
  onFinish: (type: string) => void
  isUpdate: boolean
}) {
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  let placeHolder = ''
  if (commentType === 'REPLY') {
    placeHolder = '답글'
  } else if (commentType === 'COMMENT') {
    placeHolder = '댓글'
  }

  if (isUpdate && updateObj?.id) {
    placeHolder = '수정할 ' + placeHolder
  }
  const { isPending, createForm, isSuccess, isError, updateForm, errorToUpdating, successToUpdating, updating } =
    usePostComment()
  const { register, getValues, setValue, watch, handleSubmit } = useForm()
  const [content, setContent] = useState<string>('')
  useEffect(() => {
    if (isUpdate && updateObj) {
      setContent(updateObj.content)
    }
  }, [updateObj, isUpdate])

  useEffect(() => {
    if (isError) {
      onFinish('ERROR')
    }
  }, [isError])
  useEffect(() => {
    if (isSuccess) {
      onFinish('SUCCESS')
    }
  }, [isSuccess])

  useEffect(() => {
    if (errorToUpdating) {
      onFinish('ERROR')
    }
  }, [errorToUpdating])
  useEffect(() => {
    if (successToUpdating) {
      onFinish('SUCCESS')
    }
  }, [successToUpdating])
  useEffect(() => {
    // 이펙트를 통해 textarea의 높이 변화를 감지하여 console에 출력합니다.
    const handleChange = () => {
      if (textareaRef.current) {
        onResizeHeight(textareaRef.current.scrollHeight)
        console.log('Textarea height changed:', textareaRef.current.scrollHeight)
      }
    }

    if (textareaRef.current) {
      textareaRef.current.addEventListener('input', handleChange)
    }

    return () => {
      if (textareaRef.current) {
        textareaRef.current.removeEventListener('input', handleChange)
      }
    }
  }, [])
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [])

  const onClickSubmitBtn = (data: { [key: string]: string }) => {
    if (!content) return
    if (isPending) return
    if (isUpdate && updateObj?.id) {
      updateForm(updateObj?.id, content)
    } else {
      createForm(targetId, commentType, content)
    }
  }

  return (
    <>
      <FormMain onSubmit={handleSubmit(onClickSubmitBtn)}>
        <Wrap>
          <StyledTextarea
            value={content}
            onChange={e => {
              if (e.currentTarget.value) {
                setContent(e.currentTarget.value)
              } else {
                setContent('')
              }
            }}
            ref={textareaRef}
            placeholder={`${nickName}님에게 ${placeHolder} 추가...`}
          />
          <CustomButton styleID="btn_hash" type="submit">
            {isPending || updating ? '등록중...' : '등록'}
          </CustomButton>
        </Wrap>
      </FormMain>
    </>
  )
}

const Wrap = styled.div`
  display: flex;
`
const StyledTextarea = styled(TextareaAutosize)`
  box-sizing: border-box;
  padding-inline: 10px;
  width: 100%;
  z-index: 10;
  resize: none;

  max-width: 356px;
  min-height: 57px;
  border: none;
  background: #f9f9f9;
  border-radius: 7px;
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05em;
  color: #979797;
  text-align: left;
  align-content: center;

  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
  }
`
