import TitleLight from 'src/components/common/title/TitleLight'
import { MenuOptionType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import CollectionRecList from '../sub/CollectionRecList'
import { useNavigate } from 'react-router-dom'
import useDeleteCollection, { useCollectionItemInfo } from '../hook/CollectionListCustomHook'
import BackDropDrawer2 from 'src/components/common/container/BackDropDrawer2'
import { useState } from 'react'
import DrawerRightLayout from 'src/components/common/layout/DrawerRightLayout'
import CollectionDetailMain from '../detail/CollectionDetailMain'
import NoDataContainer2 from 'src/components/common/container/NoDataContainer2'
import Alert2Modal from 'src/components/common/modal/Alert2Modal'

type CollectionItemHeaderType = {
  img: string
}

const Img = styled.div<{ $img: string }>`
  z-index: 0;
  width: 100%;
  height: 293px;
  background: url(${props => props.$img});
  background-size: cover;
  background-position: center;
`

export default function CollectionItemHeader({ img }: CollectionItemHeaderType) {
  const navigate = useNavigate()
  const { isSuccess, itemList, isPending, collectionInfo, requestItems, setItemList } = useCollectionItemInfo()
  const { deleteCollection } = useDeleteCollection()
  const [closeInner, setCloseInner] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [selectId, setSelectId] = useState<number | string | null>(null)
  const [openAlert, setOpenAlert] = useState(false)

  const myOptions: MenuOptionType[] = [
    {
      optionTitle: '도감 삭제',
      onClick: () => {
        setOpenAlert(true)
      },
    },
  ]

  if (itemList && itemList.length > 0) {
    myOptions.push({
      optionTitle: '도감 편집',
      onClick: () => {
        if (itemList && itemList?.length > 0 && collectionInfo) {
          navigate('edit', {
            state: {
              name: collectionInfo.data.title,
              count: itemList.length,
            },
          })
        }
      },
    })
  }

  const onClickBookMark = (id: string | number, clicked: boolean) => {}

  return (
    <>
      <CollectionDeleteAlert
        closeAlert={() => {
          setOpenAlert(false)
        }}
        deletePlant={deleteCollection}
        openProp={openAlert}
      />
      <Img $img={img}>
        <TitleLight
          onClick={() => {
            navigate('/collection/list', { state: { direction: 'back' } })
          }}
          options={myOptions}
          menuTitle="내 도감 편집"
        />
      </Img>

      <BackDropDrawer2
        height={500}
        title={collectionInfo ? collectionInfo.data.title : null} // TODO 추후 title생성되면 변경 collectionInfo && collectionInfo.data.title
        subTitle={collectionInfo ? `${collectionInfo.data.totalElement}개의 식물` : ''}
        backdropActive={false}
        drawerOpen={true}
      >
        {itemList && itemList.length > 0 ? (
          <CollectionRecList
            onClickItem={id => {
              setSelectId(id)
              setDrawerOpen(true)
            }}
            onSuccessDeleting={() => {}}
            clickedDefault={true}
            h="320px"
            isPending={isPending}
            scroll={true}
            onScrollEnd={() => {
              requestItems()
            }}
            onClickBookMark={onClickBookMark}
            arr={itemList}
          />
        ) : (
          <>
            <NoDataContainer2 title="저장한 목록이 없어요" discription="도감 목록이 비었어요" />
          </>
        )}
      </BackDropDrawer2>

      <DrawerRightLayout
        title="식물 상세"
        showTitle={false}
        pt={0}
        pb={0}
        p={0}
        open={drawerOpen}
        closeInner={closeInner}
        onClose={() => {
          setDrawerOpen(false)
          setCloseInner(false)
          setSelectId(null)
        }}
        children={
          <>
            {selectId && (
              <CollectionDetailMain
                onClickBackBtn={() => {
                  setCloseInner(true)
                }}
                showBtn={false}
                plantTypeId={selectId}
                loadImages={true}
              />
            )}
          </>
        }
      />
    </>
  )
}

function CollectionDeleteAlert({
  openProp,
  deletePlant,
  closeAlert,
}: {
  openProp: boolean
  deletePlant: () => void
  closeAlert: () => void
}) {
  return (
    <>
      <Alert2Modal
        title="도감을 삭제하시겠어요?"
        description="도감 내 식물들도 모두 삭제됩니다."
        open={openProp}
        afterCloseAlert={() => {
          deletePlant()
          closeAlert()
        }}
        handleClose={() => {
          closeAlert()
        }}
      />
    </>
  )
}
