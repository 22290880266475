import PlantItemLayout from 'src/components/common/layout/PlantItemLayout'
import PlantListLayout from 'src/components/common/layout/PlantListLayout'
import { SAMPLE_PLANT } from 'src/utils/common/constants/constants'
import { ItemObjType, TopSearchedItemType } from 'src/utils/common/type/type'
import MyPlantSample from '../../../../assets/images/plant/MyPlantSample.png'
import PlantItemWithNmb from 'src/components/common/layout/PlantItemWithNmb'
import { generateRandomKey, onSaveHistory } from 'src/utils/common/scripts/common'
import { Skeleton } from '@mui/material'
import NoData from 'src/components/common/content/Nodata'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

/*
    파일명 : TopSearchedList.tsx
    설명 : 싹싹도감 > 인기 검색어 > 리스트
    작성일 : 2024-06-16
*/
export default function TopSearchedList({ list }: { list: undefined | null | TopSearchedItemType[] }) {
  const navigate = useNavigate()

  const location = useLocation()
  if (list) {
    if (list.length === 0) {
      return (
        <Wrap>
          <NoData discription="인기 검색어가 없습니다." />
        </Wrap>
      )
    } else {
      return (
        <PlantListLayout height="500px">
          {list.map((item: TopSearchedItemType, index: number) => (
            <PlantItemWithNmb
              index={index + 1}
              firstmt="0px"
              onClick={() => {
                onSaveHistory(item.plantBriefInfo.plantSpeciesId, 'recently_clicked')
                navigate(`/collection/${item.plantBriefInfo.plantSpeciesId}`, {
                  state: { backpath: location.pathname },
                })
              }}
              eng={item.plantBriefInfo.scientificName}
              name={item.plantBriefInfo.commonName}
              key={generateRandomKey()}
              className={index === 0 ? 'first' : ''}
            >
              <img
                src={item.plantBriefInfo.imageUrl ? item.plantBriefInfo.imageUrl : MyPlantSample}
                alt="이미지태그"
                style={{
                  width: '60px',
                  height: '60px',
                }}
              />
            </PlantItemWithNmb>
          ))}
        </PlantListLayout>
      )
    }
  } else {
    return (
      <PlantListLayout height="500px">
        {Array.from({ length: 5 }).map((_, index) => (
          <PlantItemWithNmb
            index={index + 1}
            firstmt="0px"
            onClick={() => {}}
            eng={null}
            name={null}
            key={generateRandomKey()} // Ensure this key is applied correctly
            className={index === 0 ? 'first' : ''}
          >
            <Skeleton variant="rectangular" width={60} height={60} />
          </PlantItemWithNmb>
        ))}
      </PlantListLayout>
    )
  }
}

const Wrap = styled.div`
  height: 500px;
`
