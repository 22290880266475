import styled from 'styled-components'
import goodIcon from '../../../assets/images/post/good.png'
import goodClicked from '../../../assets/images/post/goodClicked.png'
import goodGray from '../../../assets/images/post/goodGray.png'

type GoodIconType = {
  clicked: boolean
  height?: string
  width?: string
  mr?: string
  onClick?: () => void
  isSold?: boolean
}

export default function GoodIcon({
  isSold = false,
  mr = '0px',
  onClick,
  clicked,
  height = '17px',
  width = '20px',
}: GoodIconType) {
  if (isSold) {
    return (
      <>
        <Img $mr={mr} $height={height} $width={width} src={clicked ? goodGray : goodIcon} alt="판매완료" />
      </>
    )
  } else {
    return (
      <>
        <Img
          $mr={mr}
          $height={height}
          $width={width}
          src={clicked ? goodClicked : goodIcon}
          onClick={() => {
            onClick?.()
          }}
          alt="찜버튼"
        />
      </>
    )
  }
}

const Img = styled.img<{ $mr: string; $height: string; $width: string }>`
  width: ${({ $width }) => `${$width}`};
  height: ${({ $height }) => `${$height}`};
  margin-right: ${({ $mr }) => `${$mr}`};
`
