import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import FormMain from 'src/components/common/form/FormMain'
import CustomCalendar from 'src/components/common/form/sub/calendar/CustomCalendar'
import ChoiceChips from 'src/components/common/form/sub/select/ChoiceChips'
import { PLANT_CARE } from 'src/utils/common/constants/constants'
import { useDiaryFormCheck } from '../hooks/DiaryCreateCustomHook'
import { useParams } from 'react-router-dom'
import { selectedDirayDate } from 'src/utils/common/state/state'
import { useRecoilState } from 'recoil'
import { diaryDetailType } from 'src/utils/common/type/type'
import { queryClient } from 'src/App'
import { getDataTanstackWithToken } from 'src/utils/common/api/http'
import { toast } from 'react-toastify'
import LoadingSpinner from 'src/components/common/container/LoadingSpinner'
import FullLoadingSpinner from 'src/components/common/container/FullLoadingSpinner'

export default function DiaryCreateForm({
  onPostFinish,
  updateItem,
}: {
  onPostFinish: (type: string) => void
  updateItem: null | diaryDetailType
}) {
  const {
    checkAll,
    checkImgCnt,
    checkContent,
    selectedKey,
    isValidObj,
    confirmed,
    formAlert,
    checkTitle,
    checkCheckBox,
    createDiary,
    isSuccess,
    isError,
    updateData,
    updating,
    isPending,
    errorToUpdating,
    successToUpdating,
    setFalse,
    setTrue,
  } = useDiaryFormCheck()
  const { register, getValues, setValue, watch, handleSubmit } = useForm()
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDirayDate)

  const onClickSubmitBtn = (data: { [key: string]: string | { [key: string]: boolean } }) => {
    console.log(data)

    if (isPending || updating) return
    if (!confirmed) {
      checkAll({
        title: getValues('title'),
        content: getValues('content'),

        care: getValues('care'),
      })
    } else {
      const care = getValues('care')
      const trueValues = Object.keys(care).filter(key => care[key] === true)

      const form = new FormData()
      const files = getValues('file')
      if (files) {
        files.forEach((item: File, index: number) => {
          form.append(`imageFiles`, item, item.name)
        })
      }
      if (updateItem) {
        updateData(updateItem.diaryId, data, trueValues, form)
      } else {
        createDiary(data, trueValues, form)
      }
    }
  }

  useEffect(() => {
    const subscirbe = watch((data, { name }) => {
      if (name === 'title') {
        checkTitle(getValues('title'))
      } else if (name === 'care') {
        checkCheckBox(getValues('care'), 'care', '관리')
      } else if (name === 'content') {
        checkContent(getValues('content'))
      }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (updateItem) {
      setValue('isPublic', updateItem.isPublic)
      setValue('title', updateItem.title)
      setValue('content', updateItem.content)
      checkTitle(getValues('title'))
      checkCheckBox(getValues('care'), 'care', '관리')
      checkContent(getValues('content'))
    }
  }, [])

  useEffect(() => {
    if (isSuccess) {
      onPostFinish('C')
    }
  }, [isSuccess])
  useEffect(() => {
    if (isError) {
      onPostFinish('C')
    }
  }, [isError])
  useEffect(() => {
    if (errorToUpdating) {
      onPostFinish('U')
    }
  }, [errorToUpdating])
  useEffect(() => {
    if (updating) {
      onPostFinish('U')
    }
  }, [updating])

  return (
    <FormMain styleID="scroll" onSubmit={handleSubmit(onClickSubmitBtn)}>
      <CustomCalendar
        isFirst={true}
        label="날짜"
        selectNext={false}
        selected={selectedDate.date}
        disabled={true}
        setValue={setValue}
        registerFn={register('dttm', {
          required: false,
        })}
        name="dttm"
      />

      <FormMain.LabelLayout
        warning={isValidObj && isValidObj.care === false ? true : false}
        warnText={formAlert && formAlert.care ? formAlert.care : ''}
        label="관리"
      >
        <ChoiceChips
          defaultVal={updateItem ? updateItem.plantCareList : null}
          length={3}
          multiple={true}
          list={PLANT_CARE}
          listName="care"
          register={register}
          getValues={getValues}
          setValue={setValue}
        />
      </FormMain.LabelLayout>

      <FormMain.LabelLayout
        infoStyleId="text_red"
        warning={isValidObj && isValidObj.title === false ? true : false}
        warnText={formAlert && formAlert.title ? formAlert.title : ''}
        label="제목"
      >
        <FormMain.InputFull
          styleId={
            isValidObj && isValidObj.title === false
              ? 'div_plant_02 wdt_full mb_10 border_red'
              : 'div_plant_02 wdt_full mb_10'
          }
          inputComp={
            <FormMain.Input type="text" placeholder="제목을 입력해주세요" registerFn={register('title', {})} />
          }
        />
      </FormMain.LabelLayout>
      <FormMain.LabelLayout
        warning={isValidObj && isValidObj.content === false ? true : false}
        warnText={formAlert && formAlert.content ? formAlert.content : ''}
        label="내용"
      >
        <FormMain.TextArea
          warning={isValidObj && isValidObj.content === false ? true : false}
          registerFn={register('content', {
            required: false,
          })}
          placeholder="내용을 입력해주세요"
        />
      </FormMain.LabelLayout>
      <FormMain.LabelLayout
        warning={isValidObj && isValidObj.file === false ? true : false}
        warnText={formAlert && formAlert.file ? formAlert.file : ''}
        label="사진(선택)"
      >
        <FormMain.ImageFile
          setTrue={setTrue}
          setFalse={setFalse}
          defaultValList={updateItem?.imageUrlList ? updateItem.imageUrlList : null}
          warning={isValidObj && isValidObj.file === false ? true : false}
          registerFn={register('file', {
            required: false,
          })}
          name="file"
          register={register}
          getValues={getValues}
          setValue={setValue}
        />
      </FormMain.LabelLayout>

      <FormMain.LabelLayout infoStyleId="text_red" label="내용 공개하기">
        <FormMain.Toggle
          register={register}
          disabled={updateItem ? true : false}
          defaultVal={updateItem && updateItem?.isPublic === false ? updateItem?.isPublic : true}
          getValues={getValues}
          setValue={setValue}
          registerFn={register('isPublic', {
            required: false,
          })}
          name="isPublic"
        />
      </FormMain.LabelLayout>
      <FormMain.Button
        styleID={confirmed === true ? 'btn_submit_01_active mt_52_px' : 'btn_submit_01 mt_52_px'}
        width="100%"
        height=""
        type="submit"
      >
        {isPending || updating ? '등록중...' : '작성 완료'}
      </FormMain.Button>
      <> {isPending || (updating && <FullLoadingSpinner isShow={true} />)}</>
    </FormMain>
  )
}
