import { ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import FormMain from 'src/components/common/form/FormMain'
import InputContainer from 'src/components/common/form/sub/input/InputContainer'
import styled from 'styled-components'
import SearchIconGray from 'src/components/common/icon/SearchIconGray'
import { PLANT_PLAZA_SELECT } from 'src/utils/common/constants/constants'
import SelectChipsList from 'src/components/common/form/sub/select/SelectChipsList'
import { useLocation, useParams } from 'react-router-dom'
import SearchParamInitIcon from 'src/components/common/icon/SearchParamInitIcon'

export default function PlazaSearch({
  onClick,
  onClickChip,
  defaultVal,
  onChangeSearchParam,
  hashtag,
}: {
  hashtag: string
  defaultVal: string
  onClick?: () => void
  onClickChip: (item: string) => void
  onChangeSearchParam: (param: string) => void
}) {
  const location = useLocation()
  const { typeNm, paramNm } = useParams()
  const { register, getValues, watch, handleSubmit, setValue } = useForm()
  const [changingSearch, setChangingSearch] = useState(false)
  const [defaultChip, setDefaultChip] = useState(defaultVal)

  const onClickSubmitBtn = (data: { [key: string]: string }) => {
    if (data.searchParam && data.searchParam.length > 0) {
      onChangeSearchParam(data.searchParam)
    } else {
      onChangeSearchParam('')
    }
  }

  useEffect(() => {
    if (hashtag) {
      setValue('searchParam', `#${hashtag}`)
      onChangeSearchParam(`#${hashtag}`)
    }
  }, [hashtag])

  useEffect(() => {
    if (paramNm) {
      setValue('searchParam', paramNm)
      onChangeSearchParam(paramNm)
    }
  }, [])

  useEffect(() => {
    const subscirbe = watch((data, { name }) => {
      if (name === 'searchParam') {
        if (getValues('searchParam')) {
          if (getValues('searchParam').length > 0) {
            setChangingSearch(true)
          } else if (getValues('searchParam').length === 0) {
            setChangingSearch(false)
          }
        } else {
          setChangingSearch(false)
        }
      }
    })
    return () => {
      subscirbe.unsubscribe()
    }
  }, [watch])

  const onChangeSearchField = (value: string) => {
    //  setKeyword(value)검색
    //  onChangeSearchParam?.(value)
  }

  return (
    <>
      <FormMain onSubmit={handleSubmit(onClickSubmitBtn)}>
        <InputContainer margin="16px 0px 0px 0px" width="100%" height="49px" padding="15px 24px !important">
          <SearchIconGray />
          <FormMain.Input
            onClick={() => {
              onClick?.()
            }}
            registerFn={register('searchParam', {
              required: false,
              disabled: false,
              // onChange: (event: ChangeEvent<HTMLInputElement>) => {
              //   const { value } = event.target
              //   // onChangeSearchField(value) // 입력값을 콜백 함수에 전달
              // },
            })}
            placeholder="검색어를 입력해주세요."
            styleID="input_with_two_button"
            width="75%"
            height="90%"
          />
          {changingSearch ? (
            <SearchParamInitIcon
              onClick={() => {
                setValue('searchParam', '')
                onChangeSearchParam('')
              }}
            />
          ) : (
            <div></div>
          )}
        </InputContainer>
      </FormMain>
      <SelectChipsList
        defaultVal={defaultVal}
        isChanged={null}
        onClickChip={onClickChip}
        watch={watch}
        length={5}
        register={register}
        setValue={setValue}
        getValues={getValues}
        list={PLANT_PLAZA_SELECT}
      />
    </>
  )
}
