import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { patchDataJsonWithToken, postDataJson, postDataJsonWithToken } from 'src/utils/common/api/http'
import { validateCheckType } from '../../../../utils/common/type/type'
import { toast } from 'react-toastify'
import { validateNullCheck, validateRegExpCheck } from 'src/utils/common/scripts/validateCheck'
import { useNavigate, useParams } from 'react-router-dom'

import { usePlantPageStatus } from 'src/utils/store/plant/usePlantPageStatus'
import { useCheckTokenValidity, useValidationCheck } from 'src/utils/common/hooks/customHook'
import { queryClient } from 'src/App'

type plantForm = {
  name: string
  sartDt: string
  watering: string
  spot: string
  pot: string
  lastWateringDt: string
  platTypeId: string
  plantTypeNm: string
  charType: string
}

export function useCreatePlantApi() {
  const { checkData } = useCheckTokenValidity()
  const navigate = useNavigate()

  const { setPageStatus } = usePlantPageStatus()
  const { plantId } = useParams()

  const {
    mutate,
    data: createPlantResult,
    isSuccess: SuccessToCreate,
    isPending,
  } = useMutation({
    mutationFn: postDataJsonWithToken,
    onSuccess: () => {
      // toast.success('식물 등록이 완료 되었습니다.')
      queryClient.invalidateQueries({ queryKey: ['my-plant'] })
      // setPageStatus('selectPlant')
      // navigate('/main', { state: { direction: 'back' } })
    },
    onError: () => {
      toast.success('식물 등록에 실패하였습니다.')
      setPageStatus('selectPlant')
      navigate('/main', { state: { direction: 'back' } })
    },
  })

  const {
    mutate: update,
    data: updatingData,
    isPending: updating,
    isError: errorToUpdating,
    isSuccess: successToUpdating,
  } = useMutation({
    mutationFn: patchDataJsonWithToken,
    onSuccess: successD => {
      toast.success('식물 수정이 완료 되었습니다.')
      queryClient.invalidateQueries({ queryKey: ['my-plant'] })
      setPageStatus('selectPlant')
      navigate(`/plant/detail/${plantId}`, { state: { direction: 'back' } })
    },
    onError: error => {
      checkData(error)
    },
  })

  // TODO : 캐릭터 타입, 식물 타입 추가
  function createPlant(data: plantForm, isUpdate: boolean) {
    const water = parseInt(data.watering, 10)
    const id = parseInt(data.charType, 10)

    const formData = {
      plantName: data.name /* 이름 */,
      plantType: data.plantTypeNm /* 식물타입 이름 */,
      plantTypeId: data.platTypeId /* 식물타입 아이디 */,
      plantBirthday: data.sartDt /* 생일 */,
      lastWaterday: data.lastWateringDt /* 마지막으로 물 준 주기 */,
      wateringIntervalInDays: water /* 물 주기 */,
      plantLocation: data.spot /* 위치 */,
      potType: data.pot /* 화분 */,
      characterId: id,
      /* 캐릭터 타입 추가 해야 함 */
    }

    const url = `${process.env.REACT_APP_API_DOMAIN}my-plant/plants`
    if (isUpdate && plantId) {
      const updateData = Object.assign(formData, { myPlantId: plantId })
      update({ formData: updateData, url })
    } else {
      mutate({ formData, url })
    }
  }

  return { createPlant, createPlantResult, isPending, updating, SuccessToCreate }
}

export function useUpdateCharacter() {
  const { checkData } = useCheckTokenValidity()
  const navigate = useNavigate()
  const { plantId } = useParams()

  const {
    mutate: update,
    data: updatingData,
    isPending: updating,
    isError: errorToUpdating,
    isSuccess: successToUpdating,
  } = useMutation({
    mutationFn: patchDataJsonWithToken,
    onSuccess: successD => {
      toast.success('캐릭터 변경이 완료 되었습니다.')
      queryClient.invalidateQueries({ queryKey: ['my-plant'] })

      navigate(`/plant/detail/${plantId}`, { state: { direction: 'back' } })
    },
    onError: error => {
      checkData(error)
    },
  })

  function updatePlant(newCharId: string, selectBg: string) {
    const id = parseInt(newCharId, 10)

    const formData = {
      plantCharacterId: id,
      plantLocation: selectBg,
      /* 캐릭터 타입 추가 해야 함 */
    }

    const url = `${process.env.REACT_APP_API_DOMAIN}my-plant/plants/characters/${plantId}`

    update({ formData, url })
  }

  return { updating, updatePlant }
}

export function useNewPlantValidateCheck() {
  const { createPlant, isPending, updating, SuccessToCreate } = useCreatePlantApi()

  const {
    checkInputText,
    selectedKey,
    checkCheckBox,
    isValidObj,
    confirmed,
    formAlert,
    checkTextArea,
    setFalse,
    setTrue,

    setValidCnt,
  } = useValidationCheck()

  useEffect(() => {
    setValidCnt(4)
  }, [])

  function checkName(name: string) {
    return checkInputText({
      value: name,
      name: 'name',
      label: '이름',
      regExp: '^.{1,50}$',
    })
  }

  function checkWatering(watering: string) {
    return checkInputText({
      value: watering,
      name: 'watering',
      label: '물주기 횟수',
      regExp: '^[0-9]{1,3}$',
    })
  }

  function checkDt(sartDt: string, lastWateringDt: string) {
    const start = new Date(sartDt)
    const watering = new Date(lastWateringDt)
    let isValid = true
    if (start > watering) {
      isValid = false
      setFalse('sartDt', '키우기 시작 한 날이 마지막으로 물 준 날 이전이어야 합니다.')
      setFalse('lastWateringDt', '')
    }

    if (isValid) {
      setTrue('sartDt')
      setTrue('lastWateringDt')
    }
    return isValid
  }

  return {
    createPlant,
    selectedKey,
    isValidObj,
    confirmed,
    checkCheckBox,
    checkName,
    checkWatering,
    checkDt,
    formAlert,
    isPending,
    updating,
    SuccessToCreate,
  }
}
