import { useNavigate } from 'react-router-dom'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MuiMain from 'src/components/common/mui/MuiMain'
import CollectionMainHeader from 'src/components/specific/collection/sub/CollectionMainHeader'
import CollectionMainRec from 'src/components/specific/collection/sub/CollectionMainRec'
import CollectionOfMine from 'src/components/specific/collection/sub/CollectionOfMine'
import SearchField from 'src/components/specific/plant/sub/SearchField'

/*
    파일명 : Collection.tsx
    설명 : 싹싹도감 메인
    작성일 : 2024-06-13
*/
export default function Collection() {
  const navigate = useNavigate()
  return (
    <ContainerLayout styleId="div_container_02">
      <MuiMain>
        <Content
          pb={0}
          component={
            <SearchField
              type="COLLECTION"
              onClick={() => {
                navigate('search')
              }}
              disabled={true}
              onReceiveData={() => {}}
            />
          }
        />
        <Content pb={0} pt={0} p={0} component={<CollectionOfMine />} />
        <Content pt={0} pb={0} component={<CollectionMainRec />} />
      </MuiMain>
    </ContainerLayout>
  )
}
