import React from 'react'
import styled from 'styled-components'
import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'

export interface TextAreaFieldProps {
  id?: string
  placeholder?: string
  registerFn?: React.InputHTMLAttributes<HTMLTextAreaElement>
  readOnly?: boolean
  styleID?: string
  width?: string
  height?: string
  maxLength?: number
  warning?: boolean
}

function TextAreaField({
  maxLength = 1000,
  id,
  placeholder,
  registerFn,
  readOnly,
  styleID,
  width,
  height,
  warning = false,
}: TextAreaFieldProps) {
  // Apply dynamic classes based on styleID
  let styleClasses = styleID ? divideStyleIDString(styleID) : ''
  if (warning) {
    styleClasses += ' border_red'
  }

  return (
    <StyledTextArea
      maxLength={maxLength}
      id={id}
      placeholder={placeholder}
      {...registerFn}
      readOnly={readOnly}
      width={width}
      height={height}
      className={styleClasses}
    />
  )
}

// Styled component for TextArea
const StyledTextArea = styled.textarea<{ width?: string; height?: string }>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '122px'};

  box-sizing: border-box;

  border: 1px solid #dadada;
  border-radius: 7px;
  resize: none; /* 리사이즈 핸들 비활성화 */

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  letter-spacing: -0.05em;

  color: #1c1c1c;
  padding-left: 24px;
  padding-top: 16px;

  /* 포커스 시 아웃라인 없애기 */
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #979797;
  }
  &.border_red {
    border: solid 1px #f00;
    margin-bottom: 10px;
  }
`

export default TextAreaField
