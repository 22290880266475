/*
    작성자 : 김효은
    작성일 : 2024-05-04 토
*/
import FormMain from 'src/components/common/form/FormMain'
import { FormEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import makingIcon from '../../../../assets/images/icon/MakingIcon.png'
import inputCheck from '../../../../assets/images/icon/InputCheck.png'
import MainUserCreateSvcPolicy from './MainUserCreateSvcPolicy'
import { useValidCheckCreateUser } from './hooks/MainUserCreateCustomHook'

import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import MaskingIcon from 'src/components/common/icon/MakingIcon'

function MainUserCreateForm() {
  const { register, getValues, watch, handleSubmit } = useForm()
  const [AgreeConfirmed, setAgreeConfirmed] = useState<string>('PENDING')
  const navigate = useNavigate()
  const [isMasking, setIsMasking] = useState(true)

  const {
    submitBtnLabel,
    createUser,
    checkTelNum,
    checkCertNum,
    certSendBtn,
    timer,
    certConfirm,
    checkEmail,
    checkPwd,
    isValidObj,
    formAlert,
    checkPwdCheck,
    checkAllValidate,
  } = useValidCheckCreateUser()

  useEffect(() => {
    if (submitBtnLabel === '회원 가입 성공' || submitBtnLabel === '회원 가입 실패') {
      const timeoutId = setTimeout(() => {
        toast.success(submitBtnLabel + '하였습니다.')
        navigate('/')
      }, 1000)
      // 조건에 맞을 때 클린업 함수 반환
      return () => clearTimeout(timeoutId)
    }

    // 조건에 맞지 않을 때 아무것도 반환하지 않음
    return undefined
  }, [submitBtnLabel, navigate])

  // 비밀번호, 중복비밀번호 확인
  useEffect(() => {
    const subscirbe = watch((data, { name }) => {
      if (name === 'pwd') {
        const valid = onChangePwd()
        if (valid && data.pwdCheck) {
          onChangePwdChk()
        }
      } else if (name === 'pwdCheck') {
        onChangePwdChk()
      }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])

  // 이메일 유효성 확인
  const onClickChkEmail = () => {
    const newEmail = getValues('email')
    checkEmail(newEmail)
  }

  // 비밀번호 유효성 확인
  const onChangePwd = () => {
    return checkPwd(getValues('pwd'), getValues('pwdCheck'))
  }

  // 비밀번호 맞는지 체크
  const onChangePwdChk = () => {
    checkPwdCheck(getValues('pwd'), getValues('pwdCheck'))
  }

  // 인증번호 전송 (휴대전화번호 유효성 검사)
  const onClickCertSendBtn = () => {
    checkTelNum(getValues('tel'))
  }

  // 인증 확인 버튼 클릭
  const onClickConfirmBtn = () => {
    checkCertNum(getValues('certNum'))
  }

  // 이용약관 유효성 검사
  const validAgreeCheck = (isValid: boolean) => {
    setAgreeConfirmed(isValid ? 'SUBMIT' : 'DENIED')
  }

  const onSubmit = (data: { [key: string]: string }) => {
    if (!checkAllValidate()) return

    if (AgreeConfirmed === 'PENDING' || AgreeConfirmed === 'DENIED') {
      // setAlert({ title: '필수동의', discription: '필수 동의를 확인해주세요', showAlert: true })
      setAgreeConfirmed('DENIED')
      return
    }
    createUser(getValues('pwd'))
  }

  return (
    <>
      <FormMain onSubmit={handleSubmit(onSubmit)}>
        <FormMain.LabelLayout
          isFirst={true}
          label="이메일"
          infoText={formAlert.email}
          infoStyleId={isValidObj.email !== undefined ? (isValidObj.email ? 'text_green' : 'text_red') : ''}
        >
          <FormMain.InputWithButton
            styleId={isValidObj.email === false ? 'div_with_timer border_red' : 'div_with_timer'}
            inputComp={
              <FormMain.Input
                type="text"
                placeholder="이메일"
                registerFn={register('email', {
                  required: true,
                })}
              />
            }
            buttonComp={
              <FormMain.Button
                onClick={onClickChkEmail}
                styleID={isValidObj.email ? 'btn_with_input active' : 'btn_with_input'}
                width=""
                height=""
                type="button"
              >
                중복 확인
              </FormMain.Button>
            }
          />
        </FormMain.LabelLayout>
        <FormMain.LabelLayout
          label="비밀번호"
          infoText="영문(대문자 포함), 숫자, 특수문자(.!@#$%) 9~20자로 입력해주세요."
          warning={isValidObj.pwd === true ? false : true}
          warnText={formAlert.pwd}
        >
          <FormMain.InputFull
            styleId={isValidObj.pwd === false ? 'div_with_full mb_10 border_red' : 'div_with_full mb_10'}
            iconComp={
              <MaskingIcon
                isMasking={isMasking}
                onClick={() => {
                  setIsMasking(prev => !prev)
                }}
              />
            }
            inputComp={
              <FormMain.Input
                styleID=""
                type={isMasking ? 'password' : 'text'}
                placeholder="비밀번호"
                registerFn={register('pwd', {
                  required: true,
                })}
              />
            }
          />
          <FormMain.InputFull
            styleId={isValidObj.pwd === false ? 'div_with_full mb_10 border_red' : 'div_with_full mb_10'}
            iconComp={
              isValidObj.pwd === true ? (
                <img src={inputCheck} alt="확인" />
              ) : (
                <MaskingIcon
                  isMasking={isMasking}
                  onClick={() => {
                    setIsMasking(prev => !prev)
                  }}
                />
              )
            }
            inputComp={
              <FormMain.Input
                styleID=""
                type={isMasking ? 'password' : 'text'}
                placeholder="비밀번호 재확인"
                registerFn={register('pwdCheck', {
                  required: true,
                })}
              />
            }
          />
        </FormMain.LabelLayout>
        <FormMain.LabelLayout
          warning={isValidObj.cert === true ? false : true}
          warnText={formAlert.cert}
          label="휴대전화 번호"
          infoText="인증문자가 도착하지 않으면 메시지 설정에서 스팸메시지를 확인해 주세요."
        >
          <FormMain.InputWithButton
            styleId={
              isValidObj.cert === false &&
              (formAlert.cert.includes('전화번호') || formAlert.cert.includes('인증번호를 확인'))
                ? 'div_with_timer border_red'
                : 'div_with_timer'
            }
            inputComp={
              <FormMain.Input
                styleID=""
                type="text"
                placeholder="휴대전화번호 입력( ‘ - ‘ 제외 )"
                registerFn={register('tel', {
                  required: true,
                })}
              />
            }
            buttonComp={
              <FormMain.Button
                onClick={onClickCertSendBtn}
                styleID={timer.start || certConfirm ? 'btn_with_input active' : 'btn_with_input'}
                width=""
                height=""
                type="button"
              >
                {certSendBtn}
              </FormMain.Button>
            }
          />
          <FormMain.InputWithTimerButton
            inputComp={
              <FormMain.InputTimer
                divStyleId={
                  isValidObj.cert === false &&
                  (formAlert.cert.includes('인증에 실패') || formAlert.cert.includes('인증번호를'))
                    ? 'div_with_timer border_red'
                    : 'div_with_timer'
                }
                type="text"
                placeholder="인증번호 입력"
                registerFn={register('certNum', {
                  required: true,
                })}
                certConfirm={certConfirm}
                disabled={certConfirm === true ? true : false}
              />
            }
            buttonComp={
              <FormMain.Button
                onClick={onClickConfirmBtn}
                styleID={certConfirm ? 'btn_with_timer active' : 'btn_with_timer'}
                width=""
                height=""
                type="button"
              >
                확인
              </FormMain.Button>
            }
          />
        </FormMain.LabelLayout>
        <MainUserCreateSvcPolicy
          info={AgreeConfirmed === 'DENIED' ? '필수 동의항목을 체크해 주세요' : ''}
          validCheck={validAgreeCheck}
        />
        <FormMain.Button
          styleID={
            certConfirm && AgreeConfirmed === 'SUBMIT'
              ? 'btn_submit_01_active mt_22p6_percent'
              : 'btn_submit_01 mt_22p6_percent'
          }
          width="100%"
          height=""
          type="submit"
        >
          {submitBtnLabel}
        </FormMain.Button>
      </FormMain>
    </>
  )
}

export default MainUserCreateForm
