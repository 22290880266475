import { m } from 'framer-motion'
import { SaleStatusType, SettingType } from '../type/type'
import { Children } from 'react'

export const WATERING_FREQUANCY = [
  { title: '매일', val: 'EVERYDAY' },
  { title: '1', val: '2' },
]
export const VALID_CHAR_IDS = [1, 2, 3, 4, 5, 6, 11, 12, 13, 14, 15, 16, 21, 22, 23, 24, 25, 26, 31, 32, 33, 34, 35, 36]
export const VALID_CHAR_LOC = ['basic', 'kitchen', 'bathroom', 'livingroom']

export const PLANT_SPOT = [
  { name: 'basic', title: '배경 없음', defaultVal: false },
  { name: 'kitchen', title: '주방', defaultVal: false },
  { name: 'bathroom', title: '욕실', defaultVal: false },
  { name: 'livingroom', title: '거실', defaultVal: false },
]

export const weatherDescriptions = {
  200: '가벼운 비를 동반한\n천둥 구름이 예상돼요!',
  201: '비를 동반한 천둥\n구름이 예상돼요!',
  202: '폭우를 동반한 천둥\n구름이 예상돼요!',
  210: '약한 천둥 구름이\n예상돼요!',
  211: '천둥 구름이\n예상돼요!',
  212: '강한 천둥 구름이\n예상돼요!',
  221: '불규칙적 천둥\n구름이 예상돼요!',
  230: '약한 연무를 동반한\n천둥 구름이 예상돼요!',
  231: '연무를 동반한 천둥\n구름이 예상돼요!',
  232: '강한 안개비를 동반한\n천둥 구름이 예상돼요!',
  300: '가벼운 안개비가\n내려요',
  301: '안개비가 내려요',
  302: '강한 안개비가\n내려요',
  310: '가벼운 적은비가\n내려요',
  311: '적은비가 내려요',
  312: '강한 적은비가\n내려요',
  313: '소나기와 안개비가\n내려요',
  314: '강한 소나기와 안개비가\n내려요',
  321: '소나기가 내려요',
  500: '약한 비가 내려요',
  501: '중간 비가 내려요',
  502: '강한 비가 내려요',
  503: '매우 강한 비가\n내려요',
  504: '극심한 비가\n내려요',
  511: '우박이 내려요',
  520: '약한 소나기가 내려요',
  521: '소나기가 내려요',
  522: '강한 소나기가 내려요',
  531: '불규칙적 소나기가 내려요',
  600: '가벼운 눈이 내려요',
  601: '눈이 내려요',
  602: '강한 눈이 내려요',
  611: '진눈깨비가 내려요',
  612: '소나기 진눈깨비가\n내려요',
  615: '약한 비와 눈이\n내려요',
  616: '비와 눈이 내려요',
  620: '약한 소나기 눈이\n내려요',
  621: '소나기 눈이 내려요',
  622: '강한 소나기 눈이\n내려요',
  701: '박무가 내려요',
  711: '연기가 내려요',
  721: '연무가 내려요',
  731: '모래 먼지가 휘날려요',
  741: '안개가 끼는 날이에요',
  751: '모래가 끼는 날이에요',
  761: '먼지가 끼는 날이에요',
  762: '화산재가 끼는 날이에요',
  771: '돌풍이 부는 날이에요',
  781: '토네이도가 부는 날이에요',
  800: '구름 한 점 없는\n맑은 하늘이 예상돼요!',
  801: '약간의 구름이 낀\n하늘이 예상돼요!',
  802: '드문드문 구름이 낀\n하늘이 예상돼요!',
  803: '구름이 거의 없는\n하늘이 예상돼요!',
  804: '구름으로 뒤덮인\n흐린 하늘이 예상돼요!',
  900: '토네이도가\n부는 날이에요',
  901: '태풍이 불어요!\n조심해요!',
  902: '허리케인이에요!\n조심해요!',
  903: '한랭이 예상돼요!',
  904: '고온이 예상돼요!',
  905: '바람이 부는\n날이에요!',
  906: '우박이 내리네요',
  951: '바람이 거의\n없는 날이에요',
  952: '약한 바람이 불어요',
  953: '부드러운 바람이\n불어요',
  954: '중간 세기 바람이\n불어요',
  955: '신선한 바람이 불어요',
  956: '센 바람이 불어요',
  957: '돌풍에 가까운 센\n바람이 불어요',
  958: '돌풍이 불어요',
  959: '심각한 돌풍이 불어요',
  960: '폭풍이 불어요!\n조심해요!',
  961: '강한 폭풍이 불어요!\n조심해요',
  962: '허리케인이 불어요!',
}

export const PLANT_CARE = [
  { name: 'WATERING', title: '물주기' },
  { name: 'PRUNING', title: '가지치기' },
  { name: 'FERTILIZING', title: '영양주기' },
  { name: 'REPOTTING', title: '분갈이하기' },
  { name: 'SUNLIGHT', title: '햇빛쬐기' },
  // { name: 'DEGREE', title: '온도' },
]

export const SELECT_REPEAT_TYPE_DAY = [
  { name: 'CONTINUE', title: '없음' },

  { name: 'DAY', title: '날짜' },
]
export const SELECT_REPEAT_TYPE_WEEK = [{ name: 'CONTINUE', title: '없음' }]

export const SELECT_PERIOD = [
  { name: 'SUN', title: '일요일' },
  { name: 'MON', title: '월요일' },
  { name: 'TUE', title: '화요일' },
  { name: 'WED', title: '수요일' },
  { name: 'THUR', title: '목요일' },
  { name: 'FRI', title: '금요일' },
  { name: 'SAT', title: '토요일' },
]

export const SELECT_REGULAR_INTERVAL = [
  { name: 'WEEK', title: '주별' },
  { name: 'MONTH', title: '월별' },
  { name: 'YEAR', title: '매년' },
]
// 식물광장 메인 셀렉트박스
export const PLANT_PLAZA_SELECT = [
  { name: 'common', title: '전체' },
  { name: 'general', title: '자유' },
  { name: 'qna', title: '질문' },
  { name: 'diary', title: '일지' },
]

export const POST_TYPE = [
  { name: 'general', title: '자유' },
  { name: 'qna', title: '질문' },
]

export const COLOR_SELECT = ['#FFA4A4', '#FFF3B7', '#B2EBDA', '#00C398', '#E1F5FE', '#79C9ED', '#DFC0ED', '#C26CC3']
export const CHAR_COLOR_SELECT = ['#C8927A', '#C26CC3', '#FFF3B7', '#79C9ED', '#E2FFC3', '#FF83A6']

// 푸른 마켓 메인 셀렉트박스
export const GREEN_MARKET_SELECT = [
  { name: 'all', title: '전체' },
  { name: 'market', title: '마켓' },
  { name: 'caring', title: '돌보미' },
]

// 싹싹도감> 상세 > 관련글 셀렉트박스
export const COLLECTION_DETAIL_SELECT = [
  { name: 'all', title: '전체' },
  { name: 'green', title: '푸른 마켓' },
  { name: 'plaza', title: '식물광장' },
]

// 그린마켓 > 상세 > 유저 게시글
export const GREEN_MARKET_POST_SUMMARY = [
  {
    postId: 1,
    img_url:
      'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
    title: '극락조/ 여인초 중형 판매합니다',
    price: 10000,
    stts: 'SOLD',
  },
  {
    postId: 2,
    img_url:
      'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
    title: '극락조/ 여인초 중형 판매합니다',
    price: 10000,
    stts: 'FREE',
  },
  {
    postId: 3,
    img_url:
      'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
    title: '극락조/ 여인초 중형 판매합니다',
    price: 10000,
    stts: 'SALE',
  },
]
// 싹싹 도감 > 관련글 > 푸른마켓
export const COLLECTION_RELATED_POSTS = [
  {
    postId: 1,
    imgInfo: {
      name: '가스베리아',
      id: 1,
      isMarked: true,
      img_url:
        'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
    },
    title: '극락조/ 여인초 중형 판매합니다',
    loc: '용산구 이촌 1동',
    regDttm: '2024-06-12 19:00:00',
    stts: 'SOLD',
    price: 10000,
    goodCnt: 1,
    commentsCnt: 1,
    clicked: true,
  },
  {
    postId: 2,
    imgInfo: {
      name: '가스베리아',
      id: 1,
      isMarked: true,
      img_url:
        'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
    },
    title: '극락조/ 여인초 무나합니다',
    loc: '용산구 이촌 1동',
    regDttm: '2024-06-12 19:00:00',
    stts: 'FREE',
    price: 10000,
    goodCnt: 1,
    commentsCnt: 1,
    clicked: true,
  },
  {
    postId: 3,
    imgInfo: {
      name: '극락조화',
      id: 2,
      isMarked: false,
      img_url:
        'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
    },
    title: '극락조화 분양합니다.',
    loc: '용산구 이촌 1동',
    regDttm: '2024-06-15 13:00:00',
    stts: 'HOLD',
    price: 15000,
  },
]

// 싹싹 도감 > 관련글 > 식물 광장
export const COLLECTION_RELATED_PLAZA_POSTS = [
  {
    articleId: 1,
    createdAt: '2024-07-27T00:47:33.320Z',
    title: '극락조화 잎 부패.',
    content: '극락조 잎 부패됐을 때 어떻게 해야 하는지 아시는 분 계시나요.. ㅜㅜ',
    likeCount: 2,
    commentCount: 1,
    isLiked: true,
    hashtagList: ['식물', '키우기'],
    imageUrls: [
      'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
    ],
  },
  {
    articleId: 1,
    createdAt: '2024-07-27T00:47:33.320Z',
    title: '극락조화 잎 부패.',
    content: '극락조 잎 부패됐을 때 어떻게 해야 하는지 아시는 분 계시나요.. ㅜㅜ',
    likeCount: 2,
    commentCount: 1,
    isLiked: true,

    imageUrls: null,
    hashtagList: ['식물', '키우기'],
  },
  {
    articleId: 1,
    createdAt: '2024-07-27T00:47:33.320Z',
    title: '극락조화 잎 부패.',
    content: '극락조 잎 부패됐을 때 어떻게 해야 하는지 아시는 분 계시나요.. ㅜㅜ',
    likeCount: 2,
    commentCount: 1,
    isLiked: true,
    hashtagList: ['식물', '키우기'],
    imageUrls: [
      'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
    ],
  },
  {
    articleId: 1,
    createdAt: '2024-07-27T00:47:33.320Z',
    title: '극락조화 잎 부패.',
    content: '극락조 잎 부패됐을 때 어떻게 해야 하는지 아시는 분 계시나요.. ㅜㅜ',
    likeCount: 2,
    commentCount: 1,
    isLiked: true,

    imageUrls: null,
    hashtagList: ['식물', '키우기'],
  },
  {
    articleId: 1,
    createdAt: '2024-07-27T00:47:33.320Z',
    title: '극락조화 잎 부패.',
    content: '극락조 잎 부패됐을 때 어떻게 해야 하는지 아시는 분 계시나요.. ㅜㅜ',
    likeCount: 2,
    commentCount: 1,
    isLiked: true,
    hashtagList: ['식물', '키우기'],
    imageUrls: [
      'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
    ],
  },
  {
    articleId: 1,
    createdAt: '2024-07-27T00:47:33.320Z',
    title: '극락조화 잎 부패.',
    content: '극락조 잎 부패됐을 때 어떻게 해야 하는지 아시는 분 계시나요.. ㅜㅜ',
    likeCount: 2,
    commentCount: 1,
    isLiked: true,

    imageUrls: null,
    hashtagList: ['식물', '키우기'],
  },
  {
    articleId: 1,
    createdAt: '2024-07-27T00:47:33.320Z',
    title: '극락조화 잎 부패.',
    content: '극락조 잎 부패됐을 때 어떻게 해야 하는지 아시는 분 계시나요.. ㅜㅜ',
    likeCount: 2,
    commentCount: 1,
    isLiked: true,
    hashtagList: ['식물', '키우기'],
    imageUrls: [
      'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
    ],
  },
  {
    articleId: 1,
    createdAt: '2024-07-27T00:47:33.320Z',
    title: '극락조화 잎 부패.',
    content: '극락조 잎 부패됐을 때 어떻게 해야 하는지 아시는 분 계시나요.. ㅜㅜ',
    likeCount: 2,
    commentCount: 1,
    isLiked: true,

    imageUrls: null,
    hashtagList: ['식물', '키우기'],
  },
]

export const SALE_STTS: { [key: string]: SaleStatusType } = {
  SALE: {
    stts: 'SALE',
    name: '판매중',
    imgName: 'sttsSale.png',
  },
  HOLD: {
    stts: 'HOLD',
    name: '예약중',
    imgName: 'sttsHold.png',
  },
  SOLD: {
    stts: 'SOLD',
    name: '판매완료',
    imgName: 'sttsSold.png',
  },
  FREE: {
    stts: 'FREE',
    name: '무료나눔',
    imgName: 'sttsFree.png',
  },
}

export const COLLECTION_REC_SAMPLE = [
  {
    plantSpeciesId: 1,
    commonName: '가스베리아',
    scientificName: '가스베리아',
    imageUrl:
      'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
  },
  {
    plantSpeciesId: 2,
    commonName: '가자니아',
    scientificName: '가자니아',
    imageUrl:
      'https://kukka-2-media-123.s3.amazonaws.com/media/contents/event_template/7f9e2405-0bcb-41b5-a8fd-a49873d6692c.jpg',
  },
  {
    plantSpeciesId: 3,
    commonName: '거베라',
    scientificName: '거베라',
    imageUrl:
      'https://kukka-2-media-123.s3.amazonaws.com/media/contents/event_template/7f9e2405-0bcb-41b5-a8fd-a49873d6692c.jpg',
  },
  {
    plantSpeciesId: 4,
    commonName: '게발선인장',
    scientificName: '게발선인장',
    imageUrl:
      'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
  },
  {
    plantSpeciesId: 5,
    commonName: '가스베리아',
    scientificName: '가스베리아',
    imageUrl:
      'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
  },
  {
    plantSpeciesId: 6,
    commonName: '가스베리아',
    scientificName: '가스베리아',
    imageUrl:
      'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
  },
]

export const COLLECTION_LIST_SAMPLE = [
  {
    imgUrl: 'https://portfolioshop.speedgabia.com/kpyours/sample-SK-LS-28mm-f-4.5.jpg',
    collectionNm: '초록이들',
    collectionCnt: 13,
    collctionId: 1,
  },
  {
    imgUrl: 'https://portfolioshop.speedgabia.com/kpyours/sample-SK-LS-28mm-f-4.5.jpg',
    collectionNm: '쪼꼬미들',
    collectionCnt: 8,
    collctionId: 2,
  },
]

export const PLANT_PLAZA_POST_LIST_SAMPLE = [
  {
    profileImg: null,
    imgUrl: null,
    postId: 1,
    userName: '장미러버',
    userId: '1',
    regDttm: '2024-06-12 19:00:00',
    hashTag: ['장미', '장미허브', '초보집사', '코코넛', '호호'],
    commentsCnt: 3,
    goodCnt: 1,
    clicked: true,
    title: '잎이 다 떨어졌어요',
    content:
      '지난주까지만 해도 괜찮았는데 점점 시들어가더니 잎이 거의 다 떨어졌네요ㅜㅜ 영양제를 넣어줘야 할까요?? 아님 저는 어떡하나요',
  },
  {
    profileImg: null,
    imgUrl: [
      'https://portfolioshop.speedgabia.com/kpyours/sample-SK-LS-28mm-f-4.5.jpg',
      'https://portfolioshop.speedgabia.com/kpyours/sample-SK-LS-28mm-f-4.5.jpg',
    ],
    postId: 2,
    userName: '장미러버',
    userId: '2',
    regDttm: '2024-06-12 19:00:00',
    hashTag: ['장미', '장미허브', '초보집사'],
    commentsCnt: 3,
    goodCnt: 1,
    clicked: true,
    title: '잎이 다 떨어졌어요',
    content:
      '지난주까지만 해도 괜찮았는데 점점 시들어가더니 잎이 거의 다 떨어졌네요ㅜㅜ 영양제를 넣어줘야 할까요?? 아님',
  },
]

export const PLANT_PESTS = [
  { id: 'watering', name: '갈색 반점', src: '' },
  { id: 'pruning', name: '상처', src: '' },
  { id: 'fertilizing', name: '갈변 및 마름병', src: '' },
  { id: 'repotting', name: '잎부패', src: '' },
]

export const PLANT_GUIDE_SAMPE = [
  { id: 'watering', discription: '1 ~ 2주마다' },
  { id: 'degree', discription: '9ºC ~10ºC' },
  { id: 'soilMng', discription: '모래, 양토, 백악질, 중성, 알칼리성' },
  { id: 'sunlight', discription: '최소 6시간 이상' },
  { id: 'pruning', discription: '병든 잎만' },
]

export const GREEN_CARING_GUIDE_SAMPE = [
  { id: 'date', discription: '4월 20일 (토) - 4월 27일 (토)' },
  { id: 'watering', discription: '하루 1번 물주기' },
  { id: 'degree', discription: '9ºC ~10ºC' },
]

export const PLANT_CARE_DETAIL_SAMPE = ['watering', 'pruning', 'fertilizing', 'repotting', 'sunlight']

export const PLANT_POT = [
  { name: 'hydro', title: '수경재배', defaultVal: false },
  { name: 'earthen', title: '토분', defaultVal: false },
  { name: 'plastic', title: '플라스틱/유리/캔', defaultVal: false },
  { name: 'ceramic', title: '유약/고화도 토분', defaultVal: false },
  { name: 'haging', title: '행잉/목부작', defaultVal: false },
  { name: 'outside', title: '야외', defaultVal: false },
]

export const SAMPLE_CHARACTOR = [
  {
    id: 1,
    name: 'char1',
    smallImg: 'char1.png',
    selectedSmallImg: 'char1.png',
    selectedImg: 'char1.png',
  },
  {
    id: 11,
    name: 'char11',
    smallImg: 'char11.png',
    selectedSmallImg: 'char11.png',
    selectedImg: 'char11.png',
  },
  {
    id: 21,
    name: 'char21',
    smallImg: 'char21.png',
    selectedSmallImg: 'char21.png',
    selectedImg: 'char21.png',
  },
  {
    id: 31,
    name: 'char31',
    smallImg: 'char31.png',
    selectedSmallImg: 'char31.png',
    selectedImg: 'char31.png',
  },
]

export const SAMPLE_ACC = [
  {
    id: 'hat',
  },
  {
    id: 'plantacc',
  },
]

export const SAMPLE_TODO = [
  {
    scheduleId: 0,
    title: '다육이 거래 약속 11시',
    isCompleted: false,
    startDateTime: '2024-07-20T11:24:47.654Z',
    colorType: '#FFA4A4',
    endDateTime: '2024-07-17T11:24:47.654Z',
    frequency: 12,
  },
  {
    scheduleId: 1,
    title: '나탈리 물주기',
    isCompleted: false,
    startDateTime: '2024-07-16T11:24:47.654Z',
    colorType: '#B2EBDA',
    endDateTime: '2024-07-17T11:24:47.654Z',
    frequency: 0,
  },
  {
    scheduleId: 2,
    title: '다육이 거래 약속 11시',
    isCompleted: false,
    startDateTime: '2024-07-17T11:24:47.654Z',
    colorType: '#FFF3B7',
    endDateTime: '2024-07-17T11:24:47.654Z',
    frequency: 15,
  },
]

export const CONTENT_PADDING = 2.125
export const CONTENT_PADDING_TOP = 4.25
export const CONTENT_PADDING_BOTTOM = 3

export const SAMPLE_COLLECTION = [
  {
    plantSpeciesId: 1,
    commonName: '초록이들',
    scientificName: '13개의 식물',
    imageUrl: '',
  },
  {
    plantSpeciesId: 2,
    commonName: '쪼꼬미들',
    scientificName: '8개의 식물',
    imageUrl: '',
  },
  {
    plantSpeciesId: 3,
    commonName: '꽃모음집',
    scientificName: '7개의 식물',
    imageUrl: '',
  },
]

export const SAMPLE_PLANT = [
  {
    plantSpeciesId: 'Rose',
    commonName: '장미',
    scientificName: 'Rose',
    imageUrl: '',
  },
  {
    plantSpeciesId: 'Sunflower',
    commonName: '해바라기',
    scientificName: 'Sunflower',
    imageUrl: '',
  },
  {
    plantSpeciesId: 'Dandelion',
    commonName: '민들레',
    scientificName: 'Dandelion',
    imageUrl: '',
  },
  {
    plantSpeciesId: 'Cactus',
    commonName: '선인장',
    scientificName: 'Cactus',
    imageUrl: '',
  },
  {
    plantSpeciesId: 'Junior Cactus',
    commonName: '주니어카투스',
    scientificName: 'Junior Cactus',
    imageUrl: '',
  },
  {
    plantSpeciesId: 'Royal Cactus',
    commonName: '로얄카투스',
    scientificName: 'Royal Cactus',
    imageUrl: '',
  },
]

export const MAX_FILE_CNT = 5

export const SETTING_LIST: SettingType[] = [
  {
    title: '기타 설정',
    children: [
      { title: '로그아웃', hasPage: true },
      // { title: '탈퇴하기', hasPage: true },
    ],
  },
]
