import {
  WiCloudy,
  WiNightAltLightning,
  WiDayLightning,
  WiWindy,
  WiRainMix,
  WiNightAltRain,
  WiDayCloudy,
  WiNightCloudy,
  WiCloud,
  WiSnowflakeCold,
  WiDayRain,
} from 'react-icons/wi'

import { PiMoonStars, PiSunDim } from 'react-icons/pi'

export default function WeatherIcon({ icon }: { icon: string }) {
  let comp = <></>
  switch (icon) {
    case '01d':
      comp = (
        <>
          <PiSunDim size="48px" />
        </>
      )
      break
    case '02d':
      comp = (
        <>
          <WiDayCloudy size="48px" />
        </>
      )
      break
    case '03d':
    case '03n':
      comp = (
        <>
          <WiCloud size="48px" />
        </>
      )
      break
    case '04d':
    case '04n':
      comp = (
        <>
          <WiCloudy size="48px" />
        </>
      )
      break
    case '09d':
    case '09n':
      comp = (
        <>
          <WiRainMix size="48px" />
        </>
      )
      break
    case '10d':
      comp = (
        <>
          <WiDayRain size="48px" />
        </>
      )
      break
    case '11d':
      comp = (
        <>
          <WiDayLightning size="48px" />
        </>
      )
      break
    case '13d':
    case '13n':
      comp = (
        <>
          <WiSnowflakeCold size="48px" />
        </>
      )
      break
    case '15d':
    case '15n':
      comp = (
        <>
          <WiWindy size="48px" />
        </>
      )
      break
    case '01n':
      comp = (
        <>
          <PiMoonStars size="48px" />
        </>
      )
      break
    case '02n':
      comp = (
        <>
          <WiNightCloudy size="48px" />
        </>
      )
      break

    case '10n':
      comp = (
        <>
          <WiNightAltRain size="48px" />
        </>
      )
      break
    case '11n':
      comp = (
        <>
          <WiNightAltLightning size="48px" />
        </>
      )
      break
    default:
      comp = (
        <>
          <PiSunDim size="48px" />
        </>
      )
      break
  }
  return <>{comp}</>
  // return (
  //   <>
  //     <img src={`https://openweathermap.org/img/wn/${icon}.png`} alt="날씨 아이콘" />
  //   </>
  // )
}
