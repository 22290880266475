import styled from 'styled-components'
import MaskingImg from '../../../assets/images/icon/MakingIcon.png'
import NoMaskingImg from '../../../assets/images/icon/NoMaskingIcon.png'

export default function MaskingIcon({ isMasking, onClick }: { isMasking: boolean; onClick: () => void }) {
  if (isMasking) {
    return <ImgMasking src={MaskingImg} onClick={onClick} alt="비밀번호 마스킹" />
  } else {
    return <ImgMasking src={NoMaskingImg} onClick={onClick} alt="비밀번호 마스킹" />
  }
}

const ImgMasking = styled.img`
  &:hover {
    cursor: pointer;
  }
`
