import { FormEvent, useEffect } from 'react'
import FormMain from 'src/components/common/form/FormMain'
import { useFindPwdCheckValidate } from './hooks/MainFindPwdCustomHook'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useRecoilState } from 'recoil'
import { timerState } from 'src/utils/common/state/state'

export default function MainFindPwdForm({ onChangePage }: { onChangePage: () => void }) {
  const { checkTelNum, checkCertNum, formAlert, isValidObj, timer, certConfirm, certSendBtn, checkEmail } =
    useFindPwdCheckValidate()

  // useForm 훅을 한 번만 사용
  const method = useForm()
  const { register, watch, getValues } = method

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (certConfirm) {
      onChangePage()
    } else {
      if (!checkEmail(getValues('email'))) return
      if (!checkTelNum(getValues('tel'), getValues('email'))) return
      onClickConfirmBtn()
    }
    // 폼 제출 로직을 여기에 작성
  }

  // 인증번호 전송 (휴대전화번호 유효성 검사)
  const onClickCertSendBtn = () => {
    if (isValidObj.email) {
      checkTelNum(getValues('tel'), getValues('email'))
    } else {
      checkEmail(getValues('email'))
    }
  }

  const onClickConfirmBtn = () => {
    checkCertNum(getValues('cert'))
  }

  return (
    <FormMain onSubmit={onSubmit}>
      <FormMain.LabelLayout
        label="이메일"
        warning={isValidObj.email === false ? true : false}
        warnText={formAlert.email}
        isFirst={true}
      >
        <FormMain.InputFull
          styleId={isValidObj.email === false ? 'div_with_full border_red mb_10 ' : 'div_with_full mb_10'}
          inputComp={
            <FormMain.Input
              styleID=""
              type="text"
              placeholder="이메일"
              registerFn={register('email', {
                required: true,
                onChange: () => {
                  checkEmail(getValues('email'))
                },
              })}
            />
          }
        />
      </FormMain.LabelLayout>
      <FormMain.LabelLayout
        warning={isValidObj.cert === false ? true : false}
        warnText={formAlert.cert}
        label="휴대전화 번호"
        infoText="인증문자가 도착하지 않으면 메시지 설정에서 스팸메시지를 확인해 주세요."
      >
        <FormMain.InputWithButton
          styleId={
            formAlert.cert.length > 0 &&
            (formAlert.cert.includes('전화번호') || formAlert.cert.includes('인증번호를 확인'))
              ? 'div_with_timer border_red'
              : 'div_with_timer'
          }
          inputComp={
            <FormMain.Input
              styleID=""
              type="text"
              placeholder="휴대전화번호 입력( ‘ - ‘ 제외 )"
              registerFn={register('tel', {})}
            />
          }
          buttonComp={
            <FormMain.Button
              onClick={onClickCertSendBtn}
              styleID={timer.start || certConfirm ? 'btn_with_input active' : 'btn_with_input'}
              width=""
              height=""
              type="button"
            >
              {certSendBtn}
            </FormMain.Button>
          }
        />
        <FormMain.InputWithTimerButton
          inputComp={
            <FormMain.InputTimer
              divStyleId={
                formAlert.cert.length > 0 &&
                (formAlert.cert.includes('인증에 실패') || formAlert.cert.includes('인증번호를 확인'))
                  ? 'div_with_timer border_red'
                  : 'div_with_timer'
              }
              type="text"
              placeholder="인증번호 입력"
              registerFn={register('cert')}
              certConfirm={certConfirm}
            />
          }
          buttonComp={
            <FormMain.Button
              onClick={onClickConfirmBtn}
              styleID={certConfirm ? 'btn_with_timer active' : 'btn_with_timer'}
              width=""
              height=""
              type="button"
            >
              확인
            </FormMain.Button>
          }
        />
      </FormMain.LabelLayout>
      <FormMain.Button
        styleID={certConfirm ? 'btn_submit_01_active' : 'btn_submit_01'}
        width="100%"
        height=""
        marginTop="197px"
        type="submit"
      >
        비밀번호 재설정
      </FormMain.Button>
    </FormMain>
  )
}
