/*eslint-disable */
import styled from 'styled-components'
import divideStyleIDString from '../../../utils/common/scripts/divideStyleIDString'
import styles from './styles/CustomButton.module.css'

export type CustomButtonProps = {
  children?: JSX.Element | JSX.Element[] | string
  styleID?: string
  onClick?: () => void
  disabled?: boolean
  width?: string
  height?: string
  type: 'button' | 'submit'
  padding?: string
  margin?: string
  marginTop?: string
  comp?: React.ReactElement
}

export function CustomButton({
  children,
  styleID = '',
  onClick,
  disabled = false,
  width = '',
  height = '',
  type = 'button',
  padding = '',
  margin = '',
  marginTop = '',
  comp,
}: CustomButtonProps) {
  if (comp) {
    return (
      <ButtonWithCompStyled
        width={width}
        height={height}
        padding={padding}
        margin={margin}
        marginTop={marginTop}
        onClick={() => {
          if (!disabled) {
            onClick?.()
          }
        }}
        className={`${divideStyleIDString(styles, styleID)}`}
      >
        {comp}
        {children ? children : null}
      </ButtonWithCompStyled>
    )
  } else {
    return (
      <ButtonStyled
        type={type}
        disabled={disabled}
        className={`${divideStyleIDString(styles, styleID)}`}
        onClick={onClick}
        width={width}
        height={height}
        padding={padding}
        margin={margin}
        marginTop={marginTop}
      >
        {children}
      </ButtonStyled>
    )
  }
}

interface ButtonStyledProps {
  width: string
  height: string
  padding: string
  margin: string
  marginTop: string
}

const ButtonStyled = styled.button.withConfig({
  shouldForwardProp: prop => !['width', 'height', 'padding', 'margin', 'marginTop'].includes(prop),
})<ButtonStyledProps>`
  width: ${props => props.width};
  height: ${props => props.height};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  margin-top: ${props => props.marginTop};
`

const ButtonWithCompStyled = styled.div.withConfig({
  shouldForwardProp: prop => !['width', 'height', 'padding', 'margin', 'marginTop'].includes(prop),
})<ButtonStyledProps>`
  width: ${props => props.width};
  height: ${props => props.height};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  margin-top: ${props => props.marginTop};
  &:hover {
    cursor: pointer;
  }
`
