import styled from 'styled-components'
import thirdImg from '../../assets/images/info/book.png'
import firstImg from '../../assets/images/info/compi2.png'
import secondImg from '../../assets/images/info/plant.png'
import fourthImg from '../../assets/images/info/market.png'
import AuthButtonGroup from 'src/components/specific/login/AuthButtonGroup'
import SwiperMain from 'src/components/common/swiper/SwiperMain'
import LoginDrawer from 'src/components/specific/login/LoginDrawer'
import Content from 'src/components/common/layout/Content'
import MuiMain from 'src/components/common/mui/MuiMain'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'

const ColorText = styled.span`
  color: #00c398;
`

// 스와이퍼 안에 들어갈 데이터
export const dataObjArr = [
  {
    title: (
      <>
        <div>내 식물을</div>
        <div>
          <ColorText>캐릭터</ColorText>로 만나보세요.
        </div>
      </>
    ),
    imgSrc: firstImg,
    subText: (
      <>
        <div>친밀도를 쌓으면 더 다양한 캐릭터와</div>
        <div>만나볼 수 있어요!</div>
      </>
    ),
    width: '214px',
    height: '211px',
  },
  {
    title: (
      <>
        <div>
          식물을 <ColorText>거래</ColorText>하거나,
        </div>
        <div>
          <ColorText>돌보미</ColorText>를 구할 수 있어요.
        </div>
      </>
    ),
    imgSrc: thirdImg,
    subText: (
      <>
        <div>집에 혼자 남겨진 식물을 위해</div>
        <div>돌보미를 구해보세요.</div>
      </>
    ),
    width: '273px',
    height: '219px',
  },
  {
    title: (
      <>
        <div>
          <ColorText>식물광장</ColorText>에서
        </div>
        <div>
          <ColorText>사람</ColorText>들과 소통 해보세요
        </div>
      </>
    ),
    imgSrc: secondImg,
    subText: (
      <>
        <div>내 식물을 자랑하거나,</div>
        <div>궁금한 건 언제든지 물어볼 수 있어요.</div>
      </>
    ),
    width: '315px',
    height: '253px',
  },
  {
    title: (
      <>
        <div>
          나만의 <ColorText>싹싹도감</ColorText>을
        </div>
        <div>만들어보세요</div>
      </>
    ),
    imgSrc: fourthImg,
    subText: (
      <>
        <div>집에 혼자 남겨진 식물을 위해</div>
        <div>돌보미를 구해보세요.</div>
      </>
    ),
    width: '259px',
    height: '209px',
  },
]

// SwiperMain: 리액트 스와이퍼 사용한 도메인에 대한 간단한 설명
// AuthButtonGroup: 로그인 or 회원가입 버튼
// LoginDrawer: 로그인 버튼 클릭시 랜더링되는 로그인 화면

export default function Login() {
  return (
    <>
      <Container>
        <MuiMain>
          <Content
            component={
              <>
                <SwiperMain dataObjArr={dataObjArr} />
                <AuthButtonGroup />
                <LoginDrawer />
              </>
            }
          />
        </MuiMain>
      </Container>
    </>
  )
}

// Loader 함수
export const action = async () => {}

const Container = styled.div`
  width: 100%;

  background: white;
  height: 100%;

  position: relative;
  overflow-y: hidden;
  overflow: hidden;
  touch-action: none;
`
