import styled from 'styled-components'
import good from '../../../assets/images/post/goodGray.png'
import watch from '../../../assets/images/post/watchGray.png'
import comment from '../../../assets/images/post/commentGray.png'

type PostViewCountIconType = {
  watchCnt: number
  goodCnt: number
  commentCnt: number
}

export default function PostViewCountIcon({ watchCnt, goodCnt, commentCnt }: PostViewCountIconType) {
  return (
    <Wrap>
      <Div>
        <Watch src={watch} alt="조회수" />
        <span>{watchCnt}</span>
      </Div>
      <Div>
        <Good src={good} alt="좋아요수" />
        <span>{goodCnt}</span>
      </Div>
      <Div>
        <Comment src={comment} alt="댓글수" />
        <span>{commentCnt}</span>
      </Div>
    </Wrap>
  )
}

const Wrap = styled.div`
  /* Frame 214 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
`
const Div = styled.div`
  display: flex;
  align-items: center;

  /* 7 */

  span {
    margin-left: 4px;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    color: #979797;
  }
`
const Good = styled.img`
  width: 12px;
  height: 10px;
`
const Comment = styled.img`
  width: 13px;
  height: 11px;
`

const Watch = styled.img`
  width: 14px;
  height: 10px;
`
