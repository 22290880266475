import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MuiMain from 'src/components/common/mui/MuiMain'
import Title from 'src/components/common/title/Title'
import useDeletePlant from 'src/components/specific/plant/hooks/getPlantInfoCustomHook'
import LoadingSpinner from 'src/components/common/container/LoadingSpinner'
import { useNavigate, useNavigation, useParams } from 'react-router-dom'
import SelectChar from 'src/components/specific/plant/create/SelectChar'

export default function CharacterUpdate() {
  const { deletePlant, frmtPlant, loadinPlant, deletingPlant, deletingSuccess, deletingFailed } = useDeletePlant()
  const navigate = useNavigate()
  const { plantId } = useParams()

  return (
    <>
      <ContainerLayout styleId="div_container_06">
        <MuiMain>
          <Title
            onClick={() => {
              navigate(`/plant/detail/${plantId}`, { state: { direction: 'back' } })
            }}
            title="캐릭터 변경"
          />
          <Content component={<>{frmtPlant ? <SelectChar updateItem={frmtPlant} /> : <LoadingSpinner />}</>} />
        </MuiMain>
      </ContainerLayout>
    </>
  )
}
