import styled from 'styled-components'
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp'
import DotButton from '../button/DotButton'
/*
    파일명 : DiaryButtonContainer.tsx
    설명 : 식물 상세 페이지 > 일지 > 일지 버튼
    작성일 : 2024-06-07
*/
export default function DiaryButtonContainer({ content, children }: { content: string; children: React.ReactNode }) {
  return (
    <DiaryContainer>
      <InnerContainer>
        <p>{content}</p>

        {children}
      </InnerContainer>
    </DiaryContainer>
  )
}

const InnerContainer = styled.div``

const DiaryContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  place-content: center;

  align-items: center;
  text-align: center;
  border-radius: 7px;

  /* Frame 276 */

  height: 130px;

  /* White */
  background: #ffffff;
  /* Main Shadow */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 13px;

  p {
    /* 아직 작성된 일지가 없어요! */
    margin-bottom: 23px;
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    letter-spacing: -0.05em;

    color: #979797;
  }
`
