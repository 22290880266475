/*
    작성자 : 김효은
    작성일 : 2024-05-04 
    설명 : 유효성 검사

*/

/*
    null값 체크
    @param : {key: value}
    @return 
    {
        isValid : 유효성 여부,
        unValidKey : isValid가 false일 때 대상 key값
        unValidName : isValid가 false일 때 대상 이름값
    }

*/
export const validateNullCheck = (targetObj: { [key: string]: string | number | Date }, nameArr: string[]) => {
  let isValid = true
  let unValidKey = ''
  let unValidName = ''
  let index = 0

  for (const key in targetObj) {
    const value = targetObj[key]
    if (value === null || value === undefined || value === '') {
      isValid = false
      unValidKey = key
      unValidName = nameArr[index]
      break
    }
    index++
  }

  return {
    isValid,
    unValidKey,
    unValidName,
  }
}

export const validateRegExpCheck = (targetObj: { [key: string]: string }, nameArr: string[], regExpArr: string[]) => {
  let isValid = true
  let unValidKey = ''
  let unValidName = ''
  let index = 0

  for (const key in targetObj) {
    const regex = new RegExp(regExpArr[index])
    const value = targetObj[key]
    if (!regex.test(value)) {
      isValid = false
      unValidKey = key
      unValidName = nameArr[index]
      break
    }
    index++
  }

  return {
    isValid,
    unValidKey,
    unValidName,
  }
}
