import { useLocation, useNavigate } from 'react-router-dom'
import BookmarkItem from 'src/components/common/content/BookmarkItem'
import CollectionGridLayout from 'src/components/common/layout/CollectionGridLayout'
import { CollectionDetailItemType, collectionRecSampleType } from 'src/utils/common/type/type'
import { generateRandomKey, onSaveHistory } from 'src/utils/common/scripts/common'
import BookMarkButton from 'src/components/common/button/BookMarkButton'
import NoDataContainer from 'src/components/common/container/NoDataContainer'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/scrollbar'
import NoDataContainer2 from 'src/components/common/container/NoDataContainer2'
import EndLine from 'src/components/common/line/EndLie'

/*
    파일명 : CollectionRecList.tsx
    설명 : 싹싹도감 메인 > 식물 둘러보기 > 목록
    작성일 : 2024-06-13
*/
export default function CollectionRecList({
  arr,
  editMode = false,
  onClick,
  isPending,
  h,
  changedBookmark,
  onClickBookMark,
  onScrollEnd,
  clickedDefault,
  scroll,
  onChangeBookmark,
  onSuccessDeleting,
  onClickItem,
}: {
  h: string
  onSuccessDeleting?: () => void
  isPending: boolean
  clickedDefault: boolean
  editMode?: boolean
  arr: CollectionDetailItemType[] | null | undefined
  onClick?: (id: string | number) => void
  onClickBookMark?: (id: string | number, clicked: boolean) => void
  onScrollEnd: () => void
  scroll: boolean
  changedBookmark?: number | string | null
  onChangeBookmark?: () => void
  onClickItem?: (id: string | number) => void
}) {
  const navigate = useNavigate()
  const location = useLocation()

  const isPendingComp = (
    <>
      {[...Array(6)].map((_, index) => {
        return (
          <>
            <BookmarkItem
              editMode={editMode}
              onClick={() => {}}
              iconComp={<></>}
              key={generateRandomKey()}
              item={null}
            />
          </>
        )
      })}
    </>
  )

  if (arr === null || arr === undefined || arr?.length === 0) {
    if (isPending) {
      return (
        <>
          <CollectionGridLayout h={h} scroll={scroll} onScrollEnd={() => {}}>
            {isPendingComp}
          </CollectionGridLayout>
        </>
      )
    } else {
      return (
        <>
          <NoDataContainer2 mt="50px" title="식물이 없어요" discription="도감 아이템이 없어요!" />
        </>
      )
    }
  }

  return (
    <>
      <CollectionGridLayout h={h} scroll={scroll} onScrollEnd={() => {}}>
        {arr.map(item => (
          <BookmarkItem
            editMode={editMode}
            onClick={() => {
              if (!editMode) {
                onSaveHistory(item.plantSpeciesId, 'recently_clicked')
                if (onClickItem) {
                  onClickItem?.(item.plantSpeciesId)
                } else {
                  navigate(`/collection/${item.plantSpeciesId}`, { state: { backpath: location.pathname } })
                }
              } else {
                onClick?.(item.plantSpeciesId)
                // setClicked(prev => !prev)
              }
            }}
            iconComp={
              <BookMarkButton
                onSuccessDeleting={() => {
                  onSuccessDeleting?.()
                }}
                clickedDefault={clickedDefault}
                editMode={editMode}
                changedBookmark={changedBookmark}
                onClickBookMark={(id, clicked) => {
                  onClickBookMark?.(id, clicked)
                }}
                onChangeBookmark={onChangeBookmark}
                plantSpeciesId={item.plantSpeciesId}
              />
            }
            key={generateRandomKey()}
            item={{
              name: item.commonName,
              id: item.plantSpeciesId,

              img_url: item.imageUrl,
            }}
          />
        ))}
        {isPending ? isPendingComp : <></>}
        <EndLine hidden={true} onShowEndPage={onScrollEnd} />
      </CollectionGridLayout>
    </>
  )
}
