import Content from 'src/components/common/layout/Content'
import styled from 'styled-components'
import CollectionDetailImgList from '../../collection/detail/CollectionDetailImgList'
import CollectionDetailTitle from '../../collection/detail/CollectionDetailTitle'
import PlantInfoName from '../../plant/sub/PlantInfoName'
import { displayedAt } from 'src/utils/common/scripts/common'
import PostViewCountIcon from 'src/components/common/icon/PostViewCountIcon'
import PostDetailContent from 'src/components/common/content/PostDetailContent'
import HashTagList from 'src/components/common/content/HashTagList'
import LineOne from 'src/components/common/line/LineOne'
import GreenLocationInfo from '../sub/GreenLocationInfo'
import GreenPlantInfo from '../sub/GreenPlantInfo'
import { GREEN_CARING_GUIDE_SAMPE } from 'src/utils/common/constants/constants'
import GreenSellerPosts from '../sub/GreenSellerPosts'
import GreenChatBtn from '../sub/GreenChatBtn'
import PlantInfoGuide from '../../plant/sub/PlantInfoGuide'
import { useNavigate } from 'react-router-dom'

export default function GreenDetailMain() {
  const navigate = useNavigate()

  return (
    <>
      <Content
        pb={0}
        pt={0}
        p={0}
        component={
          <Wrap>
            <CollectionDetailImgList
              backUrl="/green"
              srcArr={[
                'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
                'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
              ]}
            />
          </Wrap>
        }
      />
      <Content
        pb={0}
        pt={0}
        component={
          <>
            <PlantInfoName
              iconComp={<PostViewCountIcon commentCnt={0} goodCnt={0} watchCnt={0} />}
              stts="SOLD"
              name="다육이 팔아요"
              scienName={displayedAt('2024-06-21 10:00:00')}
            />
            <PlantInfoGuide mt="20px" careGuideList={GREEN_CARING_GUIDE_SAMPE} />
            <PostDetailContent content="다육이 팔아요! 물을 많이 먹는 식물이 아니라 순둥순둥하게 잘 자랍니다!  2개당 가격으로 팔아요 ㅎㅎ" />

            <HashTagList onClickItem={() => {}} hashTagArr={['판매', '다육이']} />

            <GreenLocationInfo />
            <GreenPlantInfo />
            <GreenSellerPosts />
          </>
        }
      />
      <Content
        pb={0}
        pt={0}
        p={0}
        component={
          <>
            <LineOne mt="19px" />
            <GreenChatBtn
              isSold={true}
              clicked={true}
              price={5000}
              onClick={() => {
                // TODO : 조건문 (만약 판매중인 상품이라면)
                navigate('chat/userId')
              }}
            />
          </>
        }
      />
    </>
  )
}

const Wrap = styled.div`
  margin-bottom: 20px;
`

const HashTagWrap = styled.div`
  margin-top: 8px;
`
