import styles from './styles/Title.module.css'
import backBtn from '../../../assets/images/icon/backBtn.png'
import { useLocation, useNavigate } from 'react-router-dom'
import closeBtn from '../../../assets/images/icon/closeBtn.png'
import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import styled from 'styled-components'
import { ProfileLevelComp } from '../content/ProfileHeader'
import { Skeleton } from '@mui/material'

type TitleType = {
  title: string | null
  backPath?: string
  close?: boolean
  styleID?: string
  onClick?: () => void
  subTitle?: string | null
  comp?: JSX.Element | JSX.Element[] | React.ReactNode
  marginTop?: string
  level?: string | null
  showBack?: boolean
}

export default function Title({
  marginTop = '6.2%',
  showBack = true,
  comp,
  subTitle = '',
  onClick,
  title,
  backPath = '',
  close = false,
  styleID = 'div_container',
  level,
}: TitleType) {
  const navigate = useNavigate()
  const location = useLocation()
  function onClickBtn() {
    if (backPath) {
      navigate(backPath, { state: { direction: 'back' } })
    }

    onClick?.()
  }

  let levelComp = <></>
  if (level) {
    levelComp = ProfileLevelComp(level)
  }

  let backComp = (
    <>
      <div />
    </>
  )
  if (showBack) {
    backComp = (
      <>
        {close ? (
          <img className={styles.btn_close} src={closeBtn} alt="닫기" onClick={onClickBtn} />
        ) : (
          <img className={styles.btn_back} src={backBtn} alt="뒤로" onClick={onClickBtn} />
        )}
      </>
    )
  }

  if (comp) {
    return (
      <div className={`${divideStyleIDString(styles, styleID)}`}>
        {backComp}

        <div className={styles.div_title_01}>
          {levelComp}
          <div>
            {title ? (
              <span>{title}</span>
            ) : (
              <>
                <Skeleton width={60} height={20} variant="rounded" />
              </>
            )}
            {subTitle && <h1 className={styles.p_sub_title}>{subTitle}</h1>}
            {subTitle === null && <Skeleton sx={{ mt: '2px' }} width={60} height={20} variant="rounded" />}
          </div>
        </div>

        {comp}
      </div>
    )
  } else {
    return (
      <div className={`${divideStyleIDString(styles, styleID)}`}>
        {backComp}

        <div className={styles.div_title_01}>
          {levelComp}
          <div>
            {title ? (
              <span>{title}</span>
            ) : (
              <>
                <Skeleton width={60} height={20} variant="rounded" />
              </>
            )}
            {subTitle && <SubTitle className="plant">{subTitle}</SubTitle>}
            {subTitle === null && <Skeleton sx={{ mt: '2px' }} height={20} variant="rounded" />}
          </div>
        </div>
      </div>
    )
  }
}

const SubTitle = styled.div`
  margin-top: 2px;
  /* text-align: justify; */
  border: none;
  background-color: unset;
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.05em;
  color: #979797;
  /* margin-right: 17px; */

  &.plant {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    text-align: center;

    color: #1c1c1c;
  }
`
