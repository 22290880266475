import * as React from 'react'
import { Global } from '@emotion/react'
import { styled } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { grey } from '@mui/material/colors'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Title from '../title/Title'
import { CONTENT_PADDING, CONTENT_PADDING_BOTTOM, CONTENT_PADDING_TOP } from 'src/utils/common/constants/constants'
import { motion } from 'framer-motion'
import FixedButtonLayout from './FixedButtonLayout'
import FormMain from '../form/FormMain'

const drawerBleeding = 56

interface Props {
  window?: () => Window
  children: React.ReactNode
  open: boolean
  onClose: () => void
  height: string
  p?: number
  pb?: number
  pt?: number
  title?: string
  closeInner?: boolean
}

const Root = styled('div')(({ theme }) => ({
  height: 'auto',
  overflow: 'hidden',
}))

const StyledBox = styled(motion.div)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],

  position: 'absolute',
  top: -drawerBleeding,

  visibility: 'visible',
  right: 0,
  left: 0,

  transition: 'height 0.3s ease',
}))

export default function DrawerChat(props: Props) {
  const {
    window,
    children,
    onClose,
    open,
    title = '일지 작성',
    height,
    p = CONTENT_PADDING,
    pb = CONTENT_PADDING_BOTTOM,
    pt = CONTENT_PADDING_TOP,
    closeInner = false,
  } = props
  const [innerOpen, setInnerOpen] = React.useState(false)
  const [toggle, setToggle] = React.useState(false)

  const [hght, setHght] = React.useState(height)
  const [direction, setDirection] = React.useState<null | string>(null)
  const touchStartX = 0
  const touchStartY = 0

  const toggleDrawer = (newOpen: boolean) => () => {
    if (newOpen === false) {
      setHght(hght)
    }
    setInnerOpen(newOpen)
  }

  React.useEffect(() => {
    if (open) {
      setInnerOpen(open)
    }
  }, [open])
  React.useEffect(() => {
    setHght(height)
  }, [height])

  // React.useEffect(() => {
  //   if (direction === 'up') {
  //     if (hght === defaultH) {
  //       setHght('100%')
  //     }
  //   }
  // }, [direction])

  React.useEffect(() => {
    if (closeInner) {
      setInnerOpen(!open)
    }
  }, [closeInner])

  const container = window !== undefined ? () => window().document.body : undefined

  if (!open) {
    return null
  } else {
    return (
      <Root>
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              height: `${hght}`,
              overflow: 'visible',
              maxWidth: '390px',
              display: 'flex',
              margin: 'auto',
            },
          }}
        />

        <Box sx={{ overflow: 'auto', textAlign: 'center', pt: 1 }}>
          <SwipeableDrawer
            style={{ height: 'auto' }}
            container={container}
            anchor="bottom"
            open={innerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            ModalProps={{
              keepMounted: true,
              onTransitionExited: onClose,
            }}
          >
            <StyledBox>
              <Box
                sx={{
                  p: p,
                }}
              >
                {children}
              </Box>
            </StyledBox>
          </SwipeableDrawer>
        </Box>
      </Root>
    )
  }
}
