import React from 'react'
import LoadingContainer from 'src/components/common/container/LoadingContainer'
import CollectionImgInfo from 'src/components/common/content/CollectionImgInfo'
import GreenImgInfo from 'src/components/common/content/GreenImgInfo'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import { GreenMarketPostSummaryType } from 'src/utils/common/type/type'
import styled from 'styled-components'

type GreenPostsList = {
  itemArr: GreenMarketPostSummaryType[] | null
}

export default function GreenPostsList({ itemArr }: GreenPostsList) {
  if (!itemArr) {
    return (
      <Wrap>
        {Array.from({ length: 3 }).map((_, index) => (
          <React.Fragment key={generateRandomKey()}>
            <div onClick={() => {}}>
              <LoadingContainer width="100%" height="126px" margin="0px 0px 6px 0px" />
              <GreenImgInfo item={null} />
            </div>
          </React.Fragment>
        ))}
      </Wrap>
    )
  } else {
    return (
      <Wrap>
        {itemArr.map((item: GreenMarketPostSummaryType, index) => {
          return (
            <React.Fragment key={generateRandomKey()}>
              <div onClick={() => {}}>
                <Img $src={item.img_url} />
                <GreenImgInfo
                  item={{
                    name: item.title,
                    price: item.price,
                    stts: item.stts,
                  }}
                />
              </div>
            </React.Fragment>
          )
        })}
      </Wrap>
    )
  }
}

const Wrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Three columns with equal width */
  gap: 20px 12px; /* 위아래는 12px, 양옆은 7px */
`

const Img = styled.div<{ $src: string }>`
  box-sizing: border-box;

  width: 100%;
  aspect-ratio: 172 / 126;
  margin-bottom: 9px;
  /* Rectangle 141 */
  border-radius: 7px;
  background-repeat: no-repeat; /* 이미지 반복 없음 */
  background-position: center; /* 이미지를 가운데 정렬 */
  background-image: ${({ $src }) => `url(${$src})`}; /* src 속성으로부터 이미지 URL을 받아와 배경 이미지로 설정 */
`
