import styles from './SubtitleContainer.module.css'

export default function SubtitleContainer({ title, title2 }: { title: string; title2: string }) {
  return (
    <div className={styles.div_container}>
      <span>{title}</span>
      <span>{title2}</span>
    </div>
  )
}
