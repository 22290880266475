import styled from 'styled-components'
import LoadingContainer from '../container/LoadingContainer'

export default function PostDetailContent({ content }: { content: string | null }) {
  if (content) {
    return (
      <Wrap>
        <p>{content}</p>
      </Wrap>
    )
  } else {
    return <LoadingContainer margin="20px 0px 18px 0px" width="100%" height="20px" />
  }
}

const Wrap = styled.div`
  margin-top: 20px;
  margin-bottom: 18px;
  p,
  span {
    text-align: left;

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    /* or 153% */
    letter-spacing: -0.05em;

    color: #1c1c1c;
  }
`
