import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MotionFadeOutLayout from 'src/components/common/layout/MotionFadeOutLayout'
import MotionUpLayout from 'src/components/common/layout/MotionUpLayout'
import MuiMain from 'src/components/common/mui/MuiMain'
import RcntSearched from 'src/components/specific/collection/search/RcntSearched'
import RcntVwdCollections from 'src/components/specific/collection/search/RcntVwdCollections'
import TopSearched from 'src/components/specific/collection/search/TopSearched'
import SearchField from 'src/components/specific/plant/sub/SearchField'
import { plantTypeType } from 'src/utils/common/type/type'

/*
    파일명 : CollectionSearch.tsx
    설명 : 싹싹도감 > 검색 화면
    작성일 : 2024-06-16
*/
export default function CollectionSearch() {
  const [isVisible, setIsVisible] = useState(true)
  const navigate = useNavigate()
  const [plantList, setPlantLists] = useState<plantTypeType[] | undefined>(undefined)
  const onReceiveDataHandler = (lists: plantTypeType[] | undefined) => {
    setPlantLists(lists)
  }

  return (
    <ContainerLayout styleId="div_container_02">
      <MuiMain>
        <Content
          pb={0}
          component={
            <SearchField
              type="COLLECTION"
              onClick={() => {
                navigate('plant')
              }}
              disabled={true}
              onClickBackBtn={() => {
                navigate('../', { state: { direction: 'back' } })
              }}
              onReceiveData={() => {}}
            />
          }
        />
        <Content pt={0} p={0} pb={0} component={<RcntSearched />} />

        <Content pt={0} p={0} pb={0} component={<RcntVwdCollections />} />
        <Content pt={0} component={<TopSearched />} />
      </MuiMain>
    </ContainerLayout>
  )
}
