import React, { useEffect, useState } from 'react'
import LoadingSpinner from 'src/components/common/container/LoadingSpinner'
import NoDataContainer from 'src/components/common/container/NoDataContainer'
import sampleProfile from '../../../../assets/images/post/sampleProfile.png'
import styled from 'styled-components'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import SwiperCore, { Swiper as swit } from 'swiper' // 타입지정을 위해 필요하다.
import useDeleteComment, { useGetComments } from '../hooks/PlazaCreateCustomHook'
import ProfileHeader from 'src/components/common/content/ProfileHeader'
import { MenuToggle } from 'src/components/common/title/TitleLight'
import { CommentListType, CommentType } from 'src/utils/common/type/type'
import { formatDttm, generateRandomKey } from 'src/utils/common/scripts/common'
import { CommentContent } from 'src/components/common/content/PostContent'
import { PostCommentDetail, PostReplyDetail } from 'src/components/common/content/PostComment'
import { useInView } from 'react-intersection-observer'
import { useLocation, useNavigate } from 'react-router-dom'
import NoDataContainer2 from 'src/components/common/container/NoDataContainer2'

export default function PlazaCommentList({
  postId,
  onSwitchCommentType,
  onClickUpdateComment,
  isLoading,
  setArticleId,
  requestApi,
  result,
}: {
  result: CommentListType[] | null | undefined
  requestApi: () => void
  isLoading: boolean
  setArticleId: React.Dispatch<React.SetStateAction<string | number | undefined>>
  onSwitchCommentType: (type: string, targetName: string, commentId?: number | string, readOnly?: boolean) => void
  postId: string | number
  onClickUpdateComment: (commentId: number | string, content: string, name: string) => void
}) {
  const [isReplyMode, setIsReplyMode] = useState(false)
  const [swiper, setSwiper] = useState<swit>()
  const [toggle, setToggle] = useState(false)
  const [replyId, setReplyId] = useState<number | string | null>(null)
  const [replyObj, setReplyObj] = useState<CommentListType | null>(null)

  const { deleteComment, deletingComment, deltingSuceess, deletingFailed } = useDeleteComment()

  useEffect(() => {
    if (deletingFailed) {
      requestApi()
    }
  }, [deletingFailed])
  useEffect(() => {
    if (deltingSuceess) {
      requestApi()
    }
  }, [deltingSuceess])

  useEffect(() => {
    if (swiper)
      if (isReplyMode) {
        swiper.slideTo(1, 1000, false) // 슬라이더 초기화
        setToggle(prev => !prev)
      } else {
        swiper.slideTo(0, 1000, false) // 슬라이더 초기화
        setToggle(prev => !prev)
      }
  }, [isReplyMode])

  const onClickReply = (id: number | string, readOnly: boolean, item: CommentListType, targetName: string) => {
    setIsReplyMode(!isReplyMode)
    setReplyId(id)

    onSwitchCommentType('REPLY', targetName, id, readOnly)
    setReplyObj(item)
  }

  useEffect(() => {
    if (replyId && replyObj && result) {
      const newObj = result.find(item => {
        return item.comment.commentId === replyId
      })
      if (newObj) {
        setReplyObj(newObj)
      }
    }
  }, [result])

  return (
    <>
      {/* <LoadingSpinner mt="120px" />
      <button type="button" onClick={switchMode}>
        {isReplyMode ? '댓글로 바꾸기' : '답글로 바꾸기'}
      </button> */}

      <Swiper
        onActiveIndexChange={(swiperCore: SwiperCore) => {
          if (swiperCore.activeIndex === 0) {
            setIsReplyMode(false)
            onSwitchCommentType('COMMENT', 'POST_USER')
            setReplyId(null)
            setReplyObj(null)
          } else {
            setIsReplyMode(true)
          }
        }}
        style={{ height: '355px' }}
        onSwiper={setSwiper}
        allowSlideNext={isReplyMode}
        allowSlidePrev={true}
        className="mySwiper"
        autoHeight={true}
      >
        <SwiperSlide style={{ height: '355px', overflowY: 'scroll' }}>
          <Comment
            result={result}
            deleteComment={deleteComment}
            requestApi={requestApi}
            setArticleId={setArticleId}
            onClickUpdateComment={onClickUpdateComment}
            onClickShowReply={onClickReply}
            postId={postId}
            isLoading={isLoading}
          />
        </SwiperSlide>
        <SwiperSlide style={{ height: '355px', overflowY: 'scroll' }}>
          <Reply
            requestApi={requestApi}
            isLoading={isLoading}
            replyObj={replyObj}
            deleteComment={deleteComment}
            onClickUpdateComment={onClickUpdateComment}
          />
        </SwiperSlide>
      </Swiper>
    </>
  )
}

// 답글
export function Reply({
  replyObj,
  deleteComment,
  onClickUpdateComment,
  requestApi,
  isLoading,
}: {
  requestApi: () => void
  isLoading: boolean
  replyObj: CommentListType | null
  onClickUpdateComment: (commentId: number | string, content: string, name: string) => void
  deleteComment: (commentId: string | number) => void
}) {
  if (isLoading) {
    return <LoadingSpinner />
  }
  let comp = <></>
  if (!replyObj) {
    return <></>
  }
  if (replyObj.children && replyObj.children.length === 0) {
    comp = <></>
  } else if (replyObj.children && replyObj.children.length > 0) {
    comp = (
      <>
        {replyObj.children.map(item => {
          return (
            <React.Fragment key={generateRandomKey()}>
              <ReplyWrap>
                <PostWrap>
                  <ProfileHeader
                    h2ClassNm="comment"
                    subComp={
                      <>
                        <CommentMenuToggle
                          item={item}
                          deleteComment={deleteComment}
                          onClickUpdateComment={onClickUpdateComment}
                        />
                      </>
                    }
                    profileSrc={item.member.thumbnailUrl || sampleProfile}
                    userNm={item.member.nickname}
                    regDttm={formatDttm(item.createdDate)}
                  />
                </PostWrap>
                <PostContentWrap>
                  <CommentContent content={item.content} />
                  <PostReplyDetail
                    afterLikePost={() => {
                      requestApi()
                    }}
                    postComment={{
                      commentsCnt: 0, // 나중에 바꿔야함
                      goodCnt: item.likeCount,
                      postId: item.commentId,
                      userId: item.member.memberId,
                      clicked: item.isLiked,
                    }}
                  />
                </PostContentWrap>
              </ReplyWrap>
            </React.Fragment>
          )
        })}
      </>
    )
  }

  return (
    <>
      <PostWrap>
        <ProfileHeader
          h2ClassNm="comment"
          subComp={
            replyObj.comment.isEditable ? null : (
              <>
                <CommentMenuParentReplyToggle item={replyObj.comment} />
              </>
            )
          }
          profileSrc={replyObj.comment.member.thumbnailUrl || sampleProfile}
          userNm={replyObj.comment.member.nickname}
          regDttm={formatDttm(replyObj.comment.createdDate)}
        />
      </PostWrap>
      <PostContentWrap>
        <CommentContent content={replyObj.comment.content} />
        <PostCommentDetail
          showReply={false}
          onClickShowReply={(commentId, readOnly) => {}}
          afterLikePost={() => {
            requestApi()
          }}
          postComment={{
            commentsCnt: replyObj.children?.length || 0, // 나중에 바꿔야함
            goodCnt: replyObj.comment.likeCount, // 나중에 바꿔야함
            postId: replyObj.comment.commentId,
            userId: replyObj.comment.member.memberId,
            clicked: replyObj.comment.isLiked, // 나중에 바꿔야함
          }}
        />
      </PostContentWrap>
      {comp}
    </>
  )
}

export function Comment({
  postId,
  result,
  onClickShowReply,
  onClickUpdateComment,
  requestApi,
  isLoading,

  setArticleId,
  deleteComment,
}: {
  isLoading: boolean
  result: CommentListType[] | null | undefined
  onClickShowReply: (commentId: number | string, readOnly: boolean, item: CommentListType, targetName: string) => void
  postId: string | number
  requestApi: () => void
  setArticleId: (articleId: string | number) => void
  deleteComment: (commentId: string | number) => void
  onClickUpdateComment: (commentId: number | string, content: string, name: string) => void
}) {
  useEffect(() => {
    setArticleId(postId)
  }, [])

  const { ref, inView } = useInView({
    threshold: 1, // 화면의 50%가 보일 때 감지
  })

  useEffect(() => {
    if (inView) {
      console.log(inView, '무한 스크롤 요청 🎃')
      // changePage()
    }
  }, [inView])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if ((result && result.length === 0) || !result) {
    return (
      <>
        <NoDataContainer2 ac="center" mt="0px" title="아직 댓글이 없어요!" discription="댓글을 남겨보세요" />
      </>
    )
  }
  return (
    <>
      {result?.map(item => {
        return (
          <React.Fragment key={generateRandomKey()}>
            <PostWrap>
              <ProfileHeader
                h2ClassNm="comment"
                subComp={
                  <>
                    <CommentMenuToggle
                      item={item.comment}
                      deleteComment={deleteComment}
                      onClickUpdateComment={onClickUpdateComment}
                    />
                  </>
                }
                profileSrc={item.comment.member.thumbnailUrl || sampleProfile}
                userNm={item.comment.member.nickname}
                regDttm={formatDttm(item.comment.createdDate)}
              />
            </PostWrap>
            <PostContentWrap>
              <CommentContent content={item.comment.content} />
              <PostCommentDetail
                onClickShowReply={(commentId, readOnly) => {
                  onClickShowReply(commentId, readOnly, item, item.comment.member.nickname)
                }}
                afterLikePost={() => {
                  requestApi()
                }}
                postComment={{
                  commentsCnt: item.children?.length || 0, // 나중에 바꿔야함
                  goodCnt: item.comment.likeCount, // 나중에 바꿔야함
                  postId: item.comment.commentId,
                  userId: item.comment.member.memberId,
                  clicked: item.comment.isLiked, // 나중에 바꿔야함
                }}
              />
            </PostContentWrap>
          </React.Fragment>
        )
      })}
    </>
  )
}

export function CommentMenuToggle({
  onClickUpdateComment,
  deleteComment,
  item,
}: {
  item: CommentType

  deleteComment: (commentId: string | number) => void
  onClickUpdateComment: (commentId: number | string, content: string, name: string) => void
}) {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <>
      <MenuToggle
        bc="#D9D9D9"
        mr="0px"
        title="설정"
        options={
          item.isEditable
            ? [
                {
                  optionTitle: '댓글 수정',
                  onClick: () => {
                    onClickUpdateComment(item.commentId, item.content, item.member.nickname)
                  },
                },
                {
                  optionTitle: '댓글 삭제',
                  onClick: () => {
                    deleteComment(item.commentId)
                  },
                },
              ]
            : [
                {
                  optionTitle: '프로필 보기',
                  onClick: () => {
                    navigate(`/profile/${item.member.memberId}`, {
                      state: { backPath: location.pathname },
                    })
                  },
                },
              ]
        }
      />
    </>
  )
}

export function CommentMenuParentReplyToggle({ item }: { item: CommentType }) {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <>
      <MenuToggle
        bc="#D9D9D9"
        mr="0px"
        title="설정"
        options={
          item.isEditable
            ? []
            : [
                {
                  optionTitle: '프로필 보기',
                  onClick: () => {
                    navigate(`/profile/${item.member.memberId}`, {
                      state: { backPath: location.pathname },
                    })
                  },
                },
              ]
        }
      />
    </>
  )
}

const PostWrap = styled.div`
  display: flex;
  padding-top: 14px;
  width: 100%;
`

const ReplyWrap = styled.div`
  margin-left: 58px;
`
const PostContentWrap = styled.div`
  position: relative;
  z-index: 0;
  padding-left: 55.5px;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 14px;
`
