/*
    파일명 : CollectionDetail.tsx
    설명 : 싹싹도감 > 상세
    작성일 : 2024-06-13
*/
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'

import MuiMain from 'src/components/common/mui/MuiMain'
import CollectionDetailMain from 'src/components/specific/collection/detail/CollectionDetailMain'
import { selectCol } from 'src/utils/common/state/state'

export default function CollectionDetail() {
  return (
    <ContainerLayout styleId="div_container_02">
      <MuiMain>
        <CollectionDetailMain />
      </MuiMain>
    </ContainerLayout>
  )
}

export const colAction = async () => {}
