import styled from 'styled-components'

export default function BubbleContainer({
  marginTop = '0',
  children,
}: {
  marginTop?: string
  children: React.ReactNode
}) {
  return <Container margintop={marginTop}>{children}</Container>
}

const Container = styled.div<{ margintop: string }>`
  text-align: center;
  position: relative;
  margin-top: ${({ margintop }) => `${margintop}`};
`
