import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import styles from '../../styles/InputField.module.css'
import styled from 'styled-components'
import { ChangeEvent } from 'react'

export interface InputFieldProps {
  type?: 'text' | 'password'
  id?: string
  placeholder?: string
  registerFn?: React.InputHTMLAttributes<HTMLInputElement>
  readOnly?: boolean
  styleID?: string
  width?: string
  height?: string
  onChange?: (value: string) => void
  onClick?: () => void
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

// 포커싱 ref 타입

export default function InputField({
  type = 'text',
  id,
  placeholder,
  registerFn,
  readOnly,
  styleID,
  width,
  height,
  onClick,
  onKeyUp,
}: InputFieldProps) {
  return (
    <StyledInput
      onClick={() => {
        onClick?.()
      }}
      id={id}
      type={type}
      placeholder={placeholder}
      {...registerFn} // Explicitly pass registerFn instead of spreading
      className={`${divideStyleIDString(styles, styleID)}`}
      readOnly={readOnly}
      aria-label={`inputTEXT_${id}`}
      aria-labelledby={id}
      width={width}
      height={height}
      onKeyUp={event => {
        onKeyUp?.(event)
      }}
    />
  )
}

const StyledInput = styled.input`
  width: ${props => props.width};
  height: ${props => props.height};
  box-sizing: border-box;
`
