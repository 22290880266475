import styled from 'styled-components'

export default function LineOne({ mt = '17px' }: { mt?: string }) {
  return <Line $mt={mt} />
}

const Line = styled.div<{ $mt: string }>`
  width: 100%;
  border: 1px solid #dadada;
  margin-top: ${({ $mt }) => $mt};
  margin-bottom: ${({ $mt }) => $mt};
`
