/*
    파일명 : PlantDetail.tsx
    설명 : 식물 상세 페이지
    작성일 : 2024-06-06
*/

import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MuiMain from 'src/components/common/mui/MuiMain'
import Title from 'src/components/common/title/Title'
import PlantDetailCharacter from 'src/components/specific/plant/detail/PlantDetailCharacter'
import PlantDetailDiary from 'src/components/specific/plant/detail/PlantDetailDiary'
import moment from 'moment'
import CollectionDetailMain from 'src/components/specific/collection/detail/CollectionDetailMain'
import { MenuToggle } from 'src/components/common/title/TitleLight'
import { useNavigate, useParams } from 'react-router-dom'
import useDeletePlant from 'src/components/specific/plant/hooks/getPlantInfoCustomHook'
import LoadingSpinner from 'src/components/common/container/LoadingSpinner'
import PlantDetailBirth from 'src/components/specific/plant/detail/PlantDetailBirth'

moment.locale('ko')

export default function PlantDetail() {
  const { deletePlant, frmtPlant, loadinPlant } = useDeletePlant()
  const navigate = useNavigate()

  return (
    <>
      <ContainerLayout styleId="div_container_06">
        <MuiMain>
          <Title
            backPath="/main"
            styleID="div_container"
            title={frmtPlant ? frmtPlant.plantName : null}
            comp={
              <div style={{ justifySelf: 'end' }}>
                <MenuToggle
                  bc="#1C1C1C"
                  title="설정"
                  options={[
                    {
                      optionTitle: '식물 정보 수정',
                      onClick: () => {
                        navigate('update')
                      },
                    },
                    {
                      optionTitle: '캐릭터 변경',
                      onClick: () => {
                        navigate('update/character')
                      },
                    },
                  ]}
                />
              </div>
            }
          />
          <Content
            pt={1.75}
            pb={2.813}
            component={
              <>
                <>
                  {frmtPlant ? (
                    <PlantDetailCharacter
                      charId={frmtPlant.characterId}
                      plantLocation={frmtPlant.plantLocation}
                      intervalIndays={frmtPlant.wateringIntervalInDays}
                    />
                  ) : (
                    <div style={{ height: '150px' }}>
                      <LoadingSpinner mt="100px" />
                    </div>
                  )}
                </>
              </>
            }
          />
          <MuiMain.MenuTab
            customP={0}
            tabObjArr={[
              {
                queryId: 'diary',
                title: '일지',
                component: <PlantDetailDiary />,
              },
              {
                queryId: 'info',
                title: '식물 정보',
                component: (
                  <>
                    <PlantDetailBirth plantInfo={frmtPlant} />

                    {frmtPlant && (
                      <>
                        <CollectionDetailMain
                          plantTypeId={frmtPlant?.plantTypeId ? frmtPlant?.plantTypeId : frmtPlant.plantType}
                          loadImages={false}
                        />
                      </>
                    )}
                  </>
                ),
              },
            ]}
          />
        </MuiMain>
      </ContainerLayout>
    </>
  )
}
