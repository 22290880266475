import { PostSmrySampleType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import LoadingContainer from '../container/LoadingContainer'
import { displayedAt, formatNumber, getPostSttsImgSize } from 'src/utils/common/scripts/common'
import Sale from '../../../assets/images/post/sttsSale.png'
import { SALE_STTS } from 'src/utils/common/constants/constants'
import { GoodButton, GoodComment, GoodNCommentLayout } from '../button/GoodButton'
import { HashTag } from './HashTagList'
import HashTagListProfile from './HashTagListProfile'
import { useNavigate } from 'react-router-dom'

type MarkedItemInfo = {
  item: PostSmrySampleType | null
  itemType: string // PLAZA, GREEN_MARKET, PROFILE
  showAll?: boolean
}

export default function MarkedItemInfo({ item, itemType, showAll = false }: MarkedItemInfo) {
  const navigate = useNavigate()
  if (item) {
    const imgNm = (item.stts && SALE_STTS[item.stts]?.imgName) || '' // Access object using variable stts
    const sttsNm = (item.stts && SALE_STTS[item.stts]?.name) || ''
    const price = (item.price && formatNumber(item.price)) || 0
    const time = displayedAt(item.regDttm)

    const params = item.id ? `articleId${item.id}` : item.title

    let priceComp = (
      <>
        <p>{price}</p>원
      </>
    )
    if (item.stts && item.stts === 'SOLD') {
      priceComp = (
        <>
          <p style={{ color: '#979797' }}>{price}</p>원
        </>
      )
    } else if (item.stts && item.stts === 'FREE') {
      priceComp = <></>
    }

    let titleComp = <h2>{item.title.length > 12 ? `${item.title.slice(0, 12)}...` : item.title}</h2>

    let commentCom = <></>
    if (showAll) {
      titleComp = <h2>{item.title}</h2>

      commentCom = (
        <>
          <GoodNCommentLayout>
            <GoodButton
              postId={1}
              afterLikePost={() => {}}
              goodCnt={item.goodCnt || 0}
              defaultVal={item.clicked || false}
            />
            <GoodComment ml="12px" onClick={() => {}} commentsCnt={item.commentsCnt || 0} />
          </GoodNCommentLayout>
        </>
      )
    }

    return (
      <Wrap
        onClick={() => {
          if (itemType === 'PROFILE') {
            navigate(`/plaza/common/${params}`, {
              state: {
                direction: 'na',
                // searchParam: item.title,
              },
            })
          }
        }}
      >
        <div>{titleComp}</div>
        <Loc>
          <span>
            {item.loc} . {time}
          </span>
        </Loc>
        <BottomDiv>
          {(itemType === 'GREEN_MARKET' && (
            <Price>
              <PriceInner>
                <img
                  style={item.stts ? getPostSttsImgSize(item.stts) : {}}
                  src={require(`/src/assets/images/post/${imgNm}`)}
                  alt={sttsNm}
                />
                {priceComp}
              </PriceInner>
              {commentCom}
            </Price>
          )) || (
            <>
              <Content>
                <p>
                  {item.content ? (item.content.length > 12 ? `${item.content.slice(0, 12)}...` : item.content) : ''}
                </p>
              </Content>
              {item.hashtagList && item.hashtagList.length > 0 ? (
                <HashTagListProfile hashTagArr={item.hashtagList} />
              ) : (
                <></>
              )}
            </>
          )}
        </BottomDiv>
      </Wrap>
    )
  } else {
    return (
      <Wrap>
        <div>
          <LoadingContainer height="18px" width="60%" />
        </div>
        <BottomDiv>
          <LoadingContainer height="18px" width="56.4%" />
          <LoadingContainer margin="6px 0px 0px 0px" height="18px" width="46.4%" />
        </BottomDiv>
      </Wrap>
    )
  }
}

const Wrap = styled.div`

      width: 100%;
      height: 100%;
      

  // aspect-ratio: 173/90;
  // border: 1px solid #dadada;
      display: flex;
    flex-direction: column;
    justify-content: space-between;
     padding-left: 13px;

  //    @media (max-width: 340px) {
  //   padding-left: 13px;
  // }
}



    h2 {
    /* 극락조 잎 부패 */

    /* Body 01-SB */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }
  
`

const BottomDiv = styled.div`
  height: 43px;
`

const Loc = styled.div`
  /* Caption */
  margin-top: 8px;
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  letter-spacing: -0.02em;

  /* Gray 04 */
  color: #a2a3a7;
`

const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const PriceInner = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 4px;
  }
`

const Content = styled.div`
  height: 100%;
  place-content: end;

  p {
    /* 극락조 잎 부패됐을 때 어떻게 해야 하는지 아시는 분 계시나요.. ㅜㅜ */

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    /* or 153% */
    letter-spacing: -0.02em;

    color: #1c1c1c;
  }
`
