import styled from 'styled-components'

type PlantListLayout = {
  children: React.ReactNode
  height?: string
}

export default function PlantListLayout({ children, height = '448px' }: PlantListLayout) {
  return <PlantContainer height={height}>{children}</PlantContainer>
}

const PlantContainer = styled.div<{ height: string }>`
  width: 100%;

  height: ${({ height }) => `${height}`};

  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  border-collapse: collapse;
  box-sizing: border-box;
`
