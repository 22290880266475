import DotButton from 'src/components/common/button/DotButton'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MuiMain from 'src/components/common/mui/MuiMain'
import Title from 'src/components/common/title/Title'
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp'
import CollectionListMain from 'src/components/specific/collection/list/CollectionListMain'
import { MenuToggle } from 'src/components/common/title/TitleLight'

/*
    파일명 : CollectionList.tsx
    설명 : 싹싹도감 > 내 도감
    작성일 : 2024-06-17
*/
export default function CollectionList() {
  return (
    <ContainerLayout styleId="div_container_05">
      <MuiMain>
        <Title
          backPath="/collection"
          title="나의 도감"
          comp={
            <>
              {/* <div style={{ justifySelf: 'end' }}>
                <MenuToggle bc="#1C1C1C" title="설정" options={[{ optionTitle: '편집', onClick: () => {} }]} />
              </div> */}
            </>
          }
        />

        <Content
          h="500px"
          component={
            <>
              <CollectionListMain />
            </>
          }
        />
      </MuiMain>
    </ContainerLayout>
  )
}
