import { ChatItemType, PostSmrySampleType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import LoadingContainer from '../container/LoadingContainer'
import { displayedAt, formatNumber, getPostSttsImgSize } from 'src/utils/common/scripts/common'
import Sale from '../../../assets/images/post/sttsSale.png'
import { SALE_STTS } from 'src/utils/common/constants/constants'
import { GoodButton, GoodComment, GoodNCommentLayout } from '../button/GoodButton'

type MarkedItemInfo = {
  item: ChatItemType | null
}

export default function ChatItemInfo({ item }: MarkedItemInfo) {
  if (item) {
    const imgNm = (item.stts && SALE_STTS[item.stts]?.imgName) || '' // Access object using variable stts
    const sttsNm = (item.stts && SALE_STTS[item.stts]?.name) || ''
    const price = (item.price && formatNumber(item.price)) || 0

    let priceComp = (
      <>
        <p>{price}</p>원
      </>
    )
    if (item.stts && item.stts === 'SOLD') {
      priceComp = (
        <>
          <p style={{ color: '#979797' }}>{price}</p>원
        </>
      )
    } else if (item.stts && item.stts === 'FREE') {
      priceComp = <></>
    }

    const titleComp = <h2>{item.title.length > 12 ? `${item.title.slice(0, 12)}...` : item.title}</h2>

    return (
      <Wrap>
        <div>{titleComp}</div>
        <Price>
          <PriceInner>
            {item.stts ? (
              <img
                style={item.stts ? getPostSttsImgSize(item.stts) : {}}
                src={require(`/src/assets/images/post/${imgNm}`)}
                alt={sttsNm}
              />
            ) : (
              <></>
            )}

            {priceComp}
          </PriceInner>
        </Price>
        <BottomDiv>
          <Loc>
            <span>{item.loc}</span>
          </Loc>
        </BottomDiv>
      </Wrap>
    )
  } else {
    return (
      <Wrap>
        <div>
          <LoadingContainer height="18px" width="60%" />
        </div>
        <BottomDiv>
          <LoadingContainer height="18px" width="56.4%" />
          <LoadingContainer margin="6px 0px 0px 0px" height="18px" width="46.4%" />
        </BottomDiv>
      </Wrap>
    )
  }
}

const Wrap = styled.div`
  width: 100%;
      height: 100%;
      

 
      display: flex;
    flex-direction: column;
    justify-content: space-between;
     padding-left: 13px;

 
}


  h2 {
    /* 극락조/ 여인초 중형 판매합니다 */

    /* 다육이 팔아요 */


font-family: 'Pretendard-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
/* identical to box height */
letter-spacing: -0.05em;

color: #1C1C1C;




  }
`

const BottomDiv = styled.div`
  height: 43px;
  margin-bottom: 2px;
  align-content: end;

  span {
    /* 용산역 1번 출구 */

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.05em;

    color: #1c1c1c;
  }
`

const Loc = styled.div`
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  letter-spacing: -0.05em;

  color: #979797;
`

const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const PriceInner = styled.div`
  display: flex;
  align-items: center;
  p {
    /* 5,000원 */

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #1c1c1c;
  }
`
