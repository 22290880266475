import styled from 'styled-components'

type DrawerType = {
  onClickBackDrop?: () => void
}

export default function BackDrop({ onClickBackDrop }: DrawerType) {
  return (
    <>
      <BackDropComp
        onClick={() => {
          onClickBackDrop?.()
        }}
      />
    </>
  )
}

const BackDropComp = styled.div`
  width: 100%;
  height: 100%;
  // position: absolute;
  top: 0px;
  z-index: 0;

  background: rgba(0, 0, 0, 0.5);
`
