/*
  작성자 : 김효은
  작성일 : 2024-05-09
  설명 : 인풋 . 버튼이 한 줄로 표현 될 때 사용하는 컴포넌트
*/
import InputWithBtnLayout from 'src/components/common/layout/InputWithBtnLayout'

import FormMain from '../../FormMain'

export interface InputFieldProps {
  inputComp: JSX.Element | JSX.Element[]
  iconComp?: JSX.Element | JSX.Element[] | null
  styleId?: string | undefined
  width?: string
  height?: string
}

export default function InputFull({
  inputComp,
  iconComp = null,
  width = '',
  height = '',
  styleId = 'div_with_timer wdt_full mb_10',
}: InputFieldProps) {
  return (
    <>
      <FormMain.InputLayout height={height} width={width} iconComp={iconComp} styleId={styleId}>
        {inputComp}
      </FormMain.InputLayout>
    </>
  )
}
