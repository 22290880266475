/*
    파일명 : PlazaList.tsx
    설명 : 식물 광장 > 메인 > 광장 게시글 목록
    작성일 : 2024-06-12
    @param 
        
*/
import DotButton from 'src/components/common/button/DotButton'
import sampleProfile from '../../../../assets/images/post/sampleProfile.png'
import ProfileHeader from 'src/components/common/content/ProfileHeader'
import { CONTENT_PADDING, PLANT_PLAZA_POST_LIST_SAMPLE } from 'src/utils/common/constants/constants'
import { PlazaPostSampleType, PostType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import PostContent from 'src/components/common/content/PostContent'
import PostComment from 'src/components/common/content/PostComment'
import DrawerLayout from 'src/components/common/layout/DrawerLayout'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import PlazaCommentForm from './PlazaCommentForm'
import { MenuToggle } from 'src/components/common/title/TitleLight'
import FixedButtonLayout from 'src/components/common/layout/FixedButtonLayout'
import FormMain from 'src/components/common/form/FormMain'
import BackDropDrawer from 'src/components/common/container/BackDropDrawer'
import { Box, CircularProgress, Container } from '@mui/material'
import { useGetPlazaMainPosts, useGetPlazaPost, useReportPost } from '../hooks/PlazaCreateCustomHook'
import { useInView } from 'react-intersection-observer'
import NoDataContainer from 'src/components/common/container/NoDataContainer'
import { formatDttm, generateRandomKey } from 'src/utils/common/scripts/common'
import LineOne from 'src/components/common/line/LineOne'
import EndLine from 'src/components/common/line/EndLie'
import { useNaverLogin } from 'src/utils/query/login/useNaverLogin'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingSpinner from 'src/components/common/container/LoadingSpinner'
import { toast } from 'react-toastify'
import NoDataContainer2 from 'src/components/common/container/NoDataContainer2'

type PlazaPostType = {
  post: PostType
  onClick: () => void
  onClickUpdate: (updatePost: PostType) => void
  afterLikePost: () => void
  onClickItem: (item: string) => void
}

export default function PlazaList({
  onClickUpdate,
  type,
  searchParam,
  isUpdated,
  setInitStts,
  onClickItem,
}: {
  onClickItem: (item: string) => void
  isUpdated: boolean
  type: string
  searchParam: string
  setInitStts: () => void
  onClickUpdate: (updatePost: PostType) => void
}) {
  const { register, getValues, setValue, watch, handleSubmit } = useForm()
  const [drawerOpen, setDrawerOpen] = useState<boolean | undefined>(undefined)
  const [inputOpen, setInputOpen] = useState(false)
  const {
    pendingDetail,
    setInit,
    setPage,
    list,
    isPending,
    setList,
    setPostType,
    changePage,
    setKeyword,
    isLoading,
    requestApi,
  } = useGetPlazaMainPosts()
  const { detailItem, pendingItem, loadingItem, initItem, setArticleId, detailId } = useGetPlazaPost()
  const [selected, setSelected] = useState<{ id: number; nickname: string } | undefined>(undefined)

  const containerEl = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (isUpdated) {
      requestApi()
    }
  }, [isUpdated])

  useEffect(() => {
    if (type) {
      setList(undefined)
      setPage(0)
      setPostType(type)
    }
  }, [type])

  useEffect(() => {
    if (drawerOpen === false) {
      setArticleId(selected?.id)
      setSelected(undefined)
    }
  }, [drawerOpen])

  useEffect(() => {
    setList(undefined)
    setPage(0)
    if (searchParam) {
      setKeyword(searchParam)
    } else {
      setKeyword('')
    }
  }, [searchParam])

  // TODO:추후 리팩토링
  useEffect(() => {
    if (detailItem && list) {
      const newList = list.map(item => {
        if (item.articleId === detailItem.articleId) {
          return {
            ...item,
            commentCount: detailItem.commentCount,
          }
        } else {
          return item
        }
      })
      setList(newList)
    }
    setArticleId(undefined)
  }, [detailItem])

  if (list === undefined || (list === undefined && isPending) || (list && list.length === 0 && isPending)) {
    return <PendingPosts />
  }

  if (list === null || (list && list.length === 0)) {
    return (
      <>
        <NoContentWrap>
          <div ref={containerEl}>
            <PullToRefresh isPending={isPending} requestRefetch={setInit} el={containerEl} />
          </div>
          <div style={{ height: '200px' }}>
            <NoDataContainer2 ac="center" mt="50px" title="게시글이 없어요" discription="게시글을 작성해보세요" />
          </div>
        </NoContentWrap>
      </>
    )
  }

  return (
    <>
      <Box sx={{ p: CONTENT_PADDING, pt: '157px', pb: 0 }}>
        <Wrap>
          <div ref={containerEl}>
            <PullToRefresh isPending={isPending} requestRefetch={setInit} el={containerEl} />
          </div>
          {list &&
            list.map((item: PostType) => {
              return (
                <PlazaPost
                  onClickItem={onClickItem}
                  afterLikePost={() => {
                    requestApi()
                  }}
                  onClickUpdate={onClickUpdate}
                  onClick={() => {
                    setSelected({
                      id: item.articleId,
                      nickname: item.member.nickname,
                    })
                    setDrawerOpen(true)
                  }}
                  key={item.articleId}
                  post={item}
                />
              )
            })}
          {isLoading && <PendingPosts />}

          <EndLine onShowEndPage={changePage} />
        </Wrap>
      </Box>

      <BackDropDrawer
        onClickBackDrop={() => {
          setDrawerOpen(false)
        }}
        b={-38}
        height={500}
        title="댓글"
        backdropActive={true}
        drawerOpen={!drawerOpen ? false : true}
      >
        <Box
          sx={{
            height: '100vh',
            overflow: 'auto',
          }}
        >
          {selected && (
            <PlazaCommentForm
              onClick={() => {
                setInputOpen(true)
              }}
              nickName={selected.nickname}
              postId={selected.id}
            />
          )}
        </Box>
      </BackDropDrawer>
    </>
  )
}

export function PendingPosts() {
  return (
    <>
      <Box sx={{ p: CONTENT_PADDING, pt: '0px', pb: 0 }}>
        <Wrap>
          {Array.from({ length: 5 }).map((_, index) => (
            <React.Fragment key={generateRandomKey()}>
              <PostWrap>
                <ProfileHeader
                  h2ClassNm="plaza"
                  subComp={<></>}
                  profileSrc={undefined}
                  userNm={undefined}
                  regDttm={undefined}
                />
              </PostWrap>
              <PostContentWrap>
                <PostContent onClickItem={() => {}} post={undefined} />
                <PostComment post={undefined} afterLikePost={() => {}} onClick={() => {}} postComment={undefined} />
              </PostContentWrap>
            </React.Fragment>
          ))}
        </Wrap>
      </Box>
    </>
  )
}

const NoContentWrap = styled.div`
  margin-top: 158px;
`
const FixedLayout = styled.div`
  position: fixed;
  bottom: 90px;
  width: 100%;
  height: 100%;
  max-width: 390px;
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  z-index: 10;
  pointer-events: none;
`

export function PlazaPost({ post, onClick, onClickUpdate, afterLikePost, onClickItem }: PlazaPostType) {
  const { isPending, createForm, isSuccess } = useReportPost()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <>
      <PostWrap>
        <ProfileHeader
          h2ClassNm="plaza"
          subComp={
            <>
              <MenuToggle
                bc="#D9D9D9"
                mr="0px"
                title="설정"
                options={
                  post.isEditable
                    ? post.type.includes('DIARY')
                      ? []
                      : [
                          {
                            optionTitle: '게시글 수정',
                            onClick: () => {
                              onClickUpdate(post)
                            },
                          },
                        ]
                    : [
                        {
                          optionTitle: '프로필 보기',
                          onClick: () => {
                            navigate(`/profile/${post.member.memberId}`, {
                              state: { backPath: location.pathname },
                            })
                          },
                        },
                        {
                          optionTitle: '게시글 신고',
                          onClick: () => {
                            createForm(post.articleId, '게시글 신고')
                          },
                        },
                      ]
                }
              />
            </>
          }
          profileSrc={post.member.thumbnailUrl || sampleProfile}
          userNm={post.member.nickname}
          regDttm={formatDttm(post.createdAt)}
        />
      </PostWrap>
      <PostContentWrap>
        <PostContent onClickItem={onClickItem} post={post} />
        <PostComment
          afterLikePost={afterLikePost}
          onClick={onClick}
          post={post}
          postComment={{
            commentsCnt: post.commentCount,
            goodCnt: post.likeCount,
            postId: post.articleId,
            userId: post.member.memberId,
            clicked: post.isLiked,
          }}
        />
      </PostContentWrap>
    </>
  )
}

const PostWrap = styled.div`
  display: flex;
  padding-top: 14px;
  width: 100%;
`
const PostContentWrap = styled.div`
  position: relative;
  z-index: 0;
  padding-left: 55.5px;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 14px;
`
const Wrap = styled.div``

interface PullToRefreshProps {
  el: MutableRefObject<HTMLElement | null>
  requestRefetch: () => void
  isPending: boolean
}

function PullToRefresh({ el, requestRefetch, isPending }: PullToRefreshProps) {
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const [startY, setStartY] = useState<number>(0)
  const [transform, setTransform] = useState<string>('translate(0,0)')

  useEffect(() => {
    const handleTouchStart = (event: TouchEvent) => {
      if (event.touches.length > 0) {
        setStartY(event.touches[0].clientY)
      }
    }

    const handleTouchMove = (event: TouchEvent) => {
      if (el.current) {
        const moveY = event.touches[0].clientY
        const pullDistance = moveY - startY

        if (pullDistance > 0) {
          event.preventDefault()
          const isAtTop = window.scrollY === 0 || document.documentElement.scrollTop === 0
          if (isAtTop && pullDistance > 10) {
            setTransform('translate(0, 40px)')
            setRefreshing(true)
          }
        }
      }
    }

    const handleTouchEnd = () => {
      if (refreshing) {
        requestRefetch()

        setTimeout(() => {
          setRefreshing(false)
          setTransform('translate(0,0)')
        }, 1000)
      }
    }

    document.addEventListener('touchstart', handleTouchStart)
    document.addEventListener('touchmove', handleTouchMove)
    document.addEventListener('touchend', handleTouchEnd)

    return () => {
      document.removeEventListener('touchstart', handleTouchStart)
      document.removeEventListener('touchmove', handleTouchMove)
      document.removeEventListener('touchend', handleTouchEnd)
    }
  }, [refreshing, startY, el])

  const containerStyle = {
    transform,
    transition: refreshing ? '0.3s' : 'none',
  }

  return (
    <Container style={containerStyle}>
      <>{refreshing ? <LoadingSpinner mt="0px" mb="30px" /> : null}</>
    </Container>
  )
}
