/*
    파일명 : SelectBoxLayout.tsx
    설명 : 셀렉트박스 레이아웃
    작성일 : 2024-06-10 
        
*/
import DropDownIcon from '../../../assets/images/icon/DropDownIcon.png'
import { useEffect, useState } from 'react'
import styles from './styles/SelectBoxLayout.module.css'
import styled from 'styled-components'
import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import { useForm } from 'react-hook-form'
import { useRecoilState } from 'recoil'
import { selectBoxContentState } from 'src/utils/common/state/state'

type SelectBoxLayoutType = {
  isValid?: boolean | undefined

  label: string
  children?: React.ReactNode

  getValues: ReturnType<typeof useForm>['getValues']

  watch: ReturnType<typeof useForm>['watch']
  name: string
  divContentWrapStyleId?: string
}

export default function SelectBoxLayout({
  divContentWrapStyleId = 'calendar_wrap',
  isValid = undefined,
  getValues,

  watch,
  name,

  label,
  children,
}: SelectBoxLayoutType) {
  const [selectBox, setSelectBox] = useRecoilState(selectBoxContentState)
  const [textColor, setTextColor] = useState('unopen')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const subscirbe = watch((data, { name: changedNm }) => {
      if (changedNm === name) {
        setTextColor('active')
        setIsOpen(false)
      }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])

  const styleId = isOpen === true ? 'div_container active' : 'div_container'

  const handleToggleCalendar = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={`${divideStyleIDString(styles, isValid === false ? `${styleId} border_red` : styleId)}`}>
        <div className={styles.toggle_wrap}>
          <DropdownButton className={getValues(name) ? 'active' : textColor} onClick={handleToggleCalendar}>
            {getValues(name) ? getValues(name) : label}
            <div>
              <img src={DropDownIcon} alt="토글 " />
            </div>
          </DropdownButton>
        </div>
        {isOpen === true && (
          <>
            <div className={styles.div_line} />
            <div className={`${divideStyleIDString(styles, divContentWrapStyleId)}`}>{children}</div>
          </>
        )}
      </div>
    </>
  )
}

const DropdownButton = styled.div`
  height: 49px;
  border: none;
  width: 100%;

  display: grid;

  color: var(--festie-gray-800, #3a3a3a);

  appearance: none;
  background-color: unset;

  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px;

  font-family: Pretendard-Regular;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;

  grid-template-columns: 1fr auto;
  padding-top: 17px;
  padding-left: 0px;
  padding-right: 0px;
  .transform {
    transform: rotate(90deg);
  }
  &.unopen {
    color: #979797;
  }
  &.active {
    color: #1c1c1c;
  }

  div {
    justify-content: end;
  }
  div:hover {
    cursor: pointer;
  }
`
