import styled from 'styled-components'
import PlantItemList from '../sub/PlantItemList'
import NoItemList from '../sub/NoItemList'
import SearchField from '../sub/SearchField'
import { plantTypeType } from 'src/utils/common/type/type'
import { useState } from 'react'
import LoadingSpinner from 'src/components/common/container/LoadingSpinner'

export default function SelectPlant() {
  const [plantList, setPlantLists] = useState<plantTypeType[] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<undefined | boolean>(undefined)
  const onReceiveDataHandler = (lists: plantTypeType[] | undefined) => {
    setPlantLists(lists)
  }
  const onChangeLoadingState = (state: boolean | undefined) => {
    setIsLoading(state)
  }

  return (
    <>
      <Wrap>
        <SearchField onChangeLoadingState={onChangeLoadingState} onReceiveData={onReceiveDataHandler} />

        {isLoading ? (
          <LoadingSpinner />
        ) : plantList && plantList.length > 0 ? (
          <PlantItemList height={plantList.length > 5 ? '388px' : '235px'} itemArr={plantList} />
        ) : (
          <NoItemList showBtn={true} />
        )}
      </Wrap>
    </>
  )
}

const Wrap = styled.div`
  height: 500px;
`

const formMainStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}
