import Content from 'src/components/common/layout/Content'
import { PlantDetailInfo } from 'src/utils/common/type/type'
import CollectionDetailImgList from './CollectionDetailImgList'
import { castPlantDrfullType, castType, isObject, reFormatArrayOfStrings } from 'src/utils/common/scripts/checkType'
import CollectionDetailTitle from './CollectionDetailTitle'
import CollectionDetailPestList from './CollectionDetailPestList'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getDataAdminTanstack } from 'src/utils/common/api/http'
import DiaryContent, { PlantContent } from '../../plant/sub/DiaryContent'
import styled from 'styled-components'
import CollectionDetailRelatedPosts from './CollectionDetailRelatedPosts'
import { toast } from 'react-toastify'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import NoData from 'src/components/common/content/Nodata'

export default function CollectionDetailMain({
  plantTypeId,
  loadImages = true,
  onClickBackBtn,
  showBtn = true,
}: {
  plantTypeId?: string | number
  loadImages?: boolean
  onClickBackBtn?: () => void
  showBtn?: boolean
}) {
  const [responseData, setResponseData] = useState<PlantDetailInfo | undefined | null>(null)

  const navigate = useNavigate()
  const { itemId, plantId } = useParams()
  const location = useLocation()

  useEffect(() => {
    // if (itemId || plantId) {
    //   let isNumber
    //   if (itemId) {
    //     isNumber = /^\d+$/.test(itemId)
    //   } else if (plantId) {
    //     isNumber = /^\d+$/.test(plantId)
    //   }

    if (plantTypeId || itemId) {
      console.log('refetch실행')
      refetch()
    }

    // else {
    //   refetchDrf()
    // }
    // }
  }, [itemId])

  const { data, isSuccess, refetch } = useQuery({
    queryKey: [
      'plant-info',
      {
        searchParam: itemId || plantTypeId,
        url: `${process.env.REACT_APP_API_GET_PLANT_INFO}/`,
      },
    ],
    queryFn: getDataAdminTanstack,
    refetchInterval: 300000,
    enabled: false,
  })

  const {
    data: dataDrf,
    isSuccess: dataScsDrf,
    refetch: refetchDrf,
  } = useQuery({
    queryKey: [
      'plant-info-drf',
      {
        searchParam: itemId,
        url: `${process.env.REACT_APP_API_GET_PLANT_DETAIL}?plantName=`,
      },
    ],
    queryFn: getDataAdminTanstack,
    refetchInterval: 300000,
    enabled: false,
  })

  useEffect(() => {
    if (isSuccess) {
      if (isObject(data) && isObject(data?.data)) {
        setResponseData(castType(data))
      }
    }

    if (data && data.message === '데이터가 없습니다.') {
      if (itemId) {
        toast.error(data.message)
        navigate('../', { state: { direction: 'back' } })
      }
    }
  }, [data, isSuccess])

  useEffect(() => {
    if (dataScsDrf) {
      console.log(dataDrf)
      if (isObject(dataDrf) && isObject(dataDrf?.data)) {
        const drf = castPlantDrfullType(dataDrf)

        const format: PlantDetailInfo = {
          message: '',
          data: {
            id: 9999,
            plantTaxonomy: {
              species: drf?.data.scientific_name || '',
              genus: null,
              family: null,
            },
            commonName: drf?.data.name || '',
            description: drf?.data.description,
            wateringInfo: {
              summary: drf?.data.planting_infos[0].info1,
              description: drf?.data.planting_infos[0].info2,
            },
            environmentInfo: {
              summary: drf?.data.planting_infos[1].info1,
              description: drf?.data.planting_infos[1].info2,
            },
            humidityInfo: {
              summary: `${drf?.data.planting_conditions[0].min}% ~ ${drf?.data.planting_conditions[0].max}%`,
              description: `${drf?.data.planting_conditions[0].min}% ~ ${drf?.data.planting_conditions[0].max}%`,
            },
            temperatureCondition: {
              min: drf?.data.planting_conditions[1].min || 0,
              max: drf?.data.planting_conditions[1].max || 0,
            },
            humidCondition: {
              min: drf?.data.planting_conditions[0].min || 0,
              max: drf?.data.planting_conditions[0].max || 0,
            },
            imageUrls: drf?.data.image_urls || null,
          },
        }

        setResponseData(castType(format))
      }
    }

    if (dataDrf && dataDrf.message === '데이터가 없습니다.') {
      toast.error(dataDrf.message)
      navigate('../', { state: { direction: 'back' } })
    }
  }, [dataDrf, dataScsDrf])

  if (data && data.message === '데이터가 없습니다.') {
    return (
      <>
        <NoData discription="관련 정보가 없어요!" />
      </>
    )
  }

  return (
    <>
      {loadImages && (
        <Content
          pb={0}
          pt={0}
          p={0}
          component={
            <Wrap>
              <CollectionDetailImgList
                onClickBackBtn={onClickBackBtn}
                backUrl={location.state?.backpath || '/collection'}
                srcArr={responseData ? reFormatArrayOfStrings(responseData.data.imageUrls) : null}
              />
            </Wrap>
          }
        />
      )}
      <Content pb={0} pt={0} component={<CollectionDetailTitle item={responseData ? responseData : null} />} />
      {/* <Content
        pb={0}
        pt={0}
        p={0}
        component={
          <>
            <CollectionDetailPestList isFetched={responseData ? true : false} />
          </>
        }
      /> */}
      <Content
        pb={0}
        pt={0}
        component={
          <>
            <PlantContent
              marginTop="23px"
              width="100%"
              title={responseData ? '식물 설명' : null}
              content={responseData ? responseData.data.description : null}
            />
          </>
        }
      />
      {loadImages && (
        <Content
          pb={0}
          pt={0}
          p={0}
          component={
            <>
              <>
                <CollectionDetailRelatedPosts
                  plantNm={responseData ? responseData.data.commonName : null}
                  showBtn={showBtn}
                  isFetched={responseData ? true : false}
                />
              </>
            </>
          }
        />
      )}
    </>
  )
}

const Wrap = styled.div`
  margin-bottom: 34px;
`
