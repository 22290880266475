import { useEffect, useState } from 'react'
import styles from '../../styles/Agree.module.css'
import agreeChk from '../../../../../../assets/images/icon/agreeChk.png'
import activeChk from '../../../../../../assets/images/icon/activeAgreeChk.png'
import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'

type AgreeProps = {
  id: string
  title: string
  link: string
  description: string
  length: number
  index: number | string
  onRequestAgreePage: (title: string, description: string) => void
  onClickAgree: (id: string, chk: boolean) => void
  isChecked: boolean
}

export default function Agree({
  description,
  onRequestAgreePage,
  length,
  id,
  index,
  title,
  link,
  onClickAgree,
  isChecked,
}: AgreeProps) {
  const [isCheckedState, setIsCheckedState] = useState(isChecked)
  let style = 'div_agree_wrap'
  if (index === 0) {
    style += ' mt_17px'
  } else if (index === length - 1) {
    style += ' mt_17px mb_23px'
  } else {
    style += ' mt_21px'
  }

  useEffect(() => {
    setIsCheckedState(isChecked)
  }, [isChecked])

  const handleImageClick = () => {
    const newValue = !isCheckedState
    setIsCheckedState(newValue)
    onClickAgree(id, newValue)
  }

  return (
    <div className={`${divideStyleIDString(styles, style)}`}>
      <div className={styles.div_agree}>
        {link.length > 0 ? (
          <span
            onClick={() => {
              onRequestAgreePage(title, description)
            }}
          >
            {title}
          </span>
        ) : (
          <p>{title}</p>
        )}
      </div>
      <div className={styles.div_chkbox}>
        <input type="checkbox" hidden checked={isCheckedState} readOnly />
        <img
          src={isCheckedState ? activeChk : agreeChk}
          alt="동의"
          onClick={handleImageClick}
          style={{ cursor: 'pointer' }}
        />
      </div>
    </div>
  )
}
