import { configureStore } from '@reduxjs/toolkit'

import plantRecommendedSliceReducer from './plant/plantRecommended'

const store = configureStore({
  reducer: {
    plantRecommended: plantRecommendedSliceReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export default store
