import { useState } from 'react'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MotionFadeOutLayout from 'src/components/common/layout/MotionFadeOutLayout'
import MuiMain from 'src/components/common/mui/MuiMain'
import SearchPlant from 'src/components/specific/collection/search/SearchPlant'

/*
    파일명 : CollectionsearchPlant.tsx
    설명 : 싹싹도감 > 검색 화면 > 식물 검색
    작성일 : 2024-06-16
*/
export default function CollectionsearchPlant() {
  const [isVisible, setIsVisible] = useState(true)
  return (
    <ContainerLayout styleId="div_container_05">
      <MuiMain>
        <Content
          h="90vh"
          pb={0}
          component={
            <>
              <SearchPlant />
            </>
          }
        />
      </MuiMain>
    </ContainerLayout>
  )
}
