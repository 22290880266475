import { useEffect, useState } from 'react'
import Agree from './Agree'
import AllAgree from './AllAgree'
import AgreeModal from 'src/components/common/modal/AgreeModal'

type AgreeListType = {
  list: AgreeItem[]

  validCheck: (requiedAgreeUnChecked: boolean) => void
}

type AgreeItem = {
  id: string
  title: string
  link: string
  required: boolean
  description: string
}

let firstRander = true
export default function AgreeList({ list, validCheck }: AgreeListType) {
  const initialIsCheckedMap: { [key: string]: boolean } = {}
  list.forEach(item => {
    initialIsCheckedMap[item.id] = false
  })
  const [allAgree, setAllAgree] = useState(false)
  const [isCheckedMap, setIsCheckedMap] = useState<{ [key: string]: boolean }>(initialIsCheckedMap)
  const [showModal, setShowModal] = useState(false)
  const [modalContents, setModalContents] = useState({
    title: '',
    description: '',
  })

  const AgreeValidChk = () => {
    let isValid = true
    for (let i = 0; i < list.length; i++) {
      const obj = list[i]
      if (obj.required === true) {
        if (isCheckedMap[obj.id] === false) {
          isValid = false
          break
        }
      }
    }

    validCheck(isValid)
  }

  useEffect(() => {
    if (!firstRander) {
      const isAllChecked = Object.values(isCheckedMap).every(isChecked => isChecked)
      setAllAgree(isAllChecked)
      AgreeValidChk()
    } else {
      firstRander = false
    }
  }, [isCheckedMap])

  const onClickAgree = (id: string, chk: boolean) => {
    setIsCheckedMap(prev => ({
      ...prev,
      [id]: chk,
    }))
  }

  const onClickAllAgreeBtn = () => {
    setAllAgree(prev => !prev)
    const newIsCheckedMap = { ...isCheckedMap }
    Object.keys(newIsCheckedMap).forEach(key => {
      newIsCheckedMap[key] = !allAgree
    })
    setIsCheckedMap(newIsCheckedMap)
  }
  const onRequestAgreePage = (title: string, description: string) => {
    setShowModal(true)
    setModalContents({
      title,
      description,
    })
  }
  return (
    <>
      <AllAgree title="모든 약관에 동의합니다." checked={allAgree} onChange={onClickAllAgreeBtn} />
      {list.map((item, index) => (
        <Agree
          index={index}
          length={list.length}
          key={item.id}
          id={item.id}
          description={item.description}
          title={item.title}
          link={item.link}
          onRequestAgreePage={onRequestAgreePage}
          onClickAgree={onClickAgree}
          isChecked={isCheckedMap[item.id]}
        />
      ))}
      <AgreeModal
        title={modalContents.title}
        description={modalContents.description}
        open={showModal}
        handleClose={() => {
          setShowModal(false)
        }}
      />
    </>
  )
}
