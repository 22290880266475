import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { usePlantPageStatus } from 'src/utils/store/plant/usePlantPageStatus'
import PlantProgress from 'src/components/specific/plant/sub/PlantProgress'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import MuiMain from 'src/components/common/mui/MuiMain'
import Content from 'src/components/common/layout/Content'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export default function PlantCreate() {
  const { setPageStatus, pageStatus, pageValue, setPageValue } = usePlantPageStatus()
  const location = useLocation()
  const { plantId, charId } = useParams()
  const navigate = useNavigate()
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    setPageValue()
  }, [pageStatus])

  useEffect(() => {
    if (!plantId && !charId) {
      if (pageStatus !== 'selectPlant') {
        setPageStatus('selectPlant')
      }
    } else if (plantId && !charId) {
      if (pageStatus !== 'selectChar') {
        setPageStatus('selectChar')
      }
    } else if (plantId && charId) {
      if (pageStatus !== 'plantInfo') {
        setPageStatus('plantInfo')
      }
    }
  }, [])

  const onClickBackBtn = () => {
    const path = location.pathname

    const pathArr = path.split('/')
    console.log(pathArr.toString())
    const pathCheckArr = pathArr.filter(item => item.length > 0)

    console.log(pathCheckArr.toString())

    if (!plantId && !charId) {
      setPageStatus('selectPlant')

      navigate('/main', { state: { direction: 'back' } })
    } else if (plantId && !charId) {
      setPageStatus('selectPlant')
      navigate('/plant/create', { state: { direction: 'na' } })
    } else if (plantId && charId) {
      setPageStatus('selectChar')
      navigate(`/plant/create/${plantId}`, { state: { direction: 'na' } })
    }
  }

  return (
    <>
      <ContainerLayout>
        <MuiMain>
          <Content
            component={
              <>
                <PlantProgress onClick={onClickBackBtn} level={pageValue.progressValue} />
                <HeaderText>{pageValue.headerText}</HeaderText>
                {pageValue.content}
              </>
            }
          />
        </MuiMain>
      </ContainerLayout>

      {/* 
        
      <ContentContainer>{pageValue.content}</ContentContainer> */}
    </>
  )
}

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;

  margin-top: 38px;
  margin-bottom: 36px;

  /* Title 02 */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */
  letter-spacing: -0.02em;

  color: #1c1c1c;
`

const ContentContainer = styled.section`
  width: 100%;
  padding: 0px 7.1%;
  box-sizing: border-box;
  overflow: hidden;
`
