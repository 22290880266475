import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MuiMain from 'src/components/common/mui/MuiMain'
import Title from 'src/components/common/title/Title'
import DiaryDetailView from 'src/components/specific/diary/sub/DiaryDetailView'

export default function Plan() {
  return (
    <>
      <ContainerLayout styleId="div_container_06">
        <Title title="일정 조회" backPath="/main" />
        <MuiMain>
          <Content pt={0} component={<DiaryDetailView />} />
        </MuiMain>
      </ContainerLayout>
    </>
  )
}
