import styled from 'styled-components'
import search from '../../../assets/images/search/search.png'
import collectionSearch from '../../../assets/images/search/collectionSearch.png'

export default function SearchIconGray() {
  return (
    <>
      <Img src={search} alt="검색" />
    </>
  )
}

export function SearchIconCollection() {
  return (
    <>
      <CollectionImg src={collectionSearch} alt="검색" />
    </>
  )
}

const CollectionImg = styled.img`
  width: 24px;
  height: 24px;
`

const Img = styled.img`
  width: 20px;
  height: 19px;
`
