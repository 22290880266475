import { Skeleton } from '@mui/material'
import { getDdate } from 'src/utils/common/scripts/common'
import { plantDetailInfoType } from 'src/utils/common/type/type'
import styled from 'styled-components'

export default function PlantDetailBirth({ plantInfo }: { plantInfo: null | plantDetailInfoType }) {
  if (!plantInfo) {
    return (
      <>
        <Wrap>
          <Title>
            <Skeleton variant="text" width={50} />
          </Title>
          <InfoDiv>
            <InfoRDiv>
              <Count>
                <Skeleton variant="text" width={80} />
              </Count>
              <Birth>
                <Skeleton variant="text" width={50} />
              </Birth>
            </InfoRDiv>

            <Skeleton variant="rounded" width={121} height={43} sx={{ borderRadius: '13px' }} />
          </InfoDiv>
        </Wrap>
      </>
    )
  } else {
    return (
      <Wrap>
        <Title>{plantInfo.plantName}</Title>
        <InfoDiv>
          <InfoRDiv>
            <Count>함께한지 {getDdate(plantInfo.plantBirthday)}일째</Count>
            <Birth>{plantInfo.plantBirthday}</Birth>
          </InfoRDiv>
          <InfoLDiv>
            <p>물 준 날</p> <span>{getDdate(plantInfo.plantBirthday)}일전</span>
          </InfoLDiv>
        </InfoDiv>
      </Wrap>
    )
  }
}

const Count = styled.div`
  /* Body 01-R */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: -0.02em;

  /* Black */
  color: #242424;
`

const Birth = styled.div`
  /* 2024. 04. 24 */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  letter-spacing: -0.02em;

  /* Gray 04 */
  color: #a2a3a7;
`

const InfoDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

const InfoRDiv = styled.div``

const InfoLDiv = styled.div`
  /* 식물등록 */
  justify-content: center;
  align-items: center;
  /* Auto layout */
  display: flex;

  width: 121px;
  height: 43px;

  /* #D9D9D9 */
  background: #c9eeff;
  border-radius: 13px;

  p {
    /* 물 준 날 */

    /* Caption */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }
  span {
    margin-left: 12px;
    font-family: 'Pretendard-Regular';

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }
`

const Title = styled.div`
  /* 꼼삐 */

  /* Title 02 */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  height: 34px;
  letter-spacing: -0.02em;

  /* Black */
  color: #242424;
`

const Wrap = styled.div`
  /* Frame 496 */
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
  height: 102px;

  border-bottom: 2px solid #f9f9f9;
  margin-bottom: 20px;
`
