import React, { useEffect, useState } from 'react'
import styles from '../../styles/Calendar.module.css'
import Calendar from 'react-calendar'
import styled from 'styled-components'
import moment from 'moment'
import DropDownIcon from '../../../../../assets/images/icon/DropDownIcon.png'
import CalendarPrevBtn from '../../../../../assets/images/icon/CalendarPrevBtn.png'
import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import CalendarNextBtn from '../../../../../assets/images/icon/CalendarNextBtn.png'
import CalendarNextBtn2 from '../../../../../assets/images/icon/CalendarNextBtn2.png'
import 'moment/locale/ko' // 한국어 로케일을 로드
import { useForm } from 'react-hook-form'
import DualCalendarContent from './DualCalendarContent'
import MonthlyCalendarMain from 'src/components/common/calendar/MonthlyCalendarMain'
import { InfoComp, LabelDiv, LabelTitle } from '../label/LabelLayout'
import { ResponsiveTimePickers } from '../time/TimeInfo'

type CustomCalendarType = {
  registerFn?: React.InputHTMLAttributes<HTMLInputElement>
  setValue: ReturnType<typeof useForm>['setValue']
  name: string
  isValid?: boolean
  disabled?: boolean
  selected?: Date
  selectNext: boolean
  valType?: string
  onChange?: () => void

  label?: string

  isFirst?: boolean // 첫번째에 위치하는지
  infoText?: string
  infoStyleId?: string
  timeComp?: React.ReactNode
}

moment.locale('ko')
function CustomCalendar({
  valType = 'string',
  timeComp,
  selected = new Date(),
  disabled = false,
  isValid = true,
  registerFn,
  setValue,
  selectNext,
  onChange,
  name,
  label,
  isFirst = false,
  infoStyleId = '',
  infoText = '',
}: CustomCalendarType) {
  const today = new Date()
  const [nowDate, setNowDate] = useState(selected)
  const [textColor, setTextColor] = useState('active')
  const [isOpen, setIsOpen] = useState(false)
  const [monthUserSeeing, setMonthUserSeeing] = useState(moment(selected).month())

  useEffect(() => {
    if (valType === 'string') {
      setValue(name, moment(selected).format('YYYY-MM-DD'))
    } else {
      setValue(name, selected)
    }
  }, [])

  const handleToggleCalendar = () => {
    if (!disabled) {
      setIsOpen(!isOpen)
    }
  }

  const handleDateChange = (selectedDate: Date) => {
    setTextColor('active')
    setNowDate(selectedDate)

    if (valType === 'string') {
      setValue(name, moment(selectedDate).format('YYYY-MM-DD'))
    } else {
      setValue(name, selectedDate)
    }

    setIsOpen(!isOpen)
  }

  const styleId = isOpen === true ? 'div_container active' : 'div_container'

  return (
    <>
      <input type="date" hidden {...registerFn} />
      <LabelOuterWrap $mb={infoText.length ? '5px' : '0px'} $isFirst={isFirst}>
        <LabelDiv $mb="0px" $display="flex">
          <LabelTitle $display="flex">
            <span>{label}</span>
          </LabelTitle>
          <div style={{ display: 'flex' }}>
            <div className={`${divideStyleIDString(styles, isValid === false ? `${styleId} border_red` : styleId)}`}>
              <div className={styles.toggle_wrap}>
                <DropdownButton className={isOpen ? 'active' : textColor} onClick={handleToggleCalendar}>
                  {moment(nowDate).format('YYYY.MM.DD')}
                </DropdownButton>
              </div>
            </div>
            {timeComp}
          </div>
        </LabelDiv>
      </LabelOuterWrap>

      {isOpen === true && !disabled && (
        <>
          <OuterWrap>
            <Wrap>
              <div className={styles.calendar_wrap}>
                <MonthlyCalendarMain
                  events={null}
                  onChangeMonth={() => {}}
                  selectNext={selectNext}
                  readonly={false}
                  selectedDate={nowDate}
                  onClickDate={!disabled ? handleDateChange : () => {}}
                  onClickMonthBtn={() => {}}
                />
              </div>
            </Wrap>
          </OuterWrap>
        </>
      )}
      {infoText.length > 0 && <InfoComp infoStyleId={infoStyleId} infoText={infoText} />}
    </>
  )
}

export default CustomCalendar

const LabelOuterWrap = styled.div<{ $isFirst: boolean; $mb: string }>`
  margin-top: ${({ $isFirst }) => ($isFirst === true ? '0px' : '23px')};
  margin-bottom: ${({ $mb }) => $mb};
`
const OuterWrap = styled.div`
  position: relative;
`

const Wrap = styled.div`
  padding: 19px 16px 12px 16px;
  margin-top: 12px;
  /* Group 360 */

  /* 달력 */

  /* White */
  background: #ffffff;
  /* Button-Dropshadow */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
`

const StyledCalendar = styled(Calendar)`
  .react-calendar {
    margin: 0px;
    padding: 0px;
  }
  /* 날짜의 글자 스타일 */
  .react-calendar__month-view__days__day-names,
  .react-calendar__month-view__days__day {
    font-family: Pretendard-Regular;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c1c1c;
  }

  /* 전달, 다음달 날짜 일요일 ,토요일 */
  button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.react-calendar__month-view__days__day--neighboringMonth.sunday,
  button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.react-calendar__month-view__days__day--neighboringMonth.saturday {
    color: #bee5ff;
  }

  /* 전달, 다음달 날짜 평일 */
  button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
    color: #dadada;
  }

  /* 선택된 날짜 스타일 */
  .react-calendar__tile--active:hover {
  }

  /* 날짜에 대한 호버 스타일 */
  .react-calendar__tile:hover {
    cursor: pointer;
  }

  /* 요일 스타일 */
  .react-calendar__month-view__weekdays__weekday abbr {
    border: none;
    text-decoration: none; /* 밑줄 제거 */
  }
  /* 주말 요일스타일 */
  .react-calendar__month-view__weekdays__weekday.react-calendar__month-view__weekdays__weekday--weekend {
    color: #2d9fe8;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-family: Pretendard-Regular;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #979797;

    align-content: center;
    vertical-align: middle;
    height: 36px;
  }

  /* 년월 스타일 */
  .react-calendar__navigation {
    display: flex;
  }
  .react-calendar__navigation button:first-child {
    order: 2;
    font-size: 25px;
    padding-right: 42px;
    margin-bottom: 15.8px;
    height: 20px;
  }
  .react-calendar__navigation button:nth-child(2) {
    order: 1;
    padding-left: 0px;
  }
  .react-calendar__navigation button:last-child {
    order: 3;
    font-size: 25px;
    padding-right: 0px;
    height: 20px;

    margin-bottom: 15.8px;
  }

  .react-calendar__viewContainer {
    margin: 0px;
    // max-width: 324px;
    margin-right: 7px;
    margin-left: 7px;
  }

  /* 년월 레이블 스타일 */
  /* 2024년 6월 */
  .react-calendar__navigation__label {
    align-self: start;
    margin-bottom: 15.8px;
    font-family: Pretendard-SemiBold;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.75px;
    text-align: left;
    color: #1c1c1c;
    border: none;
    background-color: white;
  }

  .react-calendar__navigation {
    margin-top: 19px;
    padding: 0px 24px 0px 24px;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
    background-color: unset;

    border: none;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    background-color: unset;

    border: none;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__prev-button:hover,
  .react-calendar__navigation__arrow.react-calendar__navigation__next-button:hover {
    cursor: pointer;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
  .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none;
  }

  /* 날짜 간 간격 */
  .react-calendar__month-view__days {
    vertical-align: middle;
  }

  /* 달력 타일 */
  .react-calendar__tile {
    border: none;
    background-color: white;

    // height: 36px;
    // width: 36px;

    height: 44px;
    width: 9.6%;

    margin-top: 0px;
    border: none;
    background-color: white;
    padding: 0px 0px 0px 0px;
  }

  /* 선택된 날짜 타일 */
  .react-calendar__tile--active {
    font-family: Pretendard-Regular;
    font-size: 15px;

    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c1c1c;
    background-color: green;
    border: solid 1px #00c398;
    background-color: #e6fcf5;
    border-radius: 50%;
    padding: 0px 0px 0px 0px;
  }

  button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.sunday {
    font-weight: bold;
  }
  button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.saturday {
    font-weight: bold;
  }

  /* 호버 및 액티브 스타일 */
  .react-calendar__tile--active:active,
  .react-calendar__tile:focus,
  .react-calendar__tile:hover {
  }

  /* 오늘 날짜에 대한 호버 및 액티브 스타일 */
  .react-calendar__tile--now:active,
  .react-calendar__tile--now:hover {
  }

  /* 오늘 날짜 스타일 */
  .react-calendar__tile--now {
  }

  /* 토요일 날짜 스타일 */
  .react-calendar__tile.saturday {
    font-family: Pretendard-Regular;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2d9fe8;
  }

  /* 일요일 날짜 스타일 */
  .react-calendar__tile.sunday {
    font-family: Pretendard-Regular;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2d9fe8;
  }
`

const DropdownButton = styled.div`
  // height: 49px;
  border: none;
  width: 100%;

  display: grid;

  color: var(--festie-gray-800, #3a3a3a);

  appearance: none;
  background-color: unset;

  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px;

  font-family: Pretendard-Regular;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;

  grid-template-columns: 1fr auto;

  padding-left: 0px;
  padding-right: 0px;
  .transform {
    transform: rotate(90deg);
  }
  &.unopen {
    color: #979797;
  }
  &.active {
    color: #1c1c1c;
  }

  div {
    justify-content: end;
  }
  div:hover {
    cursor: pointer;
  }
`
