import styled from 'styled-components'
import DiaryPlan from './DiaryPlan'
import ChoiceChips from 'src/components/common/form/sub/select/ChoiceChips'
import DiaryDetail from 'src/pages/diary/detail/DiaryDetail'
import { PLANT_CARE } from 'src/utils/common/constants/constants'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'

export default function DiaryPlanList({ careArr }: { careArr: string[] | null }) {
  const { register, getValues, setValue, watch, handleSubmit } = useForm()

  return (
    <>
      <ChoiceChips
        readOnly={true}
        defaultVal={careArr ? careArr : null}
        length={3}
        multiple={true}
        list={PLANT_CARE}
        listName="care"
        register={register}
        getValues={getValues}
        setValue={setValue}
      />
    </>
  )
  // return (
  //   <div>
  //     {careArr.map((item: string) => {
  //       return <DiaryPlan key={item} careId={item} />
  //     })}
  //   </div>
  // )
}

const ListWrap = styled.div``
