import PlantItemLayout from 'src/components/common/layout/PlantItemLayout'
import PlantListLayout from 'src/components/common/layout/PlantListLayout'
import { CollectionType } from 'src/utils/common/type/type'
import { useState } from 'react'
import { useCollectionInfo } from '../hook/CollectionListCustomHook'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import LoadingSpinner from 'src/components/common/container/LoadingSpinner'
import sample from '../../../../assets/images/collection/collectionHeaderImg.png'
import styled from 'styled-components'
import NoDataContainer2 from 'src/components/common/container/NoDataContainer2'

export default function CollectionItemMoveList({
  onSelect,
  padding = '16px 0px 16px 14px',
  type,
}: {
  padding?: string
  onSelect?: (id: number) => void
  type: string
}) {
  const [selectedItem, setSelectedItem] = useState<string | number>('')
  const { isSuccess, collectionList, isPending } = useCollectionInfo()

  if (isPending) {
    return (
      <>
        <LoadingSpinner />
      </>
    )
  }
  if (
    collectionList &&
    collectionList?.data &&
    collectionList.data.myEncyclopediaList &&
    collectionList.data.myEncyclopediaList.length !== 0
  ) {
    return (
      <PlantListLayout height="339px">
        {collectionList?.data.myEncyclopediaList.map((item: CollectionType, index: number) => (
          <PlantItemLayout
            key={generateRandomKey()}
            buttonTitle={type === 'ADD' ? '저장' : '이동'}
            height="92px"
            padding={padding}
            onClick={() => {
              setSelectedItem(item.id)
              onSelect?.(item.id)
            }}
            name={item.title}
            eng={`${item?.plantCount.toString()} 개의 식물`}
            className={
              selectedItem === item.id ? (index === 0 ? 'selected first' : 'selected') : index === 0 ? 'first' : ''
            }
          >
            <Img src={item.coverImageUrl ? item.coverImageUrl : sample} alt="이미지태그" />
          </PlantItemLayout>
        ))}
      </PlantListLayout>
    )
  } else {
    return (
      <PlantListLayout height="339px">
        <NoDataContainer2
          ac="center"
          title="아직 등록된 도감이 없어요!"
          discription="관심있는 식물을 도감에 저장해 보세요!"
        />
      </PlantListLayout>
    )
  }
}

const Img = styled.img`
  width: 60px;
  height: 60px;
`
