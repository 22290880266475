/*
    파일명 : CollectionDetailTitle.tsx
    설명 : 싹싹도감 > 상세 > 이름 부분
    작성일 : 2024-06-14
*/

import DetailViewLabelLayout from 'src/components/common/layout/DetailViewLabelLayout'
import PlantInfoName from '../../plant/sub/PlantInfoName'
import PlantInfoGuide from '../../plant/sub/PlantInfoGuide'
import { PLANT_GUIDE_SAMPE } from 'src/utils/common/constants/constants'
import { PlantDetailInfo } from 'src/utils/common/type/type'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import styled from 'styled-components'

type CollectionDetailTitleType = {
  item: PlantDetailInfo | null
}

export default function CollectionDetailTitle({ item }: CollectionDetailTitleType) {
  let scienName = ''
  let tempInfo = ''
  if (item) {
    const family = item.data.plantTaxonomy?.family || ''
    const genus = item.data.plantTaxonomy?.genus || ''
    const species = item.data.plantTaxonomy?.species || ''
    scienName = `${family} ${genus} ${species}`.trim() || '.'
    const min = item.data.temperatureCondition.min || ''
    const max = item.data.temperatureCondition.max || ''
    tempInfo = `${min}ºC ~ ${max}ºC`
  }

  if (item) {
    return (
      <>
        <PlantInfoName name={item.data.commonName} scienName={scienName} />
        <DetailViewLabelLayout titleClassNm="plant" marginTop="23px" h2MarginBottom="0px" title="관리 가이드">
          <PlantInfoGuide
            careGuideList={[
              { id: 'watering', discription: item.data.wateringInfo.summary || '' },
              { id: 'degree', discription: tempInfo },
              { id: 'soilMng', discription: item.data.environmentInfo.summary || '' },
              { id: 'sunlight', discription: item.data.environmentInfo.description || '' },
              { id: 'pruning', discription: item.data.humidityInfo.summary || '' },
            ]}
          />
        </DetailViewLabelLayout>
      </>
    )
  } else {
    return (
      <>
        <PlantInfoName name={null} scienName={null} />
        <DetailViewLabelLayout marginTop="23px" h2MarginBottom="12px" title={null}>
          <PlantInfoGuide careGuideList={null} />
        </DetailViewLabelLayout>
      </>
    )
  }
}
