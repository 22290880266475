import { SwiperSlide } from 'swiper/react'
import SwiperListTwo from '../swiper/SwiperListTwo'
import ImgWithTitle from '../imgTag/ImgWithTitle'
import styled from 'styled-components'
import { generateRandomKey } from 'src/utils/common/scripts/common'

type PostImages = {
  srcArr: string[]
}

export default function PostImages({ srcArr }: PostImages) {
  return (
    <SwiperListTwo slidesPerView={1} pagination={true}>
      {srcArr.map((item: string) => {
        return (
          <SwiperContainer key={generateRandomKey()}>
            <Img src={item} alt="이미지" />
          </SwiperContainer>
        )
      })}
    </SwiperListTwo>
  )
}

const SwiperContainer = styled(SwiperSlide)`
  width: 100%;
  height: 100%;

  border-radius: 7px;
  margin-bottom: 20px;
`

const Img = styled.img`
  width: 100%;
  aspect-ratio: 15 / 14;

  border-radius: 7px;
`
