import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/free-mode'
import styled from 'styled-components'

export default function SwiperListAutoWidth({
  children,
  onClick,
  spaceBetween = 0.2,
  ml = '0px',
}: {
  spaceBetween?: number
  children: React.ReactNode
  ml?: string
  onClick?: () => void
}) {
  return (
    <SwiperContainer
      onClick={() => {
        onClick?.()
      }}
      $ml={ml}
      freeMode={true}
      grabCursor={true}
      modules={[FreeMode]}
      className="mySwiper"
      spaceBetween={spaceBetween}
      slidesPerView="auto"
    >
      {children}
    </SwiperContainer>
  )
}

const SwiperContainer = styled(Swiper)<{ $ml: string }>`
  margin-left: ${({ $ml }) => $ml};
`
