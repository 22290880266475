import PageSubTitleTwo from 'src/components/common/content/PageSubTitleTwo'
import RcntVwdList from '../sub/RcntVwdList'
import { CONTENT_PADDING } from 'src/utils/common/constants/constants'
import { Box } from '@mui/material'
import { useGetRcntVwdList } from '../hook/CollectionListCustomHook'
import { queryClient } from 'src/App'
import { deleteAll } from 'src/utils/common/scripts/common'

/*
    파일명 : RcntVwdCollections.tsx
    설명 : 최근 본 식물
    작성일 : 2024-06-16
*/
export default function RcntVwdCollections() {
  const { collectionList, isLoading, isPending, isSuccess, changeQueryString, onChangeIndex, setCollectionList } =
    useGetRcntVwdList()

  return (
    <>
      <Box sx={{ p: CONTENT_PADDING, pb: 0, pt: 0 }}>
        <PageSubTitleTwo
          marginTop="23px"
          marginBottom="12px"
          h1ClassNm="small"
          title="최근 본 식물"
          iconComp={
            <span
              onClick={() => {
                deleteAll('recently_clicked')
                setCollectionList(null)
              }}
            >
              지우기
            </span>
          }
        />
      </Box>
      <Box sx={{ pl: CONTENT_PADDING }}>
        <RcntVwdList
          onClick={id => {
            changeQueryString()
          }}
          isPending={isLoading === true ? true : false}
          list={collectionList}
        />
      </Box>
    </>
  )
}
