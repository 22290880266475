import styles from './styles/MainFindResult.module.css'

import MiddleContainer from '../../../common/container/MiddleContainter'
import { CustomButton } from '../../../common/button/CustomButton'
import DualButtonLayout from '../../../common/button/DualButtonLayout'
import SubtitleContainer from 'src/components/common/container/SubtitleContainer'
import { useNavigate } from 'react-router-dom'
import FormMain from 'src/components/common/form/FormMain'
import { useFindPwdCheckValidate } from './hooks/MainFindPwdCustomHook'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import MaskingIcon from 'src/components/common/icon/MakingIcon'
import inputCheck from '../../../../assets/images/icon/InputCheck.png'
import { useEffect, useState } from 'react'
import { newPwdType } from 'src/utils/common/type/type'
import { toast } from 'react-toastify'

export default function MainFindPwdResult() {
  const [isMasking, setIsMasking] = useState(true)
  const {
    newPwd,
    token,
    setNewPwd,
    setToken,
    requestChangePwd,
    isSuccessToUpdatePwd,
    newPwdResult,
    formAlert,
    isValidObj,
    checkPwd,
    checkPwdCheck,
  } = useFindPwdCheckValidate()

  const method = useForm()
  const { register, watch, getValues, handleSubmit } = method

  const navigate = useNavigate()

  // 비밀번호, 중복비밀번호 확인
  useEffect(() => {
    const subscirbe = watch((data, { name }) => {
      if (name === 'pwd') {
        const valid = onChangePwd()
        if (valid && data.pwdCheck) {
          onChangePwdChk()
        }
      } else if (name === 'pwdCheck') {
        onChangePwdChk()
      }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])

  // useEffect(() => {
  //   if (isSuccessToUpdatePwd) {
  //   }
  // }, [isSuccessToUpdatePwd])

  useEffect(() => {
    if (newPwdResult?.message === '새 비밀번호로 변경 되었습니다.') {
      toast.success('비밀번호 변경에 성공하셨습니다.')
      localStorage.setItem('tempToken', '')
      navigate('/')
    } else if (newPwdResult?.message === '비밀번호를 변경을 위한 토큰이 유효하지 않습니다.') {
      toast.success('비밀번호 변경에 실패하셨습니다. 다시 시도해주세요')
      localStorage.setItem('tempToken', '')
      navigate('/')
    }
  }, [newPwdResult])

  // 비밀번호 유효성 확인
  const onChangePwd = () => {
    return checkPwd(getValues('pwd'), getValues('pwdCheck'))
  }

  // 비밀번호 맞는지 체크
  const onChangePwdChk = () => {
    checkPwdCheck(getValues('pwd'), getValues('pwdCheck'))
  }

  const onClickLoginBtn: SubmitHandler<FieldValues> = data => {
    if (!isValidObj.pwd) {
      if (!checkPwd(getValues('pwd'), getValues('pwdCheck'))) return
      checkPwdCheck(getValues('pwd'), getValues('pwdCheck'))
    } else {
      requestAPi()
    }

    // if (Object.values(data).some(value => value === '')) {
    //   return setFormAlert('이메일 또는 비밀번호를 다시 확인해주세요')
    // } else {
    //   setFormAlert('')
    //   return mutate(data)
    // }
  }

  const requestAPi = () => {
    if (newPwd && token) {
      requestChangePwd()
    } else {
      setNewPwd(getValues('pwd'))
      setToken(localStorage.getItem('tempToken') || '')
    }
  }

  return (
    <div className={styles.div_contianer}>
      <SubtitleContainer title="새로운 비밀번호를" title2="입력해주세요." />
      <FormMain>
        <FormMain.LabelLayout
          infoText="영문, 숫자, 특수문자(.!@#$%) 9~20자로 입력해주세요."
          warning={isValidObj.pwd === false ? true : false}
          warnText={formAlert.pwd}
        >
          <FormMain.InputFull
            styleId={
              formAlert && formAlert.pwd.length > 0
                ? 'div_with_full_custom_height mb_10 border_red'
                : 'div_with_full_custom_height mb_10'
            }
            height="43px"
            iconComp={
              <MaskingIcon
                isMasking={isMasking}
                onClick={() => {
                  setIsMasking(prev => !prev)
                }}
              />
            }
            inputComp={
              <FormMain.Input
                styleID=""
                type={isMasking ? 'password' : 'text'}
                placeholder="새로운 비밀번호"
                registerFn={register('pwd', {})}
              />
            }
          />
          <FormMain.InputFull
            styleId={
              formAlert && formAlert.pwd.length > 0
                ? 'div_with_full_custom_height mb_10 border_red'
                : 'div_with_full_custom_height mb_10'
            }
            height="43px"
            iconComp={
              isValidObj.pwd === true ? (
                <img src={inputCheck} alt="확인" />
              ) : (
                <MaskingIcon
                  isMasking={isMasking}
                  onClick={() => {
                    setIsMasking(prev => !prev)
                  }}
                />
              )
            }
            inputComp={
              <FormMain.Input
                styleID=""
                type={isMasking ? 'password' : 'text'}
                placeholder="비밀번호 재확인"
                registerFn={register('pwdCheck', {})}
              />
            }
          />
        </FormMain.LabelLayout>

        <FormMain.Button
          styleID={isValidObj.pwd ? 'btn_submit_01_active' : 'btn_submit_01'}
          onClick={handleSubmit(onClickLoginBtn)}
          height="48px"
          width="100%"
          marginTop="246px"
          type="submit"
        >
          변경하기
        </FormMain.Button>
      </FormMain>
    </div>
  )
}
