import { useForm } from 'react-hook-form'
import CameraIcon from '../../../common/icon/CameraIcon'
import SearchIcon from '../../../common/icon/SearchIcon'
import FormMain from 'src/components/common/form/FormMain'
import InputContainer from 'src/components/common/form/sub/input/InputContainer'
import InputCloseIcon from '../../../../assets/images/icon/InputCloseIcon.png'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { fetchPlantList } from 'src/utils/query/plant/usePlant'
import { plantTypeType, RcntVwdItem } from 'src/utils/common/type/type'
import { toast } from 'react-toastify'
import { useMutation } from '@tanstack/react-query'
import { postDataMultiPartWithAdmin } from 'src/utils/common/api/http'
import { castIdentifyType, isObject } from 'src/utils/common/scripts/checkType'
import { useNavigate, useParams } from 'react-router-dom'
import BackIcon from 'src/components/common/icon/BackIcon'
import SearchParamInitIcon, { SearchParamInitIcon2 } from 'src/components/common/icon/SearchParamInitIcon'
import SearchIconGray, { SearchIconCollection } from 'src/components/common/icon/SearchIconGray'

export default function SearchField({
  onReceiveData,
  type = 'PLANT',
  onChangeLoadingState,
  disabled = false,
  onClick,
  onChangeSearchParam,
  onClickBackBtn,
}: {
  onReceiveData: (lists: plantTypeType[] | undefined) => void
  disabled?: boolean
  onClick?: () => void
  onClickBackBtn?: () => void
  type?: string
  onChangeSearchParam?: (searchParam: string) => void
  onChangeLoadingState?: (state: boolean | undefined) => void
}) {
  const { register, getValues, watch, handleSubmit, setValue } = useForm()
  const [changingSearch, setChangingSearch] = useState(false)
  const { setKeyword, resultList, isLoading: Searching } = fetchPlantList()
  const { plantNm } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (plantNm) {
      onChangeSearchField(plantNm)
      setValue('searchParam', plantNm)
    }
  }, [])

  const {
    mutate,
    data: searchImgResult,
    isPending,
  } = useMutation({
    mutationFn: postDataMultiPartWithAdmin,
    onSuccess: data => {
      console.log(data)
      if (isObject(data) && isObject(data?.data)) {
        const castData = castIdentifyType(data)
        const newList: plantTypeType[] = []
        if (castData?.data.dbResults) {
          onReceiveData(castData.data.dbResults)
        }

        // if (castData?.data.identificationResults) {
        //   castData?.data.identificationResults.forEach((item, index) => {
        //     const family = item.plantTaxonomy?.family || ''
        //     const genus = item.plantTaxonomy?.genus || ''
        //     const species = item.plantTaxonomy?.species || ''
        //     const scienName = `${family} ${genus} ${species}`.trim() || '.'

        //     newList.push({
        //       commonName: item.commonNames[0],
        //       imageUrl: '',
        //       plantSpeciesId: item.commonNames[0],
        //     })

        //     onReceiveData(newList)
        //   })
        // }
      }
    },
    onError: () => {
      toast.error('이미지 검색에 실패하였습니다.')
    },
  })

  useEffect(() => {
    register('image')
  }, [])
  useEffect(() => {
    if (Searching || isPending) {
      onChangeLoadingState?.(true)
    } else if (!Searching && !isPending) {
      onChangeLoadingState?.(undefined) // undefined를 직접 출력하는 것은 특별한 경우에만 사용하는 것이 좋습니다. 대개는 생략되거나 다른 처리 방법을 사용합니다.
    }
  }, [Searching, isPending])

  useEffect(() => {
    if (resultList) {
      onReceiveData(resultList)
    } else if (resultList === undefined) {
      onReceiveData(undefined)
    }
  }, [resultList])

  useEffect(() => {
    const subscirbe = watch((data, { name }) => {
      if (name === 'searchParam') {
        if (getValues('searchParam')) {
          if (getValues('searchParam').length > 0) {
            setChangingSearch(true)
          } else if (getValues('searchParam').length === 0) {
            setChangingSearch(false)
          }
        } else {
          setChangingSearch(false)
        }
      }
    })

    return () => {
      subscirbe.unsubscribe()
    }
  }, [watch])

  const onChangeSearchField = (value: string) => {
    setKeyword(value)
    onChangeSearchParam?.(value)
  }

  const searchImg = (file: File) => {
    const formData = new FormData()

    formData.append('images', file, file.name)

    const url = `${process.env.REACT_APP_API_IDENTIFY_IMG}`
    mutate({ formData, url })
  }

  const onClickSubmitBtn = (data: { [key: string]: string }) => {}

  return (
    <div>
      <FormMain onSubmit={handleSubmit(onClickSubmitBtn)}>
        <Wrap>
          {type === 'COLLECTION' && onClickBackBtn ? (
            <BackIcon
              mr="16px"
              ml="0px"
              onClick={() => {
                if (onClickBackBtn) {
                  onClickBackBtn?.()
                } else {
                  // navigate('../', { state: { 키: 값 } } )
                  navigate('../', { state: { direction: 'back' } })
                }

                //
              }}
            />
          ) : (
            <></>
          )}
          <InputContainer
            onClick={() => {
              onClick?.()
            }}
            width="100%"
            height={type === 'PLANT' ? '49px' : '40px'}
            padding="0px 24px 0px 24px !important"
          >
            {type === 'PLANT' && !changingSearch ? <SearchIconGray /> : <></>}
            {disabled ? (
              <>
                <CollectionDivWrap>
                  <SearchIconCollection />
                  <P>검색</P>
                </CollectionDivWrap>
              </>
            ) : (
              <FormMain.Input
                registerFn={register('searchParam', {
                  required: false,
                  disabled: disabled,
                  onChange: (event: ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target
                    onChangeSearchField(value) // 입력값을 콜백 함수에 전달
                  },
                })}
                placeholder="검색어를 입력해주세요."
                styleID={type === 'PLANT' ? 'input_with_two_button' : 'input_with_two_button_collection'}
                width="75%"
                height="90%"
              />
            )}

            {changingSearch ? (
              <>
                {type === 'PLANT' ? (
                  <SearchParamInitIcon
                    onClick={() => {
                      setValue('searchParam', '')
                    }}
                  />
                ) : (
                  <SearchParamInitIcon2
                    onClick={() => {
                      setValue('searchParam', '')
                    }}
                  />
                )}
              </>
            ) : (
              <>
                {type === 'PLANT' && (
                  <CameraIcon
                    type={type}
                    disabled={disabled}
                    onAddFile={(file: File) => {
                      setValue('image', file)
                      searchImg(file)
                    }}
                  />
                )}
              </>
            )}
          </InputContainer>
          <>
            {type === 'COLLECTION' && (
              <CameraIcon
                onClick={() => {
                  if (disabled) {
                    onClick?.()
                  }
                }}
                type={type}
                disabled={disabled}
                onAddFile={(file: File) => {
                  setValue('image', file)
                  searchImg(file)
                }}
              />
            )}
          </>
        </Wrap>
      </FormMain>
    </div>
  )
}

const CollectionDivWrap = styled.div`
  display: flex;
`

const ImgTag = styled.img`
  height: 20px;
  width: 20px;
  &:hover {
    cursor: pointer;
  }
`

const Wrap = styled.div`
  display: flex;
  align-items: center;
`

const P = styled.span`
  /* 검색어를 입력해주세요. */
  margin-left: 12px;
  /* 검색 */

  /* Caption */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  letter-spacing: -0.02em;

  /* Gray 04 */
  color: #a2a3a7;

  color: #979797;
`
