import { PostSmrySampleType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import LoadingContainer from '../container/LoadingContainer'
import ForwardIcon from '../icon/ForwardIcon'

type MarkedItemInfo = {
  item: {
    title: string
    eng?: string
    level?: string
    id: string
  } | null
}

export default function GrenPlantInfo({ item }: MarkedItemInfo) {
  if (item) {
    return (
      <Wrap>
        <TitleDiv>
          <Title>{item.title}</Title>
          <TitleIcon>
            <ForwardIcon onClick={() => {}} title="일지 보기" />
          </TitleIcon>
        </TitleDiv>
        <BottomDiv>
          <Loc>{item.eng}</Loc>
          <Price>
            <PriceInner>
              <p>Lv. {item.level}</p>
            </PriceInner>
          </Price>
        </BottomDiv>
      </Wrap>
    )
  } else {
    return (
      <Wrap>
        <TitleDiv>
          <LoadingContainer height="18px" width="60%" />
        </TitleDiv>

        <BottomDiv>
          <LoadingContainer height="18px" width="56.4%" />
          <LoadingContainer margin="6px 0px 0px 0px" height="18px" width="46.4%" />
        </BottomDiv>
      </Wrap>
    )
  }
}

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 6px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 13px;
`

const TitleDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 26px;
`

const TitleIcon = styled.div`
  align-content: start;
`

const Title = styled.h2`
  align-content: end;
  /* 극락조/ 여인초 중형 판매합니다 */

  /* 다육식물 */

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  /* identical to box height, or 90% */
  letter-spacing: -0.05em;

  color: #1c1c1c;
`

const BottomDiv = styled.div`
  height: 58px;
  margin-top: 6px;
`

const Loc = styled.div`
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height, or 120% */

  color: #979797;
`

const Price = styled.div`
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const PriceInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 104px;
  height: 30px;

  background: #00c398;
  border-radius: 50px;

  p {
    /* 새싹 */

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    text-align: center;
    letter-spacing: -0.05em;

    color: #ffffff;
  }
`

const Content = styled.div`
  margin-top: 6px;
  p {
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */
    letter-spacing: -0.05em;

    color: #1c1c1c;
  }
`
