/*
    작성일 : 2024.05.21
    작성자 : 김효은
    설명 : 식물등록 > 식물 정보 입력 > 폼 작성
*/

import { useForm } from 'react-hook-form'
import FormMain from 'src/components/common/form/FormMain'
import CustomCalendar from 'src/components/common/form/sub/calendar/CustomCalendar'
import { useEffect, useState } from 'react'
import { useNewPlantValidateCheck } from '../hooks/CreateNewPlantCustomHook'
import { usePlantPageStatus } from '../../../../utils/store/plant/usePlantPageStatus'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { plantDetailInfoType } from 'src/utils/common/type/type'

import CharacterImg from 'src/components/common/charcter/CharacterImg'
import styled from 'styled-components'

import InfoModal from 'src/components/common/modal/InfoModal'

export default function CreateNewPlant({ updateItem }: { updateItem?: plantDetailInfoType | null }) {
  const { register, getValues, setValue, watch, handleSubmit } = useForm()
  const { setPageStatus } = usePlantPageStatus()
  const location = useLocation()

  const { plantId, charId } = useParams()

  const {
    createPlant,
    selectedKey,
    isValidObj,
    confirmed,
    checkCheckBox,
    checkName,
    checkWatering,
    checkDt,
    formAlert,
    isPending,
    updating,
    SuccessToCreate,
  } = useNewPlantValidateCheck()

  useEffect(() => {
    if (updateItem) {
      register('name')
      setValue('name', updateItem.plantName)

      register('watering')
      setValue('watering', updateItem.wateringIntervalInDays)

      checkName(getValues('name'))
      checkDt(getValues('sartDt'), getValues('lastWateringDt'))
      checkWatering(getValues('watering'))
      // checkCheckBox(getValues('spot'), 'spot', '키우는 장소')
      // checkCheckBox(getValues('pot'), 'pot', '화분')
    } else {
      checkDt(getValues('sartDt'), getValues('lastWateringDt'))
      checkWatering(getValues('watering'))
    }
  }, [updateItem])

  useEffect(() => {
    const subscirbe = watch((data, { name }) => {
      if (name === 'name') {
        checkName(getValues('name'))
      } else if (name === 'sartDt' || name === 'lastWateringDt') {
        checkDt(getValues('sartDt'), getValues('lastWateringDt'))
      } else if (name === 'watering') {
        checkWatering(getValues('watering'))
      }

      // else if (name === 'spot') {
      //   checkCheckBox(data.spot, 'spot', '키우는 장소')
      // } else if (name === 'pot') {
      //   checkCheckBox(data.pot, 'pot', '화분')
      // }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])

  const onClickSubmitBtn = (data: { [key: string]: string | { [key: string]: boolean } }) => {
    if (isPending || updating) return
    if (!confirmed) {
      if (!checkName(getValues('name'))) return
      if (!checkDt(getValues('sartDt'), getValues('lastWateringDt'))) return
      checkWatering(getValues('watering'))
      // if (!checkCheckBox(getValues('spot'), 'spot', '키우는 장소')) return
      // checkCheckBox(getValues('pot'), 'pot', '화분')
    } else {
      let platTypeId = plantId ? plantId : ''
      let plantTypeNm = ''
      let charType = charId ? charId : ''
      let selectSpot = ''

      if (updateItem) {
        platTypeId = updateItem.plantTypeId
        plantTypeNm = updateItem.plantType
        charType = updateItem.characterId.toString()
        selectSpot = updateItem.plantLocation
      } else {
        if (location.state.plantLocation) {
          selectSpot = location.state.plantLocation
        }
        if (location.state.plantTypeNm) {
          plantTypeNm = location.state.plantTypeNm
        }
      }

      // const spot = getValues('spot')
      // const trueValuesSpot = Object.keys(spot).filter(key => spot[key] === true)

      // const pot = getValues('pot')
      // const trueValuesPot = Object.keys(pot).filter(key => pot[key] === true)
      createPlant(
        {
          name: getValues('name'),
          lastWateringDt: getValues('lastWateringDt'),
          sartDt: getValues('sartDt'),
          watering: getValues('watering'),
          spot: selectSpot, // 임의
          pot: 'hydro',
          platTypeId: platTypeId,
          plantTypeNm: plantTypeNm,
          charType: charType,
        },
        updateItem ? true : false,
      )

      // setPageStatus('regSchedule')
    }
  }

  return (
    <>
      <FormMain onSubmit={handleSubmit(onClickSubmitBtn)}>
        <FormMain.LabelLayout infoStyleId="text_red" infoText={formAlert && formAlert.name} label="이름">
          <FormMain.InputFull
            styleId={
              isValidObj && isValidObj.name === false
                ? 'div_plant_04 wdt_full mb_10 border_red'
                : 'div_plant_04 wdt_full mb_10'
            }
            inputComp={
              <FormMain.Input
                styleID=""
                type="text"
                placeholder="이름을 지어주세요"
                registerFn={register('name', {
                  required: false,
                })}
              />
            }
          />
        </FormMain.LabelLayout>

        <CustomCalendar
          infoStyleId="text_red"
          infoText={formAlert && formAlert.sartDt}
          label="키우기 시작한 날"
          selected={updateItem?.plantBirthday ? new Date(updateItem.plantBirthday) : new Date()}
          selectNext={false}
          isValid={isValidObj && isValidObj.sartDt}
          setValue={setValue}
          registerFn={register('sartDt', {
            required: false,
          })}
          name="sartDt"
        />

        <CustomCalendar
          label="마지막으로 물 준 날"
          selected={updateItem?.lastWaterday ? new Date(updateItem.lastWaterday) : new Date()}
          selectNext={false}
          setValue={setValue}
          registerFn={register('lastWateringDt', {
            required: false,
          })}
          name="lastWateringDt"
        />

        <FormMain.LabelLayout infoStyleId="text_red" infoText={formAlert && formAlert.watering} label="물주기">
          <FormMain.InputWithText
            isValid={isValidObj && isValidObj.watering}
            text="일에 한 번"
            inputComp={
              <FormMain.InputNumber
                inputComp={
                  <FormMain.Input
                    styleID="input_count"
                    type="text"
                    placeholder="10"
                    registerFn={register('watering', {
                      required: false,
                    })}
                  />
                }
                name="watering"
                setValue={setValue}
                getValues={getValues}
              />
            }
          />
        </FormMain.LabelLayout>

        <FormMain.Button
          styleID={confirmed ? 'btn_submit_01_active mt_52_px' : 'btn_submit_01 mt_52_px'}
          width="100%"
          height=""
          type="submit"
        >
          {isPending || updating ? '등록 중...' : '등록'}
        </FormMain.Button>
      </FormMain>
      <SuccessAlert
        open={SuccessToCreate}
        charId={updateItem ? updateItem.characterId.toString() : charId}
        name={getValues('name') || ''}
        background={updateItem ? updateItem.plantLocation : location.state.plantLocation}
      />
    </>
  )
}

type SuccessAlertProps = {
  open: boolean
  charId: string | undefined
  background: string
  name: string
}
function SuccessAlert({ open, charId, background, name }: SuccessAlertProps) {
  const [isOpened, setIsOpened] = useState(false)
  const [plantNm, setPlantNm] = useState(name)

  const navigate = useNavigate()

  const { setPageStatus } = usePlantPageStatus()
  useEffect(() => {
    if (open) {
      setIsOpened(true)
    }
  }, [open])
  useEffect(() => {
    if (name) {
      setPlantNm(name)
    }
  }, [name])

  return (
    <>
      <InfoModal
        onTransitionExited={() => {
          setPageStatus('selectPlant')
          navigate('/main', { state: { direction: 'back' } })
        }}
        handleClose={() => {}}
        open={isOpened}
        title={`<${plantNm}>의 등록이\n완료되었습니다.`}
        comp={
          (charId && (
            <CharWrap>
              <CharacterImg
                version="small"
                backgroundType={background}
                charId={Number(charId)}
                onClick={() => {}}
                imgStyle={{ width: '130.22px', height: '131.74px' }}
              />
            </CharWrap>
          )) || <></>
        }
      />
    </>
  )
}

const CharWrap = styled.div`
  position: relative;
  z-index: 0;
  text-align: center;
`
