import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import ContainerLayout from '../../../components/common/layout/ContainerLayout'

import MuiMain from '../../../components/common/mui/MuiMain'
import MainUserCreateForm from 'src/components/specific/user/create/MainUserCreateForm'
import Title from 'src/components/common/title/Title'
import Content from 'src/components/common/layout/Content'
import { useNavigate } from 'react-router-dom'

export default function MainUserCreate() {
  const navigate = useNavigate()
  return (
    <ContainerLayout>
      <MuiMain>
        <Title
          onClick={() => {
            navigate('/', { state: { direction: 'back' } })
          }}
          styleID="div_container border_bottom"
          close={true}
          title="회원가입"
        />
        <Content component={<MainUserCreateForm />} />
      </MuiMain>
    </ContainerLayout>
  )
}
