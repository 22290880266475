import { useNavigate } from 'react-router-dom'
import CollectionImgInfo from 'src/components/common/content/CollectionImgInfo'
import SwiperListAutoWidth from 'src/components/common/swiper/SwiperListAutoWidth'

import sample from '../../../../assets/images/collection/sampleColl.png'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import { CollectionType, collectionListSampleType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import { SwiperSlide } from 'swiper/react'
import { useCollectionInfo } from '../hook/CollectionListCustomHook'
import { Skeleton } from '@mui/material'

/*
    파일명 : CollectionMainList.tsx
    설명 : 싹싹도감 메인 > 00님의 식물도감 > 도감 리스트
    작성일 : 2024-06-13
*/
export default function CollectionMainListPending() {
  return (
    <SwiperListAutoWidth spaceBetween={10}>
      <SwiperContainer key={generateRandomKey()}>
        <Skeleton
          variant="rectangular"
          sx={{
            borderRadius: '14px',
            width: '100%',
            height: '171px',
            mb: '9px',
          }}
        />
        <CollectionImgInfo item={null} />
      </SwiperContainer>
      <SwiperContainer key={generateRandomKey()}>
        <Skeleton
          variant="rectangular"
          sx={{
            borderRadius: '14px',
            width: '100%',
            height: '171px',
            mb: '9px',
          }}
        />
        <CollectionImgInfo item={null} />
      </SwiperContainer>
    </SwiperListAutoWidth>
  )
}

const SwiperContainer = styled(SwiperSlide)`
  box-sizing: border-box;

  width: 186px;
  height: 224px;
`

const Img = styled.img`
  box-sizing: border-box;
  width: 100%;
  height: 171px;
  border: 3px solid #ffffff;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  margin-bottom: 9px;
  border-radius: 14px;
`
