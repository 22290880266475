import { CustomButton } from 'src/components/common/button/CustomButton'
import { usePlantPageStatus } from '../../../../utils/store/plant/usePlantPageStatus'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  CHAR_COLOR_SELECT,
  COLOR_SELECT,
  PLANT_SPOT,
  SAMPLE_ACC,
  SAMPLE_CHARACTOR,
} from 'src/utils/common/constants/constants'
import { useNavigate } from 'react-router-dom'
import MuiMain from 'src/components/common/mui/MuiMain'
import { ProfileCommentIcon } from 'src/components/common/icon/ProfileTabIcon'
import { BacgroundIcon, ColorIcon, PotIcon } from 'src/components/common/icon/CharTabIcon'
import { plantDetailInfoType } from 'src/utils/common/type/type'
import SelectChipsList from 'src/components/common/form/sub/select/SelectChipsList'
import { useForm } from 'react-hook-form'
import FormMain from 'src/components/common/form/FormMain'
import ColorChips from 'src/components/common/form/sub/select/ColorChips'
import { useNewPlantValidateCheck } from '../hooks/CreateNewPlantCustomHook'
import Draggable, { DraggableData } from 'react-draggable'
import { generateKey } from 'crypto'
import { generateRandomKey } from 'src/utils/common/scripts/common'

type SelectCharType = {
  updateItem?: plantDetailInfoType
}

type ShapeType = {
  onClick: (charId: number) => void
  selectedChar: number
}

type AccType = {
  onClick: (accNmList: AccListType[]) => void
  selectedAccList: AccListType[] | undefined
}

type MenuTabsType = {
  onClickAcc: (accNmList: AccListType[]) => void
  selectedAccList: AccListType[] | undefined
  onClick: (charId: number) => void
  selectedChar: number
  onClickBg: (item: string) => void
  plantLocation: string
  onClickTab: (idx: number) => void
}

type AccListType = {
  name: string
  x: number
  y: number
}

export default function SelectCharSample({ updateItem }: SelectCharType) {
  const { setPageStatus } = usePlantPageStatus()
  const navigate = useNavigate()
  // const [selectedCharImg, setSelectedCharImg] = useState(SAMPLE_CHARACTOR[0].selectedImg) // 선택한 캐릭터의 이미지 경로 설정
  const [selectedChar, setSelectedChar] = useState(updateItem?.characterId || SAMPLE_CHARACTOR[0].id) // 선택한 캐릭터의 이미지 경로 설정
  const [selectBg, setSelectBg] = useState(updateItem?.plantLocation || PLANT_SPOT[0].name)
  const [showBg, setShowBg] = useState(false)
  const [selectedAccList, setSelectedAccList] = useState<AccListType[] | undefined>()

  const {
    createPlant,

    updating,
  } = useNewPlantValidateCheck()

  const onClickNxtBtn = () => {}

  const setPositionHandler = (newPosition: AccListType) => {
    setSelectedAccList(prev => {
      if (prev && prev.length > 0) {
        const newList = prev.map(item => {
          if (item.name === newPosition.name) {
            return {
              name: item.name,
              x: newPosition.x,
              y: newPosition.y,
            }
          } else {
            return item
          }
        })
        return newList
      } else {
        return [newPosition]
      }
    })
  }

  function getChar() {
    if (showBg && selectBg !== PLANT_SPOT[0].name) {
      return (
        <BgWrap>
          <BgInnerWrap>
            <DraggableIcons type="bg" setPosition={setPositionHandler} selectedAccList={selectedAccList} />
            <BgImg src={require(`/src/assets/images/plant/bg/${selectBg}.png`)} alt="선택한 배경" />
          </BgInnerWrap>

          <BgInnerWrap2>
            <BgChar src={require(`/src/assets/images/plant/character/char${selectedChar}.png`)} alt="선택한 캐릭터" />
          </BgInnerWrap2>
        </BgWrap>
      )
    } else {
      return (
        <>
          <ImgInnerWrap>
            <DraggableIcons type="basic" setPosition={setPositionHandler} selectedAccList={selectedAccList} />
            <img
              style={{ width: '262.52px', height: '255px' }}
              src={require(`/src/assets/images/plant/character/char${selectedChar}.png`)}
              alt="선택한 캐릭터"
            />
          </ImgInnerWrap>
        </>
      )
    }
  }

  return (
    <>
      <SelectedChar container>
        <Grid
          item
          style={{
            marginTop: '17px',
          }}
        >
          {getChar()}
        </Grid>
        <MenuTabs
          onClickAcc={newList => {
            setSelectedAccList(newList)
          }}
          selectedAccList={selectedAccList}
          onClickTab={idx => {
            if (idx === 2) {
              setShowBg(true)
            } else {
              setShowBg(false)
            }
          }}
          onClickBg={item => {
            setSelectBg(item)
          }}
          onClick={id => {
            // setSelectedCharImg(char.selectedImg)
            setSelectedChar(id)
          }}
          selectedChar={selectedChar}
          plantLocation={selectBg}
        />
      </SelectedChar>
      <CustomButton
        onClick={onClickNxtBtn}
        type="button"
        marginTop="38px"
        styleID={selectedChar ? 'btn_submit_01_active' : 'btn_submit_01'}
        width="100%"
      >
        {updateItem ? (updating ? '수정 중...' : '캐릭터 변경') : '다음'}
      </CustomButton>
    </>
  )
}

function getColorById(charId: number) {
  const color = charId % 10

  let result = '#C8927A'
  switch (color) {
    case 1:
      result = '#C8927A'
      break
    case 2:
      result = '#C26CC3'
      break
    case 3:
      result = '#FFF3B7'
      break
    case 4:
      result = '#79C9ED'
      break
    case 5:
      result = '#E2FFC3'
      break
    default:
      result = '#FF83A6'
      break
  }
  return result
}

function MenuTabs({
  onClickAcc,
  selectedAccList,
  onClickTab,
  plantLocation,
  onClick,
  selectedChar,
  onClickBg,
}: MenuTabsType) {
  const [menuIndex, setMenuIndex] = useState(0)
  const { register, getValues, setValue, watch, handleSubmit } = useForm()
  const handleMenuIndex = (index: number) => {
    setMenuIndex(index)
    onClickTab(index)
  }
  return (
    <>
      <MuiMain.MenuTab
        onClick={handleMenuIndex}
        tabObjArr={[
          {
            queryId: 'pot',
            title: (
              <>
                <PotIcon active={menuIndex === 0} />
              </>
            ),
            component: (
              <>
                <Shape onClick={onClick} selectedChar={selectedChar} />
              </>
            ),
          },
          {
            queryId: 'color',
            title: (
              <>
                <ColorIcon active={menuIndex === 1} />
              </>
            ),
            component: (
              <>
                <ColorChips
                  colorChipArr={CHAR_COLOR_SELECT}
                  defaultVal={getColorById(selectedChar)}
                  getValues={getValues}
                  register={register}
                  setValue={setValue}
                  onClick={idx => {
                    const selectToStr = selectedChar.toString()
                    const newColor = idx + 1
                    if (selectToStr.length === 1) {
                      onClick(newColor)
                    } else {
                      const newId = Number(selectToStr.slice(0, 1) + newColor.toString())
                      onClick(newId)
                    }
                  }}
                />
              </>
            ),
          },
          {
            queryId: 'bg',
            title: (
              <>
                <BacgroundIcon active={menuIndex === 2} />
              </>
            ),
            component: (
              <>
                <FormMain>
                  <SelectChipsList
                    list={PLANT_SPOT}
                    defaultVal={`select.${plantLocation}`}
                    onClickChip={onClickBg}
                    watch={watch}
                    length={4}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    mt="0px"
                    mb="0px"
                  />
                </FormMain>
              </>
            ),
          },

          {
            queryId: 'acc',
            title: (
              <>
                <BacgroundIcon active={menuIndex === 3} />
              </>
            ),
            component: (
              <>
                <AccList onClick={onClickAcc} selectedAccList={selectedAccList} />
              </>
            ),
          },
        ]}
      />
    </>
  )
}

function Shape({ onClick, selectedChar }: ShapeType) {
  return (
    <>
      <Grid item display="flex">
        {SAMPLE_CHARACTOR.map((char, index) => (
          <div
            key={char.id}
            onClick={() => {
              // 12
              // 24
              // 35
              const color = (selectedChar % 10) - 1

              onClick(char.id + color)
            }}
          >
            {Math.floor(selectedChar / 10) * 10 + 1 === char.id ? (
              <ImgContainer className={index !== 0 ? 'selected ml_9' : 'selected'}>
                <img
                  src={require(`/src/assets/images/plant/character/char${char.id}.png`)}
                  style={{ width: '80px', height: '80px' }}
                  alt="선택한 캐릭터"
                />
              </ImgContainer>
            ) : (
              <ImgContainer className={index !== 0 ? 'ml_9' : ''}>
                <img
                  src={require(`/src/assets/images/plant/character/${char.smallImg}`)}
                  style={{ width: '80px', height: '80px' }}
                  alt="캐릭터"
                />
              </ImgContainer>
            )}
          </div>
        ))}
      </Grid>
    </>
  )
}

function AccList({ onClick, selectedAccList }: AccType) {
  const [accListStr, setAccListStr] = useState<string>('')
  useEffect(() => {
    if (selectedAccList) {
      let newAccListStr = ''
      for (let i = 0; i < selectedAccList.length; i++) {
        const elem = selectedAccList[i].name
        newAccListStr += `%${elem}%`
      }
      setAccListStr(newAccListStr)
    }
  }, [selectedAccList])

  return (
    <>
      <Grid item display="flex">
        {SAMPLE_ACC.map((char, index) => (
          <div
            key={char.id}
            onClick={() => {
              if (accListStr && selectedAccList) {
                const newList: AccListType[] = []
                let exist = false

                for (let i = 0; i < selectedAccList.length; i++) {
                  const comp = selectedAccList[i]
                  if (comp.name === char.id) {
                    exist = true
                  } else {
                    newList.push(comp)
                  }
                }

                if (!exist) {
                  newList.push({
                    name: char.id,
                    x: 0,
                    y: 0,
                  })
                }
                onClick(newList)

                // let newStr = accListStr
                // if (newStr.includes(char.id)) {
                //   newStr = newStr.replace(`%${char.id}%`, '')
                // } else {
                //   newStr += `%${char.id}%`
                // }
                // const newList = newStr.split('%').filter(a => a !== '')
                // onClick(newList)
              } else {
                onClick([
                  {
                    name: char.id,
                    x: 0,
                    y: 0,
                  },
                ])
              }

              // 12
              // 24
              // 35
            }}
          >
            {accListStr.includes(char.id) ? (
              <ImgContainer className={index !== 0 ? 'selected ml_9' : 'selected'}>
                <img
                  src={require(`/src/assets/images/plant/acc/${char.id}.png`)}
                  style={{ width: '80px', height: '80px' }}
                  alt="선택한 악세서리"
                />
              </ImgContainer>
            ) : (
              <ImgContainer className={index !== 0 ? 'ml_9' : ''}>
                <img
                  src={require(`/src/assets/images/plant/acc/${char.id}.png`)}
                  style={{ width: '80px', height: '80px' }}
                  alt="악세서리"
                />
              </ImgContainer>
            )}
          </div>
        ))}
      </Grid>
    </>
  )
}

function DraggableIcons({
  selectedAccList,
  setPosition,
  type,
}: {
  type: string
  selectedAccList: AccListType[] | undefined
  setPosition: (data: AccListType) => void
}) {
  if (!selectedAccList) return <></>
  return (
    <>
      {selectedAccList.map(item => (
        <DraggableIcon type={type} setPosition={setPosition} key={generateRandomKey()} id={item} />
      ))}
    </>
  )
}

function DraggableIcon({
  type,
  id,
  setPosition,
}: {
  type: string
  id: AccListType
  setPosition: (data: AccListType) => void
}) {
  const [myPosition, setMyPosition] = useState({
    x: id.x,
    y: id.y,
  })

  const handleOnDrag = (data: DraggableData) => {
    setMyPosition({ x: data.x, y: data.y })
    //  setPosition({ name: id.name, x: data.x, y: data.y }) // 드래그를 하는 동안 컴포넌트의 위치를 업데이트 해준다.
  }

  const changeOnDrag = (data: DraggableData) => {
    setMyPosition({ x: data.x, y: data.y })
    setPosition({ name: id.name, x: data.x, y: data.y }) // 드래그를 하는 동안 컴포넌트의 위치를 업데이트 해준다.
  }
  return (
    <>
      <DraggableComp
        scale={2}
        bounds="parent"
        positionOffset={type === 'basic' ? { x: '80%', y: '80%' } : { x: '-3%', y: '10%' }}
        position={{ x: myPosition.x, y: myPosition.y }} // 업데이트된 컴포넌트의 위치를 설정해준다.
        //  onDrag={(_, data) => handleOnDrag(data)} // 드래그 중일 때 제어
        onStop={(_, data) => changeOnDrag(data)}
      >
        <div>
          <img
            src={require(`/src/assets/images/plant/acc/${id.name}.png`)}
            style={{ width: '80px', height: '80px' }}
            alt="악세서리"
          />
        </div>
        {/* <div>
          <button type="button">
            <p>호</p>
          </button>
        </div> */}
      </DraggableComp>
    </>
  )
}

const DraggableComp = styled(Draggable)``

const BgWrap = styled.div`
  margin-top: 17px;
  height: 255px;
`

const BgInnerWrap = styled.div`
  position: relative;
  width: 300px;
  height: 213px;
  div {
    position: absolute;
    z-index: 3;
  }
`

const BgInnerWrap2 = styled.div`
  position: relative;
  height: 0px;
  div {
    position: absolute;
    z-index: 3;
  }
`

const ImgInnerWrap = styled.div`
  div {
    position: absolute;
    z-index: 3;
  }
`

const BgImg = styled.img`
  position: absolute;
  width: 298px;
  height: 288px;
  z-index: 0;
  right: -47%;
  top: -31px;
`

const BgChar = styled.img`
  position: inherit;
  z-index: 1;
  width: 162.22px;
  height: 159.64px;
  top: -156px;
  left: 72px;
`

const SelectedChar = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImgContainer = styled.div`
  &.ml_27 {
    margin-left: 27px;
  }

  &.ml_9 {
    margin-left: 9px;
  }
  align-content: center;
  text-align: center;
  width: 80px;
  height: 76.83px;
  border: 1px solid #dadada;
  border-radius: 10px;
  &.selected {
    box-sizing: border-box;

    background: #e6fcf5;
    border: 1px solid #00c398;
    border-radius: 10px;
    align-content: end;
    text-align: center;
  }

  &:hover {
    cursor: pointer;
  }
`
