import { useState, useEffect } from 'react'
import WeeklyCalendar from './WeeklyCalendar'
import styled from 'styled-components'
import PrevIcon from '../icon/PrevIcon'
import CalendarPrevBtn from '../../../assets/images/icon/CalendarPrevBtn.png'
import CalendarNextBtn from '../../../assets/images/icon/CalendarNextBtn.png' // 변경된 이미지 경로
import CalendarNextBtn2 from '../../../assets/images/icon/CalendarNextBtn2.png' // 변경된 이미지 경로
import { CustomButton } from '../button/CustomButton'
import { CalendarEventType } from 'src/utils/common/type/type'

type WeelyCalendarType = {
  onChangeWeek: (start: Date, end: Date) => void
  onClickMonthBtn: () => void
  selectedDate: Date
  onClickDate: (date: Date) => void
  viewAll?: boolean
  readOnly?: boolean
  events: CalendarEventType[] | null
}
export default function WeeklyCalendarMain({
  viewAll = false,
  readOnly = false,
  onClickMonthBtn,
  onClickDate,
  selectedDate,
  onChangeWeek,
  events,
}: WeelyCalendarType) {
  const [startOfWeek, setStartOfWeek] = useState<Date>(getStartOfWeek(selectedDate))

  const [nextButtonSrc, setNextButtonSrc] = useState<string>(viewAll ? CalendarNextBtn2 : CalendarNextBtn) // 초기값을 CalendarNextBtn으로 설정
  const year = startOfWeek.getFullYear()
  const month = startOfWeek.getMonth() + 1
  const today = new Date()

  // 시작일(일요일) 계산
  function getStartOfWeek(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay())
  }

  // 이전 주 표시
  const handlePrevWeek = () => {
    setStartOfWeek(new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() - 7))
  }

  // 다음 주 표시
  const handleNextWeek = () => {
    setStartOfWeek(new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 7))
  }

  const handleSelectedDate = (date: Date) => {
    onClickDate(date)
  }

  useEffect(() => {
    // 오늘의 날짜를 포함하거나 초과한 주인 경우
    const startOfWeekDate = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate())
    const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

    // startOfWeekDate에 7일을 더한 날짜
    const endOfWeekDate = new Date(startOfWeekDate)
    endOfWeekDate.setDate(endOfWeekDate.getDate() + 6)
    if (!viewAll) {
      if (startOfWeekDate.getTime() > todayDate.getTime() || startOfWeekDate.getDay() === todayDate.getDay()) {
        setNextButtonSrc(CalendarNextBtn) // CalendarNextBtn으로 변경
      } else if (endOfWeekDate.getTime() >= todayDate.getTime()) {
        setNextButtonSrc(CalendarNextBtn) // CalendarNextBtn으로 변경
      } else {
        setNextButtonSrc(CalendarNextBtn2) // 기존 이미지 경로로 변경
      }
    }

    onChangeWeek(startOfWeekDate, endOfWeekDate)
  }, [startOfWeek])

  return (
    <CalendarWrap>
      <div className="div_head">
        <h2 className="title">
          {year}년 {month}월
        </h2>
        <div className="div_button">
          <PrevIcon onClick={handlePrevWeek}>
            <img style={{ verticalAlign: 'super', marginRight: '43px' }} src={CalendarPrevBtn} alt="이전달" />
          </PrevIcon>
          <PrevIcon onClick={handleNextWeek}>
            <img style={{ verticalAlign: 'super', marginRight: '21px' }} src={nextButtonSrc} alt="다음달" />{' '}
            {/* 변경 */}
          </PrevIcon>
          <CustomButton
            onClick={() => {
              onClickMonthBtn()
            }}
            styleID="btn_diary_detail_cal"
            type="button"
          >
            주간
          </CustomButton>
        </div>
      </div>
      <WeeklyCalendar
        viewAll={viewAll}
        readOnly={readOnly}
        disabledDate={today}
        onClickDate={handleSelectedDate}
        selectedDate={selectedDate}
        startOfWeek={startOfWeek}
        events={events}
      />
    </CalendarWrap>
  )
}

const CalendarWrap = styled.div`
  .div_head {
    justify-content: space-between;
    display: flex;
    margin-bottom: 15px;
  }
  .title {
    font-family: 'Pretendard-Regular';
    /* 2024년 4월 */

    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;

    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }
  .div_button {
    display: flex;
  }
`
