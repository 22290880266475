import styled from 'styled-components'
import DotButton from '../button/DotButton'

/*
    파일명 : LabelLayout.tsx
    설명 : 조회 > 상세 페이지 > 라벨 레이아웃
    작성일 : 2024-06-06
*/
export default function DetailViewLineLayout({
  children,

  marginTop = '0',
  marginBottom = '0',
  bb = '5px solid #f9f9f9',

  padding = '0 0 0 0',
}: {
  children: React.ReactNode
  bb?: string
  marginTop?: string
  marginBottom?: string

  padding?: string
}) {
  return (
    <Line $bb={bb} $padding={padding} $marginbottom={marginBottom} $margintop={marginTop}>
      {children}
    </Line>
  )
}

export function DetailViewLineDiv({
  marginTop = '0',
  marginBottom = '0',
  bb = '5px solid #f9f9f9',

  padding = '0 0 0 0',
}: {
  bb?: string
  marginTop?: string
  marginBottom?: string

  padding?: string
}) {
  return <Line $bb={bb} $padding={padding} $marginbottom={marginBottom} $margintop={marginTop} />
}

const Line = styled.div<{ $bb: string; $padding: string; $margintop: string; $marginbottom: string }>`
  margin-top: ${({ $margintop }) => `${$margintop}`};
  margin-bottom: ${({ $marginbottom }) => `${$marginbottom}`};
  border-top: 5px solid #f9f9f9;
  border-bottom: ${({ $bb }) => `${$bb}`};

  width: calc(100%); /* 상위 div의 padding을 고려하여 너비를 조정합니다. */
`
