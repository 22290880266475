import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import MuiMain from 'src/components/common/mui/MuiMain'
import Title from 'src/components/common/title/Title'
import { useNavigate, useNavigation, useParams } from 'react-router-dom'
import SelectCharSample from 'src/components/specific/plant/create/SelectCharSample'

export default function CharacterUpdateSample() {
  const navigate = useNavigate()
  const { plantId } = useParams()

  return (
    <>
      <ContainerLayout styleId="div_container_06">
        <MuiMain>
          <Title
            onClick={() => {
              navigate(`/plant/detail/${plantId}`, { state: { direction: 'back' } })
            }}
            title="캐릭터 변경"
          />
          <SelectCharSample />
        </MuiMain>
      </ContainerLayout>
    </>
  )
}
