import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'

export default function LoadingSpinner({
  mb = '0px',
  mt = '150px',
  size = '25px',
}: {
  mb?: string
  mt?: string
  size?: string
}) {
  return (
    <Spinner $mt={mt} $mb={mb}>
      <CircularProgress style={{ width: size, height: size, color: '#00C398' }} />
    </Spinner>
  )
}

const Spinner = styled.div<{ $mt: string; $mb: string }>`
  width: 100%;
  text-align: center;
  height: 100%;

  margin-top: ${({ $mt }) => $mt};
  margin-bottom: ${({ $mb }) => $mb};
`
