import { Grid } from '@mui/material'
import { CustomButton } from 'src/components/common/button/CustomButton'
import styled from 'styled-components'
import { useLoignDrawer } from 'src/utils/store/login/useLoginDrawer'
import { useNavigate } from 'react-router-dom'

export default function AuthButtonGroup() {
  const { setIsOpenTrue } = useLoignDrawer()
  const navigate = useNavigate()
  return (
    <>
      <FindAccText textAlign="center">
        계정이 기억나지 않아요{' '}
        <span
          onClick={() => {
            navigate('/user/find/id')
          }}
          style={{
            marginLeft: '9px',
          }}
        >
          계정찾기
        </span>
      </FindAccText>
      <Grid width="100%" display="flex" justifyContent="space-between" position="relative">
        <CustomButton onClick={setIsOpenTrue} width="49%" type="button" styleID="btn_submit_01">
          로그인
        </CustomButton>
        <CustomButton
          onClick={() => {
            navigate('/user/create')
          }}
          width="49%"
          type="button"
          styleID="btn_submit_01_active"
        >
          회원가입
        </CustomButton>
      </Grid>
    </>
  )
}

const FindAccText = styled(Grid)`
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  letter-spacing: -0.05em;

  color: #979797;
  margin-top: 56px;
  margin-bottom: 18px;

  & > span {
    border-bottom: 1px solid #1c1c1c;
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;

    letter-spacing: -0.05em;

    color: #1c1c1c;
  }
`
