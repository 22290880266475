import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { generateKey } from 'crypto'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useRecoilState } from 'recoil'
import { queryClient } from 'src/App'
import {
  deleteDataTanstackWithToken,
  getDataTanstackWithToken,
  patchDataJsonWithToken,
  patchDataMultiPartWithToken,
  postDataJsonWithToken,
  postDataJsonWithTokenExceptBody,
  postDataMultiPartWithToken,
} from 'src/utils/common/api/http'
import api, { tokenApi } from 'src/utils/common/api/useAxios'
import { useCheckTokenValidity, useValidationCheck } from 'src/utils/common/hooks/customHook'
import {
  castCommentResponseType,
  castPlazaPostListType,
  castPlazaPostType,
  castPostListType,
  castPostType,
  isObject,
} from 'src/utils/common/scripts/checkType'
import { checkTokenValidity, generateRandomKey } from 'src/utils/common/scripts/common'
import { initPlazaState, selectPostType } from 'src/utils/common/state/state'
import { CommentListType, CommentType, PlazaPostListType, PlazaPostType, PostType } from 'src/utils/common/type/type'

/*
    일지 유효성 검사
*/
export const usePlazaFormCheck = () => {
  const { checkData } = useCheckTokenValidity()
  const navigate = useNavigate()
  const {
    checkInputText,
    selectedKey,
    checkCheckBox,
    isValidObj,
    confirmed,
    formAlert,
    nullCheck,
    regExpCheck,
    setTrue,
    setFalse,
    checkTextArea,
    checkFileCnt,
    setValidCnt,
    checkHashTag,
  } = useValidationCheck()

  useEffect(() => {
    setValidCnt(4)
  }, [])

  const checkTitle = (title: string) => {
    return checkInputText({
      value: title,
      name: 'title',
      label: '제목',
      regExp: '^.{1,50}$',
    })
  }
  const checkContent = (content: string) => {
    return checkTextArea({
      value: content,
      name: 'content',
      label: '내용',
    })
  }

  const checkImgCnt = (filecnt: number) => {
    return checkFileCnt({ value: filecnt, name: 'file', label: '사진' })
  }

  const checkHashTagArr = (hashTagArr: string[]) => {
    return checkHashTag({ value: hashTagArr, name: 'hashtag', label: '해시태그' })
  }

  const checkAll = (data: {
    hashtagArr: string[]
    title: string
    content: string
    type: { [key: string]: boolean }
  }) => {
    if (!checkTitle(data.title)) return false
    if (!checkContent(data.content)) return false
    if (!checkHashTagArr(data.hashtagArr)) return false
    if (!checkCheckBox(data.type, 'type', '게시글 유형')) {
      return false
    } else {
      return true
    }
  }

  const {
    mutate,
    data: createDiaryResult,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: postDataMultiPartWithToken,
    onSuccess: data => {
      toast.success('게시글 등록이 완료되었습니다.')
      queryClient.invalidateQueries({ queryKey: ['board-list'] })
    },
    onError: error => {
      checkTokenValidity(error)
    },
  })
  const {
    mutate: update,
    data: updateResponse,
    isPending: updating,
    isError: errorToUpdating,
    isSuccess: successToUpdating,
  } = useMutation({
    mutationFn: patchDataMultiPartWithToken,
    onSuccess: successD => {
      toast.success('게시글 수정이 완료되었습니다.')
      queryClient.invalidateQueries({ queryKey: ['board-list'] })
    },
    onError: error => {
      checkData(error)
    },
  })

  const createData = (
    data: { [key: string]: string | { [key: string]: boolean } },
    trueValues: string[],
    form: FormData,
  ) => {
    const formData = {
      title: data.title,
      content: data.content,
      hashtagList: data.hashtagArr,
    }

    let url = `${process.env.REACT_APP_API_DOMAIN}board-service`
    if (trueValues[0] === 'qna') {
      url += '/qna'
    } else {
      url += '/general'
    }

    form.append('data', JSON.stringify(formData))

    mutate({ formData: form, url })
  }

  const updateData = (
    targetData: { [key: string]: string | { [key: string]: boolean } },
    trueValues: string[],
    form: FormData,
  ) => {
    const formData = {
      articleId: targetData.articleId,
      title: targetData.title,
      content: targetData.content,
      hashtagList: targetData.hashtagArr,
    }

    let url = `${process.env.REACT_APP_API_DOMAIN}board-service`
    if (trueValues[0] === 'qna') {
      url += '/qna'
    } else {
      url += '/general'
    }

    form.append('data', JSON.stringify(formData))

    update({ formData: form, url })
  }

  return {
    checkImgCnt,
    checkContent,
    selectedKey,
    isValidObj,
    confirmed,
    formAlert,
    checkTitle,
    checkCheckBox,
    checkTextArea,
    checkAll,
    checkHashTagArr,
    createData,
    isSuccess,
    updateData,
    isPending,
    updating,
    errorToUpdating,
    isError,
    setFalse,
    successToUpdating,
    setTrue,
  }
}

export function useCommonPosts() {
  const [response, setResponse] = useState<PlazaPostType | null>(null)
  const [page, setPage] = useState(0)

  const { data, refetch, isSuccess, isPending, error, isError } = useQuery({
    queryKey: [
      'posts',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}board-service/common/list?page=${page}&size=4&sort=createdAt,DESC`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })
  const getLists = () => {
    refetch()
    setPage(prev => prev + 1)
  }

  useEffect(() => {
    if (isObject(data) && isObject(data?.data)) {
      const newObj = castPlazaPostType(data)

      setResponse(newObj)

      console.log(data)
    }
  }, [data])
  const initPage = () => {
    setPage(0)
  }

  return {
    isPendingC: isPending,
    responseC: response,
    isSuccessC: isSuccess,
    initPageC: initPage,
    getListsC: getLists,
  }
}

export function useQnaPosts() {
  const [response, setResponse] = useState<PlazaPostType | null>(null)
  const [page, setPage] = useState(0)

  const { data, refetch, isSuccess, isPending, error, isError } = useQuery({
    queryKey: [
      'posts',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}board-service/qna/list?page=${page}&size=4&sort=createdAt,DESC`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })
  const getLists = () => {
    refetch()
    setPage(prev => prev + 1)
  }

  useEffect(() => {
    if (isObject(data) && isObject(data?.data)) {
      const newObj = castPlazaPostType(data)
      setResponse(newObj)

      console.log(data)
    }
  }, [data])

  const initPage = () => {
    setPage(0)
  }

  return {
    isPendingQ: isPending,
    responseQ: response,
    isSuccessQ: isSuccess,
    initPageQ: initPage,
    getListsQ: getLists,
  }
}

export function useGeneralPosts() {
  const [response, setResponse] = useState<PlazaPostType | null>(null)
  const [page, setPage] = useState(0)

  const { data, refetch, isSuccess, isPending, error, isError } = useQuery({
    queryKey: [
      'posts',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}board-service/general/list?page=${page}&size=4&sort=createdAt,DESC`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })
  const getLists = () => {
    refetch()
    setPage(prev => prev + 1)
  }

  useEffect(() => {
    if (isObject(data) && isObject(data?.data)) {
      const newObj = castPlazaPostType(data)
      setResponse(newObj)

      console.log(data)
    }
  }, [data])
  const initPage = () => {
    setPage(0)
  }

  return {
    isPendingG: isPending,
    responseG: response,
    isSuccessG: isSuccess,
    initPageG: initPage,
    getListsG: getLists,
  }
}

export function useFormatList() {
  const [list, setList] = useState<PlazaPostListType[] | null | undefined>(null)

  const formatList = (newList: PlazaPostListType[]) => {
    if (!list) {
      setList(newList)
    } else {
      // Concatenate new data to the existing list
      setList(prevList => prevList!.concat(newList || []))
    }
  }

  const initList = () => {
    setList(null)
  }

  return { formatList, initList, list }
}

export function useGetPlazaMainPosts() {
  const [list, setList] = useState<PostType[] | null | undefined>(undefined)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [keyword, setKeyword] = useState<string>('')
  const [postType, setPostType] = useState<string>('')
  const [initPlaza, setInitPlaza] = useRecoilState(initPlazaState)
  const navigate = useNavigate()

  const {
    data: boardList,
    refetch,
    isSuccess,
    isLoading,
    isError,
    isPending,
  } = useQuery({
    queryKey: [
      'board-list',
      {
        searchParam: keyword ? `&keyword=${keyword}` : '',
        url: `${process.env.REACT_APP_API_DOMAIN}board-service/common/search?page=${page}&size=${size}&sort=createdAt%2CDESC&type=${postType}`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  const {
    data: boardDetail,
    refetch: gettingDetail,
    isSuccess: successDetail,
    isLoading: loadingDetail,
    isError: failedDetail,
    isPending: pendingDetail,
  } = useQuery({
    queryKey: [
      'board-detail',
      {
        searchParam: keyword ? `${keyword.replace('articleId', '')}` : '',
        url: `${process.env.REACT_APP_API_DOMAIN}board-service/common/detail/`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  // 여기 로직
  const requestApi = async () => {
    if (postType && !isLoading) {
      if (keyword.includes('articleId')) {
        gettingDetail()
      } else if (!keyword.includes('articleId')) {
        if (!keyword.includes(' ') && keyword.indexOf('#') === 0) {
          await fetcHash()
        } else {
          refetch()
        }
      }
    }
  }

  const fetcHash = async () => {
    try {
      const res = await tokenApi.get(
        `/board-service/common/hashtags?name=${keyword.slice(1)}&page=${page}&type=${postType}&size=${size}&sort=createdAt%2CDESC`,
      )
      if (res.data.commonArticleResponseList && !res.data.commonArticleResponseList.length) {
        setList(null)
      } else {
        castData(res.data)
      }
    } catch (error: any) {
      if (error.response.data.status) {
        const sttsCode = error.response.data.status
        if (sttsCode === 401) {
          toast.error('다시 로그인 해주세요')
          navigate('/')
        }
      }
    }
  }

  useEffect(() => {
    if (postType) {
      requestRefetch()
    }
  }, [page])

  const setInit = () => {
    if (page === 0) {
      requestRefetch()
    } else {
      setList(undefined)
      setPage(0)
    }
  }

  const requestRefetch = async () => {
    if (postType) {
      if (keyword.includes('articleId')) {
        gettingDetail()
      } else if (!keyword.includes('articleId')) {
        if (!keyword.includes(' ') && keyword.indexOf('#') === 0) {
          await fetcHash()
        } else {
          refetch()
        }
      }
    }
  }

  useEffect(() => {
    requestRefetch()
  }, [keyword])

  useEffect(() => {
    if (postType) {
      if (keyword.includes('articleId')) {
        if (boardDetail) {
          castDataDetail(boardDetail)
        } else {
          gettingDetail()
        }
      } else if (!keyword.includes('articleId')) {
        if (!keyword.includes(' ') && keyword.indexOf('#') === 0) {
          fetcHash()
        } else {
          refetch()
        }
      }
    }
  }, [postType])

  const changePage = () => {
    if (isLoading) return
    const num = page + 1
    const count = num * size // 1 * 1
    console.log(list ? list.length : 0)
    if (list && list.length === count) {
      setPage(num)
    }
  }

  /* 데이터 변환 */
  useEffect(() => {
    castData(boardList)
  }, [boardList])

  useEffect(() => {
    if (boardDetail) {
      castDataDetail(boardDetail)
    }
  }, [boardDetail])

  const castDataDetail = (data: any) => {
    const newObj = castPostType(data)
    const newList: PostType[] = []
    if (newObj) {
      newList.push(newObj)
      if (newList) setList(newList)
    }
  }

  const castData = (data: any) => {
    if (data && data?.commonArticleResponseList) {
      const newObj = castPostListType(data)

      if (list === null || list === undefined) {
        setList(newObj?.commonArticleResponseList)
      } else {
        setList(prev => {
          if (page === 0) {
            return newObj?.commonArticleResponseList
          }

          if (prev && newObj) {
            return prev?.concat(newObj?.commonArticleResponseList)
          } else {
            return newObj?.commonArticleResponseList
          }
        })
      }
    }
  }

  return {
    list,
    setPostType,
    changePage,
    setKeyword,
    isPending,
    isLoading,
    setList,
    requestApi,
    setPage,
    setInit,

    pendingDetail,
  }
}

export function useGetPlazaPost() {
  const [list, setList] = useState<PostType | undefined>(undefined)

  const [articleId, setArticleId] = useState<number | string | undefined>(undefined)

  const {
    data: boardList,
    refetch,
    isSuccess,
    isLoading,
    isError,
    isPending,
  } = useQuery({
    queryKey: [
      `board-list-${articleId}`,
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}board-service/common/detail/${articleId}`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  const setInit = () => {
    setList(undefined)
  }

  useEffect(() => {
    if (articleId) {
      refetch()
    }
  }, [articleId])

  /* 데이터 변환 */
  useEffect(() => {
    castData(boardList)
  }, [boardList])

  const castData = (data: any) => {
    if (data) {
      const newObj = castPostType(data)
      if (newObj) {
        setList(newObj)
      }
    }
  }

  return {
    detailItem: list,
    pendingItem: isPending,
    loadingItem: isLoading,
    setArticleId,
    initItem: setInit,
    detailId: articleId,
  }
}

export const useLikePost = () => {
  const { checkData } = useCheckTokenValidity()

  const {
    mutate,
    data: response,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: postDataJsonWithTokenExceptBody,
    onSuccess: data => {
      // queryClient.ini
      queryClient.invalidateQueries({ queryKey: ['board-list'] })
      toast.success('게시글에 좋아요를 눌렀습니다.')
    },
    onError: (error: Error) => {
      toast.error('게시글에 좋아요 요청에 실패하였습니다.')
      checkData(error)
    },
  })

  const {
    mutate: deleteLike,
    data: deleteResponse,
    isPending: deleting,
    isSuccess: deletingSuccess,
    isError: deletingFailed,
  } = useMutation({
    mutationFn: deleteDataTanstackWithToken,
    onSuccess: data => {
      // queryClient.ini
      queryClient.invalidateQueries({ queryKey: ['board-list'] })
      toast.success('게시글에 좋아요를 해제하였습니다.')
    },
    onError: (error: Error) => {
      toast.error('게시글에 좋아요 해제에 실패하였습니다.')
      checkData(error)
    },
  })

  const createForm = (clickId: number | string, stts: boolean) => {
    const url = `${process.env.REACT_APP_API_DOMAIN}board-service/likes/${clickId}`
    if (stts) {
      mutate({ url })
    } else {
      deleteLike({ url })
    }
  }

  return {
    isPending,
    createForm,
    isSuccess,
    isError,
    deleting,
    deletingSuccess,
    deletingFailed,
  }
}

export const useLikeComment = () => {
  const { checkData } = useCheckTokenValidity()

  const {
    mutate,
    data: response,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: postDataJsonWithTokenExceptBody,
    onSuccess: data => {
      // queryClient.ini
      queryClient.invalidateQueries({ queryKey: ['board-list'] })
      toast.success('댓글에 좋아요를 눌렀습니다.')
    },
    onError: (error: Error) => {
      toast.error('댓글에 좋아요 요청에 실패하였습니다.')
      checkData(error)
    },
  })

  const {
    mutate: deleteLike,
    data: deleteResponse,
    isPending: deleting,
    isSuccess: deletingSuccess,
    isError: deletingFailed,
  } = useMutation({
    mutationFn: deleteDataTanstackWithToken,
    onSuccess: data => {
      // queryClient.ini
      queryClient.invalidateQueries({ queryKey: ['board-list'] })
      toast.success('댓글에 좋아요를 해제하였습니다.')
    },
    onError: (error: Error) => {
      toast.error('댓글에 좋아요 해제에 실패하였습니다.')
      checkData(error)
    },
  })

  const createForm = (clickId: number | string, stts: boolean) => {
    const url = `${process.env.REACT_APP_API_DOMAIN}board-service/likes/comments/${clickId}`
    if (stts) {
      mutate({ url })
    } else {
      deleteLike({ url })
    }
  }

  return {
    isPending,
    createForm,
    isSuccess,
    isError,
    deleting,
    deletingSuccess,
    deletingFailed,
  }
}

export const useReportPost = () => {
  const { checkData } = useCheckTokenValidity()

  const {
    mutate,
    data: reportPost,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: postDataJsonWithToken,
    onSuccess: data => {
      //  queryClient.ini

      toast.success('게시글 신고 처리가 완료되었습니다..')
    },
    onError: (error: Error) => {
      checkData(error)
    },
  })

  const createForm = (clickId: number | string, content: string) => {
    const url = `${process.env.REACT_APP_API_DOMAIN}board-service/reports`

    const formData = {
      content: content,
      articleId: clickId,
    }
    mutate({ formData, url })
  }

  return {
    isPending,
    createForm,
    isSuccess,
  }
}

export const usePostComment = () => {
  const { checkData } = useCheckTokenValidity()

  const {
    mutate,
    data: reportPost,
    isPending,
    isError,
    isSuccess,
  } = useMutation({
    mutationFn: postDataJsonWithToken,
    onSuccess: data => {
      //  queryClient.ini

      toast.success('댓글 등록이 완료되었습니다..')
    },
    onError: (error: Error) => {
      checkData(error)
    },
  })

  const {
    mutate: update,
    data,
    isPending: updating,
    isError: errorToUpdating,
    isSuccess: successToUpdating,
  } = useMutation({
    mutationFn: patchDataJsonWithToken,
    onSuccess: successD => {
      toast.success('댓글 수정이 완료되었습니다.')
    },
    onError: error => {
      checkData(error)
    },
  })

  const createForm = (id: number | string, commentType: string, content: string) => {
    let url
    let formData
    if (commentType === 'REPLY') {
      url = `${process.env.REACT_APP_API_DOMAIN}board-service/comments/replies`
      formData = {
        content: content,
        parentId: id,
      }
    } else {
      url = `${process.env.REACT_APP_API_DOMAIN}board-service/comments`

      formData = {
        content: content,
        articleId: id,
      }
    }
    mutate({ formData, url })
  }

  const updateForm = (id: number | string, content: string) => {
    const url = `${process.env.REACT_APP_API_DOMAIN}board-service/comments/${id}`
    const formData = {
      content: content,
    }

    update({ formData, url })
  }

  return {
    isPending,
    createForm,
    isSuccess,
    isError,
    updateForm,
    errorToUpdating,
    successToUpdating,
    updating,
  }
}

export function useGetComments() {
  const [result, setResult] = useState<CommentListType[] | null | undefined>(null)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(2)
  const [articleId, setArticleId] = useState<string | number>()

  const {
    data: boardList,
    refetch,
    isSuccess,
    isLoading,
    isPending,
    isError,
  } = useQuery({
    queryKey: [
      'comment-list',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}board-service/comments/${articleId}?parent-page=${page}&parent-size=${size}&children-page=0&children-size=0`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  const requestApi = () => {
    if (articleId) {
      refetch()
    }
  }

  useEffect(() => {
    requestApi()
  }, [page])

  useEffect(() => {
    requestApi()
  }, [articleId])

  const changePage = () => {
    // API페이징 처리 안되어있어서 주석 처리함
    // if (isLoading) return
    // const num = page + 1
    // const count = num * size // 1 * 1
    // console.log(result ? result.length : 0)
    // if (result && result.length === count) {
    //   setPage(num)
    // }
  }

  /* 데이터 변환 */
  useEffect(() => {
    castData(boardList)
  }, [boardList])

  const castData = (data: any) => {
    if (data) {
      const newObj = castCommentResponseType({ list: data })

      if (result === null || result === undefined) {
        setResult(newObj?.list)
      } else {
        setResult(prev => {
          if (page === 0) {
            return newObj?.list
          }

          if (prev && newObj) {
            return prev?.concat(newObj.list)
          } else {
            return newObj?.list
          }
        })
      }
    }
  }

  return {
    result,
    setArticleId,
    changePage,
    isPending,
    isLoading,
    requestApi,
    isSuccess,
  }
}

export default function useDeleteComment() {
  const { checkData } = useCheckTokenValidity()

  const {
    mutate,
    data,
    isPending: deletingComment,
    isSuccess: deltingSuceess,
    isError: deletingFailed,
  } = useMutation({
    mutationFn: deleteDataTanstackWithToken,
    onSuccess: response => {
      queryClient.invalidateQueries({ queryKey: ['comment-list'] })
      toast.success('댓글 삭제에 성공하였습니다.')
    },
    onError: response => {
      checkData(response)
    },
  })

  const deleteComment = (id: string | number) => {
    const url = `${process.env.REACT_APP_API_DOMAIN}board-service/comments/${id}`
    mutate({ url })
  }

  return {
    deleteComment,
    deletingComment,
    deltingSuceess,
    deletingFailed,
  }
}

export function useDeletePost() {
  const {
    mutate,
    data,
    isPending,
    isSuccess: deletingSuccess,
  } = useMutation({
    mutationFn: deleteDataTanstackWithToken,
    onSuccess: response => {
      toast.success('게시글 삭제에 성공하였습니다.')
    },
    onError: response => {
      toast.error('게시글 삭제에 실패하였습니다.')
    },
    onSettled: async () => {
      await queryClient.cancelQueries({ queryKey: ['board-list'] })
      queryClient.invalidateQueries({ queryKey: ['board-list'] })
    },
  })
  const deleteItem = (id: number, sort: string) => {
    let url = `${process.env.REACT_APP_API_DOMAIN}board-service`
    if (sort === 'qna') {
      url += `/qna/${id}`
    } else if (sort === 'general') {
      url += `/general/${id}`
    } else if (sort === 'diary') {
      url = `${process.env.REACT_APP_API_DOMAIN}my-plant/diaries/${id}`
    } else if (sort === 'unset') {
      return
    }

    mutate({ url })
  }

  return {
    deleteItem,
    isPending,
    deletingSuccess,
  }
}
