import styles from './styles/DualButtonLayout.module.css'
import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'

export default function DualButtonLayout({
  children,
  styleID = 'div_dual_btn',
}: {
  children: JSX.Element | JSX.Element[]
  styleID?: string
}) {
  return <div className={`${divideStyleIDString(styles, styleID)}`}>{children}</div>
}
