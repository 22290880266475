import { debug } from 'console'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { queryClient } from 'src/App'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'

import MuiMain from 'src/components/common/mui/MuiMain'
import Title from 'src/components/common/title/Title'
import Follower from 'src/components/specific/follow/Follower'
import Following from 'src/components/specific/follow/Following'
import { useGetFollowerList, useGetFollowingList } from 'src/components/specific/follow/hook/followCustomHook'
import { profilePageUserInfo } from 'src/components/specific/main/hooks/MainHomeCustomHook'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import { transitionSttsState } from 'src/utils/common/state/state'
import { useProfilePageStatus } from 'src/utils/store/profile/useProfilePageStatus'

export default function Follow() {
  const { profile, isPending, isSuccess, refetch } = profilePageUserInfo()
  const { setPageStatus, pageStatus, pageValue, setPageValue } = useProfilePageStatus()
  const { userId } = useParams()

  const location = useLocation()

  const {
    changePage: changeFollowerPage,
    loadingList: loadingFollowers,
    list: Followers,
    initFollower,
    chageFollowerList,
    followerIsChanged,
  } = useGetFollowerList()

  const {
    changePage: changeFollowingPage,
    loadingList: loadingFollowings,
    list: Followings,
    initFollowing,
    chageFollowingList,
    followingIsChanged,
  } = useGetFollowingList()

  const [transitionStts, setTransitionStts] = useRecoilState(transitionSttsState)

  // 뒤로가기
  let backPath = '../'
  if (location.state.backPath) {
    backPath = location.state.backPath || '../'
  }

  // 닉네임
  let myName = ''
  if (location.state?.name && typeof location.state?.name === 'string') {
    myName = location.state.name
  }

  const handleMenuIndex = (index: number) => {
    if (index === 0) {
      initFollower()
    } else {
      initFollowing()
    }
  }

  const afterRequestFollower = (id: string | number, followYn: boolean) => {
    if (Followers) {
      const newFollowers = Followers?.map(item => {
        if (item.id !== id) {
          return item
        } else {
          return {
            ...item,
            isFollowed: followYn,
          }
        }
      })

      if (newFollowers) {
        chageFollowerList(newFollowers)
        followingIsChanged(true)
        refetch()
      }
    }
  }

  const afterRequestFollowing = (id: string | number, followYn: boolean) => {
    if (Followings && Followings.length > 0) {
      const newFollowings = Followings?.map(item => {
        if (item.id !== id) {
          return item
        } else {
          return {
            ...item,
            isFollowed: followYn,
          }
        }
      })

      if (newFollowings) {
        chageFollowingList(newFollowings)

        followerIsChanged(true)
        refetch()
      }
    }
  }

  return (
    <>
      <Title
        title={profile?.data.nickname || null}
        onClick={() => {
          setPageStatus('main')
        }}
      />
      <MuiMain.MenuTab
        defaultVal={pageStatus}
        onClick={handleMenuIndex}
        tabObjArr={[
          {
            queryId: 'follower',
            title: `팔로워 ${profile?.data.followerCount}`,
            component: (
              <>
                <Follower
                  afterFollow={afterRequestFollower}
                  list={Followers}
                  loadingList={loadingFollowers}
                  changePage={changeFollowerPage}
                  key={generateRandomKey()}
                />
              </>
            ),
          },
          {
            queryId: 'following',
            title: `팔로잉 ${profile?.data.followingCount}`,
            component: (
              <>
                <Following
                  afterFollow={afterRequestFollowing}
                  list={Followings}
                  loadingList={loadingFollowings}
                  changePage={changeFollowingPage}
                  key={generateRandomKey()}
                />
              </>
            ),
          },
        ]}
      />
    </>
  )
}
