import { getCareGuideSampleName } from 'src/utils/common/scripts/common'
import { PlantCareGuideSampleType } from 'src/utils/common/type/type'
import styled from 'styled-components'

export default function PlantInfoElement({ careGuideObj }: { careGuideObj: PlantCareGuideSampleType }) {
  const careGuideName = getCareGuideSampleName(careGuideObj.id)
  return (
    <Element>
      <ImgWrap>
        <img
          style={{ width: careGuideName.width, height: careGuideName.height }}
          src={require(`/src/assets/images/plant/guide/${careGuideObj.id}.png`)}
          alt={careGuideName.careNm}
        />
      </ImgWrap>
      <Name>{careGuideName.careNm}</Name>
      <Discription>{careGuideObj.discription}</Discription>
    </Element>
  )
}

const Element = styled.div`
  display: flex;
  height: max-content;

  display: grid;
  grid-template-columns: 0.5fr 1fr 2fr; /* 3개의 컬럼으로 반복 */
`

const ImgWrap = styled.div`
  width: 25px;

  align-content: center;
  text-align: center;
`

const Name = styled.p`
  align-content: center;
  font-family: 'Pretendard-Regular';

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  /* Black */
  color: #242424;

  width: 57px;
`

const Discription = styled.span`
  align-content: center;
  /* 1 ~2 주마다 */

  /* Caption */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  letter-spacing: -0.02em;

  /* Black */
  color: #242424;

  width: max-content;
  max-width: 153px;
  background: #f9fbff;
  border-radius: 13px;
  padding: 4px 12px 4px 12px;
`
