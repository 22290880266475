import styled from 'styled-components'
import write from '../../../assets/images/post/writePost.png'

export default function WriteIcon({ onClick }: { onClick: () => void }) {
  return (
    <>
      <Img onClick={onClick} src={write} alt="글쓰기" />
    </>
  )
}

const Img = styled.img`
  width: 23px;
  height: 20px;
`
