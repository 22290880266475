import { Swiper, SwiperSlide, useSwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import styled from 'styled-components'
import ImgTag from '../imgTag/ImgTag'
import Character from 'src/components/specific/main/sub/Character'
import { myPlantType } from 'src/utils/common/type/type'
import { Skeleton } from '@mui/material'

interface DataObj {
  title: JSX.Element | JSX.Element[]
  imgSrc: string
  subText: JSX.Element | JSX.Element[]
  width: string
  height: string
}

interface SwiperMainProps {
  list: string[] | null
}

export default function SwiperFullList({ list }: SwiperMainProps) {
  console.log(list)

  if (list && list.length > 0) {
    return (
      <SwiperContainer slidesPerView={1} pagination={true} modules={[Pagination, Autoplay]} className="mySwiper">
        {list.map(item => (
          <SwiperItem key={Math.random()}>
            <CharContainer src={item} alt="이미지" $url={item} />
          </SwiperItem>
        ))}
      </SwiperContainer>
    )
  } else {
    return (
      <>
        <SwiperContainer slidesPerView={1} pagination={true} modules={[Pagination, Autoplay]} className="mySwiper">
          <SwiperItem key={Math.random()}>
            <Skeleton
              variant="rectangular"
              sx={{
                aspectRatio: '15 / 14',
                borderRadius: '7px',
                marginBottom: '16px',
              }}
            />
          </SwiperItem>
          <SwiperItem key={Math.random()}>
            <Skeleton
              variant="rectangular"
              sx={{
                aspectRatio: '15 / 14',
                borderRadius: '7px',
                marginBottom: '16px',
              }}
            />
          </SwiperItem>
        </SwiperContainer>
      </>
    )
  }
}

const SwiperContainer = styled(Swiper)`
  width: 100%;
  aspect-ratio: 1 / 1.1; /* 16:9 비율 설정 */
  margin-left: 0px;

  flex-direction: column;

  display: flex;
  box-sizing: border-box;

  * > .swiper-pagination-bullet-active {
    width: 22px;
    height: 7px;

    background: #00c398;
    border-radius: 18px;
  }
`

const SwiperItem = styled(SwiperSlide)`
  width: 100%;
  height: 100%;
  margin-top: 20px;

  border-radius: 12px;
  margin-bottom: 20px;
`

const CharContainer = styled.img<{ $url: string }>`
  width: 100%;
  aspect-ratio: 15 / 14;

  // background: ${({ $url }) => `url(${$url})`};

  border-radius: 7px;

  margin-bottom: 16px;
`
