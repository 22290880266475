import { useEffect, useState } from 'react'
import PlantItemLayout from 'src/components/common/layout/PlantItemLayout'
import PlantListLayout from 'src/components/common/layout/PlantListLayout'
import { ItemObjType, plantTypeType } from 'src/utils/common/type/type'
import MyPlantSample from '../../../assets/images/plant/MyPlantSample.png'
import { selectBoxContentState, selectMyPlantState } from 'src/utils/common/state/state'
import { useRecoilState } from 'recoil'
import { useForm } from 'react-hook-form'
import { getDataTanstackWithToken } from 'src/utils/common/api/http'
import { useQuery } from '@tanstack/react-query'
import LoadingSpinner from '../container/LoadingSpinner'
import { generateRandomKey, isArrayOfTypeMyPlantType } from 'src/utils/common/scripts/common'
import NoData from './Nodata'

type MyPlantListType = {
  itemArr: plantTypeType[]
  height?: string
  firstmt?: string
  padding?: string
  registerFn?: React.InputHTMLAttributes<HTMLInputElement>
  getValues: ReturnType<typeof useForm>['getValues']
  setValue: ReturnType<typeof useForm>['setValue']
  name: string
  register: ReturnType<typeof useForm>['register']
}

export default function PlantListCommon({
  itemArr,
  height = '448px',
  firstmt = '17px',
  padding = '10px 0px 10px 14px',
  getValues,
  setValue,
  name,
  registerFn,
  register,
}: MyPlantListType) {
  const [selectedPlant, setSelectedPlant] = useRecoilState(selectMyPlantState)

  const [selectedItem, setSelectedItem] = useState<string | number>('')

  const {
    data,
    refetch,
    isSuccess,
    isPending: loadingList,
  } = useQuery({
    queryKey: [
      'my-plant',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}my-plant/plants/list`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
    staleTime: 300000,
  })
  useEffect(() => {
    register(name + 'Id')
    register(name + 'Name')
    register(name + 'PlantType')
  }, [register, name])

  useEffect(() => {
    refetch()
  }, [])

  if (loadingList && !data) {
    return (
      <>
        <LoadingSpinner mb="120px" mt="120px" />
      </>
    )
  }

  if (!data?.myPlantResponseList) {
    return (
      <>
        <NoData discription="내 식물 리스트가 없습니다." />
      </>
    )
  }

  return (
    <>
      <input type="text" hidden {...registerFn} />
      <PlantListLayout height={height}>
        {data?.myPlantResponseList &&
          isArrayOfTypeMyPlantType(data?.myPlantResponseList) &&
          data?.myPlantResponseList.map((item, index) => (
            <PlantItemLayout
              firstmt={firstmt}
              padding={padding}
              onClick={() => {
                setSelectedItem(item.myPlantId) // 스타일링 위해서
                // setValue(name, item.plantName + ' - ' + item.plantName) // Form 위해서
                setValue(name, item.plantName) // Form 위해서
                setValue(name + 'Id', item.myPlantId)
                setValue(name + 'Name', item.plantName)
                setValue(name + 'PlantType', item.plantName)
              }}
              name={item.plantName}
              key={generateRandomKey()}
              className={
                item.plantName === getValues(name)
                  ? index === 0
                    ? 'selected first'
                    : 'selected'
                  : index === 0
                    ? 'first'
                    : ''
              }
            >
              <img
                src={item.imageUrl && !item.imageUrl.includes('test_url') ? item.imageUrl : MyPlantSample}
                alt="이미지태그"
                style={{
                  width: '60px',
                  height: '60px',
                }}
              />
            </PlantItemLayout>
          ))}
      </PlantListLayout>
    </>
  )
}
