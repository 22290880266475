import styled from 'styled-components'

type LocationType = {
  title: string
  onClick: () => void
  iconComp: React.ReactNode
}

export default function CustomLocation({ title, onClick, iconComp }: LocationType) {
  return (
    <Loc
      onClick={() => {
        onClick()
      }}
    >
      <span>{title}</span>
      <div>{iconComp}</div>
    </Loc>
  )
}

const Loc = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  /* Frame 177 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;

  width: 100%;
  height: 49px;

  border: 1px solid #dadada;
  border-radius: 7px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  span {
    /* 거래 희망 장소를 선택해주세요. */

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    align-items: center;
    letter-spacing: -0.05em;

    color: #979797;
  }
  :hover {
    cursor: pointer;
  }
`
