import { FormProvider, useForm } from 'react-hook-form'
import Layout from './components/common/layout/Layout'
import { RecoilRoot, useRecoilState } from 'recoil'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { theme } from './utils/common/mui/them'
import { StyledEngineProvider } from '@mui/material/styles'
import { useCheckTokenValidity } from './utils/common/hooks/customHook'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import React, { useState } from 'react'
import MainHome from './pages/main/MainHome'
import Plan from './pages/plan/Plan'
import DiaryCreate from './pages/diary/DiaryCreate'
import CreateNewPlant from './components/specific/plant/create/CreateNewPlant'
import MainUserCreate from './pages/user/create/MainUserCreate'
import Login from './pages/login/Login'
import { CollectionPage, FindPage, GreenPage, PlantPage, PlazaPage, ProfilePage } from './utils/common/routes/AppRouter'
import DiaryDetail from './pages/diary/detail/DiaryDetail'
import { checkTokenValidity } from './utils/common/scripts/common'
import { transitionSttsState } from './utils/common/state/state'

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: Error) => {
      checkTokenValidity(error)
    },
  }),
})

function App() {
  const [transitionStts, setTransitionStts] = useRecoilState<string>(transitionSttsState)
  const location = useLocation()

  console.log(location.state ? location.state : '')

  // Object.keys(castLocation).forEach(key => {

  //   console.log(castLocation[key].toString())
  // })

  const method = useForm()

  return (
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <FormProvider {...method}>
          <ThemeProvider theme={theme}>
            <Layout>
              <ToastContainer
                autoClose={2500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
              />

              <TransitionGroup
                className="transition-wrapper"
                childFactory={child => {
                  return React.cloneElement(child, {
                    classNames: location.state?.direction || 'navigate-push',
                  })
                }}
              >
                <CSSTransition
                  onEnter={() => {
                    setTransitionStts('ENTER')
                    console.log('onEnter')
                  }}
                  onEntered={() => {
                    setTransitionStts('ENTERED')
                    console.log('onEntered')
                  }}
                  onExit={() => {
                    setTransitionStts('EXIT')
                  }}
                  onExiting={() => {
                    setTransitionStts('EXITING')
                  }}
                  onExited={() => {
                    setTransitionStts(`${location.pathname}EXITED`)
                    console.log('onExited')
                  }}
                  key={location.key}
                  classNames={location.state?.direction || 'navigate-push'}
                  timeout={300}
                >
                  <Routes location={location}>
                    <Route path="/" element={<Login />} />
                    <Route path="/user/create" element={<MainUserCreate />} />
                    <Route path="/user/find/*" element={<FindPage />} />
                    <Route path="/test" element={<CreateNewPlant />} />
                    <Route path="/main" element={<MainHome />} />
                    <Route path="/plant/*" element={<PlantPage />} />
                    <Route path="/diary/detail" element={<DiaryDetail />} />
                    <Route path="/diary/create" element={<DiaryCreate />} />
                    <Route path="/plan/create" element={<Plan />} />
                    <Route path="/collection/*" element={<CollectionPage />} />
                    <Route path="/plaza/*" element={<PlazaPage />} />
                    <Route path="/profile/*" element={<ProfilePage />} />

                    <Route path="/green/*" element={<GreenPage />} />
                    <Route path="*" element={<p>에러</p>} />
                  </Routes>
                </CSSTransition>
              </TransitionGroup>
            </Layout>
          </ThemeProvider>
        </FormProvider>
      </QueryClientProvider>
    </StyledEngineProvider>
  )
}

export default App

export function AppLayout() {
  return <></>
}
