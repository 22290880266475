import axios from 'axios'
import getAccessToken, { getAccessToken2 } from 'src/utils/common/scripts/getAccessToken'
import { getNewAccessToken } from './http'

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_DOMAIN}`,
  headers: { Authorization: getAccessToken() },
  withCredentials: true,
})

export const socialApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_DOMAIN}`,
  headers: { Authorization: getAccessToken() },
})

export const tokenApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_DOMAIN}`,
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
})

// 요청 인터셉터 설정
tokenApi.interceptors.request.use(
  config => {
    const accessToken = getAccessToken2()
    // config 객체를 직접 수정하지 않고 새로운 객체를 생성하여 반환
    const newConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: accessToken ? accessToken : config.headers.Authorization,
      },
    }
    return newConfig
  },
  error => Promise.reject(error),
)

tokenApi.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config

    if (error.response.data.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        const newToken = await getNewAccessToken()

        // 원래 요청을 다시 시도
        return tokenApi({
          ...originalRequest,
          headers: {
            ...originalRequest.headers,
            Authorization: `Bearer ${newToken}`,
          },
        })
      } catch (err) {
        return Promise.reject(err)
      }
    }

    return Promise.reject(error)
  },
)

export default api
