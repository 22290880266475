/*eslint-disable */

import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import { CustomButton } from '../button/CustomButton'
import InputField from './sub/input/InputField'
import styles from './styles/FormMain.module.css'
import AlertText from './sub/info_text/AlertText'
import { FormEvent } from 'react'
import LabelLayout from './sub/label/LabelLayout'
import InputTimerField from './sub/input/InputTimerField'
import InputLayout from './sub/input/InputLayout'
import InputWithButton from './sub/input/InputWithButton'
import InputWithTimerButton from './sub/input/InputWIthTimerButton'
import InputFull from './sub/input/InputFull'
import LabelTag from './sub/label/LabelTag'
import InputWithText from './sub/select/SelectBoxWithText'
import InputNumber from './sub/input/InputNumber'
import TextAreaField from './sub/textArea/TextAreaField'
import ToggleField from './sub/toggle/ToggleField'
import ImageFileField from './sub/input/ImageFileField'

export type FormMainProps = {
  children: JSX.Element | JSX.Element[]
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void
  styleID?: string
  style?: any
}

export default function FormMain({ children, onSubmit, styleID, style }: FormMainProps) {
  return (
    <form onSubmit={onSubmit} className={`${divideStyleIDString(styles, styleID)}`} style={style}>
      {children}
    </form>
  )
}

FormMain.Input = InputField
FormMain.InputTimer = InputTimerField
FormMain.Button = CustomButton
FormMain.AlertText = AlertText
FormMain.LabelLayout = LabelLayout
FormMain.InputLayout = InputLayout
FormMain.InputWithButton = InputWithButton
FormMain.InputWithTimerButton = InputWithTimerButton
FormMain.InputFull = InputFull
FormMain.Label = LabelTag
FormMain.InputWithText = InputWithText
FormMain.InputNumber = InputNumber
FormMain.TextArea = TextAreaField
FormMain.Toggle = ToggleField
FormMain.ImageFile = ImageFileField
// input[type=button]도 있지만
// 일반 버튼태그로 사용해도 문제없기에
// 이미 만들어 놓은 버튼 컴포넌트로 대체
