import styles from '../styles/PlantProgress.module.css'
import icon from '../../../../assets/images/icon/ProgressPlantIcon.png'
import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import BackIcon from 'src/components/common/icon/BackIcon'
import styled from 'styled-components'
/*
    작성자 : 김효은
    작성일 : 2024.05.27
    설명 : 식물등록 > 진행 사항

*/

export default function PlantProgress({ onClick, level }: { level: number; onClick: () => void }) {
  const selectedIcon = (
    <div className={styles.selected}>
      <img src={icon} alt="진행중" />
    </div>
  )

  const passedIcon = <div className={styles.selected} />

  const deselected = (
    <>
      <div className={styles.line} />
      <div className={styles.deselected_wrap}>
        <div className={styles.deselected} />
      </div>
    </>
  )

  return (
    <div className={styles.container}>
      <BackIcon as="center" mt="0px" ml="0px" mr="33.33px" onClick={onClick} />
      <Div>
        <div className={styles.selected_wrap}>{level === 1 ? selectedIcon : passedIcon}</div>
        {level === 1 ? (
          deselected
        ) : level === 2 ? (
          <>
            <div className={styles.selected_line} />
            <div className={styles.selected_wrap}>{selectedIcon}</div>
          </>
        ) : (
          <>
            <div className={styles.selected_line} />
            <div className={styles.selected_wrap}>{passedIcon}</div>
          </>
        )}

        {level === 3 ? (
          <>
            <div className={styles.selected_line} />
            <div className={styles.selected_wrap}>{selectedIcon}</div>
          </>
        ) : (
          deselected
        )}
      </Div>
    </div>
  )
}

const Div = styled.div`
  display: flex;
  width: 100%;
`
