import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useRecoilState } from 'recoil'

import { deleteDataTanstackWithToken, getDataTanstackWithToken } from 'src/utils/common/api/http'
import { useCheckTokenValidity } from 'src/utils/common/hooks/customHook'
import { castDiary, castDiaryList, castPlantDetailInfoType, isObject } from 'src/utils/common/scripts/checkType'
import { alertState } from 'src/utils/common/state/state'
import {
  CalendarEventType,
  diaryDetailType,
  diaryListType,
  diarySummaryType,
  plantDetailInfoType,
} from 'src/utils/common/type/type'

export function useGetDirayList() {
  const { plantId } = useParams()
  const [period, setPeriod] = useState<{
    start: string | null
    end: string | null
  }>({
    start: null,
    end: null,
  })

  const [response, setResponse] = useState<CalendarEventType[] | null>()

  const {
    data: plantInfo,
    refetch,
    isSuccess,
    isPending,
    error,
  } = useQuery({
    queryKey: [
      'my-diary',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}my-plant/diaries/status?startDate=${period.start}&endDate=${period.end}&myPlantId=${plantId}`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  useEffect(() => {
    if (period.end && period.start) {
      refetch()
    }
  }, [period])

  useEffect(() => {
    if (plantInfo) {
      const dataObj = {
        data: plantInfo,
      }

      const transformedDiaries = castDiaryList(dataObj)?.data.map(diary => ({
        date: diary.createdDate,
        id: diary.diaryId,
      }))
      setResponse(transformedDiaries)
    }
  }, [plantInfo])
  return {
    setPeriod,
    response,
    isPending,
    refetch,
    period,
  }
}

export function useGetDirayDetail() {
  const [diaryId, setDiaryId] = useState<null | number>(null)

  const [response, setResponse] = useState<diaryDetailType | undefined | null>(undefined)

  const { data, refetch, isLoading, isFetching, isSuccess, isPending, error } = useQuery({
    queryKey: [
      'my-diary',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}my-plant/diaries/personal/${diaryId}`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  useEffect(() => {
    if (diaryId) {
      refetch()
    }
  }, [diaryId])

  useEffect(() => {
    if (isObject(data)) {
      const castObj = castDiary(data)
      setResponse(castObj)
    }
  }, [data])
  return {
    setDiaryId,
    setResponse,
    response,
    isLoading,
    refetch,
    isPending,
    isFetching,
  }
}
export default function useDeletePlant() {
  const { plantId } = useParams()
  const queryClient = useQueryClient()
  const [alert, setAlert] = useRecoilState(alertState)

  const navigate = useNavigate()

  const [frmtPlant, setFrmtPlant] = useState<null | plantDetailInfoType>(null)

  const {
    data: plantInfo,
    refetch,
    isSuccess,
    isPending: loadinPlant,
    error,
  } = useQuery({
    queryKey: [
      'my-plant',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}my-plant/plants/detail/${plantId}`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (isObject(plantInfo)) {
      setFrmtPlant(castPlantDetailInfoType(plantInfo))
    }
  }, [plantInfo])

  const {
    mutate,
    data,
    isSuccess: deletingSuccess,
    isError: deletingFailed,
    isPending: deletingPlant,
  } = useMutation({
    mutationFn: deleteDataTanstackWithToken,
    onSuccess: response => {
      queryClient.invalidateQueries({ queryKey: ['my-plant'] })
      toast.success('식물 삭제에 성공하였습니다.')

      navigate('/main', {
        state: {
          direction: 'na',
        },
      })
    },
    onError: response => {
      toast.error('식물 삭제에 실패하였습니다.')
    },
    onSettled: () => {
      setAlert({
        title: '',
        discription: '',
        showAlert: false,
        type: 'after',
      })
    },
  })

  const deletePlant = () => {
    const url = `${process.env.REACT_APP_API_DOMAIN}my-plant/plants/${plantId}`
    mutate({ url })
  }

  return {
    deletePlant,
    frmtPlant,
    loadinPlant,
    deletingPlant,
    deletingSuccess,
    deletingFailed,
  }
}

export const test = () => {}
