/*
    파일명 : LevelProgress.tsx
    설명 : 레벨 프로그레스바
    작성일 : 2024-06-06
*/

import styled from 'styled-components'
import { motion } from 'framer-motion'

function getWidth(step: number | string) {
  let result = '0'
  switch (step) {
    case 0:
      result = '0'
      break
    case 1:
      result = '119px'
      break
    case 2:
      result = '222.5px'
      break

    case 3:
      result = '338px'
      break
    default:
      break
  }
  return result
}

export default function LevelProgress({ marginTop = '0', step }: { marginTop?: string; step: number | string }) {
  const width = getWidth(step)
  return (
    <Container margintop={marginTop}>
      <Progress>
        <Dot />
        <Dot />
        <Dot />
        <Dot />
      </Progress>
      <ActiveProgress
        initial={{ width: '0px' }}
        animate={{ width: width }}
        transition={{ duration: 0.9 }} // Adjust the duration as needed
        width={width}
      >
        <ActiveDot />
        <ActiveDot />
      </ActiveProgress>
    </Container>
  )
}
const Container = styled.div<{ margintop: string }>`
  position: relative;
  text-align: center;
  margin-top: ${({ margintop }) => `${margintop}`};
`

const Progress = styled.div`
  align-items: center;
  display: flex;
  width: 326px;
  height: 16px;
  margin: 0 auto; /* 수정된 부분 */
  background: #f2f2f2;
  border-radius: 17.6261px;
  place-content: space-between;
  z-index: 0;

  div:first-child {
    margin-left: 4px;
  }

  div:last-child {
    margin-right: 4px;
  }
`

const ActiveProgress = styled(motion.div)<{ width: string }>`
  position: absolute;
  display: flex;
  bottom: 1px;
  left: 8px;
  height: 16px;
  margin: 0 auto; /* 수정된 부분 */
  place-content: space-between;
  width: ${({ width }) => `${width}`};
  align-items: center;

  background: #00c398;
  border-radius: 17.6261px;

  div:first-child {
    margin-left: 4px;
  }

  div:last-child {
    margin-right: 4px;
  }
`

const Dot = styled.div`
  width: 8px;
  height: 8px;
  z-index: 1;

  background: #ffffff;

  border-radius: 50%;
`

const ActiveDot = styled.div`
  z-index: 1;
  width: 8px;
  height: 8px;
  backgroud: #b2ebda;
  border-radius: 50%;
`
