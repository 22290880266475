import { useEffect, useState } from 'react'

import BookmarkBorderIcon from '../../../assets/images/icon/bookmarkDeselect.png'
import BookmarkIcon from '../../../assets/images/icon/bookmarkSelect.png'

import { useParams } from 'react-router-dom'
import {
  useAddItemInToCollection,
  useDeleteItemInToCollection,
} from 'src/components/specific/collection/hook/CollectionListCustomHook'
import styled from 'styled-components'

// import BookmarkIcon from '@mui/icons-material/Bookmark'
// import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'

export default function BookMarkButton({
  editMode,
  onClickBookMark,
  plantSpeciesId,
  clickedDefault,
  changedBookmark,
  onChangeBookmark,
  onSuccessDeleting,
}: {
  changedBookmark?: number | string | null
  editMode: boolean
  clickedDefault: boolean
  onClickBookMark?: (id: string | number, clicked: boolean) => void
  plantSpeciesId: number | string
  onChangeBookmark?: () => void
  onSuccessDeleting?: () => void
}) {
  const [clicked, setClicked] = useState(clickedDefault)
  const { deleteForm, isPending, isError: isErrorDelete, isSuccess: SuccessDeleting } = useDeleteItemInToCollection()
  const { createForm, isSuccess: isSuccessAddItem, isPending: isPendingAddItem, isError } = useAddItemInToCollection()
  const { collectionId } = useParams()

  useEffect(() => {
    if (changedBookmark === plantSpeciesId) {
      setClicked(prev => !prev)
    }
  }, [changedBookmark])

  useEffect(() => {
    onChangeBookmark?.()
  }, [clicked])

  useEffect(() => {
    if (isErrorDelete) {
      setClicked(prev => !prev)
    }
  }, [isErrorDelete])

  useEffect(() => {
    if (SuccessDeleting) {
      onSuccessDeleting?.()
    }
  }, [SuccessDeleting])
  useEffect(() => {
    if (isError) {
      setClicked(prev => !prev)
    }
  }, [isError])

  return (
    <>
      {clicked ? (
        <Bookmark
          alt="북마크"
          src={BookmarkIcon}
          onClick={() => {
            if (editMode || isPending || isPendingAddItem) return
            onClickBookMark?.(plantSpeciesId, false)
            setClicked(false)
            if (collectionId) {
              deleteForm(collectionId, [plantSpeciesId])
            }
          }}
          // htmlColor="#FFFFFF"
        />
      ) : (
        <Bookmark
          src={BookmarkBorderIcon}
          alt="북마크 안된 아이템"
          // htmlColor="#FFFFFF"
          onClick={() => {
            if (editMode || isPending || isPendingAddItem) return

            onClickBookMark?.(plantSpeciesId, true)
            setClicked(true)

            if (collectionId) {
              createForm(collectionId, [plantSpeciesId])
            }
          }}
        />
      )}
    </>
  )
}

const Bookmark = styled.img`
  width: 16px;

  height: 21px;
  margin-right: 12px;

  .&hover {
    cursor: pointer;
  }
`
