/*eslint-disable */

import { create } from 'zustand'

export const useLoignDrawer = create(set => ({
  isOpen: false,
  setIsOpenTrue: () => {
    set({ isOpen: true })
  },
  seIsOpenFalse: () => {
    set({ isOpen: false })
  },
}))
