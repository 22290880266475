import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { getDataTanstack, patchDataTanstack, postDataJson } from 'src/utils/common/api/http'
import { isArrayOfTypeTempTokenType } from 'src/utils/common/scripts/common'
import { validateNullCheck, validateRegExpCheck } from 'src/utils/common/scripts/validateCheck'
import { alertState, timerState } from 'src/utils/common/state/state'
import { validateCheckType } from 'src/utils/common/type/type'

export const useFindPwdApi = () => {
  const [email, setEmail] = useState('')
  const [telNum, setTelNum] = useState('')
  const [certNm, setCertNm] = useState('')
  const [token, setToken] = useState('')
  const [newPwd, setNewPwd] = useState('')

  const {
    mutate: mutateJson,
    data: postJsonData,
    isError,
  } = useMutation({
    mutationFn: postDataJson,
    onSuccess: () => {},
    onError: () => {},
  })

  const {
    data: certConfimMsg,
    isLoading,
    isSuccess,
    error,
    refetch,
  } = useQuery({
    queryKey: [
      'forget-pwd',
      {
        searchParam: certNm,
        url: `${process.env.REACT_APP_API_URL_FORGET}/password?phoneNumber=${telNum}&email=${email}&verificationCode=`,
      },
    ],
    queryFn: getDataTanstack,
    enabled: false,
  })

  const {
    data: newPwdResult,
    isSuccess: isSuccessToUpdatePwd,
    isError: isFailedToUpdatePwd,
    refetch: requestChangePwd,
  } = useQuery({
    queryKey: [
      'forget-pwd',
      {
        searchParam: token,
        url: `${process.env.REACT_APP_API_URL_FORGET}/password?newPassword=${newPwd}&changePasswordToken=`,
      },
    ],
    queryFn: patchDataTanstack,
    enabled: false,
  })

  useEffect(() => {
    changeNewPassword()
  }, [newPwd, token])

  // 인증번호
  const changeNewPassword = async () => {
    if (newPwd && token) {
      await requestChangePwd()
    }
  }

  // 인증번호
  const sendCertNm = async (newPhoneNumber: string, newEmail: string) => {
    setEmail(newEmail)
    setTelNum(newPhoneNumber)

    const formData = {
      email: newEmail,
      phoneNumber: newPhoneNumber,
    }
    const url = `${process.env.REACT_APP_API_URL_FORGET}/password`
    mutateJson({ formData, url })
  }

  useEffect(() => {
    checkCertNm()
  }, [certNm])

  const checkCertNm = async () => {
    if (certNm) {
      await refetch()
    }
  }

  return {
    newPwd,
    token,
    setNewPwd,
    setToken,
    isSuccessToUpdatePwd,
    newPwdResult,
    sendCertNm,
    isError,
    postJsonData,
    setCertNm,
    certConfimMsg,
    requestChangePwd,
  }
}

/*
  회원가입 필수값 유효 확인 
*/
export const useFindPwdCheckValidate = () => {
  const {
    newPwd,
    token,
    setNewPwd,
    setToken,
    isSuccessToUpdatePwd,
    newPwdResult,
    sendCertNm,
    isError,
    postJsonData,
    setCertNm,
    certConfimMsg,
    requestChangePwd,
  } = useFindPwdApi()

  const [formAlert, setFormAlert] = useState({
    email: '',

    cert: '',
    pwd: '',
  })
  const [isValidObj, setIsValidObj] = useState({
    email: undefined,

    cert: undefined,
    pwd: undefined,
  })

  const [certConfirm, setCertConfirm] = useState<boolean | null>(null)
  const [certSendBtn, setCertSendBtn] = useState('인증번호 전송')
  const [timer, setTimer] = useRecoilState(timerState)

  useEffect(() => {
    let alertDiscription = ''
    if (isArrayOfTypeTempTokenType(certConfimMsg)) {
      if (certConfimMsg?.message.includes('인증번호 확인 완료')) {
        // 인증 확인 통과시에
        setCertConfirm(true)
        setTimer({
          start: false,
        })

        localStorage.setItem('tempToken', certConfimMsg.data.token)

        updateIsValid('cert', true)
      }
    } else if (certConfimMsg?.message === '인증에 실패했습니다.') {
      setCertConfirm(false)
      updateIsValid('cert', false)
      alertDiscription = '인증번호를 다시 확인해주세요'
    }

    updateFormAlert('cert', alertDiscription)
  }, [certConfimMsg])

  useEffect(() => {
    if (postJsonData?.message === 'Not Found') {
      setFalse('cert', '해당하는 계정이 없습니다.')
    }
  }, [postJsonData])

  const setTrue = (key: string) => {
    updateFormAlert(key, '')
    updateIsValid(key, true)
  }

  const setInit = (key: string) => {
    updateFormAlert(key, '')
    updateIsValid(key, undefined)
  }
  const setFalse = (key: string, text: string) => {
    updateFormAlert(key, text)
    updateIsValid(key, false)
  }

  const updateFormAlert = (key: string, val: string | boolean | undefined) => {
    setFormAlert(prev => {
      let newVal = { ...prev }
      newVal = {
        ...prev,
        [key]: val,
      }
      return newVal
    })
  }

  const updateIsValid = (key: string, val: string | boolean | undefined) => {
    setIsValidObj(prev => {
      let newVal = { ...prev }
      newVal = {
        ...prev,
        [key]: val,
      }
      return newVal
    })
  }
  // 필수 값 null 체크
  function nullCheck(checkObj: {
    targetObj: { [key: string]: string | number }
    nameArr: string[]

    discription: string
  }) {
    const validCheck: validateCheckType = validateNullCheck(checkObj.targetObj, checkObj.nameArr)
    const isValid = checkValidation(validCheck, checkObj.discription)
    return isValid
  }

  function checkValidation(validCheck: validateCheckType, discription: string) {
    if (!validCheck.isValid) {
      if (validCheck.unValidKey.length) {
        const name = validCheck.unValidName
        setFalse(name, discription)
      }
    }
    return validCheck.isValid
  }

  function regExpCheck(checkObj: {
    targetObj: { [key: string]: string }
    nameArr: string[]
    regExpArr: string[]
    discription: string
  }) {
    const validCheck: validateCheckType = validateRegExpCheck(checkObj.targetObj, checkObj.nameArr, checkObj.regExpArr)
    const isValid = checkValidation(validCheck, checkObj.discription)
    return isValid
  }

  // 이메일 중복 체크
  const checkEmail = (emailVal: string) => {
    let isValid = nullCheck({
      targetObj: {
        email: emailVal,
      },
      nameArr: ['email'],
      discription: '이메일을 입력해주세요',
    })
    if (!isValid) return false
    isValid = regExpCheck({
      targetObj: {
        tel: emailVal,
      },
      nameArr: ['email'],
      regExpArr: ['[a-z0-9]+@[a-z]+.[a-z]{2,3}'],
      discription: '이메일을 정확히 입력해주세요',
    })
    if (isValid) {
      setTrue('email')
    }

    return isValid
  }

  const checkTelNum = (telVal: string, email: string) => {
    let isValid = nullCheck({
      targetObj: {
        tel: telVal,
      },

      nameArr: ['cert'],

      discription: '전화번호를 입력해주세요',
    })

    if (!isValid) return false

    isValid = regExpCheck({
      targetObj: {
        tel: telVal,
      },
      nameArr: ['cert'],
      regExpArr: ['^[0-9]{10,11}$'],

      discription: '전화번호를 정확히 입력해주세요',
    })
    if (isValid) {
      setCertSendBtn('인증번호 재전송')
      setCertConfirm(null)
      setTimer({
        start: true,
      })
      setInit('cert')
      sendCertNm(telVal, email)
    }

    return isValid
  }
  const checkCertNum = (newCertNum: string) => {
    if (certConfirm === true) return
    const certNum = postJsonData?.data
    let alertDiscription = ''
    console.log('인증' + certNum)

    // 유효시간안에 인증이 확인되었을 시에
    if (timer.start === true && certNum && newCertNum) {
      // 유효시간안에 인증이 안되었을 때, 혹은 인증번호 전송 안했을 때

      if (certNum !== newCertNum) {
        setCertConfirm(false)
        updateIsValid('cert', false)
        alertDiscription = '인증번호를 다시 확인해주세요'
        updateFormAlert('cert', alertDiscription)
      } else {
        setCertNm(newCertNum)
      }
    } else {
      setCertConfirm(false)
      updateIsValid('cert', false)
      alertDiscription = '인증에 실패했습니다. 다시 시도해주세요'
    }

    updateFormAlert('cert', alertDiscription)
  }

  const checkPwd = (pwdVal: string, pwdCheckVal: string) => {
    const isValid = pwdRegExpCheck(pwdVal)
    return isValid
  }

  const pwdRegExpCheck = (pwdVal: string) => {
    const isValid = regExpCheck({
      targetObj: {
        tel: pwdVal,
      },
      nameArr: ['pwd'],
      regExpArr: ['^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[.!@#$%])[A-Za-z\\d.!@#$%]{9,20}$'],
      discription: '형식이 올바르지 않습니다.',
    })
    if (isValid) {
      setInit('pwd')
    }

    return isValid
  }

  const checkPwdCheck = (pwdVal: string, pwdCheckVal: string, init?: boolean) => {
    if (!pwdRegExpCheck(pwdVal)) return

    if (pwdVal !== pwdCheckVal) {
      updateFormAlert('pwd', '비밀번호를 다시 확인해주세요.')
      updateIsValid('pwd', false)
    } else if (pwdVal === pwdCheckVal) {
      updateFormAlert('pwd', '')
      updateIsValid('pwd', true)
    }
  }

  return {
    newPwd,
    token,
    setNewPwd,
    setToken,
    isSuccessToUpdatePwd,
    newPwdResult,
    checkPwd,
    checkPwdCheck,
    formAlert,
    isValidObj,
    certSendBtn,
    timer,
    certConfirm,
    checkEmail,
    checkTelNum,
    postJsonData,
    checkCertNum,
    requestChangePwd,
  }
}
