import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'

export default function EndLine({
  hidden = false,
  mt = '17px',
  onShowEndPage,
}: {
  hidden?: boolean
  mt?: string
  onShowEndPage: () => void
}) {
  const { ref, inView } = useInView({
    threshold: 1, // 화면의 50%가 보일 때 감지
  })

  useEffect(() => {
    if (inView) {
      console.log(inView, '무한 스크롤 요청 🎃')
      onShowEndPage()
    }
  }, [inView])
  return (
    <Line ref={ref} $mt={mt}>
      {hidden ? ' ' : 'COMPPI'}
    </Line>
  )
}

const Line = styled.div<{ $mt: string }>`
  width: 100%;
  color: #dadada;

  margin-top: ${({ $mt }) => $mt};
  margin-bottom: ${({ $mt }) => $mt};
  font-family: Pretendard-Regular;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  line-height: 18px;
  display: flex;
  place-content: center;
`
