import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import MuiMain from 'src/components/common/mui/MuiMain'
import Title from 'src/components/common/title/Title'
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp'
import DotButton from 'src/components/common/button/DotButton'
import DotButtonTwo from 'src/components/common/button/DotButtonTwo'
import Content from 'src/components/common/layout/Content'
import LineOne from 'src/components/common/line/LineOne'
import GreenChatItem from 'src/components/specific/green/detail/chat/GreenChatItem'
import DrawerLayout from 'src/components/common/layout/DrawerLayout'
/*
    파일명 : GreenChat.tsx
    설명 : 그린 마켓 > 상세 > 채팅
    작성일 : 2024-06-19
*/
export default function GreenChat() {
  return (
    <ContainerLayout styleId="div_container_02">
      <MuiMain>
        <Title
          backPath="../"
          title="멋진 농부"
          level="1"
          comp={
            <button type="button" style={{ justifySelf: 'end' }}>
              <DotButtonTwo bc="#1C1C1C" />
            </button>
          }
        />
        <Content
          pt={0}
          p={0}
          pb={0}
          component={
            <>
              <GreenChatItem />
              <></>
            </>
          }
        />
      </MuiMain>
    </ContainerLayout>
  )
}
