import React from 'react'
import { useNavigate } from 'react-router-dom'
import MarkedItem from 'src/components/common/content/MarkedItem'
import MarkedItemInfo from 'src/components/common/content/MarkedItemInfo'
import RelatedPostLayout from 'src/components/common/layout/RelatedPostLayout'
import LineOne from 'src/components/common/line/LineOne'
import { COLLECTION_RELATED_POSTS } from 'src/utils/common/constants/constants'
import { generateRandomKey } from 'src/utils/common/scripts/common'

export default function GreenPostList() {
  const navigate = useNavigate()
  return (
    <>
      {COLLECTION_RELATED_POSTS.map((item, index) => (
        <React.Fragment key={generateRandomKey()}>
          <RelatedPostLayout
            onClick={() => {
              navigate(`detail/${item.postId}`)
            }}
            mb={index !== 0 ? '23px' : ''}
          >
            <MarkedItem onClick={() => {}} item={item.imgInfo} />
            <MarkedItemInfo
              itemType="GREEN_MARKET"
              showAll={true}
              item={{
                title: item.title || '',
                loc: item.loc || '',
                regDttm: item.regDttm || '',
                stts: item.stts || '',
                price: item.price || '',
                goodCnt: item.goodCnt || 0,
                commentsCnt: item.commentsCnt || 0,
                clicked: item.clicked || false,
              }}
            />
          </RelatedPostLayout>
          {index !== COLLECTION_RELATED_POSTS.length - 1 && <LineOne />}
        </React.Fragment>
      ))}
    </>
  )
}
