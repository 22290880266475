import styled from 'styled-components'
import forward from '../../../assets/images/post/forward.png'

export default function ForwardIcon({ title, onClick }: { onClick: () => void; title?: string }) {
  return (
    <Wrap>
      {title && <span>{title}</span>}
      <Img src={forward} alt="더보기" />
    </Wrap>
  )
}

const Wrap = styled.div`
  display: flex;
  align-items: center;
  span {
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.05em;

    color: #1c1c1c;
    margin-right: 10px;
  }
  :hover {
    cursor: pointer;
  }
`

const Img = styled.img`
  width: 9px;
  height: 16px;
`
