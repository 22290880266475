import styled from 'styled-components'

export default function NoDataContainer2({
  mt = '0px',
  title,
  discription,
  ac = 'unset',
  mb = '0px',
}: {
  mt?: string
  mb?: string
  ac?: string
  title: string
  discription: string
}) {
  return (
    <Wrap $mt={mt} $ac={ac}>
      <InnerWrap>
        <Title>{title}</Title>
      </InnerWrap>
      <InnerWrap>
        <Disc $mb={mb}>{discription}</Disc>
      </InnerWrap>
    </Wrap>
  )
}

const Disc = styled.h3<{ $mb: string }>`
  margin-bottom: ${({ $mb }) => $mb};
  margin-top: 2px;
  /* 내 식물을 등록하고 성장을 기록해 보세요. */

  /* Caption */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  text-align: center;
  letter-spacing: -0.02em;

  /* Gray 04 */
  color: #a2a3a7;
  white-space: pre-wrap;
`
const Title = styled.h2`
  /* 아직 등록된 식물이 없어요! */

  /* Body 01-SB */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: center;
  letter-spacing: -0.02em;

  /* Black */
  color: #242424;
`
const InnerWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Wrap = styled.div<{ $ac: string; $mt: string }>`
  width: 100%;
  height: 100%;

  align-content: ${({ $ac }) => $ac};
  margin-top: ${({ $mt }) => $mt};
`
