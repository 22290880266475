import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { getDataTanstack, postDataJson } from 'src/utils/common/api/http'
import { alertState, timerState } from 'src/utils/common/state/state'
import { validateCheckType } from '../../../../../utils/common/type/type'
import { validateNullCheck, validateRegExpCheck } from 'src/utils/common/scripts/validateCheck'

export const useCreateUserApi = () => {
  const [email, setEmail] = useState<string>('')
  const [tel, setTel] = useState<string>('')
  const [certNum, setCertNum] = useState<string>('')
  const [submitBtnLabel, setSubmitBtnLabel] = useState('가입하기')
  const [ExcuteCreateUser, setExcuteCreateUser] = useState(false)

  const [emailCheck, setEmailCheck] = useState<boolean | undefined>(undefined)

  const { data, isLoading, isSuccess, isError, error, refetch } = useQuery({
    queryKey: ['join', { searchParam: email, url: `${process.env.REACT_APP_API_URL_JOIN}/is_duplicate?email=` }],
    queryFn: getDataTanstack,
    enabled: false,
  })

  const { data: certConfimMsg, refetch: reConfirmCertCode } = useQuery({
    queryKey: [
      'join',
      {
        searchParam: certNum,
        url: `${process.env.REACT_APP_API_URL_JOIN}/verification_code?email=${email}&phoneNumber=${tel}&verificationCode=`,
      },
    ],
    queryFn: getDataTanstack,
    enabled: false,
  })

  const {
    mutate: mutateJson,

    data: postJsonData,
  } = useMutation({
    mutationFn: postDataJson,
    onSuccess: () => {
      // navigate("/events");
      console.log(postJsonData)
      if (ExcuteCreateUser) {
        setSubmitBtnLabel('회원 가입 성공')
      }
    },
    onError: () => {
      if (ExcuteCreateUser) {
        setSubmitBtnLabel('회원 가입 실패')
      }
    },
  })

  const { mutate: mutateString, data: postStringData } = useMutation({
    mutationFn: postDataJson,
    onSuccess: () => {
      console.log(postStringData)
      console.log('회원가입성공!!!!!!!!!!!!')
    },
  })

  function createUser(password: string) {
    const formData = {
      email,
      password,
      phoneNumber: tel,
    }
    setExcuteCreateUser(true)
    const url = `${process.env.REACT_APP_API_URL_JOIN}`
    mutateJson({ formData, url })
  }

  const checkCertNm = async () => {
    if (certNum && !ExcuteCreateUser) {
      await reConfirmCertCode()
    }
  }

  // 이메일 중복
  const checkEmailIsDuplicated = async () => {
    if (email && !ExcuteCreateUser) {
      await refetch() // 수동으로 쿼리 실행
    }
  }

  // 이메일이 변경될 때마다 호출
  useEffect(() => {
    checkEmailIsDuplicated()
  }, [email])

  // 인증번호 변경될 때마다 호출
  useEffect(() => {
    checkCertNm()
  }, [certNum])

  useEffect(() => {
    if (data?.message === '중복되지 않은 이메일 주소입니다.') {
      setEmailCheck(true)
    } else if (data?.message === '이미 존재하는 이메일 주소입니다.') {
      setEmailCheck(false)
    }

    // true 로직 넣어야 함
  }, [data])

  // 인증번호
  const sendCertNm = async (newTel: string) => {
    if (newTel && !ExcuteCreateUser) {
      setTel(newTel)
      const formData = {
        email,
        phoneNumber: newTel,
      }
      const url = `${process.env.REACT_APP_API_URL_JOIN}/phone_number_verification`
      const result = await mutateJson({ formData, url })
    }
  }

  return {
    setCertNum,
    createUser,
    postJsonData,
    sendCertNm,
    setEmail,
    emailCheck,
    emailIsDuplicated: data,
    certConfimMsg,
    isSuccess,
    isError,
    isLoading,
    submitBtnLabel,
  }
}

/*
  회원가입 필수값 유효 확인 
*/
export const useValidCheckCreateUser = () => {
  const {
    submitBtnLabel,
    certConfimMsg,
    setCertNum,
    createUser,
    postJsonData,
    sendCertNm,
    setEmail,
    emailCheck,
    isSuccess,
  } = useCreateUserApi()

  const [formAlert, setFormAlert] = useState({
    email: '',
    pwd: '',
    tel: '',
    cert: '',
  })
  const [isValidObj, setIsValidObj] = useState({
    email: undefined,
    pwd: undefined,
    tel: undefined,
    cert: undefined,
  })

  const [isDuplicated, setIsDuplicated] = useState(false)
  const [certConfirm, setCertConfirm] = useState<boolean | null>(null)
  const [certSendBtn, setCertSendBtn] = useState('인증번호 전송')
  const [timer, setTimer] = useRecoilState(timerState)
  const [alert, setAlert] = useRecoilState(alertState)

  useEffect(() => {
    let alertDiscription = ''
    if (certConfimMsg?.message === '인증이 완료되었습니다.') {
      // 인증 확인 통과시에
      setCertConfirm(true)
      setTimer({
        start: false,
      })
      updateIsValid('cert', true)
    } else if (certConfimMsg?.message === '인증에 실패했습니다.') {
      setCertConfirm(false)
      updateIsValid('cert', false)
      alertDiscription = '인증번호를 다시 확인해주세요'
    }

    updateFormAlert('cert', alertDiscription)
  }, [certConfimMsg])

  useEffect(() => {
    if (emailCheck !== undefined && isSuccess) {
      duplicateEmailCheck(emailCheck)
    }
  }, [emailCheck, isSuccess])

  useEffect(() => {
    let alertDiscription = ''
    if (postJsonData?.message === '이미 가입된 전화번호 입니다.') {
      setCertConfirm(false)
      updateIsValid('cert', false)
      alertDiscription = '이미 가입된 전화번호 입니다'
    }

    updateFormAlert('cert', alertDiscription)
  }, [postJsonData])

  const setInit = (key: string) => {
    updateFormAlert(key, '')
    updateIsValid(key, undefined)
  }

  const setValidateAlertFalse = (key: string, discription: string) => {
    updateFormAlert(key, discription)
    updateIsValid(key, false)
  }

  const checkAllValidate = () => {
    if (isValidObj.email !== true) {
      // setAlert({ title: '이메일', discription: '이메일 중복 확인을 해주세요', showAlert: true })

      setValidateAlertFalse('email', '이메일 중복 확인을 해주세요')

      return false
    }
    if (isValidObj.pwd !== true) {
      // setAlert({ title: '비밀번호', discription: '비밀번호를 다시 한번 확인 해주세요', showAlert: true })

      setValidateAlertFalse('pwd', '비밀번호를 다시 한번 확인 해주세요')

      return false
    }
    if (!certConfirm) {
      setValidateAlertFalse('cert', '인증번호를 확인해주세요')
      // setAlert({ title: '인증번호', discription: '인증번호를 확인해주세요', showAlert: true })
      return false
    }
    return true
  }

  const duplicateEmailCheck = (emailIsDuplicated: undefined | boolean) => {
    let result = false
    if (emailIsDuplicated === false) {
      setIsDuplicated(true)
      updateFormAlert('email', '이미 가입되어 있는 이메일입니다.')
      updateIsValid('email', false)
      result = true
    } else if (emailIsDuplicated === true) {
      updateFormAlert('email', '사용할 수 있는 이메일입니다.')
      updateIsValid('email', true)
      result = false
    }
    return result
  }

  const updateFormAlert = (key: string, val: string | boolean | undefined) => {
    setFormAlert(prev => {
      let newVal = { ...prev }
      newVal = {
        ...prev,
        [key]: val,
      }
      return newVal
    })
  }
  const updateIsValid = (key: string, val: string | boolean | undefined) => {
    setIsValidObj(prev => {
      let newVal = { ...prev }
      newVal = {
        ...prev,
        [key]: val,
      }
      return newVal
    })
  }
  function checkValidation(validCheck: validateCheckType, discription: string) {
    if (!validCheck.isValid) {
      if (validCheck.unValidKey.length) {
        const name = validCheck.unValidName
        updateFormAlert(name, discription)
        updateIsValid(name, false)
      }
    }
    return validCheck.isValid
  }

  function regExpCheck(checkObj: {
    targetObj: { [key: string]: string }
    nameArr: string[]
    regExpArr: string[]
    discription: string
  }) {
    const validCheck: validateCheckType = validateRegExpCheck(checkObj.targetObj, checkObj.nameArr, checkObj.regExpArr)
    const isValid = checkValidation(validCheck, checkObj.discription)
    return isValid
  }

  // 필수 값 null 체크
  function nullCheck(checkObj: {
    targetObj: { [key: string]: string | number }
    nameArr: string[]

    discription: string
  }) {
    const validCheck: validateCheckType = validateNullCheck(checkObj.targetObj, checkObj.nameArr)
    const isValid = checkValidation(validCheck, checkObj.discription)
    return isValid
  }

  const checkPwd = (pwdVal: string, pwdCheckVal: string) => {
    const isValid = pwdRegExpCheck(pwdVal)
    return isValid
  }

  const pwdRegExpCheck = (pwdVal: string) => {
    const isValid = regExpCheck({
      targetObj: {
        tel: pwdVal,
      },
      nameArr: ['pwd'],
      regExpArr: ['^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[.!@#$%])[A-Za-z\\d.!@#$%]{9,20}$'],
      discription: '형식이 올바르지 않습니다.',
    })
    if (isValid) {
      setInit('pwd')
    }

    return isValid
  }

  const checkPwdCheck = (pwdVal: string, pwdCheckVal: string, init?: boolean) => {
    if (!pwdRegExpCheck(pwdVal)) return

    if (pwdVal !== pwdCheckVal) {
      updateFormAlert('pwd', '비밀번호를 다시 확인해주세요.')
      updateIsValid('pwd', false)
    } else if (pwdVal === pwdCheckVal) {
      updateFormAlert('pwd', '')
      updateIsValid('pwd', true)
    }
  }

  // 이메일 중복 체크
  const checkEmail = (emailVal: string) => {
    let isValid = nullCheck({
      targetObj: {
        email: emailVal,
      },
      nameArr: ['email'],
      discription: '이메일을 입력해주세요',
    })
    if (!isValid) return

    isValid = regExpCheck({
      targetObj: {
        tel: emailVal,
      },
      nameArr: ['email'],
      regExpArr: ['[a-z0-9]+@[a-z]+.[a-z]{2,3}'],
      discription: '이메일을 정확히 입력해주세요',
    })

    if (isValid) {
      setEmail(emailVal)
    }
  }

  const checkTelNum = (telVal: string) => {
    if (isValidObj.email !== true) {
      setValidateAlertFalse('email', '이메일 중복 확인을 해주세요')
      return
    }

    let isValid = nullCheck({
      targetObj: {
        tel: telVal,
      },

      nameArr: ['cert'],

      discription: '전화번호를 입력해주세요',
    })

    if (!isValid) return

    isValid = regExpCheck({
      targetObj: {
        tel: telVal,
      },
      nameArr: ['cert'],
      regExpArr: ['^[0-9]{10,11}$'],

      discription: '전화번호를 정확히 입력해주세요',
    })
    if (isValid) {
      setCertSendBtn('인증번호 재전송')
      setCertConfirm(null)
      setTimer({
        start: true,
      })
      setInit('cert')
      sendCertNm(telVal)
    }
  }

  const checkCertNum = (newCertNum: string) => {
    if (certConfirm === true) return
    const certNum = postJsonData?.data
    let alertDiscription = ''
    console.log('인증' + certNum)

    // 유효시간안에 인증이 확인되었을 시에
    if (timer.start === true && certNum && newCertNum) {
      // 유효시간안에 인증이 안되었을 때, 혹은 인증번호 전송 안했을 때

      if (certNum !== newCertNum) {
        setCertConfirm(false)
        updateIsValid('cert', false)
        alertDiscription = '인증번호를 다시 확인해주세요'
        updateFormAlert('cert', alertDiscription)
      } else {
        setCertNum(newCertNum)
      }
    } else {
      setCertConfirm(false)
      updateIsValid('cert', false)
      alertDiscription = '인증에 실패했습니다. 다시 시도해주세요'
    }

    updateFormAlert('cert', alertDiscription)
  }

  return {
    formAlert,
    isValidObj,
    createUser,
    checkEmail,
    checkPwd,
    checkPwdCheck,
    certSendBtn,
    certConfirm,
    timer,
    checkCertNum,
    checkTelNum,
    duplicateEmailCheck,
    checkAllValidate,
    submitBtnLabel,
  }
}

export default function test() {}
