import { useState, useEffect } from 'react'
import MonthlyCalendar from 'src/components/common/calendar/MonthlyCalendar'
import styled from 'styled-components'
import PrevIcon from 'src/components/common/icon/PrevIcon'
import CalendarPrevBtn from '../../../../../assets/images/icon/CalendarPrevBtn.png'
import CalendarNextBtn from '../../../../../assets/images/icon/CalendarNextBtn.png' // 변경된 이미지 경로
import CalendarNextBtn2 from '../../../../../assets/images/icon/CalendarNextBtn2.png' // 변경된 이미지 경로
import { CustomButton } from 'src/components/common/button/CustomButton'
import DualCalendar from 'src/components/common/calendar/DualCalendar'

type MonthlyCalendarType = {
  selectedDate: Date
  onClickDate: (date: Date) => void
  nowDate: [Date | null, Date | null]
}

export default function DualCalendarContent({ nowDate, selectedDate, onClickDate }: MonthlyCalendarType) {
  const [startOfMonth, setStartOfMonth] = useState<Date>(
    new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1),
  )
  const [prevButtonSrc, setPrevButtonSrc] = useState<string>(CalendarNextBtn) // 초기값을 CalendarNextBtn으로 설정
  const [nextButtonSrc, setNextButtonSrc] = useState<string>(CalendarNextBtn2) // 초기값을 CalendarNextBtn으로 설정
  const year = startOfMonth.getFullYear()
  const month = startOfMonth.getMonth() + 1
  const today = new Date()

  // 이전 달 표시
  const handlePrevMonth = () => {
    setStartOfMonth(new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() - 1, 1))
  }

  // 다음 달 표시
  const handleNextMonth = () => {
    setStartOfMonth(new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() + 1, 1))
  }

  const handleSelectedDate = (date: Date) => {
    onClickDate(date)
  }
  useEffect(() => {
    // 오늘의 날짜를 포함하거나 초과한 주인 경우
    const startOfMonthDate = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth(), 1)
    const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

    // startOfMonthDate에 마지막 날짜를 구하는 방식
    const endOfMonthDate = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() + 1, 0)
    const previousMonth = todayDate.getMonth() === 0 ? 11 : todayDate.getMonth() - 1

    //   띄워진 달 > 현재 달 저장
    if (
      startOfMonthDate.getTime() > todayDate.getTime() ||
      startOfMonthDate.getMonth() === todayDate.getMonth() ||
      startOfMonthDate.getMonth() === previousMonth
    ) {
      setNextButtonSrc(CalendarNextBtn2) // CalendarNextBtn2으로 변경
    } else {
      setNextButtonSrc(CalendarNextBtn) // 기존 이미지 경로로 변경
    }

    if (startOfMonthDate.getTime() >= todayDate.getTime()) {
      setPrevButtonSrc(CalendarNextBtn2) // CalendarNextBtn2으로 변경
    } else {
      setPrevButtonSrc(CalendarNextBtn) // 기존 이미지 경로로 변경
    }
  }, [startOfMonth, today])

  return (
    <CalendarWrap>
      <div className="div_head">
        <h2 className="title">
          {year}년 {month}월
        </h2>
        <div className="div_button">
          <PrevIcon onClick={handlePrevMonth}>
            <img
              style={{ verticalAlign: 'super', marginRight: '43px', transform: 'rotate(-180deg)' }}
              src={prevButtonSrc}
              alt="이전달"
            />
          </PrevIcon>
          <PrevIcon onClick={handleNextMonth}>
            <img style={{ verticalAlign: 'super' }} src={nextButtonSrc} alt="다음달" /> {/* 변경 */}
          </PrevIcon>
        </div>
      </div>
      <DualCalendar
        disabledDate={today}
        onClickDate={handleSelectedDate}
        selectedDate={nowDate}
        startOfMonth={startOfMonth}
      />
    </CalendarWrap>
  )
}

const CalendarWrap = styled.div`
  .div_head {
    justify-content: space-between;
    display: flex;
    margin-bottom: 15px;
  }
  .title {
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 18px;
    /* identical to box height, or 106% */
    letter-spacing: -0.05em;

    color: #1c1c1c;
  }
  .div_button {
    display: flex;
  }
`
