import { useState } from 'react'
import MainFindPwdForm from './MainFindPwdForm'
import MainFindPwdResult from './MainFindPwdResult'

export default function PWDInquery() {
  const [chagePage, setChangePage] = useState(false)

  if (!chagePage) {
    return (
      <MainFindPwdForm
        onChangePage={() => {
          setChangePage(true)
        }}
      />
    )
  } else {
    return <MainFindPwdResult />
  }
}
