import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MuiMain from 'src/components/common/mui/MuiMain'
import Title from 'src/components/common/title/Title'
import { useCollectionItemInfo } from 'src/components/specific/collection/hook/CollectionListCustomHook'
import CollectionItemEditList from 'src/components/specific/collection/list/CollectionItemEditList'
import { SAMPLE_COLLECTION } from 'src/utils/common/constants/constants'
import { selectCol } from 'src/utils/common/state/state'

/*
    파일명 : CollectionItemEdit.tsx
    설명 : 싹싹도감 > 내 도감 > 도감 상세 > 도감 편집
    작성일 : 2024-06-17
*/
export default function CollectionItemEdit() {
  const navigate = useNavigate()

  const { itemList, refetch, requestItems, collectionInfo } = useCollectionItemInfo()

  return (
    <ContainerLayout styleId="div_container_03">
      <MuiMain>
        <Title
          onClick={() => {
            navigate('../', { state: { direction: 'back' } })
          }}
          close={true}
          title="식물 선택"
        />
        <Content
          p={0}
          pt={0}
          pb={0}
          component={
            <>
              <CollectionItemEditList
                totalCount={collectionInfo?.data.totalElement || 0}
                itemList={itemList}
                onSuccess={() => {
                  refetch()
                }}
                onScrollEnd={() => {
                  requestItems()
                }}
              />
            </>
          }
        />
      </MuiMain>
    </ContainerLayout>
  )
}
