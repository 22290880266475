import styles from '../../styles/AlertText.module.css'

function AlertText({ text, visible = false }: { text: string; visible: boolean | null }) {
  // visible 값이 null이거나 true일 때 JSX 반환
  if (visible === true && text.length > 0) {
    return (
      <div className={styles.div_alert_text}>
        <span>{text}</span>
      </div>
    )
  } else {
    // visible 값이 false일 때는 null 반환
    return <></>
  }
}

export default AlertText
