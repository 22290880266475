import { PostSmrySampleType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import LoadingContainer from '../container/LoadingContainer'
import { displayedAt } from 'src/utils/common/scripts/common'
import HashTagListProfile from './HashTagListProfile'
import { useNavigate } from 'react-router-dom'

type ItemInfoType = {
  item: PostSmrySampleType | null
  postType?: string
}

export default function ItemInfo({ item, postType = 'PLAZA' }: ItemInfoType) {
  const navigate = useNavigate()

  if (!item) {
    return (
      <Wrap>
        <LoadingContainer width="80px" height="18px" />
        <LoadingContainer margin="12px 0px 0px 0px" height="18px" width="56.4%" />
        <LoadingContainer margin="6px 0px 0px 0px" height="18px" width="100%" />
      </Wrap>
    )
  } else {
    const params = item.id ? `articleId${item.id}` : item.title
    const time = displayedAt(item.regDttm)
    return (
      <Wrap
        onClick={() => {
          if (postType === 'PROFILE') {
            navigate(`/plaza/common/${params}`, {
              state: {
                direction: 'na',
                searchParam: item.title,
              },
            })
          }
        }}
      >
        <h2>{item.title}</h2>
        <Loc>
          <span>
            {item.loc} . {time}
          </span>

          {/* <span>{time}</span> */}
        </Loc>
        <Content>
          <p>{item.content ? (item.content.length > 40 ? `${item.content.slice(0, 40)}...` : item.content) : ''}</p>
        </Content>
        {item.hashtagList && item.hashtagList.length > 0 ? <HashTagListProfile hashTagArr={item.hashtagList} /> : <></>}
      </Wrap>
    )
  }
}

const Wrap = styled.div`
  width: 100%;

  h2 {
    margin-top: 8px;
    /* 극락조 잎 부패 */

    /* Body 01-SB */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }
`

const Loc = styled.div`
  margin-top: 8px;
  /* 락조 키우기 */

  /* Caption */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  letter-spacing: -0.02em;

  /* Gray 04 */
  color: #a2a3a7;
`

const Content = styled.div`
  margin-top: 6px;
  p {
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    /* or 153% */
    letter-spacing: -0.02em;

    color: #1c1c1c;
  }
`
