import GrenPlantInfo from 'src/components/common/content/GreenPlantInfo'
import MarkedItem from 'src/components/common/content/MarkedItem'
import DetailViewLabelLayout from 'src/components/common/layout/DetailViewLabelLayout'
import RelatedPostLayout from 'src/components/common/layout/RelatedPostLayout'
import LineOne from 'src/components/common/line/LineOne'

export default function GreenPlantInfo() {
  return (
    <>
      <DetailViewLabelLayout h2MarginBottom="12px" padding="0px 0px 0px 0px" marginBottom="23px" title="식물 정보">
        <>
          <RelatedPostLayout height="90px" onClick={() => {}}>
            <>
              <MarkedItem
                onClick={() => {}}
                item={{
                  name: '이미지',
                  id: '1',
                  img_url:
                    'https://m.worldseedmall.com/web/upload/NNEditor/20220705/mobile/cf0eee0d0bfba8f736e0cc6b04510c2c_1656994911.jpg',
                }}
              />
              <GrenPlantInfo
                item={{
                  title: '다육식물',
                  eng: 'Succulent plant',
                  level: '새싹',
                  id: 'random',
                }}
              />
            </>
          </RelatedPostLayout>
        </>
      </DetailViewLabelLayout>
      <LineOne />
    </>
  )
}
