import React from 'react'
import styled from 'styled-components'

export default function IconLayout({ children }: { children: React.ReactNode }) {
  return <Wrap>{children}</Wrap>
}

const Wrap = styled.div`
  display: flex;
`
