import { createSlice } from '@reduxjs/toolkit'
import { tokenApi } from 'src/utils/common/api/useAxios'
import { castRecPlantListType } from 'src/utils/common/scripts/checkType'
import { RcntVwdItem } from 'src/utils/common/type/type'
import { AppDispatch } from '..'

const initialPlantRecommendedState: { list: RcntVwdItem[]; isPending: boolean; isError: boolean } = {
  list: [],
  isPending: true,
  isError: false,
}

const plantRecommendedSlice = createSlice({
  name: 'plantRecommended',
  initialState: initialPlantRecommendedState,
  reducers: {
    // state : 최신 상태
    // action : 컴포넌트가 보낸 액션

    decrement(state, action) {
      if (state.list !== null) {
        state.list = state.list.filter((item: RcntVwdItem) => item.plantSpeciesId !== action.payload)
      }
    },

    getData(state, action) {
      state.list = action.payload
      state.isPending = false
      state.isError = false
    },
    setError(state, action) {
      state.isError = action.payload
    },
  },
})

export const fetchPlantRecommendedData = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await tokenApi.get(
        `${process.env.REACT_APP_API_DOMAIN}encyclo-service/stat/recent-plant-detail?페이지=1&사이즈=6`,
      )

      if (res && res.data) {
        const castObj = castRecPlantListType(res.data)

        if (castObj) {
          const newArr: RcntVwdItem[] = []
          for (let i = 0; i < castObj.data.results.length; i++) {
            const elem = castObj.data.results[i]
            newArr.push({
              commonName: elem.plantBriefInfo.commonName,
              imageUrl: elem.plantBriefInfo.imageUrl,
              scientificName: elem.plantBriefInfo.scientificName,
              plantSpeciesId: elem.plantBriefInfo.plantSpeciesId,
            })
          }

          dispatch(plantRecommendedActions.getData(newArr))
        }
      }
    } catch (error) {
      dispatch(plantRecommendedActions.setError(true))
    }
  }
}

// slice에서 설정한 리듀서에 접근 가능
export default plantRecommendedSlice.reducer
export const plantRecommendedActions = plantRecommendedSlice.actions
