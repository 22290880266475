import styled from 'styled-components'
import LoadingContainer from '../container/LoadingContainer'
import { Skeleton } from '@mui/material'

type CollectionImgInfo = {
  item: { name: string; cnt: string | number } | null
}

export default function CollectionImgInfo({ item }: CollectionImgInfo) {
  if (item) {
    return (
      <>
        <ImgInfo>
          <p>{item.name}</p>
          <span>{item.cnt}개의 식물</span>
        </ImgInfo>
      </>
    )
  } else {
    return (
      <>
        <ImgInfo>
          <Skeleton variant="rounded" width="59px" height="20px" />
          <Skeleton variant="rounded" sx={{ mt: '6px' }} width="72px" height="18px" />
        </ImgInfo>
      </>
    )
  }
}

const ImgInfo = styled.div`
  p {
    /* 초록이들 */

    /* Sub-title */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;

    /* identical to box height, or 156% */
    letter-spacing: -0.02em;

    /* Black */
    color: #242424;

    margin-bottom: 3px;
  }
  span {
    font-family: 'Pretendard-Regular';

    font-style: normal;
    font-weight: 400;
    font-size: 15px;

    /* identical to box height, or 153% */
    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }
`
