import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MuiMain from 'src/components/common/mui/MuiMain'
import Title from 'src/components/common/title/Title'
import { useDiaryFormCheck } from 'src/components/specific/diary/hooks/DiaryCreateCustomHook'
import DiaryCreateForm from 'src/components/specific/diary/sub/DiaryCreateForm'
import PlantProgress from 'src/components/specific/plant/sub/PlantProgress'

export default function DiaryCreate() {
  return (
    <>
      <ContainerLayout styleId="div_container_02">
        <MuiMain>
          <Title
            styleID="div_container"
            backPath="/main"
            close={true}
            title="일지 작성하기"
            comp={
              <>
                <p>임시저장</p>
              </>
            }
          />
          <Content component={<>{/* <DiaryCreateForm /> */}</>} />
        </MuiMain>
      </ContainerLayout>
    </>
  )
}
