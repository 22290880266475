import MiddleContainerTwo from 'src/components/common/container/MiddleContainerTwo'
import PageSubTitleTwo from 'src/components/common/content/PageSubTitleTwo'
import SelectChipsList from 'src/components/common/form/sub/select/SelectChipsList'
import DetailViewLabelLayout from 'src/components/common/layout/DetailViewLabelLayout'
import DetailViewLineLayout from 'src/components/common/layout/DetailViewLineLayout'
import {
  COLLECTION_DETAIL_SELECT,
  COLLECTION_RELATED_PLAZA_POSTS,
  CONTENT_PADDING,
  PLANT_PLAZA_SELECT,
} from 'src/utils/common/constants/constants'
import forward from '../../../../assets/images/icon/ForwardIcon.png'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box } from '@mui/material'
import FormMain from 'src/components/common/form/FormMain'
import CollectionRelatedPlazaPosts from '../sub/CollectionRelatedPlazaPosts'
import { useNavigate, useParams } from 'react-router-dom'
import DrawerLayout from 'src/components/common/layout/DrawerLayout'
import { useEffect, useState } from 'react'
import CollectionItemMoveListMain from '../sub/CollectionItemMoveListMain'
import { useForm } from 'react-hook-form'
import { findTrueValues } from 'src/utils/common/scripts/common'
import { useGetRealtedPosts } from '../hook/CollectionListCustomHook'
import styled from 'styled-components'

type CollectionDetailRelatedPosts = {
  isFetched: boolean
  showBtn: boolean
  plantNm: string | null
}

export default function CollectionDetailRelatedPosts({
  plantNm,
  isFetched,
  showBtn = true,
}: CollectionDetailRelatedPosts) {
  const { itemId } = useParams()
  const navigate = useNavigate()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [closeInner, setCloseInner] = useState(false)
  const { list, isPending, setType, keyword, setKeyword, isSuccess, type } = useGetRealtedPosts()
  const [postTitle, setPostTitle] = useState('전체')

  const { register, getValues, watch, handleSubmit, setValue } = useForm()
  const onClickChip = (item: string) => {
    // type 바뀌면 refetch
    setType(item)
    let newTitle = ''
    switch (item) {
      case 'general':
        newTitle = '자유'
        break
      case 'common':
        newTitle = '전체'
        break
      case 'diary':
        newTitle = '일지'
        break
      case 'qna':
        newTitle = '질문'
        break
      case 'hashtag':
        newTitle = '해시태그'
        break
      default:
        newTitle = '전체'
        break
    }
    setPostTitle(newTitle)
  }

  useEffect(() => {
    if (plantNm) {
      const parenthesisL = plantNm.indexOf('(')
      let formatVal = plantNm
      if (parenthesisL >= 0) {
        formatVal = formatVal.slice(0, parenthesisL)
      }
      let hashTag = formatVal.replace(' ', '')
      hashTag = hashTag.trimEnd()
      setKeyword(hashTag)
    }

    // 식물 바뀌면 refetch
  }, [plantNm])

  return (
    <>
      <DetailViewLineLayout marginTop="23px" bb="unset">
        <Box sx={{ p: CONTENT_PADDING, pt: 0, pb: 0 }}>
          <DetailViewLabelLayout
            padding="0px 0px 0px 0px"
            marginTop="23px"
            marginBottom="23px"
            title="식물 광장에 올라온 글"
          >
            <FormMain>
              <ChipsWrap>
                <SelectChipsList
                  width="100%"
                  defaultVal="select.common"
                  onClickChip={onClickChip}
                  watch={watch}
                  length={5}
                  register={register}
                  getValues={getValues}
                  setValue={setValue}
                  mt="12px"
                  mb="18px"
                  list={isFetched ? PLANT_PLAZA_SELECT : null}
                />
                <Img
                  src={forward}
                  alt="식물광장으로 가기"
                  onClick={() => {
                    navigate(`/plaza/${type}/${keyword || null}`, {
                      state: {
                        select: type,
                        direction: 'na',
                        searchParam: keyword ? keyword : null,
                      },
                    })
                  }}
                />
              </ChipsWrap>
            </FormMain>

            <MiddleContainerTwo>
              {/* <PageSubTitleTwo marginBottom="25px" title={isFetched ? postTitle : null} iconComp={<></>} /> */}
              <CollectionRelatedPlazaPosts
                //   list={COLLECTION_RELATED_PLAZA_POSTS}
                list={list ? list : isPending || !isFetched ? undefined : null}
              />
            </MiddleContainerTwo>
          </DetailViewLabelLayout>
          {showBtn && (
            <FormMain.Button
              onClick={() => {
                setDrawerOpen(true)
              }}
              marginTop="36px"
              styleID="btn_submit_01_active mt_22p6_percent"
              width="100%"
              type="submit"
            >
              내 도감에 저장하기
            </FormMain.Button>
          )}
        </Box>

        <>
          {showBtn && (
            <DrawerLayout
              title="내 도감 목록"
              height="60%"
              pb={0}
              pt={0}
              open={drawerOpen}
              onClose={() => {
                setDrawerOpen(false)
                setCloseInner(false)
              }}
              closeInner={closeInner}
              children={
                <>
                  <CollectionItemMoveListMain
                    type="ADD"
                    onFinish={() => {
                      setCloseInner(true)
                    }}
                  />
                </>
              }
            />
          )}
        </>
      </DetailViewLineLayout>
    </>
  )
}

const ChipsWrap = styled.div`
  display: flex;
  justify-content: space-between;
`

const Img = styled.img`
  width: 8.67px;
  height: 16px;
`
