/*eslint-disable */

import * as React from 'react'
import { styled } from '@mui/material/styles'
import { grey } from '@mui/material/colors'
import Skeleton from '@mui/material/Skeleton'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { createPortal } from 'react-dom'

const drawerBleeding = 56

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  btnComponent: any
  contentComponent: JSX.Element | JSX.Element[]
  window?: () => Window
}

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}))

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}))

export default function Drawer(props: Props) {
  const { window } = props
  const { btnComponent } = props
  const { contentComponent } = props
  const [open, setOpen] = React.useState(true)

  const toggleDrawer = (newOpen: boolean) => () => {
    console.log(123)
    setOpen(newOpen)
  }

  // This is used only for the example
  const container = window !== undefined ? () => window().document.body : undefined

  return createPortal(
    <>
      {/* {React.cloneElement(btnComponent, { onClick: toggleDrawer })} */}
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        ></StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto',
          }}
        >
          {contentComponent}
          <Skeleton variant="rectangular" height="100%" />
        </StyledBox>
      </SwipeableDrawer>
    </>,
    document.getElementById('root')!,
  )
}
