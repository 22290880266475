import { useEffect, useState } from 'react'
import styled from 'styled-components'

export default function SelectDualDate({ start, end, isOpen }: { isOpen: boolean; start: string; end: string }) {
  const [first, setFirst] = useState(true)

  useEffect(() => {
    if (isOpen && first) {
      setFirst(false)
    }
  }, [isOpen])
  return (
    <Wrap>
      <InnerWrap>
        {isOpen && <span>시작</span>}
        <Date className={first ? 'gray' : ''}>{start}</Date>
      </InnerWrap>
      <div>
        <span>-</span>
      </div>
      <InnerWrap>
        {isOpen && <span>종료</span>}
        <Date className={first ? 'gray' : ''}>{end}</Date>
      </InnerWrap>
    </Wrap>
  )
}

const Wrap = styled.div`
  place-content: space-between;
  display: flex;
`

const Date = styled.p`
  /* 04월 24일 (수) */

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  display: flex;
  align-items: center;

  color: #1c1c1c;

  &.gray {
    color: #979797;
  }
`

const InnerWrap = styled.div`
  display: flex;
  align-items: center;
  span {
    /* 시작 */

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #1c1c1c;

    margin-right: 8px;
  }
`
