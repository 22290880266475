import styled from 'styled-components'
import { CustomButton } from '../button/CustomButton'

type PlantItemLayoutType = {
  children: React.ReactNode
  buttonTitle?: string
  name: string
  eng?: string
  onClick: () => void
  className: string
  firstmt?: string
  padding?: string
  height?: string
}

export default function PlantItemLayout({
  buttonTitle,
  firstmt = '17px',
  padding = '10px 0px 10px 14px',
  children,
  name,
  eng = '',
  onClick,
  className,
  height = '80px',
}: PlantItemLayoutType) {
  return (
    <PlantItemContainer
      $height={height}
      $firstmt={firstmt}
      $pd={padding}
      onClick={() => {
        onClick()
      }}
      className={className}
    >
      {children}
      <PlantText name={name} eng={eng} />
      {buttonTitle && (
        <CustomButton styleID="btn_collection_move" type="button">
          {buttonTitle}
        </CustomButton>
      )}
    </PlantItemContainer>
  )
}

export function PlantText({ name, eng }: { name: string; eng?: string }) {
  return (
    <TextContainer>
      <PlantTitle>{name}</PlantTitle>
      {eng && <PlantEngTitle>{eng}</PlantEngTitle>}
    </TextContainer>
  )
}

const PlantItemContainer = styled.div<{ $height: string; $pd: string; $firstmt: string }>`
  width: 100%;

  box-sizing: border-box;
  display: flex;
  margin-top: 0px;

  padding: ${({ $pd }) => $pd || '10px 0px 10px 14px'};
  height: ${({ $height }) => $height || '80px'};

  border-radius: 5px;
  transition: all 0.2s;
  &:hover {
    cursor: pointer;
    background: #e0f7f1;

    border-radius: 7px;
    button {
      color: white;
      background-color: #00c398;
      border: none;
    }
  }
  &.selected {
    background: #e0f7f1;

    border-radius: 7px;
    button {
      color: white;
      background-color: #00c398;
      border: none;
    }
  }
  &.first {
    margin-top: ${({ $firstmt }) => `${$firstmt}`};
  }
`

const TextContainer = styled.div`
  disply: flex;
  flex-direction: column;
  margin-left: 15px;
  display: flex;
  align-self: center;
`

const PlantTitle = styled.h2`
  /* Body 01-R */
  font-family: Pretendard-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.02em;

  /* Black */
  color: #242424;
`

const PlantEngTitle = styled.h3`
  /* Caption */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  letter-spacing: -0.02em;

  /* Gray 05 */
  color: #646669;
`
