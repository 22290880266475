import { useState } from 'react'
import MainFindIdForm from './MainFindIdForm'
import MainFindIdResult from 'src/components/specific/user/find/MainFindIdResult'

export default function IDInquiry() {
  const [screen, setScreen] = useState('FORM')
  const [userEmail, setUserEmail] = useState('')

  const onConfirm = (email: string) => {
    setScreen('RESULT')
    setUserEmail(email)
  }
  return screen === 'FORM' ? <MainFindIdForm onConfirm={onConfirm} /> : <MainFindIdResult email={userEmail} />
}
