import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styled from 'styled-components'
import FormMain from '../form/FormMain'

export default function InfoModal({
  title,
  comp,
  open,
  handleClose,
  onTransitionExited,
  mt = '23px',
}: {
  comp: React.ReactElement | React.ReactNode
  title: string
  open: boolean
  onTransitionExited: () => void
  handleClose: () => void
  mt?: string
}) {
  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    if (open && !isOpen) {
      onTransitionExited()
    }
  }, [isOpen])

  React.useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <div>
      <Modal open={isOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <ModalWrap>
          <div>
            <Title>
              <TitleP>{title}</TitleP>
            </Title>
            <Content $mt={mt} id="modal-modal-description">
              {comp}
            </Content>
            <FormMain.Button
              marginTop="18px"
              onClick={() => {
                setIsOpen(false)
              }}
              styleID="btn_full_modal"
              type="button"
            >
              확인
            </FormMain.Button>
          </div>
        </ModalWrap>
      </Modal>
    </div>
  )
}

const ModalWrap = styled(Box)`
  /* Alert */

  background: #ffffff;
  place-content: center;
  display: flex;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 14px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 270px;
  padding: 18px 0px 18px 0px;
`
const TitleP = styled.pre`
  text-align: center;
`
const Title = styled.div`
  display: flex;
  place-content: center;
  width: 100%;
  height: 48px;
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* text-align: center; */
  letter-spacing: -0.02em;
  /* white-space: pre-wrap; */
  color: #242424;
`

const Content = styled.div<{ $mt: string }>`
  margin-top: ${({ $mt }) => $mt};
  /* 일지를 수정하시겠어요? */
`
