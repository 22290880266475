import { MutationFunction, QueryFunction } from '@tanstack/react-query'
import { NO_REFRESH_TOKEN_CD, NO_REFRESH_TOKEN_MSG, SUCCESS_CD } from '../constants/errorMessage'
import { toast } from 'react-toastify'
import { castRefreshTokenType } from '../scripts/checkType'
import setAccessToken from '../scripts/setAccessToken'

interface ResponseData {
  [key: string]: string | undefined | number | boolean | File | FormData | Blob | File[] | FormData
}

type QueryKey = [string, { searchParam: string | undefined | number; url: string }]

type MutationVariables = {
  formData: ResponseData | URLSearchParams | FormData
  url: string
}

type deleteMutationVariables = {
  url: string
}

type withoutBodyPostVariables = {
  url: string
}

export const getDataAdminTanstack: QueryFunction<ResponseData, QueryKey> = async ({
  queryKey,
  signal,
}: {
  queryKey: QueryKey
  signal: AbortSignal
}) => {
  const [, { searchParam, url }] = queryKey

  const fullUrl = searchParam ? `${url}${searchParam}` : url

  try {
    const response = await fetch(fullUrl, {
      method: 'GET',
      mode: 'cors',
      signal,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_ADMIN_ACCESS_TOKEN}`,
      },
    })

    // const text = await response.text()

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const data = await response.json()
    return data
  } catch (error) {
    return { message: '데이터가 없습니다.' }
  }
}

// 수정 - 로그인 X
export const patchDataTanstack: QueryFunction<ResponseData, QueryKey> = async ({
  queryKey,
  signal,
}: {
  queryKey: QueryKey
  signal: AbortSignal
}) => {
  const [, { searchParam, url }] = queryKey

  const fullUrl = searchParam ? `${url}${searchParam}` : url

  try {
    const response = await fetch(fullUrl, {
      method: 'PATCH',
      mode: 'cors',
      signal,
    })
    const data = await response.json()
    if (!response.ok) {
      const message = data?.message || data?.error
      throw new Error(message)
    }

    return data
  } catch (error) {
    return error
  }
}

// 조회 - 로그인 X
export const getDataTanstack: QueryFunction<ResponseData, QueryKey> = async ({
  queryKey,
  signal,
}: {
  queryKey: QueryKey
  signal: AbortSignal
}) => {
  const [, { searchParam, url }] = queryKey

  const fullUrl = searchParam ? `${url}${searchParam}` : url

  try {
    const response = await fetch(fullUrl, {
      method: 'GET',
      mode: 'cors',
      signal,
    })

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const data = await response.json()

    return data
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error)
    throw new Error('There was a problem with the fetch operation:')
  }
}

// 조회 - 로그인 O
export const getDataTanstackWithToken: QueryFunction<ResponseData, QueryKey> = async ({
  queryKey,
  signal,
}: {
  queryKey: QueryKey
  signal: AbortSignal
}) => {
  checkHasToken() // 토큰 여부 확인

  const [, { searchParam, url }] = queryKey

  const fullUrl = searchParam ? `${url}${searchParam}` : url

  try {
    let response = await fetchWithToken('GET', fullUrl, signal)

    if (response.status === 401) {
      await getNewAccessToken() // Refresh token
      response = await fetchWithToken('GET', fullUrl, signal)
    }

    const data = await handleResponse(response)
    console.log('data' + data)

    return data
  } catch (error: Error | any) {
    const message = error instanceof Error ? error.message : '' // Check if error is an instance of Error
    throw new Error(message)
  }
}

// 삭제 - 로그인 O
export const deleteDataTanstackWithToken: MutationFunction<ResponseData, deleteMutationVariables> = async ({ url }) => {
  checkHasToken() // 토큰 여부 확인

  try {
    let response = await fetchWithToken('DELETE', url)

    if (response.status === 401) {
      await getNewAccessToken() // Refresh token
      response = await fetchWithToken('DELETE', url)
    }

    const data = await handleResponse(response)

    return data
  } catch (error: Error | any) {
    const message = error instanceof Error ? error.message : '' // Check if error is an instance of Error
    return { message: message }
  }
}

// 수정 - 로그인 O - body x
export const patchDataTanstackWithToken: MutationFunction<ResponseData, deleteMutationVariables> = async ({ url }) => {
  checkHasToken() // 토큰 여부 확인

  try {
    let response = await fetchWithToken('PATCH', url)

    if (response.status === 401) {
      await getNewAccessToken() // Refresh token
      // Retry the request
      response = await fetchWithToken('PATCH', url)
    }

    const data = await handleResponse(response)

    return data
  } catch (error: Error | any) {
    const message = error instanceof Error ? error.message : '' // Check if error is an instance of Error
    return { message: message }
  }
}

// 등록 - 로그인 O - body x
export const postDataTanstackWithToken: MutationFunction<ResponseData, deleteMutationVariables> = async ({ url }) => {
  checkHasToken() // 토큰 여부 확인

  try {
    let response = await fetchWithToken('POST', url)

    if (response.status === 401) {
      await getNewAccessToken() // Refresh token
      // Retry the request
      response = await fetchWithToken('POST', url)
    }
    const data = await handleResponse(response)

    return data
  } catch (error: Error | any) {
    const message = error instanceof Error ? error.message : '' // Check if error is an instance of Error
    return { message: message }
  }
}

// 등록 - 로그인 x - body x
export const postDataJson: MutationFunction<ResponseData, MutationVariables> = async ({ formData, url }) => {
  try {
    const response = await fetch(url, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })

    const data = await response.json()

    if (!response.ok) {
      const message = data?.message || data?.error
      throw new Error(message)
    }

    return data
  } catch (error) {
    console.error('There was a problem with the fetch operation', error)
    throw new Error('There was a problem with the fetch operation')
  }
}

// 등록 - 로그인 o - body o
export const postDataJsonWithToken: MutationFunction<ResponseData, MutationVariables> = async ({ formData, url }) => {
  checkHasToken() // 토큰 여부 확인

  try {
    let response = await fetchWithTokenAndForm('POST', url, formData)

    if (response.status === 401) {
      await getNewAccessToken() // Refresh token
      // Retry the request
      response = await fetchWithTokenAndForm('POST', url, formData)
    }

    const data = await handleResponse(response)

    return data
  } catch (error) {
    const message = error instanceof Error ? error.message : '' // Check if error is an instance of Error
    throw new Error(message)
  }
}

// 수정 - 로그인 o - body o
export const patchDataJsonWithToken: MutationFunction<ResponseData, MutationVariables> = async ({ formData, url }) => {
  checkHasToken() // 토큰 여부 확인

  try {
    let response = await fetchWithTokenAndForm('PATCH', url, formData)

    if (response.status === 401) {
      await getNewAccessToken() // Refresh token
      // Retry the request
      response = await fetchWithTokenAndForm('PATCH', url, formData)
    }

    const data = await handleResponse(response)

    return data
  } catch (error) {
    const message = error instanceof Error ? error.message : '' // Check if error is an instance of Error
    throw new Error(message)
  }
}

// 등록 - 로그인 o - body x
export const postDataJsonWithTokenExceptBody: MutationFunction<ResponseData, withoutBodyPostVariables> = async ({
  url,
}) => {
  checkHasToken() // 토큰 여부 확인

  try {
    let response = await fetchWithToken('POST', url)

    if (response.status === 401) {
      await getNewAccessToken() // Refresh token
      // Retry the request
      response = await fetchWithToken('POST', url)
    }

    const data = await handleResponse(response)

    return data
  } catch (error) {
    const message = error instanceof Error ? error.message : '' // Check if error is an instance of Error
    throw new Error(message)
  }
}

export const postDataJsonKoGPT: MutationFunction<ResponseData, MutationVariables> = async ({ formData, url }) => {
  try {
    const response = await fetch(url, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        Authorization: `KakaoAK ${process.env.REACT_APP_OPEN_API_WEATHER_KEY}`,
      },
      body: JSON.stringify(formData),
    })

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const data = await response.json()

    return data
  } catch (error) {
    console.error('There was a problem with the fetch operation', error)
    throw new Error('There was a problem with the fetch operation')
  }
}

export const postDataMultiPartWithAdmin: MutationFunction<ResponseData, MutationVariables> = async ({
  formData,
  url,
}) => {
  try {
    const headers = new Headers()

    headers.append('Authorization', `Bearer ${process.env.REACT_APP_API_ADMIN_ACCESS_TOKEN}`)

    const options: RequestInit = {
      method: 'POST',
      mode: 'cors',
      headers,
    }

    if (formData instanceof FormData) {
      options.body = formData
    } else {
      headers.append('Content-Type', 'application/json')
      options.body = JSON.stringify(formData)
    }

    const response = await fetch(url, options)

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const data = await response.json()

    return data as ResponseData
  } catch (error) {
    console.error('There was a problem with the fetch operation', error)
    throw new Error('There was a problem with the fetch operation')
  }
}

export const postDataMultiPartWithToken: MutationFunction<ResponseData, MutationVariables> = async ({
  formData,
  url,
}) => {
  checkHasToken() // 토큰 여부 확인

  try {
    let response = await fetchWithTokenAndMultiForm('POST', url, formData)

    if (response.status === 401) {
      await getNewAccessToken() // Refresh token
      // Retry the request
      response = await fetchWithTokenAndMultiForm('POST', url, formData)
    }

    const data = await handleResponse(response)

    return data as ResponseData
  } catch (error) {
    console.error('There was a problem with the fetch operation', error)
    throw new Error('There was a problem with the fetch operation')
  }
}

// 수정 - 로그인 o - body o (멀티파트)
export const patchDataMultiPartWithToken: MutationFunction<ResponseData, MutationVariables> = async ({
  formData,
  url,
}) => {
  checkHasToken() // 토큰 여부 확인

  try {
    let response = await fetchWithTokenAndMultiForm('PATCH', url, formData)

    if (response.status === 401) {
      await getNewAccessToken() // Refresh token
      // Retry the request
      response = await fetchWithTokenAndMultiForm('PATCH', url, formData)
    }

    const data = await handleResponse(response)

    return data as ResponseData
  } catch (error) {
    console.error('There was a problem with the fetch operation', error)
    throw new Error('There was a problem with the fetch operation')
  }
}

// 등록 - 로그인 x - body o (string)
export const postDataString: MutationFunction<ResponseData, MutationVariables> = async ({ formData, url }) => {
  try {
    const response = await fetch(url, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const data = await response.json()

    return data
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error)
    throw new Error('There was a problem with the fetch operation:')
  }
}

// 조회 - 로그인 x
export async function getData({ url, searchTerm }: { url: string; searchTerm?: string }) {
  let fullUrl = url

  if (searchTerm) {
    fullUrl += searchTerm
  }

  try {
    const response = await fetch(fullUrl, {
      method: 'GET',
      mode: 'cors',
    })

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const data = await response.json()

    return data
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error)
    throw new Error('There was a problem with the fetch operation:')
  }
}

export const getNewAccessToken = async () => {
  console.log('refresh!')
  if (window.location.href.includes('local')) {
    toast.error('다시 로그인해주세요')
    window.location.replace('/')
  } else {
    const data = await requestNewAccessToken()
    if (data === NO_REFRESH_TOKEN_CD) {
      toast.error('다시 로그인해주세요')
      window.location.replace('/')
    }
  }
}

// 새 액세스 토큰 요청
export const requestNewAccessToken = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}auth-service/token`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })

    const data = await response.json()

    console.log(data)
    if (!response.ok) {
      const message = data?.message || data?.error
      if (message === NO_REFRESH_TOKEN_MSG) {
        return NO_REFRESH_TOKEN_CD
      }
    }
    let castObj

    if (data) {
      castObj = castRefreshTokenType(data)

      if (castObj) {
        console.log('토큰 변경')
        // console.log('토큰 변경' + castObj?.comPToken.token)
        setAccessToken(castObj?.comppiToken.token)
      }
    }

    return castObj?.comppiToken.token
  } catch (error: Error | any) {
    window.location.replace('/')
    // const message = error instanceof Error ? error.message : '' // Check if error is an instance of Error
    // throw new Error(message)
  }
}

// 토큰 존재 여부 확인
function checkHasToken() {
  if (!hasToken()) {
    toast.error('다시 로그인해주세요')
    window.location.replace('/')
  }
}

// 토큰 존재 여부 확인
function hasToken() {
  const token = localStorage.getItem('accessToken')
  if (!token || (token && !token.length)) {
    return false
  } else {
    return true
  }
}

// data 예외 처리 공통
const handleResponse = async (response: Response): Promise<any> => {
  const data = await response.json()
  if (!response.ok) {
    const message = data?.message || data?.error

    if (data?.error.response.status === 401) {
      console.log(401)
      return 401
    } else {
      throw new Error(message)
    }
  }
  return data
}

// api 요청 - body x
const fetchWithToken = async (method: string, url: string, signal?: AbortSignal): Promise<Response> => {
  return fetch(url, {
    method: method,
    mode: 'cors',
    signal,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  })
}

// api 요청 - body 0
const fetchWithTokenAndForm = async (
  method: string,
  url: string,
  formData: ResponseData | URLSearchParams | FormData,
  signal?: AbortSignal,
): Promise<Response> => {
  const headers = new Headers()
  headers.append('Authorization', 'Bearer ' + localStorage.getItem('accessToken'))

  const options: RequestInit = {
    method: method,
    mode: 'cors',
    headers,
    signal,
  }

  if (formData instanceof FormData) {
    options.body = formData
  } else {
    headers.append('Content-Type', 'application/json')
    options.body = JSON.stringify(formData)
  }

  return fetch(url, options)
}

const fetchWithTokenAndMultiForm = async (
  method: string,
  url: string,
  formData: ResponseData | URLSearchParams | FormData,
  signal?: AbortSignal,
): Promise<Response> => {
  const headers = new Headers()

  headers.append('Authorization', 'Bearer ' + localStorage.getItem('accessToken'))

  const options: RequestInit = {
    method: method,
    mode: 'cors',
    headers,
    signal,
  }

  if (formData instanceof FormData) {
    options.body = formData
  } else {
    headers.append('Content-Type', 'application/json')
    options.body = JSON.stringify(formData)
  }
  const fetchedData = await fetch(url, options)
  return fetchedData
}
