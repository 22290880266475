import { Grid } from '@mui/material'
import { Suspense, useEffect } from 'react'
import { CustomButton } from 'src/components/common/button/CustomButton'
import ImgTag from 'src/components/common/imgTag/ImgTag'
import { useKaKaoLogin } from 'src/utils/query/login/useKaKaoLogin'
import styled from 'styled-components'
import kktLogo from 'src/assets/images/info/kakaoLogo.png'
import naverLogo from 'src/assets/images/info/naverLogo.png'
import { useNaverLogin } from 'src/utils/query/login/useNaverLogin'
import { useLocation } from 'react-router-dom'

// oauth 요청로직 onClick함수 안에 넣으려 했는데
// window.location.href = KAKAO_AUTH_URL
// 위 코드 실행되면서 새로고침되서
// onClick함수 스코프의 위 코드의 하단의 코드들은 실행안됨
// ===> useEffect 훅 사용하여 code로 시작하는
// 쿼리 스트링 있을 때만 mutate 함수 실행하는 것으로 해결

const REDIRECT_URI = window.location.href.includes('local')
  ? process.env.REACT_APP_REDIRECT_URL
  : process.env.REACT_APP_MAIN_REDIRECT_URL
const KKT_REST_API_KEY = process.env.REACT_APP_KKT_REST_API_KEY
const NAVER_REST_API_KEY = process.env.REACT_APP_NAVER_REST_API_KEY
const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${KKT_REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`
const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_REST_API_KEY}&redirect_uri=${REDIRECT_URI}&state=test`

export default function LoginOAuth() {
  return (
    <Grid pt={11.25}>
      <OAuthTitleContainer>
        <OAuthTitle>간편 로그인</OAuthTitle>
      </OAuthTitleContainer>
      <Grid display="flex" width="100%" justifyContent="space-between">
        <KaKaoButton />
        <NaverButton />
      </Grid>
    </Grid>
  )
}

function KaKaoButton() {
  const { mutate } = useKaKaoLogin()
  const handleKakaoLogin = () => {
    window.location.href = KAKAO_AUTH_URL
  }
  useEffect(() => {
    const url = new URL(document.location.toString()).searchParams

    if (url.get('code') && !url.get('state')) {
      mutate(url)
    }
  }, [])
  return (
    <CustomButton styleID="loginBtn loginKktBtn" width="48%" height="51px" type="button" onClick={handleKakaoLogin}>
      <img src={kktLogo} alt="카톡로고" />
      <span
        style={{
          marginLeft: '8.44px',
        }}
      >
        카카오로 계속하기
      </span>
    </CustomButton>
  )
}

function NaverButton() {
  const { mutate } = useNaverLogin()

  const handleNaverLogin = () => {
    window.location.href = NAVER_AUTH_URL
  }
  useEffect(() => {
    const url = new URL(document.location.toString()).searchParams
    if (url.get('code') && url.get('state')) {
      mutate(url)
    }
  }, [])
  return (
    <Suspense>
      <CustomButton styleID="loginBtn loginNaverBtn" width="48%" height="51px" type="button" onClick={handleNaverLogin}>
        <img src={naverLogo} alt="네이버로고" />
        <span
          style={{
            marginLeft: '8.44px',
          }}
        >
          네이버로 계속하기
        </span>
      </CustomButton>
    </Suspense>
  )
}

const OAuthTitleContainer = styled.div`
  width: 100%;
  border-top: 0.5px solid #979797;
  display: flex;
  justify-content: center;
`

const OAuthTitle = styled.div`
  text-align: center;
  display: inline-block;
  text-align: center;
  background: white;
  position: relative;
  top: -7px;
  font-size: 0.8rem;
  width: 25%;
  padding: auto 10px;
  color: #979797;
`
