import { Box } from '@mui/material'
import PageSubTitleTwo from 'src/components/common/content/PageSubTitleTwo'
import { CONTENT_PADDING } from 'src/utils/common/constants/constants'
import RcntSearchedList from '../sub/RcntSearchedList'
import FormMain from 'src/components/common/form/FormMain'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { castTypeSearchArr } from 'src/utils/common/scripts/checkType'
import { SearchArrType } from 'src/utils/common/type/type'

/*
    파일명 : RcntSearched.tsx
    설명 : 최근 검색어
    작성일 : 2024-06-16
*/
export default function RcntSearched() {
  const { register, getValues, setValue, watch, handleSubmit } = useForm()

  useEffect(() => {
    const search = localStorage.getItem('search_history')

    let searchList = []
    let newObj
    if (search) {
      searchList = search.split('#').filter(Boolean)
      newObj = castTypeSearchArr({ result: searchList })
    }
    register('hashtagArr')

    setValue('hashtagArr', newObj?.result ? newObj?.result : [])
  }, [])

  const onClickDelete = () => {
    setValue('hashtagArr', [])
    localStorage.setItem('search_history', '')
  }
  return (
    <>
      <Box sx={{ p: CONTENT_PADDING, pb: 0, pt: 0 }}>
        <PageSubTitleTwo
          marginTop="30px"
          h1ClassNm="small"
          marginBottom="12px"
          title="최근 검색어"
          iconComp={<span onClick={onClickDelete}>지우기</span>}
        />
      </Box>
      <Box sx={{ pl: CONTENT_PADDING }}>
        <FormMain>
          <RcntSearchedList watch={watch} getValues={getValues} setValue={setValue} />
        </FormMain>
      </Box>
    </>
  )
}
