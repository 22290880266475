import { weatherDescriptions } from '../constants/constants'

export const getWeatherInfo = data => {
  const discription = getWeatherDiscription(data['weather'][0]['id'])
  const result = {
    discription: discription,
    icon: data['weather'][0]['icon'].toString() || '',
    weather: data['weather'][0]['description'].toString() || '',
    temp: (data['main']['temp'] - 273.15).toFixed(0).toString() || '',
    min: (data['main']['temp_min'] - 273.15).toFixed(0).toString() || '',
    max: (data['main']['temp_max'] - 273.15).toFixed(0).toString() || '',
    humidity: data['main']['humidity'].toString() || '',
    wind: data['wind']['speed'].toString() || '',
    rain: data['rain'] !== undefined ? data['rain']['1h'].toString() : '0',
  }

  return result
}

export const getWeatherDiscription = id => {
  return weatherDescriptions[id] || ''
}

export const test = () => {}
