import styled from 'styled-components'
import ForwardIcon from '../icon/ForwardIcon'
import Forward from '../../../assets/images/icon/ForwardGray.png'

export default function TodoContainer({
  onClick,
  children,
  title,
  subtitle,
  mt = '0px',
  mb = '0px',
  selected, // CURRENT, COMING
  onClickTitle,
}: {
  onClick: () => void
  onClickTitle: (newVal: string) => void
  title: string
  mt?: string
  mb?: string
  subtitle: string
  selected: string
  children: React.ReactNode | JSX.Element
}) {
  return (
    <Wrap $mb={mb} $mt={mt}>
      <Header>
        <Title>
          <TitleInnerDiv
            onClick={() => {
              onClickTitle('today')
            }}
          >
            <TitleText className={selected === 'today' ? '' : 'unselected'}>{title}</TitleText>
            {selected === 'today' && <Select />}
          </TitleInnerDiv>
          <TitleInnerDiv
            className="right"
            onClick={() => {
              onClickTitle('upcoming')
            }}
          >
            <TitleText className={selected === 'upcoming' ? '' : 'unselected'}>{subtitle}</TitleText>
            {selected === 'upcoming' && <Select />}
          </TitleInnerDiv>
        </Title>
        <img onClick={onClick} alt="일정 더보기" src={Forward} />
      </Header>
      {children}
    </Wrap>
  )
}

const Title = styled.div`
  display: flex;
`
const Select = styled.div`
  /* Ellipse 94 */

  width: 6px;
  height: 6px;

  background: #00c398;
  border-radius: 50%;
  margin-left: 2px;
`

const TitleText = styled.p`
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.05em;

  color: #1c1c1c;

  &.unselected {
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.05em;

    color: #979797;
  }
`
const TitleInnerDiv = styled.div`
  display: flex;
  position: relative;

  &.right {
    margin-left: 10px;
  }

  &:hover {
    cursor: pointer;
  }
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 21px;
  border-bottom: 1px solid #f2f2f2;
  img {
    width: 9px;
    height: 16px;
  }
`

const Wrap = styled.div<{ $mb: string; $mt?: string }>`
  /* Rectangle 113 */

  width: 100%;
  height: fit-content;

  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 13px;
  margin-top: ${({ $mt }) => $mt};
  margin-bottom: ${({ $mb }) => $mb};
`
