import * as React from 'react'
import { Global } from '@emotion/react'
import { styled } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { grey } from '@mui/material/colors'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Title from '../title/Title'
import { CONTENT_PADDING, CONTENT_PADDING_BOTTOM, CONTENT_PADDING_TOP } from 'src/utils/common/constants/constants'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/scrollbar'

import { FreeMode, Scrollbar, Mousewheel } from 'swiper/modules'

const drawerBleeding = 56

interface Props {
  comp?: React.ReactNode
  window?: () => Window
  children: React.ReactNode
  open: boolean
  onClose: () => void
  p?: number
  pt?: number
  pb?: number
  title: string
  closeInner?: boolean
  showTitle?: boolean
}

const Root = styled('div')(({ theme }) => ({
  height: 'auto',
  overflow: 'hidden',
}))

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],

  position: 'absolute',

  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  visibility: 'visible',
  right: 0,
  left: 0,

  transition: 'height 0.3s ease',
}))

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}))

export default function DrawerRightLayout(props: Props) {
  const {
    window,
    children,
    onClose,
    title,
    open,
    comp,
    showTitle = true,
    p = CONTENT_PADDING,
    pt = CONTENT_PADDING_TOP,
    pb = CONTENT_PADDING_BOTTOM,
    closeInner = false,
  } = props
  const [innerOpen, setInnerOpen] = React.useState(false)

  const toggleDrawer = (newOpen: boolean) => () => {
    setInnerOpen(newOpen)
  }

  React.useEffect(() => {
    if (open) {
      setInnerOpen(open)
    }
  }, [open])

  React.useEffect(() => {
    if (closeInner) {
      setInnerOpen(!open)
    }
  }, [closeInner])

  const container = window !== undefined ? () => window().document.body : undefined

  if (!open) {
    return null
  } else {
    return (
      <Root>
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              height: '100%',
              width: '100%',
              overflow: 'visible',
              maxWidth: '390px',
              display: 'flex',
              margin: 'auto',
              insetArea: 'center',
            },
          }}
        />
        <Box sx={{ overflow: 'auto', textAlign: 'center', pt: 1 }}>
          <SwipeableDrawer
            style={{ height: 'auto' }}
            container={container}
            anchor="right"
            open={innerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            ModalProps={{
              keepMounted: true,
              onTransitionExited: onClose,
            }}
          >
            <StyledBox>
              {showTitle && (
                <Title comp={<>{comp}</>} styleID="div_container div_mb" title={title} onClick={toggleDrawer(false)} />
              )}
              <Box
                sx={{
                  height: '100vh',
                  overflowY: 'auto',
                  overflowX: 'hidden',

                  p,
                  pb,
                  pt,
                }}
              >
                {children}
              </Box>
            </StyledBox>
          </SwipeableDrawer>
        </Box>
      </Root>
    )
  }
}
