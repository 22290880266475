import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { patchDataJsonWithToken, postDataJsonWithToken, postDataMultiPartWithToken } from 'src/utils/common/api/http'
import { useCheckTokenValidity, useValidationCheck } from 'src/utils/common/hooks/customHook'
import { formatDateToString } from 'src/utils/common/scripts/common'

/*
    일지 유효성 검사
*/
export const usePlanFormCheck = () => {
  const {
    checkInputText,
    selectedKey,
    checkCheckBox,
    isValidObj,
    confirmed,
    formAlert,
    nullCheck,
    regExpCheck,
    setTrue,
    checkTextArea,
    checkFileCnt,
    setValidCnt,
    checkDttmPeriodValidate,
  } = useValidationCheck()
  const navigate = useNavigate()
  const { checkData } = useCheckTokenValidity()
  useEffect(() => {
    setValidCnt(1)
  }, [])

  const formatDate = (data: { [key: string]: string }, start: Date, end: Date) => {
    const { mm, ss, time } = data

    const startFormat = formatDateToString(start)
    const endFormat = formatDateToString(end)

    // 시간 정보를 24시간 형식으로 변환
    let hour = parseInt(mm, 10)
    if (time === 'pm' && hour !== 12) {
      hour += 12
    } else if (time === 'am' && hour === 12) {
      hour = 0
    }

    const isoStrtString = `${startFormat}T${hour.toString().padStart(2, '0')}:${ss}:00`
    const isoEndString = `${endFormat}T${hour.toString().padStart(2, '0')}:${ss}:00`

    console.log(isoStrtString, isoEndString) // 출력 예: "2024-06-26T10:10:00"

    return {
      start: isoStrtString,
      end: isoEndString,
    }
  }

  const formatSingle = (data: { [key: string]: string }, start: Date) => {
    const { mm, ss, time } = data

    const startFormat = formatDateToString(start)

    // 시간 정보를 24시간 형식으로 변환
    let hour = parseInt(mm, 10)
    if (time === 'pm' && hour !== 12) {
      hour += 12
    } else if (time === 'am' && hour === 12) {
      hour = 0
    }

    const isoStrtString = `${startFormat}T${hour.toString().padStart(2, '0')}:${ss}:00`

    console.log(isoStrtString) // 출력 예: "2024-06-26T10:10:00"

    return isoStrtString
  }

  const checkTitle = (title: string) => {
    return checkInputText({
      value: title,
      name: 'title',
      label: '제목',
      regExp: '^.{1,50}$',
    })
  }

  const checkDt = (dateObj: { name: string; startDt: Date; startTm: string; endDt: Date; endTm: string }) => {
    return checkDttmPeriodValidate(dateObj)
  }

  const checkImgCnt = (filecnt: number) => {
    return checkFileCnt({ value: filecnt, name: 'file', label: '사진' })
  }
  const checkAll = (data: { title: string }) => {
    if (!checkTitle(data.title)) return false
    return true
  }
  const {
    mutate,
    data: createDiaryResult,
    isPending,
    isError,
    isSuccess,
  } = useMutation({
    mutationFn: postDataJsonWithToken,
    onSuccess: data => {
      toast.success('일정 등록이 완료되었습니다.')
    },
    onError: error => {
      checkData(error)
    },
  })

  const {
    mutate: update,
    data,
    isPending: updating,
    isError: errorToUpdating,
    isSuccess: successToUpdating,
  } = useMutation({
    mutationFn: patchDataJsonWithToken,
    onSuccess: successD => {
      toast.success('일정 수정이 완료되었습니다.')
    },
    onError: error => {
      checkData(error)
    },
  })

  const createDiary = (
    id: number | null,
    title: string,
    frequency: number,

    colorType: string,

    start: string,
    end: string,
  ) => {
    const formData = {
      title: title,
      frequency: frequency,
      colorType: colorType,
      startDateTime: start,
      endDateTime: end,
    }
    console.log('formData')
    console.log(formData)
    let url = `${process.env.REACT_APP_API_DOMAIN}my-plant/schedules`
    if (id) {
      url += `/${id}`
      update({ formData, url })
    } else {
      mutate({ formData, url })
    }
  }

  return {
    checkImgCnt,
    errorToUpdating,
    successToUpdating,
    selectedKey,
    isValidObj,
    confirmed,
    formAlert,
    checkTitle,
    checkCheckBox,
    checkTextArea,
    checkAll,
    createDiary,
    formatDate,
    isError,
    isSuccess,
    formatSingle,
    checkDt,
    isPending,
    updating,
  }
}

export const test = () => {}
