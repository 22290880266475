/*
    파일명 : PlazaCreate.tsx
    설명 : 식물 광장 > 질문하기, 자랑하기 폼
    작성일 : 2024-06-10
    @param 
        - formType : ASKING/BOASTING
*/

import { useForm } from 'react-hook-form'
import FormMain from 'src/components/common/form/FormMain'
import { useDeletePost, usePlazaFormCheck } from '../hooks/PlazaCreateCustomHook'
import { useEffect, useState } from 'react'
import SelectBoxLayout from 'src/components/common/layout/SelectBoxLayout'
import { POST_TYPE, SAMPLE_PLANT } from 'src/utils/common/constants/constants'
import MyPlan from '../../main/MyPlan'
import MyPlantList from '../../main/MyPlantList'
import PlantListCommon from 'src/components/common/content/PlantListCommon'
import InputHashTag from 'src/components/common/form/sub/input/InputHashTag'
import ChoiceChips from 'src/components/common/form/sub/select/ChoiceChips'
import { PostType } from 'src/utils/common/type/type'
import { getFileFromUrl } from 'src/utils/common/scripts/common'
import { useRecoilState } from 'recoil'
import { initPlazaState } from 'src/utils/common/state/state'
import Alert2Modal from 'src/components/common/modal/Alert2Modal'
import FullLoadingSpinner from 'src/components/common/container/FullLoadingSpinner'

type PlazaCreateFormType = {
  formType: string
  updatePost: PostType | undefined
  onPostSuccess: (updateYn: boolean) => void
  openProp: boolean
}

export default function PlazaCreateForm({ onPostSuccess, formType, updatePost, openProp }: PlazaCreateFormType) {
  const {
    checkAll,
    checkImgCnt,
    checkContent,
    selectedKey,
    isValidObj,
    confirmed,
    formAlert,
    isSuccess,
    checkTitle,
    checkCheckBox,
    checkHashTagArr,
    createData,
    updateData,
    isPending,
    updating,
    isError,
    setFalse,
    errorToUpdating,
    successToUpdating,
    setTrue,
  } = usePlazaFormCheck()

  const { deleteItem, isPending: deleting, deletingSuccess } = useDeletePost()

  const { register, getValues, setValue, watch, handleSubmit } = useForm()

  useEffect(() => {
    const subscirbe = watch((data, { name }) => {
      if (name === 'title') {
        checkTitle(getValues('title'))
      } else if (name === 'content') {
        checkContent(getValues('content'))
      } else if (name === 'type') {
        checkCheckBox(getValues('type'), 'type', '게시글 유형')
      }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])
  useEffect(() => {
    if (deletingSuccess) {
      onPostSuccess(true)
    }
  }, [deletingSuccess])
  useEffect(() => {
    if (isSuccess) {
      onPostSuccess(true)
    }
  }, [isSuccess])

  useEffect(() => {
    if (errorToUpdating) {
      onPostSuccess(false)
    }
  }, [errorToUpdating])

  useEffect(() => {
    if (isError) {
      onPostSuccess(false)
    }
  }, [isError])

  useEffect(() => {
    if (successToUpdating) {
      onPostSuccess(true)
    }
  }, [successToUpdating])

  useEffect(() => {
    const fetchData = async () => {
      if (updatePost) {
        register('hashtagArr')
        register('articleId')
        setValue('articleId', updatePost.articleId)
        setValue('title', updatePost.title)
        setValue('content', updatePost.content)
        setValue('hashtagArr', updatePost.hashtagList)
        checkTitle(getValues('title'))
        checkContent(getValues('content'))
        checkCheckBox(getValues('type'), 'type', '게시글 유형')
        if (updatePost.hashtagList) {
          checkHashTagArr(updatePost.hashtagList)
        }
      } else {
        register('hashtagArr')
        setValue('hashtagArr', [])
      }
    }

    fetchData() // Call the async function here
  }, [])

  const onClickSubmitBtn = (data: { [key: string]: string | { [key: string]: boolean } }) => {
    console.log(data)
    if (isPending || updating || deleting) return
    if (!confirmed) {
      checkAll({
        title: getValues('title'),
        content: getValues('content'),
        type: getValues('type'),
        hashtagArr: getValues('hashtagArr'),
      })
    } else {
      const type = getValues('type')
      const trueValues = Object.keys(type).filter(key => type[key] === true)

      const form = new FormData()

      if (getValues('fileCnt') > 0) {
        const files = getValues('file')

        files.forEach((item: File, index: number) => {
          form.append(`imageFiles`, item, item.name)
        })
      }
      if (updatePost) {
        updateData(data, trueValues, form)
      } else {
        createData(data, trueValues, form)
      }
    }
  }
  // 해시태그 값 추가
  const onClickAddHashtags = () => {
    if (!getValues('hashtag')) return

    const hashtagArr = getValues('hashtagArr')
    const hashtagValue = getValues('hashtag').replace(/\s/g, '') // 모든 공백 제거
    // 이미 동일한 값이 있는지 확인
    if (hashtagArr && hashtagArr.includes(hashtagValue)) {
      setValue('hashtag', '')
      // 이미 존재하면 함수 종료
      return
    }
    if (hashtagArr && hashtagArr.length > 5) {
      return
    }
    setValue('hashtag', '')
    // 새로운 해시태그를 추가
    setValue('hashtagArr', [...(hashtagArr || []), hashtagValue])
    checkHashTagArr([...(hashtagArr || []), hashtagValue])
  }

  return (
    <>
      {deleting && <FullLoadingSpinner isShow={true} />}
      <FormMain styleID="scroll">
        <FormMain.LabelLayout
          isFirst={true}
          infoStyleId="text_red"
          warning={isValidObj && isValidObj.title === false ? true : false}
          warnText={formAlert && formAlert.title ? formAlert.title : ''}
          label="제목"
        >
          <FormMain.InputFull
            styleId={
              isValidObj && isValidObj.title === false
                ? 'div_plant_02 wdt_full mb_10 border_red'
                : 'div_plant_02 wdt_full mb_10'
            }
            inputComp={
              <FormMain.Input type="text" placeholder="제목을 입력해주세요" registerFn={register('title', {})} />
            }
          />
        </FormMain.LabelLayout>
        <FormMain.LabelLayout
          warning={isValidObj && isValidObj.content === false ? true : false}
          warnText={formAlert && formAlert.content ? formAlert.content : ''}
          label="내용"
        >
          <FormMain.TextArea
            warning={isValidObj && isValidObj.content === false ? true : false}
            registerFn={register('content', {
              required: false,
            })}
            placeholder="내용을 입력해주세요"
          />
        </FormMain.LabelLayout>
        <FormMain.LabelLayout
          infoStyleId=""
          warning={isValidObj && isValidObj.hashtag === false ? true : false}
          warnText={formAlert && formAlert.hashtag ? formAlert.hashtag : ''}
          label="해시태그 검색"
          infoText=""
        >
          <InputHashTag
            onDelete={newArr => {
              checkHashTagArr(newArr)
            }}
            watch={watch}
            getValues={getValues}
            setValue={setValue}
            name="hashtag"
            inputComp={
              <FormMain.Input
                styleID=""
                type="text"
                onKeyUp={event => {
                  if (event.code === 'Enter') {
                    onClickAddHashtags()
                  }
                }}
                placeholder="# 해시태그를 검색해주세요. (최대 5개)"
                registerFn={register('hashtag', {})}
              />
            }
            buttonComp={
              <FormMain.Button styleID="btn_hash" onClick={onClickAddHashtags} width="" height="" type="button">
                추가
              </FormMain.Button>
            }
          />
        </FormMain.LabelLayout>
        <FormMain.LabelLayout
          warning={isValidObj && isValidObj.type === false ? true : false}
          warnText={formAlert && formAlert.type ? formAlert.type : ''}
          label="게시글 유형"
        >
          <ChoiceChips
            readOnly={updatePost ? true : false}
            defaultVal={updatePost ? (updatePost.type.includes('GENERAL') ? 'general' : 'qna') : null}
            length={3}
            multiple={false}
            list={POST_TYPE}
            listName="type"
            register={register}
            getValues={getValues}
            setValue={setValue}
          />
        </FormMain.LabelLayout>
        <FormMain.LabelLayout
          warning={isValidObj && isValidObj.file === false ? true : false}
          warnText={formAlert && formAlert.file ? formAlert.file : ''}
          label="사진(선택)"
        >
          <FormMain.ImageFile
            setTrue={setTrue}
            setFalse={setFalse}
            defaultValList={updatePost?.imageUrls ? updatePost.imageUrls : undefined}
            warning={isValidObj && isValidObj.file === false ? true : false}
            registerFn={register('file', {
              required: false,
            })}
            name="file"
            register={register}
            getValues={getValues}
            setValue={setValue}
          />
        </FormMain.LabelLayout>
        <FormMain.Button
          onClick={handleSubmit(onClickSubmitBtn)}
          styleID={confirmed === true ? 'btn_submit_01_active mt_52_px' : 'btn_submit_01 mt_52_px'}
          width="100%"
          height=""
          marginTop="26px"
          type="button"
        >
          작성 완료
        </FormMain.Button>
      </FormMain>
      <FullLoadingSpinner isShow={isPending || updating || deleting} />
      {updatePost && <PlazaDeleteAlert openProp={openProp} updatePost={updatePost} deleteItem={deleteItem} />}
    </>
  )
}

function PlazaDeleteAlert({
  openProp,
  deleteItem,
  updatePost,
}: {
  openProp: boolean
  updatePost: PostType

  deleteItem: (id: number, sort: string) => void
}) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (openProp) {
      setOpen(openProp)
    }
  }, [openProp])

  return (
    <>
      <Alert2Modal
        title="게시글 삭제"
        description="게시글을 삭제하시겠어요?"
        open={open}
        afterCloseAlert={() => {
          if (updatePost) {
            if (updatePost.type) {
              deleteItem(updatePost.articleId, updatePost.type.includes('GENERAL') ? 'general' : 'qna')
            } else {
              deleteItem(updatePost.articleId, 'unset')
            }
          }
          setOpen(false)
        }}
        handleClose={() => {
          setOpen(false)
        }}
      />
    </>
  )
}
