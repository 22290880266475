import React from 'react'
import styled from 'styled-components'
import { CalendarEventType } from 'src/utils/common/type/type'
import moment from 'moment'
import { generateRandomKey } from 'src/utils/common/scripts/common'

moment.locale('ko')

type MonthlyCalendarType = {
  disabledDate: Date
  startOfMonth: Date
  selectedDate: Date
  onClickDate: (date: Date) => void
  readonly?: boolean
  viewAll?: boolean
  selectNext?: boolean
  events: CalendarEventType[] | null
}

function MonthlyCalendar({
  readonly = true,
  viewAll = false,
  startOfMonth,
  disabledDate,
  selectedDate,
  onClickDate,
  selectNext = false,
  events,
}: MonthlyCalendarType) {
  // 주간 일정 표시용 년도와 월

  // 요일 배열
  const daysOfWeek: string[] = ['일', '월', '화', '수', '목', '금', '토']

  function onClickDateDate(date: Date) {
    if (selectNext && !viewAll) {
      const today = new Date()

      if (moment(today).isAfter(date, 'day')) return
    } else {
      if (date > disabledDate && !viewAll) return
    }

    onClickDate(date)
  }
  // 해당 월의 시작 주를 찾는 함수
  const getStartOfWeekInMonth = (date: Date): Date => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
    const startOfWeek = new Date(firstDayOfMonth)

    // Adjust startOfWeek if the first day of the month is not a Sunday
    if (startOfWeek.getDay() !== 0) {
      startOfWeek.setDate(1 - (startOfWeek.getDay() || 7)) // Move back to the first Sunday
    }

    return startOfWeek
  }

  function getEvents(day: Date) {
    if (events === null) {
      return <></>
    }
    const hasWeeklyEvent = events.filter(event => event.date === moment(day).format('YYYY-MM-DD'))

    let eventsComp = <></>

    if (hasWeeklyEvent.length >= 4) {
      eventsComp = (
        <>
          {hasWeeklyEvent.slice(0, 4).map((item, index) =>
            index <= 2 ? (
              <HavePlan key={generateRandomKey()} $bc={item.colorType ? item.colorType : '#00C398'}>
                .
              </HavePlan>
            ) : (
              <></>
            ),
          )}
        </>
      )
    } else {
      eventsComp = (
        <>
          {hasWeeklyEvent.map((item, index) => (
            <HavePlan key={generateRandomKey()} $bc={item.colorType ? item.colorType : '#00C398'}>
              .
            </HavePlan>
          ))}
        </>
      )
    }

    if (readonly && hasWeeklyEvent.length > 0) {
      return (
        <div style={{ position: 'relative' }} key={generateRandomKey()}>
          <PlanWrap>{eventsComp}</PlanWrap>
          <RestPlanWrap>
            {hasWeeklyEvent.length > 3 && <RestEvents>+{hasWeeklyEvent.length - 3}</RestEvents>}
          </RestPlanWrap>
        </div>
      )
    } else {
      return <></>
    }
  }

  // 해당 주의 일자 배열 생성 함수
  const getDaysInWeek = (startOfWeek: Date): Date[] => {
    const days = []
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startOfWeek)
      currentDate.setDate(startOfWeek.getDate() + i)
      days.push(currentDate)
    }
    return days
  }
  // 월간 달력 생성 함수
  const renderMonthlyCalendar = () => {
    const startOfWeekInMonth = getStartOfWeekInMonth(startOfMonth)
    const weeks = []

    let isFirstWeek = true // 첫째 주 여부를 확인하기 위한 변수

    // 6주까지 렌더링합니다.
    for (let i = 0; i < 6; i++) {
      const daysInWeek = getDaysInWeek(
        new Date(startOfWeekInMonth.getFullYear(), startOfWeekInMonth.getMonth(), startOfWeekInMonth.getDate() + i * 7),
      )

      // 첫째 주에는 항상 달의 1일이 포함되어야 합니다.
      if (isFirstWeek) {
        isFirstWeek = false
      } else if (daysInWeek[0].getMonth() !== startOfMonth.getMonth()) {
        // 첫째 주가 아닌 경우에는 다음 달에 속하는 경우에만 처리하지 않습니다.
        continue // 다음 달에 속하는 경우 이번 주는 렌더링하지 않습니다.
      }

      // 현재 주가 이번 달에 속하는 경우에만 렌더링합니다.
      if (daysInWeek.some(day => day.getMonth() === startOfMonth.getMonth())) {
        weeks.push(
          <Week key={i}>
            {daysInWeek.map((day, index) => {
              // 주간 일정이 있는지 확인

              return (
                <Day
                  key={index}
                  onClick={() => onClickDateDate(day)}
                  className={`div_days ${day.toDateString() === selectedDate.toDateString() ? 'selected' : ''} ${
                    day.getDay() === 0 || day.getDay() === 6 ? 'weekday' : ''
                  } ${
                    day.getMonth() !== startOfMonth.getMonth() ? 'anothermonth' : '' // 이번달에 속하지 않는 날짜 중 주말이 아닌 날에 anothermonth 스타일 적용
                  } ${
                    day.getMonth() !== startOfMonth.getMonth() && (day.getDay() === 0 || day.getDay() === 6)
                      ? 'anothermonthweek'
                      : '' // 이번달에 속하지 않는 주말인 날에 anothermonthweek 스타일 적용
                  }`}
                >
                  {day.getDate()}
                  {getEvents(day)}
                </Day>
              )
            })}
          </Week>,
        )
      }
    }

    return weeks
  }

  return (
    <div>
      <DaysOfWeek>
        {daysOfWeek.map((day, index) => (
          <div key={index}>
            <strong>{day}</strong>
          </div>
        ))}
      </DaysOfWeek>
      <Calendar>{renderMonthlyCalendar()}</Calendar>
    </div>
  )
}
const PlanWrap = styled.div`
  display: flex;
  position: absolute;
  bottom: -5px;
  width: 100%;
  place-content: center;
`

const RestPlanWrap = styled.div`
  display: flex;
  position: absolute;
  right: -4px;
  bottom: -22px;
  width: 100%;
  place-content: center;
`
const RestEvents = styled.div`
  box-sizing: border-box;
  font-size: 9px;
  z-index: 1;
  height: 100%;
  color: black;
  font-weight: normal;
`
const HavePlan = styled.div<{ $bc: string }>`
  box-sizing: border-box;
  font-size: xx-large;

  z-index: 1;

  height: 100%;
  color: ${({ $bc }) => $bc};

  font-weight: normal;
`

const Calendar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-top: 11px;
`

const Week = styled.div`
  box-sizing: border-box;
  place-content: space-between;
  display: flex;
`

const Day = styled.div`
  -webkit-tap-highlight-color: transparent;
  position: relative;
  box-sizing: border-box;
  align-content: center;
  text-align: center;
  width: 36px;
  height: 36px;
  border: none;
  cursor: pointer;

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  color: #1c1c1c;
  // &:hover {
  //   background-color: #f2f2f2;
  // }

  &.selected {
    background-color: #e0f7f1;
    color: #00c398;
    border-radius: 50%;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
  }
  &.weekday {
    color: #2d9fe8;
  }
  &.anothermonth {
    color: #dadada;
  }

  &.anothermonthweek {
    color: #bee5ff;
  }
`

const DaysOfWeek = styled.div`
  display: flex;
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  place-content: space-between;
  div {
    box-sizing: border-box;
    align-content: center;
    text-align: center;
    width: 36px;
    height: 36px;

    strong {
      font-family: 'Pretendard-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #979797;
    }

    &:first-child strong {
      /* 첫 번째 strong 요소에 대한 스타일 */
      color: #2d9fe8;
    }

    &:last-child strong {
      /* 마지막 strong 요소에 대한 스타일 */
      color: #2d9fe8;
    }
  }
`

export default MonthlyCalendar
