import { todoType } from 'src/utils/common/type/type'
import RedCheck from '../../../../assets/images/icon/RedCheck.png'
import YellowCheck from '../../../../assets/images/icon/YellowCheck.png'
import GreenCheck from '../../../../assets/images/icon/GreenCheck.png'
import GreenActiveCheck from '../../../../assets/images/icon/GreenActiveCheck.png'
import RedActiveCheck from '../../../../assets/images/icon/RedActiveCheck.png'
import YellowActiveCheck from '../../../../assets/images/icon/YellowActiveCheck.png'
import styles from '../styles/Plan.module.css'
import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import { useForm } from 'react-hook-form'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { calculateDday } from 'src/utils/common/scripts/common'
import ConfettiExplosion from 'react-confetti-explosion'
import styled from 'styled-components'
import { IoIosCheckmark } from 'react-icons/io'

type PlanType = {
  registerFn?: React.InputHTMLAttributes<HTMLInputElement>
  onClick?: () => void
  // imgSrc: string
  item: todoType
  readOnly: boolean
  isPending: boolean
  getValues: ReturnType<typeof useForm>['getValues']
  setValue: ReturnType<typeof useForm>['setValue']
  onClickDetail?: () => void
}

export default function Plan({
  setValue,
  isPending,
  readOnly,
  getValues,
  item,
  onClick,
  registerFn,
  onClickDetail,
}: PlanType) {
  const [isClicked, setIsClicked] = useState(false)
  const [isExploding, setIsExploding] = useState(false)

  const onClickChip = () => {
    if (readOnly || isPending) return

    setIsClicked(true)
    setTimeout(() => setIsClicked(false), 300) // 0.3초 후 애니메이션 초기화
    onClick?.()
  }

  let styleId = 'div_todo_wrap'
  if (getValues(item.scheduleId.toString())) {
    styleId += ' active'
  }

  useEffect(() => {
    setValue(item.scheduleId.toString(), item.isCompleted)
    setIsExploding(prev => !prev)
  }, [])

  // const ddayDivStyleId = imgSrc

  // const getImgSrc = () => {
  //   if (getValues(item.scheduleId.toString()) && imgSrc === 'red') return RedActiveCheck
  //   if (getValues(item.scheduleId.toString()) && imgSrc === 'yellow') return YellowActiveCheck
  //   if (getValues(item.scheduleId.toString()) && imgSrc === 'green') return GreenActiveCheck
  //   if (imgSrc === 'red') return RedCheck
  //   if (imgSrc === 'yellow') return YellowCheck
  //   if (imgSrc === 'green') return GreenCheck
  //   return RedCheck
  // }

  const textVariants = {
    initial: { scale: 1 },
    clicked: { scale: 1.1, transition: { duration: 0.1 } },
  }

  return (
    <>
      <div className={`${divideStyleIDString(styles, styleId)}`}>
        <Div
          onClick={onClickChip}
          $bc={item.colorType || '#FFA4A4'}
          className={getValues(item.scheduleId.toString()) ? 'checked' : 'unchecked'}
          key={getValues(item.scheduleId.toString()) ? 'checked' : 'unchecked'}
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1.1, opacity: 1 }}
          transition={{ duration: 0.2 }}
        >
          <IoIosCheckmark size="small" color={getValues(item.scheduleId.toString()) ? 'white' : item.colorType} />
        </Div>
        <DDAy
          style={{
            background: item.colorType || '#FFA4A4',
          }}
          onClick={onClickChip}
          initial="initial"
          animate={isClicked ? 'clicked' : 'initial'}
          variants={textVariants}
        >
          D {calculateDday(item.startDateTime)}
        </DDAy>

        <motion.p
          onClick={() => {
            onClickDetail?.()
          }}
          initial="initial"
          animate={isClicked ? 'clicked' : 'initial'}
          variants={textVariants}
        >
          {item.title}
        </motion.p>
      </div>
      {/* {isExploding && (
        <ConfettiExplosion
          width={900}
          style={{
            position: 'absolute',
            left: '50%',
          }}
          duration={2500}
          force={2}
          particleCount={100}
          height={400}
          onComplete={() => {
            setIsExploding(false)
          }}
          colors={['#FFC700', '#FF0000', '#2E3191', '#41BBC7']}
          zIndex={9999}
        />
      )} */}
      <input type="checkbox" hidden {...registerFn} />
    </>
  )
}

const Div = styled(motion.div)<{ $bc: string }>`
  /* Vector */

  width: 18px;
  height: 18px;
  margin-right: 15px;
  border-radius: 50%;

  &.checked {
    border: ${({ $bc }) => `solid 1px ${$bc}`};
    background-color: ${({ $bc }) => $bc};
  }

  &.unchecked {
    border: ${({ $bc }) => `solid 1px ${$bc}`};

    background-color: white;
  }
`

const DDAy = styled(motion.div)`
  text-align: center;
  align-content: center;
  /* Frame 139 */
  margin-right: 7px;

  justify-content: center;
  align-items: center;

  width: 61px;
  height: 30px;

  border-radius: 12px;

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: -0.05em;

  color: #1c1c1c;
`
