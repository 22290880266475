import { SAMPLE_TODO } from 'src/utils/common/constants/constants'
import Plan from './sub/Plan'
import { todoType } from 'src/utils/common/type/type'
import FormMain from 'src/components/common/form/FormMain'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { Skeleton } from '@mui/material'
import TodoContainer from 'src/components/common/container/TodoContainer'
import Todo from './sub/Todo'
import DrawerLayout from 'src/components/common/layout/DrawerLayout'
import DrawerRightLayout from 'src/components/common/layout/DrawerRightLayout'
import DiaryDetailView from '../diary/sub/DiaryDetailView'
import { HiOutlineTrash } from 'react-icons/hi'

import { selectCalendarType, selectedDirayDate } from 'src/utils/common/state/state'
import { useRecoilState } from 'recoil'
import PlanCreate2 from '../plan/PlanCreate2'
import PlanCreate from '../plan/PlanCreate'
import Alert2Modal from 'src/components/common/modal/Alert2Modal'
import FullLoadingSpinner from 'src/components/common/container/FullLoadingSpinner'
import { useChangePlanStts, useDeletePlan, useGetPlanListOfMain } from './hooks/MainHomeCustomHook'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { generateRandomKey } from 'src/utils/common/scripts/common'

export function coloredItems(index: number) {
  const position = index + 1 // 인덱스는 0부터 시작하므로 1을 더해 위치를 맞춥니다.
  let color

  if (position % 3 === 1) {
    color = 'red' // 1, 4, 7, 10, ... 파랑
  } else if (position % 3 === 2) {
    color = 'yellow' // 2, 5, 8, 11, ... 노랑
  } else {
    color = 'green' // 3, 6, 9, 12, ... 초록
  }

  return color
}

export default function MyPlan({ isPending }: { isPending: boolean }) {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [closeInner, setCloseInner] = useState(false)
  const [detailOpen, setDetailOpen] = useState(false)
  const [updateItem, setUpdateItem] = useState<null | todoType>(null)
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDirayDate)
  const [isWeek, setIsWeek] = useRecoilState(selectCalendarType)
  const [openAlert, setOpenAlert] = useState(false)
  const { isErrorDeletePlan, isSuccessDeletePlan, deletePlan, deleting } = useDeletePlan()
  const navigate = useNavigate()
  const { list, isPending: loadingPlan, isLoading, isSuccess, setType, type, refetch } = useGetPlanListOfMain()
  const [updated, setUpdated] = useState(false)

  const onClickDetail = (item: todoType) => {
    setDrawerOpen(true)
    setUpdateItem(item)
  }

  const onFinishUpdatePlan = () => {
    setCloseInner(true)
    setUpdateItem(null)
    setUpdated(true)
  }

  useEffect(() => {
    if (isErrorDeletePlan || isSuccessDeletePlan) {
      onFinishUpdatePlan()
    }
  }, [isSuccessDeletePlan])

  // if (isPending) {
  if (deleting) {
    return (
      <>
        <FullLoadingSpinner isShow={true} />
      </>
    )
  }
  if (isPending || loadingPlan) {
    return (
      <>
        <Skeleton variant="rounded" sx={{ borderRadius: '13px', width: '100%', height: '118px', marginTop: '30px' }} />
      </>
    )
  } else {
    return (
      <>
        <FormMain>
          <>
            <TodoContainer
              onClickTitle={title => {
                setType(title)
              }}
              selected={type} // CURRENT, COMING
              mt="30px"
              title="오늘의 일정"
              subtitle="다가오는 일정"
              onClick={() => {
                setDetailOpen(true)
                setSelectedDate({
                  date: new Date(),
                })
                setIsWeek(true)
                navigate('/plan/create')
              }}
            >
              <>
                {list && ( // list로 다시 변경
                  <MyPlanContent
                    onFinish={() => {
                      refetch()
                    }}
                    readOnly={type === 'today' ? false : true}
                    onClickDetail={onClickDetail}
                    list={list}
                  />
                )}
                <Todo
                  onClick={() => {
                    setDrawerOpen(true)
                  }}
                />
              </>
            </TodoContainer>
          </>
        </FormMain>

        <PlanDeleteAlert
          closeAlert={() => {
            setOpenAlert(false)
          }}
          deletePlant={() => {
            if (updateItem) deletePlan(updateItem?.scheduleId)
          }}
          openProp={openAlert}
        />
        <DrawerLayout
          height="98%"
          title="일정"
          pt={0}
          comp={
            updateItem ? (
              <>
                <HiOutlineTrash
                  onClick={() => {
                    setOpenAlert(true)
                  }}
                  style={{
                    justifySelf: 'self-end',
                    marginRight: '17px',
                  }}
                  color="#979797"
                />
              </>
            ) : undefined
          }
          open={drawerOpen}
          onClose={() => {
            setDrawerOpen(false)
            setCloseInner(false)
            setUpdateItem(null)
            if (updated) {
              refetch()
              setUpdated(false)
            }
          }}
          closeInner={closeInner}
          children={
            <>
              <PlanCreate item={updateItem} onPostFinish={onFinishUpdatePlan} />
            </>
          }
        />
      </>
    )
  }
}

function PlanDeleteAlert({
  openProp,
  deletePlant,
  closeAlert,
}: {
  openProp: boolean
  deletePlant: () => void
  closeAlert: () => void
}) {
  return (
    <>
      <Alert2Modal
        title="일정 삭제"
        description="일정을 삭제하시겠어요?"
        open={openProp}
        afterCloseAlert={() => {
          deletePlant()
          closeAlert()
        }}
        handleClose={() => {
          closeAlert()
        }}
      />
    </>
  )
}

export function MyPlanContent({
  list,
  onClickDetail,
  readOnly,
  onFinish,
}: {
  readOnly: boolean
  list: todoType[]
  onFinish: () => void
  onClickDetail?: (item: todoType) => void
}) {
  const { register, setValue, getValues, watch, handleSubmit } = useForm()
  const [renderToggle, setRenderToggle] = useState(false)
  const { isErrorChangePlan, isSuccessChangePlan, chagePlanStts, changingPlan } = useChangePlanStts()

  const onClickPlan = (item: todoType) => {
    let val = getValues(item.scheduleId.toString()) || false
    val = !val
    setValue(item.scheduleId.toString(), val)
    chagePlanStts(item.scheduleId)
    setRenderToggle(prev => !prev)
  }

  useEffect(() => {
    onFinish()
  }, [isErrorChangePlan])
  useEffect(() => {
    onFinish()
  }, [isSuccessChangePlan])

  if (list.length === 0) {
    return <NoPlanText>일정이 없어요.</NoPlanText>
  }
  return (
    <>
      {list.map((item, index: number) => (
        <Plan
          setValue={setValue}
          isPending={changingPlan}
          readOnly={readOnly}
          onClickDetail={() => {
            onClickDetail?.(item)
          }}
          getValues={getValues}
          registerFn={register(item.scheduleId.toString(), {
            required: false,
          })}
          // imgSrc={coloredItems(index)}
          onClick={() => onClickPlan(item)}
          key={generateRandomKey()}
          item={item}
        />
      ))}
    </>
  )
}

const NoPlanText = styled.div`
  margin-top: 16px;
  /* Caption */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  place-content: center;
  /* Gray 04 */
  color: #a2a3a7;
`
