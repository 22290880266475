import { Swiper, SwiperSlide, useSwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import styled from 'styled-components'
import ImgTag from '../imgTag/ImgTag'
import Character from 'src/components/specific/main/sub/Character'
import { myPlantType } from 'src/utils/common/type/type'
import { Skeleton } from '@mui/material'
import NoData, { NoDataCenter } from '../content/Nodata'
import NoDataContainer, { NoDataIcon } from '../container/NoDataContainer'
import { CustomButton } from '../button/CustomButton'
import { useNavigate } from 'react-router-dom'
import NoDataContainer2 from '../container/NoDataContainer2'
import PlusIcon from '../icon/PlusIcon'

interface DataObj {
  title: JSX.Element | JSX.Element[]
  imgSrc: string
  subText: JSX.Element | JSX.Element[]
  width: string
  height: string
}

interface SwiperMainProps {
  // dataObjArr: DataObj[] | null
  list?: myPlantType[] | null
}

export default function SwiperList({ list }: SwiperMainProps) {
  console.log(list)
  const navigate = useNavigate()

  if (list && list.length > 0) {
    return (
      <SwiperContainer
        pagination={true}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
        slidesPerView={1}
        spaceBetween={10}
      >
        {list.map(item => (
          <SwiperItem key={Math.random()}>
            <CharOuterContainer>
              <CharContainer>
                <Character item={item} />
              </CharContainer>
              <AddPlantBtnDiv $width="100%">
                <AddPlantBtnInnerDiv>
                  <AddPlantLabel>식물 등록</AddPlantLabel>
                  <CustomButton
                    comp={
                      <>
                        <PlusIcon margin="0px 0px 0px 0px" size={20} />
                      </>
                    }
                    margin="12px 0px 16px 0px"
                    styleID="div_add_plant_short"
                    type="button"
                    onClick={() => {
                      navigate('/plant/create')
                    }}
                  />
                </AddPlantBtnInnerDiv>
              </AddPlantBtnDiv>
            </CharOuterContainer>
          </SwiperItem>
        ))}
      </SwiperContainer>
    )
  } else if (list === null) {
    return (
      <SwiperContainer
        pagination={true}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
        slidesPerView={1}
        spaceBetween={10}
      >
        <SwiperItem key={Math.random()}>
          <CharOuterContainer>
            <Skeleton variant="rounded" sx={{ width: '205px', height: '259px', borderRadius: '13px' }} />
            <AddPlantBtnDiv $width="85px">
              <AddPlantBtnInnerDiv>
                <Skeleton variant="rounded" width={50} height={17} sx={{ mb: '12px' }} />
                <Skeleton variant="rounded" width={51} height={36} sx={{ borderRadius: '77px' }} />
              </AddPlantBtnInnerDiv>
            </AddPlantBtnDiv>
          </CharOuterContainer>
        </SwiperItem>
      </SwiperContainer>
    )
  } else {
    return (
      <NoCharContainer>
        <NoDataContainer2
          mt="auto"
          ac="center"
          discription="내 식물을 등록하고 성장을 기록해 보세요."
          title="아직 등록된 식물이 없어요!"
        />
        <CustomButton
          comp={
            <>
              <PlusIcon margin="0px 10px 0px 0px" size={20} />
            </>
          }
          margin="0px 0px 16px 0px"
          styleID="div_add_plant"
          type="button"
          onClick={() => {
            navigate('/plant/create')
          }}
        >
          식물 등록
        </CustomButton>
      </NoCharContainer>
    )
  }
}

export const AddPlantLabel = styled.p`
  /* 식물 등록 */

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: -0.02em;

  /* Black */
  color: #242424;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`

const AddPlantBtnDiv = styled.div<{ $width: string }>`
  width: ${({ $width }) => $width};

  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AddPlantBtnInnerDiv = styled.div`
  text-align: center;
`
const CharOuterContainer = styled.div`
  display: flex;
`
const SwiperContainer = styled(Swiper)`
  width: 100%;
  flex-direction: column;

  display: flex;

  box-sizing: border-box;
  height: 308px;

  * > .swiper-pagination-bullet-active {
    width: 22px;
    height: 7px;

    background: #00c398;
    border-radius: 18px;
  }
`

const SwiperItem = styled(SwiperSlide)`
  box-sizing: border-box;
  width: 291px;
  height: 328px;
  margin-top: 18px;

  flex-direction: column;
  align-items: center;
`

export const NoCharContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;

  width: 100%;

  margin-left: 3px;

  background-color: #fff;

  justify-content: center;

  height: 156px;

  /* White */
  background: #ffffff;
  /* Main Shadow */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 13px;
  align-items: center;
`

const CharContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 3px;

  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;

  background: #ffffff;

  width: 205px;
  height: 259px;

  border-radius: 13px;
`
