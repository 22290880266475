import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'

import inputCheck from '../../../assets/images/icon/InputCheck.png'
import { timerState } from 'src/utils/common/state/state'

export default function Timer({ certConfirm }: { certConfirm?: boolean | null }) {
  const [timerStart, setTimerStart] = useRecoilState(timerState)
  const [remainingTime, setRemainingTime] = useState(0)
  useEffect(() => {
    let interval: NodeJS.Timeout

    if (timerStart) {
      setRemainingTime(300) // 5분은 300초
      interval = setInterval(() => {
        setRemainingTime(prevTime => prevTime - 1)
      }, 1000)
    } else {
      setRemainingTime(0)
    }

    return () => clearInterval(interval)
  }, [timerStart])

  useEffect(() => {
    if (remainingTime <= 0) {
      setTimerStart({
        start: false,
      }) // 타이머 종료
    }
  }, [remainingTime, setTimerStart])

  const minutes = Math.floor(remainingTime / 60)
  const seconds = remainingTime % 60

  return (
    <>
      <div>
        {timerStart.start && (
          <span>
            {' '}
            {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </span>
        )}
        {!timerStart.start && certConfirm && <img src={inputCheck} alt="확인" />}
      </div>
    </>
  )
}
