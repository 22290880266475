import { Skeleton } from '@mui/material'
import LoadingContainer from 'src/components/common/container/LoadingContainer'
import { SALE_STTS } from 'src/utils/common/constants/constants'
import { getPostSttsImgSize } from 'src/utils/common/scripts/common'
import styled from 'styled-components'

export default function PlantInfoName({
  stts,
  name,
  scienName,
  iconComp,
}: {
  stts?: string
  name: string | null
  scienName: string | null
  iconComp?: React.ReactNode
}) {
  let nameComp = name ? <h1>{name}</h1> : <></>
  let sttsImg = <></>
  if (stts) {
    const imgNm = SALE_STTS[stts]?.imgName || '' // Access object using variable stts
    const sttsNm = SALE_STTS[stts]?.name || ''
    sttsImg = <img style={getPostSttsImgSize(stts)} src={require(`/src/assets/images/post/${imgNm}`)} alt={sttsNm} />
    if (stts === 'SOLD' && name) {
      nameComp = <h1 style={{ color: '#979797' }}>{name}</h1>
    }
  }

  return (
    <NameDiv>
      {name ? (
        <NameInner>
          {sttsImg}
          {nameComp}
        </NameInner>
      ) : (
        <Skeleton variant="rounded" height={18} width={83} />
      )}

      {scienName ? (
        <SInner>
          <h2>{scienName}</h2>
          {iconComp}
        </SInner>
      ) : (
        <Skeleton variant="rounded" sx={{ mt: '8px' }} height={18} width={105} />
      )}
    </NameDiv>
  )
}

const NameInner = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 6px;
  }
`

const SInner = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
  justify-content: space-between;
`

const NameDiv = styled.div`
  h1 {
    /* 큰극락조화 */

    font-family: 'Pretendard-Regular';

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */

    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }

  h2 {
    /* Strelitzia nicolai */

    /* Body 01-R */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    letter-spacing: -0.02em;

    /* Gray 04 */
    color: #a2a3a7;
  }
`
