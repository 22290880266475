import styled from 'styled-components'
import DotButton from '../button/DotButton'
import DotButtonTwo from '../button/DotButtonTwo'
import LoadingContainer from '../container/LoadingContainer'
import { Skeleton } from '@mui/material'

export default function DetailViewLabelLayout({
  children,
  menu = false,
  titleClassNm,
  title,
  marginTop = '0',
  marginBottom = '0',
  h2MarginBottom = '0',
  padding = '0 0 0 0',
  comp,
}: {
  titleClassNm?: string
  children: React.ReactNode
  title: string | null
  menu?: boolean
  marginTop?: string
  marginBottom?: string
  h2MarginBottom?: string
  padding?: string
  comp?: React.ReactNode
}) {
  return (
    <Label $padding={padding} $h2marginbottom={h2MarginBottom} $marginbottom={marginBottom} $margintop={marginTop}>
      <MenuContainer>
        {title ? (
          <h1 className={titleClassNm ? titleClassNm : ''}>{title}</h1>
        ) : (
          <Skeleton variant="rounded" sx={{ mb: `${h2MarginBottom}` }} height={20} width={74} />
        )}
        {comp}
      </MenuContainer>
      {children}
    </Label>
  )
}

const Label = styled.div<{ $padding: string; $margintop: string; $marginbottom: string; $h2marginbottom: string }>`
  margin-top: ${({ $margintop }) => $margintop};
  margin-bottom: ${({ $marginbottom }) => $marginbottom};
  padding: ${({ $padding }) => $padding};

  h1 {
    margin-bottom: ${({ $h2marginbottom }) => $h2marginbottom};
    font-family: 'Pretendard-Regular';
    /* 꼼삐 */

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: -0.02em;

    /* Black */
    color: #242424;

    &.plant {
      /* Sub-title */
      font-family: 'Pretendard-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      /* identical to box height, or 156% */
      letter-spacing: -0.02em;

      /* Black */
      color: #242424;
    }
  }
`

const MenuContainer = styled.div`
  display: flex;
  place-content: space-between;
`
