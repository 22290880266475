import styled from 'styled-components'
import Magnifying from '../../../assets/images/icon/Magnifying.png'

export default function MagnifyingIcon() {
  return (
    <>
      <Img alt="돋보기" src={Magnifying} />
    </>
  )
}

const Img = styled.img`
  /* Group 305 */

  width: 18px;
  height: 23.74px;

  transform: rotate(-45deg);
  &:hover {
    cursor: pointer;
  }
`
