import styled from 'styled-components'

export default function NoDataContainer({
  mt = '50px',
  title,
  discription,
  ac = 'unset',
  mb = '0px',
}: {
  mt?: string
  mb?: string
  ac?: string
  title: string
  discription: string
}) {
  return (
    <Wrap $ac={ac}>
      <InnerWrap>
        <NoDataIcon mt={mt} />
      </InnerWrap>
      <InnerWrap>
        <Title>{title}</Title>
      </InnerWrap>
      <InnerWrap>
        <Disc $mb={mb}>{discription}</Disc>
      </InnerWrap>
    </Wrap>
  )
}

export function NoDataIcon({ mt, mb = '0px' }: { mt: string; mb?: string }) {
  return (
    <IconWrap $mt={mt} $mb={mb}>
      <Icon />
      <Icon />
      <Icon />
    </IconWrap>
  )
}
const Disc = styled.h3<{ $mb: string }>`
  font-family: 'Pretendard-Regular';
  margin-bottom: ${({ $mb }) => $mb};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  letter-spacing: -0.05em;

  color: #1c1c1c;
`
const Title = styled.h2`
  margin-top: 75px;
  margin-bottom: 9px;
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.05em;

  color: #1c1c1c;
`
const InnerWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Wrap = styled.div<{ $ac: string }>`
  width: 100%;
  height: 100%;

  align-content: ${({ $ac }) => $ac};
`
const IconWrap = styled.div<{ $mt: string; $mb: string }>`
  margin-top: ${({ $mt }) => $mt};
  margin-bottom: ${({ $mb }) => $mb};

  display: flex;
  width: 90px;
  place-content: space-between;
  height: 10px;
`
const Icon = styled.div`
  /* Ellipse 91 */

  width: 10px;
  height: 10px;

  background: #00c398;
  border-radius: 50%;
`
