/*
  작성자 : 김효은
  작성일 : 2024-05-04
*/

import { validateCheckType } from '../type/type'
import { alertState } from '../state/state'
import { useRecoilState } from 'recoil'
import { validateNullCheck, validateRegExpCheck } from '../scripts/validateCheck'
import { useEffect, useRef, useState } from 'react'
import { mergeDateAndTime, requestFullScreen } from '../scripts/common'
import { redirect, useLocation, useNavigate } from 'react-router-dom'
import { isObject } from '../scripts/checkType'
import { toast } from 'react-toastify'

export const useCheckCurrentPageIsLoginPage = () => {
  const location = useLocation()
  const path = location.pathname
  let notLoginPage = true
  if (path === '/' || path === '/user/create' || path === '/user/find/id' || path === '/user/find/pwd') {
    notLoginPage = false
  }
  return notLoginPage
}

/*
  response data 토큰 만료인지 검사 
*/
export function useCheckTokenValidity() {
  const navigate = useNavigate()
  const checkData = (error: Error) => {
    if (error.message === '토큰이 만료 되었습니다.') {
      toast.error('다시 로그인해주세요')
      navigate('/')
    }
  }

  return { checkData }
}

/*
  전체화면 진입
*/
export const useEnterFullScreenMode = () => {
  const [alert, setAlert] = useRecoilState(alertState)
  function enterFullScreenMode() {
    requestFullScreen()

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

    if (isIOS) {
      setAlert({
        title: 'IOS 기기',
        discription: 'Safari > [홈화면에 추가]를 통해 앱처럼 이용하세요!',
        showAlert: true,
      })
    }
  }

  return {
    enterFullScreenMode,
  }
}

/*
  필수값 유효 확인 
*/
export const useValidCheckModal = () => {
  const [alert, setAlert] = useRecoilState(alertState)
  const [focus, setFocus] = useState('')

  function checkValidation(validCheck: validateCheckType, title: string, discription: string) {
    if (!validCheck.isValid) {
      if (validCheck.unValidKey.length) {
        const name = validCheck.unValidName
        setAlert({
          title: name + title,
          discription: name + discription,
          showAlert: true,
        })
        setFocus(validCheck.unValidKey)
      }
    }
    return validCheck.isValid
  }

  // 필수 값 null 체크
  function nullCheck(checkObj: {
    targetObj: { [key: string]: string | number }
    nameArr: string[]
    title: string
    discription: string
  }) {
    const validCheck: validateCheckType = validateNullCheck(checkObj.targetObj, checkObj.nameArr)
    const isValid = checkValidation(validCheck, checkObj.title, checkObj.discription)
    return isValid
  }

  function regExpCheck(checkObj: {
    targetObj: { [key: string]: string }
    nameArr: string[]
    regExpArr: string[]
    title: string
    discription: string
  }) {
    const validCheck: validateCheckType = validateRegExpCheck(checkObj.targetObj, checkObj.nameArr, checkObj.regExpArr)
    const isValid = checkValidation(validCheck, checkObj.title, checkObj.discription)
    return isValid
  }

  function initFocus() {
    setFocus('')
  }

  return {
    checkValidation,
    nullCheck,
    focus,
    initFocus,
    regExpCheck,
  }
}

export const test = () => {
  return true
}

/*
  현재 경로의 마지막 쿼리 가져오기 
*/
export const useCurrentLastQuery = () => {
  const location = useLocation()
  // 현재 URL 경로를 가져옴

  const currentPath = location.pathname
  const currentId = currentPath.split('/').pop() // 배열에서 마지막 요소 추출

  return {
    currentPath,
    currentId,
  }
}

/*
  설명 : 폼 공통 유효성 확인
*/
export function useValidationCheck() {
  const [selectedKey, setSelectedKey] = useState<{ [key: string]: string } | undefined>()
  const [formAlert, setFormAlert] = useState<{ [key: string]: string } | undefined>()
  const [confirmed, setConfirmed] = useState(false)
  const [isValidObj, setIsValidObj] = useState<{ [key: string]: boolean | undefined } | undefined>()
  const [validCnt, setValidCnt] = useState(0)

  useEffect(() => {
    if (isValidObj) {
      const values = Object.values(isValidObj)
      if (values.length < validCnt) {
        setConfirmed(false)
        return
      }

      const allTrue = values.every(value => value === true)
      if (allTrue) {
        setConfirmed(true)
      } else {
        setConfirmed(false)
      }
    }
  }, [isValidObj])

  const updateIsValid = (key: string, val: boolean | undefined) => {
    setIsValidObj(prev => {
      let newVal = { ...prev }
      newVal = {
        ...prev,
        [key]: val,
      }
      return newVal
    })
  }

  const setTrue = (key: string) => {
    updateFormAlert(key, '')
    updateIsValid(key, true)
  }
  const setFalse = (key: string, text: string) => {
    updateFormAlert(key, text)
    updateIsValid(key, false)
  }

  const updateFormAlert = (key: string, val: string) => {
    setFormAlert(prev => {
      if (prev) {
        let newVal = { ...prev }
        newVal = {
          ...prev,
          [key]: val,
        }
        return newVal
      } else {
        return {
          [key]: val,
        }
      }
    })
  }

  /* pot,spot에서 선택한 key */
  const updateSelectedKey = (key: string, val: string) => {
    setSelectedKey(prev => {
      let newVal = { ...prev }
      newVal = {
        ...prev,
        [key]: val,
      }
      return newVal
    })
  }

  // 필수 값 null 체크
  function nullCheck(checkObj: {
    targetObj: { [key: string]: string | number }
    nameArr: string[]

    discription: string
  }) {
    const validCheck: validateCheckType = validateNullCheck(checkObj.targetObj, checkObj.nameArr)
    const isValid = checkValidation(validCheck, checkObj.discription)
    return isValid
  }

  function checkValidation(validCheck: validateCheckType, discription: string) {
    if (!validCheck.isValid) {
      if (validCheck.unValidKey.length) {
        const name = validCheck.unValidName
        setFalse(name, discription)
      }
    }
    return validCheck.isValid
  }

  /*
    인풋값 검사
  */
  const checkInputText = (valueObj: { value: string; name: string; label: string; regExp: string }) => {
    const newValue = valueObj.value
    let isValid = nullCheck({
      targetObj: {
        newValue,
      },
      nameArr: [valueObj.name],
      discription: valueObj.label + '을 입력해주세요',
    })
    if (!isValid) return isValid
    isValid = regExpCheck({
      targetObj: {
        newValue,
      },
      nameArr: [valueObj.name],
      regExpArr: [valueObj.regExp],
      discription: valueObj.label + '을 정확히 입력해주세요',
    })
    if (isValid) {
      setTrue(valueObj.name)
    }
    return isValid
  }

  // start 랑 end 기간 정합성 체크
  const checkDttmPeriodValidate = (dateObj: {
    name: string
    startDt: Date
    startTm: string
    endDt: Date
    endTm: string
  }) => {
    const castObj = mergeDateAndTime({
      endDt: dateObj.endDt,
      endTm: dateObj.endTm,
      startDt: dateObj.startDt,
      startTm: dateObj.startTm,
    })
    if (new Date(castObj.startDateTime) > new Date(castObj.endDateTime)) {
      // setFalse(dateObj.name, '시작 날자가 종료 날짜보다 늦습니다.')
      return false
    } else {
      // setTrue(dateObj.name)
      return true
    }
  }

  function checkTextAreaWithMaxAndReg(valueObj: {
    regExp: string

    value: string
    name: string
    label: string
  }) {
    const newValue = valueObj.value
    let isValid = true

    isValid = regExpCheck({
      targetObj: {
        newValue,
      },
      nameArr: [valueObj.name],
      regExpArr: [valueObj.regExp],
      discription: valueObj.label + '을 정확히 입력해주세요',
    })

    if (!isValid) return isValid

    if (isValid) {
      setTrue(valueObj.name)
    }
    return isValid
  }

  /*
    체크박스 검사
  */
  function checkCheckBox(obj: { [key: string]: boolean }, name: string, label: string) {
    const values = Object.values(obj)
    let isValid = false
    let keyVal = ''

    for (const key in obj) {
      const val = obj[key]
      if (val === true) {
        keyVal = key
        isValid = true
        break
      }
    }

    console.log(isValid)
    if (!isValid) {
      setFalse(name, label + ' 을/를 선택해주세요')
      updateSelectedKey(name, '')
    } else {
      setTrue(name)
      updateSelectedKey(name, keyVal)
    }

    return isValid
  }
  /*
    textArea 검사
  */

  function checkTextArea(valueObj: { value: string; name: string; label: string }) {
    const newValue = valueObj.value
    const isValid = nullCheck({
      targetObj: {
        newValue,
      },
      nameArr: [valueObj.name],
      discription: valueObj.label + '을 입력해주세요',
    })
    if (!isValid) return isValid

    if (isValid) {
      setTrue(valueObj.name)
    }
    return isValid
  }

  function checkTextAreaWithMax(valueObj: { max: number; value: string; name: string; label: string }) {
    const newValue = valueObj.value
    let isValid = true

    if (newValue.length >= valueObj.max) {
      isValid = false
      setFalse(valueObj.name, valueObj.label + ` 이 ${valueObj.max}자를 초과하였습니다.`)
    }

    if (!isValid) return isValid

    if (isValid) {
      setTrue(valueObj.name)
    }
    return isValid
  }

  function checkDualDttm(valueObj: {
    start: Date | string | null
    end: Date | string | null
    name: string
    label: string
  }) {
    const discription = valueObj.label + '을 입력해주세요'
    let isValid = false

    if (valueObj.start === null || valueObj.end === null) {
      setFalse(valueObj.name, discription)
      isValid = false
    } else if (valueObj.start !== null && valueObj.end !== null) {
      setTrue(valueObj.name)
      isValid = true
    }

    return isValid
  }

  function regExpCheck(checkObj: {
    targetObj: { [key: string]: string }
    nameArr: string[]
    regExpArr: string[]
    discription: string
  }) {
    const validCheck: validateCheckType = validateRegExpCheck(checkObj.targetObj, checkObj.nameArr, checkObj.regExpArr)
    const isValid = checkValidation(validCheck, checkObj.discription)
    return isValid
  }
  function checkFileCnt(valueObj: { value: number; name: string; label: string }) {
    if (valueObj.value === 0) {
      setFalse(valueObj.name, valueObj.label + '을 한장 이상 첨부해주세요')
      return false
    } else {
      setTrue(valueObj.name)
      return true
    }
  }

  function checkHashTag(valueObj: { value: string[]; name: string; label: string }) {
    if (valueObj.value.length === 0) {
      setFalse(valueObj.name, valueObj.label + '를 한개 이상 추가해주세요')
      return false
    } else {
      setTrue(valueObj.name)
      return true
    }
  }

  return {
    selectedKey,
    isValidObj,
    confirmed,
    formAlert,
    nullCheck,
    regExpCheck,
    setTrue,
    setFalse,
    checkInputText,
    checkCheckBox,
    checkTextArea,
    checkFileCnt,
    checkTextAreaWithMax,
    checkTextAreaWithMaxAndReg,
    setValidCnt,
    checkDualDttm,
    checkHashTag,
    checkDttmPeriodValidate,
  }
}
export function useRefFocusEffect<T extends HTMLElement>(onFocusCallback: () => void, deps?: React.DependencyList) {
  const ref = useRef<T>(null)

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              onFocusCallback() // 이 부분에서 changePage 함수 호출로 변경 필요
            }
          })
        },
        {
          threshold: 0.5, // Intersection Observer threshold 수정
        },
      )

      observer.observe(ref.current)

      return () => {
        if (ref.current) observer.unobserve(ref.current)
      }
    }
  }, deps) // deps 배열을 useEffect의 의존성 배열로 설정

  return { elementRef: ref }
}
