import FormMain from 'src/components/common/form/FormMain'
import ImgTag from 'src/components/common/imgTag/ImgTag'
import styled, { keyframes } from 'styled-components'
import closeBtn from '../../../assets/images/icon/closeBtn.png'
import { useLoignDrawer } from 'src/utils/store/login/useLoginDrawer'
import LoginForm from './LoginForm'
import LoginOAuth from './LoginOAuth'
import { motion } from 'framer-motion'
import { useEffect } from 'react'

export default function LoginDrawer() {
  const { isOpen, seIsOpenFalse, setIsOpenTrue } = useLoignDrawer()
  useEffect(() => {
    setIsOpenTrue()
  }, [])
  return (
    <DrawerContainer
      initial={{ y: '100%' }}
      animate={{ y: isOpen ? '0' : '100%' }}
      exit={{ y: '100%' }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      <Drawer>
        <LoginDrawerContainer>
          <CloseBtn onClick={seIsOpenFalse}>
            <img src={closeBtn} alt="닫기버튼" />
          </CloseBtn>
          <LoginTitle>로그인</LoginTitle>
          <FormMain>
            <LoginForm />
            <LoginOAuth />
          </FormMain>
        </LoginDrawerContainer>
      </Drawer>
    </DrawerContainer>
  )
}

const DrawerContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  right: 0;
  left: auto;
  z-index: 99;
  overflow: hidden;
`

const Drawer = styled(motion.div)`
  width: 100%;
  z-index: 999;
  position: absolute;
  bottom: 0;
  background: white;
  border-radius: 20px 20px 0 0;
`
const LoginDrawerContainer = styled.div`
  padding-top: 29px;
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 45px;
`

const LoginTitle = styled.header`
  font-family: Pretendard-Regular;

  margin-bottom: 48px;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.05em;

  color: #1c1c1c;
`
const CloseBtn = styled.span`
  float: right;
  cursor: pointer;
`
