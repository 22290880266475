import styled from 'styled-components'
import LoadingContainer from '../container/LoadingContainer'
import { formatNumber } from 'src/utils/common/scripts/common'

type GreenImgInfo = {
  item: { name: string; price: string | number; stts: string } | null
}

export default function GreenImgInfo({ item }: GreenImgInfo) {
  if (item) {
    let priceComp = <span>{formatNumber(item.price)}원</span>
    if (item.stts === 'FREE') {
      priceComp = <Free>무료나눔</Free>
    }
    return (
      <>
        <ImgInfo>
          <p>{item.name.length > 19 ? `${item.name.slice(0, 19)}...` : item.name}</p>
          {priceComp}
        </ImgInfo>
      </>
    )
  } else {
    return (
      <>
        <ImgInfo>
          <LoadingContainer width="59px" height="20px" />
          <LoadingContainer margin="6px 0px 0px 0px" width="72px" height="18px" />
        </ImgInfo>
      </>
    )
  }
}

const ImgInfo = styled.div`
  p {
    margin-bottom: 6px;

    /* 다육이 화분 4종 */

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.05em;

    color: #1c1c1c;
  }
  span {
    /* 10,000원 */

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #1c1c1c;
  }
`

const Free = styled.div`
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #00c398;
`
