import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import styled from 'styled-components'

export default function PrevIcon({
  children,
  width = '8px',
  height = '14px',
  onClick,
}: {
  onClick?: () => void
  children: React.ReactNode
  width?: string
  height?: string
}) {
  return (
    <Button onClick={onClick} width={width} height={height} type="button">
      {children}
    </Button>
  )
}

const Button = styled.button<{ height: string; width: string }>`
  align-items: center;
  background-color: unset;
  border: none;
  -webkit-tap-highlight-color: transparent;
  justify-content: center;
  vertical-align: middle;
  display: flex;
  &:hover {
    cursor: pointer;
  }

  &:active {
    outline: none; /* Remove the focus outline */
    box-shadow: none; /* Remove any box shadow */
  }

  &:focus {
    outline: none; /* Remove the focus outline */
    box-shadow: none; /* Remove any box shadow */
  }

  //   height: ${({ height }) => height};
  //   width: ${({ width }) => width};
`
