import Circle from './sub/Circle'
import Drawer from './sub/Drawer'
import MenuTab from './sub/MenuTab'

type MuiMainProps = {
  children: JSX.Element | JSX.Element[]
}

export default function MuiMain({ children }: MuiMainProps) {
  return <div>{children}</div>
}

MuiMain.MenuTab = MenuTab
MuiMain.Drawer = Drawer
MuiMain.CircularProgress = Circle
