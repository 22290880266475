import styled from 'styled-components'

export default function NoData({ discription }: { discription: string }) {
  return (
    <NoDataComp>
      <p>{discription}</p>
    </NoDataComp>
  )
}

export function NoDataCenter({ discription }: { discription: string }) {
  return (
    <NoDataCenterCom>
      <p>{discription}</p>
    </NoDataCenterCom>
  )
}

const NoDataCenterCom = styled.div`
  display: flex;
  place-content: center;
  padding-top: 0px;
  p {
    /* 무럭무럭 */
    justify-content: center;
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;

    color: #1c1c1c;
  }
`

const NoDataComp = styled.div`
  display: flex;
  place-content: center;
  p {
    /* 무럭무럭 */

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;

    color: #1c1c1c;
  }
`
