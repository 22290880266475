import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'

import MuiMain from '../../components/common/mui/MuiMain'
import MainHomeContent from 'src/components/specific/main/MainHomeContent'
import { redirect } from 'react-router-dom'

import { UseUserInfo } from 'src/components/specific/main/hooks/MainHomeCustomHook'

export default function MainHome() {
  const { profile, address } = UseUserInfo()

  return (
    <>
      <ContainerLayout>
        <MuiMain>
          <Content
            component={
              <MainHomeContent
                loc={
                  profile && address && profile.data.location
                    ? {
                        loc: profile.data.location,
                        address: address,
                      }
                    : null
                }
                nickname={profile ? profile.data.nickname : null}
              />
            }
          />
        </MuiMain>
      </ContainerLayout>
    </>
  )
}

// Loader 함수
export const loader = async () => {
  console.log('로그인 상태 확인')
  const accessToken = localStorage.getItem('accessToken')

  if (!accessToken) {
    throw redirect('/')
  }
  console.log('로그인 됨')
  // queryClient.invalidateQueries({ queryKey: ['my-info'] })

  return null // 필요에 따라 데이터를 반환
}
