import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import CollectionImgInfo from 'src/components/common/content/CollectionImgInfo'
import AddCollectionIcon from 'src/components/common/icon/AddCollectionIcon'
import FormModal from 'src/components/common/modal/FormModal'
import { COLLECTION_LIST_SAMPLE } from 'src/utils/common/constants/constants'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import { formModalState } from 'src/utils/common/state/state'
import { CollectionListType, CollectionType, collectionListSampleType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import { useCollectionInfo } from '../hook/CollectionListCustomHook'
import CollectionListMainPending from './CollectionListMainPending'
import sample from '../../../../assets/images/collection/collectionHeaderImg.png'
import { toast } from 'react-toastify'
import NoDataContainer from 'src/components/common/container/NoDataContainer'
import { CustomButton } from 'src/components/common/button/CustomButton'
import DrawerLayout from 'src/components/common/layout/DrawerLayout'
import CollectionAdd from '../sub/CollectionAdd'
import { AddPlantBtnInnerDiv, AddPlantLabel } from 'src/components/common/swiper/SwipeList'
import PlusIcon from 'src/components/common/icon/PlusIcon'
import NoDataContainer2 from 'src/components/common/container/NoDataContainer2'

/*
    파일명 : CollectionListMain.tsx
    설명 : 싹싹도감 > 내 도감 > 리스트
    작성일 : 2024-06-17
*/
export default function CollectionListMain() {
  const navigate = useNavigate()
  const [alert, setAlert] = useRecoilState(formModalState)
  const [firstRender, setFirstRender] = useState(false)
  const { isSuccess, collectionList, isPending, refetch } = useCollectionInfo()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [closeInner, setCloseInner] = useState(false)
  useEffect(() => {
    setFirstRender(true)
  }, [])

  if (isPending) {
    return <CollectionListMainPending />
  } else if (
    collectionList &&
    collectionList?.data &&
    collectionList.data.myEncyclopediaList &&
    collectionList.data.myEncyclopediaList.length !== 0
  ) {
    return (
      <>
        <Wrap>
          {collectionList?.data.myEncyclopediaList.map((item: CollectionType, index) => {
            return (
              <React.Fragment key={generateRandomKey()}>
                <div
                  onClick={() => {
                    navigate(`${item.id}`, {
                      state: {
                        name: item.title,
                        qnt: item.plantCount,
                      },
                    })
                  }}
                >
                  <ImgDiv src={item.coverImageUrl || sample} />
                  {/* <ImgDiv src={item.coverImageUrl || sample} alt="이미지" /> */}
                  <CollectionImgInfo
                    item={{
                      name: item.title,
                      cnt: item.plantCount,
                    }}
                  />
                </div>
              </React.Fragment>
            )
          })}

          <>
            <ImgAdd>
              <div>
                <AddPlantLabel>도감 등록</AddPlantLabel>
                <CustomButton
                  comp={
                    <>
                      <PlusIcon margin="0px 0px 0px 0px" size={20} />
                    </>
                  }
                  margin="12px 0px 16px 0px"
                  styleID="div_add_plant_short"
                  type="button"
                  onClick={
                    firstRender
                      ? () => {
                          if (
                            collectionList &&
                            collectionList.data &&
                            collectionList.data.myEncyclopediaList &&
                            collectionList?.data.myEncyclopediaList.length >= 5
                          ) {
                            toast.error('더 이상 도감을 생성할 수 없습니다')
                            return
                          }
                          setDrawerOpen(true)
                        }
                      : () => {}
                  }
                />
              </div>
            </ImgAdd>
          </>
        </Wrap>
        <DrawerLayout
          title="도감 추가"
          height="60%"
          pb={0}
          pt={0}
          open={drawerOpen}
          onClose={() => {
            setDrawerOpen(false)
            setCloseInner(false)
          }}
          closeInner={closeInner}
          children={
            <>
              <CollectionAdd
                onFinish={() => {
                  refetch()
                  setCloseInner(true)
                }}
              />
            </>
          }
        />
      </>
    )
  } else {
    return (
      <>
        <NoColl>
          <Inner>
            <NoDataContainer2
              mt="0px"
              mb="32px"
              ac="center"
              discription="관심있는 식물을 도감에 저장해 보세요."
              title="아직 등록된 도감이 없어요!"
            />
            <ButtonWrap>
              <CustomButton
                comp={
                  <>
                    <PlusIcon margin="0px 10px 0px 0px" size={20} />
                  </>
                }
                margin="0px 0px 16px 0px"
                styleID="div_add_plant"
                type="button"
                onClick={() => {
                  setDrawerOpen(true)
                }}
              >
                도감 등록
              </CustomButton>
            </ButtonWrap>
          </Inner>
        </NoColl>

        <DrawerLayout
          title="도감 추가"
          height="60%"
          pb={0}
          pt={0}
          open={drawerOpen}
          onClose={() => {
            setDrawerOpen(false)
            setCloseInner(false)
          }}
          closeInner={closeInner}
          children={
            <>
              <CollectionAdd
                onFinish={() => {
                  setCloseInner(true)
                  refetch()
                }}
              />
            </>
          }
        />
      </>
    )
  }
}
const ButtonWrap = styled.div`
  width: 100%;

  display: flex;
  place-content: center;
`
const Inner = styled.div``
const NoColl = styled.div`
  max-height: 400px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`

const Wrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 48%);
  gap: 23px 12px; /* 위아래는 12px, 양옆은 7px */
`

const Img = styled.img`
  box-sizing: border-box;

  width: 100%;
  aspect-ratio: 108.18 / 100;
  border: 3px solid #ffffff;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  margin-bottom: 9px;
  border-radius: 14px;
`

const ImgDiv = styled.div<{ src: string }>`
  background-repeat: no-repeat; /* 이미지 반복 없음 */
  background-position: center; /* 이미지를 가운데 정렬 */
  background-size: cover;
  background-image: ${({ src }) => `url(${src})`}; /* src 속성으로부터 이미지 URL을 받아와 배경 이미지로 설정 */
  box-sizing: border-box;

  width: 100%;
  aspect-ratio: 108.18 / 100;
  border: 3px solid #ffffff;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  margin-bottom: 9px;
  border-radius: 14px;
`

const ImgAdd = styled.div`
  /* Rectangle 140 */
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  aspect-ratio: 108.18 / 100;

  border-radius: 14px;
  text-align: center;
  align-content: center;
  &:hover {
    cursor: pointer;
  }
`
