/*eslint-disable */

import PlantInfo from 'src/components/specific/plant/create/PlantInfo'
import SelectChar from 'src/components/specific/plant/create/SelectChar'
import SelectPlant from 'src/components/specific/plant/create/SelectPlant'
import { create } from 'zustand'
import RegNewPlant from 'src/components/specific/plant/create/RegNewPlant'
import RegSchedule from 'src/components/specific/plant/create/RegSchedule'

export const usePlantPageStatus = create((set, get) => ({
  pageStatus: 'selectPlant', // 현재 페이지 상태 값, 이 값에 따라 랜더링 컴포넌트 달라짐
  pageValue: {
    // PlantMain.tsx로 들어가는 값들
    progressValue: null, // progress bar 값
    headerText: null, // 상단 텍스트
    content: null, // 보여줄 컴포넌트
  },
  setPageStatus: status => {
    set({ pageStatus: status })
  },
  // 현재 pageStatus 상태 값에 따른 랜더링 분기 처리
  setPageValue: () => {
    switch (get().pageStatus) {
      case 'selectPlant': // 첫번째 페이지
        return set({
          pageValue: {
            progressValue: 1,
            headerText: (
              <>
                <div>저는 어떤 식물인가요?</div>
              </>
            ),
            content: <SelectPlant />,
          },
        })
      case 'selectChar': // 두번째 페이지
        return set({
          pageValue: {
            progressValue: 2,
            headerText: (
              <>
                <div>나에게 어울리는</div>
                <div>캐릭터를 선택해주세요</div>
              </>
            ),
            content: <SelectChar />,
          },
        })
      case 'plantInfo':
        return set({
          pageValue: {
            progressValue: 3,
            headerText: (
              <>
                <div>마지막으로</div>
                <div>제 정보를 입력해 주세요!</div>
              </>
            ),
            content: <PlantInfo />,
          },
        })
      case 'regSchedule':
        return set({
          pageValue: {
            progressValue: 90,
            headerText: (
              <>
                <div>마지막이에요.</div>
                <div>일정을 등록해 드릴게요!</div>
              </>
            ),
            content: <RegSchedule />,
          },
        })
      case 'regNewPlant':
        return set({
          pageValue: {
            progressValue: 1,
            headerText: (
              <>
                <div>등록하고 싶은 식물을</div>
                <div>요청해주세요!</div>
              </>
            ),
            content: <RegNewPlant />,
          },
        })
    }
  },
}))
