import styles from './styles/MainHomeHeader.module.css'
import Comppi from '../../../assets/images/icon/comppi.png'
import Comppi2 from '../../../assets/images/icon/comppi3.png'
import { Skeleton } from '@mui/material'
import styled from 'styled-components'
import { VscBell } from 'react-icons/vsc'

export default function MainHomeHeader({ isPending, userName }: { isPending: boolean; userName: string | null }) {
  if (isPending) {
    return (
      <>
        <div className={styles.div_container}>
          <Skeleton variant="rounded" height={20} width={50} />
        </div>
        <div className={styles.div_title_wrap}>
          <div className={styles.div_title}>
            <Skeleton variant="rounded" height={20} width={130} />
            <Skeleton variant="rounded" height={27} width={135} sx={{ marginTop: '3px' }} />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className={styles.div_container}>
          <IconWrap>
            <IconImg2 src={Comppi2} alt="꼼삐 아이콘" />
          </IconWrap>
          {/* <div>
            <VscBell onClick={() => {}} size={23} />
          </div> */}
        </div>
        <LevelWrap>
          <UserNm>{userName} 님은</UserNm>
          <LevelTitle>
            <span>초보농부</span> 입니다.
          </LevelTitle>
        </LevelWrap>
        {/* <div className={styles.div_title_wrap}>
          <div className={styles.div_title}>
            <h2>{userName} 님은</h2>
            <h3>초보농부입니다.</h3>
          </div>
          <div className={styles.div_forward_icon}>
            <></>
            <img alt="마이페이지" src={ForwardIcon} />
          </div>
        </div> */}
      </>
    )
  }
}

const LevelWrap = styled.div`
  box-sizing: border-box;

  align-items: center;

  width: 100%;
  height: 75px;

  background: #ffffff;

  border: 1px solid #7edec3;
  border-radius: 13px;
  align-content: center;
  padding-left: 16px;
`

const UserNm = styled.h2`
  /* 박꼼삐 님은 */

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */
  letter-spacing: -0.02em;

  color: #1c1c1c;
`

const LevelTitle = styled.h3`
  /* 초보 농부 입니다. */

  /* Title 02 */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */
  letter-spacing: -0.02em;

  /* Primary */

  span {
    color: #00c398;
  }
`

const IconWrap = styled.div`
  display: flex;
`

const IconImg = styled.img``

const IconImg2 = styled.img`
  margin-left: 12px;
  width: 83px;
  height: 26px;
`
