import { FormGroup } from '@mui/material'
import { IOSSwitch } from './ToggleSample'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'

export default function ToggleField({
  registerFn,
  setValue,
  getValues,
  name,
  register,
  defaultVal,
  disabled = false,
}: {
  register: ReturnType<typeof useForm>['register']
  setValue: ReturnType<typeof useForm>['setValue']
  registerFn?: React.InputHTMLAttributes<HTMLInputElement>
  getValues: ReturnType<typeof useForm>['getValues']
  name: string
  defaultVal: boolean
  disabled?: boolean
}) {
  const [defaultC, setDefaultC] = useState(defaultVal)

  useEffect(() => {
    register(name)
    setValue(name, defaultVal)
    // setDefaultC(defaultVal)
  }, [])

  return (
    <FormGroup>
      <IOSSwitch
        disabled={disabled}
        defaultChecked={defaultC}
        onClick={() => {
          setValue(name, !getValues(name))
        }}
      />
      <input type="checkbox" hidden {...registerFn} />
    </FormGroup>
  )
}
