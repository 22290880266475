import { useEffect } from 'react'
import { useValidationCheck } from 'src/utils/common/hooks/customHook'

/*
    일지 유효성 검사
*/
export const useCaringCustomHook = () => {
  const {
    checkInputText,
    selectedKey,
    checkCheckBox,
    isValidObj,
    confirmed,
    formAlert,
    nullCheck,
    regExpCheck,
    setTrue,
    checkTextArea,
    checkFileCnt,
    setValidCnt,
    checkDualDttm,
    setFalse,
  } = useValidationCheck()

  useEffect(() => {
    setValidCnt(7)
  }, [])

  const checkTitle = (title: string) => {
    return checkInputText({
      value: title,
      name: 'title',
      label: '제목',
      regExp: '^[a-zA-Z가-힣0-9 ]{1,10}$',
    })
  }

  const checkLoc = (location: string) => {
    return checkInputText({
      value: location,
      name: 'location',
      label: '거래 장소',
      regExp: '^[a-zA-Z가-힣0-9 ]{1,10}$',
    })
  }
  const checkPrice = (price: string) => {
    return checkInputText({
      value: price,
      name: 'price',
      label: '수고 비용',
      regExp: '^[0-9 ]{1,10}$',
    })
  }
  const checkContent = (content: string) => {
    return checkTextArea({
      value: content,
      name: 'content',
      label: '내용',
    })
  }

  const checkImgCnt = (filecnt: number) => {
    return checkFileCnt({ value: filecnt, name: 'file', label: '사진' })
  }

  const checkDttm = (start: Date | string | null, end: Date | string | null) => {
    return checkDualDttm({
      start,
      end,
      label: '기간',
      name: 'dttm',
    })
  }
  const checkAll = (data: {
    dttm: { start: Date | string | null; end: Date | string | null }
    location: string
    title: string
    content: string
    price: string
    fileCnt: number
    care: { [key: string]: boolean }
  }) => {
    if (!checkCheckBox(data.care, 'care', '관리')) return false
    if (!checkTitle(data.title)) return false
    if (!checkPrice(data.price)) return false
    if (!checkImgCnt(data.fileCnt)) return false
    if (!checkDttm(data.dttm.start, data.dttm.end)) return false

    if (!checkLoc(data.location)) return false
    if (!checkContent(data.content)) {
      return false
    } else {
      return true
    }
  }

  return {
    checkImgCnt,
    checkContent,
    selectedKey,
    isValidObj,
    confirmed,
    formAlert,
    checkTitle,
    checkCheckBox,
    checkTextArea,
    checkAll,
    checkPrice,
    checkLoc,
    checkDttm,
    setFalse,
    setTrue,
  }
}

export const useMarketCustomHook = () => {
  const {
    checkInputText,
    selectedKey,
    checkCheckBox,
    isValidObj,
    confirmed,
    formAlert,
    nullCheck,
    regExpCheck,
    setTrue,
    checkTextArea,
    checkFileCnt,
    setValidCnt,
    checkDualDttm,
    setFalse,
  } = useValidationCheck()

  useEffect(() => {
    setValidCnt(5)
  }, [])

  const checkTitle = (title: string) => {
    return checkInputText({
      value: title,
      name: 'title',
      label: '제목',
      regExp: '^[a-zA-Z가-힣0-9 ]{1,10}$',
    })
  }

  const checkLoc = (location: string) => {
    return checkInputText({
      value: location,
      name: 'location',
      label: '거래 장소',
      regExp: '^[a-zA-Z가-힣0-9 ]{1,10}$',
    })
  }
  const checkPrice = (price: string) => {
    return checkInputText({
      value: price,
      name: 'price',
      label: '수고 비용',
      regExp: '^[0-9 ]{1,10}$',
    })
  }
  const checkContent = (content: string) => {
    return checkTextArea({
      value: content,
      name: 'content',
      label: '내용',
    })
  }

  const checkImgCnt = (filecnt: number) => {
    return checkFileCnt({ value: filecnt, name: 'file', label: '사진' })
  }

  const checkAll = (data: { location: string; title: string; content: string; price: string; fileCnt: number }) => {
    if (!checkTitle(data.title)) return false
    if (!checkPrice(data.price)) return false
    if (!checkImgCnt(data.fileCnt)) return false

    if (!checkLoc(data.location)) return false
    if (!checkContent(data.content)) {
      return false
    } else {
      return true
    }
  }

  return {
    checkImgCnt,
    checkContent,
    selectedKey,
    isValidObj,
    confirmed,
    formAlert,
    checkTitle,
    checkCheckBox,
    checkTextArea,
    checkAll,
    checkPrice,
    checkLoc,
    setFalse,
    setTrue,
  }
}
