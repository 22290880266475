import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MAX_FILE_CNT } from 'src/utils/common/constants/constants'
import { generateRandomKey, getFileFromUrl, validateFile } from 'src/utils/common/scripts/common'
import styles from '../../styles/ImageFileField.module.css'
import CameraIcon from '../../../../../assets/images/icon/CamerIcon.png'
import FileDeleteIcon from '../../../../../assets/images/icon/FileDeleteIcon.png'
import SwipeListContainer, { SwipeContainer } from 'src/components/common/container/SwipeListContainer'
import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import SwiperListAutoWidth from 'src/components/common/swiper/SwiperListAutoWidth'
import { SwiperSlide } from 'swiper/react'
import styled from 'styled-components'

type inputFileFiled = {
  registerFn?: React.InputHTMLAttributes<HTMLInputElement>
  getValues: ReturnType<typeof useForm>['getValues']
  setValue: ReturnType<typeof useForm>['setValue']
  name: string
  register: ReturnType<typeof useForm>['register']
  warning?: boolean
  setTrue: (key: string) => void
  defaultValList?: string[] | undefined | null
  setFalse: (key: string, text: string) => void
}

export interface FormFileType extends File {
  is_delete?: boolean | null | undefined
}

export default function ImageFileField({
  defaultValList,
  warning,
  setTrue,
  register,
  registerFn,
  getValues,
  setValue,
  name,
  setFalse,
}: inputFileFiled) {
  const [files, setFiles] = useState<null | FormFileType[]>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [showImages, setShowImages] = useState<string[]>([])
  const [fileCnt, setFileCnt] = useState(0) // 첨부한 파일 갯수

  const styleId = warning ? 'btn_file border_red' : 'btn_file'

  useEffect(() => {
    register(name)
    register('fileCnt') // 파일 개수
    setValue('fileCnt', defaultValList ? defaultValList.length : 0)
    setFileCnt(defaultValList ? defaultValList.length : 0)
  }, [register, name])

  useEffect(() => {
    setValue('fileCnt', fileCnt)
  }, [fileCnt])

  useEffect(() => {
    const fetchData = async () => {
      if (defaultValList) {
        if (defaultValList) {
          const updateFiles = await Promise.all(defaultValList.map(item => getFileFromUrl(item)))
          const filteredFiles = updateFiles.filter(file => file !== null) as FormFileType[]
          setValue(name, filteredFiles)
          setFiles(filteredFiles)
          setShowImages(defaultValList)
        }
      }
    }

    fetchData() // Call the async function here
  }, [defaultValList])

  // useEffect(() => {
  //   setValue(
  //     name,
  //     files?.filter(item => item.is_delete !== true),
  //   )
  // }, [files, name, setValue])

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return

    const remainFileCnt = MAX_FILE_CNT - fileCnt // 추가로 첨부가능한 개수
    const curFileCnt = event.target.files.length // 현재 선택된 첨부파일 개수

    const filesArr = files ? files.filter(file => !file.is_delete) : []

    // 첨부파일 개수 확인
    if (curFileCnt > remainFileCnt) {
      setFalse(name, '첨부파일은 최대 ' + MAX_FILE_CNT + '개 까지 첨부 가능합니다.')
      return
    }

    // FileList를 배열로 변환
    const fileList = Array.from(event.target.files)

    // 이미 삭제된 파일들을 제외하고 추가
    const filteredFiles = fileList.filter(
      file => !filesArr.find(existingFile => existingFile.name === file.name && existingFile.is_delete),
    )

    for (let i = 0; i < Math.min(filteredFiles.length, remainFileCnt); i++) {
      const file = filteredFiles[i]
      // 첨부파일 검증
      const validateObj = validateFile(file)
      if (validateObj.validate) {
        const reader = new FileReader()
        reader.onload = () => {
          // 파일 배열에 담기
          filesArr.push(file)
          setFileCnt(prev => prev + 1)
          if (file) {
            const currentImageUrl = URL.createObjectURL(file)
            setShowImages(prev => [...prev, currentImageUrl])
          }
          // 파일 배열과 이미지 배열 상태를 업데이트
          setFiles(filesArr)
          setValue(name, filesArr)
        }
        reader.readAsDataURL(file)
        setTrue(name)
      } else {
        setFalse(name, validateObj.message)
        break
      }
    }

    handleResetFiles()
  }

  const handleResetFiles = () => {
    if (inputRef.current) {
      inputRef.current.value = '' // 파일 입력 요소의 value 속성을 빈 문자열로 설정하여 선택한 파일을 초기화
    }
  }
  const handleDelete = (idx: number) => {
    setFileCnt(prev => prev - 1)
    setFiles(prevFiles => {
      if (!prevFiles) return null
      const updatedFiles = prevFiles.map((file, index) => {
        if (index === idx) {
          // 삭제 플래그를 추가하여 삭제된 파일을 표시
          return { ...file, is_delete: true }
        } else {
          return file
        }
      })
      // 파일을 설정하고 삭제되지 않은 파일들로 폼의 값을 업데이트
      setValue(
        name,
        updatedFiles.filter(item => !item.is_delete),
      )
      return updatedFiles
    })
    setShowImages(prev => prev.filter((_, index) => index !== idx))
  }

  return (
    <SwiperListAutoWidth spaceBetween={7}>
      <SwiperContainer $warning={warning} key={generateRandomKey()}>
        <input
          type="file"
          multiple
          ref={inputRef}
          hidden
          accept="image/jpeg, image/png, image/jpg"
          onChange={handleUpload}
        />
        <button
          className={`${divideStyleIDString(styles, styleId)}`}
          type="button"
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.click()
            }
          }}
        >
          <img src={CameraIcon} alt="파일추가" />
          <p>{fileCnt}/10</p>
        </button>
      </SwiperContainer>

      {showImages.map((src, idx) => (
        <SwiperContainer $warning={warning} key={src}>
          <div className={styles.div_img_wrap} onClick={() => handleDelete(idx)}>
            <img className={styles.delete_btn} src={FileDeleteIcon} alt="파일지우기" />
            <div className={styles.div_img_content}>
              <img className={styles.selected_img} src={src} alt={`${src}`} />
            </div>
          </div>
        </SwiperContainer>
      ))}
    </SwiperListAutoWidth>
  )
}

const SwiperContainer = styled(SwiperSlide)<{ $warning?: boolean }>`
  box-sizing: border-box;
  width: 66px;
  height: ${({ $warning }) => ($warning ? '76px' : '66px')}; /* Conditional height based on $warning prop */
`

// const SwiperContainer = styled(SwiperSlide)`
//   box-sizing: border-box;

//   width: 92px;
//   height: 120px;
// `
