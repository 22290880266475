import styled from 'styled-components'
import DotButtonTwo from '../button/DotButtonTwo'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import backBtn from '../../../assets/images/icon/backbtnWhite.png'
import MenuContent from '../content/MenuContent'
import { MenuOptionType } from 'src/utils/common/type/type'
import { useState } from 'react'

type TitleType = {
  menuTitle: string
  options: MenuOptionType[]
  onClick?: () => void
}

export default function TitleLight({ onClick, menuTitle, options }: TitleType) {
  return (
    <Wrap>
      <Back
        onClick={() => {
          onClick?.()
        }}
        src={backBtn}
        alt="뒤로"
      />
      <MenuToggle title={menuTitle} options={options} />
    </Wrap>
  )
}

export function MenuToggle({
  title,
  options,
  bc = '#FFFFFF',
  mr = '27.5px',
}: {
  bc?: string
  title: string
  options: MenuOptionType[]
  mr?: string
}) {
  const [isOpened, setIsOpened] = useState(false)

  return (
    <>
      <DotWrap
        $mr={mr}
        onClick={() => {
          setIsOpened(prev => !prev)
        }}
      >
        <DotButtonTwo bc={bc} />
        {isOpened && <MenuContent options={options} title={title} />}
      </DotWrap>
    </>
  )
}

const Wrap = styled.div`
  align-items: center;
  display: flex;
  place-content: space-between;
  padding-top: 6.2%;
`

const DotWrap = styled.div<{ $mr: string }>`
  width: 20px;

  margin-right: ${({ $mr }) => $mr};

  position: relative;
  -webkit-tap-highlight-color: transparent;

  display: flex;
  justify-content: end;
`

const Back = styled.img`
  margin-top: 3px;
  width: 8.7px;
  height: 16px;
  margin-left: 25px;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    cursor: pointer;
  }
`
