import { Skeleton } from '@mui/material'
import styled from 'styled-components'

type TitleInDrawerType = {
  title: string | null | undefined
  subTitle?: string | null
  mb?: string
}
export default function TitleInDrawer({ mb = '30px', title, subTitle }: TitleInDrawerType) {
  if (!title) {
    return (
      <Wrap
        $mb={mb}
        style={{
          display: 'flex',
          placeContent: 'center',
        }}
      >
        <div>
          <></>
          <Skeleton variant="text" sx={{ textAlign: 'center' }} width={80} height={20} />
          <Skeleton variant="text" sx={{ mb: mb }} width={80} height={20} />
        </div>
      </Wrap>
    )
  } else {
    return (
      <Wrap $mb={mb}>
        <h2>{title}</h2>
        {subTitle && <h3>{subTitle}</h3>}
      </Wrap>
    )
  }
}

const Wrap = styled.div<{ $mb: string }>`
  width: 100%;
  /* 쪼꼬미들 */

  h2 {
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.05em;

    color: #1c1c1c;
  }
  h3 {
    /* 8개의 식물 */
    margin-top: 6px;

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    text-align: center;

    color: #1c1c1c;
    margin-bottom: ${({ $mb }) => $mb};
  }
`
