const getAccessToken = (): string | null => {
  const accessToken = localStorage.getItem('accessToken')
  return accessToken ? accessToken : null
}

export const getAccessToken2 = (): string | null => {
  const accessToken = localStorage.getItem('accessToken')
  return accessToken ? 'Bearer ' + accessToken : null
}

export default getAccessToken
