import { Box } from '@mui/material'
import PageSubTitleTwo from 'src/components/common/content/PageSubTitleTwo'
import DetailViewLabelLayout from 'src/components/common/layout/DetailViewLabelLayout'
import { CONTENT_PADDING } from 'src/utils/common/constants/constants'
import CollectionMainList from './CollectionMainList'
import DotButtonTwo from 'src/components/common/button/DotButtonTwo'
import { useRecoilState } from 'recoil'
import { userNmStts } from 'src/utils/common/state/state'
import { UseUserInfo } from '../../main/hooks/MainHomeCustomHook'

/*
    파일명 : CollectionOfMine.tsx
    설명 : 싹싹도감 메인 > 00님의 식물도감
    작성일 : 2024-06-13
*/
export default function CollectionOfMine() {
  const [userNm, setUserNm] = useRecoilState(userNmStts)
  const { profile, isPending } = UseUserInfo()

  return (
    <>
      <Box sx={{ p: CONTENT_PADDING, pt: 0, pb: 0 }}>
        {/* <PageSubTitleTwo marginBottom="19.5px" title="홍길동님의 식물도감" iconComp={<DotButtonTwo bc="#1C1C1C" />} /> */}
        <PageSubTitleTwo
          marginTop="39.48px"
          marginBottom="19.5px"
          title={!profile?.data.nickname ? null : `${profile?.data.nickname}님의 식물도감`}
          iconComp={<></>}
        />
      </Box>
      <Box sx={{ p: 0, pl: CONTENT_PADDING, pr: CONTENT_PADDING }}>
        <CollectionMainList />
      </Box>
    </>
  )
}
