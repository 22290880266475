import { Skeleton } from '@mui/material'
import styles from './styles/ContainerWithTitle.module.css'

export default function ContainerWithTitle({ title, children }: { title: string | null; children: any }) {
  if (!title) {
    return (
      <div className={styles.div_container}>
        <Skeleton variant="rounded" sx={{ height: '20px', width: '105px' }} />

        {children}
      </div>
    )
  } else {
    return (
      <div className={styles.div_container}>
        <h2 className={styles.h2_title}>{title}</h2>
        {children}
      </div>
    )
  }
}
