import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import Title from '../title/Title'
import TitleInDrawer from '../title/TitleInDrawer'
import LoadingSpinner from './LoadingSpinner'
import PopOutLayout from '../layout/PopOutLayout'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/scrollbar'
import { FreeMode, Scrollbar, Mousewheel } from 'swiper/modules'

type DrawerType = {
  title?: string | null
  subTitle?: string | null
  children?: React.ReactNode | null
  mb?: string
  height?: number
  pt?: number
}

export default function Drawer2({ pt = 40, height = 591, mb = '30px', children, title, subTitle = '' }: DrawerType) {
  return (
    <>
      <Wrap $height={height} $pt={pt}>
        <TitleInDrawer mb={mb} title={title} subTitle={subTitle} />
        {children ? children : <LoadingSpinner />}
      </Wrap>
    </>
  )
}

type WrapProps = {
  $pt: number
  $height: number // $height라는 props를 정의합니다.
}

const Wrap = styled(motion.div)<WrapProps>`
  position: absolute;
  width: 100%;
  height: ${props => props.$height}px; // props.$height를 통해 height 값을 동적으로 설정합니다.
  z-index: 2;
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  bottom: -441px;
  padding-top: ${props => props.$pt}px;

  padding-left: 17px;
  padding-right: 17px;
`
