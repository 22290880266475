import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { getDataTanstack, postDataJson } from 'src/utils/common/api/http'

export const useFindIdApi = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [userCode, setUserCode] = useState('')

  const {
    mutate,
    data: newCode,
    isError: errorSendingNumber,
  } = useMutation({
    mutationFn: postDataJson,
    onSuccess: () => {},
    onError: error => {},
  })

  const { data: checkCodeResult, refetch } = useQuery({
    queryKey: [
      'forget',
      {
        searchParam: userCode,
        url: `${process.env.REACT_APP_API_URL_FORGET}/email?phoneNumber=${phoneNumber}&verifyCode=`,
      },
    ],
    queryFn: getDataTanstack,
    enabled: false,
  })

  useEffect(() => {
    checkCode()
  }, [userCode])

  const checkCode = async () => {
    if (userCode) {
      await refetch()
    }
  }

  function getCode(tel: string) {
    const formData = {
      phoneNumber: tel,
    }
    setPhoneNumber(tel)
    const url = `${process.env.REACT_APP_API_URL_FORGET}/email`
    mutate({ formData, url })
  }

  return { getCode, newCode, setUserCode, checkCodeResult, errorSendingNumber }
}

export const test = () => {}
