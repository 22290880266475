import CollectionItemMoveList from './CollectionItemMoveList'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAddItemInToCollection, useMoveItem } from '../hook/CollectionListCustomHook'
import { selectCol } from 'src/utils/common/state/state'
import { useRecoilState } from 'recoil'
import FullLoadingSpinner from 'src/components/common/container/FullLoadingSpinner'
import { toast } from 'react-toastify'

export default function CollectionItemMoveListMain({
  onFinish,
  type,
  plantId,
}: {
  plantId?: number | string | null
  type: string
  onFinish: () => void
}) {
  const [selected, setSelected] = useState<null | number>(null)
  const [selectedPlants, setSelectedPlants] = useRecoilState<{ params: string[] }>(selectCol)
  const { itemId } = useParams()
  const { createForm, isSuccess, isPending } = useAddItemInToCollection()
  const { moveError, moveSuccess, moveItem, moving } = useMoveItem()

  useEffect(() => {
    if (isSuccess) {
      onFinish()
    }
  }, [isSuccess])

  useEffect(() => {
    if (moveError) {
      onFinish()
    }
  }, [moveError])

  useEffect(() => {
    if (moveSuccess) {
      onFinish()
    }
  }, [moveSuccess])

  return (
    <>
      <CollectionItemMoveList
        type={type}
        onSelect={(id: number) => {
          if (isPending || moving) return
          if (id) {
            if (type === 'ADD' && (itemId || plantId)) {
              if (itemId) createForm(id, [itemId])
              else if (plantId) createForm(id, [plantId])
            } else {
              moveItem(id, selectedPlants.params)
            }
          }
          setSelected(id)
        }}
      />
      {(isPending || moving) && <FullLoadingSpinner isShow={true} />}
    </>
  )
}
