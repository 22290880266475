import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import FormMain from '../../FormMain'
import InputNumber from '../input/InputNumber'
import { useEffect, useState } from 'react'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import * as React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker'
import { PickerChangeHandlerContext, TimeValidationError } from '@mui/x-date-pickers/models'
import { parseDateToString } from 'src/utils/common/scripts/common'

export function ResponsiveTimePickers({
  onChange,
  name,
  defaultVal,
  getValues,
  setValue,
  registerFn,
  register,
}: TimeInfoType) {
  const [toggle, setToggle] = useState(false)
  const [tc, setTc] = useState('#242424')

  useEffect(() => {
    if (!getValues(name)) {
      register(name)
      setValue(name, `${defaultVal}:00`)
      // const parsedDate = parseDateToString(defaultVal, 'tm')
      // setValue(name, `${parsedDate}:00`)
    }
  }, [])

  const handleChange = (value: Dayjs | null, context: PickerChangeHandlerContext<TimeValidationError>) => {
    if (value) {
      setValue(name, `T${value.format('HH:mm')}:00`)
      if (tc === '#A2A3A7') {
        setTc('#242424')
      }
    }
    if (context.validationError) {
      console.error('Validation error:', context.validationError)
    }
  }

  const initialTime = defaultVal ? dayjs(`2024-09-15${defaultVal}:00`) : dayjs()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimeOuterWrap sx={{ paddingTop: '0px', marginLeft: '8px' }} components={['DesktopTimePicker']}>
        <TimeWrap sx={{ ...active.tabs }}>
          <TimeInnerWrap $tc={tc} onChange={handleChange} sx={{ ...active.tabs }} defaultValue={initialTime} />
        </TimeWrap>
      </TimeOuterWrap>
    </LocalizationProvider>
  )
}
const TimeInnerWrap = styled(DesktopTimePicker)<{ $tc: string }>`
  /* Gray 03 */
  border: none;
  border-radius: 13px;
  .MuiOutlinedInput-root {
    border: none;
    border-radius: 13px;
    height: 39px;
    overflow: hidden;
    width: 135px;
    /* Body 02 */
    font-family: 'Pretendard-Regular';

    /* Black */
    color: ${({ $tc }) => $tc};
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    /* identical to box height, or 153% */

    letter-spacing: -0.02em;
    input::focus {
      outline: none;
    }
  }
  .MuiSvgIcon-root {
    color: #e2e4e8;
  }
`

const active = {
  tabs: {
    '& .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected': {
      color: '#00C398',
    },
  },
}

const TimeWrap = styled(DemoItem)`
  border: none;
  li.Mui-selected {
    color: green;
  }

  /* Example of additional styling if needed */
  &:hover {
    background-color: lightgray;
  }
`
const TimeOuterWrap = styled(DemoContainer)`
  border: none;
  li.Mui-selected {
    color: green;
  }

  /* Example of additional styling if needed */
  &:hover {
    background-color: lightgray;
  }
  // Style the inner component
`

type TimeInfoType = {
  name: string
  onChange?: () => void

  registerFn?: React.InputHTMLAttributes<HTMLInputElement>
  setValue: ReturnType<typeof useForm>['setValue']
  register: ReturnType<typeof useForm>['register']
  getValues: ReturnType<typeof useForm>['getValues']
  defaultVal: Date
}

export default function TimeInfo({
  onChange,
  name,
  defaultVal,
  getValues,
  setValue,
  registerFn,
  register,
}: TimeInfoType) {
  const [toggle, setToggle] = useState(false)
  useEffect(() => {
    register('time')

    if (defaultVal) {
      register('mm')
      register('ss')
      // 시간과 분 추출
      let hours = defaultVal.getHours()
      const minutes = defaultVal.getMinutes()

      // 오전/오후 구분
      const period = hours >= 12 ? 'pm' : 'am'

      // 시간을 12시간 형식으로 변환
      if (hours > 12) {
        hours -= 12
      } else if (hours === 0) {
        hours = 12
      }

      // 분을 2자리 숫자로 포맷 (예: 1분 -> 01)
      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes
      setValue('time', period)
      setValue('mm', hours.toString())
      setValue('ss', formattedMinutes.toString())
    } else {
      setValue('time', 'am')
    }
  }, [])

  return (
    <>
      <Wrap>
        <Div
          onClick={() => {
            setValue('time', 'am')
            setToggle(prev => !prev)
          }}
          className={getValues('time') === 'am' ? 'selected' : ''}
        >
          오전
        </Div>
        <Div
          className={getValues('time') === 'pm' ? 'selected' : ''}
          onClick={() => {
            setValue('time', 'pm')
            setToggle(prev => !prev)
          }}
        >
          오후
        </Div>
        <InputNumber
          name="mm"
          max={12}
          setValue={setValue}
          getValues={getValues}
          inputComp={
            <>
              <FormMain.Input
                styleID="input_count"
                type="text"
                placeholder="10"
                registerFn={register('mm', {
                  required: false,
                })}
              />
            </>
          }
        />
        :
        <InputNumber
          name="ss"
          max={59}
          setValue={setValue}
          getValues={getValues}
          inputComp={
            <>
              <FormMain.Input
                styleID="input_count"
                type="text"
                placeholder="10"
                registerFn={register('ss', {
                  required: false,
                })}
              />
            </>
          }
        />
      </Wrap>
    </>
  )
}

const Wrap = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`

const Div = styled.div`
  display: flex;
  /* Frame 176 */

  box-sizing: border-box;

  justify-content: center;
  align-items: center;

  width: 43px;
  height: 49px;

  border: 1px solid #dadada;
  border-radius: 7px;

  /* 오후 */

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: -0.05em;

  color: #979797;
  &:hover {
    cursor: pointer;
  }

  &.selected {
    background: #e0f7f1;
    border: 1px solid #00c398;
    /* 오전 */

    font-weight: 500;

    line-height: 17px;

    letter-spacing: -0.05em;

    color: #1c1c1c;
  }
`
