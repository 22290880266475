import styles from '../styles/Character.module.css'
import styled from 'styled-components'
import { myPlantType } from 'src/utils/common/type/type'
import WateringIcon from 'src/components/common/icon/WateringIcon'
import { getDdate } from 'src/utils/common/scripts/common'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { selectCalendarType, selectedDirayDate } from 'src/utils/common/state/state'
import CharacterImg from 'src/components/common/charcter/CharacterImg'
import { CustomButton } from 'src/components/common/button/CustomButton'

export default function Character({ item }: { item: myPlantType }) {
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDirayDate)
  const [isWeek, setIsWeek] = useRecoilState(selectCalendarType)
  const navigate = useNavigate()
  return (
    <>
      <div className={styles.div_box_and_word}>
        <WateringIcon margin="16px 0px 0px 20px" intervalIndays={item.wateringIntervalInDays} />
      </div>
      <Char>
        <CharacterImg
          version="small"
          backgroundType={item.plantLocation}
          charId={item.plantCharacterId}
          onClick={() => {
            setIsWeek(true)
            setSelectedDate({ date: new Date() }) // 달력 날짜 오늘로 초기화
            navigate(`/plant/detail/${item.myPlantId}`)
          }}
          imgStyle={{ width: '130.22px', height: '131.74px' }}
        />
      </Char>
      <div className={styles.div_title_wrap}>
        <div className={styles.div_title}>
          {(item.plantName && <h2>{item.plantName}</h2>) || <h2>식물명</h2>}
          {(item.plantBirthday && <h3>함께한지 {getDdate(item.plantBirthday)}일째</h3>) || <h3>함께한지 0일째</h3>}
        </div>
        <div className={styles.div_forward_icon}>
          <CustomButton
            onClick={() => {
              setIsWeek(true)
              setSelectedDate({ date: new Date() }) // 달력 날짜 오늘로 초기화
              navigate(`/plant/detail/${item.myPlantId}`)
            }}
            type="button"
            styleID="btn_write_diary"
            children={<>일지 쓰기</>}
          />
        </div>
      </div>
    </>
  )
}

/*
TODO : 비밀번호 찾기, 
메인 화면 username , 위치 정보
loader 로그인 필요한 화면 라우팅 함수 추가
build 하기
오늘일정 없을 때 문구 추가
*/
const Char = styled.div`
  z-index: 0;
  text-align: center;
`

const Bubble = styled.div`
  position: relative;
  background: #00c398;
  border-radius: 50px;
  align-self: self-end;
  z-index: 1;

  align-content: center;
  text-align: center;

  width: 103px;
  height: 57px;

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  letter-spacing: -0.05em;

  color: #ffffff;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px; /* Position the tail at the bottom */
    right: 19px; /* Position the tail towards the right */
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: #00c398; /* Tail color matches the bubble */
    border-bottom: 0;
    border-right: 0;
    margin-left: -5px;
    transform: rotate(270deg);
    border-radius: 3px;
  }
`
