import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import styles from '../../styles/InputWithText.module.css'

type InputWithTextType = {
  text: string
  inputComp: JSX.Element | JSX.Element[]
  isValid?: boolean
}

export default function InputWithText({ isValid = true, text, inputComp }: InputWithTextType) {
  return (
    <div className={`${divideStyleIDString(styles, isValid === true ? 'div_container' : 'div_container border_red')}`}>
      {inputComp}
      <span>{text}</span>
    </div>
  )
}
