import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import styled from 'styled-components'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '300px',
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
}

export default function AgreeModal({
  title,
  description,
  open,
  handleClose,
}: {
  description: string
  title: string
  open: boolean
  handleClose: () => void
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Title id="modal-modal-title" variant="h6">
            {title}
          </Title>
          <Content id="modal-modal-description" sx={{ mt: 2 }}>
            {description}
          </Content>
        </Box>
      </Modal>
    </div>
  )
}

const Title = styled(Typography)`
  /* 앗! 이미 일지를 썼던 날이에요! */

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.05em;

  color: #1c1c1c;
`

const Content = styled(Typography)`
  /* 일지를 수정하시겠어요? */

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  letter-spacing: -0.05em;

  color: #1c1c1c;
`
