/*
    파일명 : CharacterIcon.tsx
    설명 : 캐릭터 상세 아이콘
    작성일 : 2024-06-06
*/

import { ClickAwayListener, Fade, Grid, Zoom } from '@mui/material'
import styled from 'styled-components'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import React, { useState } from 'react'

const WORDS = [
  '오늘도 행복해!', // 1
  '흠흠~ 콧노래가 절로나와', // 2
  '오늘도 물을 진탕 먹었어! \n어제보다 더 촉촉해진 것 같아', // 3
  '나는 햇빛이 좋아!', // 4
  '오늘은 꿀벌에게 말을 걸어봤어..', // 5
  '놀러 나가고 싶어!', // 6
  '즐거워, 노래를 부르자!', // 7
  '광합성을 하면 참 기분이 좋아져. \n너도 한번 해봐!', // 8
  '룰룰루~!', // 9
  '왜 불러!', // 10
  '나랑 친해지자!', // 11
  '나를 가끔 빤히 쳐다보는 \n고양이는 조금 무서워..', // 12
  '내가 씨앗일 때를 기억하냐구? \n그럼 당연한 걸!', // 13
  '싹트기 전, \n넌 나를 사랑스러운 \n눈빛으로 쳐다봤지..', // 14
  '사랑받는다는 건 참 행복한 거야!', // 15
  '너의 비밀을 알고 있어! \n그건.. 비밀이야!', // 16
  '나도 슬플 때가 있어! \n하지만 1분만 지나면 기억이 안 나!', // 17
  '내 웃음소리가 특이하대! \n우하하~', // 18
  '나는 우하하! 하고 웃어. \n우하하!', // 19
  '요즘 내 고민은.. \n안 씻었더니 \n진드기가 붙는다는 거야. \n다른 애들한텐 비밀이야!', // 20
  '식물 영양제 다섯개를 \n한꺼번에 먹어본적이 있어!\n윽 배가 너무 불러서 죽는줄 알았어', // 21
  '글쎄 옆집 파리지옥이는 \n곤충을 먹는대!', // 22
  '비오는 날이 좋아~', // 23
  '나도 하이페리온처럼 \n커졌으면 좋겠어! \n하이페리온은 세상에서 제일 크대~', // 25
  '잎에 리본을 달면 \n내가 더 귀여워질까?', // 26
  '예쁘고 커다란 꽃을 피우고 싶어!', // 27
  '로즈마리를 짝사랑 한적이 있어..\n향기가 달콤했거든..', // 28
  '내가 제일 좋아하는 노래는\n로즈가 부른 [장미의 꿈]이야!', // 29
  '귀여움을 한껏 받고 싶어', // 30
  '달팽이랑은 그렇게 \n친한 사이는 아니야.', // 31
  '무당벌레의 무늬를 보고 \n화들짝 놀랬어\n귀신으로 착각한거 있지! \n우하하~', // 32
  '무서운 이야기는 하지 말아줘. \n나는 겁이 많거든 ', // 33
  '[장미의 꿈]의 첫 구절은\n[아~ 아~ 나는야 분홍 장미~]\n이 구절이 내 마음에 쏙 들어', // 34
]
function getWord(clickCount: number) {
  const randomInt = Math.floor(Math.random() * 35)

  return WORDS[randomInt]
}

function useGetWord() {
  const [word, setWord] = useState('안녕!')
  const [click, setClick] = useState(0)

  const onClickChar = () => {
    setClick(prev => prev + 1)
    if (click === 0) return
    setWord(getWord(click))
  }

  return { word, onClickChar }
}

export default function CharacterIcon({
  charId,
  plantLocation,
  width,
  height,
}: {
  plantLocation: string
  charId: number
  width: string
  height: string
}) {
  const [open, setOpen] = React.useState(false)
  const { word, onClickChar } = useGetWord()

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
    onClickChar()
  }

  if (plantLocation !== 'basic') {
    return (
      <>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <LightTooltip
            title={word}
            TransitionComponent={Zoom}
            // PopperProps={{
            //   disablePortal: true,
            // }}
            TransitionProps={{ timeout: 200 }}
            onClose={handleTooltipClose}
            open={open}
            // disableFocusListener
            // disableHoverListener
            // disableTouchListener
          >
            <SelectedChar onClick={handleTooltipOpen}>
              <Grid item style={{}}>
                <BgWrap>
                  <BgInnerWrap>
                    <BgImg src={require(`/src/assets/images/plant/bg/${plantLocation}.png`)} alt="선택한 배경" />
                  </BgInnerWrap>
                  <BgInnerWrap>
                    <BgChar src={require(`/src/assets/images/plant/character/char${charId}.png`)} alt="선택한 캐릭터" />
                  </BgInnerWrap>
                </BgWrap>
              </Grid>
            </SelectedChar>
          </LightTooltip>
        </ClickAwayListener>
      </>
    )
  } else {
    return (
      <>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <LightTooltip
            title={word}
            TransitionComponent={Zoom}
            // PopperProps={{
            //   disablePortal: true,
            // }}
            TransitionProps={{ timeout: 200 }}
            onClose={handleTooltipClose}
            open={open}
          >
            <SelectedChar onClick={handleTooltipOpen}>
              <Grid item style={{}}>
                <Img
                  width={width}
                  height={height}
                  src={require(`/src/assets/images/plant/character/char${charId}.png`)}
                  alt="선택한 캐릭터"
                />
              </Grid>
            </SelectedChar>
          </LightTooltip>
        </ClickAwayListener>
      </>
    )
  }
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    placement="top-end"
    {...props}
    classes={{ popper: className }}
    // style={{
    //   bottom: '-89px',
    //   left: '67%',
    //   width: 'maxContent',
    // }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    // boxShadow: theme.shadows[1],
    fontSize: 16,
    fontFamily: 'Pretendard-Regular',
    position: 'absolute',
    bottom: '-96px',
    // left: '1px',
    right: '90px',
    width: 'max-content',
    // maxWidth: '200px',
    whiteSpace: 'pre',
  },
}))

const Img = styled.img<{ width: string; height: string }>`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  z-index: 0;
`

const BgWrap = styled.div`
  height: 255px;
`

const BgInnerWrap = styled.div`
  position: relative;
`

const BgImg = styled.img`
  position: absolute;
  width: 298px;
  height: 288px;
  z-index: 0;
  right: -47%;
  top: -34px;
`

const BgChar = styled.img`
  position: inherit;
  z-index: 1;
  width: 162.22px;
  height: 159.64px;
  top: 55px;
  left: 7px;
`
const SelectedChar = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
