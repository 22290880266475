import { MemberAddressType } from 'src/utils/common/type/type'
import MainHomeHeader from './MainHomeHeader'
import MainHomeWeather from './MainHomeWeather'

import MyPlantList from './MyPlantList'
import MyPlan from './MyPlan'

export default function MainHomeContent({ nickname, loc }: { loc: null | MemberAddressType; nickname: string | null }) {
  let name = nickname
  if (nickname?.includes('새회원')) {
    name = '새회원'
  }

  return (
    <>
      <MainHomeHeader userName={name} isPending={nickname ? false : true} />

      <MainHomeWeather loc={loc} />

      <MyPlantList isPending={nickname ? false : true} userName={name} />
      <MyPlan isPending={nickname ? false : true} />
    </>
  )
}
