import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useRecoilState } from 'recoil'
import { queryClient } from 'src/App'

import {
  deleteDataTanstackWithToken,
  getDataTanstackWithToken,
  patchDataMultiPartWithToken,
  postDataJsonWithToken,
  postDataMultiPartWithAdmin,
  postDataMultiPartWithToken,
} from 'src/utils/common/api/http'
import { useCheckTokenValidity, useValidationCheck } from 'src/utils/common/hooks/customHook'
import { castDiaryList, castPlanList } from 'src/utils/common/scripts/checkType'
import { alertState } from 'src/utils/common/state/state'
import { CalendarEventType, diaryDetailType } from 'src/utils/common/type/type'

/*
    일지 유효성 검사
*/
export const useDiaryFormCheck = () => {
  const { checkData } = useCheckTokenValidity()
  const previousEvent = queryClient.getQueryData(['my-diary'])
  const [prevDiary, setPrevDiary] = useState(previousEvent)
  const [updateDiary, setUpdateDiary] = useState<number | null>(null)

  const {
    checkInputText,
    selectedKey,
    checkCheckBox,
    isValidObj,
    confirmed,
    formAlert,
    nullCheck,
    regExpCheck,
    setTrue,
    checkTextArea,
    setFalse,
    checkFileCnt,
    setValidCnt,
  } = useValidationCheck()

  const { plantId } = useParams()

  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    setValidCnt(3)
  }, [])

  const checkTitle = (title: string) => {
    return checkInputText({
      value: title,
      name: 'title',
      label: '제목',
      regExp: '^.{1,50}$',
    })
  }
  const checkContent = (content: string) => {
    return checkTextArea({
      value: content,
      name: 'content',
      label: '내용',
    })
  }

  const checkImgCnt = (filecnt: number) => {
    return checkFileCnt({ value: filecnt, name: 'file', label: '사진' })
  }
  const checkAll = (data: { title: string; content: string; care: { [key: string]: boolean } }) => {
    if (!checkCheckBox(data.care, 'care', '관리')) return false
    if (!checkTitle(data.title)) return false

    if (!checkContent(data.content)) {
      return false
    } else {
      return true
    }
  }
  const {
    mutate,
    data: createDiaryResult,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: postDataMultiPartWithToken,
    onSuccess: data => {
      toast.success('일지 등록이 완료되었습니다.')
    },
    onError: () => {
      toast.error('일지 등록이 실패하였습니다.')
    },
    onSettled: async () => {
      await queryClient.cancelQueries({ queryKey: ['my-diary'] })
      queryClient.invalidateQueries({ queryKey: ['my-diary'] })
      // navigate(location.pathname, { state: { direction: 'na' } })
    },
  })

  const {
    mutate: update,
    data: updateResponse,
    isPending: updating,
    isError: errorToUpdating,
    isSuccess: successToUpdating,
  } = useMutation({
    mutationFn: patchDataMultiPartWithToken,
    onSuccess: async successD => {
      toast.success('일지 수정에 성공하였습니다.')
      const fetch = async () => {
        await queryClient.fetchQuery({
          queryKey: [
            'my-diary',
            {
              searchParam: '',
              url: `${process.env.REACT_APP_API_DOMAIN}my-plant/diaries/personal/${updateDiary}`,
            },
          ],
          queryFn: getDataTanstackWithToken,
        })
      }
      await fetch()
      queryClient.invalidateQueries({ queryKey: ['my-diary'] })
    },
    onError: error => {
      toast.error('일지 수정이 실패하였습니다.')
      queryClient.setQueryData(['my-diary'], prevDiary)
      checkData(error)
    },
    onSettled: async () => {
      // navigate(location.pathname, { state: { direction: 'na' } })
    },
  })

  const createDiary = (
    data: { [key: string]: string | { [key: string]: boolean } },
    trueValues: string[],
    form: FormData,
  ) => {
    const formData = {
      myPlantId: Number(plantId), // TODO : 바꾸기
      title: data.title,
      content: data.content,
      createdDate: data.dttm,
      plantCareList: trueValues,
      isPublished: true,
      isPublic: data.isPublic,
    }

    form.append('data', JSON.stringify(formData))
    const url = `${process.env.REACT_APP_API_DOMAIN}my-plant/diaries`
    mutate({ formData: form, url })
  }

  const updateData = (
    id: number,
    data: { [key: string]: string | { [key: string]: boolean } },
    trueValues: string[],
    form: FormData,
  ) => {
    setUpdateDiary(id)
    const formData = {
      diaryId: id,
      title: data.title,
      content: data.content,
      plantCareList: trueValues,
      isPublished: true,
      isPublic: data.isPublic,
    }

    form.append('data', JSON.stringify(formData))
    const url = `${process.env.REACT_APP_API_DOMAIN}my-plant/diaries`

    update({ formData: form, url })
  }

  return {
    checkImgCnt,
    checkContent,
    selectedKey,
    isValidObj,
    confirmed,
    formAlert,
    checkTitle,
    checkCheckBox,
    checkTextArea,
    checkAll,
    createDiary,
    isSuccess,
    isError,
    updateData,
    updating,
    errorToUpdating,
    isPending,
    successToUpdating,
    setFalse,
    setTrue,
  }
}

export function useGetPlanList() {
  const { plantId } = useParams()
  const [period, setPeriod] = useState<{
    start: string | null
    end: string | null
  }>({
    start: null,
    end: null,
  })

  const [response, setResponse] = useState<CalendarEventType[] | null>()

  const {
    data: plantInfo,
    refetch,
    isSuccess,
    isPending,
    error,
  } = useQuery({
    queryKey: [
      'my-plan',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}my-plant/schedules/calendar/list?startDate=${period.start}&endDate=${period.end}`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  useEffect(() => {
    if (period.end && period.start) {
      refetch()
    }
  }, [period])

  useEffect(() => {
    if (plantInfo) {
      const castObj = castPlanList(plantInfo)

      if (castObj?.scheduleCalendarResponseList && castObj?.scheduleCalendarResponseList.length > 0) {
        const newList = castObj?.scheduleCalendarResponseList.map(diary => ({
          date: diary.startDate,
          colorType: diary.colorType,
        }))

        setResponse(newList)
      }
    }
  }, [plantInfo])
  return {
    setPeriod,
    response,
    isPending,
    refetch,
  }
}

export function useDeleteDiary({
  onPostFinish,
  setDiaryDetail,
  setDiaryId,
}: {
  onPostFinish: () => void
  setDiaryDetail: React.Dispatch<React.SetStateAction<diaryDetailType | null | undefined>>
  setDiaryId: React.Dispatch<React.SetStateAction<null | number>>
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const [requestSucess, setRequestSucess] = useState<undefined | boolean>(undefined)

  const {
    mutate,
    data,
    isPending: deleting,
    isSuccess: deletingSuccess,
    isError: deletingFailed,
  } = useMutation({
    mutationFn: deleteDataTanstackWithToken,
    onSuccess: response => {
      toast.success('일지 삭제에 성공하였습니다.')
      setDiaryDetail(null)
      setDiaryId(null)
      setRequestSucess(true)
    },
    onError: response => {
      toast.error('일지 삭제에 실패하였습니다.')
      setRequestSucess(false)
    },
    // onSettled: async () => {
    //   // await queryClient.cancelQueries({ queryKey: ['my-diary'] })
    //   // queryClient.invalidateQueries({ queryKey: ['my-diary'] })
    //   // navigate(location.pathname, { state: { direction: 'na' } })
    // },
  })

  const deleteItem = (id: number) => {
    const url = `${process.env.REACT_APP_API_DOMAIN}my-plant/diaries/${id}`
    mutate({ url })
  }

  return {
    deleting,
    deleteItem,
    deletingSuccess: requestSucess,
    deletingFailed,
    setRequestSucess,
  }
}

export const test = () => {}
