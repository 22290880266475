/*
  작성자 : 김효은
  작성일 : 2024-05-09
  설명 : 인풋 . 버튼이 한 줄로 표현 될 때 사용하는 컴포넌트
*/
import InputWithBtnLayout from 'src/components/common/layout/InputWithBtnLayout'

import FormMain from '../../FormMain'

export interface InputFieldProps {
  inputComp: JSX.Element | JSX.Element[]
  buttonComp: JSX.Element | JSX.Element[]
  styleId?: string | undefined
}

export default function InputWithButton({ inputComp, buttonComp, styleId = 'div_with_timer' }: InputFieldProps) {
  return (
    <>
      <InputWithBtnLayout>
        <FormMain.InputLayout styleId={styleId}>{inputComp}</FormMain.InputLayout>
        <>{buttonComp}</>
      </InputWithBtnLayout>
    </>
  )
}
