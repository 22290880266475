import React from 'react'
import { displayedAt } from 'src/utils/common/scripts/common'
import styled from 'styled-components'
import levelIconSample from '../../../assets/images/level/sample.png'
import Skeleton from '@mui/material/Skeleton'

type ProfileHeaderType = {
  subComp: React.ReactNode
  userNm: string | undefined
  regDttm: string | undefined
  profileSrc: string | undefined
  imgSize?: string
  h2ClassNm?: string
}

export default function ProfileHeader({
  h2ClassNm = 'userNm',
  imgSize,
  subComp,
  userNm,
  regDttm,
  profileSrc,
}: ProfileHeaderType) {
  if (!userNm || !regDttm || !profileSrc) {
    return (
      <>
        <ProfileContainer imgSize={imgSize}>
          <ProfileWrap>
            <ProfileImg imgSize={imgSize} profileSrc={null} />
            <ProfileDivComp userNm={undefined} regFormat={undefined} />
          </ProfileWrap>
          <RightDiv>{subComp}</RightDiv>
        </ProfileContainer>
      </>
    )
  }
  const regFormat = displayedAt(regDttm)
  return (
    <ProfileContainer imgSize={imgSize}>
      <ProfileWrap>
        <ProfileImg imgSize={imgSize} profileSrc={profileSrc} />
        <ProfileDivComp h2ClassNm={h2ClassNm} userNm={userNm} regFormat={regFormat} />
      </ProfileWrap>
      <RightDiv>{subComp}</RightDiv>
    </ProfileContainer>
  )
}

export function ProfileContainer({
  children,
  imgSize = '45px',
  onClick,
}: {
  children: React.ReactNode
  imgSize?: string
  onClick?: () => void
}) {
  return (
    <Wrap
      onClick={() => {
        onClick?.()
      }}
      $size={imgSize}
    >
      {children}
    </Wrap>
  )
}

export function ProfileWrap({ children }: { children: React.ReactNode }) {
  return <LeftDiv>{children}</LeftDiv>
}

export function ProfileImg({ profileSrc, imgSize = '45px' }: { profileSrc: string | null; imgSize?: string }) {
  if (profileSrc) {
    return <Img $size={imgSize} src={profileSrc} alt="프로필 이미지" />
  } else {
    return (
      <>
        <Skeleton variant="circular" height={imgSize} width={imgSize} />
      </>
    )
  }
}

export function ProfileDivComp({
  level,
  userNm,
  regFormat,
  h2ClassNm = 'userNm',
}: {
  level?: string
  userNm: string | undefined
  regFormat: string | undefined
  h2ClassNm?: string
}) {
  if (!userNm || !regFormat) {
    return (
      <ProfileDiv>
        <ProfileInnerDiv>
          <Skeleton variant="text" width={50} />
        </ProfileInnerDiv>
        <Skeleton variant="text" width={50} />
      </ProfileDiv>
    )
  }

  let levelComp = <></>

  if (level) {
    levelComp = ProfileLevelComp(level)
  }
  return (
    <ProfileDiv>
      <ProfileInnerDiv>
        {levelComp}
        <UserNm className={h2ClassNm}>{userNm}</UserNm>
      </ProfileInnerDiv>
      <RegFormat>{regFormat}</RegFormat>
    </ProfileDiv>
  )
}

export function ProfileIntroComp({
  level,
  userNm,
  regFormat,
  comp,
  h2ClassNm = 'userNm',
}: {
  level?: string
  userNm: string | null
  regFormat: string | null
  comp?: React.ReactNode
  h2ClassNm?: string
}) {
  let levelComp = <></>
  if (level) {
    levelComp = ProfileLevelComp(level)
  }
  if (!userNm || !regFormat) {
    return (
      <ProfileDiv>
        <ProfileInnerDiv $mb="0px">
          <Skeleton variant="rounded" width={60} height={20} />
        </ProfileInnerDiv>
        {/* <Skeleton variant="rounded" width={40} height={18} /> */}
      </ProfileDiv>
    )
  } else {
    return (
      <ProfileDiv>
        <ProfileInnerDiv $mb="0px">
          {levelComp}
          <UserNm className={h2ClassNm}>{userNm}</UserNm>
        </ProfileInnerDiv>
        {comp ? comp : <></>}
        {/* <span>(LV) {regFormat}</span> */}
      </ProfileDiv>
    )
  }
}

export function ProfileLevelComp(level: string) {
  let result = <></>
  switch (level) {
    case '1':
      result = <Level src={levelIconSample} alt={level} />
      break
    default:
      result = <Level src={levelIconSample} alt={level} />
      break
  }
  return result
}
const Level = styled.img`
  width: 21px;
  height: 21px;
  margin-right: 7px;
`

const ProfileInnerDiv = styled.div<{ $mb?: string }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ $mb }) => $mb || `0px`};
`

const Wrap = styled.div<{ $size: string }>`
  display: flex;
  width: 100%;
  height: ${({ $size }) => $size};
  place-content: space-between;
  align-items: center;
`

const LeftDiv = styled.div`
  display: flex;
  height: 100%;
`
const RightDiv = styled.div``
const Img = styled.img<{ $size: string }>`
  height: ${({ $size }) => $size};
  width: ${({ $size }) => $size};
  border-radius: 50%;
`

const ProfileDiv = styled.div`
  height: 100%;
  width: fit-content;
  align-content: center;
  margin-left: 10px;

  // span {
  //   /* (LV) */

  //   font-family: 'Pretendard-Regular';
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 15px;
  //   line-height: 18px;
  //   /* identical to box height */
  //   letter-spacing: -0.05em;

  //   color: #00c398;
  // }
`

const UserNm = styled.h2`
  &.plaza {
    /* 멋진 농부 */
    line-height: normal;
    /* Body 02-M */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;

    /* identical to box height, or 153% */
    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }

  &.comment {
    /* 장미 러버 */

    /* Body 02-M */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    /* identical to box height, or 153% */
    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }
  &.userNm {
    line-height: 28px;
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;

    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }

  &.follow {
    /* 멋진 농부 */

    /* Body 02-M */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;

    /* identical to box height, or 153% */
    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }
`

const RegFormat = styled.p`
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #979797;
  margin-top: 5px;
`
