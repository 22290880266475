/*eslint-disable */

import * as React from 'react'
import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import MyPageIcon from '../../../assets/images/icon/MyPageIcon.png'

import MyProfile from '../../../assets/images/navigation/MyProfile.png'
import MyProfileActive from '../../../assets/images/navigation/MyProfileActive.png'
import HomeIcon from '../../../assets/images/navigation/Home.png'
import HomeActiveIcon from '../../../assets/images/navigation/HomeActive.png'
import Plaza from '../../../assets/images/navigation/Plaza.png'
import Collection from '../../../assets/images/navigation/Collection.png'
import CollectionActive from '../../../assets/images/navigation/CollectionActive.png'
import PlazaActive from '../../../assets/images/navigation/PlazaActive.png'
import GreenMarket from '../../../assets/images/navigation/GreenMarket.png'
import GreenMarketActive from '../../../assets/images/navigation/GreenMarketActive.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import DrawerLayout from '../layout/DrawerLayout'
import { styled } from 'styled-components'
import FormMain from '../form/FormMain'
import { useProfilePageStatus } from 'src/utils/store/profile/useProfilePageStatus'

const TextArea = styled.textarea`
  width: 100%;
  background-color: unset;

  margin: 0px;
  padding: 0px;
  border: none;

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  letter-spacing: -0.05em;
  height: 100%;

  color: #1c1c1c;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #979797;
  }
`

export default function BottomNav() {
  const [value, setValue] = useState(0)
  const navigate = useNavigate()
  const path = useLocation()
  const [pathName, setPathName] = useState(path.pathname)
  const { setPageStatus, pageStatus, pageValue, setPageValue } = useProfilePageStatus()

  useEffect(() => {
    setPathName(path.pathname)
  }, [path])

  if (pathName.includes('/chat')) {
    return (
      <Box sx={{ width: '100%', height: '139px', margin: 0, zIndex: 999 }}>
        <>
          <DrawerLayout
            onClose={() => {}}
            open={false}
            children={
              <>
                <TextArea placeholder="멋진 농부 님께 메시지 보내기" />
              </>
            }
          />
        </>
      </Box>
    )
  }

  return (
    <Box sx={{ width: '100%', height: '100%', margin: 0, zIndex: 999 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
        }}
      >
        <BottomNavigationAction
          label=""
          icon={
            <Img
              onClick={() => {
                navigate('/plaza', { state: { direction: 'na' } })
              }}
              src={pathName.includes('/plaza') ? PlazaActive : Plaza}
              alt="식물 광장"
            />
          }
        />
        <BottomNavigationAction
          label=""
          onClick={() => {
            navigate('/collection', { state: { direction: 'na' } })
          }}
          icon={
            <CollectionImg src={pathName.includes('/collection') ? CollectionActive : Collection} alt="싹싹 도감" />
          }
        />
        <BottomNavigationAction
          onClick={() => {
            navigate('/main', { state: { direction: 'na' } })
          }}
          label=""
          icon={<Img src={pathName === '/main' ? HomeActiveIcon : HomeIcon} alt="홈" />}
        />
        {/* <BottomNavigationAction
          onClick={() => {
            navigate('/green', { state: { direction: 'na' } })
          }}
          label=""
          icon={<GreenMarketImg src={pathName.includes('/green') ? GreenMarketActive : GreenMarket} alt="푸른 마켓" />}
        /> */}
        <BottomNavigationAction
          onClick={() => {
            navigate('/profile/myprofile', { state: { direction: 'na' } })
          }}
          label=""
          icon={
            <ProfileImg src={pathName.includes('/profile/myprofile') ? MyProfileActive : MyProfile} alt="마이페이지" />
          }
        />
      </BottomNavigation>
    </Box>
  )
}

const Img = styled.img`
  width: 24px;
  height: 24px;
`

const CollectionImg = styled.img`
  width: 18px;
  height: 24px;
`

const GreenMarketImg = styled.img`
  width: 17px;
  height: 24px;
`

const ProfileImg = styled.img`
  width: 24x;
  height: 24px;
`
