import styled from 'styled-components'

function InputLayout({
  children,
  iconComp = null,
  styleId = 'div_with_timer',
  width = '',
  height = '',
}: {
  children: JSX.Element | JSX.Element[]
  iconComp?: JSX.Element | JSX.Element[] | null
  styleId?: string
  width?: string
  height?: string
}) {
  if (iconComp === null) {
    return (
      <InputLayoutDiv height={height} width={width} className={styleId}>
        {children}
        <div style={{ width: '5%' }}>{}</div>
      </InputLayoutDiv>
    )
  } else {
    return (
      <InputLayoutDiv height={height} width={width} className={styleId}>
        {children}
        <div>{iconComp}</div>
      </InputLayoutDiv>
    )
  }
}

export default InputLayout

interface InputStyledProps {
  width: string
  height: string
}

const InputLayoutDiv = styled.div.withConfig({
  shouldForwardProp: prop => !['width', 'height'].includes(prop),
})<InputStyledProps>`
  width: ${props => props.width};
  height: ${props => props.height};

  &.div_plant_01 {
    width: 78.76%;
    height: 49px;
    background: #f2f2f2;
    border-radius: 7px;
    border: none;
    display: flex;
    vertical-align: middle;
    align-items: center;
  }

  &.div_plant_02 {
    width: 78.76%;

    border: none;
    display: flex;
    vertical-align: middle;
    align-items: center;

    height: 49px;

    border: 1px solid #dadada;
    border-radius: 7px;
  }

  &.div_plant_02 input {
    width: 100%;
    height: 90%;
    background: unset;
    border-radius: 7px;
    border: none;
    padding-left: 24px;

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    letter-spacing: -0.05em;

    color: #1c1c1c;

    &::placeholder {
      color: #979797;
    }
  }

  &.div_plant_03 {
    margin-top: 19px;
    width: 78.76%;

    border: none;
    display: flex;
    vertical-align: middle;
    align-items: center;

    height: 28px;

    border: 1px solid #dadada;
    border-radius: 7px;
  }

  &.div_plant_03 input {
    width: 100%;
    height: 90%;
    background: unset;
    border-radius: 7px;
    border: none;
    padding-left: 24px;

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    letter-spacing: -0.05em;

    color: #1c1c1c;

    &::placeholder {
      color: #979797;
    }
  }

  &.div_plant_04 {
    width: 78.76%;
    height: 48px;

    border-radius: 7px;
    border: none;
    display: flex;
    vertical-align: middle;
    align-items: center;

    /* Frame 456 */

    /* Gray 03 */
    border: 1px solid #e2e4e8;
  }

  &.div_plant_04 input {
    width: 100%;
    height: 90%;
    background: unset;
    border-radius: 7px;
    border: none;
    padding-left: 24px;

    /* 학명을 입력해주세요. */

    /* Body 02 */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    /* identical to box height, or 153% */
    letter-spacing: -0.02em;

    /* Gray 04 */
    color: #242424;

    &::placeholder {
      color: #979797;
    }
  }

  &.div_plant_chat {
    width: 78.76%;

    border: none;
    display: flex;
    vertical-align: middle;
    align-items: center;

    height: 49px;

    border: 1px solid #dadada;
    border-radius: 7px;
  }

  &.div_plant_chat input {
    width: 100%;

    background: unset;
    border-radius: 7px;
    border: none;
    padding-left: 24px;

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    letter-spacing: -0.05em;

    color: #1c1c1c;

    &::placeholder {
      color: #979797;
    }
  }

  &.div_plant_01 input {
    height: 90%;
    background: #f2f2f2;
    border-radius: 7px;
    border: none;
    padding-left: 24px;

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    letter-spacing: -0.05em;

    color: #1c1c1c;
  }

  &.div_plant_01 input:focus,
  &.div_plant_04 input:focus,
  &.div_hash input:focus {
    outline: none;
  }

  &.div_plant_02 input:focus {
    outline: none;
  }

  &.div_plant_03 input:focus {
    outline: none;
  }

  &.wdt_full {
    width: 100%;
  }

  &.div_with_full_custom_height {
    width: 100%;

    background: #f2f2f2;
    border-radius: 77px;
    color: #8d8d8d;

    display: flex;

    vertical-align: middle;
    align-items: center;
  }

  &.border_red {
    border: solid 1px #f00;
  }

  &.div_with_full {
    width: 100%;
    height: 43px;
    background: #f2f2f2;
    border-radius: 77px;
    color: #8d8d8d;

    display: flex;

    vertical-align: middle;
    align-items: center;
  }

  &.div_with_timer {
    width: 78.76%;
    height: 43px;
    background: #f2f2f2;
    border-radius: 77px;
    color: #8d8d8d;

    display: flex;

    vertical-align: middle;
    align-items: center;
  }

  &.div_hash {
    width: 78.76%;
    height: 49px;
    box-sizing: border-box;
    background: unset;

    align-items: center;

    border: 1px solid #dadada;
    border-radius: 7px;

    /* Frame 176 */

    box-sizing: border-box;
    display: flex;

    vertical-align: middle;
    align-items: center;
  }

  &.div_with_full_custom_height input::placeholder,
  &.div_with_full input::placeholder,
  &.div_with_timer input::placeholder {
    font-weight: lighter;
  }

  &.div_hash input::placeholder {
    font-weight: lighter;
    color: #979797;
  }

  &.div_with_full_custom_height input,
  &.div_with_full input,
  &.div_with_timer input {
    height: 90%;
    width: 100%;
    background: #f2f2f2;
    border-radius: 77px;
    outline: none;
    border: none;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: bold;

    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1c1c1c;

    padding-left: 24px;

    word-wrap: break-word;
  }

  &.div_hash input {
    padding-left: 24px;
    height: 90%;
    width: 100%;
    background: unset;
    border: none;

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: -0.05em;

    color: ##1c1c1c;
  }

  &.div_with_full_custom_height div,
  &.div_with_full div,
  &.div_with_timer div {
    width: 10%;
    text-align: right;
    vertical-align: middle;
    margin-right: 24px;
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }

  &.div_hash div {
    width: 0%;
  }
  &.div_with_full_custom_height span,
  &.div_with_full div span,
  &.div_with_timer div span,
  &.div_hash div span {
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #1fb09f;
  }

  &.div_with_full_custom_height img,
  &.div_with_full div img,
  &.div_hash div img,
  &.div_with_timer div img {
    width: 13px; /*고정*/
    vertical-align: middle;
  }

  &.mb_10 {
    margin-bottom: 10px;
  }

  &.div_plan_title {
    width: 100%;

    border: none;
    display: flex;
    vertical-align: middle;
    align-items: center;

    height: 49px;

    border: none;
    border-radius: 7px;
  }

  &.div_plan_title input {
    width: 100%;
    height: 90%;
    background: unset;
    border-radius: 7px;
    border: none;

    /* Title 02 */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.02em;

    /* Gray 04 */
    color: #242424;

    &::placeholder {
      color: #a2a3a7;
    }
  }
  &.div_plan_title input:focus {
    outline: none;
  }
`
