import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import styles from './styles/CustomChip.module.css'
import { SelectChipsType } from 'src/utils/common/type/type'
import LoadingContainer from '../container/LoadingContainer'

type ChipType = {
  registerFn?: React.InputHTMLAttributes<HTMLInputElement>
  onClick?: () => void
  styleId?: string
  item: SelectChipsType | null
  readOnly: boolean
}

export default function CustomChip({ readOnly, item, styleId, onClick, registerFn }: ChipType) {
  if (!item) {
    return <LoadingContainer styleId="custom-chip" />
  }
  const onClickChip = () => {
    if (readOnly) return
    onClick?.()
  }

  return (
    <>
      <div className={`${divideStyleIDString(styles, styleId)}`} onClick={onClickChip}>
        {item.title}
      </div>
      <input type="checkbox" hidden {...registerFn} />
    </>
  )
}
