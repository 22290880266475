import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { getWeatherInfo } from 'src/utils/common/scripts/commonJs'
import {
  deleteDataTanstackWithToken,
  getDataTanstack,
  getDataTanstackWithToken,
  patchDataTanstackWithToken,
} from 'src/utils/common/api/http'
import { MemberAddressType, ProfileInfoType, todoType, weatherInfoType } from 'src/utils/common/type/type'
import { toast } from 'react-toastify'
import {
  caseAddressType,
  castPlanListType,
  castProfileInfoType,
  castRecPlantListType,
  isObject,
} from 'src/utils/common/scripts/checkType'
import { useCheckTokenValidity } from 'src/utils/common/hooks/customHook'
import { useRecoilState } from 'recoil'
import { selectedDirayDate, userNmStts } from 'src/utils/common/state/state'
import { formatDateToString } from 'src/utils/common/scripts/common'
import { queryClient } from 'src/App'
import { useParams } from 'react-router-dom'

export function UseUserInfo() {
  const { address, setCurloc, setAddress, loadingAddress } = useGetAddress()
  const [profile, setProfile] = useState<ProfileInfoType | null>(null)
  const [userNm, setUserNm] = useRecoilState(userNmStts)

  const { data, refetch, isSuccess, isPending, error, isError } = useQuery({
    queryKey: [
      'my-info',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}auth-service/member`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  useEffect(() => {
    refetch()
    // getRefreshToken()
  }, [])

  useEffect(() => {
    if (isObject(data) && isObject(data?.data)) {
      const castObj = castProfileInfoType(data)
      if (castObj) {
        if (castObj && castObj.data.address && castObj.data.location) {
          setAddress(`${castObj.data.address.sigungu} ${castObj.data.address.eupmyundong}`)
        } else {
          navigator.geolocation.getCurrentPosition(position => {
            const { latitude, longitude } = position.coords
            setCurloc({
              lat: latitude,
              lon: longitude,
            })
            castObj!.data!.location = {
              latitude: latitude,
              longitude: longitude,
            }
          })
        }
      }

      let name = castObj?.data.nickname || ''

      if (name?.includes('새회원')) {
        name = '새회원'
      }

      setProfile(castObj)
      setUserNm({
        params: name,
      })
    }
  }, [data])
  return {
    profile,
    isPending,
    loadingAddress,
    address,
  }
}

export function profilePageUserInfo() {
  const [profile, setProfile] = useState<ProfileInfoType | null>(null)
  const { userId } = useParams()

  const {
    data: myInfo,
    refetch,
    isSuccess,
    isPending,
    error,
    isError,
  } = useQuery({
    queryKey: [
      'my-info',
      {
        searchParam: userId && userId !== 'myprofile' && typeof Number(userId) === 'number' ? `/${userId}` : '',
        url: `${process.env.REACT_APP_API_DOMAIN}auth-service/member`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  const castData = (data: any) => {
    if (isObject(data) && isObject(data?.data)) {
      const castObj = castProfileInfoType(data)

      let name = castObj?.data.nickname || ''

      if (name?.includes('새회원')) {
        name = '새회원'
      }

      setProfile(castObj)
    }
  }

  useEffect(() => {
    castData(myInfo)
  }, [myInfo])

  useEffect(() => {
    refetch()
  }, [])
  return {
    profile,
    isPending,
    refetch,
    isSuccess,
  }
}

export function useMemberInfo() {
  const [profile, setProfile] = useState<ProfileInfoType | null>(null)

  const { data, refetch, isSuccess, isPending, error, isError } = useQuery({
    queryKey: [
      'member-info',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}auth-service/member`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  useEffect(() => {
    refetch()
    // getRefreshToken()
  }, [])

  useEffect(() => {
    if (isObject(data) && isObject(data?.data)) {
      const castObj = castProfileInfoType(data)

      let name = castObj?.data.nickname || ''

      if (name?.includes('새회원')) {
        name = '새회원'
      }

      setProfile(castObj)
    }
  }, [data])
  return {
    memberProfile: profile,
    pendingMemberInfo: isPending,
  }
}

export function useGetAddress() {
  const [curloc, setCurloc] = useState<{
    lat: undefined | number | string
    lon: undefined | number | string
  }>({
    lat: undefined,
    lon: undefined,
  })
  const [address, setAddress] = useState('')

  const { data, refetch, isSuccess, isPending, error, isError } = useQuery({
    queryKey: [
      'my-loc',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}auth-service/address?latitude=${curloc.lat}&longitude=${curloc.lon}`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  useEffect(() => {
    if (curloc.lat !== undefined && curloc.lon !== undefined) {
      refetch()
    }
  }, [curloc, refetch])

  useEffect(() => {
    if (isObject(data) && isObject(data?.data)) {
      const castObj = caseAddressType(data)
      const str = `${castObj?.data.sigungu} ${castObj?.data.eupmyundong}`

      setAddress(str)
    }
  }, [data])
  return {
    setCurloc,
    address,
    setAddress,
    loadingAddress: isPending,
  }
}

export function UseMainHomeApi({ loc }: { loc: MemberAddressType }) {
  const [wlocation, setwLocation] = useState<{
    lat: undefined | number | string
    lon: undefined | number | string
  }>({
    lat: undefined,
    lon: undefined,
  })

  const [weatherInfo, setWeatherInfo] = useState<weatherInfoType>({
    weather: undefined,
    temp: undefined,
    humidity: undefined,
    wind: undefined,
    rain: undefined,
    icon: undefined,
    min: undefined,
    max: undefined,
    discription: undefined,
  })

  const {
    data: weather,
    refetch,
    isPending,

    isSuccess,
  } = useQuery({
    queryKey: [
      'forget',
      {
        searchParam: `${process.env.REACT_APP_OPEN_API_WEATHER_KEY}`,
        url: `${process.env.REACT_APP_OPEN_API_WEATHER}?lat=${wlocation.lat}&lon=${wlocation.lon}&appid=`,
      },
    ],
    queryFn: getDataTanstack,
    enabled: false,
  })

  useEffect(() => {
    if (loc) {
      setwLocation({
        lat: loc.loc.latitude,
        lon: loc.loc.longitude,
      })
    }

    // if (loc && loc.address && loc.loc) {

    //   setwLocation({
    //     lat: loc.loc.latitude,
    //     lon: loc.loc.longitude,
    //   })
    // } else {
    //   navigator.geolocation.getCurrentPosition(position => {
    //     const { latitude, longitude } = position.coords
    //     setwLocation({
    //       lat: latitude,
    //       lon: longitude,
    //     })
    //     setCurloc({
    //       lat: latitude,
    //       lon: longitude,
    //     })
    //   })
    // }
  }, [loc])

  useEffect(() => {
    if (isSuccess && weather) {
      if (weather['weather'] && weather['main'] !== undefined && weather['wind']) {
        const result = getWeatherInfo(weather)

        if (
          typeof result.weather === 'string' &&
          typeof result.temp === 'string' &&
          typeof result.wind === 'string' &&
          typeof result.temp === 'string' &&
          typeof result.rain === 'string' &&
          typeof result.icon === 'string' &&
          typeof result.min === 'string' &&
          typeof result.max === 'string' &&
          typeof result.discription === 'string'
        ) {
          setWeatherInfo({
            weather: result.weather,
            temp: result.temp,
            humidity: result.humidity,
            wind: result.wind,
            rain: result.rain,
            icon: result.icon,
            min: result.min,
            max: result.max,
            discription: result.discription,
          })
        }
      }
    }
  }, [isSuccess])

  useEffect(() => {
    if (wlocation.lat !== undefined && wlocation.lon !== undefined) {
      refetch()
    }
  }, [wlocation, refetch])

  return { weatherInfo, weather, wlocation, isSuccess, isPending }
}

export const test = () => {}

// 메인 > 일정 목록
export function useGetPlanListOfMain() {
  const [list, setList] = useState<todoType[] | null>()
  const [type, setType] = useState<string>('today')
  const { data, refetch, isLoading, isSuccess, isPending, isError } = useQuery({
    queryKey: [
      'plan-list',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}my-plant/schedules/main/${type}`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })
  useEffect(() => {
    if (type && type.length > 0) {
      refetch()
    }
  }, [type])

  useEffect(() => {
    if (data && data?.scheduleMainResponseList) {
      const castObj = castPlanListType(data)?.scheduleMainResponseList

      if (castObj) {
        // const filterCastObj = castObj?.filter(item => item.isCompleted === null)

        setList(castObj)
      }
    }
  }, [data])

  return {
    list,
    isPending,
    isLoading,
    isSuccess,
    setType,
    type,
    refetch,
  }
}

// 메인 > 일정 목록
export function useGetPlanListOfCalendar() {
  const [list, setList] = useState<todoType[] | null>()
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDirayDate)
  const [date, setDate] = useState<string>(formatDateToString(selectedDate.date))
  const { data, refetch, isLoading, isSuccess, isPending, isError } = useQuery({
    queryKey: [
      'plan-list',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}my-plant/schedules/calendar/date?date=${date}`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })
  useEffect(() => {
    if (date) {
      refetch()
    }
  }, [date])

  useEffect(() => {
    if (typeof data?.scheduleMainResponseList === 'object') {
      const castObj = castPlanListType(data)?.scheduleMainResponseList

      if (castObj) {
        setList(castObj)
      }
    }
  }, [data])

  return {
    list,
    isPending,
    isLoading,
    isSuccess,
    setDate,
    refetch,
  }
}

// 다른 도감으로 옮기기
export const useChangePlanStts = () => {
  const { checkData } = useCheckTokenValidity()

  const previousEvent = queryClient.getQueryData(['plan-list'])
  const [planData, setPlanData] = useState(previousEvent)

  const { mutate, data, isPending, isError, isSuccess } = useMutation({
    mutationFn: patchDataTanstackWithToken,
    onSuccess: async response => {
      await queryClient.cancelQueries({ queryKey: ['plan-list'] })

      toast.success('일정 처리를 완료하였습니다.')
    },
    onError: (error, context) => {
      toast.error('일정 처리에 실패하였습니다.')
      queryClient.setQueryData(['plan-list'], planData)
      checkData(error)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['plan-list'] })
    },
  })

  const chagePlanStts = (planId: string | number) => {
    const url = `${process.env.REACT_APP_API_DOMAIN}my-plant/schedules/status/${planId}`
    mutate({ url })
  }

  return {
    isErrorChangePlan: isError,
    isSuccessChangePlan: isSuccess,
    chagePlanStts,
    changingPlan: isPending,
  }
}

// 다른 도감으로 옮기기
export const useDeletePlan = () => {
  const { checkData } = useCheckTokenValidity()

  const { mutate, data, isPending, isError, isSuccess } = useMutation({
    mutationFn: deleteDataTanstackWithToken,
    onSuccess: async response => {
      await queryClient.cancelQueries({ queryKey: ['plan-list'] })

      toast.success('일정 처리를 완료하였습니다.')
    },
    onError: (error, context) => {
      toast.error('일정 처리에 실패하였습니다.')

      checkData(error)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['plan-list'] })
    },
  })

  const deletePlan = (schedulesId: string | number) => {
    const url = `${process.env.REACT_APP_API_DOMAIN}my-plant/schedules/${schedulesId}`
    mutate({ url })
  }

  return {
    isErrorDeletePlan: isError,
    isSuccessDeletePlan: isSuccess,
    deletePlan,
    deleting: isPending,
  }
}
