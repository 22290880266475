import styles from './styles/MainFindResult.module.css'

import MiddleContainer from '../../../common/container/MiddleContainter'
import { CustomButton } from '../../../common/button/CustomButton'
import DualButtonLayout from '../../../common/button/DualButtonLayout'
import SubtitleContainer from 'src/components/common/container/SubtitleContainer'
import { useNavigate } from 'react-router-dom'

export default function MainFindIdResult({ email }: { email: string }) {
  const navigate = useNavigate()
  return (
    <div className={styles.div_contianer}>
      <SubtitleContainer title="입력하신 정보와 일치하는" title2="아이디는 아래와 같습니다." />
      <MiddleContainer styleID="div_mid_container mt_2p7_vh" text={email} />
      <DualButtonLayout styleID="div_dual_btn mt_27_percent">
        <CustomButton
          onClick={() => {
            navigate('/login')
          }}
          styleID="btn_dual_01"
          type="button"
          height=""
          width=""
        >
          로그인
        </CustomButton>
        <CustomButton
          onClick={() => {
            navigate('/main/find/pwd')
          }}
          styleID="btn_dual_02"
          type="button"
          height=""
          width=""
        >
          비밀번호 재설정
        </CustomButton>
      </DualButtonLayout>
    </div>
  )
}
