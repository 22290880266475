import FixedButtonLayout from 'src/components/common/layout/FixedButtonLayout'
import PlazaCommentList from './PlazaCommentList'
import { CustomButton } from 'src/components/common/button/CustomButton'
import { useState } from 'react'
import DrawerChat from 'src/components/common/layout/DrawerChat'
import styled from 'styled-components'
import PlazaCommentInput from './PlazaCommentInput'
import { useGetComments } from '../hooks/PlazaCreateCustomHook'

export default function PlazaCommentForm({
  postId,
  onClick,
  nickName,
}: {
  onClick: () => void

  postId: string | number
  nickName: string
}) {
  const { result, setArticleId, isLoading, requestApi, isSuccess } = useGetComments()
  const [inputOpen, setInputOpen] = useState(false)
  const [drawerH, setDrawerH] = useState(34)
  const [showBtn, setShowBtn] = useState(true)
  const [commentType, setCommentType] = useState('COMMENT')
  const [targetObj, setTargetObj] = useState({
    id: postId,
    targetNm: nickName,
  })
  const [closeInner, setCloseInner] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [updateObj, setUpdateObj] = useState<{
    id: string | number
    content: string
    name: string
  } | null>(null)

  const [responseStts, setResponseStts] = useState('PENDING')

  return (
    <>
      <PlazaCommentList
        result={result}
        setArticleId={setArticleId}
        isLoading={isLoading}
        requestApi={requestApi}
        onClickUpdateComment={(id: string | number, content: string, name: string) => {
          setIsUpdate(true)
          setUpdateObj({
            id,
            content,
            name,
          })
          setInputOpen(true)
          setShowBtn(false)
        }}
        postId={postId}
        onSwitchCommentType={(type, targetName, id, readOnly) => {
          setCommentType(type)
          if (id) {
            setTargetObj({ id, targetNm: targetName === 'POST_USER' ? nickName : targetName })
          } else {
            setTargetObj({ id: postId, targetNm: nickName })
          }

          if (readOnly === false) {
            setInputOpen(true)
            setShowBtn(false)
          }
        }}
      />
      <FixedButtonLayout p="absolute" bottom="10px">
        {showBtn ? (
          <>
            <Wrap>
              <CustomButton
                onClick={() => {
                  setInputOpen(true)
                  setShowBtn(false)
                }}
                styleID="btn_add_comment"
                type="button"
              >
                {commentType === 'REPLY'
                  ? `${targetObj.targetNm} 님에게 답글 추가...`
                  : `${nickName} 님에게 댓글 추가...`}
              </CustomButton>
            </Wrap>
          </>
        ) : (
          <></>
        )}
      </FixedButtonLayout>
      <DrawerChat
        pt={2.125}
        height={`${drawerH}px`}
        title={commentType === 'REPLY' ? '답글' : '댓글'}
        open={inputOpen}
        onClose={() => {
          setInputOpen(false)
          setShowBtn(true)
          setDrawerH(34)
          setCloseInner(false)
          setIsUpdate(false)
          setUpdateObj(null)
          if (responseStts !== 'PENDING') {
            if (responseStts === 'SUCCESS') {
              requestApi()
            }
            setResponseStts('PENDING')
          }
        }}
        closeInner={closeInner}
        children={
          <>
            <PlazaCommentInput
              nickName={targetObj.targetNm}
              isUpdate={isUpdate}
              targetId={targetObj.id}
              updateObj={updateObj}
              commentType={commentType}
              onResizeHeight={(h: number) => {
                setDrawerH(h - 27)
              }}
              onFinish={(type: string) => {
                setCloseInner(true)
                setResponseStts(type)
              }}
            />
          </>
        }
      />
    </>
  )
}

const Wrap = styled.div`
  z-index: 10;
  background: white;
  padding-top: 20px;
  border-top: 1px solid #dadada;
`
