import { useEffect, useState } from 'react'
import WeeklyCalendarMain from './WeeklyCalendarMain'
import MonthlyCalendarMain from './MonthlyCalendarMain'
import { useRecoilState } from 'recoil'
import { selectCalendarType, selectedDirayDate } from 'src/utils/common/state/state'
import { CalendarEventType } from 'src/utils/common/type/type'
import { useForm } from 'react-hook-form'

export default function CalendarMain({
  viewAll = false,
  readOnly = false,
  onChangeDate,
  events,
  onRequestInfo,
}: {
  viewAll?: boolean
  readOnly?: boolean
  onChangeDate: (start: Date, end: Date) => void
  events: CalendarEventType[] | null
  onRequestInfo: (date: Date) => void
}) {
  const [isWeek, setIsWeek] = useRecoilState(selectCalendarType)
  // const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDirayDate)
  const { watch } = useForm()

  const onClickViewTypeBtn = () => {
    setIsWeek(prev => !prev)
  }

  useEffect(() => {
    const startOfWeek = getStartOfWeek(selectedDate.date)
    if (isWeek) {
      const startOfWeekDate = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate())
      const endOfWeekDate = new Date(startOfWeekDate)
      endOfWeekDate.setDate(endOfWeekDate.getDate() + 6)
      onChangeDate(startOfWeekDate, endOfWeekDate)
    } else {
      const startOfMonthDate = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), 1)
      const endOfMonthDate = new Date(startOfMonthDate.getFullYear(), startOfMonthDate.getMonth() + 1, 0)
      onChangeDate(startOfMonthDate, endOfMonthDate)
    }
  }, [isWeek])

  const handleSelectedDate = (date: Date) => {
    setSelectedDate({ date })
    onRequestInfo(date)
  }

  function getStartOfWeek(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay())
  }

  return (
    <>
      {isWeek ? (
        <WeeklyCalendarMain
          events={events}
          viewAll={viewAll}
          readOnly={readOnly}
          onChangeWeek={onChangeDate}
          selectedDate={selectedDate.date}
          onClickDate={handleSelectedDate}
          onClickMonthBtn={onClickViewTypeBtn}
        />
      ) : (
        <MonthlyCalendarMain
          events={events}
          onChangeMonth={onChangeDate}
          viewAll={viewAll}
          readonly={readOnly}
          selectedDate={selectedDate.date}
          onClickDate={handleSelectedDate}
          onClickMonthBtn={onClickViewTypeBtn}
        />
      )}
    </>
  )
}
