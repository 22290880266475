import { FollowType } from 'src/utils/common/type/type'
import FollowItem from './FollowItem'
import { useLocation } from 'react-router-dom'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import NoDataContainer from 'src/components/common/container/NoDataContainer'
import EndLine from 'src/components/common/line/EndLie'
import React, { useEffect, useState } from 'react'
import NoDataContainer2 from 'src/components/common/container/NoDataContainer2'

const FollowerList = function FollowerList({
  loadingList,
  list,
  changePage,
  isFollowerPage,
  afterFollow,
}: {
  changePage: () => void
  loadingList: boolean
  isFollowerPage: boolean
  list: FollowType[] | undefined
  afterFollow: (id: number | string, followYn: boolean) => void
}) {
  if (list === undefined || (list === undefined && loadingList)) {
    return <PendingFollowList afterFollow={afterFollow} />
  }

  if (list && list.length === 0 && !loadingList) {
    return (
      <>
        <NoFollowList isFollowerPage={isFollowerPage} />
      </>
    )
  }

  return (
    <>
      {list?.map((item, index) => {
        return (
          <>
            <FollowItem
              mt={index === 0 ? '0px' : '16px'}
              isFollowerPage={isFollowerPage}
              key={generateRandomKey()}
              afterFollow={afterFollow}
              defaultVal={item.isFollowed} // 나중에 변경해야 함
              item={item}
            />
          </>
        )
      })}
      <EndLine hidden={true} onShowEndPage={changePage} />
    </>
  )
}

const PendingFollowList = function PendingFollowList({
  afterFollow,
}: {
  afterFollow: (id: number | string, followYn: boolean) => void
}) {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <FollowItem
          mt={index === 0 ? '0px' : '16px'}
          isFollowerPage={true}
          key={generateRandomKey()}
          afterFollow={afterFollow}
          defaultVal={false}
          item={undefined}
        />
      ))}
    </>
  )
}

const NoFollowList = function NoFollowList({ isFollowerPage }: { isFollowerPage: boolean }) {
  return (
    <>
      {isFollowerPage ? (
        <>
          <NoDataContainer2
            mt="50px"
            ac="center"
            title="팔로워 목록이 없어요"
            discription="다양한 사람들을 팔로우 해보세요!"
          />
        </>
      ) : (
        <>
          <NoDataContainer2
            mt="50px"
            ac="center"
            title="팔로잉 목록이 없어요"
            discription="다양한 사람들을 팔로우 해보세요!"
          />
        </>
      )}
    </>
  )
}

export default FollowerList
