import { useForm } from 'react-hook-form'
import PlantListCommon from 'src/components/common/content/PlantListCommon'
import FormMain from 'src/components/common/form/FormMain'
import SelectBoxLayout from 'src/components/common/layout/SelectBoxLayout'
import Forward from '../../../../assets/images/input/forward.png'
import {
  CONTENT_PADDING,
  CONTENT_PADDING_BOTTOM,
  CONTENT_PADDING_TOP,
  PLANT_CARE,
  SAMPLE_PLANT,
} from 'src/utils/common/constants/constants'
import { useCaringCustomHook, useMarketCustomHook } from '../hook/CaringCustomHook'
import ChoiceChips from 'src/components/common/form/sub/select/ChoiceChips'
import CustomCalendar from 'src/components/common/form/sub/calendar/CustomCalendar'
import CustomDualCalendar from 'src/components/common/form/sub/calendar/CustomDualCalendar'
import { useEffect, useState } from 'react'
import Title from 'src/components/common/title/Title'
import { Box } from '@mui/material'
import CustomLocation from 'src/components/common/form/sub/loc/Location'
import LocationSelect from 'src/components/common/form/sub/loc/LocationSelect'
import styled from 'styled-components'

export default function MarketForm({ onClick }: { onClick: () => void }) {
  const { register, getValues, setValue, watch, handleSubmit } = useForm()
  const [editLoc, setEditLoc] = useState(false)
  const [locationInfo, setLocationInfo] = useState('거래 희망 장소를 선택해 주세요')

  const {
    checkAll,
    checkImgCnt,
    checkContent,
    selectedKey,
    isValidObj,
    confirmed,
    formAlert,
    checkTitle,
    checkCheckBox,
    checkPrice,
    checkLoc,
    setTrue,
    setFalse,
  } = useMarketCustomHook()

  const onClickSubmitBtn = (data: { [key: string]: string | { [key: string]: boolean } }) => {
    console.log(data)
    console.log(data)
    if (!confirmed) {
      checkAll({
        price: getValues('price'),
        location: getValues('location'),

        title: getValues('title'),
        content: getValues('content'),
        fileCnt: getValues('fileCnt'),
      })
    }
  }

  useEffect(() => {
    const subscirbe = watch((data, { name }) => {
      if (name === 'title') {
        checkTitle(getValues('title'))
      } else if (name === 'content') {
        checkContent(getValues('content'))
      } else if (name === 'price') {
        checkPrice(getValues('price'))
      } else if (name === 'fileCnt') {
        checkImgCnt(getValues('fileCnt'))
      } else if (name === 'location') {
        checkLoc(getValues('location'))
      }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])

  return (
    <>
      <Title
        styleID="div_container"
        onClick={() => {
          onClick()
        }}
        close={true}
        title="마켓 글쓰기"
        comp={
          <>
            <p>임시저장</p>
          </>
        }
      />

      <FormMain onSubmit={handleSubmit(onClickSubmitBtn)}>
        <Wrap>
          <Box sx={{ p: CONTENT_PADDING, pt: CONTENT_PADDING_TOP, pb: CONTENT_PADDING_BOTTOM }}>
            <FormMain.LabelLayout
              infoStyleId=""
              isFirst={true}
              warning={isValidObj && isValidObj.plant === false ? true : false}
              warnText={formAlert && formAlert.plant ? formAlert.plant : ''}
              label="내 식물 불러오기(선택)"
              infoText="내 식물을 불러오면 식물 일지도 함께 조회됩니다."
            >
              <SelectBoxLayout
                label="내 식물 선택"
                watch={watch}
                divContentWrapStyleId="my_plant_wrap"
                getValues={getValues}
                name="plant"
                children={
                  <>
                    <PlantListCommon
                      registerFn={register('plant', {
                        required: false,
                      })}
                      register={register}
                      setValue={setValue}
                      getValues={getValues}
                      name="plant"
                      firstmt="6px"
                      padding="10px 0px 10px 24px"
                      height="246px"
                      itemArr={SAMPLE_PLANT}
                    />
                  </>
                }
              />
            </FormMain.LabelLayout>

            <FormMain.LabelLayout
              infoStyleId="text_red"
              warning={isValidObj && isValidObj.title === false ? true : false}
              warnText={formAlert && formAlert.title ? formAlert.title : ''}
              label="제목"
            >
              <FormMain.InputFull
                styleId={
                  isValidObj && isValidObj.title === false
                    ? 'div_plant_02 wdt_full mb_10 border_red'
                    : 'div_plant_02 wdt_full mb_10'
                }
                inputComp={
                  <FormMain.Input type="text" placeholder="제목을 입력해주세요" registerFn={register('title', {})} />
                }
              />
            </FormMain.LabelLayout>
            <FormMain.LabelLayout
              infoStyleId="text_red"
              warning={isValidObj && isValidObj.price === false ? true : false}
              warnText={formAlert && formAlert.price ? formAlert.price : ''}
              label="수고 비용"
            >
              <FormMain.InputFull
                styleId={
                  isValidObj && isValidObj.price === false
                    ? 'div_plant_02 wdt_full mb_10 border_red'
                    : 'div_plant_02 wdt_full mb_10'
                }
                inputComp={
                  <FormMain.Input type="text" placeholder="가격을 입력해주세요" registerFn={register('price', {})} />
                }
              />
            </FormMain.LabelLayout>
            <FormMain.LabelLayout
              warning={isValidObj && isValidObj.file === false ? true : false}
              warnText={formAlert && formAlert.file ? formAlert.file : ''}
              label="사진"
            >
              <FormMain.ImageFile
                setTrue={setTrue}
                setFalse={setFalse}
                warning={isValidObj && isValidObj.file === false ? true : false}
                registerFn={register('file', {
                  required: false,
                })}
                name="file"
                register={register}
                getValues={getValues}
                setValue={setValue}
              />
            </FormMain.LabelLayout>

            <FormMain.LabelLayout
              warning={isValidObj && isValidObj.location === false ? true : false}
              warnText={formAlert && formAlert.location ? formAlert.location : ''}
              label="거래 희망 장소"
            >
              <>
                <CustomLocation
                  onClick={() => {
                    setEditLoc(true)
                  }}
                  title={locationInfo}
                  iconComp={
                    <>
                      <img style={{ width: '9px', height: '17px' }} src={Forward} alt="거래 희망 장소 선택" />
                    </>
                  }
                />
              </>
            </FormMain.LabelLayout>
            <FormMain.LabelLayout
              warning={isValidObj && isValidObj.content === false ? true : false}
              warnText={formAlert && formAlert.content ? formAlert.content : ''}
              label="내용"
            >
              <FormMain.TextArea
                warning={isValidObj && isValidObj.content === false ? true : false}
                registerFn={register('content', {
                  required: false,
                })}
                placeholder="내용을 입력해주세요"
              />
            </FormMain.LabelLayout>
            <FormMain.Button
              styleID={confirmed === true ? 'btn_submit_01_active mt_52_px' : 'btn_submit_01 mt_52_px'}
              width="100%"
              marginTop="26px"
              type="submit"
            >
              작성 완료
            </FormMain.Button>
          </Box>
        </Wrap>
        {/* <LocationSelect
          setValue={setValue}
          isVisible={editLoc}
          onClick={() => {
            setEditLoc(false)
            if (getValues('location')) {
              setLocationInfo(getValues('location'))
            }
          }}
          onClose={() => {
            setEditLoc(false)
          }}
          watch={watch}
          name="location"
          register={register}
          registerFn={register('location', {
            required: false,
          })}
          getValues={getValues}
        /> */}
      </FormMain>
    </>
  )
}

const Wrap = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
`
