import { useState, useEffect } from 'react'
import MonthlyCalendar from './MonthlyCalendar' // WeeklyCalendar 대신 MonthlyCalendar import
import styled from 'styled-components'
import PrevIcon from '../icon/PrevIcon'
import CalendarPrevBtn from '../../../assets/images/icon/CalendarPrevBtn.png'
import CalendarNextBtn from '../../../assets/images/icon/CalendarNextBtn.png' // 변경된 이미지 경로
import CalendarNextBtn2 from '../../../assets/images/icon/CalendarNextBtn2.png' // 변경된 이미지 경로
import { CustomButton } from '../button/CustomButton'
import { CalendarEventType } from 'src/utils/common/type/type'
import { useForm } from 'react-hook-form'

type MonthlyCalendarType = {
  onClickMonthBtn: () => void
  selectedDate: Date
  onClickDate: (date: Date) => void
  readonly?: boolean
  selectNext?: boolean
  viewAll?: boolean
  onChangeMonth: (start: Date, end: Date) => void
  events: CalendarEventType[] | null
}

export default function MonthlyCalendarMain({
  events,
  readonly = true,
  viewAll = false,
  selectNext = false,
  onClickMonthBtn,
  selectedDate,
  onClickDate,
  onChangeMonth,
}: MonthlyCalendarType) {
  const [startOfMonth, setStartOfMonth] = useState<Date>(
    new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1),
  )

  const [nextButtonSrc, setNextButtonSrc] = useState<string>(viewAll ? CalendarNextBtn2 : CalendarNextBtn) // 초기값을 CalendarNextBtn으로 설정
  const year = startOfMonth.getFullYear()
  const month = startOfMonth.getMonth() + 1
  const today = new Date()

  // 이전 달 표시
  const handlePrevMonth = () => {
    setStartOfMonth(new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() - 1, 1))
  }

  // 다음 달 표시
  const handleNextMonth = () => {
    setStartOfMonth(new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() + 1, 1))
  }

  const handleSelectedDate = (date: Date) => {
    onClickDate(date)
  }

  useEffect(() => {
    // 오늘의 날짜를 포함하거나 초과한 주인 경우
    const startOfMonthDate = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth(), 1)
    const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

    // startOfMonthDate에 마지막 날짜를 구하는 방식
    const endOfMonthDate = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() + 1, 0)
    // 전체보기가 아닌 경우
    if (!viewAll) {
      if (startOfMonthDate.getTime() > todayDate.getTime() || startOfMonthDate.getMonth() === todayDate.getMonth()) {
        setNextButtonSrc(CalendarNextBtn) // CalendarNextBtn으로 변경
      } else if (endOfMonthDate.getTime() >= todayDate.getTime()) {
        setNextButtonSrc(CalendarNextBtn) // CalendarNextBtn으로 변경
      } else {
        setNextButtonSrc(CalendarNextBtn2) // 기존 이미지 경로로 변경
      }
    }
    onChangeMonth(startOfMonthDate, endOfMonthDate)
  }, [startOfMonth])

  return (
    <CalendarWrap>
      <div className="div_head">
        <h2 className="title">
          {year}년 {month}월
        </h2>
        <div className="div_button">
          <PrevIcon onClick={handlePrevMonth}>
            <img style={{ verticalAlign: 'super', marginRight: '43px' }} src={CalendarPrevBtn} alt="이전달" />
          </PrevIcon>

          <PrevIcon onClick={handleNextMonth}>
            {selectNext ? (
              <img
                style={{ verticalAlign: 'super', marginRight: readonly ? '21px' : '0px' }}
                src={CalendarNextBtn2}
                alt="다음달"
              />
            ) : (
              <img
                style={{ verticalAlign: 'super', marginRight: readonly ? '21px' : '0px' }}
                src={nextButtonSrc}
                alt="다음달"
              />
            )}
          </PrevIcon>
          {readonly && (
            <CustomButton
              onClick={() => {
                onClickMonthBtn()
              }}
              styleID="btn_diary_detail_cal"
              type="button"
            >
              월간
            </CustomButton>
          )}
        </div>
      </div>

      <MonthlyCalendar
        viewAll={viewAll}
        events={events}
        selectNext={selectNext}
        readonly={readonly}
        disabledDate={today}
        onClickDate={handleSelectedDate}
        selectedDate={selectedDate}
        startOfMonth={startOfMonth}
      />
    </CalendarWrap>
  )
}

const CalendarWrap = styled.div`
  .div_head {
    justify-content: space-between;
    display: flex;
    margin-bottom: 15px;
  }
  .title {
    font-family: 'Pretendard-Regular';
    /* 2024년 4월 */

    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;

    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }
  .div_button {
    display: flex;
  }
`
