/*
    받은 response가 맞는 타입인지 확인하는 스크립트
    날짜 : 2024-06-13
*/

import {
  AddressType,
  CollectionDetailType,
  CollectionListType,
  CommentListType,
  CommentResponseType,
  FollowerListType,
  FollowingListType,
  IdentifyType,
  PlanListType,
  PlantDetailInfo,
  PlantDrfullInfo,
  PlazaPostListType,
  PlazaPostType,
  PostListType,
  PostType,
  ProfileInfoType,
  RcntSearhedType,
  RcntVwdItem,
  RecPlantListType,
  RefreshTokenType,
  SearchArrType,
  TopSearchedResType,
  diaryDetailType,
  diaryListType,
  planListType,
  planMainListType,
  plantDetailInfoType,
  plantTypeObj,
  plantTypeType,
} from '../type/type'

export function test() {}
// Objcet인지 확인
export function isObject(value: any): boolean {
  return value && typeof value === 'object' && Object.prototype.toString.call(value) === '[object Object]'
}

// string 배열인지 확인 : 삭제
function isArrayOfStrings(arr: any): boolean {
  return Array.isArray(arr) && arr.every(item => typeof item === 'string')
}

// string 배열인지 확인 : 삭제
export function reFormatArrayOfStrings(arr: any): string[] {
  if (!arr) return []

  if (isArrayOfStrings(arr)) {
    return arr
  } else {
    return []
  }
}

// 식물 정보로 타입 캐스트
export const castType = (data: any) => {
  if (isObject(data)) {
    const tempData = data as PlantDetailInfo
    return tempData || null
  } else {
    return null
  }
}

// 식물 정보로 타입 캐스트
export const castPlantDetailInfoType = (data: any): plantDetailInfoType | null => {
  if (isObject(data)) {
    const tempData = data as plantDetailInfoType
    return tempData || null
  } else {
    return null
  }
}

// 식물 정보로 타입 캐스트
export const castDiaryList = (data: any): diaryListType | null => {
  if (isObject(data)) {
    const tempData = data as diaryListType
    return tempData || null
  } else {
    return null
  }
}

export const castPlanList = (data: any): planListType | null => {
  if (isObject(data)) {
    const tempData = data as planListType
    return tempData || null
  } else {
    return null
  }
}

export const castDiary = (data: any): diaryDetailType | null => {
  if (isObject(data)) {
    const tempData = data as diaryDetailType
    return tempData || null
  } else {
    return null
  }
}

export const castPlazaPostType = (data: any): PlazaPostType | null => {
  if (isObject(data)) {
    const tempData = data as PlazaPostType
    return tempData || null
  } else {
    return null
  }
}

export const castPlazaPostListType = (data: any): PlazaPostListType[] | null => {
  if (isObject(data)) {
    const tempData = data as PlazaPostListType[]
    return tempData || null
  } else {
    return null
  }
}

export const castTypeSearchArr = (data: any) => {
  if (isObject(data)) {
    const tempData = data as SearchArrType
    return tempData || null
  } else {
    return null
  }
}

export const castPlantDrfullType = (data: any) => {
  if (isObject(data)) {
    const tempData = data as PlantDrfullInfo
    return tempData || null
  } else {
    return null
  }
}

export const castIdentifyType = (data: any) => {
  if (isObject(data)) {
    const tempData = data as IdentifyType
    return tempData || null
  } else {
    return null
  }
}

// 식물 정보로 타입 캐스트
export const castPlantTypeType = (data: any): plantTypeType | null => {
  if (isObject(data)) {
    const tempData = data as plantTypeType
    return tempData || null
  } else {
    return null
  }
}

export const castPlantTypeObj = (data: any): plantTypeObj | null => {
  if (isObject(data)) {
    const tempData = data as plantTypeObj
    return tempData || null
  } else {
    return null
  }
}

export const castProfileInfoType = (data: any): ProfileInfoType | null => {
  if (isObject(data)) {
    const tempData = data as ProfileInfoType
    return tempData || null
  } else {
    return null
  }
}

export const caseAddressType = (data: any): AddressType | null => {
  if (isObject(data)) {
    const tempData = data as AddressType
    return tempData || null
  } else {
    return null
  }
}

export const castCollectionListType = (data: any): CollectionListType | null => {
  if (isObject(data)) {
    const tempData = data as CollectionListType
    return tempData || null
  } else {
    return null
  }
}

export const castRcntVwdType = (data: any): RcntSearhedType | null => {
  if (isObject(data)) {
    const tempData = data as RcntSearhedType
    return tempData || null
  } else {
    return null
  }
}

export const castRecPlantListType = (data: any): RecPlantListType | null => {
  if (isObject(data)) {
    const tempData = data as RecPlantListType
    return tempData || null
  } else {
    return null
  }
}

export const castRcntVwdItemType = (data: any): RcntVwdItem[] | null => {
  if (isObject(data)) {
    const tempData = data as RcntVwdItem[]
    return tempData || null
  } else {
    return null
  }
}

export const castPlanListType = (data: any): PlanListType | null => {
  if (isObject(data)) {
    const tempData = data as PlanListType
    return tempData || null
  } else {
    return null
  }
}

export const castPlanMainListType = (data: any): planMainListType | null => {
  if (isObject(data)) {
    const tempData = data as planMainListType
    return tempData || null
  } else {
    return null
  }
}

export const castCollectionDetailType = (data: any): CollectionDetailType | null => {
  if (isObject(data)) {
    const tempData = data as CollectionDetailType
    return tempData || null
  } else {
    return null
  }
}

export const castTopSearchedType = (data: any): TopSearchedResType | null => {
  if (isObject(data)) {
    const tempData = data as TopSearchedResType
    return tempData || null
  } else {
    return null
  }
}

export const castPostListType = (data: any): PostListType | null => {
  if (isObject(data)) {
    const tempData = data as PostListType
    return tempData || null
  } else {
    return null
  }
}

export const castPostType = (data: any): PostType | null => {
  if (isObject(data)) {
    const tempData = data as PostType
    return tempData || null
  } else {
    return null
  }
}

export const castCommentResponseType = (data: any): CommentResponseType | null => {
  if (isObject(data)) {
    const tempData = data as CommentResponseType
    return tempData || null
  } else {
    return null
  }
}

export const castFollowingListType = (data: any): FollowingListType | null => {
  if (isObject(data)) {
    const tempData = data as FollowingListType
    return tempData || null
  } else {
    return null
  }
}

export const castFollowerListType = (data: any): FollowerListType | null => {
  if (isObject(data)) {
    const tempData = data as FollowerListType
    return tempData || null
  } else {
    return null
  }
}

// Cast an array of objects to an array of plantTypeType
export const castPlantTypeTypeArray = (dataArray: any[]): plantTypeType[] => {
  return dataArray.filter(isObject).map(item => item as plantTypeType)
}

export const castRefreshTokenType = (data: any): RefreshTokenType | null => {
  if (isObject(data)) {
    const tempData = data as RefreshTokenType
    return tempData || null
  } else {
    return null
  }
}
