import React, { useEffect, useState } from 'react'
import styles from '../../styles/Calendar.module.css'
import Calendar from 'react-calendar'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/ko' // 한국어 로케일을 로드

import DropDownIcon from '../../../../../assets/images/icon/DropDownIcon.png'
import CalendarPrevBtn from '../../../../../assets/images/icon/CalendarPrevBtn.png'
import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import CalendarNextBtn from '../../../../../assets/images/icon/CalendarNextBtn.png'
import CalendarNextBtn2 from '../../../../../assets/images/icon/CalendarNextBtn2.png'

import { useForm } from 'react-hook-form'
import MonthlyCalendarMain from 'src/components/common/calendar/MonthlyCalendarMain'
import MonthlyCalendar from 'src/components/common/calendar/MonthlyCalendar'
import DualCalendarContent from './DualCalendarContent'
import SelectDualDate from './SelectDualDate'

moment.locale('ko')

type CustomCalendarType = {
  registerFn?: React.InputHTMLAttributes<HTMLInputElement>
  setValue: ReturnType<typeof useForm>['setValue']
  name: string
  isValid?: boolean
  disabled: boolean
  defaultDate?: null | { start: Date; end: Date }
  watch: ReturnType<typeof useForm>['watch']
}
export function CustomDualCalendar({
  watch,
  disabled,
  isValid = true,
  registerFn,
  setValue,
  name,
  defaultDate,
}: CustomCalendarType) {
  const today = new Date()
  const [nowDate, setNowDate] = useState<[Date | null, Date | null]>([
    defaultDate?.start || today,
    defaultDate?.end || today,
  ])
  const [textColor, setTextColor] = useState('unopen')
  const [isOpen, setIsOpen] = useState(false)
  const [monthUserSeeing, setMonthUserSeeing] = useState(moment(today).month())

  useEffect(() => {
    setValue(name, {
      start: defaultDate?.start || today,
      end: defaultDate?.end || today,
    })
  }, [])

  useEffect(() => {
    const subscirbe = watch((data, { name: dname }) => {
      if (dname === 'dttm') {
        setNowDate([data.dttm.start, data.dttm.end]) // 초기화
      }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])

  const handleToggleCalendar = () => {
    if (disabled) return
    setIsOpen(!isOpen)
  }
  const handleDateChange = (selectedDate: Date) => {
    const [startDate, endDate] = nowDate

    if (!startDate && !endDate) {
      // 시작일도 선택되지 않았고 종료일도 선택되지 않은 경우
      // setNowDate([selectedDate, null])
      setValue(name, {
        start: selectedDate,
        end: null,
      })
    } else if (startDate && !endDate) {
      // 시작일은 선택되었지만 종료일은 선택되지 않은 경우

      if (moment(selectedDate).isBefore(startDate)) {
        //    setNowDate([startDate, null]) // 선택한 날짜가 시작일보다 이전이면 종료일을 초기화
        setValue(name, {
          start: startDate,
          end: null,
        })
      } else {
        //   setNowDate([startDate, selectedDate]) // 그렇지 않으면 종료일을 선택한 날짜로 설정
        setValue(name, {
          start: startDate,
          end: selectedDate,
        })
      }
    } else {
      // 두 날짜가 모두 선택된 경우
      //  setNowDate([null, null]) // 초기화
      setValue(name, {
        start: null,
        end: null,
      })
    }
  }

  const styleId = isOpen === true ? 'div_container active' : 'div_container'

  return (
    <>
      <input type="text" hidden {...registerFn} />
      <div className={`${divideStyleIDString(styles, isValid === false ? `${styleId} border_red` : styleId)}`}>
        <div className={styles.toggle_wrap}>
          <DropdownButton className={isOpen ? 'active' : textColor} onClick={handleToggleCalendar}>
            {Array.isArray(nowDate) ? (
              <>
                <SelectDualDate
                  isOpen={isOpen}
                  end={nowDate[1] ? `${moment(nowDate[1]).format('MM월 DD일 (ddd)')}` : '종료일'}
                  start={nowDate[0] ? `${moment(nowDate[0]).format('MM월 DD일 (ddd)')}` : '시작일'}
                />
              </>
            ) : (
              <></>
            )}
            {!isOpen && (
              <Toggle>
                <img src={DropDownIcon} alt="토글 " />
              </Toggle>
            )}
          </DropdownButton>
        </div>
        {isOpen === true && (
          <>
            <div className={styles.div_line} />
            <Wrap>
              <div className={styles.calendar_wrap}>
                <DualCalendarContent
                  nowDate={nowDate}
                  selectedDate={nowDate[0] || today}
                  onClickDate={(date: Date) => {
                    handleDateChange(date)
                  }}
                />
              </div>
            </Wrap>
          </>
        )}
      </div>
    </>
  )
}

export default CustomDualCalendar
const Wrap = styled.div`
  margin: 19px 16px 12px 16px;
`

const DropdownButton = styled.div`
  height: 49px;
  border: none;
  width: 100%;

  display: grid;

  color: var(--festie-gray-800, #3a3a3a);

  appearance: none;
  background-color: unset;

  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px;

  font-family: Pretendard-Regular;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;

  grid-template-columns: 1fr auto;
  // padding-top: 17px;
  align-content: center;

  padding-left: 0px;
  padding-right: 0px;
  .transform {
    transform: rotate(90deg);
  }
  &.unopen {
    color: #979797;
  }
  &.active {
    color: #1c1c1c;
  }
`

const Toggle = styled.div`
  justify-content: end;
  margin-left: 26px;
  :hover {
    cursor: pointer;
  }
`
