/*
    파일명 : CollectionItemEdit.tsx
    설명 : 싹싹도감 > 내 도감 > 도감 상세 > 도감 편집
    작성일 : 2024-06-17
*/
import { CONTENT_PADDING, CONTENT_PADDING_TOP } from 'src/utils/common/constants/constants'
import CollectionRecList from '../sub/CollectionRecList'
import { useEffect, useState } from 'react'
import itemMoveIcon from '../../../../assets/images/icon/itemMove.png'
import itemDeleteIcon from '../../../../assets/images/icon/itemDelete.png'
import { Box } from '@mui/material'
import FixedButtonLayout from 'src/components/common/layout/FixedButtonLayout'
import { useDeleteItemInToCollection } from '../hook/CollectionListCustomHook'
import { useRecoilState } from 'recoil'
import { selectCol } from 'src/utils/common/state/state'
import DrawerLayout from 'src/components/common/layout/DrawerLayout'
import CollectionItemMoveListMain from '../sub/CollectionItemMoveListMain'
import { useParams } from 'react-router-dom'
import { CollectionDetailItemType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import Alert2Modal from 'src/components/common/modal/Alert2Modal'

export default function CollectionItemEditList({
  totalCount,
  itemList,
  onSuccess,
  onScrollEnd,
}: {
  totalCount: number
  itemList: CollectionDetailItemType[] | null | undefined
  onSuccess: () => void
  onScrollEnd: () => void
}) {
  return (
    <>
      <Box sx={{ p: CONTENT_PADDING, pb: 0, pt: CONTENT_PADDING_TOP }}>
        <>
          <ItemSize>{`${totalCount}개의 식물`}</ItemSize>
        </>

        <CollectionRecList
          isPending={false}
          clickedDefault={true}
          h="480px"
          onScrollEnd={onScrollEnd}
          scroll={true}
          onClick={() => {}}
          editMode={true}
          arr={itemList}
        />
      </Box>
      <ButtonComps
        onSuccess={() => {
          onSuccess()
        }}
      />
      <></>
    </>
  )
}

export function ButtonComps({ onSuccess }: { onSuccess: () => void }) {
  const [selectedPlants, setSelectedPlants] = useRecoilState<{ params: string[] }>(selectCol)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [closeInner, setCloseInner] = useState(false)
  const { deleteForm, isPending: isPendingDelete, isError: isErrorDelete, isSuccess } = useDeleteItemInToCollection()
  const { collectionId } = useParams()

  const [openAlert, setOpenAlert] = useState(false)

  useEffect(() => {
    if (isSuccess === true) {
      onSuccess()
    }
  }, [isSuccess])

  const deletePlant = () => {
    if (isPendingDelete || !collectionId) return
    deleteForm(collectionId, selectedPlants.params)
  }

  return (
    <>
      <PlazaDeleteAlert
        closeAlert={() => {
          setOpenAlert(false)
        }}
        deletePlant={deletePlant}
        openProp={openAlert}
      />
      <Box sx={{ p: CONTENT_PADDING, pb: 0, pt: CONTENT_PADDING_TOP }}>
        {selectedPlants.params.length > 0 && (
          <FixedButtonLayout p="absolute" left="0px" maxWidth="390px" zi="1000" bottom="0px">
            <ButtonLayout>
              <ButtonDiv
                onClick={() => {
                  if (selectedPlants.params && selectedPlants.params.length > 0) {
                    setDrawerOpen(true)
                  }
                }}
              >
                <ItemMove alt="도감 이동" src={itemMoveIcon} />
                <ButtonLabel>도감 이동</ButtonLabel>
              </ButtonDiv>
              <ButtonDiv
                onClick={() => {
                  setOpenAlert(true)
                }}
              >
                <ItemDelete alt="삭제" src={itemDeleteIcon} />
                <ButtonLabel>삭제</ButtonLabel>
              </ButtonDiv>
            </ButtonLayout>
          </FixedButtonLayout>
        )}
      </Box>
      <DrawerLayout
        title="내 도감 목록"
        height="60%"
        pb={0}
        pt={0}
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false)
          setCloseInner(false)
        }}
        closeInner={closeInner}
        children={
          <>
            <CollectionItemMoveListMain
              type="MOVE" // MOVE, ADD
              onFinish={() => {
                setCloseInner(true)
                onSuccess()
              }}
            />
          </>
        }
      />
    </>
  )
}
function PlazaDeleteAlert({
  openProp,
  deletePlant,
  closeAlert,
}: {
  openProp: boolean
  deletePlant: () => void
  closeAlert: () => void
}) {
  return (
    <>
      <Alert2Modal
        title="식물을 삭제하시겠어요?"
        description="선택한 식물들이 삭제됩니다."
        open={openProp}
        afterCloseAlert={() => {
          deletePlant()
          closeAlert()
        }}
        handleClose={() => {
          closeAlert()
        }}
      />
    </>
  )
}

const ItemMove = styled.img`
  width: 20px;
  height: 20px;
`
const ItemDelete = styled.img`
  width: 16px;
  height: 18px;
`

const ButtonDiv = styled.div`
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`
const ButtonLabel = styled.p`
  /* ✏️ Action */
  margin-top: 4px;

  /* Body 01-R */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;

  /* White */
  color: #ffffff;
`
const ButtonLayout = styled.div`
  /* Frame 444 */

  display: flex;
  max-width: 390px;
  width: 100%;
  height: 84px;
  background: #00c398;
  align-items: center;
  padding-left: 71px;
  padding-right: 71px;
  justify-content: space-between;
`

const ItemSize = styled.div`
  /* 꼼삐 */
  margin-bottom: 12.5px;
  /* Body 02 */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height, or 153% */

  letter-spacing: -0.02em;

  /* Black */
  color: #242424;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`
