import ProfileContent from 'src/components/common/content/ProfileContent'
import ProfileHeader, {
  ProfileContainer,
  ProfileDivComp,
  ProfileImg,
  ProfileIntroComp,
  ProfileWrap,
} from 'src/components/common/content/ProfileHeader'
import samplePrfImg from '../../../assets/images/post/sampleProfile.png'
import DualButtonLayout from 'src/components/common/button/DualButtonLayout'
import { CustomButton } from 'src/components/common/button/CustomButton'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import FollowerContent from 'src/components/common/content/FollowerContent'
import { UseUserInfo } from '../main/hooks/MainHomeCustomHook'
import { ProfileInfoType } from 'src/utils/common/type/type'
import { myPlantQnt } from 'src/utils/common/state/state'
import { useRecoilState } from 'recoil'
import profileSampleImg from '../../../assets/images/plant/MyPlantSample.png'
import ProfileInfoLoading from './sub/ProfileInfoLoading'
import { useFollow } from './hook/ProfileCreateCustomHook'

export default function ProfileInfo({
  onClickEdit,
  profile,
  name,
  isPending,
}: {
  isPending: boolean
  onClickEdit: () => void
  name: string | null
  profile: ProfileInfoType | null
}) {
  const path = useLocation()
  const [plantsQnt, setPlantsQnt] = useRecoilState(myPlantQnt)
  const [pathName, setPathName] = useState(path.pathname)

  useEffect(() => {
    setPathName(path.pathname)
  }, [path])

  if (isPending || !name || !profile) {
    return (
      <>
        <ProfileInfoLoading />
      </>
    )
  }

  return (
    <>
      <ProfileContainer imgSize="74px">
        <ProfileWrap>
          <ProfileImg imgSize="74px" profileSrc={profile?.data.imageUrl || profileSampleImg} />
          <ProfileIntroComp
            comp={
              <>
                <FollowerContent
                  name={profile.data.nickname}
                  contentArr={[
                    { title: '키우는 식물', value: `${plantsQnt}` },
                    { title: '팔로워', value: profile.data.followerCount },
                    { title: '팔로잉', value: profile.data.followingCount },
                  ]}
                />
              </>
            }
            userNm={name}
            regFormat="1"
          />
        </ProfileWrap>
      </ProfileContainer>
      <ProfileContent content={profile?.data.introduction || ''} />

      {pathName.includes('/myprofile') ? (
        <>
          <CustomButton onClick={onClickEdit} styleID="btn_edit_prof" width="" type="button">
            프로필 편집
          </CustomButton>
        </>
      ) : (
        <MemberProfileCompnent defaultVal={profile.data.isFollowed || false} afterFollow={() => {}} />
      )}
    </>
  )
}

export function MemberProfileCompnent({
  defaultVal,

  afterFollow,
}: {
  defaultVal: boolean
  afterFollow: () => void
}) {
  const [clicked, setClicked] = useState(defaultVal)
  const { isPending, createForm, isSuccess, isError, deleting, deletingSuccess, deletingFailed } = useFollow()
  useEffect(() => {
    if (isSuccess) {
      setClicked(prev => !prev)
      afterFollow()
    }
  }, [isSuccess])
  useEffect(() => {
    if (isError) {
      setClicked(prev => prev)
    }
    // afterLikePost()
  }, [isError])
  useEffect(() => {
    if (deletingSuccess) {
      setClicked(prev => !prev)
      afterFollow()
    }
  }, [deletingSuccess])
  useEffect(() => {
    if (deletingFailed) {
      setClicked(prev => prev)
    }

    // afterLikePost()
  }, [deletingFailed])

  return (
    <>
      <DualButtonLayout styleID="div_dual_btn">
        <CustomButton
          onClick={() => {
            createForm(!clicked)
          }}
          styleID={clicked ? 'btn_dual_05' : 'btn_dual_03'}
          type="button"
          height=""
          width=""
        >
          {clicked ? '팔로우 취소' : '팔로우'}
        </CustomButton>
      </DualButtonLayout>
    </>
  )
}
