import { collectionRecSampleType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import LoadingContainer from '../container/LoadingContainer'
import { useEffect, useState } from 'react'
import SelectedIcon from '../../../assets/images/collection/selectedCollection.png'
import { useRecoilState } from 'recoil'
import { selectCol } from 'src/utils/common/state/state'
import { Skeleton } from '@mui/material'
/*
    파일명 : BookmarkItem.tsx
    설명 : 싹싹도감 메인 > 식물 둘러보기 > 목록 > 북마크 아이템
    작성일 : 2024-06-13
*/
type BookmarkItemType = {
  item: collectionRecSampleType | null
  onClick: () => void
  iconComp?: React.ReactNode
  namefs?: string
  namelh?: string
  editMode?: boolean
}
export default function BookmarkItem({
  editMode = false,
  namelh = '24px',
  namefs = '16px',
  iconComp,
  item,
  onClick,
}: BookmarkItemType) {
  const [selected, setSelected] = useState(false)
  const [selectedPlants, setSelectedPlants] = useRecoilState<{ params: string[] }>(selectCol)

  if (!item) {
    return (
      <Wrap>
        <Skeleton
          variant="rectangular"
          sx={{
            width: '100%',
            height: 'unset',
            borderRadius: '7px',
            aspectRatio: '1/1',
          }}
        />
        <Skeleton variant="rounded" width={65} height={18} sx={{ marginTop: '8px' }} />
      </Wrap>
    )
  }

  useEffect(() => {
    return () => {
      setSelectedPlants({ params: [] })
    }
  }, [])

  const selectHandler = (id: string | number) => {
    // id가 이미 selectedPlants 배열에 포함되어 있는지 확인합니다.
    const str = id.toString()

    const index = selectedPlants.params.indexOf(str)

    if (index !== -1) {
      // 이미 선택된 경우: 배열에서 해당 id를 제거합니다.
      const updatedPlants = [...selectedPlants.params]
      updatedPlants.splice(index, 1)
      setSelectedPlants({
        params: updatedPlants,
      })
    } else {
      // 선택되지 않은 경우: id를 배열에 추가합니다.
      setSelectedPlants({
        params: [...selectedPlants.params, str],
      })
    }
  }

  return (
    <Wrap>
      <ItemImgDiv
        onClick={() => {
          if (editMode) {
            setSelected(prev => !prev)
            selectHandler(item.id)
          }
          onClick()
        }}
        $bgurl={item.img_url}
      >
        {selected && (
          <ItemImgDivSelected>
            <SelectedImg src={SelectedIcon} alt="선택된 아이템" />
          </ItemImgDivSelected>
        )}
      </ItemImgDiv>
      <BookMarkWrap>
        <BookMark>{iconComp}</BookMark>
      </BookMarkWrap>

      <Name $namelh={namelh} $namefs={namefs}>
        {item.name}
      </Name>
    </Wrap>
  )
}

interface ItemImgDivProps {
  $bgurl: string
}

const ItemImgDiv = styled.div<ItemImgDivProps>`
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  background: url(${props => props.$bgurl});
  background-size: cover;
  background-position: center; /* 이미지를 가운데로 정렬합니다. */
  border-radius: 7px;
`

const ItemImgDivSelected = styled.div`
  position: absolute;

  width: 100%;
  aspect-ratio: 1 / 1;

  border-radius: 7px;

  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  align-content: center;
`
const SelectedImg = styled.img`
  max-width: 24px;
  aspect-ratio: 1 / 1;
`

const BookMarkWrap = styled.div`
  position: relative;
`
const BookMark = styled.div`
  bottom: 28px;
  position: inherit;
  width: 100%;
  height: 0px;
  text-align: right;
`

const Wrap = styled.div`
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`

const Name = styled.h2<{ $namefs: string; $namelh: string }>`
  margin-top: 8px;
  font-size: ${({ $namefs }) => $namefs};
  line-height: ${({ $namelh }) => $namelh};

  /* Body 01-R */
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;

  /* identical to box height, or 150% */
  letter-spacing: -0.02em;

  /* Black */
  color: #242424;
`

/* 가스베리아 */
