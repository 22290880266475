/*
    파일명 : Green.tsx
    설명 : 그린 마켓 
    작성일 : 2024-06-19
*/

import ScrollButton from 'src/components/common/button/ScrollButton'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MuiMain from 'src/components/common/mui/MuiMain'
import GreenHome from 'src/components/specific/green/GreenHome'

export default function Green() {
  return (
    <ContainerLayout styleId="div_container_02">
      <MuiMain>
        <Content
          component={
            <>
              <GreenHome />
            </>
          }
        />
      </MuiMain>
      <ScrollButton
        btnOnePath="/green/create/market"
        btnTwoPath="/green/create/caring"
        btnOneTitle="마켓 글쓰기"
        btnTwoTitle="돌보미 구하기"
      />
    </ContainerLayout>
  )
}
