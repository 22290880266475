import styled from 'styled-components'
import { motion } from 'framer-motion' // Import motion from Framer Motion

export default function SmallBubble({
  content,
  zIndex = 1,
  step,
}: {
  content: string
  zIndex?: number
  step: number | string
}) {
  return step === 3 ? (
    <MotionBubble
      zindex={zIndex}
      initial={{ x: 0 }}
      animate={{ x: [-5, 5, -5, 5, 0] }}
      transition={{ duration: 0.5, delay: 1 }}
    >
      {content}
    </MotionBubble>
  ) : (
    <Bubble zindex={zIndex}>{content}</Bubble>
  )
}

const MotionBubble = styled(motion.div)<{ zindex: number }>`
  z-index: ${({ zindex }) => `${zindex}`};
  position: absolute;

  width: 50px;
  height: 26.89px;

  border-radius: 7.33333px;

  align-self: self-end;

  align-content: center;

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  text-align: center;
  letter-spacing: -0.05em;

  color: #1c1c1c;

  right: -7px;
  background: #e0f7f1;
  bottom: -9px;

  &::after {
    content: '';
    position: absolute;
    bottom: -7px; /* Position the tail at the bottom */
    right: 13px; /* Position the tail towards the right */
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-top-color: #e0f7f1; /* Tail color matches the bubble */
    border-bottom: 0;
    border-right: 0;
    margin-left: -5px;
    transform: rotate(246deg);
    border-radius: 3px;
  }
`

const Bubble = styled.div<{ zindex: number }>`
  z-index: ${({ zindex }) => `${zindex}`};
  position: absolute;

  /* Rectangle 192 */

  width: 50px;
  height: 26.89px;

  border-radius: 7.33333px;

  align-self: self-end;

  align-content: center;

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  text-align: center;
  letter-spacing: -0.05em;

  color: #1c1c1c;

  right: -7px;
  background: #e0f7f1;
  bottom: -9px;

  &::after {
    content: '';
    position: absolute;
    bottom: -7px; /* Position the tail at the bottom */
    right: 13px; /* Position the tail towards the right */
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-top-color: #e0f7f1; /* Tail color matches the bubble */
    border-bottom: 0;
    border-right: 0;
    margin-left: -5px;
    transform: rotate(246deg);
    border-radius: 3px;
  }
`
