import styled from 'styled-components'
import pot from '../../../assets/images/plant/potIcon.png'
import potActive from '../../../assets/images/plant/potIconActive.png'
import color from '../../../assets/images/plant/colorIcon.png'
import colorActive from '../../../assets/images/plant/colorIconActive.png'
import bg from '../../../assets/images/plant/bgIcon.png'
import bgActive from '../../../assets/images/plant/bgIconActive.png'

export function PotIcon({ active }: { active: boolean }) {
  return (
    <>
      <Pot alt="화분종류" src={active ? potActive : pot} />
    </>
  )
}

export function ColorIcon({ active }: { active: boolean }) {
  return (
    <>
      <Color alt="화분색" src={active ? colorActive : color} />
    </>
  )
}

export function BacgroundIcon({ active }: { active: boolean }) {
  return (
    <>
      <Bg alt="배경종류" src={active ? bgActive : bg} />
    </>
  )
}

const Pot = styled.img`
  width: 23px;
  height: 20px;
`

const Color = styled.img`
  width: 23.2px;
  height: 23px;
`

const Bg = styled.img`
  width: 19px;
  height: 21px;
`
