/*
    작성자 : 김효은
    작성일 : 2024-05-04 토
*/
// TODO : 인증번호 전송 누르면 타이머 나오게

import { useRecoilState } from 'recoil'
import { alertState, timerState } from '../../../../utils/common/state/state'
import FormMain from '../../../common/form/FormMain'

import { FormEvent, useEffect, useState } from 'react'
import { useValidCheckModal } from '../../../../utils/common/hooks/customHook'
import { useForm } from 'react-hook-form'
import { useFindIdApi } from './hooks/MainFindIdCustomHook'

function MainFindIdForm({ onConfirm }: { onConfirm: (email: string) => void }) {
  const { register, getValues } = useForm()
  const { getCode, newCode, setUserCode, checkCodeResult, errorSendingNumber } = useFindIdApi()

  const { nullCheck, regExpCheck } = useValidCheckModal()
  const [certConfirm, setCertConfirm] = useState<boolean | null>(null)
  const [alert, setAlert] = useRecoilState(alertState)
  const [certSendBtn, setCertSendBtn] = useState('인증번호 전송')
  const [timer, setTimer] = useRecoilState(timerState)

  useEffect(() => {
    if (checkCodeResult?.message === '인증에 성공했습니다') {
      // 인증 확인 통과시에
      setCertConfirm(true)
      setTimer({
        start: false,
      })

      setAlert({
        title: '',
        discription: '',
        showAlert: false,
      })
    }
  }, [checkCodeResult])

  useEffect(() => {
    if (errorSendingNumber) {
      setCertConfirm(false)
      setAlert({
        title: '',
        discription: '휴대 전화를 다시 확인해주세요',
        showAlert: true,
      })
    }
  }, [errorSendingNumber])

  const onClickConfirmBtn = () => {
    if (certConfirm === true) return
    let alertDiscription = ''
    const certNum = newCode?.data

    // 유효시간안에 인증이 확인되었을 시에
    if (timer.start === true && certNum && getValues('authNumber')) {
      // 유효시간안에 인증이 안되었을 때, 혹은 인증번호 전송 안했을 때
      if (certNum !== getValues('authNumber')) {
        setCertConfirm(false)
        alertDiscription = '인증번호를 다시 확인해주세요'
        setAlert({
          title: '',
          discription: alertDiscription,
          showAlert: false,
        })
      } else if (certNum === getValues('authNumber')) {
        setUserCode(getValues('authNumber'))
      }
    } else {
      setCertConfirm(false)
      alertDiscription = '인증에 실패했습니다. 다시 시도해주세요'
      setAlert({
        title: '',
        discription: alertDiscription,
        showAlert: false,
      })
    }
  }

  const onClickCertNumBtn = () => {
    let isValid = nullCheck({
      targetObj: {
        tel: getValues('tel'),
      },

      nameArr: ['전화번호'],
      title: ' 미입력',
      discription: '을 입력해주세요',
    })

    if (!isValid) return

    isValid = regExpCheck({
      targetObj: {
        tel: getValues('tel'),
      },
      nameArr: ['전화번호'],
      regExpArr: ['^[0-9]{10,11}$'],
      title: ' 형식 오기재',
      discription: '을 정확히 입력해주세요',
    })
    if (isValid) {
      setAlert({
        title: '',
        discription: '',
        showAlert: false,
      })
      setCertSendBtn('인증번호 재전송')
      setCertConfirm(null)
      setTimer({
        start: true,
      })
      getCode(getValues('tel'))
    }
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!certConfirm) {
      setAlert({
        title: '인증번호 미확인',
        discription: '인증번호를 확인해주세요',
        showAlert: true,
      })
    } else {
      const result = typeof checkCodeResult?.data === 'string' ? checkCodeResult.data : ''
      onConfirm(result)
    }
  }

  return (
    <>
      <FormMain
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          onSubmit(event)
        }}
      >
        <FormMain.LabelLayout
          isFirst={true}
          warnText={alert.discription}
          warning={alert.discription.length > 0 ? true : false}
          label="휴대전화 번호"
          infoText="인증문자가 도착하지 않으면 메시지 설정에서 스팸메시지를 확인해 주세요."
        >
          <FormMain.InputWithButton
            styleId={
              alert.discription.length > 0 &&
              (alert.discription.includes('전화번호') || alert.discription.includes('인증번호를 확인'))
                ? 'div_with_timer border_red'
                : 'div_with_timer'
            }
            inputComp={
              <FormMain.Input
                styleID=""
                type="text"
                placeholder="휴대전화번호 입력( ‘ - ‘ 제외 )"
                registerFn={register('tel', {
                  required: 'Phone number is required',
                  pattern: {
                    value: /^\d{3}\d{4}\d{4}$/,
                    message: 'Invalid phone number format',
                  },
                })}
              />
            }
            buttonComp={
              <FormMain.Button
                onClick={onClickCertNumBtn}
                styleID={timer.start || certConfirm ? 'btn_with_input active' : 'btn_with_input'}
                width=""
                height=""
                type="button"
              >
                {certSendBtn}
              </FormMain.Button>
            }
          />
          <FormMain.InputWithTimerButton
            inputComp={
              <FormMain.InputTimer
                divStyleId={
                  alert.discription.length > 0 &&
                  (alert.discription.includes('인증에 실패') || alert.discription.includes('인증번호를 확인'))
                    ? 'div_with_timer border_red'
                    : 'div_with_timer'
                }
                type="text"
                placeholder="인증번호 입력"
                registerFn={register('authNumber')}
                certConfirm={certConfirm}
              />
            }
            buttonComp={
              <FormMain.Button
                onClick={onClickConfirmBtn}
                styleID={certConfirm ? 'btn_with_timer active' : 'btn_with_timer'}
                width=""
                height=""
                type="button"
              >
                확인
              </FormMain.Button>
            }
          />
        </FormMain.LabelLayout>
        <FormMain.Button
          styleID={certConfirm ? 'btn_submit_01_active' : 'btn_submit_01'}
          width="100%"
          height=""
          type="submit"
          marginTop="292px"
        >
          아이디 찾기
        </FormMain.Button>
      </FormMain>
    </>
  )
}

export default MainFindIdForm
