/*
    파일명 : LevelIcon.tsx
    설명 : 레벨 아이콘
    작성일 : 2024-06-06
*/

import styled from 'styled-components'

export default function LevelIcon({ level }: { level: string }) {
  return (
    <Level>
      <p>Lv.</p>
      <p>{level}</p>
    </Level>
  )
}

const Level = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  width: 95px;
  height: 38px;

  background: #00c398;
  border-radius: 50px;

  p {
    /* 새싹 */

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;

    text-align: center;
    letter-spacing: -0.05em;

    color: #ffffff;
  }

  & p:last-child {
    margin-left: 8px;
  }
`
