/*

*/
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

export default function MotionUpLayout({
  children,
  isVisible,
  backPath,
}: {
  backPath: string
  isVisible: boolean
  children: React.ReactNode
}) {
  const navigate = useNavigate()

  return (
    <AnimatePresence
      onExitComplete={() => {
        navigate(backPath)
      }}
    >
      {isVisible && (
        <motion.div
          key="upanddown"
          initial={{ opacity: 0, y: 50 }} // 초기 상태: 투명도 0으로 설정하여 숨김, y축으로 아래로 이동
          animate={{ opacity: 1, y: 0 }} // 애니메이션 상태: 투명도 1로 설정하여 나타나고, y축으로 0으로 이동
          exit={{ opacity: 0, y: 50 }} // 종료 상태: 투명도 0으로 설정하여 사라지고, y축으로 아래로 이동
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
