/*
    파일명 : PlantDetailCharacter.tsx
    설명 : 식물 상세 페이지 > 캐릭터 상세 
    작성일 : 2024-06-06
*/

import SmallBubble from 'src/components/common/bubble/SmallBubble'
import ImgMiddleContainer from 'src/components/common/container/ImgMiddleContainer'

import BubbleContainer from 'src/components/common/container/BubbleContainer'
import CharacterIcon from 'src/components/common/icon/CharacterIcon'
import LevelIcon from 'src/components/common/icon/LevelIcon'
import WateringIcon from 'src/components/common/icon/WateringIcon'
import styled from 'styled-components'
import LevelProgress from 'src/components/common/progress/level/LevelProgress'
import LoadingSpinner from 'src/components/common/container/LoadingSpinner'
import { alertState, LodingSpinnerType } from 'src/utils/common/state/state'
import useDeletePlant from '../hooks/getPlantInfoCustomHook'
import { useDeleteDiary } from '../../diary/hooks/DiaryCreateCustomHook'
import { useRecoilState } from 'recoil'
import { useEffect } from 'react'
import Alert from 'src/components/common/modal/Alert'

type PlantDetailCharacterType = {
  intervalIndays: number
  charId: number
  plantLocation: string
}

export default function PlantDetailCharacter({ intervalIndays, charId, plantLocation }: PlantDetailCharacterType) {
  return (
    <>
      <IconWrap>
        <WateringIcon intervalIndays={intervalIndays} />
        <LevelIcon level="새싹" />
      </IconWrap>
      <ImgMiddleContainer marginTop="13px">
        <CharacterIcon width="262.52px" height="255px" charId={charId} plantLocation={plantLocation} />
      </ImgMiddleContainer>
      <BubbleContainer>
        <SmallBubble step={3} content="레벨업!" />
      </BubbleContainer>
      <LevelProgress step={3} marginTop="19px" />
    </>
  )
}

const IconWrap = styled.div`
  justify-content: space-between;
  display: flex;
`
