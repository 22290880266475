import { Box } from '@mui/material'
import NoDiary from '../sub/NoDiary'
import { CONTENT_PADDING, PLANT_CARE } from 'src/utils/common/constants/constants'
import { MenuToggle } from 'src/components/common/title/TitleLight'
import DetailViewLabelLayout from 'src/components/common/layout/DetailViewLabelLayout'
import Alert from 'src/components/common/modal/Alert'
import { useDeleteDiary } from '../../diary/hooks/DiaryCreateCustomHook'
import useDeletePlant, { useGetDirayDetail } from '../hooks/getPlantInfoCustomHook'
import {
  alertState,
  alertState2,
  LodingSpinnerType,
  selectedDirayDate,
  transitionSttsState,
} from 'src/utils/common/state/state'
import { useRecoilState } from 'recoil'
import LoadingSpinner from 'src/components/common/container/LoadingSpinner'
import styled from 'styled-components'
import DiaryPlanList from '../sub/DiaryPlanList'
import DiaryContent from '../sub/DiaryContent'
import DiaryImgList from '../sub/DiaryImgList'
import { useEffect, useState } from 'react'
import DrawerLayout from 'src/components/common/layout/DrawerLayout'
import DiaryCreateForm from '../../diary/sub/DiaryCreateForm'
import FullLoadingSpinner from 'src/components/common/container/FullLoadingSpinner'
import Alert2Modal from 'src/components/common/modal/Alert2Modal'

export default function DiaryMng({
  onPostFinish,
  detailId,
  hasEvent,
  isPending,
}: {
  onPostFinish: () => void
  detailId: number | null
  hasEvent: boolean
  isPending: boolean
}) {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [closeInner, setCloseInner] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [updated, setUpdated] = useState('PENDING')
  const [alert, setAlert] = useRecoilState(alertState2)
  const {
    setDiaryId,
    setResponse: setDiaryDetail,
    response: diaryDetail,
    isLoading: requestDetail,
    refetch,
  } = useGetDirayDetail()
  const { deleting, deleteItem, deletingSuccess, deletingFailed, setRequestSucess } = useDeleteDiary({
    onPostFinish,
    setDiaryDetail,
    setDiaryId,
  })
  const [transitionStts, setTransitionStts] = useRecoilState<string>(transitionSttsState)

  useEffect(() => {
    if (deletingSuccess) {
      onPostFinish()
      setRequestSucess(false)
    }
  }, [deletingSuccess])

  // useEffect(() => {
  //   if (diaryDetail === null && deletingSuccess) {
  //     onPostFinish()
  //   }
  // }, [diaryDetail])

  useEffect(() => {
    if (detailId && transitionStts.includes('EXITED')) {
      setDiaryId(detailId)
    }
    setToggle(prev => !prev)
  }, [detailId])

  useEffect(() => {
    setToggle(prev => !prev)
  }, [diaryDetail])

  if (deleting) {
    return (
      <>
        <FullLoadingSpinner isShow={true} />
      </>
    )
  }

  const createForm = (
    <>
      <DrawerLayout
        height="99%"
        title={detailId && diaryDetail ? '일지 수정' : '일지 등록'}
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false)
          setCloseInner(false)
          if (updated !== 'PENDING') {
            setUpdated('PENDING')
            if (updated === 'U') {
              console.log('U')
            } else {
              onPostFinish()
            }
          }
        }}
        closeInner={closeInner}
        children={
          <>
            <>
              <DiaryCreateForm
                updateItem={detailId && diaryDetail ? diaryDetail : null}
                onPostFinish={type => {
                  setCloseInner(true)
                  setUpdated(type)
                }}
              />
            </>
          </>
        }
      />
    </>
  )

  if (!diaryDetail || (diaryDetail && !hasEvent)) {
    if (diaryDetail === undefined && detailId) {
      return <LoadingSpinner />
    } else {
      return (
        <>
          <NoDiary
            onClickBtn={() => {
              setDrawerOpen(true)
            }}
          />
          {createForm}
        </>
      )
    }
  }

  return (
    <>
      {diaryDetail && hasEvent ? (
        <>
          <Box sx={{ p: CONTENT_PADDING, pb: 0, pt: 0 }}>
            <DetailViewLabelLayout
              comp={
                <MenuToggle
                  bc="#D9D9D9"
                  mr="0px"
                  title="설정"
                  options={[
                    {
                      optionTitle: '수정',
                      onClick: () => {
                        setDrawerOpen(true)
                      },
                    },
                    {
                      optionTitle: '삭제',
                      onClick: () => {
                        setAlert({
                          title: '일지 삭제',
                          showAlert: true,
                          discription: '일지를 삭제하시겠어요?',
                          params: diaryDetail.diaryId,
                          type: 'after',
                        })
                      },
                    },
                  ]}
                />
              }
              marginTop="23px"
              menu={true}
              h2MarginBottom="18px"
              title="오늘의 기록"
            >
              <DiaryWrap>
                <DiaryPlanList careArr={diaryDetail.plantCareList} />
                <DiaryContent marginTop="20px" width="100%" title={diaryDetail.title} content={diaryDetail.content} />
                {diaryDetail.imageUrlList && diaryDetail.imageUrlList.length > 0 && (
                  <DiaryImgList list={diaryDetail.imageUrlList} />
                )}
              </DiaryWrap>
            </DetailViewLabelLayout>
          </Box>
          <Alert2Modal
            title={alert.title}
            description={alert.discription}
            open={alert.showAlert}
            afterCloseAlert={() => {
              if (typeof alert.params === 'number') {
                deleteItem(alert.params)
                setAlert({
                  title: '',
                  discription: '',
                  showAlert: false,
                  params: null,
                  type: 'after',
                })
              }
            }}
            handleClose={() => {
              setAlert({
                title: '',
                discription: '',
                showAlert: false,
                params: null,
                type: 'after',
              })
            }}
          />
        </>
      ) : (
        <>
          <NoDiary
            onClickBtn={() => {
              setDrawerOpen(true)
            }}
          />
        </>
      )}
      <>{createForm}</>
    </>
  )
}

const DiaryWrap = styled.div`
  margin: 0px;
`
