import { useForm } from 'react-hook-form'
import CalendarMain from 'src/components/common/calendar/CalendarMain'

import { CONTENT_PADDING, CONTENT_PADDING_TOP, SAMPLE_TODO } from 'src/utils/common/constants/constants'
import { CalendarEventType, todoType } from 'src/utils/common/type/type'
import Plan from '../../main/sub/Plan'
import { useState } from 'react'
import { coloredItems, MyPlanContent } from '../../main/MyPlan'
import { Box } from '@mui/material'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useGetPlanList } from '../hooks/DiaryCreateCustomHook'
import { formatDateToString, generateRandomKey } from 'src/utils/common/scripts/common'
import { useGetPlanListOfCalendar } from '../../main/hooks/MainHomeCustomHook'

moment.locale('ko')

// 주간 일정 (임의의 예시)
const events: CalendarEventType[] = [
  { date: '2024-07-17', colorType: '#FFA4A4' },
  {
    date: '2024-07-17',
    colorType: '#00C398',
  },
  {
    date: '2024-07-17',
    colorType: '#C26CC3',
  },

  {
    date: '2024-07-16',
    colorType: '#00C398',
  },
  {
    date: '2024-07-16',
    colorType: '#C26CC3',
  },
  {
    date: '2024-07-15',
    colorType: '#C26CC3',
  },
]

export default function DiaryDetailView() {
  const { register, setValue, getValues, watch, handleSubmit } = useForm()
  const [renderToggle, setRenderToggle] = useState(false)
  const { setPeriod, response, isPending, refetch } = useGetPlanList()
  const {
    list,
    isPending: pendingPlan,
    isLoading: loadingPlan,
    isSuccess: isSuccessPlan,
    setDate,
    refetch: getPlan,
  } = useGetPlanListOfCalendar()

  const onClickPlan = (item: todoType) => {
    let val = getValues(item.scheduleId.toString()) || false
    val = !val
    setValue(item.scheduleId.toString(), val)
    setRenderToggle(prev => !prev)
  }

  // 리스트 조회
  const onChangeDate = (start: Date, end: Date) => {
    const stformat = moment(start).format('YYYY-MM-DD')
    const endformat = moment(end).format('YYYY-MM-DD')
    setPeriod({ start: stformat, end: endformat })
  }
  // 상세조회
  const onRequestInfo = (date: Date) => {
    const d = formatDateToString(date)
    setDate(d)
  }
  const navigate = useNavigate()

  return (
    <>
      <Box sx={{ p: CONTENT_PADDING, pt: CONTENT_PADDING_TOP }}>
        <CalendarMain
          onRequestInfo={onRequestInfo}
          events={response ? response : null}
          onChangeDate={onChangeDate}
          viewAll={true}
          readOnly={true}
        />
      </Box>
      {list && <MyPlanContent onFinish={getPlan} readOnly={true} list={list} />}
    </>
  )
}
