import styles from '../../styles/InputNumber.module.css'
import MinustIcon from '../../../../../assets/images/icon/MinusIcon.png'
import PlusIcon from '../../../../../assets/images/icon/PlusIcon.png'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

type InputNumberType = {
  name: string
  max?: number
  inputComp: JSX.Element | JSX.Element[]
  setValue: ReturnType<typeof useForm>['setValue']
  getValues: ReturnType<typeof useForm>['getValues']
  disabled?: boolean
  caption?: string
  defaultVal?: number
}

export default function InputNumber({
  defaultVal = 10,
  caption,
  max,
  disabled,
  name,
  inputComp,
  setValue,
  getValues,
}: InputNumberType) {
  const [renderToggle, setRenderToggle] = useState(false)

  useEffect(() => {
    setValue(name, defaultVal)
  }, [])

  const onClickMinus = () => {
    if (disabled) return

    if (typeof getValues(name) === 'string' || typeof getValues(name) === 'number') {
      let val = parseInt(getValues(name), 10)
      if (val === 1) {
        setValue(name, '1')
        return
      }
      if (100 > val && 0 < val) {
        val -= 1
        setValue(name, val.toString())
      }
    }
  }

  const onClickPlus = () => {
    if (disabled) return
    if (max && getValues(name) >= max) return
    if (getValues(name) === '0' || getValues(name) === 0) {
      setValue(name, '1')
      return
    }
    if (typeof getValues(name) === 'string' || typeof getValues(name) === 'number') {
      let val = parseInt(getValues(name), 10)
      if (100 > val && 0 < val) {
        val += 1
        setValue(name, val.toString())
      }
    }
  }

  return (
    <>
      <Wrap>
        <div className={styles.div_counter_wrap}>
          <ClickableDiv onClick={onClickMinus}>
            <Minus className={styles.img_count} src={MinustIcon} alt="마이너스" />
          </ClickableDiv>
          {inputComp}
          <ClickableDiv onClick={onClickPlus}>
            <Plus className={styles.img_count} src={PlusIcon} alt="플러스" />
          </ClickableDiv>
        </div>
        {caption && <Caption>{caption}</Caption>}
      </Wrap>
    </>
  )
}

const Wrap = styled.div`
  align-items: center;
  display: flex;
`

const Caption = styled.span`
  margin-left: 8px;
`

const Minus = styled.img`
  width: 9px;
`
const Plus = styled.img`
  width: 9px;
`

const ClickableDiv = styled.div`
  height: 100%;
  align-items: center;
  align-content: center;
  outline: none; /* 포커스 시 나타나는 테두리 제거 */
  user-select: none; /* 텍스트 선택 방지 */

  &:active {
    background-color: inherit; /* 클릭 시 배경색 변경 방지 */
  }

  &:focus {
    outline: none; /* 포커스 시 나타나는 테두리 제거 */
  }

  /* 모바일 브라우저의 클릭 하이라이트 제거 */
  -webkit-tap-highlight-color: transparent;
`
