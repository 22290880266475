import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useNavigation } from 'react-router-dom'
import ScrollButton from 'src/components/common/button/ScrollButton'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import DrawerRightLayout from 'src/components/common/layout/DrawerRightLayout'
import MuiMain from 'src/components/common/mui/MuiMain'
import PlazaHome from 'src/components/specific/plaza/PlazaHome'
import PlazaCreate from './create/PlazaCreate'
import PlazaCreateForm from 'src/components/specific/plaza/create/PlazaCreateForm'
import { PostType } from 'src/utils/common/type/type'
import { HiOutlineTrash } from 'react-icons/hi'
import Alert from 'src/components/common/modal/Alert'
import { useRecoilState } from 'recoil'
import { alertState } from 'src/utils/common/state/state'
import { useDeletePost } from 'src/components/specific/plaza/hooks/PlazaCreateCustomHook'

export default function Plaza() {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const navigate = useNavigate()
  const [closeInner, setCloseInner] = useState(false)
  const [updatePost, setUpdatePost] = useState<PostType | undefined>(undefined)
  const [open, setOpen] = useState(false)

  const [isUpdated, setIsUpdated] = useState(false)
  const { deleteItem, isPending, deletingSuccess } = useDeletePost()
  useEffect(() => {
    if (deletingSuccess) {
      setIsUpdated(true)
    }
  }, [deletingSuccess])

  return (
    <ContainerLayout styleId="div_container_02">
      <MuiMain>
        <Content
          p={0}
          component={
            <PlazaHome
              setInitStts={() => {
                setIsUpdated(false)
              }}
              onClickBel={() => {}}
              onClickPost={() => {
                setDrawerOpen(true)
              }}
              refetch={isUpdated}
              onClickUpdate={(id: PostType) => {
                setUpdatePost(id)
                setDrawerOpen(true)
              }}
            />
          }
        />
      </MuiMain>

      <DrawerRightLayout
        title={updatePost ? '게시글 수정' : '게시글 등록'}
        pt={0}
        pb={0}
        comp={
          updatePost ? (
            <>
              <HiOutlineTrash
                onClick={() => {
                  if (isPending) return
                  setOpen(true)
                }}
                style={{
                  justifySelf: 'self-end',
                  marginRight: '17px',
                }}
                color="#979797"
              />
            </>
          ) : undefined
        }
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false)
          setCloseInner(false)
          setUpdatePost(undefined)
        }}
        closeInner={closeInner}
        children={
          <>
            <PlazaCreateForm
              openProp={open}
              onPostSuccess={updateYn => {
                setCloseInner(true)
                if (updateYn === true) {
                  navigate('/plaza', { state: { direction: 'na' } })
                }
              }}
              updatePost={updatePost}
              formType="ALL"
            />
          </>
        }
      />

      {/* <Alert
        afterCloseAlert={() => {
          if (updatePost) {
            if (updatePost.type) {
              deleteItem(updatePost.articleId, updatePost.type.includes('GENERAL') ? 'general' : 'qna')
            } else {
              deleteItem(updatePost.articleId, 'unset')
            }
          }
        }}
      /> */}
    </ContainerLayout>
  )
}
