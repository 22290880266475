import { generateRandomKey } from 'src/utils/common/scripts/common'
import { MenuOptionType } from 'src/utils/common/type/type'
import styled from 'styled-components'

type MenuContent = {
  left?: string
  top?: string
  title: string
  options: MenuOptionType[]
}

export default function MenuContent({ options, left = '-184px', top = '1px', title }: MenuContent) {
  return (
    <MenuWrap $left={left} $top={top}>
      {/* <MenuTitle>
        <p>{title}</p>
      </MenuTitle> */}
      {options.map((item, index) => {
        return <MenuOption mt="0px" key={generateRandomKey()} onClick={item.onClick} optionTitle={item.optionTitle} />
      })}
    </MenuWrap>
  )
}

type OptionType = {
  optionTitle: string
  onClick: () => void
  mt?: string
}
export function MenuOption({ optionTitle, onClick, mt = '0px' }: OptionType) {
  return (
    <Option onClick={onClick} $mt={mt}>
      <p>{optionTitle}</p>
    </Option>
  )
}

const Option = styled.div<{ $mt: string }>`
  /* Rectangle 150 */
  margin-top: ${({ $mt }) => $mt};

  box-sizing: border-box;
  align-content: center;

  width: 100%;
  height: 46px;
  p {
    padding-left: 9.11px;

    text-align-last: start;

    /* 식물 삭제 */

    /* Body 02 */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    /* identical to box height, or 153% */
    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }

  &:hover {
    p {
      color: #00c398;
    }

    cursor: pointer;

    /* Frame 726 */

    /* Secondary 01 */
    background: #e0f7f1;
    border-radius: 9px;
  }
`

const MenuWrap = styled.div<{ $left: string; $top: string }>`
  left: -120px;
  width: 134px;
  position: absolute;
  height: fit-content;

  top: 1px;
  padding-left: 11.71px;
  padding-right: 11.71px;
  padding-bottom: 8px;
  padding-top: 8px;

  z-index: 3;

  /* Menu */

  /* White */
  background: #ffffff;
  /* Button-Dropshadow */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 13px;
`

const MenuTitle = styled.div`
  text-align: center;
  margin-top: 18px;
  display: flex;
  place-content: center;

  margin-top: 18px;
  p {
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    align-items: center;
    letter-spacing: -0.05em;

    color: #979797;
    margin: auto;
  }
`
