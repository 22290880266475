import { Box } from '@mui/material'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import LineOne from 'src/components/common/line/LineOne'
import MuiMain from 'src/components/common/mui/MuiMain'
import Title from 'src/components/common/title/Title'
import { CONTENT_PADDING, SETTING_LIST } from 'src/utils/common/constants/constants'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import { SettingType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import Forward from '../../assets/images/icon/ForwardIcon.png'
import { useMutation } from '@tanstack/react-query'
import { deleteDataTanstackWithToken } from 'src/utils/common/api/http'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCheckTokenValidity } from 'src/utils/common/hooks/customHook'

type SettingItemType = {
  item: SettingType
  mt: string
}
export default function MyPage() {
  return (
    <>
      <ContainerLayout>
        <MuiMain>
          <Title backPath="/profile/myprofile" title="설정" />

          <Content
            p={0}
            component={
              <>
                <SettingList />
              </>
            }
          />
        </MuiMain>
      </ContainerLayout>
    </>
  )
}

function SettingList() {
  return (
    <>
      {SETTING_LIST.map((item: SettingType, index) => {
        return (
          <>
            {index !== 0 && <LineOne mt="24px" />}
            <Box sx={{ p: CONTENT_PADDING, pt: 0, pb: 0 }}>
              <SettingItem key={generateRandomKey()} item={item} mt={index === 0 ? '0px' : '24px'} />
            </Box>
          </>
        )
      })}
    </>
  )
}

function SettingItem({ item, mt }: SettingItemType) {
  const navigate = useNavigate()
  const { checkData } = useCheckTokenValidity()
  const {
    mutate,
    data,
    isSuccess: deletingSuccess,
    isError: deletingFailed,
    isPending: deleting,
  } = useMutation({
    mutationFn: deleteDataTanstackWithToken,
    onSuccess: response => {
      toast.success('로그아웃 되었습니다.')

      navigate('/', {
        state: {
          direction: 'back',
        },
      })
    },
    onError: response => {
      toast.error('로그아웃에 실패하였습니다.')
      checkData(response)
    },
  })

  const requestLogout = () => {
    const url = `${process.env.REACT_APP_API_DOMAIN}auth-service/logout`
    mutate({ url })
  }

  const onClickItem = (title: string) => {
    if (deleting) return
    if (title === '로그아웃') {
      requestLogout()
    }
  }
  return (
    <>
      <SettingTitle $mt={mt}>{item.title}</SettingTitle>
      {item.children.map(child => {
        if (child.hasPage) {
          return (
            <ChildWrap
              onClick={() => {
                onClickItem(child.title)
              }}
              key={generateRandomKey()}
            >
              <ChildTitle>{child.title}</ChildTitle>
              <ForwardImg src={Forward} alt={child.title} />
            </ChildWrap>
          )
        } else {
          return (
            <ChildWrap
              onClick={() => {
                onClickItem(child.title)
              }}
              key={generateRandomKey()}
            >
              <ChildTitle>{child.title}</ChildTitle>
            </ChildWrap>
          )
        }
      })}
    </>
  )
}

const ForwardImg = styled.img``

const ChildWrap = styled.div`
  display: flex;
  margin-top: 20px;
  place-content: space-between;
  &:hover {
    cursor: pointer;
  }
`

const ChildTitle = styled.p`
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: -0.05em;

  color: #1c1c1c;
`

const SettingTitle = styled.h2<{ $mt: string }>`
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: -0.05em;
  margin-top: ${({ $mt }) => $mt};

  color: #1c1c1c;
`
