import styled from 'styled-components'
import addIcon from '../../../../assets/images/icon/addPlan.png'
import { SAMPLE_TODO } from 'src/utils/common/constants/constants'
import Plan from './Plan'
import PlusIcon from 'src/components/common/icon/PlusIcon'
import { CustomButton } from 'src/components/common/button/CustomButton'
import { NoCharContainer } from 'src/components/common/swiper/SwipeList'

export default function Todo({ onClick }: { onClick: () => void }) {
  return (
    <>
      <TodoBtn onClick={onClick} />
    </>
  )
}
export function TodoBtn({ onClick }: { onClick: () => void }) {
  return (
    <>
      <BtnWrap>
        <CustomButton
          comp={
            <>
              <PlusIcon margin="0px 10px 0px 0px" size={20} />
            </>
          }
          margin="16px 0px 14px 0px"
          styleID="div_add_plant"
          type="button"
          onClick={onClick}
        >
          일정 등록
        </CustomButton>
      </BtnWrap>
    </>
  )
}

const BtnWrap = styled.div`
  display: flex;

  align-items: center;
  place-content: center;
`
