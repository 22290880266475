import { Skeleton } from '@mui/material'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import LoadingContainer from 'src/components/common/container/LoadingContainer'
import { alertState, LodingSpinnerType } from 'src/utils/common/state/state'
import styled from 'styled-components'
import useDeletePlant from '../hooks/getPlantInfoCustomHook'
import { useDeleteDiary } from '../../diary/hooks/DiaryCreateCustomHook'
import Alert from 'src/components/common/modal/Alert'

export default function DiaryContent({
  width = '308px',
  marginTop = '5px',
  title,

  content,
}: {
  marginTop?: string

  width?: string
  title: string | null
  content: string | null | undefined
}) {
  const { deletePlant, frmtPlant, loadinPlant, deletingPlant } = useDeletePlant()
  const [showSpinner, setShowSpinner] = useRecoilState(LodingSpinnerType)
  // const { deleting, deleteItem, deletingSuccess, deletingFailed } = useDeleteDiary({
  //   onPostFinish,
  //   setDiaryDetail,
  //   setDiaryId,
  // })
  // useEffect(() => {
  //   if (deletingSuccess !== undefined) {
  //     setShowSpinner(false)
  //     // if (deletingSuccess === true)
  //   }
  // }, [deletingSuccess])

  // useEffect(() => {
  //   if (!deletingPlant) {
  //     setShowSpinner(false)
  //   }
  // }, [deletingPlant])
  return (
    <>
      <Content $margintop={marginTop}>
        {title ? <h1>{title}</h1> : <Skeleton variant="rounded" width={60} height={20} />}
        {content ? (
          <ContentWrap $width={width}>{content}</ContentWrap>
        ) : (
          <Skeleton variant="rounded" sx={{ mt: '12px' }} width={width} height={122} />
        )}
      </Content>
    </>
  )
}

export function PlantContent({
  width = '308px',
  marginTop = '5px',
  title,

  content,
}: {
  marginTop?: string

  width?: string
  title: string | null
  content: string | null | undefined
}) {
  return (
    <>
      <PlantContentTitle $margintop={marginTop}>
        {title ? <h1>{title}</h1> : <Skeleton variant="rounded" width={60} height={20} />}
        {content ? (
          <>
            {title === '식물 설명' ? (
              <ContentWrapDiv $width={width}>{content}</ContentWrapDiv>
            ) : (
              <ContentWrap $width={width}>{content}</ContentWrap>
            )}
          </>
        ) : (
          <Skeleton variant="rounded" sx={{ mt: '12px' }} width={width} height={122} />
        )}
      </PlantContentTitle>
    </>
  )
}

const PlantContentTitle = styled.div<{ $margintop: string }>`
  margin-top: ${({ $margintop }) => `${$margintop}`};

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */
    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }
`

const Content = styled.div<{ $margintop: string }>`
  margin-top: ${({ $margintop }) => `${$margintop}`};

  h1 {
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: -0.02em;

    color: #242424;

    margin-bottom: 0px;
  }
`

const ContentWrap = styled.pre<{ $width: string }>`
  margin-top: 12px;
  width: ${({ $width }) => `${$width}`};
  font-family: 'Pretendard-Regular';

  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  /* or 153% */
  letter-spacing: -0.02em;

  /* Black */
  color: #242424;

  text-align: justify;
`

const ContentWrapDiv = styled.div<{ $width: string }>`
  margin-top: 12px;
  width: ${({ $width }) => `${$width}`};
  font-family: 'Pretendard-Regular';

  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  /* or 153% */
  letter-spacing: -0.02em;

  /* Black */
  color: #242424;

  text-align: justify;
`
