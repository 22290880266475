/*eslint-disable */

import { create } from 'zustand'

import ProfileContent from 'src/components/specific/profile/sub/ProfileContent'
import Follow from 'src/pages/follow/Follow'

export const useProfilePageStatus = create((set, get) => ({
  pageStatus: 'main', // 현재 페이지 상태 값, 이 값에 따라 랜더링 컴포넌트 달라짐
  pageValue: {
    // PlantMain.tsx로 들어가는 값들

    content: null, // 보여줄 컴포넌트
  },
  setPageStatus: status => {
    set({ pageStatus: status })
  },
  // 현재 pageStatus 상태 값에 따른 랜더링 분기 처리
  setPageValue: () => {
    switch (get().pageStatus) {
      case 'main': // 첫번째 페이지
        return set({
          pageValue: {
            content: <ProfileContent />,
          },
        })
      case 'follower': // 두번째 페이지
        return set({
          pageValue: {
            content: <Follow />,
          },
        })

      case 'following': // 두번째 페이지
        return set({
          pageValue: {
            content: <Follow />,
          },
        })
    }
  },
}))
