import { Grid } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import FormMain from 'src/components/common/form/FormMain'
import { useLogin } from 'src/utils/query/login/useLogin'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import MaskingIcon from 'src/components/common/icon/MakingIcon'
import { getCookie, setCookie } from 'src/utils/common/scripts/common'

interface FormType {
  // 서버 req body 파라미터 키 이름 하고 맞췄음
  email: string
  password: string
}

export default function LoginForm() {
  const navigate = useNavigate()
  const { register, handleSubmit, getValues, setValue } = useForm<FormType>()
  const { mutate, isLoading, isPending } = useLogin()
  const [isMasking, setIsMasking] = useState(true)
  const [formAlert, setFormAlert] = useState<undefined | string>(undefined)

  useEffect(() => {
    const savedEmail = getCookie('email')
    const savedPwd = getCookie('password')

    if (savedEmail && savedPwd) {
      setValue('email', savedEmail)
      setValue('password', savedPwd)
    }
  }, [])

  const onClickLoginBtn: SubmitHandler<FormType> = data => {
    if (isPending) return

    if (Object.values(data).some(value => value === '')) {
      return setFormAlert('이메일 또는 비밀번호를 다시 확인해주세요')
    } else {
      if (!isPending) {
        setCookie('email', getValues('email'), 30)
        setCookie('password', getValues('password'), 30)
        setFormAlert('')

        return mutate(data)
      } else {
        return null
      }
    }
  }
  return (
    <>
      <FormMain.LabelLayout infoText={formAlert} infoStyleId="text_red" isFirst={true}>
        <FormMain.InputFull
          styleId={
            formAlert && formAlert.length > 0
              ? 'div_with_full_custom_height mb_10 border_red'
              : 'div_with_full_custom_height mb_10'
          }
          height="49px"
          inputComp={<FormMain.Input styleID="" type="text" placeholder="이메일" registerFn={register('email')} />}
        />
        <FormMain.InputFull
          styleId={
            formAlert && formAlert.length > 0
              ? 'div_with_full_custom_height mb_10 border_red'
              : 'div_with_full_custom_height mb_10'
          }
          height="49px"
          iconComp={
            <MaskingIcon
              isMasking={isMasking}
              onClick={() => {
                setIsMasking(prev => !prev)
              }}
            />
          }
          inputComp={
            <FormMain.Input
              styleID=""
              type={isMasking ? 'password' : 'text'}
              placeholder="비밀번호"
              registerFn={register('password')}
            />
          }
        />
      </FormMain.LabelLayout>

      <FormMain.Button
        onClick={handleSubmit(onClickLoginBtn)}
        height="48px"
        styleID="btn_submit_01_active"
        width="100%"
        type="submit"
        margin="46px 0 13px 0"
      >
        {isPending ? '로그인 중...' : '로그인'}
      </FormMain.Button>

      <FindAccText textAlign="center">
        계정이 기억나지 않아요
        <span
          style={{
            marginLeft: '9px',
          }}
          onClick={() => {
            navigate('/user/find/id')
          }}
        >
          계정찾기
        </span>
      </FindAccText>
    </>
  )
}

const FindAccText = styled(Grid)`
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
  color: #979797;
  & > span {
    font-weight: 600;
    color: black;
    border-bottom: 1px solid black;
    cursor: pointer;
  }
`
