import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import NoData from 'src/components/common/content/Nodata'
import HashTagListLayout, { RecentKeyWordListLayout } from 'src/components/common/layout/HashTagListLayout'

export interface HashTagListType {
  watch: ReturnType<typeof useForm>['watch']
  getValues: ReturnType<typeof useForm>['getValues']
  setValue: ReturnType<typeof useForm>['setValue']
  name: string
}

export default function HashTagList({ name, watch, setValue, getValues }: HashTagListType) {
  const [toggle, setToggle] = useState(false)
  const [newArr, setNewArr] = useState([])

  useEffect(() => {
    const subscirbe = watch((data, { name: tgNm }) => {
      if (tgNm === name + 'Arr') {
        setToggle(prev => !prev)
        setNewArr(getValues(name + 'Arr'))
      }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])

  return (
    <>
      {newArr ? (
        <HashTagListLayout isHistory={true} setValue={setValue} getValues={getValues} name={name} hashTagArr={newArr} />
      ) : (
        <></>
      )}
    </>
  )
}

export function RecentKeyWordList({ name, watch, setValue, getValues }: HashTagListType) {
  const [toggle, setToggle] = useState(false)
  const [newArr, setNewArr] = useState([])

  useEffect(() => {
    const subscirbe = watch((data, { name: tgNm }) => {
      if (tgNm === name + 'Arr') {
        setToggle(prev => !prev)
        setNewArr(getValues(name + 'Arr'))
      }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])

  return (
    <>
      {newArr ? (
        <RecentKeyWordListLayout
          isHistory={true}
          setValue={setValue}
          getValues={getValues}
          name={name}
          hashTagArr={newArr}
        />
      ) : (
        <></>
      )}
    </>
  )
}
