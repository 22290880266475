import { useForm } from 'react-hook-form'
import CustomChip from '../../../chip/CustomChip'

import styles from '../../styles/ChoiceChips.module.css'
import { useEffect, useState } from 'react'
import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import { SelectChipsType } from 'src/utils/common/type/type'
import { generateRandomKey } from 'src/utils/common/scripts/common'

type ChoiceChipsType = {
  readOnly?: boolean
  register: ReturnType<typeof useForm>['register']
  getValues: ReturnType<typeof useForm>['getValues']
  setValue: ReturnType<typeof useForm>['setValue']
  listName: string
  multiple?: boolean
  list: SelectChipsType[] | null
  defaultVal?: string | null | string[]
  length: number
}

export default function ChoiceChips({
  multiple = false,
  listName,
  readOnly = false,
  list,
  register,
  getValues,
  setValue,
  length,
  defaultVal,
}: ChoiceChipsType) {
  const [renderToggle, setRenderToggle] = useState(false)

  useEffect(() => {
    if (defaultVal) {
      register(listName)

      if (typeof defaultVal === 'string') {
        handleChipClick(defaultVal)
      } else {
        handleChipsInit(defaultVal)
        handleChipClickMultiple(defaultVal)
        // defaultVal.map(item => handleChipClick(item))
      }
    }
  }, [defaultVal])

  if (!list) {
    return (
      <div className={styles.div_chips_wrap}>
        {Array.from({ length: length }).map((_, index) => (
          <CustomChip readOnly={readOnly} key={generateRandomKey()} item={null} />
        ))}
      </div>
    )
  }

  const handleChipsInit = (fieldNameArr: string[]) => {
    if (!multiple) return
    const values = getValues(listName) || {}
    const updatedValues = { ...values }
    fieldNameArr.forEach(item => {
      updatedValues[item] = !updatedValues[item]
    })
    setValue(listName, false)
  }

  const handleChipClickMultiple = (fieldNameArr: string[]) => {
    if (!multiple) return
    const values = getValues(listName) || {}
    const updatedValues = { ...values }
    fieldNameArr.forEach(item => {
      updatedValues[item] = !updatedValues[item]
    })
    setValue(listName, updatedValues)
    setRenderToggle(prev => !prev) // 트리거 상태 변경으로 리렌더링
  }

  const handleChipClick = (fieldName: string) => {
    const values = getValues(listName) || {}
    const updatedValues = { ...values }
    if (multiple === false) {
      list.forEach(item => {
        updatedValues[item.name] = item.name === fieldName ? !values[fieldName] : false
      })
    } else {
      updatedValues[fieldName] = !updatedValues[fieldName]
    }

    setValue(listName, updatedValues)
    setRenderToggle(prev => !prev) // 트리거 상태 변경으로 리렌더링
  }

  if (length >= list.length) {
    return (
      <div className={`${divideStyleIDString(styles, 'div_chips_wrap mb_10_px')}`}>
        {list.map(item => (
          <CustomChip
            readOnly={readOnly}
            key={item.name}
            item={item}
            styleId={getValues(`${listName}.${item.name}`) === true ? 'div_chip selected' : 'div_chip'}
            registerFn={register(`${listName}.${item.name}`)}
            onClick={() => {
              handleChipClick(item.name)
            }}
          />
        ))}
      </div>
    )
  } else {
    return (
      <>
        <div className={styles.div_chips_wrap}>
          {list.slice(0, length).map(item => (
            <CustomChip
              readOnly={readOnly}
              key={item.name}
              item={item}
              styleId={getValues(`${listName}.${item.name}`) === true ? 'div_chip selected' : 'div_chip'}
              registerFn={register(`${listName}.${item.name}`)}
              onClick={() => handleChipClick(item.name)}
            />
          ))}
        </div>
        <div className={`${divideStyleIDString(styles, 'div_chips_wrap mt_6_px mb_10_px')}`}>
          {list.slice(length).map(item => (
            <CustomChip
              readOnly={readOnly}
              key={item.name}
              item={item}
              styleId={getValues(`${listName}.${item.name}`) === true ? 'div_chip selected' : 'div_chip'}
              registerFn={register(`${listName}.${item.name}`)}
              onClick={() => handleChipClick(item.name)}
            />
          ))}
        </div>
      </>
    )
  }
}
