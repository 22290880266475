import { useState } from 'react'
import { CustomButton } from 'src/components/common/button/CustomButton'
import CreateNewPlant from 'src/components/specific/plant/create/CreateNewPlant'
import { usePlantPageStatus } from '../../../../utils/store/plant/usePlantPageStatus'

export default function PlantInfo() {
  // const onClickNxtBtn = () => {
  //   setPageStatus('regSchedule')
  // }
  const onClickNxtBtn = () => {}
  return (
    <div>
      <CreateNewPlant />
    </div>
  )
}
