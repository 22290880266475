import { useEffect, useState } from 'react'
import BookmarkItem from 'src/components/common/content/BookmarkItem'
import SwiperListAutoWidth from 'src/components/common/swiper/SwiperListAutoWidth'
import { COLLECTION_LIST_SAMPLE, COLLECTION_REC_SAMPLE } from 'src/utils/common/constants/constants'
import { generateRandomKey, getHistory, onSaveHistory } from 'src/utils/common/scripts/common'
import { collectionListSampleType, RcntVwdItem } from 'src/utils/common/type/type'
import styled from 'styled-components'
import { SwiperSlide } from 'swiper/react'
import { useGetRcntVwdList } from '../hook/CollectionListCustomHook'
import { useLocation, useNavigate } from 'react-router-dom'
import { queryClient } from 'src/App'
import NoData from 'src/components/common/content/Nodata'

/*
    파일명 : RcntVwdList.tsx
    설명 : 최근 본 식물 > 리스트
    작성일 : 2024-06-16
*/
export default function RcntVwdList({
  list,
  onClick,
  isPending,
}: {
  list: RcntVwdItem[] | null
  onClick: (id: number) => void
  isPending: boolean
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    setToggle(prev => !prev)
  }, [isPending])
  if (isPending === true) {
    return (
      <>
        <SwiperListAutoWidth spaceBetween={10}>
          {Array.from({ length: 5 }).map((_, index) => (
            <SwiperContainer key={generateRandomKey()}>
              <BookmarkItem namefs="15px" namelh="18px" onClick={() => {}} key={generateRandomKey()} item={null} />
            </SwiperContainer>
          ))}
        </SwiperListAutoWidth>
      </>
    )
  }
  if (list && list.length > 0)
    return (
      <SwiperListAutoWidth spaceBetween={10}>
        <></>
        {list.map(item => (
          <SwiperContainer key={generateRandomKey()}>
            <BookmarkItem
              namefs="15px"
              namelh="18px"
              onClick={() => {
                onSaveHistory(item.plantSpeciesId, 'recently_clicked')
                onClick(item.plantSpeciesId)
                navigate(`/collection/${item.plantSpeciesId}`, { state: { backpath: location.pathname } })
              }}
              key={item.plantSpeciesId}
              item={{
                id: item.plantSpeciesId,
                name: item.commonName,
                img_url: item.imageUrl,
              }}
            />
          </SwiperContainer>
        ))}
      </SwiperListAutoWidth>
    )
  else
    return (
      <>
        <NoData discription="최근 본 식물이 없습니다." />
      </>
    )
}

const SwiperContainer = styled(SwiperSlide)`
  box-sizing: border-box;
  height: 154px;
  width: 92px;
`
