import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import styled from 'styled-components'
import ImgTag from '../imgTag/ImgTag'

interface DataObj {
  title: JSX.Element | JSX.Element[]
  imgSrc: string
  subText: JSX.Element | JSX.Element[]
  width: string
  height: string
}

interface SwiperMainProps {
  dataObjArr: DataObj[]
}

export default function SwiperMain({ dataObjArr }: SwiperMainProps) {
  return (
    <SwiperContainer
      pagination={true}
      modules={[Pagination, Autoplay]}
      className="mySwiper"
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
    >
      {dataObjArr.map(item => (
        <SwiperItem key={Math.random()}>
          <HeaderText>{item.title}</HeaderText>
          <ImgContainer>
            <ImgTag width={item.width} height={item.height} imgObj={{ src: item.imgSrc, alt: '' }} />
            <SubText>{item.subText} </SubText>
          </ImgContainer>
        </SwiperItem>
      ))}
    </SwiperContainer>
  )
}

const SwiperContainer = styled(Swiper)`
  width: 100%;

  height: 65vh;
  flex-direction: column;

  display: flex;
  box-sizing: border-box;

  @media (min-height: 780px) {
    height: 58vh;
  }

  @media (min-height: 800px) {
    margin-top: 2vh;
  }
  * > .swiper-pagination-bullet-active {
    background: #00c398;
  }
  .swiper-pagination {
    position: relative;
    margin-top: 4vh;
    @media (min-height: 780px) {
      margin-top: 0%;
    }
  }
`

const SwiperItem = styled(SwiperSlide)`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HeaderText = styled.div`
  font-size: 22.5px;
  line-height: 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  text-align: center;
  font-weight: 600;
  margin-bottom: 2vh;

  @media (min-height: 780px) {
    margin-bottom: 0%;
  }
`

const SubText = styled.div`
  height: 8vh;

  display: flex;
  flex-direction: column;

  color: #343434;

  align-items: center;
  text-align: center;
  align-content: center;

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  margin-top: 2vh;
  line-height: 20px;

  letter-spacing: -0.05em;

  color: #1c1c1c;
`

const ImgContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`
