import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import MuiMain from 'src/components/common/mui/MuiMain'
import GreenDetailMain from 'src/components/specific/green/detail/GreenDetailMain'

/*
    파일명 : GreenDetail.tsx
    설명 : 푸른마켓 > 상세
    작성일 : 2024-06-21
*/
export default function GreenDetail() {
  return (
    <ContainerLayout styleId="div_container_02">
      <MuiMain>
        <GreenDetailMain />
      </MuiMain>
    </ContainerLayout>
  )
}
