import { useNavigate } from 'react-router-dom'
import SwiperListTwo from 'src/components/common/swiper/SwiperListTwo'
import TitleLight from 'src/components/common/title/TitleLight'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import styled from 'styled-components'
import { SwiperSlide } from 'swiper/react'

type CollectionDetailImgListType = {
  srcArr: string[] | null // 문자열 배열 또는 null로 수정
  backUrl: string
  onClickBackBtn?: () => void
}

export default function CollectionDetailImgList({ srcArr, backUrl, onClickBackBtn }: CollectionDetailImgListType) {
  console.log(srcArr)
  const navigate = useNavigate()
  if (srcArr) {
    return (
      <>
        <SwiperListTwo slidesPerView={1} pagination={true}>
          {srcArr.map((item: string) => {
            return (
              <SwiperContainer key={generateRandomKey()}>
                <ImgDiv src={item}>
                  <TitleLight
                    onClick={() => {
                      if (onClickBackBtn) {
                        onClickBackBtn?.()
                      } else {
                        navigate(backUrl, { state: { direction: 'back' } })
                      }
                    }}
                    // options={[{ optionTitle: '메뉴1', onClick: () => {} }]}
                    options={[]}
                    menuTitle=""
                  />
                </ImgDiv>

                {/* <Img src={item} alt="이미지" /> */}
              </SwiperContainer>
            )
          })}
        </SwiperListTwo>
      </>
    )
  } else {
    return <LoadingContainer />
  }
}

const LoadingContainer = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  background: #f2f2f2;
`

const SwiperContainer = styled(SwiperSlide)`
  width: 100%;
  aspect-ratio: 1 / 1;
`

const Img = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
`

const ImgDiv = styled.div<{ src: string }>`
  width: 100%;
  aspect-ratio: 1 / 1;
  background-size: contain;
  background-color: #f2f2f2;
  background-repeat: no-repeat; /* 이미지 반복 없음 */
  background-position: center; /* 이미지를 가운데 정렬 */
  background-image: ${({ src }) => `url(${src})`}; /* src 속성으로부터 이미지 URL을 받아와 배경 이미지로 설정 */
`
