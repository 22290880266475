import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/ko' // 한국어 로케일을 로드
import { useEffect, useState } from 'react'
import { useValidationCheck } from 'src/utils/common/hooks/customHook'

type PlantRepeatDiscriptionProps = {
  watch: ReturnType<typeof useForm>['watch']
  getValues: ReturnType<typeof useForm>['getValues']
  repeatEndDate?: Date
  defaultFrequency: string | number
}

export default function PlantRepatDiscription({
  repeatEndDate,
  defaultFrequency,
  watch,
  getValues,
}: PlantRepeatDiscriptionProps) {
  const [frequency, setFrequency] = useState(defaultFrequency)
  const [entDt, setEndDt] = useState(repeatEndDate)

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (name === 'frequency') {
        setFrequency(data.frequency)
      }
      if (name === 'endDt') {
        setEndDt(data.endDt)
      }
    })

    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <PlanRepeatDiscription>
      <p>
        일정이 <span>{`${frequency}일에 한 번`}</span> 반복됩니다.
      </p>
      {repeatEndDate && (
        <p>
          <span>{`${moment(entDt).format('YYYY년 MM월 DD일')}`}</span>까지 반복됩니다.
        </p>
      )}
    </PlanRepeatDiscription>
  )
}

const PlanRepeatDiscription = styled.div`
  /* Frame 652 */
  margin-top: 23px;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 35px;
  p {
    /* Caption */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */
    text-align: center;
    letter-spacing: -0.02em;

    /* Gray 06 */
    color: #5b5b5b;
  }

  span {
    /* 일정이 3일에 한 번 반복됩니다. */

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    /* identical to box height, or 153% */
    text-align: center;
    letter-spacing: -0.02em;

    /* Primary */
    color: #00c398;
  }
`

const repeatToggleGroup = {
  marginTop: '30px',
  width: '100%',
  height: '43px',
  background: '#F4F6FA',
  borderRadius: '50px',
  border: 'none',
  alignItems: 'center',
  paddingLeft: '4px',
  paddingRight: '4px',
}

const repeatToggle = {
  width: '100%',
  border: 'none',
  height: '35px',
  borderRadius: '50px',
  fontFamily: 'Pretendard-Regular',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '15px',
  lineHeight: '23px',
  letterSpacing: '-0.02em',
}

const repeatToggleSelected = {
  width: '100%',
  border: 'none',
  height: '35px',
  borderRadius: '50px',
  fontFamily: 'Pretendard-Regular',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '15px',
  lineHeight: '23px',
  letterSpacing: '-0.02em',

  background: '#FFFFFF',

  color: '#00C398',
}
