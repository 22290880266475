import styled from 'styled-components'
import Aside from '../content/Aside'
import BottomNav from '../content/BottomNav'
import Header from '../content/HeaderAppBar'
import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { useCheckCurrentPageIsLoginPage } from 'src/utils/common/hooks/customHook'

interface LayoutProps {
  children: ReactNode
}
interface MainWrapperProps {
  $position: string
}
interface MainInnerProps {
  isTop: boolean
}
/**
 * 레이아웃 배치 위한 컴포넌트
 * @param param0
 * @returns
 */
export default function Layout({ children }: LayoutProps) {
  const notLoginPage = useCheckCurrentPageIsLoginPage()
  const h = window.innerHeight
  return (
    <LayoutContainer $h={h}>
      {/* <Aside /> */}
      <MainWrapper $position="relative">
        {/* <HeaderContainer>
          <Header />
        </HeaderContainer> */}
        <Content>
          {children}
          {/* <Footer>ddd</Footer> */}
        </Content>
        {notLoginPage && (
          <BottomContainer>
            <BottomNav />
          </BottomContainer>
        )}
      </MainWrapper>
    </LayoutContainer>
  )
}
const LayoutContainer = styled.div<{ $h: number }>`
  width: 100vw;
  display: flex;
  // height: ${({ $h }) => `${$h}px`};
  justify-content: center;
  background-color: white;
`
const MainWrapper = styled.div<MainWrapperProps>`
  width: 390px;
  min-height: 100vh;
  overflow: hidden;
  position: ${props => props.$position};
  box-sizing: border-box;
  // left: 15vw;
  @media screen and (max-width: 1024px) {
    left: 0;
  }
  background: white;
`

const Content = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  box-sizing: content-box;
  // margin-top: 5vh;
  margin-bottom: 8vh;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  background-color: white;
  padding-bottom: 3vw;
`

const Footer = styled.footer`
  background: white;
  padding: 10px 0;
  color: black;
`
const HeaderContainer = styled.div`
  position: fixed;
  width: 390px;
  top: 0;
  z-index: 999;

  @media screen and (max-width: 390px) {
    width: 100vw;
  }
`

const BottomContainer = styled.div`
  width: 390px;

  position: fixed;
  bottom: 0;
  z-index: 999;
  @media screen and (max-width: 390px) {
    width: 100vw;
  }
`
