import styled from 'styled-components'

export default function DotButtonTwo({ bc = '#d9d9d9' }: { bc?: string }) {
  return (
    <Wrap>
      <DotButton $bc={bc} />
      <DotButton $bc={bc} />
      <DotButton $bc={bc} />
    </Wrap>
  )
}

const Wrap = styled.div`
  &:hover {
    cursor: pointer;
  }
  text-align: right;
`

const DotButton = styled.div<{ $bc: string }>`
  background-color: ${({ $bc }) => `${$bc}`};
  width: 2.5px;
  height: 2.5px;

  margin-top: 4.5px;
  border-radius: 50%;
`
