import styled from 'styled-components'

export default function ImgMiddleContainer({
  children,
  marginTop = '0',
}: {
  marginTop?: string
  children: React.ReactNode
}) {
  return <Container $margintop={marginTop}>{children}</Container>
}

const Container = styled.div<{ $margintop: string }>`
  text-align: center;
  margin-top: ${({ $margintop }) => $margintop}; /* 직접 prop을 적용하지 않고 스타일드 컴포넌트의 prop을 사용 */
`
