import styled from 'styled-components'

export default function FixedButtonLayout({
  children,
  bottom = '110px',
  zi = 'unset',
  p = 'fixed',
  ai = 'unset',
  maxWidth = '356px',
  left = 'unset',
}: {
  bottom?: string
  zi?: string
  p?: string
  children: React.ReactNode
  ai?: string
  maxWidth?: string
  left?: string
}) {
  return (
    <WrapBtn $left={left} $mw={maxWidth} $ai={ai} $p={p} $zi={zi} $bottom={bottom}>
      {children}
    </WrapBtn>
  )
}

const WrapBtn = styled.div<{ $left: string; $mw: string; $ai: string; $p: string; $bottom: string; $zi: string }>`
  position: ${({ $p }) => $p};

  width: 100%;
  max-width: ${({ $mw }) => $mw};
  display: flex;

  flex-direction: column;
  left: ${({ $left }) => $left};
  bottom: ${({ $bottom }) => $bottom};
  z-index: ${({ $zi }) => $zi};
  align-items: ${({ $ai }) => $ai};
`
