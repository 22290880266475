import { ChatItemType, PostSmrySampleType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import LoadingContainer from '../container/LoadingContainer'
import { displayedAt, formatNumber, getPostSttsImgSize } from 'src/utils/common/scripts/common'
import Sale from '../../../assets/images/post/sttsSale.png'
import { SALE_STTS } from 'src/utils/common/constants/constants'
import { GoodButton, GoodComment, GoodNCommentLayout } from '../button/GoodButton'
import { Skeleton } from '@mui/material'

type MarkedItemInfo = {
  item: ChatItemType | null
}

export default function ProfilePlantInfo({ item }: MarkedItemInfo) {
  if (!item) {
    return (
      <Wrap>
        <div>
          <Skeleton variant="text" height="18px" width={100} />
        </div>
        <BottomDiv>
          <Skeleton variant="text" height="18px" width={80} />
          <Skeleton variant="text" height="18px" width={60} />
        </BottomDiv>
      </Wrap>
    )
  }

  const imgNm = (item.stts && SALE_STTS[item.stts]?.imgName) || '' // Access object using variable stts
  const sttsNm = (item.stts && SALE_STTS[item.stts]?.name) || ''
  const price = item.price

  let priceComp = (
    <>
      <p>{price}</p>
    </>
  )
  if (item.stts && item.stts === 'SOLD') {
    priceComp = (
      <>
        <p style={{ color: '#979797' }}>{price}</p>원
      </>
    )
  } else if (item.stts && item.stts === 'FREE') {
    priceComp = <></>
  }

  const titleComp = <h2>{item.title.length > 12 ? `${item.title.slice(0, 12)}...` : item.title}</h2>

  return (
    <Wrap>
      <div>{titleComp}</div>

      <Price>
        <PriceInner>
          {item.stts ? (
            <img
              style={item.stts ? getPostSttsImgSize(item.stts) : {}}
              src={require(`/src/assets/images/post/${imgNm}`)}
              alt={sttsNm}
            />
          ) : (
            <></>
          )}

          {priceComp}
        </PriceInner>
      </Price>
      <BottomDiv>
        <Loc>
          <span>
            함께한지 <span>{item.loc}일째</span>
          </span>
        </Loc>
      </BottomDiv>
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100%;
      height: 100%;
      

 
      display: flex;
    flex-direction: column;
    justify-content: space-between;
     padding-left: 13px;

 
}


  h2 {
  margin-top: 8px;
/* 꼼삐 */


/* Body 01-M */
font-family: 'Pretendard-Regular';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */
letter-spacing: -0.02em;

/* Black */
color: #242424;









  }
`

const BottomDiv = styled.div`
  height: 43px;
  margin-bottom: 8px;
  align-content: end;

  span {
    /* 용산역 1번 출구 */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    letter-spacing: -0.02em;

    /* Black */
    color: #242424;
  }
`

const Loc = styled.div`
  /* 함께한지 10일 */

  /* Caption */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  letter-spacing: -0.02em;

  /* Black */
  color: #242424;
`

const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const PriceInner = styled.div`
  display: flex;
  align-items: center;
  p {
    /* 방울토마토 */

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    letter-spacing: -0.05em;

    color: #1c1c1c;

    margin-top: 8px;
  }
`
