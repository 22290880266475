import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

type PopOutLayoutProps = {
  children?: React.ReactNode
}

export default function MotionLeftToRightLayout({ children }: PopOutLayoutProps) {
  return (
    <Wrap
      exit={{ x: '100%' }} // 사라질 때의 위치 설정
      initial={{ x: '100%' }} // 초기 위치 (오른쪽 끝)
      animate={{ x: 0 }} // 최종 위치 (왼쪽 끝)
      transition={{ duration: 0.1 }} // 애니메이션 지속 시간
    >
      {children}
    </Wrap>
  )
}

const Wrap = styled(motion.div)`
  top: 0px;

  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 22;
`
