import { useEffect, useState } from 'react'
import AgreeList from './sub/agree/AgreeList'
import FormMain from 'src/components/common/form/FormMain'

// 예시데이터
const POLICY = [
  {
    id: 'FOURTEEN',
    title: '만 14세 이상 (필수)',
    link: '',
    required: true,
    description: '만 14세 이상 페이지 입니다.',
  },
  {
    id: 'SVC',
    title: '서비스 이용약관 (필수)',
    link: '있음',
    required: true,
    description: '서비스 이용약관 페이지 입니다.',
  },
  {
    id: 'PINFO',
    title: '개인정보 수집 및 이용 (필수)',
    link: '있음',
    required: true,
    description: '개인정보 수집 및 이용 페이지 입니다.',
  },
  {
    id: 'LOC',
    title: '위치기반서비스 이용약관 (필수)',
    link: '있음',
    required: true,
    description: '위치기반서비스 이용약관 페이지 입니다.',
  },
  {
    id: 'MKT',
    title: '마케팅 수신 동의 (선택)',
    link: '있음',
    required: false,
    description: '마케팅 수신 동의 페이지 입니다.',
  },
]

export default function MainUserCreateSvcPolicy({
  validCheck, // 필수 항목이 다 체크 되었는지 여부 return
  info, // 필수 체크 되지 않았을 때 뜨는 텍스트
}: {
  validCheck: (requiedAgreeUnChecked: boolean) => void
  info: string
}) {
  const AgreeValidChk = (isValid: boolean) => {
    validCheck(isValid)
  }

  return (
    <>
      <FormMain.LabelLayout label="서비스 정책" infoText={info} infoStyleId="text_red">
        <AgreeList list={POLICY} validCheck={AgreeValidChk} />
      </FormMain.LabelLayout>
    </>
  )
}
