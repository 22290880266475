import ReactDOM from 'react-dom/client'
import './style/index.css'
import './style/style.css'
import './utils/common/scripts/configure.js'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { Provider } from 'react-redux'
import store from './utils/store'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

window.Kakao.init(`${process.env.REACT_APP_KAKAO_SHARE}`) // 내 웹 키를 할당하면 된다

root.render(
  <BrowserRouter>
    <RecoilRoot>
      <Provider store={store}>
        <App />
      </Provider>
    </RecoilRoot>
  </BrowserRouter>,
)
