import styled from 'styled-components'
import backBtn from '../../../assets/images/icon/backBtn.png'

export default function BackIcon({
  onClick,
  mt = '3px',
  ml = '19px',
  mr = '0px',
  mb = '0px',
  as = 'unset',
}: {
  as?: string
  onClick: () => void
  mt?: string
  ml?: string
  mr?: string
  mb?: string
}) {
  return <Img $as={as} $mb={mb} $mr={mr} $mt={mt} $ml={ml} src={backBtn} alt="뒤로" onClick={onClick} />
}

const Img = styled.img<{ $as: string; $mb: string; $mt: string; $ml: string; $mr: string }>`
  margin-top: ${({ $mt }) => $mt};
  align-self: ${({ $as }) => $as};
  width: 8.7px;
  height: 16px;
  margin-left: ${({ $ml }) => $ml};
  margin-bottom: ${({ $mb }) => $mb};
  margin-right: ${({ $mr }) => $mr};
`
