import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import CollectionImgInfo from 'src/components/common/content/CollectionImgInfo'
import AddCollectionIcon from 'src/components/common/icon/AddCollectionIcon'
import FormModal from 'src/components/common/modal/FormModal'
import { COLLECTION_LIST_SAMPLE } from 'src/utils/common/constants/constants'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import { formModalState } from 'src/utils/common/state/state'
import { CollectionListType, CollectionType, collectionListSampleType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import { useCollectionInfo } from '../hook/CollectionListCustomHook'
import { Skeleton } from '@mui/material'

/*
    파일명 : CollectionListMain.tsx
    설명 : 싹싹도감 > 내 도감 > 리스트
    작성일 : 2024-06-17
*/
export default function CollectionListMainPending() {
  return (
    <Wrap>
      <React.Fragment key={generateRandomKey()}>
        <div>
          <>
            <ImgDiv>
              <Skeleton
                variant="rectangular"
                sx={{
                  borderRadius: '14px',
                  width: '100%',
                  height: '100%',
                }}
              />
            </ImgDiv>
          </>

          <CollectionImgInfo item={null} />
        </div>
        <div>
          <>
            <ImgDiv>
              <Skeleton
                variant="rectangular"
                sx={{
                  borderRadius: '14px',
                  width: '100%',
                  height: '100%',
                }}
              />
            </ImgDiv>
            <CollectionImgInfo item={null} />
          </>
        </div>
      </React.Fragment>
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 48%);
  gap: 23px 12px; /* 위아래는 12px, 양옆은 7px */
`

const ImgDiv = styled.div`
  box-sizing: border-box;

  width: 100%;
  aspect-ratio: 108.18 / 100;
  border: 3px solid #ffffff;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  margin-bottom: 9px;
  border-radius: 14px;
`

const Img = styled.img`
  box-sizing: border-box;

  width: 100%;
  aspect-ratio: 108.18 / 100;
  border: 3px solid #ffffff;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  margin-bottom: 9px;
  border-radius: 14px;
`

const ImgAdd = styled.div`
  /* Rectangle 140 */
  box-sizing: border-box;

  width: 100%;
  aspect-ratio: 108.18 / 100;

  background: #f2f2f2;
  border-radius: 14px;
  text-align: center;
  align-content: center;
  &:hover {
    cursor: pointer;
  }
`
