import { Box } from '@mui/material'
import { CustomButton } from 'src/components/common/button/CustomButton'
import DiaryButtonContainer from 'src/components/common/container/DiaryButtonContainer'
import FormMain from 'src/components/common/form/FormMain'
import PlusIcon from 'src/components/common/icon/PlusIcon'
import DetailViewLabelLayout from 'src/components/common/layout/DetailViewLabelLayout'
import { CONTENT_PADDING, CONTENT_PADDING_BOTTOM, CONTENT_PADDING_TOP } from 'src/utils/common/constants/constants'
import styled from 'styled-components'
import DiaryPlanList from './DiaryPlanList'

export default function NoDiary({ onClickBtn }: { onClickBtn: () => void }) {
  return (
    <>
      <Box sx={{ p: CONTENT_PADDING, pt: 0, pb: 0 }}>
        <DetailViewLabelLayout marginTop="23px" h2MarginBottom="18px" title="오늘의 기록">
          <DiaryPlanList careArr={null} />
          <DiaryButtonContainer content="아직 작성된 일지가 없어요!">
            <Content>
              <CustomButton
                comp={
                  <>
                    <PlusIcon margin="0px 10px 0px 0px" size={20} />
                  </>
                }
                margin="0px 0px 0px 0px"
                styleID="div_add_plant"
                type="button"
                onClick={() => {
                  onClickBtn()
                }}
              >
                일지 작성
              </CustomButton>
            </Content>
          </DiaryButtonContainer>
        </DetailViewLabelLayout>
      </Box>
    </>
  )
}

const Content = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
`
