import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import styles from '../../styles/LabelTag.module.css'

export interface LabelTagProps {
  children: string
  id: string
  styleID?: string
}

export default function LabelTag({ children, id, styleID }: LabelTagProps) {
  return (
    <label htmlFor={id} className={`${divideStyleIDString(styles, styleID)}`}>
      {children}
    </label>
  )
}
