import MainFindPwdForm from 'src/components/specific/user/find/MainFindPwdForm'
import ContainerLayout from '../../../components/common/layout/ContainerLayout'

import MuiMain from '../../../components/common/mui/MuiMain'
import IDInquiry from '../../../components/specific/user/find/IDInquiry'

import Title from 'src/components/common/title/Title'
import { useState } from 'react'
import PWDInquery from 'src/components/specific/user/find/PWDInquery'
import { UseUserInfo } from 'src/components/specific/main/hooks/MainHomeCustomHook'
import { useNavigate } from 'react-router-dom'

export default function MainFindIdAndPwd() {
  const navigate = useNavigate()
  return (
    <ContainerLayout styleId="div_container_03">
      <MuiMain>
        <Title
          onClick={() => {
            navigate('/', { state: { direction: 'back' } })
          }}
          title="계정찾기"
        />
        <MuiMain.MenuTab
          tabObjArr={[
            { queryId: 'id', title: '아이디 찾기', component: <IDInquiry /> },
            { queryId: 'pwd', title: '비밀번호 찾기', component: <PWDInquery /> },
          ]}
        />
      </MuiMain>
    </ContainerLayout>
  )
}
