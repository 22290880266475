import { useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { queryClient } from 'src/App'
import { getDataTanstackWithToken } from 'src/utils/common/api/http'
import { castFollowerListType, castFollowingListType } from 'src/utils/common/scripts/checkType'
import { isArrayOfTypeMyPlantType } from 'src/utils/common/scripts/common'
import { FollowType, myPlantType } from 'src/utils/common/type/type'

const size = 10
export const useGetFollowerList = () => {
  const { userId } = useParams()
  const [page, setPage] = useState(0)
  const [list, setList] = useState<FollowType[] | undefined>(undefined)
  const [isChanged, setIsChanged] = useState(false)

  const {
    data: responseData,
    refetch,
    isSuccess,
    isPending: loadingList,
  } = useQuery({
    queryKey: [
      `follower-list-${userId}`,
      {
        searchParam:
          userId && userId !== 'myprofile' && typeof Number(userId) === 'number'
            ? `/${userId}/follower?page=${page}&size=${size}`
            : `/follower?page=${page}&size=${size}`,
        url: `${process.env.REACT_APP_API_DOMAIN}auth-service/follow`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  useEffect(() => {
    refetch()
  }, [page])
  const initFollower = () => {
    if (!isChanged) return
    // setList(undefined)
    if (page === 0) {
      refetch()
    } else {
      setPage(0)
    }
  }

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries({ queryKey: [`follower-list-${userId}`] })
      queryClient.cancelQueries({ queryKey: [`follower-list-${userId}`] })
    }
  }, [])

  const changePage = useCallback(() => {
    console.log('페이지 바꿈 요청')
    const num = page + 1

    if (loadingList) return

    const count = num * size // 1 * 1
    console.log('count : ' + count)
    console.log('length : ' + (list ? list.length : 0))

    if (list && list.length === count) {
      console.log('setPage')
      refetch()
      setPage(num)
    }
  }, [])

  const castData = useCallback((data: any) => {
    if (data && data.data) {
      console.log('데이터 설정')
      const newObj = castFollowerListType(data)

      if (list === undefined || page === 0) {
        setList(newObj?.data.followerMemberList)
      } else {
        setList(prev => {
          if (page === 0) {
            return newObj?.data.followerMemberList
          }

          if (prev && newObj) {
            return prev?.concat(newObj?.data.followerMemberList)
          } else {
            return newObj?.data.followerMemberList
          }
        })
      }
      if (isChanged === true) setIsChanged(false)
    }
  }, [])

  useEffect(() => {
    if (responseData) {
      castData(responseData)
    }
  }, [responseData])

  return {
    changePage,
    loadingList,
    list,
    isSuccess,
    initFollower,
    chageFollowerList: setList,
    followerIsChanged: setIsChanged,
  }
}

export const useGetFollowingList = () => {
  const { userId } = useParams()
  const [page, setPage] = useState(0)
  const [isChanged, setIsChanged] = useState(false)

  const [list, setList] = useState<FollowType[] | undefined>(undefined)

  const {
    data,
    refetch,
    isSuccess,
    isPending: loadingList,
  } = useQuery({
    queryKey: [
      `following-list-${userId}`,
      {
        searchParam:
          userId && userId !== 'myprofile' && typeof Number(userId) === 'number'
            ? `/${userId}/follow?page=${page}&size=${size}`
            : `/following?page=${page}&size=${size}`,
        url: `${process.env.REACT_APP_API_DOMAIN}auth-service/follow`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })
  const initFollowing = () => {
    if (!isChanged) return

    if (page === 0) {
      refetch()
    } else {
      setPage(0)
    }
  }

  useEffect(() => {
    refetch()
  }, [page])

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries({ queryKey: [`following-list-${userId}`] })
      queryClient.cancelQueries({ queryKey: [`following-list-${userId}`] })
    }
  }, [])

  const changePage = useCallback(() => {
    console.log('페이지 바꿈 요청')
    const num = page + 1

    if (loadingList) return

    const count = num * size // 1 * 1
    console.log('count : ' + count)
    console.log('length : ' + (list ? list.length : 0))

    if (list && list.length === count) {
      console.log('setPage')
      refetch()
      setPage(num)
    }
  }, [])

  useEffect(() => {
    if (data && data.data) {
      console.log('데이터 설정')
      const newObj = castFollowingListType(data)
      if (newObj?.data.followingMemberList === list) return

      if (list === undefined || page === 0) {
        setList(newObj?.data.followingMemberList)
      } else {
        setList(prev => {
          if (page === 0) {
            return newObj?.data.followingMemberList
          }

          if (prev && newObj) {
            return prev?.concat(newObj?.data.followingMemberList)
          } else {
            return newObj?.data.followingMemberList
          }
        })
      }
      if (isChanged === true) setIsChanged(false)
    }
  }, [data])

  return {
    chageFollowingList: setList,
    changePage,
    loadingList,
    list,
    isSuccess,
    initFollowing,
    followingIsChanged: setIsChanged,
  }
}
