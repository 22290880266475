import styled from 'styled-components'

interface ImgProps {
  imgObj: {
    src: string
    alt: string
  }
  width?: string
  height?: string
}

export default function ImgTag({ imgObj, width = '', height = '' }: ImgProps) {
  return (
    <ImgCustomLayout>
      <ImgCustom src={imgObj.src} alt={imgObj.alt} width={width} height={height} />
    </ImgCustomLayout>
  )
}

interface ImgCustomProp {
  width: string
  height: string
}
const ImgCustom = styled.img<ImgCustomProp>`
  width: ${props => props.width};
  height: ${props => props.height};
  box-sizing: border-box;
  vertical-align: middle;
  align-content: center;
  align-items: center;
  text-align: center;
`

const ImgCustomLayout = styled.div`
  height: 37.8vh;
  width: 100%;
  box-sizing: border-box;
  vertical-align: middle;
  align-content: center;
  align-items: center;
  text-align: center;
`
