import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import {
  CollectionDetailItemType,
  CollectionDetailType,
  CollectionListType,
  PostType,
  RcntVwdItem,
  TopSearchedItemType,
  TopSearchedResType,
} from 'src/utils/common/type/type'
import {
  castCollectionDetailType,
  castCollectionListType,
  castPostListType,
  castRcntVwdType,
  castTopSearchedType,
  isObject,
} from 'src/utils/common/scripts/checkType'
import {
  deleteDataTanstackWithToken,
  getDataTanstackWithToken,
  patchDataTanstackWithToken,
  postDataJsonWithTokenExceptBody,
} from 'src/utils/common/api/http'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useCheckTokenValidity } from 'src/utils/common/hooks/customHook'
import { queryClient } from 'src/App'
import { getHistory } from 'src/utils/common/scripts/common'

import { tokenApi } from 'src/utils/common/api/useAxios'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/utils/store'
import { fetchPlantRecommendedData, plantRecommendedActions } from 'src/utils/store/plant/plantRecommended'

export function useCollectionInfo() {
  const [collectionList, setCollectionList] = useState<CollectionListType | undefined | null>(undefined)
  const { data, refetch, isSuccess, isPending, error, isError } = useQuery({
    queryKey: [
      'my-collection',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}encyclo-service/my-encyclopedia`,
      },
    ],

    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  useEffect(() => {
    refetch()
  }, [])

  if (isError) {
    console.error('Error during query:', error) // 에러 로그를 출력하거나, 다른 처리를 수행합니다.
    // setErrorState(error); 등의 로직을 추가할 수 있습니다.
  }

  useEffect(() => {
    if (isObject(data) && isObject(data?.data)) {
      if (data !== undefined) {
        setCollectionList(castCollectionListType(data))
      }

      console.log(data)
    }
  }, [data])
  return {
    collectionList,
    isPending,
    isSuccess,
    refetch,
  }
}

export function useGetRcntVwdList() {
  const [collectionList, setCollectionList] = useState<RcntVwdItem[] | null>(null)
  const [quesryString, setQueryString] = useState('')
  const [item, setItem] = useState<string[]>([])

  useEffect(() => {
    changeQueryString()
  }, [])

  const changeQueryString = async () => {
    // await queryClient.cancelQueries({ queryKey: ['rcnt-list'] })
    // queryClient.invalidateQueries({ queryKey: ['rcnt-list'] })

    const list = getHistory('recently_clicked')
    setItem(list?.result || [])
    let query = ''

    if (list)
      for (let i = 0; i < list?.result.length; i++) {
        const id = list.result[i]
        if (i === 0) {
          query += `ids=${id}`
        } else {
          query += `&ids=${id}`
        }
      }
    setQueryString(query)
  }

  const onChangeIndex = (id: number | string) => {
    setCollectionList(null)
  }

  const { data, refetch, isLoading, isSuccess, isPending, error, isError } = useQuery({
    queryKey: [
      'rcnt-list',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}encyclo-service/plant/brief?${quesryString}`,
      },
    ],

    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  useEffect(() => {
    if (quesryString.length > 0) {
      refetch()
    }
  }, [quesryString])

  if (isError) {
    console.error('Error during query:', error) // 에러 로그를 출력하거나, 다른 처리를 수행합니다.
    // setErrorState(error); 등의 로직을 추가할 수 있습니다.
  }

  useEffect(() => {
    if (isObject(data) && isObject(data?.data)) {
      const castObj = castRcntVwdType(data)?.data.plantBriefList
      if (castObj) setCollectionList(castObj)
    }
  }, [data])
  return {
    collectionList,
    isPending,
    isLoading,
    isSuccess,
    changeQueryString,
    onChangeIndex,
    setCollectionList,
  }
}

// 유저들이 둘러본 목록
export function useGetPlantList() {
  const dispatch = useDispatch<AppDispatch>()
  const collectionList = useSelector((state: RootState) => state.plantRecommended.list)
  const isPending = useSelector((state: RootState) => state.plantRecommended.isPending)
  const isError = useSelector((state: RootState) => state.plantRecommended.isError)

  useEffect(() => {
    if (collectionList?.length === 0 || collectionList === null || !collectionList) {
      dispatch(fetchPlantRecommendedData())
    }
  }, [])

  const setCollectionList = (selectId: number | string) => {
    dispatch(plantRecommendedActions.decrement(selectId))
  }

  return {
    collectionList,
    setCollectionList,
    isPending,
    isError,
  }
}

// 도감 아이템 리스트 조회
export function useCollectionItemInfo() {
  const { collectionId } = useParams()
  const [collectionInfo, setCollectionInfo] = useState<CollectionDetailType | null>(null)
  const [itemList, setItemList] = useState<CollectionDetailItemType[] | null | undefined>(null)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(12)

  const { data, refetch, isSuccess, isPending, error, isError } = useQuery({
    queryKey: [
      'my-collection',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}encyclo-service/my-encyclopedia/${collectionId}?page=${page}&size=${size}`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  useEffect(() => {
    setPage(0)
  }, [])

  useEffect(() => {
    refetch()
  }, [page])

  const requestItems = () => {
    const num = page + 1
    if (isPending) return
    if (itemList && itemList.length !== num * size) return
    setPage(num)
  }

  useEffect(() => {
    if (isObject(data) && isObject(data?.data)) {
      const newObj = castCollectionDetailType(data)
      setCollectionInfo(newObj)
      if (itemList === null || itemList === undefined) {
        setItemList(newObj?.data.plantCollection)
      } else {
        setItemList(prev => {
          if (page === 0) {
            return newObj?.data.plantCollection
          }

          if (prev && newObj) {
            return prev?.concat(newObj?.data.plantCollection)
          } else {
            return newObj?.data.plantCollection
          }
        })
      }

      console.log(data)
    }
  }, [data])
  return {
    itemList,
    setItemList,
    isPending,
    collectionInfo,
    isSuccess,
    refetch,
    setPage,
    requestItems,
  }
}
export default function useDeleteCollection() {
  const { collectionId } = useParams()

  const navigate = useNavigate()

  const { checkData } = useCheckTokenValidity()

  const { mutate, data, isPending } = useMutation({
    mutationFn: deleteDataTanstackWithToken,
    onSuccess: response => {
      queryClient.invalidateQueries({ queryKey: ['my-collection'] })
      toast.success('도감 삭제에 성공하였습니다.')
      navigate('../')
    },
    onError: (error: Error) => {
      toast.success('도감 삭제에 실패하였습니다.')
      checkData(error)
    },
  })

  const deleteCollection = () => {
    const url = `${process.env.REACT_APP_API_DOMAIN}encyclo-service/my-encyclopedia/${collectionId}`
    mutate({ url })
  }

  return {
    deleteCollection,
    isPending,
  }
}

export const useAddItemInToCollection = () => {
  const { checkData } = useCheckTokenValidity()

  const {
    mutate,
    data: response,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: postDataJsonWithTokenExceptBody,
    onSuccess: data => {
      toast.success('도감에 아이템을 저장하였습니다.')
      // queryClient.ini
      queryClient.invalidateQueries({ queryKey: ['my-collection'] })
    },
    onError: (error: Error) => {
      checkData(error)
    },
  })

  const createForm = (collectionId: string | number, itemId: (string | number)[]) => {
    let url = `${process.env.REACT_APP_API_DOMAIN}encyclo-service/my-encyclopedia/${collectionId}/plant?`

    for (let i = 0; i < itemId.length; i++) {
      const id = itemId[i]
      if (i === 0) {
        url += `plantSpeciesIds=${id}`
      } else {
        url += `&plantSpeciesIds=${id}`
      }
    }

    mutate({ url })
  }

  return {
    isPending,
    createForm,
    isSuccess,
    isError,
  }
}

export const useDeleteItemInToCollection = () => {
  const { checkData } = useCheckTokenValidity()

  const previousEvent = queryClient.getQueryData(['my-collection'])
  const [coldata, setColData] = useState(previousEvent)

  const navigate = useNavigate()

  const { mutate, data, isPending, isError, isSuccess } = useMutation({
    mutationFn: deleteDataTanstackWithToken,
    onSuccess: async response => {
      await queryClient.cancelQueries({ queryKey: ['my-collection'] })

      toast.success('도감에 아이템을 삭제하였습니다.')
    },
    onError: (error, context) => {
      toast.error('도감에 아이템 삭제에 실패하였습니다.')
      queryClient.setQueryData(['my-collection'], coldata)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['my-collection'] })
    },
  })

  const deleteForm = (collectionId: string | number, itemId: (string | number)[]) => {
    let url = `${process.env.REACT_APP_API_DOMAIN}encyclo-service/my-encyclopedia/${collectionId}/plant?`

    for (let i = 0; i < itemId.length; i++) {
      const id = itemId[i]
      if (i === 0) {
        url += `plantSpeciesIds=${id}`
      } else {
        url += `&plantSpeciesIds=${id}`
      }
    }

    mutate({ url })
  }

  return {
    isError,
    isSuccess,
    deleteForm,
    isPending,
  }
}

// 다른 도감으로 옮기기
export const useMoveItem = () => {
  const { checkData } = useCheckTokenValidity()

  const previousEvent = queryClient.getQueryData(['my-collection'])
  const [coldata, setColData] = useState(previousEvent)
  const { collectionId } = useParams()

  const navigate = useNavigate()

  const { mutate, data, isPending, isError, isSuccess } = useMutation({
    mutationFn: patchDataTanstackWithToken,
    onSuccess: async response => {
      await queryClient.cancelQueries({ queryKey: ['my-collection'] })
      toast.success('도감에 아이템을 이동하였습니다.')
    },
    onError: (error, context) => {
      queryClient.setQueryData(['my-collection'], coldata)
      toast.error('도감에 아이템 이동에 실패하였습니다.')
      checkData(error)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['my-collection'] })
    },
  })

  const moveItem = (collectionToId: string | number, itemId: (string | number)[]) => {
    let url = `${process.env.REACT_APP_API_DOMAIN}encyclo-service/my-encyclopedia/${collectionId}?toEncyclopediaId=${collectionToId}&`

    for (let i = 0; i < itemId.length; i++) {
      const id = itemId[i]
      if (i === 0) {
        url += `plantSpeciesIds=${id}`
      } else {
        url += `&plantSpeciesIds=${id}`
      }
    }

    mutate({ url })
  }

  return {
    moveError: isError,
    moveSuccess: isSuccess,
    moveItem,
    moving: isPending,
  }
}

export function useTopSearchedInfo() {
  const [response, setResponse] = useState<TopSearchedResType | null>(null)
  const [list, setList] = useState<TopSearchedItemType[] | null | undefined>(null)
  const { data, refetch, isSuccess, isPending, error, isError } = useQuery({
    queryKey: [
      'top-searched',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}encyclo-service/stat/popular-plant`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (isObject(data) && isObject(data?.data)) {
      const newObj = castTopSearchedType(data)
      setResponse(newObj)
      setList(newObj?.data.plantRecord)
      console.log(data)
    }
  }, [data])
  return {
    list,
    isPending,
    response,
    isSuccess,
  }
}

export function useGetRealtedPosts() {
  const [list, setList] = useState<PostType[] | null | undefined>(null)
  const [type, setType] = useState<string>('common')
  const [keyword, setKeyword] = useState<string>('')
  const navigate = useNavigate()

  const { data, refetch, isSuccess, isPending, error, isError } = useQuery({
    queryKey: [
      'board-list',
      {
        searchParam: '',
        url: `${process.env.REACT_APP_API_DOMAIN}board-service/common/search?page=0&size=3&sort=createdAt%2CDESC&keyword=${keyword}&type=${type}`,
      },
    ],
    queryFn: getDataTanstackWithToken,
    enabled: false,
  })

  useEffect(() => {
    if (type && keyword) {
      if (type === 'hashtag') {
        fetcHash()
      } else {
        refetch()
      }
    }
  }, [keyword, type])

  useEffect(() => {
    if (data && data?.commonArticleResponseList) {
      const newObj = castPostListType(data)

      setList(newObj?.commonArticleResponseList)
      console.log(data)
    }
  }, [data])

  const fetcHash = async () => {
    try {
      const res = await tokenApi.get(
        `/board-service/common/hashtags?name=${keyword}&page=0&size=1&type=${type}&sort=createdAt%2CDESC`,
      )
      if (res.data.commonArticleResponseList && !res.data.commonArticleResponseList.length) {
        setList([])
      } else {
        castData(res.data)
      }
    } catch (newError: any) {
      if (newError.response.data.status) {
        const sttsCode = newError.response.data.status
        if (sttsCode === 401) {
          toast.error('다시 로그인 해주세요')
          navigate('/')
        }
      }
    }
  }

  const castData = (newData: any) => {
    if (newData && newData?.commonArticleResponseList) {
      const newObj = castPostListType(newData)

      setList(newObj?.commonArticleResponseList)
    }
  }
  return {
    list,
    isPending,
    setType,
    setKeyword,
    isSuccess,
    type,
    keyword,
  }
}
