/*
    작성자 : 김효은
    작성일 : 2024-05-04
    설명 : 공통함수 

*/

import fs from 'fs'
import path from 'path'

import { SearchArrType, myPlantType, tempTokenType } from '../type/type'
import moment from 'moment'
import { castTypeSearchArr } from './checkType'
import { toast } from 'react-toastify'
import { FormFileType } from 'src/components/common/form/sub/input/ImageFileField'

export function getDomain() {
  const REDIRECT_URI = window.location.href.includes('local')
    ? process.env.REACT_APP_REDIRECT_URL
    : process.env.REACT_APP_MAIN_REDIRECT_URL

  return REDIRECT_URI
}

export function getDdate(date: string) {
  // 주어진 날짜
  const plantBirthday = moment(date)

  // 오늘 날짜
  const today = moment()

  // 날짜 차이 계산
  const daysDiff = today.diff(plantBirthday, 'days')

  return daysDiff
}

export function checkTokenValidity(error: Error) {
  if (error.message === '토큰이 만료 되었습니다.') {
    toast.error('다시 로그인해주세요')
    window.location.replace('/')
  }
}

// 주어진 값이 배열인지 확인하는 함수
export function isArrayOfTypeMyPlantType(arr: any): arr is myPlantType[] {
  if (!Array.isArray(arr)) return false // 배열이 아닌 경우 false 반환

  // 배열의 모든 요소가 myPlantType인지 확인
  return arr.every(item => {
    return typeof item === 'object' && item !== null && !Array.isArray(item)
  })
}

function getFileInfoFromUrl(url: string): { fileName: string; fileType: string } {
  // 파일 이름 추출
  const fileName = url.substring(url.lastIndexOf('/') + 1)

  // 파일 확장자를 통해 파일 유형 추론
  const fileType = getFileTypeFromExtension(fileName)

  return { fileName, fileType }
}

function getFileTypeFromExtension(fileName: string): string {
  const extension = fileName.split('.').pop()?.toLowerCase() || '' // 파일 확장자 추출 후 소문자로 변환

  // 파일 확장자에 따라 파일 유형 결정
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg'
    case 'png':
      return 'image/png'
    case 'gif':
      return 'image/gif'
    // 기타 파일 유형들도 필요에 따라 추가할 수 있습니다.
    default:
      return 'application/octet-stream' // 기본적으로 이진 파일로 처리
  }
}

export async function getFileFromUrl(url: string): Promise<FormFileType | null> {
  const { fileName, fileType } = getFileInfoFromUrl(url)
  try {
    const response = await fetch(url)
    const blob = await response.blob()
    const file = new File([blob], fileName, { type: fileType })
    return file
  } catch (error) {
    console.error('Error fetching file from URL:', error)
    return null
  }
}

export const generateRandomKey = () => {
  const array = new Uint32Array(1)
  window.crypto.getRandomValues(array)
  return array[0].toString(36)
}

function parseDateString(dateString: string): Date {
  const isoString = dateString.replace(' ', 'T') // 공백을 T로 대체하여 ISO 8601 형식으로 변환
  const date = new Date(isoString)
  return Number.isNaN(date.getTime()) ? new Date() : date
}

export function mergeDateAndTime(dateObj: { startDt: Date; endDt: Date; startTm: string; endTm: string }) {
  const startFormat = formatDateToString(dateObj.startDt)
  const endFormat = formatDateToString(dateObj.endDt)

  const parsedStartDateTime = `${startFormat}${dateObj.startTm}`
  const parsedEndDateTime = `${endFormat}${dateObj.endTm}`
  return {
    startDateTime: parsedStartDateTime,
    endDateTime: parsedEndDateTime,
  }
}

export function parseDateToString(date: Date, type: string): string {
  // 현재 날짜와 시간

  // 연, 월, 일, 시, 분을 추출
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // 월은 0부터 시작하므로 +1 필요
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  // 분을 5분 단위로 반올림
  const rawMinutes = date.getMinutes()
  const roundedMinutes = Math.round(rawMinutes / 5) * 5

  // 분이 60을 넘을 경우 처리를 위해 조정
  const adjustedHours = roundedMinutes === 60 ? hours + 1 : hours
  const adjustedMinutes = roundedMinutes === 60 ? '00' : String(roundedMinutes).padStart(2, '0')

  if (type === 'dttm') {
    return `${year}-${month}-${day}T${adjustedHours}:${adjustedMinutes}`
  } else {
    return `T${adjustedHours}:${adjustedMinutes}`
  }
}

export function displayedAt(createdAt: string) {
  const parsedDate = parseDateString(createdAt)
  const milliSeconds: number = new Date().getTime() - parsedDate.getTime()
  const seconds = milliSeconds / 1000
  if (seconds < 60) return `방금 전`
  const minutes = seconds / 60
  if (minutes < 60) return `${Math.floor(minutes)}분 전`
  const hours = minutes / 60
  if (hours < 24) return `${Math.floor(hours)}시간 전`
  const days = hours / 24
  if (days < 7) return `${Math.floor(days)}일 전`
  const weeks = days / 7
  if (weeks < 5) return `${Math.floor(weeks)}주 전`
  const months = days / 30
  if (months < 12) return `${Math.floor(months)}개월 전`
  const years = days / 365
  return `${Math.floor(years)}년 전`
}

// #,###
export function formatNumber(number: string | number): string {
  // 숫자로 변환
  const num = typeof number === 'string' ? parseFloat(number) : number

  // 숫자를 천 단위로 포맷팅
  return num.toLocaleString('en-US')
}

export function formatDttm(utcDateString: string): string {
  const date = new Date(utcDateString)
  const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`

  return formattedDate
}

function padZero(num: number) {
  return num.toString().padStart(2, '0')
}

export function getCareName(careId: string) {
  let careNm = ''
  let width = '17px'
  let height = '17px'

  switch (careId.toLowerCase()) {
    case 'watering':
      careNm = '물주기'
      width = '17px'
      height = '22px'
      break
    case 'pruning':
      careNm = '가지치기'
      width = '19px'
      height = '21px'
      break
    case 'fertilizing':
      careNm = '영양주기'
      width = '20px'
      height = '17px'
      break
    case 'repotting':
      careNm = '분갈이하기'
      width = '20px'
      height = '17px'
      break
    case 'sunlight':
      careNm = '햇빛쬐기'
      width = '20px'
      height = '20px'
      break
    default:
      break
  }
  return {
    careNm,
    width,
    height,
  }
}

export function getPostSttsImgSize(sttsNm: string) {
  let width = '17px'
  let height = '21px'
  switch (sttsNm) {
    case 'SOLD':
      width = '57px'

      break
    case 'HOLD':
    case 'SALE':
      width = '47px'

      break
    case 'FREE':
      width = '80px'
      height = '30px'
      break

    default:
      break
  }
  return {
    width,
    height,
  }
}

export function getCareGuideSampleName(careId: string) {
  let careNm = ''
  let width = '17px'
  let height = '17px'
  switch (careId) {
    case 'watering':
      careNm = '급수 주기'
      width = '20px'
      height = '20px'
      break
    case 'degree':
      careNm = '온도'
      width = '11px'
      height = '21px'
      break
    case 'soilMng':
      careNm = '토양 관리'
      width = '18px'
      height = '16px'
      break
    case 'sunlight':
      careNm = '일조량'
      width = '21px'
      height = '21px'
      break
    case 'pruning':
      careNm = '습도'
      width = '16px'
      height = '18.5px'
      break
    case 'date':
      careNm = '일정'
      width = '18px'
      height = '20px'
      break
    default:
      break
  }
  return {
    careNm,
    width,
    height,
  }
}

const maxSize = parseInt(`${process.env.REACT_APP_FILE_MAX_SIZE}`, 10)

/* 첨부파일 검증 */
export function validateFile(obj: File) {
  const fileTypes = ['image/jpeg', 'image/png', 'image/jpg']
  if (obj.name.length > 100) {
    return {
      validate: false,
      message: '파일명이 100자 미만 이어야 합니다.',
    }
  } else if (obj.size > 10 * 1024 * 1024) {
    return {
      validate: false,
      message: '최대 파일 용량인 10MB 미만 이어야 합니다.',
    }
  } else if (obj.name.lastIndexOf('.') === -1) {
    return {
      validate: false,
      message: '확장자가 필요합니다.',
    }
  } else if (!fileTypes.includes(obj.type)) {
    return {
      validate: false,
      message: '첨부가 불가능한 파일입니다.',
    }
  } else {
    return {
      validate: true,
      message: '성공',
    }
  }
}

export const getCookie = (name: string) => {
  const search = name + '='
  if (document.cookie.length > 0) {
    let offset = document.cookie.indexOf(search)

    if (offset !== -1) {
      offset += search.length
      let end = document.cookie.indexOf(';', offset)
      if (end === -1) {
        end = document.cookie.length
      }
      return unescape(document.cookie.substring(offset, end))
    }
  }
  return ''
}

export const setCookie = (name: string, value: string, expiredays: number) => {
  if (getCookie(name) === value) return

  const todayDate = new Date()

  todayDate.setDate(todayDate.getDate() + expiredays)
  document.cookie = name + '=' + escape(value) + '; path=/; expires=' + todayDate.toUTCString() + ';'
}

export function calculateDday(futureDate: Date | string) {
  // 입력된 미래 날짜와 현재 날짜를 Date 객체로 변환합니다.
  const future = new Date(futureDate)
  const today = new Date()

  // 날짜 차이를 계산합니다 (단위: 밀리초)
  const difference = future.getTime() - today.getTime()

  // 계산된 차이를 일(day) 단위로 변환합니다.
  const daysDifference = Math.ceil(difference / (1000 * 3600 * 24))
  const format = Math.abs(daysDifference)

  let result = `- ${format}`
  if (daysDifference < 0) {
    result = `+ ${format}`
  }

  return result
}

export function formatDateToString(date: Date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // 월은 0부터 시작하므로 +1 해줍니다.
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export function findTrueValues(obj: { [key: string]: boolean }) {
  // 객체의 각 키-값 쌍을 배열로 변환합니다.
  const entries = Object.entries(obj)

  // 값이 true인 키(key)들을 필터링합니다.
  const trueKeys = entries.filter(([key, value]) => value === true).map(([key, value]) => key)

  // true인 속성들을 담은 배열을 반환합니다.
  return trueKeys
}

export function isArrayOfTypeTempTokenType(obj: any): obj is tempTokenType {
  if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
    return false // 객체가 아니거나 null이거나 배열인 경우 false 반환
  }

  // obj가 tempTokenType 형식인지 확인
  const { message, data } = obj
  if (typeof message !== 'string' || typeof data !== 'object' || data === null || Array.isArray(data)) {
    return false // message가 문자열이 아니거나 data가 객체가 아니거나 null이거나 배열인 경우 false 반환
  }

  const { tokenType, token } = data
  if (typeof tokenType !== 'string' || typeof token !== 'string') {
    return false // tokenType과 token이 문자열이 아닌 경우 false 반환
  }

  return true // 모든 조건이 충족되면 true 반환
}

/*
    전체화면 요청
*/
export const requestFullScreen = () => {
  const element = document.documentElement.parentElement || document.documentElement
  if (element.requestFullscreen) {
    element.requestFullscreen()
  } else if ('webkitRequestFullscreen' in element) {
    ;(element as Element & { webkitRequestFullscreen(): Promise<void> }).webkitRequestFullscreen()
  } else if ('mozRequestFullScreen' in element) {
    ;(element as Element & { mozRequestFullScreen(): Promise<void> }).mozRequestFullScreen()
  } else if ('msRequestFullscreen' in element) {
    ;(element as Element & { msRequestFullscreen(): Promise<void> }).msRequestFullscreen()
  }
}

export const test = () => {
  return true
}

export const onSaveHistory = (newSearchParam: string | number, name: string) => {
  let search = localStorage.getItem(name)
  if (!search) {
    localStorage.setItem(name, `#${newSearchParam}#`)
  } else {
    const searchArr = search.split('#')
    if (10 > searchArr.length) {
      search = search.replace(`#${newSearchParam}#`, '')
      localStorage.setItem(name, `#${newSearchParam}#${search}`)
    } else {
      if (search.includes(`#${newSearchParam}#`)) {
        search = search.replace(`#${newSearchParam}#`, '')
        localStorage.setItem(name, `#${newSearchParam}#${search}`)
      }
    }
  }
}

export const getHistory = (name: string): SearchArrType | null => {
  const search = localStorage.getItem(name)

  let searchList = []
  let newObj
  if (search) {
    searchList = search.split('#').filter(Boolean)
    newObj = castTypeSearchArr({ result: searchList })
  }
  return newObj || null
}

export const deleteAll = (name: string) => {
  localStorage.setItem(name, '')
}

export const deleteHistory = (arr: string[], deleteItem: string, name: string) => {
  const search = localStorage.getItem(name)
  if (search) {
    localStorage.setItem(name, search.replace(`#${deleteItem}#`, ''))
  }
  const newArr: string[] = arr.filter((item: string) => item !== deleteItem)

  return newArr
}

/**
 * Checks if a file exists at the given URL or path.
 * @param {string} url - The URL or file path to check.
 * @returns {Promise<boolean>} - Returns a promise that resolves to a boolean indicating file existence.
 */
export async function fileExists(url: string): Promise<boolean> {
  try {
    const response = await fetch(url, { method: 'HEAD' })

    return response.ok
  } catch (error) {
    console.error('Error checking file existence:', error)
    return false
  }
}
