import styled from 'styled-components'

import { SwiperSlide } from 'swiper/react'

import SwiperListAutoWidth from '../swiper/SwiperListAutoWidth'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import LoadingContainer from '../container/LoadingContainer'

type HashTagListLayout = {
  hashTagArr: string[] | null
  onClickItem: (item: string) => void
}

type HashTagItemType = {
  item: string | null
  onClickItem: (item: string) => void
}

export default function HashTagList({ hashTagArr, onClickItem }: HashTagListLayout) {
  if (!hashTagArr) {
    return (
      <SwiperListAutoWidth>
        <SwiperSlide key={generateRandomKey()} style={{ width: 'auto' }}>
          <HashTag onClickItem={() => {}} item={null} />
        </SwiperSlide>
        <SwiperSlide key={generateRandomKey()} style={{ width: 'auto' }}>
          <HashTag onClickItem={() => {}} item={null} />
        </SwiperSlide>
      </SwiperListAutoWidth>
    )
  } else {
    return (
      <SwiperListAutoWidth>
        {hashTagArr.map((item: string) => {
          return (
            <SwiperSlide key={item} style={{ width: 'auto' }}>
              <HashTag onClickItem={onClickItem} item={item} />
            </SwiperSlide>
          )
        })}
      </SwiperListAutoWidth>
    )
  }
}

export function HashTag({ item, onClickItem }: HashTagItemType) {
  if (!item) {
    return (
      <HashTagWrapLoad>
        <LoadingContainer styleId="hash" />
      </HashTagWrapLoad>
    )
  } else {
    return (
      <HashTagWrap
        onClick={() => {
          onClickItem(item)
        }}
      >
        <HashTagDiv># {item}</HashTagDiv>
      </HashTagWrap>
    )
  }
}

const HashTagWrapLoad = styled.div`
  display: flex;
  margin-right: 14px;
`

const HashTagWrap = styled.div`
  display: flex;
  margin-right: 14px;
  &:hover {
    cursor: pointer;
  }
`

const HashTagDiv = styled.div`
  justify-content: center;
  align-items: center;

  align-content: center;
  text-align: center;

  width: fit-content;
  padding-right: 11px;
  padding-left: 11px;
  height: 26px;

  background: #f9fbff;
  border-radius: 7px;

  /* Caption */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  letter-spacing: -0.02em;

  /* Black */
  color: #242424;
`
