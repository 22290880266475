import { Box } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import MotionLeftToRightLayout from 'src/components/common/layout/MotionLeftToRightLayout'
import Title from 'src/components/common/title/Title'
import { CONTENT_PADDING, CONTENT_PADDING_BOTTOM, CONTENT_PADDING_TOP } from 'src/utils/common/constants/constants'
import styled from 'styled-components'
import FormMain from '../../FormMain'

import CustomMap from './CustomMap'

import { useEffect, useState } from 'react'
import { useGetAddress } from 'src/components/specific/main/hooks/MainHomeCustomHook'

type LocationSelectType = {
  registerFn?: React.InputHTMLAttributes<HTMLInputElement>
  setValue: ReturnType<typeof useForm>['setValue']
  name: string
  onClose: () => void
  isVisible: boolean
  register: ReturnType<typeof useForm>['register']
  getValues: ReturnType<typeof useForm>['getValues']
  onClick: () => void
  watch: ReturnType<typeof useForm>['watch']
  defaultVal: {
    lng: number
    lat: number
  }
}

export default function LocationSelect({
  register,
  defaultVal,
  watch,
  registerFn,
  getValues,
  setValue,
  name,
  onClose,
  onClick,
  isVisible,
}: LocationSelectType) {
  const [defaultP, setDefaultP] = useState<
    | {
        lat: number
        lng: number
      }
    | undefined
  >()

  const { setCurloc, address } = useGetAddress()
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    setDefaultP({
      lat: defaultVal?.lat,
      lng: defaultVal?.lng,
    })
    setCurloc({
      lat: defaultVal?.lat,
      lon: defaultVal?.lng,
    })
  }, [defaultVal])

  useEffect(() => {
    setValue('location', address)
  }, [address])

  useEffect(() => {
    const subscirbe = watch((data, { name: changedNm }) => {
      if (changedNm === 'location') {
        if (data.location && data.location.length > 0) {
          setIsValid(true)
        } else if (!data.location) {
          setIsValid(false)
        }
      }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])

  return (
    <>
      <AnimatePresence>
        {isVisible && (
          <>
            <MotionLeftToRightLayout>
              <Wrap>
                <Title
                  onClick={() => {
                    onClose()
                  }}
                  close={true}
                  title="내 위치 설정"
                />
                <Box sx={{ pt: CONTENT_PADDING_TOP, pb: CONTENT_PADDING_BOTTOM }}>
                  <Box sx={{ p: CONTENT_PADDING, pt: 0, pb: 0 }}>
                    <Content>
                      <h2>위치를 선택해주세요.</h2>
                    </Content>
                  </Box>
                  {defaultP && (
                    <CustomMap
                      onChangePosition={(lat: number, lng: number) => {
                        setValue('position', {
                          lat,
                          lng,
                        })
                        setCurloc({
                          lat,
                          lon: lng,
                        })
                      }}
                      center={{
                        lat: getValues('position.lat'),
                        lng: getValues('position.lng'),
                      }}
                    />
                  )}
                  <Box sx={{ p: CONTENT_PADDING, pt: 0, pb: 0 }}>
                    <FormMain.LabelLayout
                      label="장소명"
                      warning={isValid === false ? true : false}
                      warnText={isValid === false ? '장소명을 입력해주세요' : ''}
                    >
                      <FormMain.InputFull
                        styleId={
                          isValid === false ? 'div_plant_02 wdt_full mb_10 border_red' : 'div_plant_02 wdt_full mb_10'
                        }
                        inputComp={
                          <FormMain.Input
                            registerFn={register('location', {
                              required: false,
                            })}
                            type="text"
                            placeholder="예) 강남역 1번 출구, 베스킨라빈스 앞"
                          />
                        }
                      />
                    </FormMain.LabelLayout>
                    <FormMain.Button
                      styleID={isValid ? 'btn_submit_01_active' : 'btn_submit_01'}
                      width="100%"
                      marginTop="20px"
                      type="button"
                      onClick={() => {
                        if (isValid) {
                          onClick()
                        } else {
                          setIsValid(false)
                        }
                      }}
                    >
                      선택 완료
                    </FormMain.Button>
                  </Box>
                </Box>
              </Wrap>
            </MotionLeftToRightLayout>
          </>
        )}
      </AnimatePresence>
    </>
  )
}

const Wrap = styled(motion.div)`
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: white;
`
const Content = styled.div`
  h2 {
    /* 거래 희망 장소를 선택해주세요. */
    margin-bottom: 23px;
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    /* identical to box height, or 115% */
    letter-spacing: -0.05em;

    color: #1c1c1c;
  }
`
