import { Box } from '@mui/material'
import ChatItemInfo from 'src/components/common/content/ChatItemInfo'
import MarkedItem from 'src/components/common/content/MarkedItem'
import CollectionGridLayout from 'src/components/common/layout/CollectionGridLayout'
import RelatedPostLayout from 'src/components/common/layout/RelatedPostLayout'
import LineOne from 'src/components/common/line/LineOne'
import { CONTENT_PADDING } from 'src/utils/common/constants/constants'

export default function GreenChatItem() {
  return (
    <>
      <LineOne />
      <Box sx={{ p: CONTENT_PADDING, pt: 0, pb: 0 }}>
        <RelatedPostLayout height="62px">
          <MarkedItem onClick={() => {}} item={null} />
          <>
            <ChatItemInfo
              item={{
                title: '다육이 팔아요',
                loc: '용산역 1번출구',
                price: 5000,
              }}
            />
          </>
        </RelatedPostLayout>
      </Box>

      <LineOne />
    </>
  )
}
