/*
    파일명 : CollectionRelatedPlazaPosts.tsx
    설명 : 싹싹도감 메인 > 관련 게시글 > 광장 게시글 
    작성일 : 2024-06-16
*/
import { COLLECTION_RELATED_PLAZA_POSTS, COLLECTION_RELATED_POSTS } from 'src/utils/common/constants/constants'
import styled from 'styled-components'
import MarkedItem from 'src/components/common/content/MarkedItem'
import MarkedItemInfo from 'src/components/common/content/MarkedItemInfo'
import React from 'react'
import LineOne from 'src/components/common/line/LineOne'
import RelatedPostLayout from 'src/components/common/layout/RelatedPostLayout'
import ItemInfo from 'src/components/common/content/ItemInfo'
import { PostType } from 'src/utils/common/type/type'
import { formatDttm, generateRandomKey } from 'src/utils/common/scripts/common'
import NoDataContainer2 from 'src/components/common/container/NoDataContainer2'

export default function CollectionRelatedPlazaPosts({ list }: { list: null | PostType[] | undefined }) {
  if (list === null || (list && list.length === 0)) {
    return <NoPosts />
  }
  if (list === undefined) {
    return <PendingPosts />
  }

  return (
    <>
      {list.map((item, index) => (
        <React.Fragment key={generateRandomKey()}>
          {item.imageUrls && item.imageUrls.length > 0 ? (
            <>
              {index !== 0 && <LineOne />}
              <RelatedPostLayout outterHeight={item.hashtagList ? '137px' : '102px'}>
                <MarkedItem
                  onClick={() => {}}
                  item={{
                    id: item.articleId,
                    img_url: item.imageUrls[0],
                    name: item.title,
                  }}
                />
                <MarkedItemInfo
                  itemType="PLAZA"
                  item={{
                    title: item.title || '',
                    loc: item.member.nickname,
                    regDttm: formatDttm(item.createdAt) || '',
                    content: item.content || '',
                    hashtagList: item.hashtagList ? item.hashtagList : null,
                  }}
                />
              </RelatedPostLayout>
              {index === list.length - 1 && <LineOne />}
            </>
          ) : (
            <>
              {index !== 0 && <LineOne />}
              <ItemInfo
                item={{
                  hashtagList: item.hashtagList ? item.hashtagList : null,
                  title: item.title || '',
                  loc: item.member.nickname,
                  regDttm: formatDttm(item.createdAt) || '',
                  content: item.content || '',
                }}
              />
              {index === list.length - 1 && <LineOne />}
            </>
          )}
        </React.Fragment>
      ))}
    </>
  )
}

export function PendingPosts() {
  return (
    <>
      <ItemInfo item={null} />
      <LineOne />
      <ItemInfo item={null} />
      <LineOne />
      <ItemInfo item={null} />
    </>
  )
}

export function NoPosts() {
  return (
    <NoColl>
      <NoDataContainer2 mt="50px" title="관련 게시글이 없어요" discription="관련 게시글을 작성해보세요" />
    </NoColl>
  )
}

const NoColl = styled.div`
  /* Rectangle 113 */

  width: 100%;
  height: 100px;
  // aspect-ratio: 1.1068 / 1;

  display: flex;
  place-content: center;

  align-items: center;
`
