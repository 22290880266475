import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PlantItemLayout from 'src/components/common/layout/PlantItemLayout'
import PlantListLayout from 'src/components/common/layout/PlantListLayout'
import { ItemObjType, plantTypeType } from 'src/utils/common/type/type'
import MyPlantSample from '../../../../assets/images/plant/MyPlantSample.png'
import { generateRandomKey } from 'src/utils/common/scripts/common'

type PlantItemListProps = {
  itemArr: plantTypeType[]
  height?: string
  onClickPlant: (name: string | number) => void
}

export default function CollectionSearchPlantList({ height = '448px', itemArr, onClickPlant }: PlantItemListProps) {
  const [selectedItem, setSelectedItem] = useState<string | number>('')
  const navigate = useNavigate()

  const onClickNxtBtn = () => {
    if (selectedItem) {
      navigate(selectedItem.toString())
    }
  }

  return (
    <>
      <PlantListLayout height={height}>
        {itemArr.map((item: ItemObjType, index: number) => (
          <PlantItemLayout
            eng={item.eng}
            onClick={() => {
              onClickPlant(item.plantSpeciesId)
            }}
            name={item.commonName}
            key={generateRandomKey()}
            className={
              selectedItem === item.plantSpeciesId
                ? index === 0
                  ? 'selected first'
                  : 'selected'
                : index === 0
                  ? 'first'
                  : ''
            }
          >
            <img
              src={item.imageUrl ? item.imageUrl : MyPlantSample}
              alt="이미지태그"
              style={{
                width: '60px',
                height: '60px',
              }}
            />
          </PlantItemLayout>
        ))}
      </PlantListLayout>
    </>
  )
}
