import { Skeleton } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import { useProfilePageStatus } from 'src/utils/store/profile/useProfilePageStatus'
import styled from 'styled-components'

type FollowerContentType = {
  mb?: string
  name: string
  contentArr:
    | {
        title: string
        value: string | number
      }[]
    | null
}

export default function FollowerContent({ name, mb, contentArr }: FollowerContentType) {
  if (!contentArr) {
    return <Skeleton variant="rounded" height={52} sx={{ marginBottom: mb }} />
  } else {
    return (
      <Wrap $mb={mb}>
        {contentArr.map((item, index) => {
          return (
            <>
              <FollowerElement name={name} title={item.title} value={item.value} key={generateRandomKey()} />
            </>
          )
        })}
      </Wrap>
    )
  }
}

export function FollowerElement({ name, title, value }: { name: string; title: string; value: string | number }) {
  const { setPageStatus, pageStatus, pageValue, setPageValue } = useProfilePageStatus()
  const location = useLocation()
  const navigate = useNavigate()
  const path = location.pathname

  const onClickTitle = () => {
    if (title === '팔로워') {
      setPageStatus('follower')
    } else if (title === '팔로잉') {
      setPageStatus('following')
    }
  }
  return (
    <>
      <Title onClick={onClickTitle}>
        {title}
        <Value>{value}</Value>
      </Title>
    </>
  )
}

const Title = styled.p`
  /* 키우는 식물 */

  /* Body 02 */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height, or 153% */
  letter-spacing: -0.02em;

  /* Gray 05 */
  color: #646669;
`

const Value = styled.span`
  margin-left: 8px;
  margin-right: 16px;

  text-align: center;

  /* Body 02-M */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height, or 153% */
  text-align: right;
  letter-spacing: -0.02em;

  /* Black */
  color: #242424;
`

const Wrap = styled.div<{ $mb?: string }>`
  align-items: center;
  width: 267px;
  height: 23px;
  display: flex;

  margin-bottom: ${({ $mb }) => $mb || `0px`};
`
