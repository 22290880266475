import ProfileContent from 'src/components/common/content/ProfileContent'
import ProfileHeader, {
  ProfileContainer,
  ProfileDivComp,
  ProfileImg,
  ProfileIntroComp,
  ProfileWrap,
} from 'src/components/common/content/ProfileHeader'
import samplePrfImg from '../../../assets/images/post/sampleProfile.png'
import DualButtonLayout from 'src/components/common/button/DualButtonLayout'
import { CustomButton } from 'src/components/common/button/CustomButton'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import FollowerContent from 'src/components/common/content/FollowerContent'
import { Skeleton } from '@mui/material'

export default function ProfileInfoLoading() {
  const path = useLocation()
  const [pathName, setPathName] = useState(path.pathname)

  useEffect(() => {
    setPathName(path.pathname)
  }, [path])

  return (
    <>
      <ProfileContainer imgSize="74px">
        <ProfileWrap>
          <ProfileImg imgSize="74px" profileSrc={null} />
          <ProfileIntroComp userNm={null} regFormat="1" />
        </ProfileWrap>
      </ProfileContainer>
      <ProfileContent content={null} />
      <FollowerContent name="왜이래" contentArr={null} mb="19px" />

      {pathName.includes('/myprofile') ? (
        <>
          <Skeleton width="100%" height={34} />
        </>
      ) : (
        <DualButtonLayout styleID="div_dual_btn">
          <Skeleton width="44.4vw" height={34} />
          <Skeleton width="44.4vw" sx={{ ml: '2.6%' }} height={34} />
        </DualButtonLayout>
      )}
    </>
  )
}
