/* eslint-disable react/destructuring-assignment */

import * as React from 'react'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import ImgTag from 'src/components/common/imgTag/ImgTag'
import plantIcon from 'src/assets/images/plant/plantIcon.png'

export default function Circle(props: CircularProgressProps & { value?: number }) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        '& .MuiCircularProgress-root ': {
          width: '80px !important',
          height: '80px !important',
          color: '#3DD2A3 !important',
        },
      }}
    >
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 10,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ImgTag imgObj={{ src: plantIcon, alt: '식물아이콘' }} width="90%" height="90%" />
      </Box>
    </Box>
  )
}

// 이거 그냥 예시임 안쓰는거!!
function CircularWithValueLabel() {
  const [progress, setProgress] = React.useState(10)

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress => (prevProgress >= 100 ? 0 : prevProgress + 10))
    }, 800)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return <Circle />
}
