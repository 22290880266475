import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import Title from '../title/Title'
import TitleInDrawer from '../title/TitleInDrawer'
import LoadingSpinner from './LoadingSpinner'
import PopOutLayout from '../layout/PopOutLayout'

type DrawerType = {
  title?: string | null
  subTitle?: string | null
  children?: React.ReactNode | null
  mb?: string
  height?: number
  pt?: number
  b?: number
  position?: string
}

export default function Drawer({
  b = 7,
  pt = 40,
  height = 591,
  mb = '30px',
  children,
  position = 'absolute',
  title,
  subTitle = '',
}: DrawerType) {
  return (
    <>
      <Wrap $position={position} $b={b} $height={height} $pt={pt}>
        <TitleInDrawer mb={mb} title={title} subTitle={subTitle} />
        {children ? children : <LoadingSpinner />}
      </Wrap>
    </>
  )
}

type WrapProps = {
  $pt: number
  $height: number // $height라는 props를 정의합니다.
  $b: number
  $position: string
}

const Wrap = styled(motion.div)<WrapProps>`
  position: ${props => props.$position};
  width: 100%;
  max-width: 390px;
  height: ${props => props.$height}px; // props.$height를 통해 height 값을 동적으로 설정합니다.
  z-index: 1;
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  bottom: ${props => props.$b}px;

  padding-top: ${props => props.$pt}px;

  padding-left: 17px;
  padding-right: 17px;
`
