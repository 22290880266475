import { ChangeEvent, useRef, useState } from 'react'
import { validateFile } from 'src/utils/common/scripts/common'
import styled from 'styled-components'
import sampleimg from '../../../../../assets/images/plant/MyPlantSample.png'
import addProf from '../../../../../assets/images/profile/addProfile.png'
import { useForm } from 'react-hook-form'

export default function ProfileFile({
  onAddFile,
  sampleUrl,
  disabled = false,
  getValues,
}: {
  sampleUrl: string | null
  disabled?: boolean
  onAddFile?: (file: File) => void
  getValues: ReturnType<typeof useForm>['getValues']
}) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [toggle, setToggle] = useState(false)
  const [showImages, setShowImages] = useState<string>(sampleUrl || sampleimg)

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    // FileList를 배열로 변환
    const fileList = Array.from(event.target.files)

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i]
      // 첨부파일 검증
      if (!validateFile(file)) {
        handleResetFiles()
      } else {
        // const reader = new FileReader()
        // reader.onload = () => {
        //   // 파일 배열에 담기

        //   const currentImageUrl = URL.createObjectURL(file)

        //   setShowImages(currentImageUrl)
        // }
        onAddFile?.(file)
      }
    }
    setToggle(prev => !prev)
  }

  const handleResetFiles = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }
  return (
    <>
      <input
        type="file"
        disabled={disabled}
        hidden
        ref={inputRef}
        accept="image/jpeg, image/png, image/jpg"
        onChange={handleUpload}
      />
      <Wrapper>
        <ImgWrap>
          <Img
            src={getValues('image') ? URL.createObjectURL(getValues('image')) : sampleUrl ? sampleUrl : sampleimg}
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.click()
              }
            }}
            alt="샘플 프로필이미지"
          />

          <Add
            src={addProf}
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.click()
              }
            }}
            alt="프로필 추가"
          />
        </ImgWrap>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 63px;
`

const ImgWrap = styled.div`
  width: fit-content;
  position: relative;
`
const Img = styled.img`
  /* Ellipse 88 */

  box-sizing: border-box;

  width: 96px;
  height: 96px;

  border: 1px solid #dadada;
  border-radius: 50%;
  hover {
    cursor: pointer;
  }
`

const Add = styled.img`
  position: absolute;
  width: 29px;
  height: 29px;
  bottom: 4px;
  right: -3px;
`
