import styled from 'styled-components'
import good from '../../../assets/images/post/good.png'
import goodClicked from '../../../assets/images/post/goodClicked.png'
import comment from '../../../assets/images/post/comment.png'
import React, { useEffect, useState } from 'react'
import { useLikeComment, useLikePost } from 'src/components/specific/plaza/hooks/PlazaCreateCustomHook'

export function GoodButton({
  defaultVal,
  goodCnt,
  afterLikePost,
  postId,
}: {
  postId: string | number
  defaultVal: boolean
  afterLikePost: () => void
  goodCnt: number
}) {
  const [clicked, setClicked] = useState(defaultVal)
  const { isPending, createForm, isSuccess, isError, deleting, deletingSuccess, deletingFailed } = useLikePost()

  useEffect(() => {
    if (isSuccess) {
      setClicked(prev => !prev)
      afterLikePost()
    }
  }, [isSuccess])
  useEffect(() => {
    if (isError) {
      setClicked(prev => prev)
    }
    // afterLikePost()
  }, [isError])
  useEffect(() => {
    if (deletingSuccess) {
      setClicked(prev => !prev)
      afterLikePost()
    }
  }, [deletingSuccess])
  useEffect(() => {
    if (deletingFailed) {
      setClicked(prev => prev)
    }

    // afterLikePost()
  }, [deletingFailed])

  return (
    <>
      <ClickedDiv
        onClick={() => {
          if (isPending) return
          createForm(postId, !clicked)
        }}
      >
        <GoodImg src={clicked ? goodClicked : good} alt={clicked ? '좋아요 취소하기' : '좋아요하기'} />
        <span>{goodCnt}</span>
      </ClickedDiv>
    </>
  )
}

export function GoodCommentButton({
  defaultVal,
  goodCnt,
  afterLikePost,
  postId,
}: {
  postId: string | number
  defaultVal: boolean
  afterLikePost: () => void
  goodCnt: number
}) {
  const [clicked, setClicked] = useState(defaultVal)
  const { isPending, createForm, isSuccess, isError, deleting, deletingSuccess, deletingFailed } = useLikeComment()

  useEffect(() => {
    if (isSuccess) {
      setClicked(prev => !prev)
      afterLikePost()
    }
  }, [isSuccess])
  useEffect(() => {
    if (isError) {
      setClicked(prev => prev)
    }
    // afterLikePost()
  }, [isError])
  useEffect(() => {
    if (deletingSuccess) {
      setClicked(prev => !prev)
      afterLikePost()
    }
  }, [deletingSuccess])
  useEffect(() => {
    if (deletingFailed) {
      setClicked(prev => prev)
    }

    // afterLikePost()
  }, [deletingFailed])

  return (
    <>
      <ClickedDiv
        onClick={() => {
          if (isPending) return
          createForm(postId, !clicked)
        }}
      >
        <GoodImg src={clicked ? goodClicked : good} alt={clicked ? '좋아요 취소하기' : '좋아요하기'} />
        <span>{goodCnt}</span>
      </ClickedDiv>
    </>
  )
}

export function GoodComment({
  commentsCnt,
  ml = '20px',
  onClick,
}: {
  onClick: () => void
  commentsCnt: number
  ml?: string
}) {
  return (
    <ClickedDiv onClick={onClick}>
      <CommentImg $ml={ml} src={comment} alt="댓글" />
      <span>{commentsCnt}</span>
    </ClickedDiv>
  )
}

export function GoodNCommentLayout({ children }: { children: React.ReactNode }) {
  return <LeftDiv>{children}</LeftDiv>
}

const LeftDiv = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;

  span {
    margin-left: 6px;

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;

    color: #1c1c1c;
  }
`

const ClickedDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    cursor: pointer;
  }
`

const GoodImg = styled.img`
  width: 18px;
  height: 15px;
`

const CommentImg = styled.img<{ $ml: string }>`
  margin-left: ${({ $ml }) => $ml};
  width: 18px;
  height: 15px;
`
