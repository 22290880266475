import { PostFooterType, PostType } from 'src/utils/common/type/type'
import styled from 'styled-components'
import sampleImg from '../../../assets/images/plant/selectedChar1.png'
import { GoodButton, GoodComment, GoodCommentButton, GoodNCommentLayout } from '../button/GoodButton'
import { Skeleton, Snackbar, SnackbarOrigin } from '@mui/material'
import CompModal from '../modal/CompModal'
import React, { useState, useEffect } from 'react'
import { generateRandomKey, getDomain } from 'src/utils/common/scripts/common'
import { TbCopy } from 'react-icons/tb'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import good from '../../../assets/images/post/good.png'
import goodClicked from '../../../assets/images/post/goodClicked.png'
import comment from '../../../assets/images/post/comment.png'
import share from '../../../assets/images/post/share.png'
import sampleProfile from '../../../assets/images/post/sampleProfile.png'

type PostCommentType = {
  postComment: PostFooterType | undefined
  onClick: () => void
  afterLikePost: () => void
  post: PostType | undefined
}

type PostCommentDetailType = {
  postComment: PostFooterType
  showReply?: boolean

  afterLikePost: () => void
  onClickShowReply: (commentId: number | string, readOnly: boolean) => void
}

type PostReplyDetailType = {
  postComment: PostFooterType

  afterLikePost: () => void
}

export default function PostComment({ post, postComment, onClick, afterLikePost }: PostCommentType) {
  if (!postComment) {
    return (
      <Wrap>
        <GoodNCommentLayout>
          <Skeleton variant="text" width={100} />
        </GoodNCommentLayout>
        <RightDiv>
          <ClickedDiv>
            <></>
          </ClickedDiv>
        </RightDiv>
      </Wrap>
    )
  }
  return (
    <Wrap>
      <GoodNCommentLayout>
        <GoodButton
          postId={postComment.postId}
          goodCnt={postComment.goodCnt}
          defaultVal={postComment.clicked}
          afterLikePost={afterLikePost}
        />
        <GoodComment commentsCnt={postComment.commentsCnt} onClick={onClick} />
      </GoodNCommentLayout>
      <RightDiv>
        <ShareComp post={post} />
      </RightDiv>
    </Wrap>
  )
}
interface State extends SnackbarOrigin {
  open: boolean
}

export function ShareComp({ post }: { post: PostType | undefined }) {
  const [open, setOpen] = useState(false)

  const [state, setState] = useState(false)
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (!open && copied) {
      setCopied(false)
      setState(true)
    }
  }, [open])
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(`${getDomain()}/plaza/common/articleId${post?.articleId}`)
      .then(() => {
        setOpen(false)
        setCopied(true)
        // console.log('URL copied to clipboard')
      })
      .catch(err => {
        console.error('Failed to copy URL: ', err)
      })
  }
  const shareBtn = (
    <>
      <img
        onClick={shareKakao}
        src="https://developers.kakao.com/assets/img/about/logos/kakaolink/kakaolink_btn_medium.png"
        alt="카카오링크 보내기 버튼"
      />
    </>
  )

  function shareKakao() {
    if (post) {
      const domainurl = `${getDomain()}`

      const img = post.imageUrls && post.imageUrls.length > 0 ? post.imageUrls[0] : sampleImg
      window.Kakao.Share.sendCustom({
        templateId: 111047,
        templateArgs: {
          THU: img,
          title: post.title,
          description: post.content,
          REGI_WEB_DOMAIN: domainurl,
          like: post.likeCount || '0',
          comment: post.commentCount || '0',
          username: post.member.memberId,
          PROFILEIMG: post.member.thumbnailUrl || sampleProfile,
          params: `plaza/common/articleId${post?.articleId}`,
        },
      })
    }
  }

  const handleClose = () => {
    setState(false)
  }

  return (
    <>
      <CompModal
        open={open}
        handleClose={() => {
          setOpen(false)
        }}
        title="링크 공유"
        comp={
          <ShareWrap>
            <TbCopy onClick={copyToClipboard} size={80} style={{ marginRight: '10px' }} />
            {shareBtn}
          </ShareWrap>
        }
      />
      <Snackbar
        autoHideDuration={600}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={state}
        onClose={handleClose}
        message="클립보드에 복사되었습니다."
        key={generateRandomKey()}
        sx={{
          marginTop: '200px',
          backgroundColor: 'white',
          color: 'black',
        }}
      />
      <ClickedDiv
        onClick={() => {
          setOpen(true)
        }}
      >
        <ShareImg src={share} alt="공유" />
      </ClickedDiv>
    </>
  )
}

export function PostCommentDetail({
  showReply = true,
  onClickShowReply,
  postComment,
  afterLikePost,
}: PostCommentDetailType) {
  return (
    <Wrap>
      <GoodNCommentLayout>
        <GoodCommentButton
          postId={postComment.postId}
          goodCnt={postComment.goodCnt}
          defaultVal={postComment.clicked}
          afterLikePost={afterLikePost}
        />
        <GoodComment
          commentsCnt={postComment.commentsCnt}
          onClick={() => {
            onClickShowReply(postComment.postId, false)
          }}
        />
        {showReply && (
          <ShowReply
            onClick={() => {
              onClickShowReply(postComment.postId, true)
            }}
          />
        )}
      </GoodNCommentLayout>
    </Wrap>
  )
}

export function PostReplyDetail({ postComment, afterLikePost }: PostReplyDetailType) {
  return (
    <Wrap>
      <GoodNCommentLayout>
        <GoodCommentButton
          postId={postComment.postId}
          goodCnt={postComment.goodCnt}
          defaultVal={postComment.clicked}
          afterLikePost={afterLikePost}
        />
      </GoodNCommentLayout>
    </Wrap>
  )
}

export function ShowReply({ onClick }: { onClick: () => void }) {
  return <Reply onClick={onClick}>답글달기</Reply>
}

const ShareWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Reply = styled.div`
  margin-left: 20px;
  /* 답글달기 */

  /* 답글달기 */

  /* Caption */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  letter-spacing: -0.02em;

  /* Gray 04 */
  color: #a2a3a7;
`

const Wrap = styled.div`
  width: 100%;
  height: 18px;
  margin-top: 14px;

  display: flex;
  justify-content: space-between;
`

const RightDiv = styled.div`
  margin-right: 5px;
`

const ShareImg = styled.img`
  width: 17px;
  height: 15px;
`

const ClickedDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    cursor: pointer;
  }
`
