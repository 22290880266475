import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import FormMain from '../../../common/form/FormMain'
import { usePlantPageStatus } from '../../../../utils/store/plant/usePlantPageStatus'
import { useEffect, useState } from 'react'
import { useCreateNewPlantType } from '../hooks/CreateNewPlantTypeCustomHook'
import { useNavigate } from 'react-router-dom'

export default function RegNewPlant({ onFinish }: { onFinish?: () => void }) {
  const { register, getValues, setValue, watch, handleSubmit } = useForm()
  const navigate = useNavigate()
  const { setPageStatus } = usePlantPageStatus()
  const { isValidObj, confirmed, formAlert, checkNmName, checkScName, createForm, requestFinish, isPending } =
    useCreateNewPlantType()

  const onClickNxtBtn = () => {
    if (!confirmed) {
      if (!checkScName(getValues('scName'))) return
      checkNmName(getValues('nmName'))
    } else {
      createForm({
        commonName: getValues('nmName'),
        scientificName: getValues('scName'),
      })
    }
  }

  useEffect(() => {
    if (requestFinish === true) {
      if (onFinish) {
        onFinish()
      } else {
        navigate('/plant/create/unset', { state: { direction: 'na' } })
        setPageStatus('selectChar')
      }
    }
  }, [requestFinish])

  useEffect(() => {
    const subscirbe = watch((data, { name }) => {
      if (name === 'scName') {
        checkScName(getValues('scName'))
      } else if (name === 'nmName') {
        checkNmName(getValues('nmName'))
      }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])

  return (
    <FormMain>
      <div style={{ height: '284px' }}>
        <FormMain.LabelLayout infoStyleId="text_red" infoText={formAlert.scName} label="학명">
          <FormMain.InputFull
            styleId={
              isValidObj.scName === false ? 'div_plant_04 wdt_full mb_10 border_red' : 'div_plant_04 wdt_full mb_10'
            }
            inputComp={
              <FormMain.Input
                styleID=""
                type="text"
                placeholder="학명을 입력해주세요"
                registerFn={register('scName', {
                  required: false,
                })}
              />
            }
          />
        </FormMain.LabelLayout>
        <FormMain.LabelLayout infoStyleId="text_red" infoText={formAlert.nmName} label="일반명">
          <FormMain.InputFull
            styleId={
              isValidObj.nmName === false ? 'div_plant_04 wdt_full mb_10 border_red' : 'div_plant_04 wdt_full mb_10'
            }
            inputComp={
              <FormMain.Input
                styleID=""
                type="text"
                placeholder="일반명을 입력해주세요"
                registerFn={register('nmName', {
                  required: false,
                })}
              />
            }
          />
        </FormMain.LabelLayout>
      </div>

      <FormMain.Button
        onClick={onClickNxtBtn}
        type="button"
        styleID={confirmed === true ? 'btn_submit_01_active' : 'btn_submit_01'}
        width="100%"
        marginTop="16px"
      >
        등록 요청하기
      </FormMain.Button>
    </FormMain>
  )
}
