import divideStyleIDString from 'src/utils/common/scripts/divideStyleIDString'
import styles from './styles/ContainerLayout.module.css'
import { useEffect, useRef } from 'react'

export default function ContainerLayout({
  children,
  styleId = 'div_container_01',
}: {
  children: JSX.Element | JSX.Element[]
  styleId?: string
}) {
  return <div className={`${divideStyleIDString(styles, styleId)}`}>{children}</div>
}
