import { planDttmValidateObj, todoType } from 'src/utils/common/type/type'
import { usePlanFormCheck } from './hooks/PlanCreateCustomHooks'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import FormMain from 'src/components/common/form/FormMain'
import ColorChips from 'src/components/common/form/sub/select/ColorChips'
import { COLOR_SELECT } from 'src/utils/common/constants/constants'
import { ResponsiveTimePickers } from 'src/components/common/form/sub/time/TimeInfo'
import CustomCalendar from 'src/components/common/form/sub/calendar/CustomCalendar'
import { generateRandomKey, mergeDateAndTime, parseDateToString } from 'src/utils/common/scripts/common'
import PlantRepatDiscription from './sub/PlanRepeatDiscription'
import PlanDttmValidateCaption from './sub/PlanDttmValidateCaption'
import FullLoadingSpinner from 'src/components/common/container/FullLoadingSpinner'

type PlantCreateProps = { onPostFinish: () => void; item: null | todoType }

export default function PlanCreate({ onPostFinish, item }: PlantCreateProps) {
  const {
    checkAll,
    errorToUpdating,
    successToUpdating,
    isValidObj,
    confirmed,
    formAlert,
    checkTitle,
    checkDt,
    formatDate,
    createDiary,
    isError,
    isSuccess,
    formatSingle,
    isPending,
    updating,
  } = usePlanFormCheck()

  const { register, getValues, setValue, watch, handleSubmit } = useForm()
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    const subscirbe = watch((data, { name }) => {
      if (name === 'title') {
        checkTitle(getValues('title'))
      } else if (name === 'repeat') {
        if (data.repeat === false) {
          setValue('frequency', 0)
        }
        setToggle(prev => !prev)
      }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (isSuccess || isError || errorToUpdating || successToUpdating) {
      onPostFinish()
    }
  }, [isSuccess, isError, errorToUpdating, successToUpdating, onPostFinish])

  const onClickSubmitBtn = (data: { [key: string]: string }) => {
    if (!onChangeDttm() || !checkTitle(getValues('title'))) {
      return
    }

    let dttm

    if (!getValues('repeat')) {
      // 반복 없음
      dttm = mergeDateAndTime({
        startDt: getValues('singleDt'),
        endDt: getValues('singleDt'),
        endTm: getValues('singleTm'),
        startTm: getValues('singleTm'),
      })
    } else {
      // 반복 있음
      dttm = mergeDateAndTime({
        startDt: getValues('startDt'),
        endDt: getValues('endDt'),
        endTm: getValues('endTm'),
        startTm: getValues('startTm'),
      })
    }

    console.log(data.title)
    console.log(data.frequency)
    console.log(data.repeat)
    console.log(data.color)
    console.log(dttm)
    createDiary(
      item?.scheduleId || null,
      data.title,
      Number(data.frequency) || 0,
      data.color,
      dttm.startDateTime,
      dttm.endDateTime,
    )
  }

  useEffect(() => {
    if (item) {
      register('title')
      register('frequency')
      setValue('title', item.title)
      setValue('frequency', item.frequency || 0)
    } else {
      setValue('frequency', 0)
      setValue('startDt', new Date())
      setValue('endDt', new Date())
    }
  }, [])

  const getDefaultFrequency = () => {
    if (getValues('frequency') || getValues('frequency') === 0) {
      return getValues('frequency')
    }
    if (item && typeof item?.frequency === 'number') {
      return item.frequency
    } else {
      return 10
    }
  }
  const onChangeDttm = () => {
    if (!getValues('repeat')) return true
    if (getValues('endDt') && getValues('startDt') && getValues('endTm') && getValues('startTm')) {
      const valid = checkDt({
        endDt: getValues('endDt'),
        endTm: getValues('endTm'),
        startDt: getValues('startDt'),
        name: 'startDt',
        startTm: getValues('startTm'),
      })

      return valid
    }
    return false
  }

  return (
    <>
      <FormMain styleID="scroll" onSubmit={handleSubmit(onClickSubmitBtn)}>
        <FormMain.LabelLayout
          isFirst={true}
          infoStyleId="text_red"
          warning={isValidObj && isValidObj.title === false ? true : false}
          warnText={formAlert && formAlert.title ? formAlert.title : ''}
          label=""
        >
          <FormMain.InputFull
            styleId={
              isValidObj && isValidObj.title === false
                ? 'div_plan_title wdt_full mb_10 border_red'
                : 'div_plan_title wdt_full mb_10'
            }
            inputComp={<FormMain.Input type="text" placeholder="제목" registerFn={register('title', {})} />}
          />
        </FormMain.LabelLayout>
        <FormMain.LabelLayout infoStyleId="text_red" label="반복">
          <FormMain.Toggle
            register={register}
            disabled={item ? true : false}
            defaultVal={item ? (item.frequency === 0 ? false : true) : false}
            getValues={getValues}
            setValue={setValue}
            registerFn={register('repeat', {
              required: false,
            })}
            name="repeat"
          />
        </FormMain.LabelLayout>
        {getValues('repeat') ? (
          <>
            <FormMain.LabelLayout display="flex" label="반복 주기">
              <FormMain.InputNumber
                disabled={false}
                caption="일에 한번"
                inputComp={
                  <FormMain.Input
                    styleID="input_count"
                    type="text"
                    placeholder={getDefaultFrequency()}
                    registerFn={register('frequency', {
                      required: false,
                    })}
                  />
                }
                defaultVal={getDefaultFrequency()}
                name="frequency"
                setValue={setValue}
                getValues={getValues}
              />
            </FormMain.LabelLayout>
            <CustomCalendar
              key={generateRandomKey()}
              label="시작일"
              selectNext={true}
              selected={
                getValues('startDt') || (item && item.startDateTime ? new Date(item.startDateTime) : new Date())
              }
              disabled={false}
              setValue={setValue}
              valType="date"
              registerFn={register('startDt', {
                required: false,
              })}
              name="startDt"
              timeComp={
                <>
                  <ResponsiveTimePickers
                    name="startTm"
                    defaultVal={
                      getValues('startTm') ||
                      (item && item.startDateTime
                        ? parseDateToString(new Date(item.startDateTime), 'tm')
                        : parseDateToString(new Date(), 'tm'))
                    }
                    setValue={setValue}
                    getValues={getValues}
                    register={register}
                  />
                </>
              }
            />

            <CustomCalendar
              key={generateRandomKey()}
              label="종료일"
              selectNext={true}
              selected={getValues('endDt') || (item && item.endDateTime ? new Date(item.endDateTime) : new Date())}
              disabled={false}
              setValue={setValue}
              valType="date"
              registerFn={register('endDt', {
                required: false,
              })}
              name="endDt"
              timeComp={
                <>
                  <ResponsiveTimePickers
                    name="endTm"
                    defaultVal={
                      getValues('endTm') ||
                      (item && item.endDateTime
                        ? parseDateToString(new Date(item.endDateTime), 'tm')
                        : parseDateToString(new Date(), 'tm'))
                    }
                    setValue={setValue}
                    getValues={getValues}
                    register={register}
                  />
                </>
              }
            />
          </>
        ) : (
          <>
            <CustomCalendar
              key={generateRandomKey()}
              label="날짜"
              selectNext={true}
              selected={
                getValues('singleDt') || (item && item.startDateTime ? new Date(item.startDateTime) : new Date())
              }
              disabled={false}
              setValue={setValue}
              valType="date"
              registerFn={register('singleDt', {
                required: false,
              })}
              name="singleDt"
              timeComp={
                <>
                  <ResponsiveTimePickers
                    name="singleTm"
                    defaultVal={
                      getValues('singleTm') ||
                      (item && item.startDateTime
                        ? parseDateToString(new Date(item.startDateTime), 'tm')
                        : parseDateToString(new Date(), 'tm'))
                    }
                    setValue={setValue}
                    getValues={getValues}
                    register={register}
                  />
                </>
              }
            />
          </>
        )}
        <PlanDttmValidateCaption
          hidden={getValues('repeat') ? false : true}
          checkDt={checkDt}
          getValues={getValues}
          watch={watch}
        />
        {getValues('repeat') && (
          <>
            <PlantRepatDiscription
              defaultFrequency={getDefaultFrequency()}
              getValues={getValues}
              watch={watch}
              repeatEndDate={getValues('endDt')}
            />
          </>
        )}

        <FormMain.LabelLayout label="색상 선택">
          <>
            <>
              <ColorChips
                lineMaxQuantity={4}
                colorChipArr={COLOR_SELECT}
                defaultVal={item && item.colorType ? item.colorType : '#FFA4A4'}
                getValues={getValues}
                register={register}
                setValue={setValue}
              />
            </>
          </>
        </FormMain.LabelLayout>

        <SubmitBtn checkDt={checkDt} getValues={getValues} watch={watch} confirmedProp={confirmed} />
      </FormMain>
      {(isPending || updating) && <FullLoadingSpinner isShow={true} />}
    </>
  )
}

function SubmitBtn({
  confirmedProp,
  watch,
  getValues,
  checkDt,
}: {
  checkDt: (dateObj: planDttmValidateObj) => boolean
  getValues: ReturnType<typeof useForm>['getValues']
  watch: ReturnType<typeof useForm>['watch']
  confirmedProp: boolean
}) {
  const [confirmed, setConfirmed] = useState(confirmedProp)
  const [isValidDttm, setIsValidDttm] = useState<boolean>(true)

  useEffect(() => {
    setConfirmed(confirmedProp)
  }, [confirmedProp])

  const onChangeDttm = () => {
    if (!getValues('repeat')) {
      setIsValidDttm(true)
      return
    }
    if (getValues('endDt') && getValues('startDt') && getValues('endTm') && getValues('startTm')) {
      const valid = checkDt({
        endDt: getValues('endDt'),
        endTm: getValues('endTm'),
        startDt: getValues('startDt'),
        name: 'startDt',
        startTm: getValues('startTm'),
      })

      setIsValidDttm(valid)
    }
  }

  useEffect(() => {
    const subscription = watch(data => {
      if (data.endDt) {
        onChangeDttm()
      }
      if (data.startDt) {
        onChangeDttm()
      }
      if (data.endTm) {
        onChangeDttm()
      }
      if (data.startTm) {
        onChangeDttm()
      }
    })

    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <>
      <FormMain.Button
        styleID={confirmed === true && isValidDttm ? 'btn_submit_01_active mt_52_px' : 'btn_submit_01 mt_52_px'}
        width="100%"
        height=""
        type="submit"
      >
        작성 완료
      </FormMain.Button>
    </>
  )
}
