/*
    작성자 : 김효은
    작성일 : 2024-05-04 토
    설명 : recoil 상태값 
*/

import { RecoilState, atom } from 'recoil'
import { CollectionDetailItemType, fileType, FollowType, RcntVwdItem } from '../type/type'

// 아이디 찾기
export const findIdState = atom({
  key: 'findIdState',
  default: {
    name: '',
    tel: '',
    certNum: '',
  },
})

// 내식물 > 상세> 일지 날짜 선택
export const selectedDirayDate = atom({
  key: 'selectedDirayDateState',
  default: {
    date: new Date(),
  },
})

// 내식물 > 상세> 일지 날짜 선택
export const isWeekState = atom({
  key: 'isWeekState',
  default: {
    date: true,
  },
})

// 내식물 > 상세> 일지 날짜 선택

//  내식물 셀렉트 박스
export const selectMyPlantState = atom({
  key: 'selectMyPlantState',
  default: {
    name: '',
    plantType: '',
    id: '',
  },
})

//  셀렉트 박스
export const selectBoxContentState = atom({
  key: 'selectBoxContentState',
  default: {
    name: '',
  },
})

export const alertState = atom<{
  title: string
  discription: string
  showAlert: boolean
  params?: number | string | null
  type?: string
}>({
  key: 'alertState',
  default: {
    title: '',
    discription: '',
    showAlert: false,
    type: 'after',
  },
})

export const alertState2 = atom<{
  title: string
  discription: string
  showAlert: boolean
  params?: number | string | null
  type?: string
}>({
  key: 'alertState2',
  default: {
    title: '',
    discription: '',
    showAlert: false,
    type: 'after',
  },
})

export const formModalState = atom({
  key: 'formModalState',
  default: {
    title: '',
    discription: '',
    showAlert: false,
  },
})

export const timerState = atom({
  key: 'timerState',
  default: {
    start: false,
  },
})

export const validState = atom({
  key: 'validState',
  default: {
    isValid: false,
  },
})

export const selectCol = atom<{ params: string[] }>({
  key: 'selectCol',
  default: {
    params: [],
  },
})

export const userNmStts = atom<{ params: string }>({
  key: 'userNmStts',
  default: {
    params: '',
  },
})

export const colDirection = atom<'next' | 'prev'>({
  key: 'colDirection',
  default: 'next',
})

export const colStep = atom<number>({
  key: 'colStep',
  default: 1,
})

export const colStts = atom<string>({
  key: 'colStts',
  default: 'main',
})

export const selectPostType = atom<string>({
  key: 'selectPostType',
  default: 'common',
})

export const collectionListState = atom<RcntVwdItem[] | null>({
  key: 'collectionListState',
  default: null,
})

export const collectionItemList = atom<CollectionDetailItemType[] | null>({
  key: 'collectionItemList',
  default: null,
})

export const myPlantQnt = atom<number>({
  key: 'myPlantQntState',
  default: 0,
})

export const refethCollections = atom<boolean>({
  key: 'refethCollections',
  default: false,
})

// 내식물 > 상세> 주간, 월간
export const selectCalendarType = atom<boolean>({
  key: 'selectCalendarTypeState',
  default: true,
})

// 내식물 > 상세> 주간, 월간
export const LodingSpinnerType = atom<boolean>({
  key: 'LodingSpinnerTypeState',
  default: false,
})

export const transitionSttsState = atom<string>({
  key: 'transitionSttsState',
  default: '',
})

export const FollowerState = atom<FollowType[] | undefined>({
  key: 'FollowerState',
  default: undefined,
})

export const FollowingState = atom<FollowType[] | undefined>({
  key: 'FollowState',
  default: undefined,
})

export const initPlazaState = atom<boolean>({
  key: 'initPlazaState',
  default: false,
})
