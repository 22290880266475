import { useEffect } from 'react'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MotionLeftToRightLayout from 'src/components/common/layout/MotionLeftToRightLayout'
import MuiMain from 'src/components/common/mui/MuiMain'
import Title from 'src/components/common/title/Title'
import { profilePageUserInfo } from 'src/components/specific/main/hooks/MainHomeCustomHook'
import { useProfilePageStatus } from 'src/utils/store/profile/useProfilePageStatus'
import { AnimatePresence, motion } from 'framer-motion'

export default function Profile2() {
  const { pageStatus, pageValue, setPageStatus, setPageValue } = useProfilePageStatus(state => ({
    pageStatus: state.pageStatus,
    pageValue: state.pageValue,
    setPageStatus: state.setPageStatus,
    setPageValue: state.setPageValue,
  }))

  // 상태가 변경될 때마다 setPageValue 호출
  useEffect(() => {
    setPageValue()
  }, [pageStatus, setPageValue])

  useEffect(() => {
    return () => {
      setPageStatus('main')
    }
  }, [])

  return (
    <>
      <ContainerLayout>
        <MuiMain>
          {/* <Title onClick={onClickBackBtn} title="식물등록" /> */}

          <Content pt={0} p={0} component={<> {pageValue.content}</>} />
        </MuiMain>
      </ContainerLayout>
    </>
  )
}
