import styled from 'styled-components'
import DotButtonTwo from '../button/DotButtonTwo'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import LoadingContainer from '../container/LoadingContainer'
import React from 'react'
import { Skeleton } from '@mui/material'

export default function PageSubTitleTwo({
  title,
  onClick,
  marginTop = '0',
  marginBottom = '0',
  h2MarginBottom = '0',
  padding = '0 0 0 0',
  iconComp,
  h1ClassNm = '',
  subTitle,
}: {
  title: string | null
  onClick?: () => void
  h1ClassNm?: string
  marginTop?: string
  marginBottom?: string
  h2MarginBottom?: string
  padding?: string
  iconComp?: React.ReactNode
  subTitle?: string | null
}) {
  let comp = (
    <>
      <Title className={h1ClassNm} $h2marginbottom={h2MarginBottom}>
        {title}
      </Title>
    </>
  )

  if (subTitle) {
    comp = (
      <div>
        <Title className={h1ClassNm} $h2marginbottom="0px">
          {title}
        </Title>
        <SubTitle className={h1ClassNm} $h2marginbottom={h2MarginBottom}>
          {subTitle}
        </SubTitle>
      </div>
    )
  }

  return (
    <Label $padding={padding} $h2marginbottom={h2MarginBottom} $marginbottom={marginBottom} $margintop={marginTop}>
      <MenuContainer>
        {title ? (
          <>
            {comp}
            {iconComp && iconComp}
          </>
        ) : (
          <Skeleton variant="rounded" width={60} height={20} />
        )}
      </MenuContainer>
    </Label>
  )
}

const Label = styled.div<{ $padding: string; $margintop: string; $marginbottom: string; $h2marginbottom: string }>`
  margin-top: ${({ $margintop }) => $margintop};
  margin-bottom: ${({ $marginbottom }) => $marginbottom};
  padding: ${({ $padding }) => $padding};
`

const SubTitle = styled.h1<{ $h2marginbottom: string }>`
  margin-top: 2px;
  margin-bottom: ${({ $h2marginbottom }) => $h2marginbottom};

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;

  letter-spacing: -0.02em;

  /* Black */
  color: #242424;

  align-self: center;
  display: flex;

  &.small {
    margin-bottom: ${({ $h2marginbottom }) => $h2marginbottom};
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.05em;

    color: #1c1c1c;
  }
`

const Title = styled.h1<{ $h2marginbottom: string }>`
  margin-bottom: ${({ $h2marginbottom }) => $h2marginbottom};
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;

  letter-spacing: -0.02em;

  /* Black */
  color: #242424;
  align-self: center;
  display: flex;

  &.small {
    margin-bottom: ${({ $h2marginbottom }) => $h2marginbottom};
    /* 최근 검색어 */

    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    letter-spacing: -0.02em;

    color: #000000;
  }
`

const MenuContainer = styled.div`
  display: flex;
  place-content: space-between;
  span {
    /* 지우기 */

    /* Body 02-M */
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;

    text-align: right;
    letter-spacing: -0.02em;

    /* Gray 04 */
    color: #a2a3a7;

    letter-spacing: -0.05em;

    .&hover {
      cursor: pointer;
    }
  }
`
