import { useState } from 'react'

import { IoSettingsOutline } from 'react-icons/io5'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useGetProfilePlantList } from '../hook/ProfileCreateCustomHook'
import { profilePageUserInfo } from '../../main/hooks/MainHomeCustomHook'
import Title from 'src/components/common/title/Title'
import Content from 'src/components/common/layout/Content'
import ProfileInfo from '../ProfileInfo'
import MuiMain from 'src/components/common/mui/MuiMain'
import { ProfileCommentIcon, ProfileGoodIcon, ProfilePlantIcon } from 'src/components/common/icon/ProfileTabIcon'
import { ProfilePlantsMain } from './ProfilePlants'
import ProfilePosts from './ProfilePosts'
import ProfilePostsLiked from './ProfilePostsLiked'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import DrawerRightLayout from 'src/components/common/layout/DrawerRightLayout'
import ProfileForm from './ProfileForm'
import { MenuToggle } from 'src/components/common/title/TitleLight'
import { VscBell } from 'react-icons/vsc'

export default function ProfileContent() {
  const [menuIndex, setMenuIndex] = useState(0)
  const { userId } = useParams()
  const navigate = useNavigate()

  const { loadingList, list, isSuccess: successGetList } = useGetProfilePlantList()
  const handleMenuIndex = (index: number) => {
    setMenuIndex(index)
  }
  const { profile, isPending, isSuccess } = profilePageUserInfo()
  const [closeInner, setCloseInner] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)

  let name = profile ? profile.data.nickname : ''
  if (profile && profile.data.nickname?.includes('새회원')) {
    name = '새회원'
  }

  return (
    <>
      <Title
        showBack={userId === 'myprofile' ? false : true}
        onClick={() => {
          if (userId === 'myprofile') {
            navigate('/main', { state: { direction: 'back' } })
          } else {
            navigate(-1)
          }
        }}
        title={profile ? (userId === 'myprofile' ? '나의 프로필' : `${name}님의 텃밭`) : null}
        comp={
          <>
            {userId === 'myprofile' ? (
              <>
                <div style={{ justifySelf: 'end' }}>
                  <IoSettingsOutline
                    style={{ marginRight: '20px' }}
                    onClick={() => {
                      navigate('setting')
                    }}
                    size={23}
                  />
                  <VscBell onClick={() => {}} size={23} style={{ marginRight: '17px' }} />
                </div>
              </>
            ) : null}
          </>
        }
      />
      <Content
        pb={0}
        component={
          <>
            <ProfileInfo
              isPending={isPending}
              onClickEdit={() => {
                setDrawerOpen(true)
              }}
              name={name}
              profile={profile}
            />
          </>
        }
      />
      <MuiMain.MenuTab
        onClick={handleMenuIndex}
        tabObjArr={[
          {
            queryId: 'plant',
            title: (
              <>
                <ProfilePlantIcon active={menuIndex === 0} />
              </>
            ),
            component: (
              <>
                <ProfilePlantsMain
                  list={list}
                  successGetList={successGetList}
                  loadingList={loadingList}
                  isSuccess={isSuccess}
                />
              </>
            ),
          },
          {
            queryId: 'mypost',
            title: (
              <>
                <ProfileCommentIcon active={menuIndex === 1} />
              </>
            ),
            component: (
              <>
                <ProfilePosts key={generateRandomKey()} type="comment" />
              </>
            ),
          },
          {
            queryId: 'good',
            title: (
              <>
                <ProfileGoodIcon active={menuIndex === 2} />
              </>
            ),
            component: (
              <>
                <ProfilePostsLiked key={generateRandomKey()} type="good" />
              </>
            ),
          },
        ]}
      />

      <DrawerRightLayout
        title="나의 프로필"
        pt={0}
        pb={0}
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false)
          setCloseInner(false)
        }}
        closeInner={closeInner}
        p={0}
        children={
          <>
            <>
              <>
                {profile && (
                  <ProfileForm
                    onPostSuccess={() => {
                      setCloseInner(true)
                    }}
                    profile={profile}
                    onClick={() => {}}
                  />
                )}
              </>
            </>
          </>
        }
      />
    </>
  )
}
