import PageHeader from 'src/components/common/content/PageHeader'
import SelectChipsList from 'src/components/common/form/sub/select/SelectChipsList'
import MagnifyingIcon from 'src/components/common/icon/MagnifyingIcon'
import { GREEN_MARKET_SELECT } from 'src/utils/common/constants/constants'
import GreenPostList from './sub/GreenPostList'

/*
    파일명 : GreenHome.tsx
    설명 : 푸른 마켓 메인
    작성일 : 2024-06-21
    @param 
        
*/
export default function GreenHome() {
  return (
    <>
      <PageHeader title="용산구 이촌 1동" iconComp={<MagnifyingIcon />} />
      {/* <SelectChipsList list={GREEN_MARKET_SELECT} /> */}
      <GreenPostList />
    </>
  )
}
